import { makeStyles } from "@material-ui/core";
import Post, { PostPayload } from "types/Post";
import FacebookPublic from "assets/facebook-public.png";
import OptionsHorizontal from "assets/options-horizontal.png";
import LoveReaction from "assets/love-reaction.png";
import LikeReaction from "assets/like-reaction.png";
import WowReaction from "assets/wow-reaction.png";
import LikeAction from "assets/like-action.png";
import ShareAction from "assets/share-action.png";
import CommentAction from "assets/comment-action.png";
import SocialProfile from "types/SocialProfile";
import moment from "moment";
import clsx from "clsx";
import SocialPreviewMedia from "./SocialPreviewMedia";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";

const useStyles = makeStyles((theme) => ({
	container: {
		minWidth: 400,
		boxShadow: "2px 2px 12px rgba(62, 76, 85, 0.1)",
		fontFamily: "Tahoma",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		padding: 16,
		paddingBottom: 8,
	},

	headerTextContainer: {
		display: "flex",
		flexDirection: "column",
	},

	username: {
		fontFamily: "inherit",
		fontSize: 16,
		color: theme.palette.text.primary,
		fontWeight: 600,
	},

	date: {
		color: "#878787",
		fontFamily: "inherit",
		fontSize: 14,
	},

	optionsContainer: {
		width: "fit-content",
		marginLeft: "auto",
		paddingRight: 8,
	},

	postContent: {
		padding: 16,
		paddingBottom: 8,
		paddingTop: 0,
		whiteSpace: "pre-line",
	},

	postImage: {
		width: "100%",
		maxHeight: 400,
		height: "auto",
	},

	reactions: {
		display: "flex",
		flexDirection: "row",
		paddingLeft: 16,
		paddingRight: 16,
		marginTop: 8,
		paddingBottom: 8,
		alignItems: "center",
		width: "100%",
	},

	reaction: {
		width: 22,
	},

	actions: {
		flexDirection: "row",
		display: "flex",
		paddingLeft: 16,
		paddingRight: 16,
		marginTop: 8,
		alignItems: "center",
		height: 60,
		borderTop: "1px solid #CDCFD3",
	},

	action: {
		width: "33%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: 500,
		fontSize: 14,
	},

	actionIcon: {
		width: 25,
		marginRight: 16,
	},
}));

interface Props {
	postPayload?: PostPayload | Post;
	width?: number;
	className?: string;
}

const FacebookPreview = (props: Props) => {
	const classes = useStyles();
	const { postPayload, width, className } = props;

	const profile: SocialProfile | undefined = postPayload?.socialProfiles?.filter(
		(profile: SocialProfile) => profile.network === "facebook"
	)[0];

	if (!profile || !postPayload) return <></>;

	return (
		<div style={{ width: width || "" }} className={clsx(classes.container, className)}>
			<div className={classes.header}>
				{profile && (
					<SocialProfilePicture
						profile={profile}
						style={{ width: 40, height: 40, marginRight: 8 }}
					/>
				)}
				<div className={classes.headerTextContainer}>
					<span className={classes.username}>{profile.displayName}</span>
					<span className={classes.date}>
						{moment(postPayload?.schedule || new Date()).format("dddd hh:mm:a")}
						<img src={FacebookPublic} style={{ marginLeft: 8, height: 10, objectFit: "contain" }} />
					</span>
				</div>

				<div className={classes.optionsContainer}>
					<img src={OptionsHorizontal} style={{ height: 5 }} />
				</div>
			</div>

			<div className={classes.postContent}>{postPayload?.content}</div>

			<SocialPreviewMedia className={classes.postImage} post={postPayload} />

			<div className={classes.reactions}>
				<img src={LoveReaction} className={classes.reaction} />
				<img src={LikeReaction} className={classes.reaction} style={{ marginLeft: 2 }} />
				<img src={WowReaction} className={classes.reaction} style={{ marginLeft: 2 }} />

				<span style={{ marginLeft: 12 }}>Lorem Ipsum and 230</span>
				<div style={{ width: "fit-content", marginLeft: "auto" }}>55 comments</div>
			</div>

			<div className={classes.actions}>
				<div className={classes.action}>
					<img src={LikeAction} className={classes.actionIcon} />
					Like
				</div>

				<div className={classes.action}>
					<img src={CommentAction} className={classes.actionIcon} />
					Comment
				</div>

				<div className={classes.action}>
					<img src={ShareAction} className={classes.actionIcon} />
					Share
				</div>
			</div>
		</div>
	);
};

export default FacebookPreview;

import { makeStyles } from "@material-ui/core";
import React from "react";

const usePageStyles = makeStyles(() => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "90vh",
		paddingTop: 24,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	headerContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
	},

	button: {
		width: 145,
		marginRight: 10,
	},

	rightSection: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "fit-content",
	},
}));

export default usePageStyles;

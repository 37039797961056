import FacebookPreview from "modules/SocialPreviews/FacebookPreview";
import InstagramPreview from "modules/SocialPreviews/InstagramPreview";
import TwitterPreview from "modules/SocialPreviews/TwitterPreview";
import Tabs, { Tab } from "components/Tabs";
import { ContentType } from "types/Content";
import { PostPayload } from "types/Post";
import LinkedInPreview from "modules/SocialPreviews/LinkedInPreview";
import { useTranslation } from "react-i18next";
import usePreviewSectionStyles from "./styles/preview-section";
import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import Network from "types/Network";

interface Props {
	selectedContentType: ContentType;
	selectedPreview: Tab | undefined;
	postPayload: PostPayload;
	availablePreviews: Network[];
	setSelectedPreview: Dispatch<SetStateAction<Tab | undefined>>;
	setAvailablePreviews: Dispatch<SetStateAction<Network[]>>;
}

const PreviewSection = (props: Props) => {
	const classes = usePreviewSectionStyles();
	const { t } = useTranslation("posts");
	const {
		selectedPreview,
		postPayload,
		availablePreviews,
		setSelectedPreview,
		setAvailablePreviews,
	} = props;

	const handleTabChange = (tab: Tab) => {
		setSelectedPreview(tab);
	};

	// Update available previews when the post payload changes
	useEffect(() => {
		let includedNetworks: Network[] = [];
		let availablePreviews: Network[] = [];

		if (postPayload.socialProfiles) {
			postPayload.socialProfiles.forEach((profile) => {
				includedNetworks.push(profile.network);
				if (!availablePreviews.includes(profile.network)) {
					availablePreviews.push(profile.network);
				}
			});

			availablePreviews.forEach((preview, index) => {
				if (!includedNetworks.includes(preview)) {
					availablePreviews.splice(index, 1);
				}
			});

			if (availablePreviews.length > 0 && !selectedPreview) {
				setSelectedPreview({ name: availablePreviews[0], index: 0 });
			} else if (selectedPreview && !availablePreviews.includes(selectedPreview.name as Network)) {
				if (availablePreviews.length > 0) {
					setSelectedPreview({ name: availablePreviews[0], index: 0 });
				} else {
					setSelectedPreview(undefined);
				}
			}

			setAvailablePreviews([...availablePreviews]);
		}
	}, [postPayload]);

	return (
		<Box className={classes.container}>
			{!selectedPreview && <Box className={classes.noContentMessage}>{t("noPostPreviewHint")}</Box>}

			{selectedPreview && (
				<Box display="flex" alignItems="center">
					<Box className={classes.title}>{t("preview")}</Box>

					{/* Preview tabs */}
					{availablePreviews.length > 0 && (
						<div className={classes.tabsContainer}>
							<Tabs
								tabs={availablePreviews}
								onTabChange={handleTabChange}
								selectedTab={selectedPreview}
								socialIcons={true}
							/>
						</div>
					)}
				</Box>
			)}

			<Box className={classes.previewContainer}>
				{selectedPreview?.name === "facebook" && <FacebookPreview postPayload={postPayload} />}
				{selectedPreview?.name === "instagram" && <InstagramPreview postPayload={postPayload} />}

				{selectedPreview?.name === "twitter" && <TwitterPreview postPayload={postPayload} />}
				{selectedPreview?.name === "linkedin" && <LinkedInPreview postPayload={postPayload} />}
			</Box>
		</Box>
	);
};

export default PreviewSection;

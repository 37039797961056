import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	menuPaper: {
		backgroundColor: "white",
		fontSize: 12,
	},

	menuItem: {
		fontSize: 12,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		position: "relative",
		minWidth: 200,
		paddingBottom: 8,
		paddingTop: 8,
	},

	networkIcon: {
		width: 18,
		marginRight: 12,
	},

	arrowIcon: {
		color: theme.palette.primary.main,
		fontSize: 18,
		position: "absolute",
		right: 8,
	},

	emptyMessage: {
		padding: 16,
		fontSize: 12,
	},
}));

export default useStyles;

import ClampLines from "react-clamp-lines";
import { formatDate } from "helpers/dateAndTime";
import Post from "types/Post";
import useStyles from "../styles/post-item-styles";
import PostImage from "modules/Posts/PostImage";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { useTheme } from "@material-ui/core";

interface Props {
	post: Post;
	selected: boolean;
	onClick: (post: Post) => void;
	selectionIndex: number;
	compare: boolean;
}

const PostItem = (props: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const { post, selected, onClick, selectionIndex, compare } = props;

	return (
		<div
			onClick={() => onClick(post)}
			style={{
				border: selected ? `2px solid ${theme.palette.primary.main}` : "1px solid #E5E5E5",
			}}
			className={classes.container}
		>
			<div className={classes.imageContainer}>
				<PostImage post={post} />

				{compare && selected && (
					<div className={classes.selectionIndexCircle}>
						<div className={classes.selectionIndexInnerCircle}>{selectionIndex}</div>
					</div>
				)}
			</div>
			<div className={classes.postContent}>
				{post.content && (
					<ClampLines
						id="post-content"
						text={post.content}
						lines={2}
						ellipsis="..."
						buttons={false}
					>
						{post.content}
					</ClampLines>
				)}
			</div>
			{post.socialProfiles?.[0] && (
				<>
					<div className={classes.socialDetails}>
						<NetworkIcon size={25} network={post.socialProfiles[0].network} />
						<SocialProfilePicture
							style={{ marginLeft: 6, marginRight: 6 }}
							profile={post.socialProfiles[0]}
						/>
						<div className={classes.username}>{post.socialProfiles[0].displayName}</div>
					</div>
				</>
			)}

			<div className={classes.dateText}>{formatDate(post.publishedAt as string)}</div>
		</div>
	);
};

export default PostItem;

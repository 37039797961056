import { Box } from "@material-ui/core";
import Button from "components/Basic/Button";
import SearchInput from "components/Basic/Inputs/SearchInput";
import EmptyPageMessage from "components/EmptyPageMessage";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePageStyles from "../page-styles";

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
}

const NoResultsMessage = (props: Props) => {
	const { t } = useTranslation();
	const classes = usePageStyles();
	const [inputValue, setInputValue] = useState("");
	const { searchValue, setSearchValue } = props;

	useEffect(() => {
		setInputValue(searchValue);
	}, [searchValue]);

	return (
		<EmptyPageMessage title={t("noResultsFound")} subtitle={t("noResultsHint")}>
			<Box className={classes.searchInputContainer}>
				<SearchInput
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					style={{ width: 450 }}
				/>
				<Button
					onClick={() => setSearchValue(inputValue)}
					secondary
					style={{ width: 150, marginLeft: 16 }}
				>
					{t("search")}
				</Button>
			</Box>
		</EmptyPageMessage>
	);
};

export default NoResultsMessage;

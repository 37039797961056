import { Box, useTheme } from "@material-ui/core";
import { CSSProperties, MouseEvent } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { setUserInfoPopper } from "redux/actions/modals";

interface Props {
	text: string;
	style?: CSSProperties;
}

const MarkdownText = (props: Props) => {
	const { style, text } = props;

	return (
		<Box style={style}>
			<ReactMarkdown renderers={{ link: LinkRenderer }} children={text} />
		</Box>
	);
};

const LinkRenderer: React.FC<any> = (props) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
		e.preventDefault();
		
		const { href } = props;
		if (href.startsWith("user/")) {
			const userId = href.split("user/")[1];
			dispatch(setUserInfoPopper({ anchorEl: e.currentTarget, userId }));
		}
	};

	const handleMouseLeave = () => {
		dispatch(setUserInfoPopper(null));
	};

	return (
		<span
			style={{ backgroundColor: theme.palette.secondary.main, cursor: "pointer" }}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{props.children}
		</span>
	);
};

export default MarkdownText;

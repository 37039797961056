import { useState } from "react";
import DropdownInput from "components/Basic/Inputs/DropdownInput/index";
import usePageStyles from "../../page-styles";
import useTimeSettingsStyles from "../styles/time-settings";
import momentTZ from "moment-timezone";
import moment from "moment-timezone";
import { Clock } from "assets";
import { Box } from "@material-ui/core";
import countries from "../../../../data/countries.json";
import TimeSettingsMap from "./Map";
import { useDispatch, useSelector } from "react-redux";
import { updateUserSettings } from "redux/actions/user/settings";
import UserSettings from "types/User/UserSettings";
import { useEffect } from "react";
import { StoreState } from "../../../../setup/store";
import { useTranslation } from "react-i18next";

const weekDayOptions = [
	{ value: "sunday", label: "Sunday" },
	{ value: "monday", label: "Monday" },
];

export const TimeSettings = () => {
	const { t } = useTranslation("settings");
	const [inputValues, setInputValues] = useState<Partial<UserSettings>>({});
	const { userData } = useSelector((state: StoreState) => state.user);
	const pageClasses = usePageStyles();
	const classes = useTimeSettingsStyles();
	const dispatch = useDispatch();

	const handleInputChange = (name: string | number, value: string) => {
		const updatedInputValues = {
			...inputValues,
			[name]: value,
		} as UserSettings;

		setInputValues(updatedInputValues);
		dispatch(updateUserSettings(updatedInputValues));
	};

	useEffect(() => {
		setInputValues({ ...userData?.settings });
	}, [userData]);

	return (
		<>
			<Box display="flex" flexDirection="row">
				<div style={{ width: "50%" }}>
					<div className={pageClasses.cardTitle} style={{ marginTop: 12 }}>
						{t("timeSettings")}
					</div>
					<div className={pageClasses.card} style={{ minHeight: 245 }}>
						<DropdownInput
							name="country"
							label={t("yourCountry")}
							options={countries}
							value={inputValues.country || "US"}
							onChange={handleInputChange}
						/>

						<DropdownInput
							name="timeFormat"
							label={t("timeFormat")}
							options={[
								{ label: "12 hours format", value: "12" },
								{ label: "24 hours format", value: "24" },
							]}
							rootStyle={{ marginTop: 16 }}
							value={inputValues.timeFormat || "12"}
							onChange={handleInputChange}
						/>

						<DropdownInput
							name="weekStartsFrom"
							label={t("weekStartsFrom")}
							disableSearch
							options={weekDayOptions}
							rootStyle={{ marginTop: 16 }}
							value={inputValues.weekStartsFrom || "monday"}
							onChange={handleInputChange}
						/>

						<DropdownInput
							name="timeZone"
							label={t("selectTimeZone")}
							options={moment.tz.names().map((tz) => ({ value: tz, label: tz }))}
							value={inputValues.timeZone || momentTZ.tz.guess()}
							rootStyle={{ marginTop: 16 }}
							onChange={handleInputChange}
						/>
					</div>
				</div>
				<Box display="flex" flexDirection="column">
					<Box display="flex" flexDirection="row" style={{ margin: 58, marginTop: 32 }}>
						<img className={classes.clockImage} src={Clock} />

						<div className={classes.timeIllustrationContainer}>
							<span>{t("yourCurrentTime")}:</span>
							<br />
							<span
								style={{
									fontSize: 18,
									fontWeight: 700,
									textTransform: "uppercase",
								}}
							>
								{moment.tz(inputValues.timeZone || "").format("hh:mm a")}
							</span>
						</div>
					</Box>
					<div style={{ width: 400, height: 200, marginTop: -52, marginLeft: 12 }}>
						<TimeSettingsMap inputValues={inputValues} />
					</div>
				</Box>
			</Box>
		</>
	);
};

import InteractionsSection from "modules/Reply/InteractionsSection/InteractionsSection";
import Post from "types/Post";
import useStyles from "./styles";
import ByPostInteractionsHeader from "./Header";
import { DateRange } from "pages/Reply";

interface Props {
	post: Post;
	dateRange: DateRange;
}

const ByPost = (props: Props) => {
	const classes = useStyles();
	const { post, dateRange } = props;

	return (
		<div className={classes.container}>
			<ByPostInteractionsHeader post={post} />

			<InteractionsSection
				sectionType="comments"
				selectedPosts={post ? [post] : []}
				tabs={["ALL COMMENTS", "UNREAD COMMENTS", "UNREPLIED COMMENTS"]}
				dateRange={dateRange}
				// items={comments}
			/>

			{/* <InteractionsSection
				sectionType="reposts"
				tabs={["ALL REPOSTS", "UNREAD REPOSTS", "UNREPLIED REPOSTS"]}
				// items={reposts}
			/> */}
		</div>
	);
};

export default ByPost;

const EditIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || 30}
			height={size || 30}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.4107 2.74408C19.7362 2.41864 20.2638 2.41864 20.5893 2.74408L27.2559 9.41074C27.5814 9.73618 27.5814 10.2638 27.2559 10.5893L24.6014 13.2438L16.7398 5.41502L19.4107 2.74408ZM15.414 6.74085L2.5 19.6548V26.6667C2.5 27.1269 2.8731 27.5 3.33333 27.5H10.3452L23.2756 14.5696L15.414 6.74085Z"
				fill={color || "#868C91"}
			/>
		</svg>
	);
};

export default EditIconSvg;

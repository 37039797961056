interface Props {
	size?: number;
	color?: string;
	opacity?: string;
	light?: boolean;
}
export default function ExpandIconSvg({ color, opacity, light }: Props) {
	if (light) {
		return (
			<svg
				width="13"
				height="13"
				viewBox="0 0 26 14"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M1 1L13 13L25 1" stroke={color || "#4B7FA3"} />
			</svg>
		);
	} else
		return (
			<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					opacity={opacity || "0.7"}
					d="M4.5 5.18273L0.5 1.16992H8.5L4.5 5.18273Z"
					fill={color || "#3E4C55"}
					stroke={color || "#3E4C55"}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
}

import { CircularProgress, Popover } from "@material-ui/core";
import { useState, MouseEvent, useEffect, CSSProperties } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SocialProfile from "types/SocialProfile";
import useProfilesSelectorStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { listSocialProfiles } from "redux/actions/socialProfiles/socialProfiles";
import { useTranslation } from "react-i18next";
import SocialProfileItem from "./components/SocialProfileItem";
import ProfilesSelectorActions from "./components/Actions";

interface MenuProps {
	anchorEl: HTMLElement | null;
	selectedProfiles?: SocialProfile[];
	postingOnly?: boolean; // Filter social profiles to include only ones that can be posted to
	single?: boolean;
	handleClose: () => void;
	onChange: (socialProfiles: SocialProfile[]) => void;
}

export const ProfileSelectorMenu = (props: MenuProps) => {
	const classes = useProfilesSelectorStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation("socialProfiles");
	const [filteredProfiles, setFilteredProfiles] = useState<SocialProfile[]>([]);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { profiles, profilesLoading } = useSelector((state: StoreState) => state.socialProfiles);
	let [checkedProfiles, setCheckedProfiles] = useState<string[]>([]);
	const { anchorEl, selectedProfiles, single, handleClose, onChange, postingOnly } = props;

	const handleCheck = (id: string) => {
		if (checkedProfiles.includes(id)) {
			checkedProfiles.splice(checkedProfiles.indexOf(id), 1);
		} else {
			if (single) {
				checkedProfiles = [id];
			} else {
				checkedProfiles.push(id);
			}
		}

		setCheckedProfiles([...checkedProfiles]);
	};

	useEffect(() => {
		let checkedProfiles: string[] = [];

		selectedProfiles?.forEach((profile) => {
			checkedProfiles.push(profile._id || "");
		});

		setCheckedProfiles([...checkedProfiles]);
	}, [anchorEl]);

	useEffect(() => {
		if (currentTeamId && profiles.length === 0) {
			dispatch(listSocialProfiles());
		}
	}, [currentTeamId]);

	useEffect(() => {
		const filteredProfiles: SocialProfile[] = [];

		profiles.forEach((profile) => {
			if (postingOnly && profile.type === "profile" && profile.network === "facebook") return;
			filteredProfiles.push(profile);
		});

		setFilteredProfiles([...filteredProfiles]);
	}, [profiles, postingOnly]);

	return (
		<Popover
			anchorEl={anchorEl}
			onClose={handleClose}
			open={Boolean(anchorEl)}
			classes={{ paper: classes.popover }}
			style={{ height: single ? 200 : "" }}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<div className={classes.menuTitle}>
				<span>{single ? t("chooseSocialProfile") : t("selectSocialProfiles")}</span>
				{profilesLoading && filteredProfiles.length > 0 && (
					<div style={{ marginLeft: 8 }}>
						<CircularProgress size={15} />
					</div>
				)}
			</div>

			{profilesLoading && filteredProfiles.length === 0 && (
				<div className={classes.loadingContainer}>
					<CircularProgress size={18} />
				</div>
			)}

			{filteredProfiles.length === 0 && !profilesLoading && (
				<div style={{ marginTop: 12, fontSize: 12 }} className={classes.loadingContainer}>
					{t("noProfilesFound")}
				</div>
			)}

			<div className={classes.profilesContainer}>
				{filteredProfiles.map((profile, index) => (
					<SocialProfileItem
						key={index}
						single={single}
						profile={profile}
						isChecked={checkedProfiles.includes(profile._id!)}
						onCheck={handleCheck}
					/>
				))}
			</div>

			{!single && (
				<ProfilesSelectorActions
					checkedProfiles={checkedProfiles}
					filteredProfiles={filteredProfiles}
					handleClose={handleClose}
					onChange={onChange}
				/>
			)}
		</Popover>
	);
};

interface Props {
	onChange: (selectedProfiles: SocialProfile[]) => void;
	selectedProfiles?: SocialProfile[];
	single?: boolean;
	style?: CSSProperties;
}

export default function ProfileSelector(props: Props) {
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const classes = useProfilesSelectorStyles();
	const { t } = useTranslation("socialProfiles");

	const { onChange, style, single, selectedProfiles } = props;

	const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
		setMenuAnchor(e.currentTarget);
	};

	return (
		<div>
			<button style={{ ...style }} className={classes.button} onClick={handleButtonClick}>
				{t("postInSocialProfiles")}
				<ArrowDropDownIcon style={{ marginLeft: 8 }} />
			</button>

			<ProfileSelectorMenu
				selectedProfiles={selectedProfiles}
				onChange={onChange}
				anchorEl={menuAnchor}
				single={single}
				handleClose={() => setMenuAnchor(null)}
				postingOnly={true}
			/>
		</div>
	);
}

import { makeStyles } from "@material-ui/core";

const useDateTimePickerStyles = makeStyles((theme) => ({
	container: {
		maxWidth: 600,
		padding: 32,
		backgroundColor: "white",
	},
	calendarContainer: {
		fontWeight: 500,
	},

	deleteReminder: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: 8,
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "#f5f5f5",
			borderRadius: 4,
		},
		width: "fit-content",
		marginTop: 32,
	},

	removeText: {
		color: "rgba(62, 76, 85, 1)",
		marginLeft: 16,
	},

	buttons: {
		display: "flex",
		width: "95%",
		margin: "8px auto 0px auto",
		paddingTop: 32,
	},

	error: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		paddingTop: 12,
		color: theme.palette.error.main,
	},
}));

export default useDateTimePickerStyles;

import { useEffect, useState } from "react";
import useStyles from "./styles/styles";
import Container from "components/Navigation/Container";
import Header from "./Components/Header";
import TableViewItem from "./Components/TableViewItem";
import ListViewItem from "./Components/ListViewItem";
import Post from "types/Post";
import MetaTagsHeader from "components/Navigation/Components/MetaTags";
import { Box, CircularProgress, Fade } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { listPosts } from "redux/actions/posts/get";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { LIST_POSTS } from "redux/reducers/posting";
import EmptyPageMessage from "components/EmptyPageMessage";
import { useHistory } from "react-router";
import { PostFilters } from "components/Popovers/PostsFilter";
import { useTranslation } from "react-i18next";
import TodayView from "./Components/TodayView/TodayView";
import { setPostPreviewDialog } from "redux/actions/modals";

export type ScheduleItem = { id: string; date: string; posts: Post[] };
export type TodayItem = { hour: string; posts: Post[] };
export type PostScheduleView = "table" | "list" | "today";
export type DateFilters = {
	startDate: Date | undefined;
	endDate: Date | undefined;
};

const PostingPage = () => {
	const [appliedFilters, setAppliedFilters] = useState<PostFilters>([]);
	const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);
	const [selectedView, setSelectedView] = useState<PostScheduleView>("table");
	const { t } = useTranslation("posts");
	const [dateFilters, setDateFilters] = useState<DateFilters>({
		startDate: new Date(),
		endDate: undefined,
	});
	const history = useHistory();
	const dispatch = useDispatch();
	const { posts, listPostsLoading } = useSelector((state: StoreState) => state.posts);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const classes = useStyles();

	const handlePostClick = (post: Post) => {
		dispatch(setPostPreviewDialog(true, false, post));
	};

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: LIST_POSTS,
				payload: [],
			});
		};
	}, [history.location]);

	useEffect(() => {
		// Schedule Items
		let schedule: ScheduleItem[] = [];

		posts.forEach((post) => {
			let formattedDate = moment(post.scheduledAt).format("DD MMM");
			if (schedule.filter((scheduleItem) => scheduleItem.date === formattedDate).length === 0) {
				schedule.push({
					id: uuid(),
					date: formattedDate,
					posts: [post],
				});
			} else {
				schedule.forEach((scheduleItem) => {
					if (scheduleItem.date === formattedDate) {
						scheduleItem.posts.push(post);
					}
				});
			}
		});

		setScheduleItems(schedule);
	}, [posts]);

	// TODO: Query only today's posts when selectedView is today
	useEffect(() => {
		if (currentTeamId) {
			dispatch(
				listPosts({
					scheduled: true,
					filters: appliedFilters,
					...dateFilters,
				})
			);
		}
	}, [appliedFilters, dateFilters, history.location, currentTeamId]);

	// TODO: If TableView is selected, automatically fetch the next page if the following conditions are met:
	// TODO:     * There are less than 100 posts loaded
	// TODO:     * There are less than 10 TableViewItem's loaded
	// TODO:     * The total is more than the current posts array length

	// TODO: Lazy load the components
	return (
		<>
			<MetaTagsHeader title="Schedule | HashtagHero" />
			<Container page={t("postingSchedule")}>
				<Box className={classes.pageContainer}>
					<Box className={classes.innerContainer}>
						<Header
							selectedView={selectedView}
							setSelectedView={setSelectedView}
							appliedFilters={appliedFilters}
							dateFilters={dateFilters}
							setDateFilters={setDateFilters}
							setAppliedFilters={setAppliedFilters}
						/>

						{listPostsLoading && posts.length === 0 && (
							<Box className={classes.centeredContainer}>
								<CircularProgress size={25} />
							</Box>
						)}

						{!listPostsLoading && selectedView !== "today" && posts.length === 0 && (
							<Box className={classes.centeredContainer}>
								<EmptyPageMessage title={t("emptyPosts")} />
							</Box>
						)}

						<Box className={classes.postsContainer}>
							{!listPostsLoading &&
								scheduleItems.map((tableItem, index) => (
									<>
										{/* Table View */}
										<Fade
											unmountOnExit
											timeout={{ enter: 500, exit: 0 }}
											in={selectedView === "table"}
										>
											<Box>
												<TableViewItem
													handlePostClick={handlePostClick}
													tableItem={tableItem}
													key={index}
												/>
											</Box>
										</Fade>

										{/* List View */}
										<Fade
											unmountOnExit
											timeout={{ enter: 500, exit: 0 }}
											in={selectedView === "list"}
										>
											<Box>
												<ListViewItem
													handlePostClick={handlePostClick}
													listItem={tableItem}
													key={index}
												/>
											</Box>
										</Fade>
									</>
								))}

							{selectedView === "today" && <TodayView onPostClick={handlePostClick} />}
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default PostingPage;

import { Box } from "@material-ui/core";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import { CSSProperties } from "react";
import { useHistory } from "react-router";
import { getSocialProfileRoute } from "setup/router/utils";
import SocialProfile from "types/SocialProfile";

interface Props {
	socialProfile: SocialProfile;
	postInfoShown?: boolean;
	collapsed?: boolean;
}
const textOverflowEllipsis: CSSProperties = {
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	width: 100,
};

const SocialProfileInfo: React.FC<Props> = (props) => {
	const history = useHistory();
	const { socialProfile, postInfoShown, collapsed } = props;

	return (
		<Box
			onClick={() => history.push(getSocialProfileRoute(socialProfile._id))}
			style={{ paddingLeft: postInfoShown ? 16 : 0, paddingRight: 16, cursor: "pointer" }}
			display="flex"
			alignItems="center"
		>
			<NetworkIcon network={socialProfile?.network as any} size={23} />
			<SocialProfilePicture style={{ marginLeft: 6, marginRight: 6 }} profile={socialProfile} />
			<div style={{ marginBottom: -2, fontSize: 12, ...(collapsed ? textOverflowEllipsis : {}) }}>
				{socialProfile?.displayName || socialProfile?.username}
			</div>
		</Box>
	);
};

export default SocialProfileInfo;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	paper: {
		maxWidth: 1000,
	},

	container: {
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		minWidth: 600,
		padding: 32,
	},

	image: {
		height: 150,
		marginBottom: 24,
	},

	message: {
		fontSize: 16,
		color: theme.palette.text.primary,
		textAlign: "center",
		maxWidth: 280,
		fontFamily: "Avenir",
		whiteSpace: "pre-line",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 24,
	},
}));

export default useStyles;

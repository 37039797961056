import { Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DraftIconSvg from "assets/SVG/DraftIconSvg";
import Button from "components/Basic/Button";
import { StoreState } from "../../../../../setup/store";
import { PostPayload } from "types/Post";
import usePostCreatorStyles from "../styles/post-creator-styles";

interface Props {
	handleSubmit: (publishNow?: boolean, draft?: boolean) => void;
	postPayload: PostPayload | undefined;
	errors: string[];
	submitDisabled: boolean;
}

const PostActions = (props: Props) => {
	const classes = usePostCreatorStyles();
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const { createPostLoading } = useSelector((state: StoreState) => state.posts);
	const { handleSubmit, errors, postPayload, submitDisabled } = props;
	const { t } = useTranslation("posts");
	const actionDisabled = submitDisabled || errors.length > 0 || createPostLoading;

	return (
		<div className={classes.bottomContainer}>
			<Box display="flex" alignItems="center">
				<Box
					style={{ opacity: actionDisabled ? 0.6 : 1 }}
					onClick={() => !actionDisabled && handleSubmit(false, true)}
					className={classes.saveAsDraftContainer}
				>
					<span style={{ marginRight: 8 }}>{t("saveAsDraft")}</span>
					<DraftIconSvg size={14} />
				</Box>
				<Box
					display="flex"
					alignItems="center"
					style={{ width: "fit-content", marginLeft: "auto", marginTop: 0 }}
				>
					{currentTeamRole !== "writer" ? (
						<>
							<Button
								onClick={() => handleSubmit(true)}
								style={{ marginRight: 16, width: 160, fontSize: 12 }}
								disabled={actionDisabled}
							>
								{t("postRightNow")}
							</Button>

							<Button
								onClick={() => handleSubmit(false)}
								style={{ width: 160, fontSize: 12 }}
								disabled={!postPayload?.schedule || actionDisabled}
							>
								{createPostLoading ? <CircularProgress size={20} /> : t("saveToSchedule")}
							</Button>
						</>
					) : (
						<Button
							disabled={actionDisabled}
							style={{ width: 160, fontSize: 12 }}
							onClick={() => handleSubmit(false)}
						>
							{t("saveForApprovement")}
						</Button>
					)}
				</Box>
			</Box>
		</div>
	);
};

export default PostActions;

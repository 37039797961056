import { Box } from "@material-ui/core";
import ErrorText from "components/Basic/Text/ErrorText";
import Tabs, { Tab } from "components/Tabs";
import { Dispatch, ReactNode, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SyncError } from "redux/reducers/social-interactions";
import { getSocialProfileRoute } from "setup/router/utils";
import { StoreState } from "setup/store";
import SocialProfile from "types/SocialProfile";
import InteractionsSectionFiltersMenu, {
	InteractionsSectionFilters,
	Sort,
} from "../FilterMenu/FilterMenu";
import { InteractionSectionType } from "./InteractionsSection";
import useStyles from "./styles";
import SocialProfileChip from "modules/Settings/SocialProfile/SocialProfileChip";

interface Props {
	tabs: string[];
	selectedTab: Tab | undefined;
	selectedSocialProfile?: SocialProfile;
	sort: Sort;
	filters: InteractionsSectionFilters;
	sectionType: InteractionSectionType;
	setSort: Dispatch<SetStateAction<Sort>>;
	setFilters: Dispatch<SetStateAction<InteractionsSectionFilters>>;
	setSelectedTab: Dispatch<SetStateAction<Tab | undefined>>;
}

const InteractionsSectionHeader: React.FC<Props> = (props) => {
	const { t } = useTranslation("reply");
	const {
		tabs,
		selectedTab,
		selectedSocialProfile,
		filters,
		sort,
		sectionType,
		setSelectedTab,
		setSort,
		setFilters,
	} = props;

	const { syncErrors, socialProfiles } = useSelector((state: StoreState) => {
		const { messagesSyncErrors, commentsSyncErrors } = state.socialInteractions;
		let syncErrors: SyncError[] = [];

		if (sectionType === "messages") {
			syncErrors = messagesSyncErrors;
		} else if (sectionType === "comments") {
			syncErrors = commentsSyncErrors;
		}

		return {
			syncErrors,
			socialProfiles: state.socialProfiles.profiles,
		};
	});

	const sectionErrors = useMemo(() => {
		let sectionErrors: ReactNode[] = [];

		syncErrors.forEach((error) => {
			const profile = socialProfiles.find((profile) => profile._id === error.profileId);
			if (!profile) {
				return;
			}

			if (error.message === "MissingPermissions") {
				sectionErrors.push(
					<>
						{t("failedToSyncMessages1")} <strong>{profile.displayName || profile.username}</strong>{" "}
						{t("failedToSyncMessages2")}
						<Link style={{ fontSize: 12, marginLeft: 4 }} to={getSocialProfileRoute(profile._id)}>
							{t("updatePermissions")}
						</Link>
					</>
				);
			}
		});

		return sectionErrors;
	}, [syncErrors, socialProfiles]);

	const classes = useStyles();

	return (
		<>
			<Box mb={2} className={classes.header}>
				<Tabs
					tabs={tabs}
					onTabChange={setSelectedTab}
					selectedTab={selectedTab}
					style={{ backgroundColor: "#F7F7F7" }}
				/>

				<Box className={classes.filterContainer}>
					<Box
						style={{
							gap: 4,
							overflowY: "auto",
							display: "flex",
							width: "fit-content",
							marginLeft: "auto",
							maxWidth: "80%",
						}}
					>
						{filters.socialProfiles?.map((profile) => (
							<SocialProfileChip
								key={profile._id}
								profile={profile}
								onDeleteClick={() =>
									setFilters({
										...filters,
										socialProfiles: [
											...(filters.socialProfiles?.filter(({ _id }) => _id !== profile._id) || []),
										],
									})
								}
							/>
						))}
					</Box>
					<Box width="fit-content">
						<InteractionsSectionFiltersMenu
							hideSocialProfiles={Boolean(selectedSocialProfile)}
							onFiltersChange={(filters) => setFilters({ ...filters })}
							onSortChange={(sort) => setSort(sort)}
							sort={sort}
							filters={filters}
						/>
					</Box>
				</Box>
			</Box>
			{sectionErrors.map((error, index) => (
				<ErrorText key={index}>{error}</ErrorText>
			))}
		</>
	);
};

export default InteractionsSectionHeader;

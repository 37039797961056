import { makeStyles } from "@material-ui/core";

const useDropdownInputStyles = makeStyles((theme) => ({
	container: {
		border: `1px solid ${theme.palette.secondary.main}`,
		paddingLeft: 18,
		paddingRight: 18,
		backgroundColor: "white",
		height: 35,
		width: 330,
		flexDirection: "row",
		alignItems: "center",
		display: "flex",
		cursor: "pointer",
	},

	label: {
		fontSize: 14,
		color: theme.palette.text.primary,
	},

	rightContainer: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
	},

	option: {
		borderTop: "1px solid #D5E1F5",
		color: theme.palette.text.primary,
		fontSize: 12,
		padding: 12,
		paddingTop: 8,
		paddingBottom: 8,
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "#f5f5f5",
		},
	},

	popoverContainer: {
		backgroundColor: "white",
		borderRadius: 5,
		width: "fit-content",
		maxWidth: 350,
		minWidth: 200,
	},
}));

export default useDropdownInputStyles;

export default function ErrorIconSvg({ width, height }: { width: number; height: number }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.8"
				d="M3.18182 20L14 11.0526L8.27273 7.89474L10.8182 0L0 8.94737L5.72727 12.1053L3.18182 20Z"
				fill="#F7F7F7"
			/>
		</svg>
	);
}

import React, { ButtonHTMLAttributes } from "react";
import { makeStyles } from "@material-ui/core";
import FilterIcon from "assets/filter.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { MouseEvent } from "react";

const useStyles = makeStyles(() => ({
	button: {
		backgroundColor: "#D9E3EB",
		height: 36,
		width: 120,
		borderRadius: 8,
		fontFamily: "Avenir",
		fontSize: 12,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		border: "0px",
		letterSpacing: 0.4,
		paddingLeft: 8,
		color: "#3E4C55",
		"&:focus": {
			outline: "none",
		},
		cursor: "pointer",
	},
}));

interface Props extends ButtonHTMLAttributes<any> {}

export default function FilterButton(props: Props) {
	const classes = useStyles();

	return (
		<button {...props} className={classes.button}>
			<img src={FilterIcon} height={13} style={{ marginRight: 12 }} />
			Filter
			<ArrowDropDownIcon style={{ marginLeft: 8 }} />
		</button>
	);
}

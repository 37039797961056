import { Box, CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import WarningIconSvg from "assets/SVG/WarningIconSvg";
import HintText from "components/Basic/Text/HintText";
import TextButton from "components/Basic/TextButton";
import { CSSProperties, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBillingMethodDialog } from "redux/actions/modals";
import {
	reactivateSubscription,
	SubscriptionPayload,
} from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import SubscriptionInfoDialog from "../../../SubscriptionInfoDialog";
import useCurrentSubscriptionPlanStyles from "../styles";

interface Props {
	style?: CSSProperties;
}

const CancelledSubscriptionButton = ({ style }: Props) => {
	const classes = useCurrentSubscriptionPlanStyles();
	const [reactivateConfirmation, setReactivateConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { currentTeamSubscription, paymentMethods } = useSelector(
		(state: StoreState) => state.subscription
	);

	const { t } = useTranslation("settings");
	const theme = useTheme();

	const reactivatePayload = useMemo(() => {
		if (!currentTeamSubscription) {
			return null;
		}

		const {
			planInfo,
			userAddons,
			paymentSourceId: paymentMethodId,
			billingPeriodUnit,
		} = currentTeamSubscription;

		return {
			planId: planInfo.id,
			userAddons,
			paymentMethodId,
			paymentPeriod: billingPeriodUnit === "month" ? "monthly" : "yearly",
		} as SubscriptionPayload;
	}, [currentTeamSubscription]);

	const handleReactivate = async () => {
		setReactivateConfirmation(false);
		setLoading(true);
		await reactivateSubscription(currentTeamId);
		setLoading(false);
	};

	return (
		<Box style={style}>
			{reactivatePayload && (
				<SubscriptionInfoDialog
					title={t("reactivateSubscription")}
					open={reactivateConfirmation}
					subscriptionPayload={reactivatePayload}
					actionButtons={[
						{ text: t("cancel"), secondary: true, onClick: () => setReactivateConfirmation(false) },
						{ text: t("reactivate"), onClick: () => handleReactivate() },
					]}
					onClose={() => setReactivateConfirmation(false)}
				/>
			)}
			<HintText error>
				<Box style={{ marginRight: 8 }}>
					<WarningIconSvg color={theme.palette.error.dark} size={15} />
				</Box>
				{t("cancelledSubscription")}
			</HintText>
			<Box display="flex" alignItems="center">
				{paymentMethods.length > 0 && (
					<TextButton
						className={classes.textButton}
						onClick={() => !loading && setReactivateConfirmation(true)}
					>
						{t("reactivate")}
					</TextButton>
				)}

				{paymentMethods.length === 0 && (
					<TextButton
						className={classes.textButton}
						onClick={() => dispatch(setBillingMethodDialog(true))}
					>
						{t("addPaymentMethodToReactivate")}
					</TextButton>
				)}

				{loading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
			</Box>
		</Box>
	);
};

export default CancelledSubscriptionButton;

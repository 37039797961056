import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import usePageStyles from "../../../../../AccountSettings/styles";
import CurrentSubscriptionPlanActions from "./components/Actions";
import TitleText from "components/Basic/Text/TitleText";
import PlanInfo from "./components/PlanInfo";
import CurrentSubscriptionHintText from "./components/SubscriptionHintText";

export const CurrentSubscriptionInfo = () => {
	const pageClasses = usePageStyles();
	const { t } = useTranslation("settings");

	return (
		<>
			<TitleText style={{ marginLeft: 32 }}>{t("subscriptionPlan")}</TitleText>

			<Box style={{ padding: 32 }} className={pageClasses.card}>
				<PlanInfo />
				<CurrentSubscriptionHintText />
				<CurrentSubscriptionPlanActions />
			</Box>
		</>
	);
};

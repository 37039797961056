import { Box } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import WarningIconSvg from "assets/SVG/WarningIconSvg";
import { ReactNode } from "react";
import theme from "setup/Theme";

interface Props {
	children: ReactNode;
	style?: CSSProperties;
}

const ErrorText: React.FC<Props> = (props) => {
	return (
		<Box style={props.style} mt={1} color={theme.palette.primary.main} fontSize={12} display="flex">
			<WarningIconSvg size={15} color={theme.palette.primary.main} />
			<Box ml={1}>{props.children}</Box>
		</Box>
	);
};

export default ErrorText;

import { Box, CircularProgress, useTheme } from "@material-ui/core";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Switch from "components/Basic/Inputs/Switch";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { listPaymentMethods, submitSetupIntent } from "redux/actions/team/subscriptions/billing";
import Team from "types/User/Team";
import BillingAddressInputs, {
	TBillingAddressInputs,
	validateBillingAddressInputs,
} from "./BillingAddressInputs";
import useStyles from "../styles";
import ActionButtons from "./ActionButtons";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "setup/store";

interface Props {
	onClose: () => void;
}

const NewBillingMethodForm = (props: Props) => {
	const elements = useElements();
	const { t } = useTranslation("settings");
	const [error, setError] = useState("");
	const [isDefault, setIsDefault] = useState(true);
	const [mountLoading, setMountLoading] = useState(true);
	const [billingAddress, setBillingAddress] = useState<TBillingAddressInputs>({});
	const [billingAddressErrors, setBillingAddressErrors] = useState<TBillingAddressInputs>({});
	const [loading, setLoading] = useState(false);
	const { paymentMethods } = useSelector((state: StoreState) => state.subscription);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { onClose } = props;
	const classes = useStyles({});
	const stripe = useStripe();
	const theme = useTheme();
	const dispatch = useDispatch();

	const handleBillingAddressInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setBillingAddress({ ...billingAddress, [name]: value });
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const billingAddressErrors = validateBillingAddressInputs(billingAddress);
		setBillingAddressErrors({ ...billingAddressErrors });

		if (!stripe || !elements || Object.keys(billingAddressErrors).length) {
			return;
		}

		setLoading(true);

		try {
			const result = await stripe.confirmSetup({
				elements,
				confirmParams: {
					payment_method_data: {
						billing_details: {
							address: billingAddress,
						},
					},
				},
				redirect: "if_required",
			});
			if (result.error) {
				setError(result.error.message || "");
				setLoading(false);
			} else {
				await submitSetupIntent(
					result.setupIntent.id,
					currentTeamId,
					// Update payment method to be default only if there are other payment methods for this customer
					// since chargebee will automatically use this one as the default if there are no others
					isDefault && Boolean(paymentMethods.length)
				);

				setLoading(false);
				onClose();
				dispatch(listPaymentMethods());
			}
		} catch (e) {
			setError(e.message);
			setLoading(false);
		}
	};

	return (
		<Box className={classes.formContainer}>
			{mountLoading && (
				<Box width="fit-content" margin="auto" padding={12}>
					<CircularProgress size={32} />
				</Box>
			)}

			{!mountLoading && (
				<BillingAddressInputs
					inputValues={billingAddress}
					errors={billingAddressErrors}
					onChange={handleBillingAddressInputChange}
				/>
			)}
			<PaymentElement onReady={() => setMountLoading(false)} />

			{!mountLoading && Boolean(paymentMethods.length) && (
				<Box className={classes.defaultPaymentMethodBox}>
					<Box style={{ marginRight: 12 }}>{t("setAsDefaultBillingMethod")}</Box>
					<Switch checked={isDefault} onChange={(e, checked) => setIsDefault(checked)} />
				</Box>
			)}

			{error && (
				<Box style={{ color: theme.palette.error.main, fontSize: 12, marginTop: 16 }}>{error}</Box>
			)}

			<ActionButtons
				onClose={onClose}
				onSubmit={handleSubmit}
				loading={loading}
				actionButtonText={t("addCard")}
			/>
		</Box>
	);
};

export default NewBillingMethodForm;

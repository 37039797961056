import { CircularProgress, Popover } from "@material-ui/core";
import useOptionsModalStyles from "./styles";

export type Option = {
	label: string;
	value: string;
	loading?: boolean;
	disabled?: boolean;
};

interface Props {
	anchorEl: HTMLElement | null;
	value?: string;
	options: Option[];
	preventClose?: boolean;
	onClose: () => void;
	onChange: (value: string) => void;
}

const OptionsPopover = (props: Props) => {
	const classes = useOptionsModalStyles();
	const { anchorEl, preventClose, onClose, onChange, options, value } = props;

	return (
		<Popover
			open={Boolean(anchorEl)}
			onClose={onClose}
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			transformOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<div className={classes.popoverContainer}>
				{options.map((option, index) => (
					<div
						key={index}
						style={{
							backgroundColor: value === option.value ? "#F1F4F8" : "",
							opacity: option.disabled ? 0.5 : 1,
							pointerEvents: option.disabled ? "none" : "all",
							borderTop: index > 0 ? "1px solid #D5E1F5" : "",
						}}
						className={classes.option}
						onClick={() => {
							if (value !== option.value) {
								onChange(option.value);
							}
							if (!preventClose) {
								onClose();
							}
						}}
					>
						<div>{option.label}</div>
						{option.loading && <CircularProgress style={{ marginLeft: 16 }} size={15} />}
					</div>
				))}
			</div>
		</Popover>
	);
};

export default OptionsPopover;

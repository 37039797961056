import { useTheme } from "@material-ui/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import useStyles from "./monthly-events-styles";
import moment from "moment";
import "../styles/big-calendar.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { getCalendarData } from "redux/actions/calendar-events/get";
import { CSSProperties } from "react";
import { setCampaignDialog } from "redux/actions/modals";

const localizer = momentLocalizer(moment);

export interface MonthlyEventsProps {
	height?: number;
	width?: number;
	smallEvents?: boolean;
	mainCalendar?: boolean;
	taskStyle?: CSSProperties;
	date?: Date;
}

export default function MonthlyCalendar(props: MonthlyEventsProps) {
	const { posts, campaigns, calendarEvents } =
		useSelector((state: StoreState) => state.calendarEvents.monthCalendarData) || {};
	const { createEventSuccess } = useSelector((state: StoreState) => state.calendarEvents);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);

	const classes = useStyles(props as MonthlyEventsProps);
	const dispatch = useDispatch();
	const theme = useTheme();
	const { height, width, smallEvents, taskStyle, mainCalendar, date } = props;

	const eventStyleGetter = (event: { color: string }) => {
		if (taskStyle) {
			return {
				style: {
					...taskStyle,
					fontSize: 11,
					fontFamily: "Avenir",
					backgroundColor: event.color,
				},
			};
		}

		if (mainCalendar) {
			return { style: { marginTop: 76, backgroundColor: event.color } };
		}

		if (!smallEvents)
			return {
				style: {
					marginBottom: -8,
					fontFamily: "Avenir",
					fontSize: 11,
				},
			};

		const style = {
			color: "transparent",
			height: 3,
			marginBottom: -8,
			marginTop: 28,
		};

		return { style: { ...style, backgroundColor: event.color } };
	};

	const DateCellWrapper = ({ value }: any) => {
		const [postsCount, setPostsCount] = useState<number>(0);
		const [eventsCount, setEventsCount] = useState<number>(0);
		const currentDate = new Date().getDate().toString() + new Date().getMonth().toString();
		const cellDate = new Date(value).getDate().toString() + new Date(value).getMonth().toString();

		useEffect(() => {
			let postsCount = 0;
			let eventsCount = 0;

			posts?.forEach((post) => {
				let scheduleDate = new Date(post.scheduledAt!);
				let postDateString = scheduleDate.getDate().toString() + scheduleDate.getMonth().toString();

				if (postDateString === cellDate) {
					postsCount += 1;
				}
			});

			calendarEvents?.forEach((calendarEvents) => {
				let scheduleDate = new Date(calendarEvents.scheduledAt!);
				let eventDateString =
					scheduleDate.getDate().toString() + scheduleDate.getMonth().toString();

				if (eventDateString === cellDate) {
					eventsCount += 1;
				}
			});

			setPostsCount(postsCount);
			setEventsCount(eventsCount);
		}, [posts, calendarEvents]);

		return (
			<div className={classes.daySlot}>
				<div
					style={{
						backgroundColor: currentDate === cellDate ? theme.palette.primary.main : "",
						color: currentDate === cellDate ? "white" : "",
						opacity: new Date().getMonth() !== new Date(value).getMonth() ? 0.3 : 1,
					}}
					className={classes.dayNumber}
				>
					{new Date(value).getDate()}
				</div>
				<br />

				{postsCount > 0 && (
					<>
						<div style={{ marginBottom: -12, textAlign: "center" }}>
							{postsCount} post{postsCount === 1 ? "" : "s"}
						</div>
						<br />
					</>
				)}
				{eventsCount > 0 && (
					<span>
						{eventsCount} event{eventsCount === 1 ? "" : "s"}
					</span>
				)}
			</div>
		);
	};

	const HeaderWrapper = ({ date, label }: any) => {
		return (
			<div
				style={{
					color: new Date(date).getDay() === new Date().getDay() ? theme.palette.primary.main : "",
				}}
				className={classes.headerWrapper}
			>
				{label}
			</div>
		);
	};

	useEffect(() => {
		if (!currentTeamId) return;
		dispatch(getCalendarData("month"));
	}, [createEventSuccess, currentTeamId]);

	return (
		<div>
			<Calendar
				style={{
					height: height || 400,
					width: width || "",
					fontFamily: "Avenir",
					padding: 8,
				}}
				localizer={localizer}
				events={[...(campaigns || [])]?.map((campaign) => {
					const startDateMonth = moment(campaign.startDate).format("MM");
					const startDateDay = moment(campaign.startDate).format("DD");
					const endDateMonth = moment(campaign.endDate).format("MM");
					const endDateDay = moment(campaign.endDate).format("DD");

					return {
						title: `${startDateMonth}.${startDateDay} - ${endDateMonth}.${endDateDay} ${campaign.title}`,
						start: campaign.startDate,
						end: campaign.endDate,
						color: campaign.color,
						campaign,
					};
				})}
				startAccessor="start"
				step={10}
				selectable={false}
				view="month"
				date={date || new Date()}
				onView={(view) => console.log(view)}
				eventPropGetter={eventStyleGetter}
				onSelectEvent={(e) => dispatch(setCampaignDialog(true, e.campaign))}
				components={{
					dateCellWrapper: DateCellWrapper,
					month: {
						header: HeaderWrapper,
					},
				}}
			/>
		</div>
	);
}

import { useTheme } from "@material-ui/core";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import ProfileIconSvg from "assets/SVG/ProfileIconSvg";
import Button from "components/Basic/Button";
import { useDispatch } from "react-redux";
import { setAddProfileDialog } from "redux/actions/modals";
import Network, { networks } from "types/Network";
import usePageStyles from "../../page-styles";
import useSocialProfilesStyles from "../styles/social-profiles";

interface Props {
	selectedNetwork: Network | undefined;
	setSelectedNetwork: (network: Network | undefined) => void;
}

const SocialProfilesSideBar = (props: Props) => {
	const pageClasses = usePageStyles();
	const classes = useSocialProfilesStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const { selectedNetwork, setSelectedNetwork } = props;

	return (
		<div
			style={{ width: "30%", marginRight: 16, padding: "16px 0px 32px 0px" }}
			className={pageClasses.card}
		>
			<div onClick={() => setSelectedNetwork(undefined)} className={classes.navMenuItem}>
				{!selectedNetwork && <div className={classes.selectedHighlighter}></div>}
				<ProfileIconSvg
					size={20}
					color={!selectedNetwork ? theme.palette.primary.main : theme.palette.text.primary}
				/>
				<span
					style={{
						paddingLeft: 8,
						color: !selectedNetwork ? theme.palette.primary.main : theme.palette.text.primary,
					}}
				>
					View All
				</span>
			</div>

			{networks.map((network, index) => {
				return (
					<div
						onClick={() => setSelectedNetwork(network)}
						key={index}
						className={classes.navMenuItem}
					>
						{selectedNetwork === network && <div className={classes.selectedHighlighter}></div>}
						<NetworkIcon
							size={20}
							strokeWidth={4}
							color={
								selectedNetwork === network
									? theme.palette.primary.main
									: theme.palette.text.primary
							}
							network={network}
						/>
						<span
							style={{
								paddingLeft: 8,
								textTransform: "capitalize",
								color:
									selectedNetwork === network
										? theme.palette.primary.main
										: theme.palette.text.primary,
							}}
						>
							{network}
						</span>
					</div>
				);
			})}

			<div style={{ marginTop: 32 }} className={classes.centeredContainer}>
				<Button
					style={{ width: 180 }}
					onClick={() => dispatch(setAddProfileDialog(true))}
					secondary
				>
					+ Add Social Profile
				</Button>
			</div>
		</div>
	);
};

export default SocialProfilesSideBar;

import WatchIcon from "assets/clock-yellow.png";
import PaperIcon from "assets/posts-yellow.png";
import DraftIcon from "assets/drafts-orange.png";
import NoteIcon from "assets/notes-orange.png";
import StrikeIcon from "assets/strikes-orange.png";
import UserIcon from "assets/users-red.png";

export type SummaryElementType = {
	key: string;
	icon: string;
	title: string;
	subtitle: string;
};

const summaryElements = [
	{
		key: "nextCampaign",
		icon: WatchIcon,
		title: "days",
		subtitle: "nextCampaignStart",
	},
	{
		icon: PaperIcon,
		title: "posts",
		subtitle: "scheduledForToday",
		key: "scheduledPosts",
	},
	{
		icon: DraftIcon,
		title: "drafts",
		subtitle: "forApproval",
		key: "drafts",
	},
	{
		icon: NoteIcon,
		title: "notes",
		subtitle: "remindersYouSaved",
		key: "notes",
	},
	{
		icon: StrikeIcon,
		title: "tasks",
		subtitle: "withoutAssignment",
		key: "tasks",
	},
	{
		icon: UserIcon,
		title: "users",
		subtitle: "toShareWorkspace",
		key: "users",
	},
];

export default summaryElements;

import { Box, CircularProgress } from "@material-ui/core";
import Task from "modules/Tasks/Task";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setTaskDialog } from "redux/actions/modals";
import { StoreState } from "setup/store";
import useGeneralSearchPageStyles from "../page-styles";
import NoResultsMessage from "./NoResultsMessage";
import Masonry from "react-masonry-css";

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
}

const TasksSearch = (props: Props) => {
	const { t } = useTranslation();
	const classes = useGeneralSearchPageStyles();
	const dispatch = useDispatch();
	const { tasksSearchResult, tasksSearchLoading } = useSelector((state: StoreState) => state.tasks);
	const { searchValue, setSearchValue } = props;

	return (
		<Box>
			{tasksSearchLoading && (
				<Box className={classes.loadingContainer}>
					<CircularProgress color="primary" />
				</Box>
			)}

			{!tasksSearchLoading && (
				<>
					{tasksSearchResult.length > 0 ? (
						<Box className={classes.sectionTitle}>
							{t("tasks")} ({tasksSearchResult.length}{" "}
							{tasksSearchResult.length !== 1 ? "results" : "result"})
						</Box>
					) : (
						<NoResultsMessage searchValue={searchValue} setSearchValue={setSearchValue} />
					)}

					{Boolean(tasksSearchResult.length) && (
						<Box className={classes.tasksSearchResultContainer}>
							<Masonry
								breakpointCols={5}
								className="masonry-grid"
								columnClassName="masonry-grid_column-5"
							>
								{tasksSearchResult.map((task) => (
									<Task onTaskClick={() => dispatch(setTaskDialog(true, task))} task={task} />
								))}
							</Masonry>
						</Box>
					)}
				</>
			)}
		</Box>
	);
};

export default TasksSearch;

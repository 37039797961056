export default function LogoSvg({ size }: { size?: number }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 60 60"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.96619 22.657L56.4391 13.6369C57.5283 13.4532 58.5735 14.1849 58.7737 15.2711L60 21.924L6.52707 30.9441C5.43789 31.1278 4.39263 30.3961 4.19242 29.3099L2.96619 22.657Z"
				fill="#F04755"
			/>
			<path
				d="M38.9102 6.13965C40.0147 6.13965 40.9102 7.03508 40.9102 8.13965L40.9102 59.9999L33.7609 59.9999C32.6563 59.9999 31.7609 59.1044 31.7609 57.9999L31.7609 6.13965L38.9102 6.13965Z"
				fill="#F04755"
			/>
			<path
				d="M0 37.6936L53.4729 28.6735C54.5621 28.4898 55.6073 29.2215 55.8076 30.3077L57.0338 36.9606L3.56089 45.9807C2.47171 46.1644 1.42645 45.4328 1.22623 44.3465L0 37.6936Z"
				fill="#F04755"
			/>
			<path
				d="M25.1923 0L25.1923 51.8602C25.1923 52.9648 24.2968 53.8602 23.1923 53.8602L16.043 53.8602L16.043 2C16.043 0.89543 16.9384 -3.47621e-07 18.043 -3.011e-07L25.1923 0Z"
				fill="#F04755"
			/>
		</svg>
	);
}

import React from "react";

export default function NotificationIconActive({ size }: { size?: number }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 44 44"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M28.0951 18.0299C28.0951 14.4947 25.3661 11.6289 21.9998 11.6289C18.6335 11.6289 15.9046 14.4947 15.9046 18.0299C15.9046 25.4978 12.8569 27.6315 12.8569 27.6315H31.1427C31.1427 27.6315 28.0951 25.4978 28.0951 18.0299Z"
				fill="#F04755"
				stroke="#F04755"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.05 31.8984C23.3931 32.9162 21.9426 33.2627 20.8102 32.6723C20.4531 32.4861 20.1563 32.2194 19.9492 31.8984"
				stroke="#F04755"
				strokeLinecap="round"
				strokeLinejoin="bevel"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0078 10.0078C16.6309 3.38468 27.3691 3.38468 33.9922 10.0078C38.405 14.4206 39.8777 20.6603 38.4102 26.2924C38.7495 26.3763 39.082 26.4776 39.4066 26.5952C40.9814 20.6073 39.4218 13.966 34.7279 9.27209C27.6985 2.24264 16.3015 2.24264 9.27208 9.27208C2.24264 16.3015 2.24264 27.6985 9.27208 34.7279C13.9659 39.4218 20.6073 40.9814 26.5952 39.4066C26.4776 39.0819 26.3763 38.7495 26.2924 38.4102C20.6602 39.8777 14.4206 38.405 10.0078 33.9922C3.38468 27.3691 3.38469 16.6309 10.0078 10.0078Z"
				fill="#F04755"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.59271 7.59271C15.5497 -0.364237 28.4504 -0.364234 36.4073 7.5927C41.849 13.0345 43.5692 20.7885 41.5677 27.6921C41.939 27.9414 42.2926 28.2149 42.6264 28.5104C44.9688 21.0676 43.1904 12.608 37.2912 6.70883C28.8462 -1.73628 15.1539 -1.73627 6.70882 6.70882C-1.73628 15.1538 -1.73627 28.8461 6.70882 37.2912C12.608 43.1904 21.0675 44.9688 28.5104 42.6264C28.2149 42.2926 27.9414 41.939 27.6921 41.5677C20.7885 43.5692 13.0344 41.849 7.59271 36.4073C-0.364237 28.4503 -0.364233 15.5496 7.5927 7.59271L7.15077 7.15077L7.59271 7.59271Z"
				fill="#F04755"
			/>
		</svg>
	);
}

const CommentIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || "28"}
			height={size || "28"}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.33341 14L3.33341 24.6667L14.0001 24.6667C19.8911 24.6667 24.6667 19.8911 24.6667 14C24.6667 8.109 19.8911 3.33337 14.0001 3.33337C8.10904 3.33337 3.33341 8.109 3.33341 14Z"
				stroke={color || "#868C91"}
				strokeWidth="2"
			/>
			<ellipse
				rx="1.45833"
				ry="1.45833"
				transform="matrix(-1 0 0 1 18.9584 14.2917)"
				fill={color || "#868C91"}
			/>
			<circle r="1.45833" transform="matrix(-1 0 0 1 13.7084 14.2917)" fill={color || "#868C91"} />
			<ellipse
				rx="1.45833"
				ry="1.45833"
				transform="matrix(-1 0 0 1 8.45841 14.2917)"
				fill={color || "#868C91"}
			/>
		</svg>
	);
};

export default CommentIconSvg;

import Snackbar from "@material-ui/core/Snackbar";
import { IconButton, makeStyles } from "@material-ui/core";
import WarningIconSvg from "assets/SVG/WarningIconSvg";
import ErrorIconSvg from "assets/SVG/ErrorIconSvg";
import SuccessIconSvg from "assets/SVG/SuccessIconSvg";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import { useTranslation } from "react-i18next";

interface Props {
	open: boolean;
	severity?: "success" | "warning" | "error" | string;
	message: string;
	handleClose: () => void;
}

const useStyles = makeStyles(() => ({
	container: {
		color: "white",
		padding: 12,
		paddingBottom: 10,
		paddingTop: 10,
		borderRadius: 8,
		fontWeight: 500,
		minWidth: 350,
		maxWidth: "50vw",
	},

	bold: {
		fontWeight: 600,
		fontSize: 14,
		marginRight: 12,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	closeIconContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},
}));

const SnackBar = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { open, severity, message, handleClose } = props;

	let backgroundColor = "";

	if (severity && severity === "warning") {
		backgroundColor = "#F2801B";
	} else if (severity === "error") {
		backgroundColor = "#F04755";
	} else {
		backgroundColor = "#27AE60";
	}

	return (
		<Snackbar
			style={{ background: "transparent", borderRadius: 15 }}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
		>
			<div style={{ background: backgroundColor }} className={classes.container}>
				<div className={classes.linearContainer}>
					<div style={{ marginRight: 12, marginBottom: -4 }}>
						{severity === "warning" && <WarningIconSvg size={18} />}
						{severity === "error" && <ErrorIconSvg width={14} height={18} />}
						{severity === "success" && <SuccessIconSvg width={14} height={14} />}
					</div>
					<div className={classes.bold}>
						{severity === "warning" && "Warning!"}
						{severity === "error" && "Error!"}
						{severity === "success" && "Success!"}
					</div>
					<div style={{ marginRight: 16 }}>{t(message)}</div>

					<div className={classes.closeIconContainer}>
						<IconButton onClick={handleClose} style={{ padding: 6 }}>
							<CloseIconSvg size={12} color="white" />
						</IconButton>
					</div>
				</div>
			</div>
		</Snackbar>
	);
};

export default SnackBar;

export default function ColorMark({
	color,
	selected,
	height,
}: {
	color: string;
	selected: boolean;
	height: number;
}) {
	return (
		<svg
			width={height}
			height={height}
			viewBox="0 0 60 60"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_712:12176)">
				<rect x="2" y="2" width="56" height="56" rx="11" fill="white" />
				<path
					d="M50.679 59.9999H46.2532C45.3134 59.9999 44.5513 59.2379 44.5513 58.298C44.5513 57.358 45.3134 56.5961 46.2532 56.5961H50.679C53.9414 56.5961 56.5958 53.9418 56.5958 50.6793V9.32061C56.5958 6.05823 53.9415 3.40382 50.679 3.40382H25.1209C24.181 3.40382 23.4189 2.64187 23.4189 1.70191C23.4189 0.761952 24.181 0 25.1209 0H50.6792C55.8184 0 59.9998 4.18113 59.9998 9.32061V50.6793C59.9997 55.8188 55.8185 59.9999 50.679 59.9999Z"
					fill={selected ? "#3E4C55" : "#BDBDBD"}
				/>
				<path
					d="M33.9485 60.0009H9.32061C4.18113 60.0009 0 55.8197 0 50.6803V9.32158C0 4.1821 4.18113 0.000976562 9.32061 0.000976562H13.1508C14.0906 0.000976562 14.8527 0.762928 14.8527 1.70289C14.8527 2.64285 14.0906 3.4048 13.1508 3.4048H9.32061C6.05823 3.4048 3.40382 6.05909 3.40382 9.32158V50.6803C3.40382 53.9426 6.05811 56.597 9.32061 56.597H33.9485C34.8883 56.597 35.6504 57.359 35.6504 58.299C35.6505 59.2389 34.8883 60.0009 33.9485 60.0009Z"
					fill={selected ? "#3E4C55" : "#BDBDBD"}
				/>
				<rect
					x="12"
					y="12"
					width="36"
					height="36"
					rx="6"
					fill={color}
					stroke="#3E4C55"
					strokeWidth={selected ? "4" : "0"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_712:12176">
					<rect width="60" height="60" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

import axios from "axios";
import { Dispatch } from "react";
import config from "config";
import { StoreState } from "../../../../setup/store";
import { DeleteTaskCommentFunc } from "types/ReduxActions";
import { SET_TASK_COMMENTS } from "../../../reducers/tasks";
import { setSnackbarState } from "../../snackBar";

export const deleteTaskComment: DeleteTaskCommentFunc = (selectedComment, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { taskComments, taskCommentsTotal } = getState().tasks;
		const { currentTeamId } = getState().teams;

		try {
			await axios.delete(
				`${config.apiUrl}/tasks/${selectedComment.task}/comments/${selectedComment._id}`,
				{ params: { teamId: currentTeamId } }
			);

			dispatch({
				type: SET_TASK_COMMENTS,
				payload: {
					taskComments: taskComments.filter((comment) => comment._id !== selectedComment._id),
					taskCommentsTotal: taskCommentsTotal - 1,
				},
			});

			cb && cb();
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "DeleteCommentError"));
			cb && cb();
		}
	};
};

import MetaTagsHeader from "../../components/Navigation/Components/MetaTags";
import Container from "../../components/Navigation/Container";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import Header from "./Views/Header/Header";
import General from "./Views/General";
import Post from "types/Post";
import SelectPostDialog from "../../components/Dialogs/SelectPostDialog";
import PostListenStats from "./Views/PostListenStats";
import SelectProfileDialog from "../../components/Dialogs/SelectProfileDialog/SelectProfileDialog";
import SocialProfile from "types/SocialProfile";
import CompareProfiles from "./Views/CompareProfiles";
import ComparePosts from "./Views/ComparePosts";
import { useDispatch, useSelector } from "react-redux";
import { getListenPageData } from "redux/actions/insights";
import { StoreState } from "setup/store";

export type ListenViewType =
	| "general"
	| "bySocialProfile"
	| "byPost"
	| "comparePosts"
	| "compareSocialProfiles";

const ListenPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [selectedView, setSelectedView] = useState<ListenViewType>("general");
	const [postsDialog, setPostsDialog] = useState<"single" | "compare">();
	const [profilesDialog, setProfilesDialog] = useState<"single" | "compare">();
	const [selectedPosts, setSelectedPosts] = useState<Array<Post>>([]);
	const [selectedProfiles, setSelectedProfiles] = useState<Array<SocialProfile>>([]);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);

	const handlePostsDialogSubmit = (selectedPosts: Array<Post>) => {
		setSelectedPosts(selectedPosts);
		if (postsDialog === "single") {
			setSelectedView("byPost");
		} else {
			setSelectedView("comparePosts");
		}
		setPostsDialog(undefined);
	};

	const handleProfilesDialogSubmit = (selectedProfiles: Array<SocialProfile>) => {
		setSelectedProfiles([...selectedProfiles]);
		
		if (profilesDialog === "single") {
			setSelectedView("bySocialProfile");
		} else {
			setSelectedView("compareSocialProfiles");
		}
		setProfilesDialog(undefined);
	};

	useEffect(() => {
		if (currentTeamId) {
			dispatch(
				getListenPageData(() => {
					setIsLoading(false);
				})
			);
		}
	}, [currentTeamId]);

	return (
		<Container
			page={`Listen Audience ${
				selectedView === "byPost"
					? "by Post"
					: selectedView === "comparePosts" || selectedView === "compareSocialProfiles"
					? "Comparing"
					: selectedView === "bySocialProfile"
					? "by Social Profile"
					: ""
			}`}
		>
			<SelectPostDialog
				open={Boolean(postsDialog)}
				compare={postsDialog === "compare"}
				handleClose={() => setPostsDialog(undefined)}
				handleSubmit={handlePostsDialogSubmit}
			/>

			<SelectProfileDialog
				profilesWithPostsAccessOnly
				open={Boolean(profilesDialog)}
				handleClose={() => setProfilesDialog(undefined)}
				compare={profilesDialog === "compare"}
				handleSubmit={handleProfilesDialogSubmit}
			/>

			<MetaTagsHeader title="Listen | HashtagHero" />

			<div className={classes.pageContainer}>
				<div className={classes.innerContainer}>
					<Header
						selectedView={selectedView}
						selectedProfiles={selectedProfiles}
						setSelectedView={setSelectedView}
						setPostsDialog={setPostsDialog}
						setProfilesDialog={setProfilesDialog}
					/>

					{selectedView === "general" && <General />}
					{selectedView === "bySocialProfile" && <General selectedProfile={selectedProfiles[0]} />}
					{selectedView === "byPost" && <PostListenStats post={selectedPosts[0]} />}
					{selectedView === "comparePosts" && <ComparePosts posts={selectedPosts} />}
					{selectedView === "compareSocialProfiles" && (
						<CompareProfiles profiles={selectedProfiles} />
					)}
				</div>
			</div>
		</Container>
	);
};

export default ListenPage;

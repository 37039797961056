import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import TaskSelector from "components/Popovers/TaskSelector/TaskSelector";
import { PostPayload } from "types/Post";
import usePostCreatorStyles from "../styles/post-creator-styles";

interface Props {
	setPostPayload: Dispatch<SetStateAction<PostPayload>>;
	postPayload: PostPayload | undefined;
}

const PostTask = (props: Props) => {
	const [tasksMenu, setTasksMenu] = useState<HTMLElement | null>(null);
	const { t } = useTranslation("posts");
	const classes = usePostCreatorStyles();
	const { postPayload, setPostPayload } = props;

	return (
		<Box className={classes.verticalContainer}>
			<span className={classes.dropdownTitle}>{t("pinToTask")}</span>
			<span className={classes.dropdownSubtitle}>{t("pinToTaskSubtitle")}</span>
			<TaskSelector
				anchorEl={tasksMenu}
				setAnchorEl={setTasksMenu}
				selectedTaskId={postPayload?.task?._id}
				title={postPayload?.task ? postPayload?.task.title : t("selectTask")}
				onSelect={(task) => {
					setTasksMenu(null);
					setPostPayload({
						...postPayload,
						task,
					});
				}}
			/>
		</Box>
	);
};

export default PostTask;

import { useState, useEffect, CSSProperties } from "react";
import { makeStyles, Tabs as MUITabs, Tab, useTheme } from "@material-ui/core";
import Network from "types/Network";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { useTranslation } from "react-i18next";

interface StylesProps {
	compact?: boolean;
}

const useStyles = makeStyles((theme) => ({
	container: {
		borderRadius: ({ compact }: StylesProps) => (compact ? 0 : 20),
		backgroundColor: ({ compact }: StylesProps) => (compact ? "" : "#F7F7F7"),
		border: ({ compact }: StylesProps) => (compact ? "none" : "1px solid #e3ecfa"),
		color: "#5B666E",
		minHeight: 0,
		height: 36,
		width: "fit-content",
	},

	indicator: {
		display: ({ compact }: StylesProps) => (compact ? "default" : "none"),
		backgroundColor: theme.palette.primary.main,
		height: 1,
	},

	tab: {
		borderRadius: ({ compact }: StylesProps) => (compact ? 0 : 15),
		minWidth: ({ compact }: StylesProps) => (compact ? 0 : 90),
		paddingLeft: ({ compact }: StylesProps) => (compact ? 4 : ""),
		paddingRight: ({ compact }: StylesProps) => (compact ? 4 : ""),
		fontWeight: 500,
		height: 36,
		minHeight: 0,
		cursor: "pointer",
		transition: "0.3s",
	},

	tabContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},

	flexContainer: {
		gap: ({ compact }: StylesProps) => (compact ? 16 : 0),
	},
}));

export type Tab = {
	name: string;
	index: number;
};
interface Props {
	tabs: Array<string>;
	onTabChange: (selectedTab: Tab) => void;
	selectedTab: Tab | undefined;
	socialIcons?: boolean;
	selectedTabColor?: "primary" | "secondary";
	style?: CSSProperties;
	compact?: boolean;
}

const Tabs = (props: Props) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const theme = useTheme();
	const {
		tabs,
		onTabChange: handleTabChange,
		compact,
		selectedTabColor,
		socialIcons,
		style,
	} = props;
	const classes = useStyles({ compact });
	const { t } = useTranslation();

	const handleClick = (name: string, index: number) => {
		setSelectedTab(index);
		handleTabChange({ name, index });
	};

	useEffect(() => {
		if (props.selectedTab) {
			setSelectedTab(props.selectedTab.index);
		}
	}, [props.selectedTab]);

	return (
		<MUITabs
			style={{ ...style }}
			classes={{
				indicator: classes.indicator,
				root: classes.container,
				flexContainer: classes.flexContainer,
			}}
			value={selectedTab}
		>
			{tabs?.map((tab, index) => (
				<Tab
					key={index}
					label={
						<div className={classes.tabContainer}>
							{socialIcons && <NetworkIcon network={tab as Network} strokeWidth={3} size={16} />}
							<span
								style={{
									textTransform: socialIcons || compact ? "capitalize" : "uppercase",
									fontSize: 12,
									paddingLeft: socialIcons ? 4 : 0,
								}}
							>
								{t(tab)}
							</span>
						</div>
					}
					className={classes.tab}
					onClick={() => handleClick(tab, index)}
					style={{
						color: selectedTab === index && selectedTabColor === "primary" ? "white" : "",
						backgroundColor: !compact
							? selectedTab === index
								? selectedTabColor === "primary"
									? theme.palette.primary.main
									: "#D5E1F5"
								: ""
							: "",
					}}
				/>
			))}
		</MUITabs>
	);
};

export default Tabs;

import { makeStyles } from "@material-ui/core";

const useHeaderStyle = makeStyles(() => ({
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 24,
	},
}));

export default useHeaderStyle;

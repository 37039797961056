import { CircularProgress, makeStyles } from "@material-ui/core";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { ImagePlaceholder, PlayButton } from "../../assets";
import { getImage } from "helpers/utils";
import { FileUploadProgress } from "redux/actions/media/upload";
import { getPostAttachmentUrl } from "redux/actions/posts/get";

const useStyles = makeStyles(() => ({
	attachmentContainer: {
		position: "relative",
		cursor: "pointer",
		marginRight: 16,
	},

	attachment: {
		height: 80,
		maxWidth: 80,
		borderRadius: 10,
		objectFit: "cover",
	},

	circularProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},

	attachmentOverlay: {
		position: "absolute",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0,
		zIndex: 10,
	},

	playButton: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -58%)",
		height: 23,
	},

	progressCircle: { color: "#4B7FA3" },
	secondaryCircle: { color: "white" },
}));

interface Props {
	onAttachmentClick: (val: File | string) => void;
	uploadProgress?: FileUploadProgress;
	thumbnailKey?: string;
	style?: CSSProperties;
	progressSize?: number;
	playButtonStyle?: CSSProperties;
	progressStyle?: CSSProperties;
	containerStyle?: CSSProperties;
	attachment: File | string;
	type: "image" | "video";
}

const MediaAttachment = (props: Props) => {
	const [thumbnail, setThumbnail] = useState<string>("");
	const {
		attachment,
		type,
		uploadProgress,
		style,
		progressSize,
		playButtonStyle,
		progressStyle,
		containerStyle,
		thumbnailKey,
		onAttachmentClick,
	} = props;
	const classes = useStyles();
	const imageElement = useRef<HTMLImageElement>(null);

	const getThumbnail = async () => {
		let thumbnail = "";
		if (typeof attachment === "string") {
			if (type === "image") {
				thumbnail = getImage(attachment);
			} else {
				thumbnail = getImage(thumbnailKey || "");
			}
		} else {
			thumbnail = await getPostAttachmentUrl(attachment);
		}

		setThumbnail(thumbnail);
	};

	const handleImageLoadError = () => {
		if (!imageElement.current) {
			return;
		}

		imageElement.current.src = ImagePlaceholder;
	};

	useEffect(() => {
		getThumbnail();
	}, [attachment]);

	return (
		<div
			style={{ ...style, ...containerStyle }}
			onClick={() => onAttachmentClick(attachment)}
			className={classes.attachmentContainer}
		>
			<img
				alt={`image-${String(attachment)}`}
				src={thumbnail}
				ref={imageElement}
				onError={handleImageLoadError}
				style={{ filter: uploadProgress ? "blur(1.5px)" : "", ...style }}
				className={classes.attachment}
			/>
			{uploadProgress && (
				<div className={classes.attachmentOverlay}>
					<div style={progressStyle} className={classes.circularProgress}>
						<CircularProgress
							classes={{ circle: classes.secondaryCircle }}
							variant="determinate"
							value={100}
							size={progressSize || 40}
							thickness={4.5}
						/>
					</div>
					<div style={progressStyle} className={classes.circularProgress}>
						<CircularProgress
							classes={{ circle: classes.progressCircle }}
							variant="determinate"
							value={uploadProgress.progress}
							size={progressSize || 40}
							thickness={4.5}
						/>
					</div>
				</div>
			)}

			{type === "video" && (
				<div className={classes.attachmentOverlay}>
					<img style={playButtonStyle} src={PlayButton} className={classes.playButton} />
				</div>
			)}
		</div>
	);
};

export default MediaAttachment;

import { Box, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { CSSProperties, MouseEvent, ReactNode } from "react";

const useStyles = makeStyles(() => ({
	textButton: {
		fontSize: 12,
		textDecoration: "underline",
		cursor: "pointer",
		color: "#5B666E",
		display: "flex",
		alignItems: "center",
	},
}));

interface Props {
	children: ReactNode;
	style?: CSSProperties;
	secondary?: boolean;
	disabled?: boolean;
	className?: string;
	onClick: (e: MouseEvent<HTMLElement>) => void;
}

const TextButton = (props: Props) => {
	const { onClick, className, disabled, style, secondary, children } = props;
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Box
			style={{
				color: secondary ? theme.palette.text.secondary : "",
				...style,
				opacity: disabled ? 0.8 : 1,
				pointerEvents: disabled ? "none" : "all",
			}}
			className={classes.textButton + " " + className}
			onClick={onClick}
		>
			{children}
		</Box>
	);
};

export default TextButton;

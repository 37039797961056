import axios from "axios";
import { Dispatch } from "react";

import { setSnackbarState } from "../snackBar";
import config from "config";
import { DeletePostFunc, UpdatePostFunc } from "types/ReduxActions";
import { StoreState } from "../../../setup/store";
import {
	CREATE_POST_ERROR,
	CREATE_POST_LOADING,
	CREATE_POST_SUCCESS,
	LIST_POSTS,
} from "../../reducers/posting";
import { PostStatus } from "types/Post";
import { uploadMediaArray } from "../media/upload";
import { handleSubmitPostError } from "./create";
import { Media } from "types/Task";

export type UpdatePostPayload = {
	content?: string;
	draft?: boolean;
	schedule?: string;
	media: Media[];
	publishNow?: boolean;
	campaign?: string;
	task?: string | null;
	status?: PostStatus;
};

export const updatePost: UpdatePostFunc = (
	postId,
	socialProfiles,
	payload,
	onProgressUpdate,
	callback
) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		dispatch({
			type: CREATE_POST_LOADING,
			payload: true,
		});

		const { content, media, campaign, draft, schedule, publishNow, task, status } = payload;

		try {
			const uploadedMedia = media ? await uploadMediaArray(media, onProgressUpdate) : [];

			if (!schedule && !draft) {
				dispatch(setSnackbarState(true, "success", "Your post is being processed..."));
				dispatch({
					type: CREATE_POST_LOADING,
					payload: false,
				});

				if (callback) callback(true);
			}

			const result = await axios.put(
				`${config.apiUrl}/posts/${postId}`,
				{
					content,
					draft,
					campaign,
					socialProfiles: socialProfiles || null,
					media: uploadedMedia,
					schedule,
					publishNow,
					task,
					status,
				},
				{ params: { teamId: currentTeamId } }
			);

			dispatch({
				type: CREATE_POST_SUCCESS,
				payload: result.data,
			});

			dispatch(setSnackbarState(true, "success", "Post updated successfully"));
			callback && callback(true);
		} catch (e) {
			console.log(e.response?.data || e);
			dispatch(setSnackbarState(true, "error", "Error creating post..."));
			dispatch({
				type: CREATE_POST_ERROR,
				payload: e.response?.data || e,
			});
			callback && callback(false);
		}
	};
};

export const deletePost: DeletePostFunc = (postId, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { posts } = getState().posts;

		try {
			const result = await axios.delete(`${config.apiUrl}/posts/${postId}`, {});

			if (result.data.success) {
				posts.forEach((post, index) => {
					if (post._id === postId) {
						posts.splice(index, 1);
					}
				});

				dispatch({
					type: LIST_POSTS,
					payload: [...posts],
				});

				if (callback) callback(true);
			}
		} catch (e) {
			dispatch(handleSubmitPostError(e));
			if (callback) callback(false);
			dispatch(setSnackbarState(true, "error", "Something went wrong, try again later..."));
		}
	};
};

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%",
		maxWidth: 900,
		height: "80vh",
		background: "white",
		position: "relative",
		overflow: "hidden",
	},

	container: {
		padding: 32,
		overflow: "hidden",
		height: "100%",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 32,
	},

	dialogTitle: {
		fontWeight: 700,
		fontSize: 16,
		color: theme.palette.text.primary,
	},

	alignRight: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	postsList: {
		width: "100%",
		maxHeight: "80%",
		overflowY: "scroll",
	},

	buttonsContainer: {
		display: 'flex',
		position: "absolute",
		bottom: 24,
		right: 32,
	},
}));

export default useStyles;

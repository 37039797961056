import { makeStyles } from "@material-ui/core";

const useSummaryStyles = makeStyles((theme) => ({
	summary: {
		width: "100%",
		borderRadius: 8,
		background: "#F1F4F8",
		height: 390,
		overflowY: "scroll",
		padding: 24,
		paddingTop: 16,
	},

	sectionLabel: {
		fontWeight: 500,
		color: theme.palette.text.primary,
		fontSize: 14,
		marginBottom: 8,
	},

	textContainer: {
		position: "relative",

		"&:after": {
			position: "absolute",
			bottom: 0,
			height: "50%",
			width: "100%",
			content: '""',
			background: ({ expanded }: { expanded?: boolean }) =>
				expanded ? "" : "linear-gradient(to top, #F1F4F8 20%, rgba(255,255,255, 0) 80%)",
			pointerEvents: "none",
		},
	},

	description: {
		fontSize: 14,
		overflow: "hidden",
		transition: "0.3s",
	},

	emptySectionMessage: {
		color: "#868C91",
		fontWeight: 300,
	},

	attachments: {
		padding: 12,
		display: "flex",
		flexWrap: "wrap",
		gap: 8,
	},

	attachmentsBox: {
		backgroundColor: "#D5E1F5",
		borderRadius: 8,
	},
}));

export default useSummaryStyles;

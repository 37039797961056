import { Divider, makeStyles } from "@material-ui/core";
import LinkedInClapReact from "assets/linkedin-clap.png";
import LinkedInLoveReact from "assets/linkedin-love.png";
import LinkedInBulpReact from "assets/linkedin-bulp.png";
import LinkedInLike from "assets/linkedin-like.png";
import LinkedInComment from "assets/linkedin-comment.png";
import LinkedInShare from "assets/linkedin-share.png";
import OptionsHorizontal from "assets/options-horizontal.png";
import PublicIcon from "assets/linkedin-public.png";
import Post, { PostPayload } from "types/Post";
import SocialProfile from "types/SocialProfile";
import clsx from "clsx";
import SocialPreviewMedia from "./SocialPreviewMedia";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";

const useStyles = makeStyles((theme) => ({
	container: {
		minWidth: 400,
		boxShadow: "2px 2px 12px rgba(62, 76, 85, 0.1)",
		fontFamily: "Tahoma",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	headerTextContainer: {
		display: "flex",
		flexDirection: "column",
		marginLeft: 8,
	},

	username: {
		fontFamily: "inherit",
		color: "black",
		marginBottom: 2,
		fontSize: 12,
		fontWeight: 600,
	},

	headerSubText: {
		color: "#707070",
		marginBottom: 2,
		fontSize: 12,
	},

	seperatorDot: {
		marginLeft: 6,
		marginRight: 6,
		width: 2,
		height: 2,
		backgroundColor: "#707070",
		borderRadius: "50%",
	},

	optionsDotsContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},

	content: {
		color: "#191919",
		fontSize: 12,
		paddingBottom: 12,
		paddingLeft: 12,
		paddingRight: 12,
	},

	postImage: {
		width: "100%",
		objectFit: "contain",
		height: "auto",
	},

	reactionIcon: {
		height: 15,
		marginRight: 2,
	},

	action: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		color: "#707070",
		fontWeight: 700,
		marginRight: 32,
	},

	actionIcon: {
		width: 18,
		marginRight: 10,
		alignItems: "center",
	},
}));

interface Props {
	postPayload?: PostPayload | Post;
	scheduled?: boolean;
	width?: number;
	className?: string;
}

const LinkedInPreview = (props: Props) => {
	const classes = useStyles();
	const { postPayload, className } = props;

	const profile = postPayload?.socialProfiles?.filter(
		(profile: SocialProfile) => profile.network === "linkedin"
	)[0];

	if (!profile || !postPayload) return <></>;

	return (
		<div className={clsx(classes.container, className)}>
			<div style={{ padding: 12, paddingRight: 16 }} className={classes.linearContainer}>
				{profile && (
					<SocialProfilePicture
						profile={profile}
						style={{ width: 40, height: 40, marginRight: 8 }}
					/>
				)}
				<div className={classes.headerTextContainer}>
					<div className={classes.username}>{profile.displayName || profile.username}</div>
					<span className={classes.headerSubText}>
						623 followers
						<div className={classes.linearContainer}>
							1w <div className={classes.seperatorDot}></div>
							<img src={PublicIcon} height={15} />
						</div>
					</span>
				</div>
				<div className={classes.optionsDotsContainer}>
					<img src={OptionsHorizontal} width={20} />
				</div>
			</div>

			<div className={classes.content}>{postPayload?.content}</div>

			<SocialPreviewMedia className={classes.postImage} post={postPayload} />

			{/* Reactions */}
			<div className={classes.linearContainer} style={{ padding: 12 }}>
				<img src={LinkedInClapReact} className={classes.reactionIcon} />
				<img src={LinkedInLoveReact} className={classes.reactionIcon} />
				<img src={LinkedInBulpReact} className={classes.reactionIcon} style={{ marginRight: 6 }} />
				<span
					style={{ fontSize: 12, display: "flex", alignItems: "center" }}
					className={classes.headerSubText}
				>
					153 <div className={classes.seperatorDot}></div>57 comments
				</span>
			</div>

			<Divider />
			<div style={{ padding: 12, paddingLeft: 16 }} className={classes.linearContainer}>
				<div className={classes.action}>
					<img src={LinkedInLike} className={classes.actionIcon} />
					<span>Like</span>
				</div>

				<div className={classes.action}>
					<img src={LinkedInComment} className={classes.actionIcon} />
					<span>Comment</span>
				</div>

				<div className={classes.action}>
					<img src={LinkedInShare} className={classes.actionIcon} />
					<span>Share</span>
				</div>
			</div>
		</div>
	);
};

export default LinkedInPreview;

import { makeStyles } from "@material-ui/core";

const useTaskStyles = makeStyles((theme) => ({
	taskCard: {
		borderRadius: 8,
		marginBottom: 10,
		marginTop: 10,
		boxShadow: "2px 2px 0px #D9E3EB",
		width: '100%',
		padding: 12,
		minHeight: 100,
		background: "#F7F7F7",
		cursor: "pointer",
	},

	deadline: {
		color: theme.palette.text.primary,
		opacity: 0.8,
		fontWeight: 300,
		fontSize: 12,
	},

	title: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontWeight: 500,
		marginTop: 8,
	},

	assignees: {
		display: "flex",
		alignItems: "center",
		gap: 8,
		marginTop: 8,
	},

	assignee: {
		width: 25,
		height: 25,
		objectFit: "cover",
		borderRadius: "50%",
	},
}));

export default useTaskStyles;

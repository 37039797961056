import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	stepsContainer: {
		position: "fixed",
		bottom: 72,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	},

	step: {
		height: 12,
		width: 12,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: "50%",
		marginLeft: 6,
		marginRight: 6,
	},
}));

const stepsCount = [1, 2, 3, 4, 5];

interface Props {
	selectedStep: number;
	static?: boolean;
}

export default function SignUpStepper(props: Props) {
	const classes = useStyles();

	return (
		<div className={classes.stepsContainer} style={{ position: props.static ? "static" : "fixed" }}>
			{stepsCount.map((step, index) => (
				<div
					key={index}
					className={classes.step}
					style={{ background: step <= props.selectedStep ? "#E42631" : "" }}
				></div>
			))}
		</div>
	);
}

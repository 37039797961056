import { makeStyles } from "@material-ui/core";

const usePostStyles = makeStyles((theme) => ({
	scheduleBox: {
		border: "1px solid #6b9ec2",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 3,
		marginRight: 6,
		width: 80,
		height: 25,
	},

	clockIcon: {
		height: 14,
		marginTop: -1,
	},

	schedule: {
		fontSize: 11,
		color: theme.palette.text.primary,
		marginLeft: 4,
	},
}));

export default usePostStyles;

import { Popover } from "@material-ui/core";
import Picker, { IEmojiData, SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { MouseEvent } from "react";

interface Props {
	anchorEl: HTMLElement | null;
	onClose: () => void;
	onEmojiClick: (e: MouseEvent, data: IEmojiData) => void;
}

const EmojiPickerPopover: React.FC<Props> = (props) => {
	const { anchorEl, onClose, onEmojiClick } = props;

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={() => onClose()}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
		>
			<Picker
				onEmojiClick={onEmojiClick}
				disableAutoFocus={true}
				skinTone={SKIN_TONE_MEDIUM_DARK}
				groupNames={{ smileys_people: "PEOPLE" }}
				native
			/>
		</Popover>
	);
};

export default EmojiPickerPopover;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {},

	header: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 32,
	},

	label: {
		color: theme.palette.text.primary,
		fontSize: 12,
		opacity: 0.9,
	},

	title: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		fontSize: 14,
		marginBottom: 12,
	},

	value: {
		color: theme.palette.text.primary,
		fontSize: 12,
		fontWeight: 500,
		marginBottom: 8,
	},

	sectionSummaryContainer: {
		display: "flex",
		flexDirection: "column",
		width: "50%",
	},

	hashtagText: {
		color: "#4285F4",
		fontSize: 12,
		marginTop: 6,
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

interface Props {
	limitExceeded: boolean;
}

const useAddProfileDialogStyles = makeStyles((theme) => ({
	container: {
		width: 580,
		height: 440,
		padding: 32,
		backgroundColor: "#F7F7F7",
	},

	paper: {
		borderRadius: 12,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	closeIconContainer: {
		width: "fit-content",
		marginLeft: "auto",
		cursor: "pointer",
	},

	dialogTitle: {
		fontSize: 16,
		fontWeight: 700,
		color: theme.palette.text.primary,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	illustration: {
		height: 150,
		width: 150,
	},

	label: {
		fontSize: 14,
		marginBottom: 12,
		marginTop: 32,
		color: theme.palette.text.primary,
		userSelect: "none",
	},

	errorLabel: {
		color: theme.palette.error.main,
	},

	socialNetworkItem: {
		color: "#4B7FA3",
		fontSize: 14,
		paddingTop: 12,
		paddingBottom: 12,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		opacity: ({ limitExceeded }: Props) => (limitExceeded ? 0.6 : 1),
		cursor: ({ limitExceeded }: Props) => (limitExceeded ? "default" : "pointer"),
		marginLeft: 32,
		userSelect: "none",
	},

	note: {
		fontSize: 12,
		color: theme.palette.text.primary,
		marginTop: 32,
	},
}));

export default useAddProfileDialogStyles;

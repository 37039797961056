import { useEffect, useRef, useState, MouseEvent as ReactMouseEvent } from "react";
import useStyles from "../../pages/Auth/styles";
import CancelIcon from "assets/cancel.png";
import AvatarEditor from "react-avatar-editor";
import Button from "../Basic/Button";
import { Box, Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ResizeIconSvg from "assets/SVG/ResizeIconSvg";

interface Props {
	open: boolean;
	handleClose: () => void;
	file: File;
	setSelectedFile: (file: File | undefined) => void;
	setImagePreview?: (url: string) => void;
}

const resizerId = "cropper-resizer";

type Position = {
	x: number;
	y: number;
};

const PhotoCropper = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [initialResizePosition, setInitialResizePosition] = useState<Position>();
	const [scale, setScale] = useState<number>(1);
	const { open, file, handleClose, setSelectedFile, setImagePreview } = props;

	const editorRef = useRef<any>();

	const handleCancelClick = () => {
		setSelectedFile(undefined);
		handleClose();
	};

	const dataURItoBlob = (dataURI: string) => {
		// convert base64 to raw binary data held in a string
		let byteString = atob(dataURI.split(",")[1]);
		let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

		let ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);

		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	};

	const handleSave = () => {
		const blob = dataURItoBlob(editorRef.current.getImage().toDataURL());
		const resultFile = new File([blob], "image.jpeg", {
			type: file.type,
			lastModified: 1597081051454,
		});

		setImagePreview && setImagePreview(editorRef.current.getImage().toDataURL());
		setSelectedFile(resultFile);
		handleClose();
	};

	const handleMouseDown = (e: ReactMouseEvent<HTMLElement>) => {
		setInitialResizePosition({ x: e.clientX, y: e.clientY });
	};

	// Handle resizer actions
	useEffect(() => {
		const resizerElement = document.getElementById(resizerId);

		if (!open) return;

		if (!resizerElement) {
			return;
		}

		const mouseUpHandler = () => {
			window.removeEventListener("mousemove", mouseMoveHandler);
			setInitialResizePosition(undefined);
		};

		const mouseMoveHandler = (e: MouseEvent) => {
			const { x, y } = initialResizePosition || {};
			if (!x || !y) return;

			let offsetX = x - e.clientX;
			let offsetY = y - e.clientY;

			let newScale = (offsetX > offsetY ? offsetX : offsetY) / 100 + (scale || 1);
			if (newScale < 1) {
				setScale(1);
			} else setScale(newScale);
		};

		// resizerElement.addEventListener('mousedown', mouseDownHandler);
		window.addEventListener("mouseup", mouseUpHandler);
		if (initialResizePosition) {
			window.addEventListener("mousemove", mouseMoveHandler);
		}
		return function cleanup() {
			window.removeEventListener("mouseup", mouseUpHandler);
			window.removeEventListener("mousemove", mouseMoveHandler);
		};
	}, [initialResizePosition]);

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={true}>
			<Box className={classes.photoEditorContainer}>
				<Box className={classes.photoEditorCancel} onClick={handleCancelClick}>
					<img src={CancelIcon} style={{ height: 20 }} />
				</Box>

				<Box className={classes.photoEditorTitle}>{t("photoAdding")}</Box>

				<Box width="fit-content" position="relative">
					<Box className={classes.editorContainer}>
						<AvatarEditor
							ref={editorRef}
							image={file}
							width={400}
							height={400}
							border={[140, 50]}
							borderRadius={500}
							color={[62, 76, 85, 0.7]} // RGBA
							scale={scale}
							rotate={0}
							style={{ marginTop: -50 }}
						/>
					</Box>

					<Box onMouseDown={handleMouseDown} id={resizerId} className={classes.resizer}>
						<ResizeIconSvg size={35} />
					</Box>
				</Box>

				<Button
					onClick={() => document.getElementById("input-label")?.click()}
					secondary={true}
					style={{ marginTop: 32 }}
				>
					{t("uploadAnotherPhoto")}
				</Button>

				<Button style={{ marginTop: 140, marginBottom: 100 }} onClick={handleSave}>
					{t("continue")}
				</Button>
			</Box>
		</Dialog>
	);
};

export default PhotoCropper;

import { makeStyles } from "@material-ui/core";

const useNotificationsStyles = makeStyles((theme) => ({
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 24,
	},

	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "90vh",
		paddingTop: 12,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	cardTitle: {
		fontFamily: "Chau Philomene One",
		color: "#5B666E",
		fontSize: 16,
		marginLeft: 24,
	},

	card: {
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
		marginTop: 8,
		marginBottom: 16,
		minHeight: 250,
		padding: 32,
		position: "relative",
	},

	cardInnerTitle: {
		color: "#4B7FA3",
		fontWeight: 500,
		fontSize: 16,
	},

	centeredContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		marginTop: 80,
	},

	notificationItem: {
		fontSize: 14,
		color: theme.palette.text.primary,
		paddingBottom: 10,
		paddingTop: 10,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		cursor: "pointer",
		whiteSpace: "pre-line",
	},

	notificationTime: {
		color: theme.palette.text.secondary,
		marginLeft: "auto",
		fontSize: 12,
		paddingLeft: 16,
		width: "fit-content",
		whiteSpace: "nowrap",
	},

	illustration: {
		width: 230,
		marginLeft: 42,
		marginTop: 32,
	},
}));

export default useNotificationsStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%",
		maxWidth: 400,
		height: "50vh",
		background: "white",
		position: "relative",
		overflow: "hidden",
		paddingTop: 32,
		paddingBottom: 32,
	},

	container: {
		overflow: "hidden",
		height: "100%",
	},

	title: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: 16,
		paddingLeft: 32,
		paddingRight: 32,
	},

	profilesContainer: {
		maxHeight: "75%",
		overflowY: "scroll",
	},

	profileItem: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		height: 40,
		userSelect: "none",
		transition: "0.3s",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#fcfcfc",
		},
		paddingLeft: 32,
		paddingRight: 32,
	},

	networkIcon: {
		width: 20,
		marginRight: 10,
	},

	profileText: {
		color: theme.palette.text.primary,
		letterSpacing: 0.4,
		fontSize: 12,
	},

	buttonsContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		position: "absolute",
		display: "flex",
		bottom: 32,
		left: 32,
	},

	selectedNumber: {
		width: 18,
		height: 18,
		borderRadius: "50%",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		fontWeight: 500,
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: 11,
	},
}));

export default useStyles;

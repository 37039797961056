import { Dispatch } from "react";

import config from "config";
import axios from "axios";
import {
	DELETE_EVENT_LOADING,
	DELETE_EVENT_SUCCESS,
	LIST_DAY_CALENDAR_EVENTS,
	SET_MONTH_CALENDAR_DATA,
	SET_WEEK_CALENDAR_DATA,
} from "../../reducers/calendar-events";
import { setSnackbarState } from "../snackBar";
import { StoreState } from "../../../setup/store";

export const deleteCalendarEvent = (_id: string) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		let { dayCalendarEvents, monthCalendarData, weekCalendarData } = getState().calendarEvents;
		dispatch({
			type: DELETE_EVENT_LOADING,
			payload: true,
		});

		try {
			await axios.delete(`${config.apiUrl}/calendarEvents/${_id}`, {});

			dispatch({
				type: LIST_DAY_CALENDAR_EVENTS,
				payload: [...dayCalendarEvents.filter((event) => event._id !== _id)],
			});

			if (monthCalendarData) {
				dispatch({
					type: SET_MONTH_CALENDAR_DATA,
					payload: {
						...monthCalendarData,
						calendarEvents: [
							...monthCalendarData.calendarEvents.filter((event) => event._id !== _id),
						],
					},
				});
			}

			if (weekCalendarData) {
				dispatch({
					type: SET_WEEK_CALENDAR_DATA,
					payload: {
						...weekCalendarData,
						calendarEvents: [
							...weekCalendarData.calendarEvents.filter((event) => event._id !== _id),
						],
					},
				});
			}

			dispatch({
				type: DELETE_EVENT_SUCCESS,
				payload: true,
			});

			dispatch(setSnackbarState(true, "success", "Event deleted successfully!"));
		} catch (e) {
			console.log(e);
			dispatch({
				type: DELETE_EVENT_LOADING,
				payload: false,
			});

			dispatch(setSnackbarState(true, "error", "Error deleting event..."));
		}
	};
};

import { Box, CircularProgress, Popover } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DeleteIconSvg from "assets/SVG/DeleteIconSvg";
import { useMenuStyles } from "components/Basic/Inputs/Dropdown";
import MenuOption from "components/Popovers/MenuOption";
import { deleteMessage } from "redux/actions/chat/delete";
import { deleteTaskComment } from "redux/actions/tasks/comments/delete";
import { Message as MessageType } from "types/Chat/Chat";
import { TaskComment } from "types/Task";

interface Props {
	message: Partial<TaskComment & MessageType>;
	type: "message" | "comment";
	anchorEl: HTMLElement | null;
	onClose: () => void;
}

const MessageOptionsMenu = (props: Props) => {
	const { message, anchorEl, type, onClose } = props;
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const classes = useMenuStyles();
	const { t } = useTranslation("chat");
	const dispatch = useDispatch();

	const handleDeleteClick = () => {
		if (deleteLoading) return;

		setDeleteLoading(true);

		if (type === "message") {
			dispatch(
				deleteMessage(message._id!, () => {
					setDeleteLoading(false);
					onClose();
				})
			);
		} else if (type === "comment") {
			dispatch(
				deleteTaskComment(message as TaskComment, () => {
					setDeleteLoading(false);
					onClose();
				})
			);
		}
	};

	return (
		<Popover
			classes={{
				root: classes.menuRoot,
				paper: classes.menuPaper,
			}}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={onClose}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			transformOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<MenuOption onClick={handleDeleteClick}>
				{deleteLoading ? (
					<CircularProgress size={18} />
				) : (
					<Box style={{ height: 16 }}>
						<DeleteIconSvg width={12} height={14} />
					</Box>
				)}
				<Box style={{ marginLeft: 8 }}>
					{type === "message" ? t("deleteMessage") : t("deleteComment")}
				</Box>
			</MenuOption>
		</Popover>
	);
};

export default MessageOptionsMenu;

// Authenticate a user using a cognito authorization code

import { CognitoUserPool, CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";
import axios from "axios";
import { Dispatch } from "react";
import { decodeToken } from "react-jwt";
import config from "config";
import { GetTokensFromCodeFunc } from "types/ReduxActions";
import { SET_AUTH_LOADING } from "../../reducers/user";
import querystring from "querystring";
import { getCognitoClientId } from "helpers/utils";
import { setCurrentTeamId } from "../team/post";
import store from "setup/store";

const { cognitoAuthUrl, UserPoolId, cognitoCallbackURL } = config;

export type AuthProvider = "Google" | "Facebook" | "Apple";

// Used for social logins
export const getTokensFromCode: GetTokensFromCodeFunc = (code, provider) => {
	return new Promise(async (resolve, reject) => {
		const dispatch = store.dispatch as Dispatch<any>;
		dispatch({
			type: SET_AUTH_LOADING,
			payload: true,
		});

		const clientId = getCognitoClientId(provider);

		dispatch(setCurrentTeamId(""));

		try {
			const result = await axios.post(
				cognitoAuthUrl + `/oauth2/token`,
				querystring.stringify({
					grant_type: "authorization_code",
					redirect_uri: cognitoCallbackURL + `?provider=${provider}`,
					client_id: clientId,
					scope: "email openid profile aws.cognito.signin.user.admin",
					code,
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			);
			const { id_token, refresh_token } = result.data;

			localStorage.setItem("AuthProvider", provider);

			const poolData = {
				ClientId: clientId,
				UserPoolId: UserPoolId,
			};

			const userPool = new CognitoUserPool(poolData);

			// Decode id token and get the user's email
			const decodedToken = decodeToken(id_token) as { [index: string]: any };
			const cognitoUser = new CognitoUser({
				Username: decodedToken["cognito:username"],
				Pool: userPool,
			});

			cognitoUser.refreshSession(
				new CognitoRefreshToken({ RefreshToken: refresh_token }),
				(err, result) => {
					if (result) {
						cognitoUser.setSignInUserSession(result);
						resolve({ success: true, err });
					}
				}
			);

			dispatch({
				type: SET_AUTH_LOADING,
				payload: true,
			});
		} catch (e: any) {
			console.log(e);
			resolve({ success: false, err: e });
		}
		dispatch({
			type: SET_AUTH_LOADING,
			payload: false,
		});
	});
};

import { getImage, getVideo } from "helpers/utils";
import { getPostAttachmentUrl } from "redux/actions/posts/get";
import { Media } from "types/Task";

export const getAttachmentPath = async (attachment: Media) => {
	let path = "";

	if (!attachment) {
		return path;
	};

	if (typeof attachment.value === "string") {
		if (attachment.value.startsWith("https")) {
			return attachment.value;
		}
		if (attachment.type === "image") {
			path = getImage(attachment.value);
		} else {
			path = getVideo(attachment.value);
		}
	} else {
		if (attachment.type === "image") {
			const url = await getPostAttachmentUrl(attachment.value);
			path = url;
		} else {
			path = URL.createObjectURL(attachment.value);
		}
	}

	console.log(path)

	return path;
};

import moment from "moment";
import LineChart from "components/Charts/LineChart";
import PieChart from "components/Charts/PieChart";
import { numberWithSpaces } from "helpers/utils";
import { pieData, postDataStats, postStatsLineChart } from "../../../../mock/listenPlaceholderData";
import { networkIcons } from "types/Network";
import Post from "types/Post";
import usePageStyles from "../../styles";
import useStyles from "./styles";

interface Props {
	post: Post;
}

const PostListenStats = ({ post }: Props) => {
	const classes = useStyles();
	const pageClasses = usePageStyles();

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div className={classes.postOverview}>
					{/* TODO: Handle posts without image attachments */}
					<img className={classes.postImage} src={post.attachments[0]} />
					<div className={classes.postText}>
						{post.content} {/* TODO: Handle overflow text clamp */}
					</div>

					<div className={classes.socialDetails}>
						<div className={classes.socialIcon}>
							<img src={networkIcons[post.networks[0]]} height={21} alt="network" />
						</div>
						<img src={post.user.picture} className={classes.profilePicture} alt="profile" />
						<span className={classes.username}>{post.user.username}</span>
					</div>

					<div className={classes.postDateText}>
						Posted {moment(post.schedule).format("DD MMM YYYY")}
						<br />
						at {moment(post.schedule).format("h:mm:ss a")}
					</div>
				</div>
				<div className={classes.postData}>
					{postDataStats.map((item, index) => (
						<div key={index} className={classes.postDataItem}>
							<div className={pageClasses.dataItemTitle}>{item.title}</div>
							<div className={pageClasses.dataItemValue}>{numberWithSpaces(item.value)}</div>
						</div>
					))}
				</div>
			</div>

			<div className={classes.chartTitle}>Post Listen Statistics</div>
			<div className={classes.lineChartContainer}>
				<LineChart
					data={postStatsLineChart}
					colors={["rgba(134, 140, 144, 0.2)", "#F04755", "#56CCF2", "#3E4C55"]}
				/>
			</div>

			<div className={classes.pieChartsContainer}>
				<div className={classes.pieChartItem}>
					<div className={classes.pieChartTitle}>Most Viewed in</div>
					<div className={classes.pieChart}>
						<PieChart data={pieData} />
					</div>
				</div>

				<div className={classes.pieChartItem}>
					<div className={classes.pieChartTitle}>Most Liked in</div>
					<div className={classes.pieChart}>
						<PieChart data={pieData} />
					</div>
				</div>

				<div className={classes.pieChartItem}>
					<div className={classes.pieChartTitle}>Most Disliked in</div>
					<div className={classes.pieChart}>
						<PieChart data={pieData} />
					</div>
				</div>

				<div className={classes.pieChartItem}>
					<div className={classes.pieChartTitle}>Most Reposted in</div>
					<div className={classes.pieChart}>
						<PieChart data={pieData} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PostListenStats;

import { Box, CircularProgress } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DeleteConfirmImage } from "assets";
import Button from "components/Basic/Button";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { PostCreatorInitialState } from "../../../../pages/Posting/Creator";
import { deleteTask } from "redux/actions/tasks/delete";
import { submitTask } from "redux/actions/tasks/submit";
import { StoreState } from "../../../../setup/store";
import Task from "types/Task";
import useTaskDialogStyles from "../../styles/task-dialog";

interface Props {
	selectedTask: Task;
	onSubmit?: (updatedTask: Task, deleted?: boolean) => void;
	setEditMode: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}

const ViewModeActions = (props: Props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const { selectedTask, onSubmit, setEditMode, onClose } = props;
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const { deleteLoading } = useSelector((state: StoreState) => state.tasks);
	const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation("tasks");
	const classes = useTaskDialogStyles();
	const isAdmin = currentTeamRole === "owner" || currentTeamRole === "admin";

	const handleDelete = () => {
		dispatch(
			deleteTask(selectedTask._id, () => {
				onSubmit?.(selectedTask, true);
				onClose();
			})
		);
	};

	const handleSetFinishedClick = () => {
		setLoading(true);
		dispatch(
			submitTask(
				{ status: "finished" },
				selectedTask._id,
				() => {},
				() => {
					setLoading(false);
					onSubmit?.({ ...selectedTask, status: "finished" });
					onClose();
				}
			)
		);
	};

	const handleCreatePostClick = () => {
		const { media, socialProfiles } = selectedTask;
		let postInitialState: Partial<PostCreatorInitialState> = {
			media,
			content: "",
			socialProfiles: socialProfiles,
			task: selectedTask,
		};

		history.push("/posts/new", postInitialState);
		onClose();
	};

	return (
		<>
			<ConfirmationDialog
				open={deleteConfirmation}
				onClose={() => setDeleteConfirmation(false)}
				onAction={handleDelete}
				confirmButton={t("deleteTask")}
				message={t("deleteTaskMessage")}
				actionLoading={deleteLoading}
				image={DeleteConfirmImage}
			/>

			<Box
				style={{ width: "100%", left: 0, paddingRight: 32, paddingLeft: 32 }}
				className={classes.actions}
			>
				{isAdmin && (
					<Button onClick={() => setDeleteConfirmation(true)} secondary style={{ width: 110 }}>
						{t("removeTask")}
					</Button>
				)}

				<Box width="fit-content" marginLeft="auto" display="flex">
					{selectedTask.status !== "finished" && isAdmin && (
						<>
							<Button onClick={() => setEditMode(true)} secondary style={{ width: 110 }}>
								{t("editTask")}
							</Button>

							<Button
								disabled={loading}
								onClick={handleSetFinishedClick}
								style={{ width: 160, marginLeft: 12 }}
							>
								{loading ? <CircularProgress size={20} /> : t("setAsFinished")}
							</Button>
						</>
					)}

					{!isAdmin && selectedTask.status !== "finished" && (
						<Button style={{ width: 160 }} onClick={handleCreatePostClick}>
							{t("createPost")}
						</Button>
					)}
				</Box>
			</Box>
		</>
	);
};

export default ViewModeActions;

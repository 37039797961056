import { debounce } from "lodash";
import { Dispatch, SetStateAction, useCallback, ChangeEvent, useState, MouseEvent } from "react";
import { PostPayload } from "types/Post";
import usePostCreatorStyles from "../styles/post-creator-styles";
import PostAttachments from "./PostAttachments";
import { IEmojiData } from "emoji-picker-react";
import { Box } from "@material-ui/core";
import { FileUploadProgress } from "redux/actions/media/upload";
import EmojiIconSvg from "assets/SVG/EmojiIconSvg";
import AttachmentIconSvg from "assets/SVG/AttachmentIconSvg";
import { useTranslation } from "react-i18next";
import { Media } from "types/Task";
import HashtagIconSvg from "assets/SVG/HashtagIconSvg";
import Dropzone from "react-dropzone";
import { getDragActiveStyles } from "helpers/utils";
import EmojiPickerPopover from "components/Popovers/EmojiPickerPopover";
interface Props {
	postPayload: PostPayload | undefined;
	postContent: string;
	filesUploadProgress: FileUploadProgress[];
	isHashtagsDrawerOpen: boolean;
	setIsHashtagsDrawerOpen: Dispatch<SetStateAction<boolean>>;
	setPostContent: Dispatch<SetStateAction<string>>;
	setPostPayload: Dispatch<SetStateAction<PostPayload>>;
}

const fileInputId = "file-post-input";

const PostContent = (props: Props) => {
	const classes = usePostCreatorStyles();
	const { t } = useTranslation("posts");
	const [emojiPickerPopover, setEmojiPickerPopover] = useState<HTMLElement | null>(null);
	const {
		postPayload,
		postContent,
		filesUploadProgress,
		isHashtagsDrawerOpen,
		setIsHashtagsDrawerOpen,
		setPostContent,
		setPostPayload,
	} = props;

	const handleEmojiClick = (e: MouseEvent, data: IEmojiData) => {
		const { emoji } = data;
		const content = postContent + emoji;

		setPostContent(content);
		debouncedContentUpdate(content);
	};

	const debouncedContentUpdate = useCallback(
		debounce((nextValue) => {
			setPostPayload({
				...postPayload,
				content: nextValue,
			});
		}, 200),
		[postPayload]
	);

	const handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		setPostContent(value);
		debouncedContentUpdate(value);
	};

	const handleFilesChange = (files?: FileList | null) => {
		if (!files) {
			return;
		}

		let newMedia: Media[] = Array.from(files).map((file) => ({
			value: file,
			type: file.type.includes("video") ? "video" : "image",
		}));

		setPostPayload({
			...postPayload,
			media: [...(postPayload?.media || []), ...newMedia],
		});
	};

	const handleFileInputClick = () => {
		const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
		if (fileInput) {
			fileInput.value = "";
		}
	};

	return (
		<Dropzone
			accept={{ "video/*": [], "image/*": [] }}
			onDrop={(files) => handleFilesChange(files as unknown as FileList)}
		>
			{({ getRootProps, getInputProps, isDragActive }) => (
				<div
					style={{
						...getDragActiveStyles(isDragActive),
					}}
					className={classes.postContentContainer}
					{...getRootProps()}
					onClick={(e) => e.stopPropagation()}
				>
					<EmojiPickerPopover
						anchorEl={emojiPickerPopover}
						onEmojiClick={handleEmojiClick}
						onClose={() => setEmojiPickerPopover(null)}
					/>
					<textarea
						onChange={handleContentChange}
						className={classes.textArea}
						value={postContent}
						placeholder={t("yourPostHere")}
						style={{
							height: postPayload?.media?.length ? 120 : 200,
							backgroundColor: isDragActive ? "#FFFFFF00" : "",
							transition: "0.2s",
							fontSize: 12,
						}}
					/>

					<PostAttachments
						postPayload={postPayload}
						filesUploadProgress={filesUploadProgress}
						setPostPayload={setPostPayload}
					/>

					<div className={classes.postActions}>
						{/* <Box className={classes.postAction}>
         			 <LocationIconSvg size={16} />
       			 </Box> */}
						<Box
							onClick={() => setIsHashtagsDrawerOpen(!isHashtagsDrawerOpen)}
							style={{ background: isHashtagsDrawerOpen ? "#ebebeb" : "" }}
							className={classes.postAction}
						>
							<HashtagIconSvg size={16} />
						</Box>
						<Box
							onClick={(e) => setEmojiPickerPopover(e.currentTarget)}
							className={classes.postAction}
						>
							<EmojiIconSvg size={16} />
						</Box>

						<input {...getInputProps()} />
						<input
							accept="image/*|video/*"
							id={fileInputId}
							type="file"
							multiple={true}
							style={{ display: "none" }}
							onChange={(e) => handleFilesChange(e.target.files)}
							onClick={handleFileInputClick}
						/>
						<Box
							onClick={() => document.getElementById(fileInputId)?.click()}
							className={classes.postAction}
						>
							<AttachmentIconSvg size={16} />
						</Box>
					</div>
				</div>
			)}
		</Dropzone>
	);
};

export default PostContent;

import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import useStyles from "./styles";
import Input from "components/Basic/Inputs/Input";
import Button from "components/Basic/Button";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../setup/store";
import { SET_CREDENTIALS, SET_SIGN_IN_ERROR } from "redux/reducers/user";
import { checkAuth } from "redux/actions/auth/checkAuth";
import { CircularProgress } from "@material-ui/core";
import { validateEmail } from "helpers/utils";
import SocialAuthButtons from "./SignUp/SocialAuthButtons";
import { signIn } from "redux/actions/auth";
import { useTranslation } from "react-i18next";
import Disclaimer from "./Disclaimer";

const Login = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [inputValues, setInputValues] = useState<{
		email?: string;
		password?: string;
	}>({});
	const { t } = useTranslation("auth");
	const [errors, setErrors] = useState<{ email?: string; password?: string }>({});
	const { signInResponse, signInError, authLoading } = useSelector(
		(state: StoreState) => state.user
	);

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleSubmit = () => {
		const { email, password } = inputValues;
		let errors = {};

		if (!email || !validateEmail(email)) {
			errors = { ...errors, email: t("invalidEmail") };
		}

		if (!password) {
			errors = { ...errors, password: t("enterPassword") };
		}

		setErrors({ ...errors });

		if (Object.keys(errors).length === 0 && email && password) {
			dispatch(signIn(email, password));
		}
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === "Enter") {
			handleSubmit();
		}
	};

	useEffect(() => {
		if (signInError) {
			if (signInError.code === "NotAuthorizedException") {
				setErrors({
					...errors,
					password: t("incorrectEmailOrPassword"),
				});
			} else if (signInError.code === "UserNotConfirmedException") {
				dispatch({
					type: SET_CREDENTIALS,
					payload: inputValues,
				});
				history.push("signup/verification");
			}
		}
	}, [signInError]);

	useEffect(() => {
		dispatch(
			checkAuth((success, userData) => {
				if (!userData?.emailConfirmed && userData?.email) {
					history.push("signup/verification");
				} else if (userData && !userData?.email) {
					history.push("/signup/general-info");
				} else if (success) {
					history.push("/");
				}
			})
		);
	}, [signInResponse]);

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: SET_SIGN_IN_ERROR,
				payload: {},
			});
		};
	}, []);

	return (
		<div className={classes.formContainer}>
			<div className={classes.inputContainer}>
				<span className={classes.inputHeader}>{t("yourEmail")}</span>
				<Input
					autoFocus
					name="email"
					error={errors.email}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder="example@mail.com"
				/>
			</div>

			<div className={classes.inputContainer}>
				<span className={classes.inputHeader}>{t("password")}</span>
				<Input
					name="password"
					error={errors.password}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder={t("typeYourPassword")}
					type="password"
				/>
			</div>

			<div onClick={() => history.push("/recovery")} className={classes.forgotPasswordLink}>
				{t("forgotPassword")}
			</div>

			<SocialAuthButtons authType="login" />

			<div className={classes.buttonContainer}>
				<Button disabled={authLoading} onClick={handleSubmit}>
					{authLoading ? <CircularProgress size={20} /> : t("signIn")}
				</Button>
			</div>
			<span className={classes.noAccountMessage}>
				{t("dontHaveAnAccount")}&nbsp;
				<span onClick={() => history.push("/signup")} className={classes.joinSpan}>
					{t("join")}!
				</span>
			</span>

			<Disclaimer />
		</div>
	);
};

export default Login;

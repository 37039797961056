import { Box, CircularProgress, Dialog } from "@material-ui/core";
import useStyles from "./styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import config from "config";
import NewBillingMethodForm from "./components/NewBillingMethodForm";
import {
	cancelSetupIntent,
	getSetupIntent,
	SetupIntent,
} from "redux/actions/team/subscriptions/billing";
import { useTranslation } from "react-i18next";
import { PaymentMethod } from "types/Billing";
import UpdateBillingAddressForm from "./components/UpdateBillingAddressForm";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
interface Props {
	open: boolean;
	selectedPaymentMethod?: PaymentMethod;
	onClose: () => void;
}

const stripePromise = loadStripe(config.stripeKey);

export const BillingMethodDialog = (props: Props) => {
	const { open, selectedPaymentMethod, onClose } = props;
	const [setupIntent, setSetupIntent] = useState<SetupIntent>();
	const [loading, setLoading] = useState(false);
	const classes = useStyles({ isNewBillingMethod: !selectedPaymentMethod });
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);

	const { t } = useTranslation("settings");

	const loadSetupIntent = async () => {
		const result = await getSetupIntent(currentTeamId);
		setLoading(false);
		if (result) {
			setSetupIntent(result);
		}
	};

	useEffect(() => {
		if (!open || !currentTeamId) {
			return;
		}
		if (!selectedPaymentMethod) {
			setLoading(true);
			loadSetupIntent();
		}

		return function cleanup() {
			if (setupIntent) {
				cancelSetupIntent(setupIntent.id, currentTeamId);
			}
		};
	}, [open]);

	return (
		<Dialog classes={{ paper: classes.paper }} onClose={onClose} open={open}>
			<div className={classes.container}>
				<div className={classes.dialogTitle}>
					{!selectedPaymentMethod ? t("addBillingMethod") : t("updateBillingAddress")}
				</div>

				{loading && (
					<Box width="fit-content" margin="auto" padding={12}>
						<CircularProgress size={32} />
					</Box>
				)}

				{/* The form below is only loaded when a new payment method is being added */}
				{setupIntent && !loading && !selectedPaymentMethod && (
					<Elements
						stripe={stripePromise}
						options={{
							clientSecret: setupIntent.clientSecret,
							fonts: [
								{
									family: "Avenir",
									src: `url("https://fonts.cdnfonts.com/s/13298/AvenirLTStd-Book.woff")`,
								},
							],
							appearance: {
								variables: {
									fontFamily: "Avenir",
									fontSizeSm: "12px",
									fontSizeBase: "12px",
									spacingGridRow: "16px",
									borderRadius: "8px",
								},
							},
						}}
					>
						<NewBillingMethodForm onClose={onClose} />
					</Elements>
				)}

				{/* If an existing payment method is selected, allow for updating its billing address only */}
				{selectedPaymentMethod && (
					<UpdateBillingAddressForm
						selectedPaymentMethod={selectedPaymentMethod}
						onClose={onClose}
					/>
				)}
			</div>
		</Dialog>
	);
};

import { MembersIllustration } from "assets";
import useTeamUserStyles from "./styles";
import Team, { TeamUser as TTeamUser, TeamUserRole } from "types/User/Team";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../setup/store";
import ExpandIconSvg from "assets/SVG/ExpandIconSvg";
import { Box, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { removeTeamUser, updateTeamUserRole } from "redux/actions/team/users";
import OptionsPopover from "components/Popovers/OptionsPopover";
import { useTranslation } from "react-i18next";
import UserPicture from "components/Media/UserPicture";
import ChangeRolePopover from "./ChangeRolePopover";

interface Props {
	team: Team;
	teamUser: TTeamUser;
	isOwner: boolean;
	index: number;
}

export const TeamUserCard = (props: Props) => {
	const [changeRolePopoverAnchorEl, setChangeRolePopoverAnchorEl] = useState<HTMLElement | null>(
		null
	);
	const [deleteUserConfirm, setDeleteUserConfirm] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [roleUpdateLoading, setRoleUpdateLoading] = useState<boolean>(false);
	const [role, setRole] = useState<TeamUserRole>(props.teamUser.role);
	const classes = useTeamUserStyles();
	const { t } = useTranslation("workspaces");
	const { userData } = useSelector((state: StoreState) => state.user);
	const dispatch = useDispatch();
	const { teamUser, index, isOwner, team } = props;
	const eventIndex = index % 2;
	const { user, restricted } = teamUser;

	const handleDeleteUser = () => {
		setDeleteLoading(true);
		dispatch(
			removeTeamUser(team._id, teamUser._id, () => {
				setDeleteLoading(false);
				setDeleteUserConfirm(false);
			})
		);
	};

	const handleRoleChange = (role: TeamUserRole) => {
		setRoleUpdateLoading(true);

		dispatch(
			updateTeamUserRole(team._id, teamUser._id, role, () => {
				setRoleUpdateLoading(false);
				setRole(role);
			})
		);
	};

	useEffect(() => {
		setRole(props.teamUser.role);
	}, [props.teamUser]);

	return (
		<div
			className={classes.container}
			style={{
				backgroundColor: eventIndex ? "#F1F4F8" : "#f7f9fd",
			}}
		>
			<ChangeRolePopover
				anchorEl={changeRolePopoverAnchorEl}
				teamUser={teamUser}
				onChange={handleRoleChange}
				onClose={() => setChangeRolePopoverAnchorEl(null)}
			/>

			<ConfirmationDialog
				open={deleteUserConfirm}
				actionLoading={deleteLoading}
				onClose={() => setDeleteUserConfirm(false)}
				onAction={handleDeleteUser}
				message={t("removeTeamMemberConfirm")}
				confirmButton={t("deleteMember")}
				image={MembersIllustration}
			/>

			<UserPicture user={user} />
			<div>
				<div className={classes.name}>
					{user?.firstName} {user?.lastName}{" "}
					<strong>{user?._id === userData?._id && "(You)"}</strong>
				</div>
				<div className={classes.email}>
					{user?.email ? <>{user?.email}</> : <>{t("deletedUser")}</>}
				</div>

				{restricted && <div className={classes.restrictedError}>{t("accessRestricted")}</div>}
			</div>
			<div style={{ width: !isOwner ? 100 : 150 }} className={classes.role}>
				{role === "owner" ? (
					<div className={classes.ownerTag}>{t("owner")}</div>
				) : (
					<Box display="flex" alignItems="center">
						<Box
							display="flex"
							alignItems="center"
							onClick={(e) =>
								isOwner && !roleUpdateLoading && setChangeRolePopoverAnchorEl(e.currentTarget)
							}
							style={{ cursor: isOwner ? "pointer" : "" }}
						>
							<span style={{ marginRight: 8 }}>
								{roleUpdateLoading ? <CircularProgress size={16} /> : role}
							</span>
							{isOwner && <ExpandIconSvg opacity="1" color="#868C91" />}
						</Box>

						{isOwner && (
							<div onClick={() => setDeleteUserConfirm(true)} className={classes.deleteUserIcon}>
								<CloseIconSvg color="white" size={8} strokeWidth={3} />
							</div>
						)}
					</Box>
				)}
			</div>
		</div>
	);
};

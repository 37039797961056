import { useState } from "react";
import { Box, CircularProgress, Fade, makeStyles, useTheme } from "@material-ui/core";
import useStyles from "./styles";
import Tabs, { Tab } from "../../components/Tabs";
import MonthlyCalendar from "modules/Calendar/MonthlyCalendar";
import PlusIconTransparent from "assets/plus-icon-transparent.png";
import CalendarEventDialog from "modules/Calendar/CalendarEventDialog/CalendarEventDialog";
import { getCurrentDate } from "helpers/dateAndTime";
import WeeklyCalendar from "modules/Calendar/WeeklyCalendar";
import { StoreState } from "../../setup/store";
import { useSelector } from "react-redux";

const useComponentStyles = makeStyles(() => ({
	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	tabsContainer: {
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		marginLeft: "auto",
		marginTop: 8,
		marginRight: 22,
		alignItems: "center",
	},

	plusIcon: {
		height: 26,
		marginRight: 16,
		cursor: "pointer",
	},

	innerContainer: {
		width: "100%",
		paddingLeft: 16,
		paddingRight: 16,
		marginTop: 32,
		height: 600,
	},
}));

const tabs = ["TODAY", "THIS WEEK", "THIS MONTH"];

interface Props {
	selectedTab: Tab;
	setSelectedTab: (tab: Tab) => void;
}

export default function MainCalender({ selectedTab, setSelectedTab }: Props) {
	const [eventDialog, setEventDialog] = useState<boolean>(false);
	const { listPostsLoading } = useSelector((state: StoreState) => state.posts);
	const { calendarLoading } = useSelector((state: StoreState) => state.calendarEvents);
	const { listCampaignsLoading } = useSelector((state: StoreState) => state.campaigns);
	const classes = useComponentStyles();
	const mainClasses = useStyles();
	const theme = useTheme();

	return (
		<div style={{ width: "100%", maxWidth: 1100 }}>
			<div className={classes.header}>
				<span style={{ color: theme.palette.primary.main }} className={mainClasses.title}>
					<Box display="flex" flexDirection="row" alignItems="center">
						<span style={{ marginRight: 12 }}>{getCurrentDate()}</span>
						{(listPostsLoading || calendarLoading || listCampaignsLoading) && (
							<CircularProgress size={20} />
						)}
					</Box>
				</span>
				<div className={classes.tabsContainer}>
					<img
						onClick={() => setEventDialog(true)}
						src={PlusIconTransparent}
						className={classes.plusIcon}
					/>
					<Tabs tabs={tabs} onTabChange={setSelectedTab} selectedTab={selectedTab} />
				</div>
			</div>
			<div className={mainClasses.container} style={{ height: 700, marginTop: 8 }}>
				<div className={classes.innerContainer} style={{ marginTop: 0 }}>
					<Fade in={selectedTab?.index === 1} unmountOnExit timeout={{ enter: 500, exit: 0 }}>
						<Box style={{ marginTop: 12 }}>
							<WeeklyCalendar height={640} />
						</Box>
					</Fade>

					<Fade in={selectedTab?.index === 2} unmountOnExit timeout={{ enter: 500, exit: 0 }}>
						<Box>
							<MonthlyCalendar height={680} mainCalendar={true} />
						</Box>
					</Fade>
				</div>
			</div>

			<CalendarEventDialog open={eventDialog} handleClose={() => setEventDialog(false)} />
		</div>
	);
}

import { makeStyles } from "@material-ui/core";

const useSocialProfilesStyles = makeStyles((theme) => ({
	platformTitle: {
		color: "#4B7FA3",
		textTransform: "uppercase",
		fontSize: 16,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		fontWeight: 500,
		marginBottom: 12,
		letterSpacing: 0.4,
	},

	profilesContainer: {
		padding: 16,
		paddingTop: 32,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},

	divider: {
		width: 180,
		backgroundColor: theme.palette.text.primary,
		height: 1,
		marginTop: 8,
		marginBottom: 8,
	},

	more: {
		cursor: "pointer",
		color: "#868C91",
		marginTop: 16,
		textDecoration: "underline",
		fontSize: 12,
		marginBottom: 24,
	},

	navMenuItem: {
		paddingTop: 20,
		paddingBottom: 20,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		color: theme.palette.text.primary,
		fontWeight: 500,
		position: "relative",
		fontSize: 14,
		cursor: "pointer",
		paddingLeft: 32,
	},

	selectedHighlighter: {
		height: 27,
		backgroundColor: theme.palette.primary.main,
		width: 5,
		position: "absolute",
		left: 0,
	},

	centeredContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

export default useSocialProfilesStyles;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "helpers/utils";
import CampaignDialog from "modules/Campaigns/CampaignDialog";
import PostPreviewDialog from "modules/Posts/PostPreview";
import TaskDialog from "modules/Tasks/TaskDialog/TaskDialog";
import { openDialogFromQuery } from "redux/actions/modals";
import {
	SET_ADD_PROFILE_DIALOG,
	SET_BILLING_METHOD_DIALOG,
	SET_CAMPAIGN_DIALOG,
	SET_COMING_SOON_DIALOG,
	SET_FULL_SCREEN_MEDIA_PREVIEW,
	SET_POST_PREVIEW_DIALOG,
	SET_TASK_DIALOG,
} from "redux/reducers/modals";
import { StoreState } from "../../setup/store";
import FullScreenMediaPreview from "./FullScreenMediaPreview/FullScreenMediaPreview";
import BillingMethodDialog from "modules/Profile/Billing/BillingMethodDialog";
import UserInfoPopper from "components/Popovers/UserInfoPopper/UserInfoPopper";
import AddProfileDialog from "./AddProfileDialog/AddProfileDialog";
import ComingSoonDialog from "./ComingSoonDialog";

const Dialogs = () => {
	const dispatch = useDispatch();
	const query = useQuery();
	const {
		postPreviewDialog,
		campaignDialog,
		taskDialog,
		fullScreenMediaPreview,
		billingMethodDialog,
		userInfoPopper,
		addProfileDialog,
		comingSoonDialog,
	} = useSelector((state: StoreState) => state.modals);

	const handleDialogClose = (action: string) => {
		dispatch({
			type: action,
			payload: null,
		});
	};

	useEffect(() => {
		dispatch(openDialogFromQuery(query));
	}, []);

	return (
		<>
			<PostPreviewDialog
				{...postPreviewDialog}
				open={Boolean(postPreviewDialog?.open)}
				onClose={() => handleDialogClose(SET_POST_PREVIEW_DIALOG)}
			/>

			<CampaignDialog
				{...campaignDialog}
				open={Boolean(campaignDialog?.open)}
				onClose={() => handleDialogClose(SET_CAMPAIGN_DIALOG)}
			/>

			<TaskDialog
				{...taskDialog}
				open={Boolean(taskDialog?.open)}
				onClose={() => handleDialogClose(SET_TASK_DIALOG)}
			/>

			<FullScreenMediaPreview
				{...(fullScreenMediaPreview || { media: [], initialSelectedIndex: 0 })}
				open={Boolean(fullScreenMediaPreview?.open)}
				onClose={() => handleDialogClose(SET_FULL_SCREEN_MEDIA_PREVIEW)}
			/>

			<BillingMethodDialog
				{...billingMethodDialog}
				open={Boolean(billingMethodDialog?.open)}
				onClose={() => handleDialogClose(SET_BILLING_METHOD_DIALOG)}
			/>

			<AddProfileDialog
				open={addProfileDialog}
				onClose={() => handleDialogClose(SET_ADD_PROFILE_DIALOG)}
			/>
			<ComingSoonDialog
				open={comingSoonDialog}
				onClose={() => handleDialogClose(SET_COMING_SOON_DIALOG)}
			/>

			{userInfoPopper && <UserInfoPopper {...userInfoPopper} />}
		</>
	);
};

export default Dialogs;

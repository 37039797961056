import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import SubProfileSelectionDialog, {
	SubProfileType,
} from "modules/Settings/SubProfilesDialog/SubProfilesDialog";
import SocialProfile from "modules/Settings/SocialProfile/SocialProfile";
import { StoreState } from "../../../../../setup/store";
import SocialProfileType from "types/SocialProfile";
import useProfileDetailsStyles from "../../styles/social-profile-details";
import { Box } from "@material-ui/core";
import { getSocialProfileRoute } from "setup/router/utils";

interface Props {
	currentProfile: SocialProfileType;
}

const SubProfilesCard = ({ currentProfile }: Props) => {
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);
	const [subProfilesDialog, setSubProfilesDialog] = useState<boolean>(false);
	const [subProfileTypes, setSubProfileTypes] = useState<SubProfileType[]>([]);
	const [subProfiles, setSubProfiles] = useState<SocialProfileType[]>([]);
	const { permissions } = useSelector((state: StoreState) => state.socialProfiles);
	const classes = useProfileDetailsStyles();
	const history = useHistory();

	const handlePageClick = (page: SocialProfileType) => {
		history.push(getSocialProfileRoute(page._id!));
	};

	useEffect(() => {
		let pages: SocialProfileType[] = [];

		profiles.forEach((profile) => {
			if (profile.parentProfile === currentProfile._id) {
				pages.push(profile);
			}
		});

		setSubProfiles([...pages]);
	}, [profiles, currentProfile]);

	useEffect(() => {
		let subProfileTypes: SubProfileType[] = [];
		if (currentProfile.network === "facebook") {
			permissions.forEach((permission) => {
				if (permission.permission === "pages_manage_posts") {
					subProfileTypes.push("page");
				} else if (permission.permission === "instagram_content_publish") {
					subProfileTypes.push("instagram");
				}
			});
		} else if (currentProfile.network === "linkedin") {
			subProfileTypes = ["page"];
		}

		setSubProfileTypes([...subProfileTypes]);
	}, [permissions]);

	return (
		<div className={classes.card} style={{ marginLeft: 6, width: "35%" }}>
			{/* Pages Dialog */}
			<SubProfileSelectionDialog
				currentProfile={currentProfile}
				open={subProfilesDialog}
				handleClose={() => setSubProfilesDialog(false)}
				profileTypes={subProfileTypes}
			/>

			<Box display="flex" flexDirection="row" alignItems="center">
				<div className={classes.pagesHeader}>Connected Pages</div>

				<div onClick={() => setSubProfilesDialog(true)} className={classes.addPageContainer}>
					+ Add a Page
				</div>
			</Box>

			{subProfiles.length === 0 && (
				<div style={{ paddingTop: 52 }} className={classes.centeredContainer}>
					No profiles found
				</div>
			)}

			<div className={classes.pagesContainer}>
				{subProfiles.map((subProfile, index) => (
					<div key={index} style={{ width: "50%" }}>
						<SocialProfile size="sm" socialProfile={subProfile} onClick={handlePageClick} />
					</div>
				))}
			</div>
		</div>
	);
};

export default SubProfilesCard;

import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import Resizer from "react-image-file-resizer";
import config from "config";
import { getVideoThumbnail } from "helpers/utils";
import { UploadImageFunc, UploadMediaArrayFunc, UploadVideoFunc } from "types/ReduxActions";
import { Media } from "types/Task";

export type FileUploadProgress = {
	fileType: "video" | "image";
	index: number;
	progress: number;
};

export type VideoUploadResult = {
	key: string;
	thumbnail: string;
};

export const resizeFile = (file: File) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			1000,
			1000,
			"JPEG",
			80,
			0,
			(uri) => {
				resolve(uri as Blob);
			},
			"blob"
		);
	});

export const uploadImage: UploadImageFunc = async (file, onUploadProgress) => {
	const blob = await resizeFile(file);

	const formData = new FormData();
	formData.append("file", blob as Blob);

	const result = await axios.post(`${config.apiUrl}/images`, formData, {
		onUploadProgress,
	});

	return result.data[0].key;
};

export const uploadVideo: UploadVideoFunc = async (file, onUploadProgress) => {
	const formData = new FormData();
	formData.append("file", file);

	const thumbnail = await getVideoThumbnail(file);

	const thumbnailForm = new FormData();
	thumbnailForm.append("file", thumbnail as File);

	const imageUploadResult = await axios.post(`${config.apiUrl}/images`, thumbnailForm, {});

	const result = await axios.post(`${config.apiUrl}/videos`, formData, {
		onUploadProgress,
	});

	return {
		key: result.data[0].key,
		thumbnail: imageUploadResult.data[0].key,
	};
};

/** If the media value is an object (File), upload it and replace the value with the S3 object key */
export const uploadMediaArray: UploadMediaArrayFunc = async (media, onProgressUpdate) => {
	let mediaPayload: Media[] = [];
	console.log(media)
	const uploadMedia = media.map((media: Media, index: number) => {
		return new Promise(async (resolve) => {
			try {
				if (typeof media.value === "object") {
					if (media.type === "image") {
						const key = await uploadImage(media.value, (progress) => {
							onProgressUpdate?.({
								fileType: "image",
								index,
								progress: (progress.loaded / progress.total) * 100,
							});
						});
						mediaPayload.push({ type: "image", value: key });
					} else {
						const { key, thumbnail } = await uploadVideo(media.value, (progress) => {
							onProgressUpdate?.({
								fileType: "image",
								index,
								progress: (progress.loaded / progress.total) * 100,
							});
						});
						mediaPayload.push({
							type: "video",
							value: key,
							thumbnail,
							videoDuration: media.videoDuration,
						});
					}
				} else {
					mediaPayload.push(media);
				}

				resolve(media);
			} catch (e) {
				throw e.response?.data || e;
			}
		});
	});

	await Promise.all(uploadMedia);
	return mediaPayload;
};

export const handleUploadProgressChange = (
	mediaUploadProgress: FileUploadProgress[],
	setMediaUploadProgress: Dispatch<SetStateAction<FileUploadProgress[]>>,
	newProgress: FileUploadProgress
) => {
	let fileFound = false;

	mediaUploadProgress.forEach((fileProgress, index) => {
		if (
			fileProgress.fileType === newProgress.fileType &&
			fileProgress.index === newProgress.index
		) {
			mediaUploadProgress[index] = { ...newProgress };
			fileFound = true;
		}
	});

	if (!fileFound) {
		mediaUploadProgress.push(newProgress);
	}

	setMediaUploadProgress([...mediaUploadProgress]);
};

import {
	createStyles,
	Dialog,
	LinearProgress,
	makeStyles,
	Theme,
	withStyles,
} from "@material-ui/core";
import { CloseIcon } from "assets";
import { placeholderHashtagStats } from "../../../mock/placeholderData";
import useStyles from "./styles";

const BorderLinearProgress = withStyles((theme: Theme) =>
	createStyles({
		root: {
			height: 10,
			width: "100%",
			borderRadius: 5,
			marginLeft: 24,
			marginRight: 24,
		},
		colorPrimary: {
			backgroundColor: "transparent",
		},
		bar: {
			borderRadius: 5,
			backgroundColor: theme.palette.primary.main,
		},
	})
)(LinearProgress);

export default function HashtagsStats({
	open,
	handleClose,
}: {
	open: boolean;
	handleClose: () => void;
}) {
	const classes = useStyles();

	// TODO: Replace placeholders
	return (
		<Dialog
			open={open}
			classes={{
				paper: classes.paper,
			}}
			onClose={handleClose}
		>
			<div className={classes.dialogContainer}>
				<div className={classes.header}>
					<span className={classes.headerText}>Most Popular Hashtags for Last Week</span>

					<div className={classes.closeIconContainer} onClick={handleClose}>
						<img src={CloseIcon} height={13} />
					</div>
				</div>

				<div className={classes.hashtags}>
					{placeholderHashtagStats.map((hashtag, index) => (
						<div className={classes.hashtag} key={index}>
							<span style={{ width: "30%" }}>{hashtag.title}</span>
							<BorderLinearProgress
								style={{ opacity: 1 - parseFloat(`0.${index}`) }}
								variant="determinate"
								value={
									((placeholderHashtagStats.length - index) / placeholderHashtagStats.length) * 100
								}
							/>
							<span style={{ marginLeft: "auto", width: "fit-content" }}>{hashtag.shares}</span>
						</div>
					))}
				</div>
			</div>
		</Dialog>
	);
}

import HomeIcon from "assets/home.png";
import SelectedHomeIcon from "assets/home-selected.png";
import PostingIcon from "assets/posting.png";
import SelectedPostingIcon from "assets/posting-selected.png";
import CalendarIcon from "assets/calendar.png";
import SelectedCalendarIcon from "assets/calendar-selected.png";
import ReplyIcon from "assets/reply.png";
import SelectedReplyIcon from "assets/reply-selected.png";
import ListenIcon from "assets/listen.png";
import SelectedListenIcon from "assets/listen-selected.png";
import AnalyticsIcons from "assets/analytics.png";
import SelectedAnalyticsIcon from "assets/analytics-selected.png";
import { SelectedSettingsIcon, SettingsIcon } from "assets";

export type DrawerListItem = {
	icon: string;
	selectedIcon: string;
	title: string;
	url: string;
	subUrls: string[];
	disabled?: boolean;
	isComingSoon?: boolean;
};

export const topDrawerList: DrawerListItem[] = [
	{
		icon: HomeIcon,
		selectedIcon: SelectedHomeIcon,
		title: "Home",
		url: "/home",
		subUrls: ["/"],
	},
	{
		icon: PostingIcon,
		selectedIcon: SelectedPostingIcon,
		title: "Posting",
		url: "/posts/schedule",
		subUrls: ["/posts/new", "/posts/archive", "/posts/edit", "/posts/drafts", "/posts/schedule"],
	},
	{
		icon: CalendarIcon,
		selectedIcon: SelectedCalendarIcon,
		title: "Calendar",
		url: "/calendar",
		subUrls: [],
	},
	{
		icon: ReplyIcon,
		selectedIcon: SelectedReplyIcon,
		title: "Reply",
		url: "/reply",
		subUrls: [],
	},
	{
		icon: ListenIcon,
		selectedIcon: SelectedListenIcon,
		disabled: process.env.NODE_ENV !== "development",
		title: "Listen",
		url: "/listen",
		isComingSoon: process.env.NODE_ENV !== "development",
		subUrls: [],
	},
	{
		icon: AnalyticsIcons,
		disabled: process.env.NODE_ENV !== "development",
		selectedIcon: SelectedAnalyticsIcon,
		title: "Analytics",
		url: "/analytics",
		isComingSoon: process.env.NODE_ENV !== "development",
		subUrls: [],
	},
];

export const bottomDrawerList: DrawerListItem[] = [
	{
		icon: SettingsIcon,
		selectedIcon: SelectedSettingsIcon,
		title: "Settings",
		url: "/settings",
		subUrls: [""],
	},
];

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 16,
	},

	dataHeaderItem: {
		width: "16.7%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: 65,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	postInfoContainer: {
		width: "35%",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: 12,
		borderRadius: 12,
		marginRight: 10,
		height: 60,
		paddingLeft: 16,
		paddingRight: 16,
	},

	postDataContainer: {
		width: "65%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "white",
		borderRadius: 12,
		padding: 10,
		height: 60,
	},

	postAttachment: {
		width: 42,
		height: 42,
		borderRadius: 10,
		marginRight: 10,
	},

	postContent: {
		color: theme.palette.text.primary,
		width: 150,
		fontSize: 11,
		fontWeight: 300,
	},

	dateContainer: {
		color: theme.palette.text.primary,
		fontSize: 11,
	},

	profileInfo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 6,
		marginLeft: "auto",
		width: "fit-content",
	},

	profileImage: {
		height: 16,
		width: 16,
		borderRadius: "50%",
		marginLeft: 4,
		marginRight: 4,
	},

	profileUsername: {
		fontSize: 11,
		color: theme.palette.text.primary,
		fontWeight: 500,
	},
}));

export default useStyles;

import { makeStyles, Typography } from "@material-ui/core";
import { CSSProperties, ReactNode } from "react";

interface Props {
	children: ReactNode;
	className?: string;
	style?: CSSProperties;
	onClick?: () => void;
}

const useState = makeStyles((theme) => ({
	text: {
		color: theme.palette.text.secondary,
		fontWeight: 500,
		fontSize: 14,
		textDecoration: "underline",
		cursor: "pointer",
	},
}));

const LinkText = (props: Props) => {
	const classes = useState();

	return (
		<Typography {...props} className={classes.text}>
			{props.children}
		</Typography>
	);
};

export default LinkText;

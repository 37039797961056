import { makeStyles } from "@material-ui/core";

const useGeneralSearchPageStyles = makeStyles((theme) => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "90vh",
		paddingTop: 12,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	tabsContainer: {
		width: "fit-content",
		marginTop: 8,
	},

	resultSection: {
		marginTop: 32,
	},

	sectionTitle: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.text.primary,
		marginBottom: 16,
		marginLeft: 8,
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 62,
	},

	searchInputContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 24,
		marginTop: 16,
	},

	tasksSearchResultContainer: {
		padding: 16,
		paddingLeft: 24,
		paddingRight: 24,
		backgroundColor: "white",
		borderRadius: 16,
	},
}));

export default useGeneralSearchPageStyles;

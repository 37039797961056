import React, { useState } from "react";
import MetaTagsHeader from "../../components/Navigation/Components/MetaTags";
import Container from "../../components/Navigation/Container";
import AudienceAnalytics from "modules/Analytics/AudienceAnalytics";
import CountryAnalytics from "modules/Analytics/CountryAnalytics";
import HashtagsAnalytics from "modules/Analytics/HashtagsAnalytics";
import MapAnalytics from "modules/Analytics/MapAnalytics";
import useAnalyticsStyles from "modules/Analytics/styles/analytics-styles";
import TimeAnalytics from "modules/Analytics/TimeAnalytics";
import usePageStyles from "../GeneralSearch/page-styles";
import Header from "./Header";

export type AnalyticsViewType = "general" | "bySocialProfile" | "byPost" | "byCampaign" | "today";

const AnalyticsPage = () => {
	const [selectedView, setSelectedView] = useState<AnalyticsViewType>("general");
	const classes = useAnalyticsStyles();
	const pageClasses = usePageStyles();

	return (
		<Container page="Analytics">
			<MetaTagsHeader title="Analytics | HashtagHero" />
			<div className={pageClasses.pageContainer}>
				<div className={pageClasses.innerContainer}>
					<Header selectedView={selectedView} setSelectedView={setSelectedView} />

					<div className={classes.sectionTitle} style={{ paddingTop: 24 }}>
						Country Analytics
					</div>
					<div className={classes.section}>
						<CountryAnalytics />
						<MapAnalytics />
					</div>

					<div className={classes.sectionTitle} style={{ paddingTop: 24 }}>
						Hashtags Analytics
					</div>
					<div className={classes.section}>
						<HashtagsAnalytics />
					</div>

					<div className={classes.sectionTitle} style={{ paddingTop: 24 }}>
						Audience Statistics
					</div>
					<div className={classes.section}>
						<AudienceAnalytics />
					</div>

					<div className={classes.sectionTitle} style={{ paddingTop: 24 }}>
						Time Analytics
					</div>
					<div className={classes.section}>
						<TimeAnalytics />
					</div>
				</div>
			</div>
		</Container>
	);
};

export default AnalyticsPage;

import { makeStyles } from "@material-ui/core";
import Post, { PostPayload } from "types/Post";
import FollowButton from "assets/twitter-follow.png";
import LikeIcon from "assets/twitter-like.png";
import RetweetIcon from "assets/twitter-retweet.png";
import ShareIcon from "assets/twitter-share.png";
import SocialProfile from "types/SocialProfile";
import moment from "moment";
import clsx from "clsx";
import SocialPreviewMedia from "./SocialPreviewMedia";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";

interface Props {
	postPayload?: PostPayload | Post;
	scheduled?: boolean;
	width?: number;
	className?: string;
}

const useStyles = makeStyles(() => ({
	container: {
		minWidth: 400,
		boxShadow: "2px 2px 12px rgba(62, 76, 85, 0.1)",
		fontFamily: "Helvetica",
		padding: 16,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingBottom: 16,
	},

	headerTextContainer: {
		display: "flex",
		flexDirection: "column",
	},

	username: {
		color: "black",
		fontWeight: 700,
		fontSize: 14,
	},

	usertag: {
		color: "#6A7783",
		fontSize: 12,
		fontWeight: 400,
	},

	followButtonContainer: {
		marginLeft: "auto",
	},

	followButton: {
		height: 30,
	},

	postContent: {
		whiteSpace: "pre-line",
		paddingBottom: 16,
		color: "#3E4C55",
	},

	postImage: {
		width: "100%",
		marginBottom: 16,
		borderRadius: 6,
		maxHeight: 400,
		objectFit: "contain",
	},

	dateString: {
		color: "#6A7783",
		fontSize: 14,
	},

	postActions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingTop: 8,
	},

	actionIcon: {
		height: 12,
		marginRight: 24,
	},
}));

export default function TwitterPreview(props: Props) {
	const classes = useStyles();
	const { postPayload, width, className } = props;

	const profile = postPayload?.socialProfiles?.filter(
		(profile: SocialProfile) => profile.network === "twitter"
	)[0];

	if (!profile) return <></>;

	return (
		<div style={{ width: width || "" }} className={clsx(className, classes.container)}>
			<div className={classes.header}>
				{profile && (
					<SocialProfilePicture
						profile={profile}
						style={{ width: 40, height: 40, marginRight: 8 }}
					/>
				)}
				<div className={classes.headerTextContainer}>
					<span className={classes.username}>{profile?.displayName}</span>
					<span className={classes.usertag}>@{profile.username}</span>
				</div>
				<div className={classes.followButtonContainer}>
					<img src={FollowButton} className={classes.followButton} />
				</div>
			</div>

			<div className={classes.postContent}>{postPayload?.content}</div>

			<SocialPreviewMedia className={classes.postImage} post={postPayload} />

			<span className={classes.dateString}>
				{moment(postPayload?.schedule || new Date()).format("dddd hh:mm:A")}
			</span>
			<div className={classes.postActions}>
				<img className={classes.actionIcon} src={ShareIcon} />
				<img className={classes.actionIcon} src={RetweetIcon} />
				<img style={{ marginRight: 8 }} className={classes.actionIcon} src={LikeIcon} />
				<span style={{ color: "#6A7783" }}>542</span>
			</div>
		</div>
	);
}

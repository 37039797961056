import { Dispatch } from "redux";
import config, { ExternalLogoUrl, taskQuery } from "config";
import { StoreState } from "../../../../setup/store";
import Task, { TaskComment } from "types/Task";
import { LIST_TASKS, SET_TASK_COMMENTS } from "../../../reducers/tasks";

export const handleReceivedComment = (comment: TaskComment) => {
	return (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { selectedTask, taskComments, taskCommentsTotal, tasks, currentUserTasks, activeTasks } =
			getState().tasks;
		const { currentTeamId, teams } = getState().teams;
		const { userData } = getState().user;
		const currentTeam = teams.find((team) => team._id === currentTeamId);

		if (!userData || currentTeamId !== comment.team) {
			return;
		}

		const { assignedTasksCommentsNotifs } = userData.settings || {};
		const commentUser = currentTeam?.users.find(
			(teamUser) => teamUser.user?._id === comment.user
		)?.user;
		if (!commentUser) return;

		if (selectedTask?._id === comment.task) {
			dispatch({
				type: SET_TASK_COMMENTS,
				payload: {
					taskComments: [comment, ...taskComments],
					taskCommentsTotal: taskCommentsTotal + 1,
				},
			});
		} else {
			const updateUnreadComments = (task: Task) => {
				if (task._id === comment.task) {
					task.comments = [...(task.comments || []), comment];
				}

				return task;
			};

			dispatch({
				type: LIST_TASKS,
				payload: {
					tasks: tasks.map((task) => updateUnreadComments(task)),
					activeTasks: activeTasks.map((task) => updateUnreadComments(task)),
					currentUserTasks: currentUserTasks.map((task) => updateUnreadComments(task)),
				},
			});

			if (!assignedTasksCommentsNotifs || assignedTasksCommentsNotifs === "enabled") {
				const { firstName, lastName } = commentUser;
				const desktopNotif = new Notification(
					`${firstName} ${lastName} commented on a task assigned to you`,
					{
						icon: ExternalLogoUrl,
					}
				);

				desktopNotif.onclick = () => {
					window.open(`${config.clientUrl}?${taskQuery}=${comment.task}`);
				};
			}
		}
	};
};

export const handleDeletedComment = (deletedComment: TaskComment) => {
	return (dispatch: Dispatch, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { selectedTask, taskComments, taskCommentsTotal, tasks, currentUserTasks, activeTasks } =
			getState().tasks;

		if (deletedComment.team !== currentTeamId) {
			return;
		}

		const removeUnreadComment = (task: Task) => {
			if (
				typeof deletedComment.task !== "string" &&
				task._id === (deletedComment.task as unknown as Task)._id
			) {
				task.comments = task.comments?.filter((comment) => comment._id !== deletedComment._id);
			}

			return task;
		};

		if (selectedTask?._id === deletedComment.task) {
			let commentFound = false;

			taskComments.forEach((taskComment, index) => {
				if (taskComment._id === deletedComment._id) {
					commentFound = true;
					taskComments.splice(index, 1);
				}
			});

			dispatch({
				type: SET_TASK_COMMENTS,
				payload: {
					taskComments,
					taskCommentsTotal: commentFound ? taskCommentsTotal - 1 : taskCommentsTotal,
				},
			});
		} else {
			dispatch({
				type: LIST_TASKS,
				payload: {
					tasks: tasks.map((task) => removeUnreadComment(task)),
					activeTasks: activeTasks.map((task) => removeUnreadComment(task)),
					currentUserTasks: currentUserTasks.map((task) => removeUnreadComment(task)),
				},
			});
		}
	};
};

import useStyles from "./styles";
import Container from "../../components/Navigation/Container";
import TopSummary from "./TopSummary/TopSummary";
import MetaTagsHeader from "../../components/Navigation/Components/MetaTags";
import { useTranslation } from "react-i18next";
import TasksCard from "./TasksCard/index";
import { Box } from "@material-ui/core";
import MyTasks from "./MyTasks";
import TeamProductivity from "./TeamProductivity";

const HomePage = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Container page={t("home")}>
			<MetaTagsHeader title={`${t("home")} | HashtagHero`} />
			<Box className={classes.pageContainer}>
				<Box className={classes.innerContainer}>
					<TopSummary />
					<Box display="flex" style={{ width: "100%" }}>
						<TasksCard />
						<Box style={{ width: "35%", height: "100%" }}>
							<MyTasks />
							<TeamProductivity />
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};

export default HomePage;

import React, { MouseEvent, useState } from "react";
import Post from "types/Post";
import ClockIcon from "assets/clock-icon.png";
import Button from "components/Basic/Button";
import usePostStyles from "./shared-styles";
import { useHistory } from "react-router";
import useStyles from "./styles/table-post";
import { ImagePlaceholder, ProfilePicturePlaceholder } from "../../assets";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import moment from "moment";
import { getPostImage } from "../SocialPreviews/utils";
import { useEffect } from "react";
import { Box } from "@material-ui/core";
interface Props {
	post: Post;
	onClick?: (post: Post) => void;
}

function TablePost(props: Props) {
	const [image, setImage] = useState<string>("");
	const classes = useStyles();
	const sharedPostClasses = usePostStyles();
	const history = useHistory();

	const { post, onClick } = props;

	const handleClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		onClick && onClick(post);
	};

	const handleImageLoadError = () => {
		const imageElement = document.getElementById(`${post._id}-image`) as HTMLImageElement;
		if (!imageElement) return;

		imageElement.src = ImagePlaceholder;
	};

	useEffect(() => {
		getPostImage(post).then((image) => {
			setImage(image);
		});
	}, [post]);

	return (
		<div onClick={handleClick} className={classes.container}>
			<div className={classes.left}>
				<div className={classes.header}>
					<div className={classes.userInfo}>
						<img
							id={`${post._id}-image`}
							src={
								(post.socialProfiles && post.socialProfiles[0].picture) || ProfilePicturePlaceholder
							}
							className={classes.userImage}
							alt="profile"
							onError={handleImageLoadError}
						/>
						<span className={classes.username}>
							{post.socialProfiles && post.socialProfiles[0].displayName}
						</span>
					</div>
					<div className={classes.socialIcon}>
						{post.socialProfiles && (
							<NetworkIcon network={post.socialProfiles[0].network} size={20} />
						)}
					</div>
				</div>

				{post.content && (
					<div className={classes.content}>
						{post.content.length > 75 ? post.content.substr(0, 75) + "..." : post.content}
					</div>
				)}

				<div className={classes.buttons}>
					<div className={sharedPostClasses.scheduleBox}>
						<img src={ClockIcon} className={sharedPostClasses.clockIcon} alt="clock" />
						<span className={sharedPostClasses.schedule}>
							{moment(post.scheduledAt).format("hh:mm A")}
						</span>
					</div>
					<Box onClick={(e) => e.stopPropagation()}>
						<Button
							onClick={() => history.push(`/posts/edit/${post._id}`)}
							style={{ width: 45, height: 25, fontSize: 12, borderRadius: 2 }}
						>
							Edit
						</Button>
					</Box>
				</div>
			</div>

			<span>
				<img src={image || ImagePlaceholder} className={classes.attachment} alt="attachment" />
			</span>
		</div>
	);
}

export default TablePost;

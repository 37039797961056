import React from "react";
import { AnalyticsViewType } from ".";
import Button from "components/Basic/Button";
import DurationInput from "components/Basic/Inputs/DurationInput";
import useStyles from "./styles/page-styles";

interface Props {
	selectedView: AnalyticsViewType;
	setSelectedView: (view: AnalyticsViewType) => void;
}

const Header = (props: Props) => {
	const classes = useStyles();
	const { selectedView, setSelectedView } = props;

	return (
		<div className={classes.headerContainer}>
			<div className={classes.buttonsContainer}>
				<Button
					className={classes.button}
					secondary
					selected={selectedView === "general"}
					onClick={() => setSelectedView("general")}
				>
					General
				</Button>

				<Button
					className={classes.button}
					secondary
					selected={selectedView === "bySocialProfile"}
					onClick={() => setSelectedView("bySocialProfile")}
				>
					By Social Profile
				</Button>

				<Button
					className={classes.button}
					secondary
					selected={selectedView === "byPost"}
					onClick={() => setSelectedView("byPost")}
				>
					By Post
				</Button>

				<Button
					className={classes.button}
					onClick={() => setSelectedView("byCampaign")}
					secondary
					selected={selectedView === "byCampaign"}
				>
					By Campaign
				</Button>
			</div>
			<div className={classes.rightSection}>
				<DurationInput height={36} width={220} />
				<Button secondary style={{ width: 91, marginLeft: 16 }}>
					Today
				</Button>
			</div>
		</div>
	);
};

export default Header;

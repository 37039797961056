import { makeStyles } from "@material-ui/core";

const useCampaignStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "white",
		borderRadius: 5,
		width: 200,
		paddingTop: 4,
	},

	radioOption: {
		borderTop: "1px solid #D5E1F5",
		color: theme.palette.text.primary,
		fontSize: 12,
		paddingLeft: 12,
		paddingRight: 12,
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	option: {
		borderTop: "1px solid #D5E1F5",
		color: theme.palette.text.primary,
		fontSize: 12,
		padding: 12,
		paddingTop: 8,
		paddingBottom: 8,
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "rgb(247, 247, 247)",
		},
	},

	checkboxContainer: {
		marginLeft: "auto",
		width: "fit-content",
	},
}));

export default useCampaignStyles;

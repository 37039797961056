import axios from "axios";
import { Dispatch } from "react";

import { setSnackbarState } from "../snackBar";
import config from "config";
import { SubmitCalendarEventFunc } from "types/ReduxActions";
import {
	CREATE_EVENT_ERROR,
	CREATE_EVENT_LOADING,
	CREATE_EVENT_SUCCESS,
	LIST_DAY_CALENDAR_EVENTS,
	SET_MONTH_CALENDAR_DATA,
	SET_WEEK_CALENDAR_DATA,
} from "../../reducers/calendar-events";
import { StoreState } from "../../../setup/store";
import CalendarEvent from "types/CalendarEvent";

export const submitCalendarEvent: SubmitCalendarEventFunc = (payload, selectedEventId) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { dayCalendarEvents, monthCalendarData, weekCalendarData } = getState().calendarEvents;
		const { title, networks, scheduledAt } = payload;

		dispatch({
			type: CREATE_EVENT_LOADING,
			payload: true,
		});

		const errors: { [index: string]: string } = {};
		if (!networks || networks.length < 1) {
			errors.networks = "Network Required";
		}

		if (!title) {
			errors.title = "Title required";
		} else if (title.length > 50) {
			errors.title = "Title cannot exceed 50 characters";
		}

		if (!scheduledAt) {
			errors.scheduledAt = "Schedule required";
		}

		if (Object.keys(errors).length > 0) {
			return dispatch({
				type: CREATE_EVENT_ERROR,
				payload: errors,
			});
		}

		let url = `${config.apiUrl}/calendarEvents?teamId=${currentTeamId}`;

		if (selectedEventId) {
			url = `${config.apiUrl}/calendarEvents/${selectedEventId}?teamId=${currentTeamId}`;
		}

		try {
			let updatedEvent: CalendarEvent | null = null;
			if (selectedEventId) {
				const result = await axios.put(url, payload);
				updatedEvent = result.data;
				dispatch(setSnackbarState(true, "success", "Calendar Event updated successfully!"));
			} else {
				const result = await axios.post(url, payload);
				updatedEvent = result.data;

				dispatch(setSnackbarState(true, "success", "Calendar Event created successfully!"));
			}

			if (updatedEvent) {
				const findUpdatedAndReplace = (calendarEvents: CalendarEvent[]) => {
					let found = false;

					calendarEvents.forEach((event, index) => {
						if (event._id === selectedEventId) {
							found = true;
							calendarEvents[index] = updatedEvent!;
						}
					});

					if (!found) {
						calendarEvents.push(updatedEvent!);
					}

					return calendarEvents;
				};
				dispatch({
					type: LIST_DAY_CALENDAR_EVENTS,
					payload: findUpdatedAndReplace(dayCalendarEvents),
				});

				dispatch({
					type: SET_MONTH_CALENDAR_DATA,
					payload: {
						...(monthCalendarData || {}),
						calendarEvents: findUpdatedAndReplace(monthCalendarData?.calendarEvents || []),
					},
				});

				dispatch({
					type: SET_WEEK_CALENDAR_DATA,
					payload: {
						...(weekCalendarData || {}),
						calendarEvents: findUpdatedAndReplace(weekCalendarData?.calendarEvents || []),
					},
				});
			}

			dispatch({
				type: CREATE_EVENT_SUCCESS,
				payload: true,
			});
		} catch (err) {
			console.log(err);
			dispatch({
				type: CREATE_EVENT_ERROR,
				payload: err.response.data,
			});
		}
	};
};

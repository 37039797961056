import axios from "axios";
import { Dispatch } from "react";
import config from "config";

import { StoreState } from "../../../setup/store";
import { ListInstagramAccounts, UpdateInstagramAccounts } from "types/ReduxActions";
import { setSnackbarState } from "../snackBar";
import { listSocialProfiles } from "./socialProfiles";

export const listInstagramAccounts: ListInstagramAccounts = (profileId, callback) => {
	return async (dispatch: Dispatch<any>) => {
		try {
			const result = await axios.get(
				`${config.apiUrl}/socialProfiles/${profileId}/instagram/accounts`
			);

			callback(result.data);
		} catch (e) {
			console.log(e);
			callback([]);
			dispatch(setSnackbarState(true, "error", "Error loading accounts, try again later."));
		}
	};
};

// To add an instagram account, both the socialId of this account and the page ID of the associated page is required
export const updateConnectedInstagramAccounts: UpdateInstagramAccounts = (
	currentProfile,
	checkedAccounts,
	callback
) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { profiles } = getState().socialProfiles;

		let disconnectedIds: string[] = [];
		let connectedIds: string[] = [];

		// Find disconnected profiles
		profiles.forEach((profile) => {
			if (
				profile.parentProfile === currentProfile._id &&
				profile.network === "instagram" &&
				!checkedAccounts.filter((account) => account.socialId !== profile.socialId)[0]
			) {
				disconnectedIds.push(profile.socialId || "");
			}
		});

		// Find newly connected profiles
		checkedAccounts.forEach((account) => {
			if (profiles.filter((profile) => profile.socialId === account.socialId).length === 0) {
				connectedIds.push(account.socialId);
			}
		});

		// Connect profiles
		await Promise.all(
			connectedIds.map(async (socialId) => {
				let pageId = checkedAccounts.filter((account) => account.socialId === socialId)[0].pageId;
				try {
					await axios.get(
						`${config.apiUrl}/socialProfiles/${currentProfile._id}/instagram/accounts/connect/${pageId}/${socialId}`
					);
				} catch (e) {
					if (e.response.data.error === "ProfilesLimitReached") {
						dispatch(setSnackbarState(true, "error", "ProfilesLimitReached"));
					} else {
						dispatch(setSnackbarState(true, "error", "ConnectPageError"));
					}
				}
			})
		);

		// Disconnect profiles
		await Promise.all(
			disconnectedIds.map(async (socialId) => {
				try {
					await axios.delete(`${config.apiUrl}/socialProfiles/${socialId}?idType=social`, {});
				} catch (e) {
					dispatch(setSnackbarState(true, "error", "Error disconnecting page..."));
				}
			})
		);

		dispatch(
			listSocialProfiles(() => {
				if (callback) callback();
			})
		);
	};
};

import { makeStyles } from "@material-ui/core";
import React from "react";

interface Props {}

const useStyles = makeStyles((theme) => ({
	clockCircle: {
		borderRadius: "50%",
		backgroundColor: "white",
		width: 180,
		height: 180,
		border: "5px solid #868C91",
		position: "relative",
	},

	clockText: {
		color: theme.palette.text.primary,
		fontSize: 14,
		position: "absolute",
	},
}));

const ClockChart = ({}: Props) => {
	const classes = useStyles();

	return (
		<div className={classes.clockCircle}>
			<div style={{ left: "46%", top: 12 }} className={classes.clockText}>
				12
			</div>

			<div style={{ left: "45.5%", top: 42 }} className={classes.clockText}>
				PM
			</div>

			<div style={{ left: 12, top: "46%" }} className={classes.clockText}>
				9
			</div>

			<div style={{ right: 12, top: "46%" }} className={classes.clockText}>
				3
			</div>

			<div style={{ bottom: 12, left: "46%" }} className={classes.clockText}>
				6
			</div>
		</div>
	);
};

export default ClockChart;

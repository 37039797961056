import { useTranslation } from "react-i18next";
import { getSettingPageRoute, selectedSettingTabQuery, SettingsTab } from "..";
import Button from "components/Basic/Button";
import { useQuery } from "helpers/utils";
import useHeaderStyle from "./styles/header";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import AttentionIconSvg from "assets/SVG/AttentionIconSvg";
import { Box, Tooltip } from "@material-ui/core";
import { isProfileDisconnected } from "redux/actions/socialProfiles/socialProfiles";

const tabs: SettingsTab[] = [
	"myAccount",
	"socialProfiles",
	"workspaces",
	"billingAndPayments",
	"notifications",
	"localization",
	"faq",
];

const Header = () => {
	const classes = useHeaderStyle();
	const query = useQuery();
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const { t } = useTranslation("settings");

	const hasDisconnectedSocialProfile = useMemo(
		() => profiles.find(isProfileDisconnected),
		[profiles]
	);

	return (
		<div className={classes.container}>
			{tabs.map((tab, index) => {
				const selected =
					query.get(selectedSettingTabQuery) === tab ||
					(!query.get(selectedSettingTabQuery) && tab === "myAccount");

				if (tab === "billingAndPayments" && currentTeamRole !== "owner") {
					return <></>;
				}

				return (
					<Box position="relative">
						{tab === "socialProfiles" && hasDisconnectedSocialProfile && (
							<Tooltip title={t("youHaveDisconnectedProfile") as string}>
								<Box style={{ position: "absolute", right: 4, top: 0, zIndex: 999 }}>
									<AttentionIconSvg size={20} />
								</Box>
							</Tooltip>
						)}
						<Link style={{ textDecoration: "none" }} to={getSettingPageRoute(tab)}>
							<Button
								secondary
								disabled={selected}
								style={{ marginRight: 12, width: 150 }}
								key={index}
							>
								{t(tab)}
							</Button>
						</Link>
					</Box>
				);
			})}
		</div>
	);
};

export default Header;

const CheckIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || "32"}
			height={size || "32"}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.3397 15.8457L7.98614 15.4922L7.63259 15.8457L5.74697 17.7314L5.39342 18.0849L5.74697 18.4385L12.3466 25.0381L12.7002 25.3917L13.0537 25.0381L14.5858 23.5061L14.9394 23.1525L15.2929 22.799L26.2531 11.8388L26.6066 11.4852L26.2531 11.1317L24.3674 9.24606L24.0139 8.8925L23.6603 9.24606L12.7002 20.2062L8.3397 15.8457Z"
				fill={color || "#F04755"}
				stroke={color || "#F04755"}
			/>
		</svg>
	);
};

export default CheckIconSvg;

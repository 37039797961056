import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { geoPath } from "d3-geo";
import { useState } from "react";
import { geoUrl } from "helpers/map-utils";
import { countryAnalytics } from "../../mock/analyticsPlaceholderData";

type AnalyticsMapMarker = {
	color: string;
	coordinates: [number, number];
	countryName: string;
};

const MapAnalytics = () => {
	const [markers, setMarkers] = useState<AnalyticsMapMarker[]>([]);
	const [markersLoaded, setMarkersLoaded] = useState<boolean>(false);

	const getMarkers = (geographies: any[]) => {
		let markers: AnalyticsMapMarker[] = [];

		geographies.forEach((geo: any) => {
			const country = countryAnalytics.find((item) => item.country === geo.properties.NAME);
			if (country) {
				let markerColor: string = "";
				const coordinates = geoPath().centroid(geo);
				if (country.views < 10000) {
					markerColor = "#F2B04C";
				} else if (country.views < 100000) {
					markerColor = "#F2724A";
				} else if (country.views > 100000) {
					markerColor = "#E1353F";
				}

				markers.push({
					color: markerColor,
					coordinates,
					countryName: geo.properties.NAME,
				});
			}
		});

		setMarkers([...markers]);
		setMarkersLoaded(true);
	};

	return (
		<div style={{ width: "50%", height: 350, marginTop: -60 }}>
			<ComposableMap>
				<Geographies geography={geoUrl}>
					{({ geographies }) => {
						if (!markersLoaded) {
							getMarkers(geographies);
						}

						return (
							<>
								{geographies.map((geo) => {
									return (
										<Geography
											key={geo.rsmKey}
											geography={geo}
											style={{
												default: { fill: "#B6BFC4", outline: "none" },
												hover: { outline: "none", fill: "#B6BFC4" },
												pressed: { outline: "none", fill: "#B6BFC4" },
											}}
										/>
									);
								})}
							</>
						);
					}}
				</Geographies>

				{markers.map((marker, index) => (
					<Marker key={index} coordinates={marker.coordinates}>
						<g
							fill="none"
							stroke={marker.color}
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
							transform="translate(-12, -24)"
						>
							<circle cx="12" cy="10" r="3" />
							<path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
						</g>
						<text
							textAnchor="middle"
							y={15}
							style={{
								fill: "#5D5A6D",
								fontWeight: 600,
							}}
						>
							{marker.countryName}
						</text>
					</Marker>
				))}
			</ComposableMap>
		</div>
	);
};

export default MapAnalytics;

import { Box } from "@material-ui/core";
import TitleText from "components/Basic/Text/TitleText";
import Dialog from "components/Dialogs/components/Dialog";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	listSubscriptionPlans,
	PaymentPeriod,
	SubscriptionPayload,
} from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import SubscriptionDialogActions from "./components/DialogActions";
import SubscriptionDialogInputs from "./components/DialogInputs";
import SubscriptionDialogSummary from "./components/Summary";
import SubscriptionDialogWarnings from "./components/Warnings";

interface Props {
	open: boolean;
	defaultPlanId?: string;
	defaultPeriod?: PaymentPeriod;
	onClose: () => void;
}

export const SubscriptionDialog = (props: Props) => {
	const { open, defaultPeriod, defaultPlanId, onClose } = props;

	const [payload, setPayload] = useState<Partial<SubscriptionPayload>>({
		paymentPeriod: "monthly",
	});
	const { t } = useTranslation("settings");
	const { plans, currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);

	const planInfo = useMemo(() => {
		return plans.find((plan) => plan.id === payload.planId);
	}, [payload, plans]);

	// validates that the current payload has different values from the current subscription
	// the submit button will be disabled if they both match
	const isSubscriptionUpdated = useMemo(() => {
		if (!currentTeamSubscription || !currentTeamSubscription?.id) {
			return true;
		}

		const {
			planInfo,
			billingPeriodUnit,
			userAddons,
			paymentSourceId: paymentMethodId,
		} = currentTeamSubscription;

		if (
			planInfo.id !== payload.planId ||
			billingPeriodUnit !== payload.paymentPeriod?.split("ly")[0] ||
			userAddons !== payload.userAddons ||
			paymentMethodId !== payload.paymentMethodId
		) {
			return true;
		}

		return false;
	}, [payload, currentTeamSubscription]);

	const dispatch = useDispatch();

	const hasActiveSubscription = useMemo(
		() => Boolean(currentTeamSubscription?.id),
		[currentTeamSubscription]
	);

	useEffect(() => {
		if (open) {
			if (plans.length === 0) {
				dispatch(listSubscriptionPlans());
			}

			// populate the the current team's subscription data to the payload if team has a subscription
			if (
				currentTeamSubscription?.id &&
				!currentTeamSubscription.planInfo.id.includes("Free") &&
				!defaultPlanId
			) {
				const {
					planInfo,
					billingPeriodUnit,
					userAddons,
					paymentSourceId: paymentMethodId,
				} = currentTeamSubscription;

				setPayload({
					planId: planInfo.id.replace("-Yearly", "").replace("-Monthly", ""),
					paymentPeriod: billingPeriodUnit === "month" ? "monthly" : "yearly",
					userAddons: userAddons || 0,
					paymentMethodId,
				});
			} else {
				setPayload({
					planId: defaultPlanId || plans.find((plan) => plan.id.includes("Business"))?.id,
					paymentPeriod: defaultPeriod || "monthly",
					userAddons: 0,
				});
			}
		}
	}, [open]);

	return (
		<Dialog
			containerStyle={{
				width: 1050,
				maxHeight: 800,
				height: "90vh",
				padding: 32,
			}}
			titleStyle={{ marginLeft: 24 }}
			title={hasActiveSubscription ? t("updateSubscriptionPlan") : t("selectSubscriptionPlan")}
			onClose={onClose}
			open={open}
		>
			<TitleText style={{ marginBottom: 42 }}></TitleText>
			<Box display="flex">
				<Box width="60%" marginRight={8} style={{ overflowY: "auto", maxHeight: "100%" }}>
					<SubscriptionDialogInputs payload={payload} setPayload={setPayload} />

					<SubscriptionDialogWarnings />
				</Box>

				{planInfo && (
					<SubscriptionDialogSummary
						period={payload.paymentPeriod || "monthly"}
						userAddons={payload.userAddons || 0}
						plan={planInfo}
					/>
				)}
			</Box>
			<SubscriptionDialogActions
				isSubscriptionUpdated={isSubscriptionUpdated}
				onClose={onClose}
				payload={payload}
			/>
		</Dialog>
	);
};

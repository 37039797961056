import axios from "axios";
import { Dispatch } from "react";
import config from "config";
import { StoreState } from "../../../../setup/store";
import { ListTaskCommentsFunc, ReadTaskCommentsFunc } from "types/ReduxActions";
import Task from "types/Task";
import { LIST_TASKS, SET_TASK_COMMENTS, SET_TASK_COMMENTS_LOADING } from "../../../reducers/tasks";
import { setSnackbarState } from "../../snackBar";

export type ListTaskCommentsFilters = {
	unread?: boolean;
	from?: number;
	pageLimit?: number;
};

export const listTaskComments: ListTaskCommentsFunc = (taskId, filters) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { taskComments } = getState().tasks;
		const { from } = filters;

		if (!from) {
			dispatch({
				type: SET_TASK_COMMENTS_LOADING,
				payload: true,
			});
		}

		try {
			const result = await axios.get(`${config.apiUrl}/tasks/${taskId}/comments`, {
				params: { ...filters, teamId: currentTeamId },
			});

			const { comments: newComments, total } = result.data;

			dispatch({
				type: SET_TASK_COMMENTS,
				payload: {
					taskComments: from ? [...taskComments, ...newComments] : newComments,
					taskCommentsTotal: total,
				},
			});
			if (!filters.unread && !from) {
				dispatch(readTaskComments(taskId));
			}
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "ListCommentsError"));
		}
	};
};

export const readTaskComments: ReadTaskCommentsFunc = (taskId) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { tasks, activeTasks, currentUserTasks } = getState().tasks;

		try {
			await axios.get(`${config.apiUrl}/tasks/${taskId}/comments/read`, {
				params: { teamId: currentTeamId },
			});

			// Filter unread comments from the tasks and activeTasks arrays
			const updateTask = (task: Task) => {
				if (task._id === taskId) task.comments = [];
				return task;
			};

			dispatch({
				type: LIST_TASKS,
				payload: {
					tasks: tasks.map((task) => updateTask(task)),
					activeTasks: activeTasks.map((task) => updateTask(task)),
					currentUserTasks: currentUserTasks.map((task) => updateTask(task)),
				},
			});
		} catch (e) {
			console.log(e);
		}
	};
};

import { Box, Tooltip, useTheme } from "@material-ui/core";
import AttentionIconSvg from "assets/SVG/AttentionIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isProfileDisconnected } from "redux/actions/socialProfiles/socialProfiles";
import SocialProfileType from "types/SocialProfile";
import useSocialProfilesStyles from "../styles/social-profile";

interface Props {
	size?: "sm" | "lg";
	socialProfile: SocialProfileType;
	onClick: (profile: SocialProfileType) => void;
}

const SocialProfile = ({ socialProfile, size, onClick }: Props) => {
	const theme = useTheme();
	const classes = useSocialProfilesStyles();
	const { t } = useTranslation('settings');

	const isDisconnected = useMemo(
		() => isProfileDisconnected(socialProfile),
		[socialProfile]
	);

	return (
		<>
			<div className={classes.container} onClick={() => onClick(socialProfile)}>
				<div style={{position: 'relative'}} className={classes.profilePhotoContainer}>
					{isDisconnected && (
						<Tooltip title={t("socialProfileDisconnected") as string}>
							<Box className={classes.expiredIcon}>
								<AttentionIconSvg size={25} />
							</Box>
						</Tooltip>
					)}
					<SocialProfilePicture
						style={{
							width: size === "sm" ? 100 : 140,
							height: size === "sm" ? 100 : 140,
						}}
						profile={socialProfile}
					/>
					<div className={classes.networkIcon}>
						<NetworkIcon
							size={25}
							strokeWidth={3}
							color={theme.palette.text.primary}
							style={{ backgroundColor: "white" }}
							network={socialProfile.network}
						/>
					</div>
				</div>
				<div className={classes.username}>{socialProfile.displayName}</div>

				<div className={classes.type}>{socialProfile.type === "page" ? "PAGE" : "ACCOUNT"}</div>
			</div>
		</>
	);
};

export default SocialProfile;

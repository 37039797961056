const SendButtonSvg = ({ size }: { size?: number }) => {
	return (
		<svg
			width={size || 50}
			height={size || 50}
			viewBox="0 0 89 88"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_3893_18194)">
				<circle cx="45" cy="40" r="30" fill="url(#paint0_linear_3893_18194)" />
				<g clipPath="url(#clip0_3893_18194)">
					<path
						d="M65.4544 41.115C65.3347 41.1589 65.2053 41.1828 65.0702 41.1826L35.3171 41.137L32.5324 52.34C32.4317 52.7452 32.5667 53.1734 32.8819 53.4487C33.1972 53.724 33.6395 53.8 34.0273 53.6454L65.4544 41.115Z"
						fill="white"
					/>
					<path
						d="M35.3137 38.9113L32.4946 27.6996C32.3927 27.294 32.5263 26.8662 32.8407 26.5918C33.1551 26.3175 33.5973 26.2429 33.9855 26.3987L65.4575 39.028C65.3359 38.9823 65.2042 38.9572 65.0668 38.9569L35.3137 38.9113Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_3893_18194"
					x="0.715332"
					y="0"
					width="88.2847"
					height="88"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="7" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.843137 0 0 0 0 0.160784 0 0 0 0 0.0470588 0 0 0 0.3 0"
					/>
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3893_18194" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_3893_18194"
						result="shape"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_3893_18194"
					x1="15"
					y1="16.5625"
					x2="76.9654"
					y2="19.3233"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F05D6A" />
					<stop offset="1" stopColor="#F0765D" />
				</linearGradient>
				<clipPath id="clip0_3893_18194">
					<rect
						width="40"
						height="40"
						fill="white"
						transform="translate(42.9995 11.7153) rotate(45)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SendButtonSvg;

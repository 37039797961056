import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
	},

	cardTitle: {
		color: "#5B666E",
		fontWeight: 600,
		marginLeft: 24,
		marginBottom: 6,
		fontSize: 18,
		borderRadius: 12,
	},

	cardContainer: {
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
		height: 300,
		position: "relative",
	},

	chartContainer: {
		width: "100%",
		height: 300,
	},

	iconsContainer: {
		position: "absolute",
		top: 10,
		right: 10,
		zIndex: 99,
	},
}));

export default useStyles;

import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import Campaign from "types/Campaign";
import CheckBox from "../../Basic/Inputs/CheckBox";
import useCampaignStyles from "./styles";

interface Props {
	selectedCampaignId: string | undefined;
	onChange: (campaign: Campaign | null) => void;
}

export default function SelectCampaign(props: Props) {
	const { campaigns, listCampaignsLoading: loading } = useSelector(
		(state: StoreState) => state.campaigns
	);
	const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[]>([]);
	const [campaignsType, setCampaignsType] = useState<"active" | "future">("active");
	const classes = useCampaignStyles();
	const { selectedCampaignId, onChange } = props;

	const handleCampaignChange = (campaign: Campaign | null) => {
		onChange(campaign);
	};

	useEffect(() => {
		let filteredCampaigns: Campaign[] = [];

		campaigns.forEach((campaign) => {
			if (campaignsType === "active") {
				if (
					moment().isBefore(moment(campaign.endDate)) &&
					moment().isAfter(moment(campaign.startDate))
				) {
					filteredCampaigns.push(campaign);
				}
			} else if (campaignsType === "future") {
				filteredCampaigns.push(campaign);
			}
		});

		setFilteredCampaigns([...filteredCampaigns]);
	}, [campaigns, campaignsType]);

	return (
		<div className={classes.container}>
			<div
				onClick={() => setCampaignsType("active")}
				className={classes.radioOption}
				style={{ borderTop: "none" }}
			>
				Only Active Campaigns
				<div className={classes.checkboxContainer}>
					<CheckBox width={14} height={14} checked={campaignsType === "active"} />
				</div>
			</div>
			<div
				onClick={() => setCampaignsType("future")}
				className={classes.radioOption}
				style={{ borderTop: "none", marginTop: -8, paddingBottom: 8 }}
			>
				With Future Campaigns
				<div className={classes.checkboxContainer}>
					<CheckBox width={14} height={14} checked={campaignsType === "future"} />
				</div>
			</div>

			<div
				style={{
					backgroundColor: !selectedCampaignId ? "rgb(247, 247, 247)" : "",
				}}
				onClick={() => handleCampaignChange(null)}
				className={classes.option}
			>
				No Campaign
			</div>

			{filteredCampaigns.map((campaign, index) => (
				<div
					style={{
						backgroundColor: campaign._id === selectedCampaignId ? "rgb(247, 247, 247)" : "",
					}}
					onClick={() => handleCampaignChange(campaign)}
					className={classes.option}
					key={index}
				>
					{campaign.title}
				</div>
			))}
		</div>
	);
}

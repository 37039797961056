const CloseIconSvg = ({
	size,
	color,
	strokeWidth,
}: {
	size?: number;
	color?: string;
	strokeWidth?: number;
}) => {
	return (
		<svg
			width={size || 22}
			height={size || 22}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M1 1L21 21M1 21L21 1" stroke={color || "#3E4C55"} strokeWidth={strokeWidth || 2} />
		</svg>
	);
};

export default CloseIconSvg;

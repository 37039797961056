import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { CSSProperties } from "react";
import { v4 } from "uuid";
import { ProfilePicturePlaceholder } from "../../assets";
import SocialProfile from "types/SocialProfile";

interface Props {
	profile?: SocialProfile;
	style?: CSSProperties;
	className?: string;
	url?: string;
}

const useStyles = makeStyles(() => ({
	picture: {
		height: 25,
		width: 25,
		borderRadius: "50%",
		border: "1px solid white",
	},
}));

const SocialProfilePicture = (props: Props) => {
	const { profile, url, style, className } = props;
	const classes = useStyles();

	const imageId = `${profile?._id}-picture-${v4()}`;

	const handleImageLoadError = () => {
		const imageElement = document.getElementById(imageId) as HTMLImageElement;
		if (!imageElement) {
			return;
		}

		imageElement.src = ProfilePicturePlaceholder;
	};

	return (
		<img
			id={imageId}
			onError={handleImageLoadError}
			style={style}
			className={clsx(classes.picture, className)}
			src={profile?.picture || url || ProfilePicturePlaceholder}
		/>
	);
};

export default SocialProfilePicture;

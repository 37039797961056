import { CSSProperties, InputHTMLAttributes, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import EyeIcon from "assets/SVG/EyeIconSvg";

interface Props extends InputHTMLAttributes<any> {
	disableEye?: boolean;
	multiline?: boolean;
	error?: string;
	rows?: number;
	label?: string;
	inputStyle?: CSSProperties;
	errorStyle?: CSSProperties;
	containerStyle?: CSSProperties;
}

const useStyles = makeStyles((theme) => ({
	input: {
		border: `1px solid ${theme.palette.secondary.main}`,
		outline: "none",
		borderRadius: 8,
		paddingLeft: 12,
		paddingRight: 36,
		background: "transparent",
		height: 35,
		width: 340,
		"&:focus": {
			outline: "none",
			border: "1px solid #4B7FA3",
		},
		"&:placeholder": {
			color: "#3E4C55",
			letterSpacing: 0.5,
			fontSize: 14,
		},
		fontFamily: "Avenir",
		letterSpacing: 0.5,
		fontSize: 12,
		resize: "none",
		transition: "0.2s",
		color: theme.palette.text.primary,
	},

	container: {
		display: "flex",
		flexDirection: "row",
		position: "relative",
	},

	eyeIcon: {
		position: "absolute",
		right: 16,
		width: "fit-content",
		height: "fit-content",
		top: 3,
		padding: 8,
		cursor: "pointer",
		borderRadius: 50,
		transition: "0.3s",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "#f5f5f5",
		},
	},

	error: {
		whiteSpace: "pre-line",
		color: theme.palette.primary.main,
		fontSize: 12,
		maxWidth: "100%",
		marginTop: 6,
		overflow: "hidden",
		transition: "0.3s",
	},
}));

const Input = (props: Props) => {
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
	const theme = useTheme();
	const classes = useStyles();

	const { multiline, error, inputStyle, errorStyle, containerStyle, label, ...inputProps } = props;

	return (
		<div style={containerStyle}>
			{label && (
				<div style={{ fontSize: 12, fontWeight: 500, marginBottom: 6 }}>
					{label} {props.required ? "*" : ""}
				</div>
			)}
			<div
				style={{
					height: props.style?.height || "",
					width: props.style?.width || "",
				}}
				className={classes.container}
			>
				{props.multiline ? (
					<textarea
						{...inputProps}
						rows={props.rows}
						className={classes.input}
						style={{
							height: "auto",
							paddingTop: 8,
							paddingBottom: 8,
							...props.style,
						}}
					/>
				) : (
					<input
						{...inputProps}
						type={props.type === "password" ? (passwordVisible ? "text" : "password") : props.type}
						style={{
							border: error ? `1px solid ${theme.palette.primary.main}` : "",
							...inputStyle,
						}}
						className={classes.input}
					/>
				)}
				{props.type === "password" && !props.disableEye && (
					<div
						style={{ backgroundColor: passwordVisible ? "#ebebeb" : "" }}
						onClick={() => setPasswordVisible(!passwordVisible)}
						className={classes.eyeIcon}
					>
						<EyeIcon size={15} />
					</div>
				)}
			</div>
			<div
				style={{
					minHeight: error ? 15 : 0,
					maxHeight: error ? 15 : 0,
					overflow: error ? "visible" : "",
					...errorStyle,
				}}
				className={classes.error}
			>
				{error}
			</div>
		</div>
	);
};

export default Input;

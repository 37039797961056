import { makeStyles } from "@material-ui/core";

const useReplyDialogStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: "white",
		width: 900,
		maxWidth: 900,
		height: "fit-content",
		boxShadow: "none",
		border: "2px solid #868C91",
	},

	container: {
		padding: 32,
		width: "100%",
	},

	dateText: {
		color: theme.palette.text.primary,
		fontSize: 13,
	},

	postText: {
		fontSize: 12,
		color: theme.palette.text.primary,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	originalPostContainer: {
		backgroundColor: "#F1F4F8",
		marginTop: 24,
		borderRadius: 12,
		padding: 16,
		paddingTop: 8,
		paddingBottom: 8,
	},

	postImage: {
		height: 50,
		width: 50,
		marginRight: 12,
		borderRadius: 6,
	},

	postUsername: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		paddingLeft: 8,
		fontSize: 12,
	},

	postProfilePicture: {
		marginLeft: 8,
		height: 25,
		width: 25,
		borderRadius: "50%",
	},

	interactionContainer: {
		marginTop: 32,
	},

	interactionProfilePicture: {
		height: 45,
		width: 45,
		objectFit: "cover",
		marginRight: 12,
		borderRadius: "50%",
	},

	interactionUsername: {
		color: theme.palette.text.primary,
		fontWeight: 500,
	},

	interactionDate: {
		color: theme.palette.text.primary,
		paddingLeft: 24,
		fontSize: 12,
	},

	content: {
		fontSize: 12,
		color: theme.palette.text.primary,
	},

	replyContainer: {
		marginTop: 24,
	},

	postContentContainer: {
		border: "1px solid #4B7FA3",
		height: 140,
		width: "100%",
		borderRadius: 10,
		marginTop: 16,
		position: "relative",
	},

	textArea: {
		resize: "none",
		"&:focus": {
			outline: "none",
		},
		fontFamily: "Avenir",
		border: 0,
		width: "100%",
		borderRadius: 10,
		padding: 16,
		color: theme.palette.text.primary,
	},

	postActions: {
		position: "absolute",
		display: "flex",
		alignItems: "center",
		gap: 8,
		bottom: 4,
		right: 6,
	},

	postAction: {
		height: 28,
		width: 28,
		display: "flex",
		justifyContent: 'center',
		alignItems: "center",
		cursor: "pointer",
		borderRadius: "50%",
		"&:hover": {
			backgroundColor: "#ebebeb",
		},
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "fit-content",
		marginLeft: "auto",
		marginTop: 16,
	},

	postMentionUserPicture: {
		height: 35,
		width: 35,
		borderRadius: "50%",
	},

	postAttachmentPreviewContainer: {
		marginLeft: "auto",
		width: "fit-content",
	},

	postAttachmentPreview: {
		height: 48,
		width: 48,
		marginBottom: -4,
		borderRadius: 8,
	},

	postMentionAttachment: {
		height: 48,
		width: 48,
		marginRight: 10,
		borderRadius: 8,
	},

	mentionText: {
		cursor: "pointer",
		color: theme.palette.text.secondary,
		marginLeft: "auto",
		width: "fit-content",
		fontSize: 12,
	},

	originalInteractionBox: {
		marginLeft: "auto",
		background: "#F7F7F7",
		border: "1px solid #D5E1F5",
		borderRadius: 2,
		width: 180,
		fontSize: 12,
		paddingTop: 2,
		paddingBottom: 4,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		userSelect: "none",
		cursor: "pointer",
	},
}));

export default useReplyDialogStyles;

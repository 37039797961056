import { makeStyles } from "@material-ui/core";

const useNotesCardStyles = makeStyles(() => ({
	header: {
		paddingTop: 8,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	notesContainer: {
		marginTop: 12,
		marginLeft: 16,
		marginRight: 16,
		height: 380,
	},

	addIconContainer: {
		width: "fit-content",
		position: "absolute",
		bottom: 24,
		right: 24,
	},

	plusIcon: {
		height: 25,
		marginLeft: 12,
		cursor: "pointer",
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 52,
	},
}));

export default useNotesCardStyles;

import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { TasksTab } from "..";
import Masonry from "react-masonry-css";
import { StoreState } from "../../../../setup/store";
import usePageStyles from "../../styles";
import TaskType from "types/Task";
import { useTranslation } from "react-i18next";
import Task from "modules/Tasks/Task";
import TasksSkeleton from "modules/Tasks/LoadingSkeletons/TasksSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import AddTaskButton from "modules/Tasks/AddTaskButton";
import { Fragment } from "react";

interface Props {
	selectedTab: TasksTab;
	fetchMore: () => void;
	onAddClick: () => void;
	onTaskClick: (task: TaskType) => void;
}

const sections = ["urgent", "byDeadline", "finished"];

const Tasks = (props: Props) => {
	const { selectedTab, fetchMore, onAddClick, onTaskClick } = props;
	const classes = usePageStyles();
	const { t } = useTranslation("tasks");
	const { listTasksLoading, tasks, tasksQueryTotal } = useSelector(
		(state: StoreState) => state.tasks
	);

	return (
		<Box>
			{/* Skeleton Loading */}
			{listTasksLoading && (
				<Box style={{ marginTop: 62 }}>
					<TasksSkeleton />
				</Box>
			)}

			<InfiniteScroll
				dataLength={tasks.length}
				next={fetchMore}
				height="55vh"
				style={{ overflowX: "hidden" }}
				loader={<h4>Loading...</h4>}
				hasMore={tasksQueryTotal > tasks.length}
			>
				{!listTasksLoading && (
					<Box
						style={{
							position: "relative",
							marginRight: -16,
							marginBottom: 16,
							marginTop: -16,
						}}
					>
						<AddTaskButton onAddClick={onAddClick} />
					</Box>
				)}

				{!listTasksLoading &&
					sections.map((section, index) => {
						if (
							(selectedTab === "finished" && section !== "finished") ||
							(selectedTab !== "finished" && section === "finished")
						) {
							return <Fragment key={index} />;
						}

						const filteredTasks = tasks
							.filter(
								(task) =>
									(section === "urgent" && task.urgent) || (!task.urgent && section !== "urgent")
							)
							// for filtering updated tasks that had their status change
							.filter((task) =>
								selectedTab === "finished"
									? task.status === "finished"
									: task.status === "inProgress"
							);

						return (
							<Box style={{ marginBottom: 16 }}>
								<Box key={index} style={{ padding: 16, paddingTop: 8 }} className={classes.label}>
									{t(section)}
								</Box>

								{/* Urgent Tasks */}
								{filteredTasks.length > 0 && (
									<Masonry
										breakpointCols={3}
										className="masonry-grid"
										columnClassName="masonry-grid_column-3"
									>
										{filteredTasks.map((task, index) => (
											<Task key={index} task={task} onTaskClick={onTaskClick} />
										))}
									</Masonry>
								)}

								{filteredTasks.length === 0 && (
									<div
										style={{ paddingLeft: 16, fontSize: 12 }}
										className={classes.emptySectionMessage}
									>
										{t("noTasks")}
									</div>
								)}
							</Box>
						);
					})}
			</InfiniteScroll>
		</Box>
	);
};

export default Tasks;

import { CircularProgress } from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchInput from "components/Basic/Inputs/SearchInput";
import SocialProfile from "modules/Settings/SocialProfile/SocialProfile";
import { StoreState } from "../../../../setup/store";
import Network from "types/Network";
import usePageStyles from "../../page-styles";
import useSocialProfilesStyles from "../styles/social-profiles";
import SocialProfileType from "types/SocialProfile";
import SocialProfileDetails from "./SocialProfileDetails/SocialProfileDetails";
import { useQuery } from "helpers/utils";
import SocialProfilesSideBar from "./SideBar";
import { useHistory } from "react-router";
import SubProfileSelectionDialog from "modules/Settings/SubProfilesDialog/SubProfilesDialog";
import { useTranslation } from "react-i18next";
import { getSocialProfileRoute } from "setup/router/utils";

const SocialProfiles = () => {
	const [searchValue, setSearchValue] = useState<string>("");
	const [selectedNetwork, setSelectedNetwork] = useState<Network>();
	const [filteredProfiles, setFilteredProfiles] = useState<SocialProfileType[]>([]);
	const [subProfilesDialog, setSubProfilesDialog] = useState<boolean>(false);
	const { profiles, profilesLoading } = useSelector((state: StoreState) => state.socialProfiles);
	const { t } = useTranslation("settings");
	const classes = useSocialProfilesStyles();
	const pageClasses = usePageStyles();
	const query = useQuery();
	const history = useHistory();

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	useEffect(() => {
		let filteredProfiles: SocialProfileType[] = [];

		profiles?.forEach((profile) => {
			if (searchValue || selectedNetwork) {
				if (searchValue && profile.displayName?.toLowerCase().includes(searchValue.toLowerCase())) {
					filteredProfiles.push(profile);
				} else if (selectedNetwork?.toLowerCase() === profile.network) {
					filteredProfiles.push(profile);
				}
			} else {
				filteredProfiles.push(profile);
			}
		});

		setFilteredProfiles([...filteredProfiles]);
	}, [selectedNetwork, searchValue, profiles]);

	useEffect(() => {
		if (query.get("ptype") === "instagram") {
			setSubProfilesDialog(true);
		}
	}, [query.get("ptype")]);

	if (query.get("pid") && query.get("ptype") !== "instagram") {
		return (
			<SocialProfileDetails
				profile={profiles.filter((profile) => profile._id === query.get("pid"))[0]}
			/>
		);
	}

	return (
		<div style={{ marginTop: 32 }}>
			<div className={pageClasses.linearContainer} style={{ alignItems: "flex-start" }}>
				<SocialProfilesSideBar
					selectedNetwork={selectedNetwork}
					setSelectedNetwork={setSelectedNetwork}
				/>

				{profiles.filter((profile) => profile._id === query.get("pid"))[0] && (
					<SubProfileSelectionDialog
						currentProfile={profiles.filter((profile) => profile._id === query.get("pid"))[0]}
						open={subProfilesDialog}
						handleClose={() => setSubProfilesDialog(false)}
						profileTypes={["instagram"]}
					/>
				)}

				<div className={pageClasses.card} style={{ width: "100%", minHeight: 418 }}>
					<SearchInput
						onChange={handleInputChange}
						value={searchValue}
						placeholder={t("searchProfiles")}
						style={{ width: 300 }}
					/>

					{(profilesLoading || filteredProfiles.length === 0) && (
						<div className={pageClasses.loadingContainer} style={{ height: 200 }}>
							{profilesLoading && <CircularProgress color="primary" size={20} />}
							{!profilesLoading && filteredProfiles.length === 0 && (
								<div style={{ paddingTop: 62 }}>{t("noProfilesFound")}</div>
							)}
						</div>
					)}

					<div className={classes.profilesContainer}>
						{!profilesLoading &&
							filteredProfiles.map((profile, index) => {
								return (
									<div key={index} style={{ width: "25%", marginBottom: 32 }}>
										<SocialProfile
											onClick={() => history.push(getSocialProfileRoute(profile._id!))}
											socialProfile={profile}
										/>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SocialProfiles;

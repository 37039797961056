import CalendarEvent from "types/CalendarEvent";
import Campaign from "types/Campaign";
import Post from "types/Post";
import { ReduxAction } from "types/ReduxActions";

export interface CalendarEventsReducerState {
	createEventErrors: { [index: string]: string } | null;
	createEventSuccess: boolean;
	deleteEventSuccess: boolean;

	createEventLoading: boolean;
	deleteEventLoading: boolean;
	calendarLoading: boolean;

	dayCalendarEvents: CalendarEvent[];

	monthCalendarData: CalendarData | null;
	weekCalendarData: CalendarData | null;
}

export type CalendarData = {
	posts: Post[];
	calendarEvents: CalendarEvent[];
	campaigns: Campaign[];
};

let initialState: CalendarEventsReducerState = {
	createEventErrors: null,
	createEventSuccess: false,
	deleteEventSuccess: false,

	createEventLoading: false,
	deleteEventLoading: false,
	calendarLoading: false,

	dayCalendarEvents: [],
	monthCalendarData: null,
	weekCalendarData: null,
};

export const CREATE_EVENT_LOADING = "CREATE_EVENT_LOADING";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";

export const DELETE_EVENT_LOADING = "DELETE_EVENT_LOADING";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";

export const LIST_DAY_CALENDAR_EVENTS = "LIST_DAY_CALENDAR_EVENTS";
export const CALENDAR_LOADING = "CALENDAR_LOADING";

export const SET_MONTH_CALENDAR_DATA = "SET_MONTH_CALENDAR_DATA";
export const SET_WEEK_CALENDAR_DATA = "SET_WEEK_CALENDAR_DATA";

export default function calendarEventsReducer(
	state: CalendarEventsReducerState = initialState,
	action: ReduxAction
): CalendarEventsReducerState {
	switch (action.type) {
		case CREATE_EVENT_LOADING: {
			return {
				...state,
				createEventLoading: true,
			};
		}
		case CREATE_EVENT_ERROR: {
			return {
				...state,
				createEventLoading: false,
				createEventErrors: action.payload,
			};
		}
		case CREATE_EVENT_SUCCESS: {
			return {
				...state,
				createEventLoading: false,
				createEventSuccess: action.payload,
				createEventErrors: null,
			};
		}

		case CALENDAR_LOADING: {
			return {
				...state,
				calendarLoading: action.payload,
			};
		}

		case LIST_DAY_CALENDAR_EVENTS: {
			return {
				...state,
				calendarLoading: false,
				dayCalendarEvents: action.payload,
			};
		}

		case DELETE_EVENT_LOADING: {
			return {
				...state,
				deleteEventLoading: true,
			};
		}

		case DELETE_EVENT_SUCCESS: {
			return {
				...state,
				deleteEventLoading: false,
				deleteEventSuccess: action.payload,
			};
		}

		case SET_MONTH_CALENDAR_DATA: {
			return {
				...state,
				monthCalendarData: action.payload,
				calendarLoading: false,
			};
		}

		case SET_WEEK_CALENDAR_DATA: {
			return {
				...state,
				weekCalendarData: { ...action.payload },
				calendarLoading: false,
			};
		}

		default:
			return state;
	}
}

import { ArrowUp, ArrowDown } from "assets";
import { ChangeEvent, useEffect, useState } from "react";
import useStyles from "./styles";

export type Time = { hours: number; minutes: number; dayTime: "AM" | "PM" };
interface Props {
	onChange: (time: Time) => void;
	defaultValue?: string;
}

export default function TimePicker(props: Props) {
	const classes = useStyles();
	const [hours, setHours] = useState<string>("12");
	const [minutes, setMinutes] = useState<string>("00");
	const [dayTime, setDayTime] = useState<"AM" | "PM">("PM");

	const { defaultValue, onChange } = props;

	const handleInputChange = (inputType: "hours" | "minutes" | "dayTime", value: any) => {
		if (inputType === "hours") {
			if (parseInt(value) === 0) return setHours("");
			if ((!parseInt(value) && value.length !== 0) || parseInt(value) > 12) return;
			else {
				if (value.length === 0) setHours("");
				else setHours(parseInt(value).toString());
			}
		} else if (inputType === "minutes") {
			if (parseInt(value) === 0) return setMinutes("");
			if ((!parseInt(value) && value.length !== 0) || parseInt(value) > 59) return;
			else {
				if (value.length === 0) setMinutes("");
				else setMinutes(parseInt(value).toString());
			}
		}
	};

	const handleArrowClick = (inputType: "hours" | "minutes" | "dayTime", arrow: "up" | "down") => {
		if (inputType === "hours") {
			let newValue = 0;
			if (hours.length === 0) return setHours("01");
			if (hours.startsWith("0")) {
				newValue = parseInt(hours.substr(1, 1));
				if (arrow === "up") {
					newValue = newValue + 1;
				} else {
					if (newValue === 1) {
						newValue = 12;
					} else {
						newValue = newValue - 1;
					}
				}
			} else {
				if (arrow === "up") {
					if (hours === "12") {
						newValue = 1;
					} else {
						newValue = parseInt(hours) + 1;
					}
				} else newValue = parseInt(hours) - 1;
			}

			if (newValue > 12 || newValue < 1) return;
			if (newValue >= 10) setHours(String(newValue));
			else setHours(`0${String(newValue)}`);
		} else if (inputType === "minutes") {
			let newValue = 0;
			if (minutes.length === 0) return setMinutes("01");
			if (minutes.startsWith("0")) {
				newValue = parseInt(minutes.substr(1, 1));
				if (arrow === "up") newValue = newValue + 1;
				else newValue = newValue - 1;
			} else {
				if (arrow === "up") {
					newValue = parseInt(minutes) + 1;
				} else newValue = parseInt(minutes) - 1;
			}
			if (newValue > 59 || newValue < 0) return;
			if (newValue >= 10) setMinutes(String(newValue));
			else setMinutes(`0${String(newValue)}`);
		} else {
			setDayTime(dayTime === "AM" ? "PM" : "AM");
		}
	};

	useEffect(() => {
		onChange({
			hours: parseInt(hours),
			minutes: parseInt(minutes),
			dayTime,
		});
	}, [hours, minutes, dayTime]);

	useEffect(() => {
		if (defaultValue) {
			let defaultDate = new Date(defaultValue);
			let hours = defaultDate.getHours();
			if (hours === 0) {
				hours = 12;
			} else if (hours > 12) {
				hours = hours - 12;
			}

			setHours(String(hours).length === 1 ? `0${String(hours)}` : String(hours));
			setMinutes(
				String(defaultDate.getMinutes()).length === 1
					? `0${String(defaultDate.getMinutes())}`
					: String(defaultDate.getMinutes())
			);
			setDayTime(defaultDate.getHours() > 12 ? "PM" : "AM");
		}
	}, [defaultValue]);

	return (
		<div className={classes.container}>
			{/* Hours */}
			<div className={classes.verticalContainer}>
				<img
					style={{ marginBottom: 8 }}
					src={ArrowUp}
					className={classes.arrow}
					onClick={() => handleArrowClick("hours", "up")}
				/>
				<div className={classes.inputBox}>
					<input
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange("hours", e.target.value)
						}
						value={hours}
						className={classes.input}
					/>
				</div>
				<img
					src={ArrowDown}
					className={classes.arrow}
					onClick={() => handleArrowClick("hours", "down")}
				/>
			</div>

			<span className={classes.timeSeparator}>:</span>
			{/* Minutes */}
			<div className={classes.verticalContainer}>
				<img
					style={{ marginBottom: 8 }}
					src={ArrowUp}
					className={classes.arrow}
					onClick={() => handleArrowClick("minutes", "up")}
				/>
				<div className={classes.inputBox}>
					<input
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange("minutes", e.target.value)
						}
						value={minutes}
						className={classes.input}
					/>
				</div>
				<img
					src={ArrowDown}
					className={classes.arrow}
					onClick={() => handleArrowClick("minutes", "down")}
				/>
			</div>

			{/* Day Time */}
			<div className={classes.verticalContainer} style={{ marginLeft: 16 }}>
				<img
					style={{ marginBottom: 8 }}
					src={ArrowUp}
					className={classes.arrow}
					onClick={() => handleArrowClick("dayTime", "up")}
				/>
				<div className={classes.inputBox}>
					<input
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							handleInputChange("dayTime", e.target.value)
						}
						value={dayTime}
						readOnly
						className={classes.input}
					/>
				</div>
				<img
					src={ArrowDown}
					className={classes.arrow}
					onClick={() => handleArrowClick("dayTime", "down")}
				/>
			</div>
		</div>
	);
}

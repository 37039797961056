import { makeStyles } from "@material-ui/core";

const useEditorStyles = makeStyles((theme) => ({
	container: {
		marginTop: 32,
		paddingLeft: 120,
		paddingRight: 100,
		height: "85vh",
		position: "relative",
	},

	innerContainer: {
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
		height: "85vh",
		position: "relative",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "fit-content",
		marginLeft: "auto",
		paddingBottom: 12,
		position: "absolute",
		zIndex: 999,
		right: 50,
		top: 22,
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 62,
	},

	editorPaper: {
		width: "100%",
		maxWidth: "9999999px !important",
		height: "105%",
		maxHeight: "999999px",
		zIndex: "99999 !important" as any,
		margin: 0,
		background: "#f7f7f7",
	},
}));

export default useEditorStyles;

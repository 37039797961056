import { Dispatch } from "react";
import { StoreState } from "../../../setup/store";
import { TeamUserRole } from "types/User/Team";
import { SET_CURRENT_TEAM_ROLE } from "../../reducers/teams";
import { getUserData } from "../user/user-data";

export const setCurrentTeamRole = () => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		let role: TeamUserRole | null = null;

		dispatch(
			getUserData({ populateTeamUsers: true }, (userData) => {
				userData.teams.forEach((team) => {
					if (team._id === currentTeamId) {
						team.users.forEach((teamUser) => {
							if (teamUser.user?._id === userData._id) {
								role = teamUser.role;
							}
						});
					}
				});
				dispatch({
					type: SET_CURRENT_TEAM_ROLE,
					payload: role,
				});
			})
		);
	};
};

import { Dialog } from "@material-ui/core";
import Container from "../../components/Navigation/Container";
import { Media } from "types/Task";
import PhotoEditor from "./PhotoEditor";
import useEditorStyles from "./styles/styles";

interface Props {
	selectedAttachment?: Media;
	open: boolean;
	onSubmit: (updatedFile: File) => void;
	onClose: () => void;
}

const PhotoEditorDialog = (props: Props) => {
	const classes = useEditorStyles();
	const { open, onClose, onSubmit, selectedAttachment } = props;

	return (
		<Dialog
			classes={{ paper: classes.editorPaper, root: classes.editorPaper }}
			open={open && Boolean(selectedAttachment)}
		>
			<Container page="Photo Editor">
				{selectedAttachment && (
					<PhotoEditor
						image={selectedAttachment.value}
						onCancelClick={onClose}
						onSubmit={onSubmit}
					/>
				)}
			</Container>
		</Dialog>
	);
};

export default PhotoEditorDialog;

import BellIcon from "assets/note-bell.png";
import NoteType from "types/Note";
import useStyles from "./styles/note-styles";
import { formatDate } from "helpers/dateAndTime";
import { Box } from "@material-ui/core";
import MarkdownText from "components/Basic/Text/MarkdownText";

interface Props {
	note: NoteType;
	onClick?: (note: NoteType) => void;
}

const Note = (props: Props) => {
	const classes = useStyles();
	const { note, onClick } = props;

	return (
		<Box className={classes.container} onClick={() => onClick && onClick(note)}>
			<Box style={{ paddingBottom: 32 }}>
				<Box className={classes.textArea}>
					<MarkdownText text={note.content} />
				</Box>
			</Box>

			<Box className={classes.dateContainer}>
				{note.reminder && <img src={BellIcon} alt="bell" height={15} />}
				<span className={classes.dateText}>{formatDate(note.updatedAt)}</span>
			</Box>
		</Box>
	);
};

export default Note;

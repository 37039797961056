import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import Post from "types/Post";
import PostActions from "./Actions";
import PostContent from "./PostContent";
import PostMedia from "./PostMedia";
import PostTask from "./PostTask";
import PostSocialProfiles from "./SocialProfiles";
import useUnapprovedPostStyles from "./styles";

interface Props {
	post: Post;
	onDeleteClick: (post: Post) => void;
}

const UnapprovedPost = (props: Props) => {
	const { post, onDeleteClick } = props;
	const classes = useUnapprovedPostStyles();
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const isAdmin = currentTeamRole === "admin" || currentTeamRole === "owner";
	const { t } = useTranslation("posts");

	return (
		<Box
			style={{
				alignItems: isAdmin ? "flex-start" : "",
				border: post.status === "returned" ? "2px solid #F04755" : "",
			}}
			className={classes.container}
		>
			<Box display="flex" style={{ width: "100%" }}>
				{post.status === "returned" && <Box className={classes.returned}>{t("returned")}</Box>}
				<Box display="flex" style={{ width: "50%" }}>
					<PostMedia post={post} />
					<PostContent post={post} />
				</Box>
				<PostSocialProfiles post={post} />
				<PostTask post={post} />
			</Box>

			<PostActions post={post} onDeleteClick={onDeleteClick} />
		</Box>
	);
};

export default UnapprovedPost;

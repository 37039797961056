import axios from "axios";
import { Dispatch } from "react";
import config from "config";

import { StoreState } from "../../../setup/store";
import { ConversationType } from "types/Chat/Chat";
import {
	SET_CHAT_OPEN,
	SET_SEARCH_VALUE,
	SET_SELECTED_CONVO,
	SET_UNREAD_MESSAGES,
} from "../../reducers/chat";

export const setChatOpen = (open: boolean) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_CHAT_OPEN,
			payload: open,
		});
	};
};

export const setSelectedConvo = (type: ConversationType, receiver?: string) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_SELECTED_CONVO,
			payload: { type, receiver },
		});
	};
};

export const setSearchValue = (value: string) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_SEARCH_VALUE,
			payload: value,
		});
	};
};

export const readMessages = (sender: string, messageId?: string) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { unreadMessages } = getState().chat;
		if (!currentTeamId) return;

		if (unreadMessages && !unreadMessages[sender] && !messageId) {
			return;
		}

		try {
			await axios.get(`${config.apiUrl}/messages/${sender}/read?teamId=${currentTeamId}`, {});

			if (unreadMessages) {
				delete unreadMessages[sender];

				dispatch({
					type: SET_UNREAD_MESSAGES,
					payload: { ...unreadMessages },
				});
			}
		} catch (e) {
			console.log(e);
		}
	};
};

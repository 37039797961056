import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";
import { PostFilterType } from "../../components/Popovers/PostsFilter";
import { StoreState } from "../../setup/store";
import Campaign from "types/Campaign";
import Network from "types/Network";
import SocialProfile from "types/SocialProfile";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "white",
		padding: 12,
		paddingBottom: 8,
		paddingTop: 8,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginRight: 12,
		borderRadius: 8,
		marginTop: 8,
	},

	picture: {
		width: 22,
		height: 22,
		borderRadius: "50%",
		objectFit: "cover",
		marginLeft: 8,
	},

	label: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		paddingRight: 24,
		paddingLeft: 8,
	},
}));

interface Props {
	onDeleteClick: (type: PostFilterType, value: string) => void;
	filter: { value: string; type: PostFilterType };
}

const PostFilterTab = (props: Props) => {
	const classes = useStyles();
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);
	const { campaigns } = useSelector((state: StoreState) => state.campaigns);
	const { filter, onDeleteClick } = props;
	const { value, type } = filter;

	const selectedProfile: SocialProfile | undefined = profiles.find(
		(profile) => profile._id === value
	);
	const selectedCampaign: Campaign | undefined = campaigns.find(
		(campaign) => campaign._id === value
	);

	return (
		<div className={classes.container}>
			{(type === "network" || type === "socialProfile") && (
				<NetworkIcon
					size={18}
					strokeWidth={2}
					network={type === "network" ? (value as Network) : (selectedProfile?.network as Network)}
				/>
			)}

			{type === "socialProfile" && selectedProfile && (
				<SocialProfilePicture
					style={{ marginLeft: 4, height: 22, width: 22 }}
					profile={selectedProfile}
				/>
			)}

			<div className={classes.label}>
				{type === "campaign" && selectedCampaign?.title}
				{type === "socialProfile" && (selectedProfile?.displayName || selectedProfile?.username)}
				{type === "network" && <span style={{ textTransform: "capitalize" }}>{value}</span>}
			</div>

			<div onClick={() => onDeleteClick(type, value)} style={{ cursor: "pointer", height: 17 }}>
				<CloseIconSvg size={12} />
			</div>
		</div>
	);
};

export default PostFilterTab;

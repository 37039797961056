import { makeStyles } from "@material-ui/core";

const useTaskAssignmentStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#F1F4F8",
		borderRadius: 8,
		width: "100%",
		height: 226,
		marginTop: 8,
	},

	labelContainer: {
		paddingLeft: 16,
		paddingTop: 12,
		display: "flex",
		alignItems: "center",
	},

	label: {
		color: theme.palette.text.secondary,
		fontSize: 14,
		marginLeft: 8,
	},

	teamUser: {
		display: "flex",
		alignItems: "center",
		paddingLeft: 12,
		marginLeft: 6,
		marginRight: 6,
		borderRadius: 6,
		width: "96.5%",
		cursor: "pointer",
		paddingTop: 8,
		paddingBottom: 8,
	},

	username: {
		fontSize: 12,
		color: theme.palette.text.primary,
		maxWidth: "100%",
		whiteٍpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingRight: 12,
	},
}));

export default useTaskAssignmentStyles;

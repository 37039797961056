import { makeStyles } from "@material-ui/core";
import MonthlyCalendar from "modules/Calendar/MonthlyCalendar";

const useStyles = makeStyles(() => ({
	container: {
		marginTop: 56,
		width: "50%",
		marginLeft: 24,
		height: 430,
		backgroundColor: "white",
		position: "relative",
		overflow: "hidden",
		borderRadius: 10,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	title: {
		padding: 24,
		paddingLeft: 32,
		fontWeight: 700,
		color: "#5B666E",
		fontSize: 16,
	},

	calendarContainer: {},

	navigationButtons: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingRight: 24,
	},
}));

const MonthOverview = () => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<span className={classes.title}>Month Overview</span>
			</div>

			<div className={classes.calendarContainer}>
				<MonthlyCalendar height={350} taskStyle={{ marginTop: 26 }} />
			</div>
		</div>
	);
};

export default MonthOverview;

import { Theme, withStyles, MenuItem, Menu } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import PopupState, { bindToggle, bindMenu } from "material-ui-popup-state";
import { forwardRef } from "react";
import { ParentPopupState } from "..";

const styles = (theme: Theme) => ({
	menu: {
		marginTop: theme.spacing(-1),
		marginLeft: 0,
		backgroundColor: "white",
	},
	title: {
		flexGrow: 1,
		fontSize: 12,
		fontFamily: "Avenir",
	},
	moreArrow: {
		marginRight: theme.spacing(-1),
		color: theme.palette.primary.main,
		fontSize: 18,
	},
});

const SubMenu = withStyles(styles)(
	forwardRef<any, any>(({ classes, title, popupId, children, ...props }, ref) => (
		<ParentPopupState.Consumer>
			{(parentPopupState) => (
				<PopupState variant="popover" popupId={popupId} parentPopupState={parentPopupState}>
					{(popupState) => (
						<ParentPopupState.Provider value={popupState as any}>
							<MenuItem {...bindToggle(popupState)} selected={popupState.isOpen} ref={ref as any}>
								<span className={classes.title}>{title}</span>
								<ChevronRight className={classes.moreArrow} />
							</MenuItem>
							<Menu
								{...bindMenu(popupState)}
								classes={{ paper: classes.menu }}
								anchorOrigin={{ vertical: "top", horizontal: "right" }}
								transformOrigin={{ vertical: "top", horizontal: "left" }}
								getContentAnchorEl={null}
								{...props}
							>
								{children}
							</Menu>
						</ParentPopupState.Provider>
					)}
				</PopupState>
			)}
		</ParentPopupState.Consumer>
	))
);

export default SubMenu;

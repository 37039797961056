import { makeStyles } from "@material-ui/core";

const usePageStyles = makeStyles((theme) => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "90vh",
		paddingTop: 12,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	title: {
		fontWeight: 500,
		fontSize: 18,
		letterSpacing: 0.5,
		marginTop: 16,
		marginLeft: 22,
		color: "#5B666E",
		textTransform: "uppercase",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		marginLeft: "auto",
		marginRight: "auto",
		width: 1100,
		marginTop: 16,
	},

	cardTitle: {
		fontFamily: "Chau Philomene One",
		color: "#5B666E",
		fontSize: 20,
		letterSpacing: 0.4,
		display: "flex",
		alignItems: "center",
	},

	card: {
		height: 300,
		width: "50%",
		borderRadius: 10,
		backgroundColor: "white",
		padding: 16,
		paddingTop: 24,
		marginTop: 12,
		position: "relative",
	},

	tasksCard: {
		width: "100%",
		minHeight: "60vh",
		marginTop: 0,
	},

	label: {
		color: "#868C91",
		fontWeight: 700,
		fontSize: 14,
	},

	addTaskButton: {
		position: "absolute",
		right: 24,
		top: 24,
	},

	emptySectionMessage: {
		color: "#868C91",
		fontWeight: 300,
	},

	container: {
		width: "100%",
		height: 70,
		display: "flex",
		flexDirection: "row",
	},

	element: {
		display: "flex",
		flexDirection: "row",
		width: "16.3%",
		marginRight: 6,
		marginLeft: 6,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "white",
		borderRadius: 10,
		position: "relative",
	},

	elementText: {
		display: "flex",
		flexDirection: "column",
		color: "#3E4C55",
		justifyContent: "center",
	},

	summaryTitle: {
		fontSize: 16,
		fontFamily: "Avenir",
	},

	summarySubtitle: {
		fontSize: 10,
		fontFamily: "Avenir",
	},

	icon: {
		position: "absolute",
		width: 13,
		height: 15,
		top: 10,
		right: 10,
	},
}));

export default usePageStyles;

import axios from "axios";
import { Dispatch } from "react";
import config from "config";

import { StoreState } from "../../../setup/store";
import { DeleteTaskFunc } from "types/ReduxActions";
import {
	DELETE_TASK_LOADING,
	LIST_CURRENT_USER_TASKS,
	LIST_TASKS,
	SET_TASKS_COUNT,
} from "../../reducers/tasks";
import { setSnackbarState } from "../snackBar";

export const deleteTask: DeleteTaskFunc = (id, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { tasks, currentUserTasks, tasksCount, tasksQueryTotal, totalCurrentUserTasks } =
			getState().tasks;

		dispatch({
			type: DELETE_TASK_LOADING,
			payload: true,
		});

		try {
			await axios.delete(`${config.apiUrl}/tasks/${id}`, {});

			dispatch({
				type: LIST_TASKS,
				payload: {
					tasks: tasks.filter((task) => task._id !== id),
					tasksQueryTotal: tasksQueryTotal - 1,
				},
			});

			dispatch({
				type: LIST_CURRENT_USER_TASKS,
				payload: {
					currentUserTasks: currentUserTasks.filter((task) => task._id !== id),
					totalCurrentUserTasks: totalCurrentUserTasks - 1,
				},
			});

			dispatch({
				type: SET_TASKS_COUNT,
				payload: tasksCount !== 0 ? tasksCount - 1 : 0,
			});
			callback(true);
			dispatch(setSnackbarState(true, "success", "TaskDeleteSuccess"));
		} catch (e) {
			console.log(e.response?.data);
			callback(false);
			dispatch(setSnackbarState(true, "error", "DeleteTaskError"));
		}

		dispatch({
			type: DELETE_TASK_LOADING,
			payload: false,
		});
	};
};

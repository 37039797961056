import { Box, IconButton } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import ExpandIconSvg from "assets/SVG/ExpandIconSvg";
import { getAttachmentPath } from "../../components/Dialogs/AttachmentPreview/utils";
import Post, { PostPayload } from "types/Post";

interface Props {
	post: Post | PostPayload;
	style?: CSSProperties;
	className?: string;
}

const useStyles = makeStyles(() => ({
	container: {
		position: "relative",
	},

	image: {
		objectFit: "cover",
		width: "100%",
	},

	iconButton: {
		backgroundColor: "#999999",
		position: "absolute",
		top: "50%",
	},
}));

const SocialPreviewMedia = (props: Props) => {
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [path, setPath] = useState<string>();
	const { style, className, post } = props;
	const classes = useStyles();
	const { media } = post;

	const getPath = async () => {
		if (!media) return;

		const path = await getAttachmentPath(media[currentIndex]);
		setPath(path);
	};

	useEffect(() => {
		getPath();
	}, [post.media, currentIndex]);

	if (!media) return <></>;

	return (
		<Box style={style} className={clsx(classes.container, className)}>
			{path && currentIndex !== 0 && (
				<IconButton
					className={classes.iconButton}
					onClick={() => setCurrentIndex(currentIndex - 1)}
					style={{
						transform: "rotate(90deg) scaleX(-1) translate(-50%, 0px)",
						marginRight: 32,
						left: 32,
					}}
				>
					<ExpandIconSvg color="black" size={25} light />
				</IconButton>
			)}

			{media[currentIndex]?.type === "image" && (
				<img alt="preview" style={{ maxHeight: 400 }} className={classes.image} src={path} />
			)}

			{media[currentIndex]?.type === "video" && (
				<ReactPlayer url={path} width={"100%"} height={"100%"} controls={true} />
			)}

			{path && media[currentIndex + 1] && (
				<IconButton
					onClick={() => setCurrentIndex(currentIndex + 1)}
					className={classes.iconButton}
					style={{
						transform: "rotate(270deg) scaleX(-1) translate(50%, 0px)",
						marginLeft: 32,
						right: 32,
					}}
				>
					<ExpandIconSvg color="black" size={25} light />
				</IconButton>
			)}
		</Box>
	);
};

export default SocialPreviewMedia;

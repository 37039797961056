import { makeStyles, Popover } from "@material-ui/core";
import React, { CSSProperties, MouseEvent, useState } from "react";
import ExpandIconSvg from "assets/SVG/ExpandIconSvg";
import OptionsPopover from "../../Popovers/OptionsPopover";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
	},

	label: {
		fontSize: 14,
		color: theme.palette.text.primary,
	},

	value: {
		fontSize: 14,
		fontWeight: 500,
	},

	rightContainer: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		cursor: "pointer",
	},
}));

interface Props {
	label: string;
	name: string;
	value?: string;
	style?: CSSProperties;
	valueColor?: string;
	options: Array<{ value: string; label: string }>;
	onChange: (name: string, value: string) => void;
}

const SettingsSelectInput = (props: Props) => {
	const classes = useStyles();
	const [popover, setPopover] = useState<HTMLElement | null>(null);
	const { label, value, options, onChange, valueColor, style, name } = props;

	const handleExpandClick = (e: MouseEvent<HTMLElement>) => {
		setPopover(e.currentTarget);
	};

	return (
		<div style={{ ...style }} className={classes.container}>
			<div className={classes.label}>{label}</div>
			<div onClick={handleExpandClick} className={classes.rightContainer}>
				<div style={{ color: valueColor || "", marginRight: 8 }} className={classes.value}>
					{options.filter((option) => option.value === value)[0]?.label || options[0].label}
				</div>

				<div style={{ marginRight: 8 }}>
					<ExpandIconSvg />
				</div>
			</div>
			<OptionsPopover
				options={options}
				anchorEl={popover}
				onClose={() => setPopover(null)}
				onChange={(value) => onChange(name, value)}
				value={value}
			/>
		</div>
	);
};

export default SettingsSelectInput;

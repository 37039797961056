import Router from "./router/router";
import { makeStyles, ThemeProvider } from "@material-ui/core";
import theme from "./Theme";
import Footer from "../components/Footer";
import { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "./store";
import config, { CurrentTeamLsKey } from "config";
import { getNotifications } from "redux/actions/notifications";
import moment from "moment";
import i18n from "../i18n";
import { I18nextProvider } from "react-i18next";
import { setCurrentTeamRole } from "redux/actions/team/get";
import { getTeamSubscription } from "redux/actions/team/subscriptions/plans";
import enableSocketListeners from "./socket-listeners";
import axios from "axios";
import { setupAuthInterceptor, setupResponseInterceptor } from "./axios-interceptors";
import { useHistory } from "react-router";
import Analytics from "analytics";
import { getUserHash } from "redux/actions/auth";
import segmentPlugin from "@analytics/segment";
import intercomPlugin from "@analytics/intercom";
import { setCurrentTeamId } from "redux/actions/team/post";
import { listSocialProfiles } from "redux/actions/socialProfiles/socialProfiles";

const useStyles = makeStyles(() => ({
	appContainer: {
		fontFamily: "Avenir",
		position: "relative",
		minHeight: "100vh",
		backgroundColor: "#F7F7F7",
	},
}));

export const intercomLauncherSelector = "contact_support";

export const analytics = Analytics({
	app: "hashtaghero",
	plugins: [
		segmentPlugin({
			writeKey: "bg6HBSi4D8A0mTqNClOKGAqju5boJBBx",
		}),
		intercomPlugin({
			appId: "m8r70wx9",
			customLauncherSelector: `#${intercomLauncherSelector}`,
		}),
	],
});

const App = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const history = useHistory();
	const userIdentified = useRef(false);

	useEffect(() => {
		if (!userData) return;

		// Set the app timezone if the user has one saved in their settings
		if (userData.settings?.timeZone) {
			moment.tz.setDefault(userData.settings.timeZone);
		}

		if (currentTeamId) {
			dispatch(getNotifications({}));
		}

		const socket = io(config.apiBaseUrl);
		socket.connect();

		enableSocketListeners(socket);

		return function cleanup() {
			if (userData) {
				socket.emit("leave", { userId: userData?._id });
				socket.disconnect();
			}
		};
	}, [userData, currentTeamId]);

	const setupAnalytics = async () => {
		if (!userData || process.env.NODE_ENV !== "production") {
			return;
		}

		const { teams, ...filteredUserData } = userData;

		const userHash = await getUserHash();

		await analytics.identify(
			userData._id,
			{
				...filteredUserData,
			},
			{
				Intercom: {
					user_hash: userHash,
				},
			}
		);

		const currentTeam = userData.teams.find((team) => team._id === currentTeamId);
		if (!currentTeam) return;
		// @ts-ignore
		await analytics.plugins.segment.group(currentTeam?._id, {
			name: currentTeam.name,
		});

		analytics.page();
	};

	// Select team
	useEffect(() => {
		if (!userData) return;
		let currentTeamId = localStorage.getItem(CurrentTeamLsKey);
		if (!currentTeamId || !userData.teams.find((team) => team._id === currentTeamId)) {
			currentTeamId = userData?.teams[0]?._id || "";
		}

		dispatch(setCurrentTeamId(currentTeamId));
	}, [userData]);

	useEffect(() => {
		if (currentTeamId && userData) {
			dispatch(setCurrentTeamRole());
			getTeamSubscription();
		}
	}, [currentTeamId, userData]);

	useEffect(() => {
		if (!userData || userIdentified.current || !currentTeamId) {
			return;
		}

		userIdentified.current = true;
		setupAnalytics();
	}, [userData, currentTeamId]);

	useEffect(() => {
		const authInterceptor = setupAuthInterceptor();
		const responseInterceptor = setupResponseInterceptor();

		return () => {
			if (authInterceptor) {
				axios.interceptors.request.eject(authInterceptor);
			}

			if (responseInterceptor) {
				axios.interceptors.response.eject(responseInterceptor);
			}
		};
	}, [userData, history.location.pathname]);

	useEffect(() => {
		if (currentTeamId) {
			dispatch(listSocialProfiles());
		}
	}, [currentTeamId]);

	return (
		<ThemeProvider theme={theme}>
			<I18nextProvider i18n={i18n} defaultNS="common">
				<div className={classes.appContainer}>
					<Router />
					<Footer />
				</div>
			</I18nextProvider>
		</ThemeProvider>
	);
};

export default App;

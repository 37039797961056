import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	container: {
		maxWidth: 1250,
		display: "flex",
		flexDirection: "row",
		minHeight: 150,
		marginTop: 12,
		position: "relative",
		borderRadius: 8,
	},

	date: {
		width: 120,
		display: "flex",
		flexDirection: "row",
		marginTop: 24,
		justifyContent: "center",
	},

	dateText: {
		color: "#5B666E",
		textTransform: "uppercase",
		fontFamily: "Avenir",
		letterSpacing: 0.4,
		fontSize: 16,
	},

	postsContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		marginTop: 8,
		marginBottom: 16,
	},

	separator: {
		height: 1.4,
		backgroundColor: "#4B7FA3",
		position: "relative",
		width: "101%",
		marginTop: 4,
		marginBottom: -4,
		marginLeft: -16,
	},

	separatorCircle: {
		position: "absolute",
		height: 15,
		width: 15,
		backgroundColor: "white",
		border: "1px solid #4B7FA3",
		borderRadius: "50%",
		left: 0,
		top: -7.5,
	},
}));

export default useStyles;

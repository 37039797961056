import ClampLines from "react-clamp-lines";
import Post from "types/Post";
import useStyles from "./styles";
import moment from "moment";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { postReplyStats } from "mock/replyPlaceholderData";
import usePageStyles from "../../styles";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import PostImage from "modules/Posts/PostImage";

interface Props {
	post: Post;
}

const ByPostInteractionsHeader: React.FC<Props> = (props) => {
	const pageClasses = usePageStyles();

	const classes = useStyles();
	const { post } = props;

	return (
		<div className={classes.header}>
			<div className={classes.postInfoContainer}>
				<PostImage className={classes.postAttachment} post={post} />
				<div className={classes.postContent}>
					{post.content && (
						<ClampLines
							id="post-content-header-preview"
							text={post.content}
							lines={3}
							ellipsis="..."
							buttons={false}
						/>
					)}
				</div>
				<div className={classes.dateContainer}>
					{moment(post.schedule).format("DD MMM YYYY")} at{" "}
					{moment(post.schedule).format("h:mm:ss a")}
					<div className={classes.profileInfo}>
						<NetworkIcon size={16} network={post.socialProfiles![0].network} />
						<SocialProfilePicture
							style={{ height: 20, width: 20, marginLeft: 4, marginRight: 4 }}
							profile={post.socialProfiles![0]}
						/>
						<div className={classes.profileUsername}>
							{post.socialProfiles![0].displayName || post.socialProfiles![0].username}
						</div>
					</div>
				</div>
			</div>
			<div className={classes.postDataContainer}>
				{/* TODO: Replace placeholder */}
				{postReplyStats.map((item, index) => (
					<div key={index} className={classes.dataHeaderItem}>
						<div className={pageClasses.dataItemTitle} style={{ fontSize: 11 }}>
							{item.title}
						</div>
						<div className={pageClasses.dataItemValue}>{item.value}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ByPostInteractionsHeader;

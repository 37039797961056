import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 16,
	},

	title: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		fontSize: 14,
		marginBottom: 8,
	},

	generalDataContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
	},

	generalDataItem: {
		width: "16.6%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: 65,
	},

	hashtagText: {
		color: "#4285F4",
		fontSize: 12,
		marginTop: 6,
	},

	label: {
		color: theme.palette.text.primary,
		fontSize: 12,
		opacity: 0.9,
	},

	value: {
		color: theme.palette.text.primary,
		fontSize: 12,
		fontWeight: 500,
	},

	socialProfileContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginTop: 16,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	selectedProfileImage: {
		height: 25,
		width: 25,
		marginLeft: 4,
		marginRight: 4,
		borderRadius: "50%",
	},

	selectedProfileUsername: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.text.primary,
	},
}));

export default useStyles;

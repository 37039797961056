import { MenuItem } from "@material-ui/core";
import useStyles from "../styles";
import { PostFilters, PostFilterType } from "..";
import Campaign from "types/Campaign";

interface Props {
	campaign: Campaign;
	appliedFilters: PostFilters;
	onFilterClick: (type: PostFilterType, value: string) => void;
}

const CampaignItem = (props: Props) => {
	const classes = useStyles();
	const { campaign, onFilterClick, appliedFilters } = props;

	return (
		<MenuItem
			style={{
				backgroundColor: appliedFilters.find((filter) => filter.value === campaign._id)
					? "#e6e6e6"
					: "",
			}}
			onClick={() => onFilterClick("campaign", campaign._id)}
			className={classes.menuItem}
		>
			<span style={{ textTransform: "capitalize" }}>{campaign.title}</span>
		</MenuItem>
	);
};

export default CampaignItem;

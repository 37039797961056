import React from "react";

export default function ListIconSvg({ size, color }: { size?: number; color?: string }) {
	return (
		<svg
			width={size || "30"}
			height={size || "30"}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.375 9.02778H10.625V6.25H19.375V9.02778ZM19.375 16.3889H10.625V13.6111H19.375V16.3889ZM19.375 23.75H10.625V20.9722H19.375V23.75Z"
				fill={color || "#5B666E"}
			/>
		</svg>
	);
}

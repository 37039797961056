import axios from "axios";
import { Dispatch } from "react";
import config, { CurrentTeamLsKey } from "config";

import { StoreState } from "../../../setup/store";
import { CreateTeamFunc, DeleteTeamFunc, LeaveTeamFunc, UpdateTeamFunc } from "types/ReduxActions";
import { SET_CURRENT_TEAM_ID, SET_TEAMS_DATA } from "../../reducers/teams";
import { setSnackbarState } from "../snackBar";
import { getUserData } from "../user/user-data";
import { getHeaders } from "helpers/utils";

export const setCurrentTeamId = (teamId: string) => {
	return async (dispatch: Dispatch<any>) => {
		localStorage.setItem(CurrentTeamLsKey, teamId);

		dispatch({
			type: SET_CURRENT_TEAM_ID,
			payload: teamId,
		});
	};
};

export const createTeam: CreateTeamFunc = (payload, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams } = getState().teams;
		try {
			const result = await axios.post(`${config.apiUrl}/teams`, payload, { headers: getHeaders() });

			teams.push(result.data);

			// Get updated user data
			dispatch(
				getUserData({}, () => {
					dispatch({
						type: SET_TEAMS_DATA,
						payload: [...teams],
					});

					if (callback) callback(result.data);
				})
			);
		} catch (e) {
			callback(undefined, e.response?.data?.error);
			console.log(e);
		}
	};
};

export const updateTeam: UpdateTeamFunc = (teamId, payload, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams } = getState().teams;

		try {
			await axios.put(`${config.apiUrl}/teams/${teamId}`, payload, {});

			dispatch({
				type: SET_TEAMS_DATA,
				payload: [
					...teams.map((team) => {
						if (team._id === teamId) {
							return {
								...team,
								...payload,
							};
						} else return team;
					}),
				],
			});

			callback && callback();
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "Something went wrong, try again later..."));
		}
	};
};

export const deleteTeam: DeleteTeamFunc = (teamId, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams, currentTeamId } = getState().teams;

		try {
			await axios.delete(`${config.apiUrl}/teams/${teamId}`, {});
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "Unable to delete team, try again later."));
		}

		teams.forEach((team, index) => {
			if (team._id === teamId) teams.splice(index, 1);
		});

		dispatch({
			type: SET_TEAMS_DATA,
			payload: [...teams],
		});

		if (teamId === currentTeamId) {
			dispatch(setCurrentTeamId(teams[0]._id));
		}

		callback();
	};
};

export const leaveTeam: LeaveTeamFunc = (teamId, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams, currentTeamId } = getState().teams;

		try {
			console.log("deleting...");
			await axios.get(`${config.apiUrl}/teams/${teamId}/leave`, {});
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "Unable to leave workspace, try again later."));
		}

		teams.forEach((team, index) => {
			if (team._id === teamId) teams.splice(index, 1);
		});

		dispatch({
			type: SET_TEAMS_DATA,
			payload: [...teams],
		});

		if (teamId === currentTeamId) {
			dispatch(setCurrentTeamId(teams[0]._id));
		}

		callback();
	};
};

export default function ErrorIconSvg2({ width, height }: { width: number; height: number }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 15.9754V13.9754H6V15.9754H10ZM6 16V18H10V16H6ZM6 0V9.83103H10V0H6ZM6 15.9754V16H10V15.9754H6Z"
				fill="#F04755"
			/>
		</svg>
	);
}

import { DateRange, ReplyViewType } from "../..";
import Button from "components/Basic/Button";
import DurationInput from "components/Basic/Inputs/DurationInput";
import SocialProfile from "types/SocialProfile";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import moment from "moment";

interface Props {
	selectedView: ReplyViewType;
	selectedProfiles: SocialProfile[];
	dateRange: DateRange;
	onDateRangeChange: (dateRange: DateRange) => void;
	setPostsDialog: (dialog: boolean) => void;
	setProfilesDialog: (dialog: boolean) => void;
	setSelectedView: (view: ReplyViewType) => void;
}

const Header = (props: Props) => {
	const { t } = useTranslation("reply");
	const classes = useStyles();
	const {
		selectedView,
		selectedProfiles,
		dateRange,
		onDateRangeChange,
		setPostsDialog,
		setProfilesDialog,
		setSelectedView,
	} = props;

	const isTodaySelected = useMemo(
		() =>
			dateRange.startDate?.getTime() === moment().startOf("day").toDate().getTime() ||
			dateRange.endDate?.getTime() === moment().endOf("day").toDate().getTime(),
		[dateRange]
	);

	const handleTodayClick = () => {
		if (isTodaySelected) {
			onDateRangeChange({});
		} else {
			onDateRangeChange({
				startDate: moment().startOf("day").toDate(),
				endDate: moment().endOf("day").toDate(),
			});
		}
	};

	return (
		<div className={classes.headerContainer}>
			<div className={classes.buttonsContainer}>
				<Button
					className={classes.button}
					secondary
					selected={selectedView === "general"}
					onClick={() => setSelectedView("general")}
				>
					{t("general")}
				</Button>

				<Button
					className={classes.button}
					secondary
					selected={selectedView === "bySocialProfile"}
					onClick={() => setProfilesDialog(true)}
				>
					{selectedView === "bySocialProfile"
						? t(selectedProfiles[0].network)
						: "By Social Profile"}
				</Button>

				<Button
					className={classes.button}
					secondary
					selected={selectedView === "byPost"}
					onClick={() => setPostsDialog(true)}
				>
					{t("byPost")}
				</Button>

				<Button
					className={classes.button}
					onClick={() => setSelectedView("yourReplies")}
					secondary
					selected={selectedView === "yourReplies"}
				>
					{t("yourReplies")}
				</Button>
			</div>
			<div className={classes.rightSection}>
				<DurationInput
					height={36}
					width={220}
					startDate={dateRange.startDate}
					endDate={dateRange.endDate}
					onChange={(startDate, endDate) => onDateRangeChange({ startDate, endDate })}
				/>
				<Button
					selected={isTodaySelected}
					onClick={handleTodayClick}
					secondary
					style={{ width: 91, marginLeft: 16 }}
				>
					{t("today")}
				</Button>
			</div>
		</div>
	);
};

export default Header;

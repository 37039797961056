import { GeoGeometryObjects, json } from "d3";

export const geoUrl =
	"https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

export const getMapGeometries = async () => {
	const geo = (await json(geoUrl)) as any;
	const { geometries } = geo.objects.ne_110m_admin_0_countries;

	return geometries as any;
};

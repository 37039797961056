import { Fade, Box, Collapse } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import Interaction from "types/Interaction";
import ReplyDialog from "../ReplyDialog/ReplyDialog";
import CollapsedItem from "./CollapsedItem";
import ExpandedItem from "./ExpandedItem";

interface Props {
	interaction: Interaction;
}

const InteractionItem = (props: Props) => {
	const [expanded, setExpanded] = useState<boolean>(false);
	const [replyDialog, setReplyDialog] = useState<boolean>(false);
	const { interaction } = props;
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);

	const socialProfile = useMemo(
		() => profiles.find((profile) => profile._id === interaction.socialProfile),
		[profiles]
	);

	if (!socialProfile) {
		return null;
	}

	return (
		<>
			<ReplyDialog
				socialProfile={socialProfile}
				interaction={interaction}
				open={replyDialog}
				onClose={() => setReplyDialog(false)}
			/>

			<Fade unmountOnExit timeout={{ enter: 500, exit: 0 }} in={!expanded}>
				<Box>
					<CollapsedItem
						socialProfile={socialProfile}
						handleExpand={() => setExpanded(!expanded)}
						interaction={interaction}
						handleReply={() => setReplyDialog(true)}
					/>
				</Box>
			</Fade>
			<Collapse unmountOnExit timeout={{ enter: 500, exit: 500 }} in={expanded}>
				<Box>
					<ExpandedItem
						socialProfile={socialProfile}
						handleExpand={() => setExpanded(!expanded)}
						interaction={interaction}
						handleReply={() => setReplyDialog(true)}
					/>
				</Box>
			</Collapse>
		</>
	);
};

export default InteractionItem;

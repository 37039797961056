import { ReduxAction } from "types/ReduxActions";

export type Hashtag = {
	name: string;
	usages: number;
	lastUsedAt?: string;
	saved?: boolean;
};

export interface StatsReducerState {
	teamHashtags: Hashtag[];
	teamHashtagsLoading: boolean;

	trendingHashtags: Hashtag[];
	trendingTopics: { name: string; usages: number }[];
	trendingHashtagsLoading: boolean;
}

const initialState: StatsReducerState = {
	trendingHashtags: [],
	trendingTopics: [],
	trendingHashtagsLoading: false,

	teamHashtags: [],
	teamHashtagsLoading: false,
};

export const SET_TRENDING_HASHTAGS = "SET_TRENDING_HASHTAGS";
export const SET_TRENDING_HASHTAGS_LOADING = "SET_TRENDING_HASHTAGS_LOADING";

export const SET_TEAM_HASHTAGS_LOADING = "SET_TEAM_HASHTAGS_LOADING";
export const SET_TEAM_HASHTAGS = "SET_TEAM_HASHTAGS";

const statsReducer = (state: StatsReducerState = initialState, action: ReduxAction) => {
	switch (action.type) {
		case SET_TRENDING_HASHTAGS_LOADING: {
			return {
				...state,
				trendingHashtagsLoading: action.payload,
			};
		}
		case SET_TRENDING_HASHTAGS: {
			return {
				...state,
				trendingHashtags: action.payload.hashtags || state.trendingHashtags,
				trendingTopics: action.payload.topics || state.trendingTopics,
				trendingHashtagsLoading: false,
			};
		}

		case SET_TEAM_HASHTAGS: {
			return {
				...state,
				teamHashtags: action.payload,
				teamhashtagsLoading: false,
			};
		}

		case SET_TEAM_HASHTAGS_LOADING: {
			return {
				...state,
				teamhashtagsLoading: action.payload,
			};
		}

		default:
			return state;
	}
};

export default statsReducer;

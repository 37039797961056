import { Dialog } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import SocialProfile from "types/SocialProfile";
import Button from "../../Basic/Button";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import SocialProfileItem from "./components/SocialProfileItem";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "setup/store";
import { listSocialProfiles } from "redux/actions/socialProfiles/socialProfiles";
import LoadingSpinner from "components/Basic/LoadingSpinner";

interface Props {
	compare?: boolean;
	open: boolean;
	/** If true, social profiles that we cannot access their posting data will be hidden
	 * (such as FaceBook and LinkedIn personal profiles)
	 */
	profilesWithPostsAccessOnly?: boolean;
	handleClose: () => void;
	handleSubmit: (selectedPosts: Array<SocialProfile>) => void;
}

const SelectProfileDialog: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [selectedProfiles, setSelectedProfiles] = useState<SocialProfile[]>([]);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { profiles, profilesLoading } = useSelector((state: StoreState) => state.socialProfiles);
	let { compare, open, profilesWithPostsAccessOnly, handleClose, handleSubmit } = props;

	const filteredProfiles = useMemo(() => {
		if (profilesWithPostsAccessOnly) {
			return profiles.filter(
				(profile) =>
					!(
						(profile.network === "facebook" || profile.network === "linkedin") &&
						profile.type !== "page"
					)
			);
		} else {
			return profiles;
		}
	}, [profiles, profilesWithPostsAccessOnly]);

	const isSubmitDisabled = useMemo(
		() => (compare && selectedProfiles.length < 2) || (!compare && selectedProfiles.length !== 1),
		[selectedProfiles]
	);

	const handleProfileClick = (clickedProfile: SocialProfile) => {
		const clickedProfileIndex = selectedProfiles.findIndex(
			(profile) => profile._id === clickedProfile._id
		);

		if (clickedProfileIndex > -1) {
			selectedProfiles.splice(clickedProfileIndex, 1);
			setSelectedProfiles([...selectedProfiles]);
		} else {
			if (selectedProfiles.length === 1 || (compare && selectedProfiles.length !== 2)) {
				setSelectedProfiles([clickedProfile]);
			} else {
				setSelectedProfiles([...selectedProfiles, clickedProfile]);
			}
		}
	};

	useEffect(() => {
		if (!open) {
			setSelectedProfiles([]);
		}
	}, [open]);

	useEffect(() => {
		if (currentTeamId && profiles.length === 0) {
			dispatch(listSocialProfiles());
		}
	}, [currentTeamId]);

	return (
		<Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
			<div className={classes.container}>
				<div className={classes.title}>
					{compare ? t("select2ProfilesToCompare") : t("selectSocialProfile")}
				</div>

				<LoadingSpinner isVisible={profilesLoading} />
				<div className={classes.profilesContainer}>
					{!profilesLoading &&
						filteredProfiles.map((profile, index) => {
							let isSelected = Boolean(
								selectedProfiles.find((selectedProfile) => selectedProfile._id === profile._id)
							);

							return (
								<SocialProfileItem
									key={index}
									isSelected={isSelected}
									profile={profile}
									compare={compare}
									selectionIndex={selectedProfiles[0]?._id === profile._id ? 1 : 2}
									onClick={handleProfileClick}
								/>
							);
						})}
				</div>

				<div className={classes.buttonsContainer}>
					<Button onClick={handleClose} secondary style={{ width: 166, marginRight: 8 }}>
						{t("cancel")}
					</Button>
					<Button
						onClick={() => handleSubmit(selectedProfiles)}
						disabled={isSubmitDisabled}
						style={{ width: 166 }}
					>
						{t("select")}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default SelectProfileDialog;

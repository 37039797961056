import { Box, Tooltip } from "@material-ui/core";
import AttentionIconSvg from "assets/SVG/AttentionIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import CheckBox from "components/Basic/Inputs/CheckBox";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import { useTranslation } from "react-i18next";
import SocialProfile from "types/SocialProfile";
import useProfilesSelectorStyles from "../styles";

interface Props {
	profile: SocialProfile;
	single?: boolean;
	isChecked: boolean;
	onCheck: (profileId: string) => void;
}

const SocialProfileItem: React.FC<Props> = (props) => {
	const { profile, single, isChecked, onCheck } = props;
	const classes = useProfilesSelectorStyles();
	const { t } = useTranslation();

	return (
		<div
			style={{ cursor: profile.disconnected ? "default" : "" }}
			onClick={() => !profile.disconnected && onCheck(profile._id!)}
			className={classes.profile}
		>
			<NetworkIcon size={18} style={{ marginRight: 8 }} network={profile.network} strokeWidth={3} />

			<SocialProfilePicture profile={profile} style={{ marginRight: 8 }} />

			<span className={classes.profileName}>{profile.displayName}</span>
			{profile.disconnected && (
				<Tooltip title={t("SocialProfileDisconnected") as string}>
					<Box marginLeft={2}>
						<AttentionIconSvg size={20} />
					</Box>
				</Tooltip>
			)}
			<div 
			style={{ opacity: profile.disconnected ? 0.3 : 1 }}
			className={classes.checkboxContainer}>
				<CheckBox
					disabled={profile.disconnected}
					width={14}
					height={14}
					borderRadius={single && "50%"}
					checked={isChecked}
				/>
			</div>
		</div>
	);
};

export default SocialProfileItem;

const DashIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || "32"}
			height={size || "32"}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.3334 17.3327H6.66669V14.666H25.3334V17.3327Z"
				fill={color || "#868C91"}
				stroke={color || "#868C91"}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default DashIconSvg;

import axios from "axios";
import { Dispatch } from "redux";
import config from "config";

import { StoreState } from "../../../setup/store";
import { GetConversationMessagesFunc } from "types/ReduxActions";
import {
	SET_CONVERSATION_MESSAGES,
	SET_MESSAGES_LOADING,
	SET_UNREAD_MESSAGES,
} from "../../reducers/chat";
import { setSnackbarState } from "../snackBar";

export const getUnreadMessagesCount = () => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		const result = await axios.get(
			`${config.apiUrl}/messages/unreadCount?teamId=${currentTeamId}`,
			{}
		);

		dispatch({
			type: SET_UNREAD_MESSAGES,
			payload: result.data,
		});
	};
};

export type MessagesFilters = {
	receiver?: string;
	type?: "channel" | "direct";
	searchValue?: string;
	from?: number;
	pageLimit?: number;
};

export const getConversationMessages: GetConversationMessagesFunc = (filters, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { conversations } = getState().chat;
		const { receiver, type, from } = filters;

		if (!from) {
			dispatch({
				type: SET_MESSAGES_LOADING,
				payload: true,
			});
		}

		try {
			const result = await axios.get(`${config.apiUrl}/messages?teamId=${currentTeamId}`, {
				params: filters,
			});
			const { messages, total } = result.data;

			const oldMessages = conversations.find(
				(conversation) => conversation.receiver === (receiver || "general")
			)?.messages;

			dispatch({
				type: SET_CONVERSATION_MESSAGES,
				payload: {
					receiver: receiver || "general",
					type,
					total,
					messages: from ? [...(oldMessages || []), ...messages] : messages,
				},
			});

			cb && cb({ messages, total });
		} catch (e) {
			console.log(e.response.data);
			dispatch(setSnackbarState(true, "error", "ListMessagesError"));
			cb && cb(undefined);
		}
	};
};

import { List, Drawer as MUIDrawer, ListItem, ListItemIcon, CssBaseline } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router";
import useStyles from "../styles/container-styles";
import { MenuDots, Slogan } from "assets";
import { bottomDrawerList, topDrawerList } from "../pages";
import LogoSvg from "assets/SVG/LogoSvg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import DrawerItem from "./DrawerItem";

interface Props {
	open: boolean;
	setSearchValue: (value: string) => void;
	setOpen: (open: boolean) => void;
}

const Drawer = (props: Props) => {
	const classes = useStyles();
	const history = useHistory();
	const { open, setOpen, setSearchValue } = props;

	const pathname: string = history.location.pathname.split("/:")[0];

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	useEffect(() => {
		setSearchValue("");
	}, [history.location]);

	return (
		<>
			<CssBaseline />
			<MUIDrawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<List>
					<Link to="/home">
						<div className={classes.logoContainer}>
							<LogoSvg size={30} />
							{open && <img alt="slogan" src={Slogan} style={{ height: 19, marginLeft: 16 }} />}
						</div>
					</Link>
					<ListItem className={classes.listItem}>
						<ListItemIcon style={{ cursor: "pointer" }} onClick={handleDrawerOpen}>
							<img
								alt="menu-dots"
								src={MenuDots}
								className={classes.menuIcon}
								style={{ paddingLeft: !open ? "" : 8 }}
							/>
						</ListItemIcon>
					</ListItem>
					{topDrawerList.map((item, index) => (
						<DrawerItem
							item={item}
							key={index}
							selected={pathname === item.url || item.subUrls?.includes(pathname)}
						/>
					))}
				</List>

				<List className={classes.bottomList}>
					{bottomDrawerList.map((item, index) => (
						<DrawerItem
							item={item}
							key={index}
							selected={pathname === item.url || item.subUrls?.includes(pathname)}
						/>
					))}
				</List>
			</MUIDrawer>
		</>
	);
};

export default Drawer;

import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Fade, Box, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import MainHeader from "./Components/MainHeader";
import useStyles from "./styles/container-styles";
import Drawer from "./Components/Drawer";
import GeneralSearchPage from "../../pages/GeneralSearch";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "redux/actions/auth/checkAuth";
import { StoreState } from "../../setup/store";
import SnackBar from "../Basic/Snackbar";
import { setSnackbarState } from "redux/actions/snackBar";
import Dialogs from "../Dialogs/Dialogs";
import { SET_SIGN_IN_RESPONSE } from "redux/reducers/user";
import { getUnreadMessagesCount } from "redux/actions/chat/get";
import { setChatOpen } from "redux/actions/chat";
import { deleteQueryParam, useQuery } from "helpers/utils";
import {
	confirmationSuccessQuery,
	emailConfirmationCodeQuery,
	unsubscribeEmailsQuery,
} from "config";
import { confirmEmailAddress } from "redux/actions/auth";
import { handleUnsubscribeEmailsQuery } from "redux/actions/user/settings";
import Onboarding from "components/Onboarding/Onboarding";
import ComingSoonDialog from "components/Dialogs/ComingSoonDialog";
import { useTranslation } from "react-i18next";

interface Props {
	page: string;
	children: ReactNode;
}

const Container = (props: Props) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const query = useQuery();
	const { userData, authenticated, authLoading } = useSelector((state: StoreState) => state.user);
	const { open, message, severity } = useSelector((state: StoreState) => state.snackbar);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { unreadMessages } = useSelector((state: StoreState) => state.chat);
	const [mobileNotSupportedDialog, setMobileNotSupportedDialog] = useState(false);
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const { t } = useTranslation();
	const { page, children } = props;

	const emailsUnsubscribed = useRef(false);

	const scrollToTop = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;
	};

	useEffect(() => {
		scrollToTop();
		dispatch(setChatOpen(false));
		dispatch(checkAuth());

		const isMobile = window.innerWidth < 900;

		if (isMobile) {
			setMobileNotSupportedDialog(true);
		} else {
			setMobileNotSupportedDialog(false);
		}
	}, [history.location.pathname]);

	useEffect(() => {
		if (currentTeamId && unreadMessages === null) {
			dispatch(getUnreadMessagesCount());
		}
	}, [currentTeamId]);

	useEffect(() => {
		if (authLoading) {
			return;
		}

		const emailConfirmationCode = query.get(emailConfirmationCodeQuery);

		if (query.get(confirmationSuccessQuery)) {
			dispatch(setSnackbarState(true, "success", "EmailConfirmationSuccess"));
			deleteQueryParam(confirmationSuccessQuery);
		} else if (query.get(unsubscribeEmailsQuery) && !emailsUnsubscribed.current) {
			dispatch(handleUnsubscribeEmailsQuery());
			emailsUnsubscribed.current = true;
		}

		if (userData && emailConfirmationCode) {
			dispatch(confirmEmailAddress(emailConfirmationCode));
		} else if (!authenticated && !userData && !history.location.pathname.includes("/auth/")) {
			history.push("/login");
		} else {
			// Continue sign up steps if user has not yet completed it
			if (authenticated && (!userData?.firstName || !userData?.teams[0] || !userData?.email)) {
				history.push("/signup/general-info");
			} else if (userData?.email && !userData.emailConfirmed) {
				history.push("signup/verification");
			} else {
				dispatch({
					type: SET_SIGN_IN_RESPONSE,
					payload: null,
				});
			}
		}
	}, [authLoading, userData]);

	return (
		<>
			<ComingSoonDialog
				hideTitle
				open={mobileNotSupportedDialog}
				message={t("mobileNotSupported")}
				onClose={() => setMobileNotSupportedDialog(false)}
			/>
			<MainHeader
				page={page}
				drawerOpen={drawerOpen}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
			/>

			<SnackBar
				open={open}
				severity={severity}
				message={message}
				handleClose={() => dispatch(setSnackbarState(false, severity, ""))}
			/>

			<Dialogs />

			<div className={classes.root}>
				<Drawer open={drawerOpen} setSearchValue={setSearchValue} setOpen={setDrawerOpen} />
				<main
					className={classes.content}
					style={{ marginLeft: drawerOpen ? 50 : 0, transition: "0.8s" }}
				>
					{authLoading && (
						<Box className={classes.loadingContainer}>
							<CircularProgress />
						</Box>
					)}
					{/* Replace the current page with the general search one if there's search input */}
					<Fade
						in={Boolean(searchValue.length !== 0) && !authLoading && userData?.emailConfirmed}
						unmountOnExit
						timeout={{ enter: 500, exit: 0 }}
					>
						<Box>
							<GeneralSearchPage searchValue={searchValue} setSearchValue={setSearchValue} />
						</Box>
					</Fade>

					<Fade
						in={Boolean(searchValue.length === 0) && !authLoading && userData?.emailConfirmed}
						unmountOnExit
						timeout={{ enter: 500, exit: 0 }}
					>
						<Box>{children}</Box>
					</Fade>
				</main>

				<Onboarding />

				{/* <Chat /> */}
			</div>
		</>
	);
};

export default Container;

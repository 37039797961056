import MarkdownText from "components/Basic/Text/MarkdownText";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import OptionsPopover from "components/Popovers/OptionsPopover";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TeamUser, TeamUserRole } from "types/User/Team";

interface Props {
	teamUser: TeamUser;
	anchorEl: HTMLElement | null;
	onChange: (role: TeamUserRole) => void;
	onClose: () => void;
}

const ChangeRolePopover: React.FC<Props> = (props) => {
	const { t } = useTranslation("workspaces");
	const [ownerConfirmDialog, setOwnerConfirmDialog] = useState(false);
	const { teamUser, anchorEl, onChange, onClose } = props;

	const handleOptionClick = (option: TeamUserRole) => {
		if (option === "owner" && !ownerConfirmDialog) {
			setOwnerConfirmDialog(true);
		} else {
			onChange(option);
			setOwnerConfirmDialog(false);
		}
	};

	return (
		<>
			<ConfirmationDialog
				open={ownerConfirmDialog}
				message={<MarkdownText text={t("ownerConfirmDialogMessage")} />}
				onClose={() => setOwnerConfirmDialog(false)}
				onAction={() => handleOptionClick("owner")}
				textMaxWidth={550}
				messageStyle={{ textAlign: "start", fontSize: 14 }}
			/>
			<OptionsPopover
				anchorEl={anchorEl}
				onClose={onClose}
				onChange={(value) => handleOptionClick(value as TeamUserRole)}
				options={[
					{ label: t("owner"), value: "owner" },
					{ label: t("admin"), value: "admin" },
					{ label: t("writer"), value: "writer" },
				]}
				value={teamUser.role}
			/>
		</>
	);
};

export default ChangeRolePopover;

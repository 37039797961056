import { Box, Fade, Popover } from "@material-ui/core";
import { ChangeEvent, useState, KeyboardEvent, MouseEvent, CSSProperties } from "react";
import useMessageInputStyles from "../styles/message-input";
import Picker, { IEmojiData, SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import EmojiIconSvg from "assets/SVG/EmojiIconSvg";
import AttachmentIconSvg from "assets/SVG/AttachmentIconSvg";
import { Media, MediaType } from "types/Task";
import MediaAttachment from "components/Media/MediaAttachment";
import AttachmentPreviewDialog from "components/Dialogs/AttachmentPreview/AttachmentPreview";
import {
	FileUploadProgress,
	handleUploadProgressChange,
	uploadMediaArray,
} from "redux/actions/media/upload";
import SendButtonSvg from "assets/SVG/SendButtonSvg";
import MentionsInput from "components/Basic/Inputs/MentionsInput";
import { OnChangeHandlerFunc } from "react-mentions";

interface Props {
	onSubmit: (value: CommentPayload) => void;
	style?: CSSProperties;
	placeholder: string;
}

const fileInputId = "chat-upload-input";

export type CommentPayload = {
	mentions: string[];
	value: string;
	media: Media[];
};

const MessageInput = (props: Props) => {
	const classes = useMessageInputStyles();
	const [commentPayload, setCommentPayload] = useState<CommentPayload>({
		value: "",
		mentions: [],
		media: [],
	});
	const [media, setMedia] = useState<Media[]>([]);
	const [emojiPicker, setEmojiPicker] = useState<HTMLElement | null>(null);
	const [selectedAttachment, setSelectedAttachment] = useState<Media>();
	const [attachmentDialog, setAttachmentDialog] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [mediaUploadProgress, setMediaUploadProgress] = useState<FileUploadProgress[]>([]);
	const { onSubmit, style, placeholder } = props;

	const handleSubmit = async () => {
		if (loading) {
			return;
		}
		setLoading(true);

		let uploadedMedia: Media[] = await uploadMediaArray(media, (newProgress) =>
			handleUploadProgressChange(mediaUploadProgress, setMediaUploadProgress, newProgress)
		);

		onSubmit({ ...commentPayload, media: uploadedMedia });
		setCommentPayload({ value: "", mentions: [], media: [] });
		setMedia([]);
		setLoading(false);
		setMediaUploadProgress([]);
	};

	const handleEmojiClick = (e: MouseEvent, data: IEmojiData) => {
		const { emoji } = data;
		setCommentPayload({ ...commentPayload, value: commentPayload.value + emoji });
	};

	const handleInputChange: OnChangeHandlerFunc = (e, value, newPlainTextValue, mentions) => {
		setCommentPayload({
			...commentPayload,
			value,
			mentions: mentions.map((mention) => mention.id.split("user/")[1]),
		});
	};

	const handleKeyDown = (
		e: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>
	) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			if (commentPayload.value.replace(/\s/g, "").length) {
				handleSubmit();
			}
		}
	};

	const handleFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (!files) return;

		const updatedMedia: Media[] = [
			...(media || []),
			...Array.from(files).map((file) => ({
				type: file.type.split("/")[0] as MediaType,
				value: file,
			})),
		];

		if (updatedMedia.length > 8) {
			updatedMedia.splice(0, updatedMedia.length - 8);
		}

		setMedia(updatedMedia);
	};

	const handleFileInputClick = () => {
		const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
		if (fileInput) {
			fileInput.value = "";
		}
	};

	const handleAttachmentClick = (attachment: Media) => {
		setSelectedAttachment(attachment);
		setAttachmentDialog(true);
	};

	const handleAttachmentDelete = (attachment: Media | undefined) => {
		if (attachment) {
			setMedia([...media.filter((item) => item.value !== attachment.value)]);
		}
	};

	return (
		<Box style={style} className={classes.container}>
			<AttachmentPreviewDialog
				open={attachmentDialog}
				onClose={() => setAttachmentDialog(false)}
				attachment={selectedAttachment}
				onDeleteClick={() => handleAttachmentDelete(selectedAttachment)}
			/>

			<Popover
				open={Boolean(emojiPicker)}
				anchorEl={emojiPicker}
				onClose={() => setEmojiPicker(null)}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<Picker
					onEmojiClick={handleEmojiClick}
					disableAutoFocus={true}
					skinTone={SKIN_TONE_MEDIUM_DARK}
					groupNames={{ smileys_people: "PEOPLE" }}
					native
				/>
			</Popover>

			<MentionsInput
				value={commentPayload.value}
				placeholder={placeholder}
				onKeyDown={handleKeyDown}
				onChange={handleInputChange}
				controlStyle={{ minHeight: 80 }}
				inputStyle={{ border: "none" }}
			/>

			<Box className={classes.attachments}>
				{media.map((mediaItem, index) => (
					<MediaAttachment
						key={index}
						onAttachmentClick={() => handleAttachmentClick(mediaItem)}
						attachment={mediaItem.value}
						type={mediaItem.type}
						thumbnailKey={mediaItem.thumbnail}
						style={{ height: 35, width: 35, borderRadius: 6 }}
						playButtonStyle={{ height: 18 }}
						progressSize={18}
						uploadProgress={mediaUploadProgress[index]}
					/>
				))}
			</Box>

			<Fade in={Boolean(commentPayload.value) || media.length > 0} unmountOnExit>
				<Box onClick={handleSubmit} className={classes.sendButtonContainer}>
					<SendButtonSvg size={50} />
				</Box>
			</Fade>

			<div className={classes.actions}>
				<Box onClick={(e) => setEmojiPicker(e.currentTarget)} className={classes.actionItem}>
					<EmojiIconSvg size={16} />
				</Box>
				<input
					accept="image/*|video/*"
					id={fileInputId}
					type="file"
					onClick={handleFileInputClick}
					multiple={true}
					style={{ display: "none" }}
					onChange={handleFilesChange}
				/>
				<Box
					onClick={() => document.getElementById(fileInputId)?.click()}
					className={classes.actionItem}
				>
					<AttachmentIconSvg size={16} />
				</Box>
			</div>
		</Box>
	);
};

export default MessageInput;

import axios from "axios";
import config from "config";
import { getHeaders } from "helpers/utils";
import { Dispatch } from "react";
import { InsightsEntry, InsightsReducerState, SET_INSIGHTS_STATE } from "redux/reducers/insights";
import { StoreState } from "setup/store";
import { setSnackbarState } from "./snackBar";

export const getListenPageData = (cb?: () => void) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		try {
			const result = await axios.get(
				`${config.apiUrl}/socialProfiles/insights?teamId=${currentTeamId}`,
				{
					params: {},
					headers: getHeaders(),
				}
			);

			let state: Partial<InsightsReducerState> = {
				dailyPostReactions: [],
				profileImpressions: [],
				profileEngagements: [],
				dailyProfileLikes: [],
				totalProfileLikes: 0,
				totalViews: 0,
			};

			let latestTotalProfileLikesEntries: InsightsEntry[] = [];

			result.data.insights.reverse().forEach((entry: InsightsEntry) => {
				const { socialProfile } = entry.metadata;
				if (entry.metadata.name === "totalProfileLikes") {
					const isProfileCounted = latestTotalProfileLikesEntries.find(
						(entry) => entry.metadata.socialProfile === socialProfile
					);
					
					if (!isProfileCounted) {
						latestTotalProfileLikesEntries.push(entry);
						state.totalProfileLikes = (state.totalProfileLikes || 0) + entry.metadata.value;
					}
				} else {
					(state as any)[entry.metadata.name]?.unshift(entry);
				}

				if (entry.metadata.name === "profileImpressions") {
					state.totalViews = (state.totalViews || 0) + entry.metadata.value;
				}
			});

			dispatch({
				type: SET_INSIGHTS_STATE,
				payload: state,
			});

			console.log(result.data);
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));

			console.log(e);
		}

		cb?.();
	};
};

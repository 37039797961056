import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listSubscriptionPlans } from "redux/actions/team/subscriptions/plans";
import { getUserData } from "redux/actions/user/user-data";
import { StoreState } from "setup/store";
import Team from "types/User/Team";
import TeamDetails from "./components/TeamDetails";
import TeamsList from "./components/TeamsList/TeamsList";

const Workspaces = () => {
	const [newTeam, setNewTeam] = useState<boolean>(false);
	const [selectedTeam, setSelectedTeam] = useState<Team>();
	const dispatch = useDispatch();
	const { teams, currentTeamId } = useSelector((state: StoreState) => state.teams);

	useEffect(() => {
		dispatch(getUserData({ populateTeamUsers: true }));
		dispatch(listSubscriptionPlans());
	}, []);

	useEffect(() => {
		if (teams.length === 0 || newTeam) {
			return;
		}

		setSelectedTeam(teams.find((team) => team._id === currentTeamId) || teams[0]);
	}, [teams, newTeam]);

	return (
		<Box>
			<Box display="flex">
				{(selectedTeam || newTeam) && (
					<TeamDetails team={selectedTeam} newTeam={newTeam} setNewTeam={setNewTeam} />
				)}

				<TeamsList
					selectedTeam={selectedTeam}
					setSelectedTeam={setSelectedTeam}
					setNewTeam={setNewTeam}
				/>
			</Box>
		</Box>
	);
};

export default Workspaces;

import { Box } from "@material-ui/core";
import { Tab } from "components/Tabs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Hashtag } from "redux/reducers/stats";
import { StoreState } from "setup/store";
import HashtagListItem from "./HashtagListItem";

interface Props {
	isDrawerLoading: boolean;
	hashtagsList: Hashtag[];
	selectedTab: Tab;
    extended: boolean;
	onHashtagClick: (hashtag: Hashtag) => void;
}

const HashtagsList: React.FC<Props> = (props) => {
	const { isDrawerLoading, hashtagsList, selectedTab, extended, onHashtagClick } = props;
	const { teamHashtags } = useSelector((state: StoreState) => state.stats);
    const {t} = useTranslation('posts');
    
	return (
		<Box marginTop={3} style={{ overflowY: "auto", height: "calc(100vh - 250px)" }}>
			{!isDrawerLoading && (
				<>
					{hashtagsList?.map((listItem, index) => {
						const isHashtagSaved =
							selectedTab.name === "popular"
								? Boolean(
										teamHashtags.find(
											(teamHashtag) => teamHashtag.name === listItem.name && teamHashtag.saved
										)
								  )
								: listItem.saved;

						return (
							<HashtagListItem
								hashtag={{ ...listItem, saved: isHashtagSaved }}
								key={index}
								stats={selectedTab.name === "recent" || selectedTab.name === "saved"}
								isSaveButtonVisible={selectedTab.name !== "topics" && extended}
								onClick={onHashtagClick}
							/>
						);
					})}

					{!Boolean(hashtagsList?.length) && (
						<Box style={{ fontSize: 12, paddingLeft: 32, marginTop: 16 }}>
							{selectedTab.name === "topics" ? t("noTopicsFound") : t("noHashtagsFound")}
						</Box>
					)}
				</>
			)}
		</Box>
	);
};

export default HashtagsList;

import { capitalize } from "lodash";
import { ReactNode } from "react";
import theme from "setup/Theme";

export const onboardingSteps: { [stepName: string]: OnboardingStep } = {
	home: {
		getContent: ({ firstName }) => (
			<div>
				Hi {firstName}!
				<br /> <br />
				Welcome to{" "}
				<span style={{ color: theme.palette.primary.main, fontWeight: 500 }}>
					#HashtagHero
				</span>
				. Are you ready?
				<br />
				<br />
				You need to start posting to get your statistics. <br />
				First of all, let's add your Social Profile.
			</div>
		),
		actions: [{ secondary: true, type: "addSocialProfile" }, { type: "next" }],
	},
	home2: {
		getContent: ({ socialProfilesLimit, subscriptionPlanName }) => (
			<div>
				You can add{" "}
				<span style={{ color: theme.palette.primary.main }}>
					{socialProfilesLimit} social profiles
				</span>{" "}
				according to your{" "}
				<span style={{ color: theme.palette.primary.main }}>
					subscription plan - {capitalize(subscriptionPlanName)}
				</span>
				<br />
				<br />
				Let's see what options you have now
			</div>
		),
		actions: [{ secondary: true, type: "seeMySubscriptionPlan" }],
	},
	socialProfiles: {
		getContent: () => (
			<div>
				Click on the “Add Social Profile” button to add your first profile. Just few clicks and you
				are ready for professional posting!
			</div>
		),
		actions: [{ secondary: true, type: "close" }],
	},
};

export type OnboardingStepName = "home" | "home2" | "socialProfiles";
export type OnboardingActionType =
	| "next"
	| "addSocialProfile"
	| "seeSubscriptionPlans"
	| "seeMySubscriptionPlan"
	| "close";

export type OnboardingStep = {
	actions: OnboardingAction[];
	getContent: (contentData: OnboardingContentData) => ReactNode;
};

export type OnboardingAction = {
	secondary?: boolean;
	type: OnboardingActionType;
};

export type OnboardingContentData = {
	firstName: string;
	subscriptionPlanName: string;
	socialProfilesLimit: number;
};

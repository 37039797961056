import { Box, CircularProgress } from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DeleteConfirmImage } from "assets";
import Button from "components/Basic/Button";
import Input from "components/Basic/Inputs/Input";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { nameRegex } from "helpers/utils";
import { signOut } from "redux/actions/auth";
import { deleteUserData, updateUser, validateUsername } from "redux/actions/user/user-data";
import { StoreState } from "../../../../../../setup/store";
import usePageStyles from "../../styles";
import useStyles from "../styles/personal-info-styles";
import clsx from "clsx";

type InputValues = {
	firstName?: string;
	lastName?: string;
	country?: string;
	companyName?: string;
};

const ChangeProfileInfo = () => {
	const { userData, authLoading } = useSelector((state: StoreState) => state.user);
	const [inputValues, setInputValues] = useState<InputValues>({});
	const [errors, setErrors] = useState<InputValues>({});
	const [updated, setUpdated] = useState<boolean>(false);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const pageClasses = usePageStyles();
	const { t } = useTranslation("settings");

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setUpdated(true);

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleSubmit = () => {
		const { firstName, lastName, companyName, country } = inputValues;
		let errors: InputValues = {};

		const firstNameError = validateUsername(firstName || "");
		const lastNameError = validateUsername(lastName || "");

		if (firstNameError) {
			errors.firstName = t(`common:${firstNameError}`);
		}

		if (lastNameError) {
			errors.lastName = t(`common:${lastNameError}`);
		}

		if (!companyName) {
			errors.companyName = t("emptyCompanyNameError");
		}

		if (country && !nameRegex.test(country)) {
			errors.country = t("invalidCountry");
		}

		if (Object.keys(errors).length === 0) {
			dispatch(updateUser(inputValues));
			setUpdated(false);
		}
		setErrors({ ...errors });
	};

	const handlAccountDelete = () => {
		dispatch(
			deleteUserData(() => {
				dispatch(signOut());
			})
		);
	};

	useEffect(() => {
		if (!userData) return;
		const { companyName, country, firstName, lastName } = userData;
		setInputValues({
			companyName,
			country,
			firstName,
			lastName,
		});
	}, [userData]);

	return (
		<div style={{ width: "40%", marginRight: 16 }}>
			<ConfirmationDialog
				open={deleteConfirmation}
				onClose={() => setDeleteConfirmation(false)}
				onAction={handlAccountDelete}
				confirmButton={t("deleteAccount")}
				message={t("deleteAccountMessage")}
				textMaxWidth={450}
				actionLoading={deleteLoading}
				image={DeleteConfirmImage}
			/>
			<div className={clsx(pageClasses.card, classes.card)}>
				<Box className={pageClasses.innerCardTitle}>{t("personalInformation")}</Box>

				<div className={pageClasses.inputTitle}>{t("firstName")}</div>
				<Input
					style={{ width: "100%", fontSize: 12 }}
					inputStyle={{ width: "100%" }}
					name="firstName"
					onChange={handleInputChange}
					error={errors.firstName}
					value={inputValues.firstName || ""}
				/>
				<div style={{ marginTop: 16 }}>
					<div className={pageClasses.inputTitle}>{t("lastName")}</div>
					<Input
						style={{ width: "100%", fontSize: 12 }}
						inputStyle={{ width: "100%" }}
						name="lastName"
						onChange={handleInputChange}
						error={errors.lastName}
						value={inputValues.lastName || ""}
					/>
				</div>

				<div style={{ marginTop: 16 }}>
					<div className={pageClasses.inputTitle}>{t("companyName")}</div>
					<Input
						style={{ width: "100%", fontSize: 12 }}
						inputStyle={{ width: "100%" }}
						name="companyName"
						onChange={handleInputChange}
						error={errors.companyName}
						value={inputValues.companyName || ""}
					/>
				</div>

				<div style={{ marginTop: 16 }}>
					<div className={pageClasses.inputTitle}>{t("country")}</div>
					<Input
						style={{ width: "100%", fontSize: 12 }}
						inputStyle={{ width: "100%" }}
						name="country"
						onChange={handleInputChange}
						error={errors.country}
						value={inputValues.country || ""}
					/>
				</div>

				<div className={classes.buttonsContainer}>
					<Button
						secondary
						style={{ width: 170, marginRight: 12 }}
						onClick={() => setDeleteConfirmation(true)}
					>
						{t("deleteAccountData")}
					</Button>

					<div className={classes.rightButtonsContainer}>
						<Button
							disabled={!updated || authLoading}
							onClick={handleSubmit}
							secondary
							style={{ width: 140 }}
						>
							{authLoading ? <CircularProgress size={20} /> : t("saveChanges")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangeProfileInfo;

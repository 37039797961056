import { CircularProgress } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { ProfilePicturePlaceholder } from "assets";
import { getImage } from "helpers/utils";
import ProfilePhotoEditor from "../PhotoCropper";
import Button from "../../Basic/Button";
import useImagePickerStyles from "./styles";

interface Props {
	imageUrl: string | undefined;
	placeholderImage?: string;
	loading?: boolean;
	size?: number;
	onSubmit: (file: File | undefined) => void;
}

const imageId = "image-preview";
const fileInputId = "file-upload-input";

const ImagePicker = (props: Props) => {
	const [selectedFile, setSelectedFile] = useState<File>();
	const [editorDialog, setEditorDialog] = useState<boolean>(false);
	const [pictureHovered, setPictureHovered] = useState<boolean>(false);
	const [imagePreview, setImagePreview] = useState<string>("");
	const classes = useImagePickerStyles();
	const { imageUrl, loading, size, onSubmit, placeholderImage } = props;

	const handleImageLoadError = () => {
		const imageElement = document.getElementById(imageId) as HTMLImageElement;
		if (!imageElement) return;

		imageElement.src = ProfilePicturePlaceholder;
	};

	const handleSubmit = (file: File | undefined) => {
		if (!file) return;
		onSubmit(file);
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (!files || !files[0]) return;
		setSelectedFile(files[0]);
		setEditorDialog(true);
	};

	const handleFileInputClick = () => {
		const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
		if (fileInput) {
			fileInput.value = "";
		}
	};

	return (
		<>
			<ProfilePhotoEditor
				file={selectedFile as File}
				setSelectedFile={handleSubmit}
				open={editorDialog}
				handleClose={() => setEditorDialog(false)}
				setImagePreview={setImagePreview}
			/>

			<div
				onMouseEnter={() => setPictureHovered(true)}
				onMouseLeave={() => setPictureHovered(false)}
				className={classes.pictureContainer}
			>
				<img
					id={imageId}
					className={classes.profilePicture}
					onError={handleImageLoadError}
					style={{
						filter: pictureHovered ? "brightness(60%)" : "",
						height: size,
						width: size,
						minWidth: size,
						minHeight: size,
					}}
					src={
						imagePreview ||
						(imageUrl ? getImage(imageUrl) : placeholderImage || ProfilePicturePlaceholder)
					}
				/>

				<input
					accept="image/*"
					id={fileInputId}
					type="file"
					style={{ display: "none" }}
					onChange={handleFileChange}
					name="file"
					onClick={handleFileInputClick}
				/>
				{(pictureHovered || editorDialog || loading) && (
					<div className={classes.imageOverlayContainer}>
						<label htmlFor={fileInputId} id="input-label">
							<Button
								disabled={loading}
								onClick={() => document.getElementById("input-label")?.click()}
								style={{ width: 70, fontSize: 10, height: 30 }}
								secondary
							>
								{loading ? <CircularProgress size={20} /> : "Change Pic"}
							</Button>
						</label>
					</div>
				)}
			</div>
		</>
	);
};

export default ImagePicker;

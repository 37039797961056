import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	container: {
		height: 400,
		overflowY: "scroll",
		paddingLeft: 8,
		paddingRight: 8,
	},

	periodContainer: {
		width: "100%",
		display: "flex",
		flexDireciton: "row",
	},

	timeContainer: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		minHeight: 50,
		width: 60,
	},

	timeText: {
		fontFamily: "Avenir",
		fontSize: 10,
		letterSpacing: 0.4,
		color: "#77808E",
		marginTop: 0,
	},

	itemContainer: {
		backgroundColor: "#F7F7F7",
		height: 35,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		fontFamily: "Avenir",
		alignItems: "center",
		marginBottom: 4,
		marginLeft: 8,
		cursor: "pointer",
		borderRadius: 5,
	},

	itemText: {
		display: "flex",
		flexDirection: "column",
		paddingLeft: 16,
	},

	itemColor: {
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
		height: "100%",
		width: 7.5,
	},

	itemTitle: {
		color: "#3E4C55",
		fontSize: 12,
		letterSpacing: 0.4,
		fontWeight: 500,
	},

	eventsContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},

	socialMediaContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: 16,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},

	socialMediaIcon: {
		height: 15,
		marginLeft: 6,
		marginRight: 6,
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 32,
	},
}));

export default useStyles;

import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import config from "config";
import { useQuery } from "helpers/utils";
import { AuthProvider, getTokensFromCode } from "redux/actions/auth/socialAuth";
import Network from "types/Network";
import useStyles from "../styles";
import { checkAuth } from "redux/actions/auth/checkAuth";

interface Props {
	authType: "login" | "signup";
}

const SocialAuthButtons = ({ authType }: Props) => {
	const classes = useStyles();
	const query = useQuery();
	const dispatch = useDispatch();
	const history = useHistory();

	const handleSocialClick = (network: Network) => {
		let clientId = "";
		let provider = "";
		const scope = "email+openid+profile+aws.cognito.signin.user.admin";

		if (network === "google") {
			clientId = config.GoogleCognitoClientId;
			provider = "Google";
		} else if (network === "facebook") {
			clientId = config.FacebookCognitoClientId;
			provider = "Facebook";
		}

		window.location.href = `${config.cognitoAuthUrl}/login?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${config.cognitoCallbackURL}?provider=${provider}`;
	};

	const handleCodeQueryParameter = async (code: string, provider: string) => {
		const { success } = await getTokensFromCode(code, provider as AuthProvider);

		if (success) {
			dispatch(
				checkAuth((currentUser, userData, error) => {
					if (!userData) {
						history.push("/signup/general-info");
					} else {
						history.push("/");
					}
				})
			);
		}
	};

	useEffect(() => {
		const code = query.get("code");
		const provider = query.get("provider");
		if (code && provider) {
			handleCodeQueryParameter(code, provider);
		}
	}, []);

	return (
		<div style={{ marginTop: 32 }}>
			<Box display="flex" alignItems="center">
				<div className={classes.divider}></div>
				<div className={classes.altSignUpText}>
					Or Sign {authType === "login" ? "In" : "Up"} With
				</div>
				<div className={classes.divider}></div>
			</Box>

			<Box display="flex" alignItems="center" justifyContent="center">
				<div onClick={() => handleSocialClick("facebook")} className={classes.socialButton}>
					<NetworkIcon network="facebook" size={28} strokeWidth={2} />
				</div>
				<div onClick={() => handleSocialClick("google")} className={classes.socialButton}>
					<NetworkIcon network="google" size={22} />
				</div>
				{/* <div className={classes.socialButton}>
                    <NetworkIcon network='apple' size={22} />
                </div> */}
			</Box>
		</div>
	);
};

export default SocialAuthButtons;

import { CircularProgress } from "@material-ui/core";
import Button from "components/Basic/Button";
import { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import useBillingMethodDialogStyles from "../styles";

interface Props {
	onSubmit: (e: FormEvent<HTMLFormElement>) => void;
	onClose: () => void;
	loading: boolean;
	actionButtonText: string;
}

const ActionButtons = (props: Props) => {
	const classes = useBillingMethodDialogStyles({});
	const { t } = useTranslation("settings");
	const { onSubmit, onClose, actionButtonText, loading } = props;

	return (
		<div className={classes.buttonsContainer}>
			<Button
				disabled={loading}
				onClick={onClose}
				secondary
				style={{ width: 150, marginRight: 10 }}
			>
				{t("cancel")}
			</Button>

			<Button disabled={loading} onClick={onSubmit} style={{ width: 150 }}>
				{loading ? <CircularProgress size={20} /> : actionButtonText}
			</Button>
		</div>
	);
};

export default ActionButtons;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import useStyles from "./styles";
import CalendarEvent from "types/CalendarEvent";
import DailyCalendar from "modules/Calendar/DailyCalendar";
import PlusIconTransparent from "assets/plus-icon-transparent.png";
import Tabs, { Tab } from "../../components/Tabs";
import CalendarEventDialog from "modules/Calendar/CalendarEventDialog/CalendarEventDialog";
import { getCurrentDate } from "helpers/dateAndTime";

const useComponentStyles = makeStyles(() => ({
	container: {
		marginTop: 12,
		width: "100%",
		height: 430,
		backgroundColor: "white",
		position: "relative",
		overflow: "hidden",
		borderRadius: 10,
	},

	innerContainer: {
		paddingLeft: 16,
		paddingRight: 24,
		marginBottom: 16,
		paddingTop: 32,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	tabsContainer: {
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		marginLeft: "auto",
		marginTop: 8,
		alignItems: "center",
	},

	plusIcon: {
		height: 25,
		marginLeft: 8,
		marginTop: 4,
		cursor: "pointer",
	},
}));

interface Props {
	selectedTab: Tab | undefined;
	setSelectedTab: (tab: Tab) => void;
}

const tabs = ["TODAY", "THIS WEEK", "THIS MONTH"];

export default function AltCalendar({ selectedTab, setSelectedTab }: Props) {
	const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>();
	const [eventDialog, setEventDialog] = useState<boolean>(false);
	const classes = useComponentStyles();
	const mainClasses = useStyles();

	const handleTabChange = (selectedTab: Tab) => {
		setSelectedTab(selectedTab);
	};

	return (
		<div style={{ width: "50%" }}>
			<div className={classes.header}>
				<span style={{ marginLeft: 24, marginBottom: -8 }} className={mainClasses.title}>
					{getCurrentDate()}
				</span>

				<img
					onClick={() => setEventDialog(true)}
					src={PlusIconTransparent}
					className={classes.plusIcon}
				/>

				<div className={classes.tabsContainer}>
					<Tabs tabs={tabs} onTabChange={handleTabChange} selectedTab={selectedTab} />
				</div>
			</div>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<DailyCalendar
						height={380}
						eventDialog={eventDialog}
						setEventDialog={setEventDialog}
						setSelectedEvent={setSelectedEvent}
					/>
				</div>
			</div>

			<CalendarEventDialog
				open={eventDialog}
				handleClose={() => setEventDialog(false)}
				selectedEvent={selectedEvent}
			/>
		</div>
	);
}

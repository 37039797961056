export default function HeartIconSvg({ size, selected }: { size?: number; selected?: boolean }) {
	return (
		<svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.15058 2.91602C3.67418 2.91602 1.66666 4.92354 1.66666 7.39994C1.66666 8.58915 2.13907 9.72966 2.97997 10.5706L9.60715 17.1977C9.82411 17.4147 10.1759 17.4147 10.3928 17.1977L17.02 10.5706C17.8609 9.72966 18.3333 8.58915 18.3333 7.39994C18.3333 4.92354 16.3258 2.91602 13.8494 2.91602C12.6602 2.91602 11.5197 3.38843 10.6788 4.22933L9.99999 4.90812L9.3212 4.22933C8.4803 3.38843 7.33979 2.91602 6.15058 2.91602Z"
				fill={selected ? "#F04755" : "white"}
			/>
			<path
				d="M9.99999 17.2017L9.29288 17.9088C9.68341 18.2994 10.3166 18.2994 10.7071 17.9088L9.99999 17.2017ZM2.89943 10.1012L2.19233 10.8083L2.19233 10.8083L2.89943 10.1012ZM8.85182 4.14879L8.14471 4.8559L8.14471 4.8559L8.85182 4.14879ZM9.99999 5.29697L9.29288 6.00408C9.68341 6.3946 10.3166 6.3946 10.7071 6.00408L9.99999 5.29697ZM11.1482 4.14879L10.4411 3.44169V3.44169L11.1482 4.14879ZM10.7071 16.4946L3.60654 9.39407L2.19233 10.8083L9.29288 17.9088L10.7071 16.4946ZM16.3934 9.39407L9.29288 16.4946L10.7071 17.9088L17.8077 10.8083L16.3934 9.39407ZM8.14471 4.8559L9.29288 6.00408L10.7071 4.58986L9.55892 3.44169L8.14471 4.8559ZM10.7071 6.00408L11.8553 4.8559L10.4411 3.44169L9.29288 4.58986L10.7071 6.00408ZM14.1244 1.91602C12.7429 1.91602 11.4179 2.46482 10.4411 3.44169L11.8553 4.8559C12.4571 4.2541 13.2733 3.91602 14.1244 3.91602V1.91602ZM17.3333 7.12498C17.3333 7.97606 16.9952 8.79227 16.3934 9.39407L17.8077 10.8083C18.7845 9.83141 19.3333 8.50649 19.3333 7.12498H17.3333ZM19.3333 7.12498C19.3333 4.24815 17.0012 1.91602 14.1244 1.91602V3.91602C15.8966 3.91602 17.3333 5.35272 17.3333 7.12498H19.3333ZM5.87563 3.91602C6.7267 3.91602 7.54291 4.2541 8.14471 4.8559L9.55892 3.44169C8.58205 2.46482 7.25713 1.91602 5.87563 1.91602V3.91602ZM2.66666 7.12498C2.66666 5.35272 4.10336 3.91602 5.87563 3.91602V1.91602C2.99879 1.91602 0.666656 4.24815 0.666656 7.12498H2.66666ZM3.60654 9.39407C3.00474 8.79227 2.66666 7.97606 2.66666 7.12498H0.666656C0.666656 8.50649 1.21546 9.83141 2.19233 10.8083L3.60654 9.39407Z"
				fill="#5B666E"
			/>
		</svg>
	);
}

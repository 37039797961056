import { makeStyles } from "@material-ui/core";

const useCampaignStyles = makeStyles((theme) => ({
	container: {
		marginTop: 12,
		paddingTop: 24,
		width: "50%",
		marginLeft: 24,
		height: 440,
		backgroundColor: "white",
		position: "relative",
		borderRadius: 20,
	},

	section: {
		marginTop: 16,
		paddingLeft: 24,
		paddingRight: 24,
	},

	subTitle: {
		fontFamily: "Avenir",
		fontSize: 12,
		color: theme.palette.text.primary,
		opacity: 0.6,
	},

	campaigns: {
		marginTop: 8,
	},

	addIconContainer: {
		width: "fit-content",
		position: "absolute",
		bottom: 24,
		right: 24,
	},
	plusIcon: {
		height: 25,
		marginLeft: 12,
		cursor: "pointer",
	},
	header: {
		paddingTop: 8,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 32,
	},

	emptyText: {
		padding: 16,
		paddingLeft: 0,
		fontSize: 12,
	},
}));

export default useCampaignStyles;

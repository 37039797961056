const DeleteIconSvg = ({
	width,
	height,
	color,
}: {
	width?: number;
	height?: number;
	color?: string;
}) => {
	return (
		<svg
			width={width || 28}
			height={height || 30}
			viewBox="0 0 28 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.5 0H9.5C7.8455 0 6.5 1.3455 6.5 3V4.5H0.5V7.5H3.5V27C3.5 28.6545 4.8455 30 6.5 30H21.5C23.1545 30 24.5 28.6545 24.5 27V7.5H27.5V4.5H21.5V3C21.5 1.3455 20.1545 0 18.5 0ZM9.5 3H18.5V4.5H9.5V3ZM21.5 27H6.5V7.5H21.5V27Z"
				fill={color || "#4B7FA3"}
			/>
			<path d="M9.5 10.5H12.5V24H9.5V10.5Z" fill="#4B7FA3" />
			<path d="M15.5 10.5H18.5V24H15.5V10.5Z" fill="#4B7FA3" />
		</svg>
	);
};

export default DeleteIconSvg;

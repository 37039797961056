import { Dispatch, SetStateAction, useState } from "react";
import { PostPayload } from "types/Post";
import AttachmentPreviewDialog from "components/Dialogs/AttachmentPreview/AttachmentPreview";
import usePostCreatorStyles from "../styles/post-creator-styles";
import { FileUploadProgress } from "redux/actions/media/upload";
import MediaAttachment from "components/Media/MediaAttachment";
import { Media } from "types/Task";
import PhotoEditorDialog from "modules/Editors/PhotoEditorDialog";
import { Box } from "@material-ui/core";

interface Props {
	postPayload: PostPayload | undefined;
	filesUploadProgress: FileUploadProgress[];
	setPostPayload: Dispatch<SetStateAction<PostPayload>>;
}

const PostAttachments = (props: Props) => {
	const [attachmentPreview, setAttachmentPreview] = useState<boolean>(false);
	const [selectedAttachment, setSelectedAttachment] = useState<Media>();
	const [imageEditor, setImageEditor] = useState<boolean>(false);
	const classes = usePostCreatorStyles();
	const { postPayload, filesUploadProgress, setPostPayload } = props;

	const handleAttachmentDelete = (selectedAttachment: string | File) => {
		if (!postPayload) return;
		postPayload.media?.splice(
			postPayload.media?.findIndex((media) => media.value === selectedAttachment),
			1
		);

		setPostPayload({ ...postPayload });
	};

	const handleImageEditorSubmit = (updatedFile: File) => {
		if (!postPayload) return;

		let { media } = postPayload;

		// Remove the uploaded file and add the updated one to attached files
		media?.forEach((image, index) => {
			if (image.value === selectedAttachment?.value) {
				media![index].value = updatedFile;
			}
		});

		setPostPayload({
			...postPayload,
			media: media ? [...media] : [],
		});

		setSelectedAttachment(undefined);
		setImageEditor(false);
	};

	const handleAttachmentClick = (attachment: File | string, type: "video" | "image") => {
		setSelectedAttachment({ value: attachment, type });
		setAttachmentPreview(true);
	};

	return (
		<Box>
			<PhotoEditorDialog
				selectedAttachment={selectedAttachment}
				open={imageEditor}
				onSubmit={handleImageEditorSubmit}
				onClose={() => setImageEditor(false)}
			/>

			<AttachmentPreviewDialog
				open={attachmentPreview}
				onClose={() => setAttachmentPreview(false)}
				attachment={selectedAttachment}
				onEditClick={() => setImageEditor(true)}
				onDeleteClick={() => handleAttachmentDelete(selectedAttachment?.value as string | File)}
			/>

			{postPayload && postPayload?.media?.length! > 0 && (
				<Box className={classes.attachments}>
					{postPayload.media?.map((media, index) => (
						<MediaAttachment
							attachment={media.value}
							key={index}
							type={media.type}
							thumbnailKey={media.thumbnail}
							uploadProgress={filesUploadProgress[index]}
							onAttachmentClick={() => handleAttachmentClick(media.value, media.type)}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

export default PostAttachments;

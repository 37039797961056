import React from "react";
import { posts } from "../../../../mock/placeholderData";
import {
	comparisonProfileSubscribes,
	comparisonProfileViews,
	pieData,
	postComparisonLikes,
	profileComparisonMentions,
} from "../../../../mock/listenPlaceholderData";
import LineDataCard from "modules/ListenAudience/LineDataCard";
import { PostPreview, SocialMediaPostStats } from "modules/ListenAudience/PostStats";
import SocialProfile from "types/SocialProfile";
import usePageStyles from "../../styles";
import ProfileItem from "./components/ProfileItem";
import useStyles from "./styles/styles";

interface Props {
	profiles: SocialProfile[];
}

const CompareProfiles = (props: Props) => {
	const classes = useStyles();
	const pageClasses = usePageStyles();

	const { profiles } = props;
	return (
		<div className={classes.container}>
			<div className={classes.linearContainer}>
				<ProfileItem profile={profiles[0]} profileIndex={1} />
				<ProfileItem profile={profiles[1]} profileIndex={2} />
			</div>

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Views Statistics"
						data={comparisonProfileViews}
						colors={["#3E4C55", "#F04755"]}
					/>
				</div>

				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer}>
							<PostPreview title="Most Viewed Post on Profile #1" post={posts[0]} />
							<SocialMediaPostStats title={"Most Viewed In"} data={pieData} />
						</div>

						<div style={{ marginTop: 20 }} className={pageClasses.linearContainer}>
							<PostPreview title="Most Viewed Post on Profile #2" post={posts[2]} />
							<SocialMediaPostStats title={"Most Viewed In"} data={pieData} />
						</div>
					</div>
				</div>
			</div>

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Likes and Dislikes Statistics"
						data={postComparisonLikes}
						colors={["#3E4C55", "#F04755"]}
					/>
				</div>

				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer}>
							<PostPreview title="Most Liked Post on Profile #1" post={posts[0]} />
							<SocialMediaPostStats title={"Most Liked In"} data={pieData} />
						</div>

						<div style={{ marginTop: 20 }} className={pageClasses.linearContainer}>
							<PostPreview title="Most Liked Post on Profile #2" post={posts[2]} />
							<SocialMediaPostStats title={"Most Liked In"} data={pieData} />
						</div>
					</div>
				</div>
			</div>

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Reposts and Mentions Statistics"
						data={profileComparisonMentions}
						colors={["#F2C94C", "#4285F4"]}
					/>
				</div>

				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer}>
							<PostPreview title="Most Reposted" post={posts[0]} />
							<SocialMediaPostStats title={"Most Reposted in"} data={pieData} />
						</div>

						<div style={{ marginTop: 20 }} className={pageClasses.linearContainer}>
							<div style={{ width: "50%" }}>
								{/* TODO: Remove placeholders */}
								<div className={pageClasses.title}>Most Mentioned with</div>
								<div className={pageClasses.hashtagText}>#newtechnology</div>
								<div className={pageClasses.hashtagText}>#blackfriday</div>
								<div className={pageClasses.hashtagText}>#wintercomes</div>
								<div className={pageClasses.hashtagText}>#goodmood</div>
							</div>
							<SocialMediaPostStats title={"Most Mentioned in"} data={pieData} />
						</div>
					</div>
				</div>
			</div>

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Subscribes and Messages Statistics"
						data={comparisonProfileSubscribes}
						colors={["#F2C94C", "#4285F4"]}
					/>
				</div>

				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer}>
							<div style={{ width: "50%" }}>
								{/* TODO: Remove placeholders */}
								<div className={classes.title}>Subscribe Statistics</div>
								<span className={classes.label}>Users mostly subscribe on:</span>
								<br />
								<span className={classes.value}>Sundays</span>
								<br />
								<span className={classes.label}>Most popular time: </span>
								<span className={classes.value}>6:00 PM - 8:00 PM</span>
								<br />
								<span className={classes.label}>
									Most active country - <span className={classes.value}>USA</span>
								</span>
							</div>
							<SocialMediaPostStats title={"Most Subscribed on"} data={pieData} />
						</div>

						<div style={{ marginTop: 20 }} className={pageClasses.linearContainer}>
							<div style={{ width: "50%" }}>
								{/* TODO: Remove placeholders */}
								<div className={classes.title}>Message Statistics</div>
								<span className={classes.label}>Users mostly send messages on: </span>
								<span className={classes.value}>Mondays</span>
								<br />
								<span className={classes.label}>Most popular time: </span>
								<span className={classes.value}>6:00 PM - 8:00 PM</span>
								<br />
								<span className={classes.label}>
									Most active country - <span className={classes.value}>USA</span>
								</span>
							</div>
							<SocialMediaPostStats title={"Most Messages in"} data={pieData} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompareProfiles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "100vh",
		paddingTop: 12,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		marginBottom: 16,
	},

	lightBlueButton: {
		backgroundColor: "#B6D8F0",
		width: 160,
		fontFamily: "Avenir",
		"&:hover": {
			backgroundColor: "#91bcdb !important",
		},
		color: "#2B4455",
	},

	centeredContainer: {
		width: "fit-content",
		margin: "52px auto 16px auto",
	},
}));

export default useStyles;

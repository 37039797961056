import { makeStyles, Theme, createStyles } from "@material-ui/core";

const drawerWidth = 200;

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			display: "flex",
		},

		logoContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center",
			marginBottom: 24,
			cursor: "pointer",
			marginLeft: -8,
		},

		logo: {
			height: 28,
			[theme.breakpoints.down("lg")]: {
				height: 22,
			},
		},

		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: "none",
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap",
		},
		drawerOpen: {
			backgroundColor: "#F7F7F7",
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			overflowX: "hidden",
			height: "100vh",
			border: "none",
			position: "fixed",
			top: 8,
			zIndex: 999,
		},
		drawerClose: {
			minHeight: "100vh",
			border: "none",
			maxHeight: "100vh",
			backgroundColor: "#F7F7F7",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: "hidden",
			width: 75,
			position: "fixed",
			top: 8,
			zIndex: 999,
			[theme.breakpoints.down("lg")]: {
				width: 65,
			},
		},
		content: {
			flexGrow: 1,
			paddingTop: 16,
			marginTop: 45,
			[theme.breakpoints.down("md")]: {},
		},

		icon: {
			height: 25,
			paddingLeft: 4,
			[theme.breakpoints.down("lg")]: {
				height: 18,
			},
		},

		menuIcon: {
			height: 22,
			transition: "0.2s",
			marginTop: -4,
			paddingLeft: 45,
			[theme.breakpoints.down("lg")]: {
				height: 16,
				paddingLeft: 30,
			},
		},

		listItem: {
			marginBottom: 16,
			position: "relative",
			flexDirection: "row",
			alignItems: "center",
			[theme.breakpoints.down("lg")]: {
				marginBottom: 8,
			},
		},

		disabledListItem: {
			opacity: 0.5
		},

		bottomList: {
			position: "absolute",
			bottom: 16,
		},

		selectedHighlighter: {
			height: 29,
			backgroundColor: theme.palette.primary.main,
			width: 5,
			position: "absolute",
			left: 0,
		},

		listItemText: {
			letterSpacing: 0.5,
			marginLeft: 8,
			[theme.breakpoints.down("lg")]: {
				fontSize: 12,
			},
		},

		loadingContainer: {
			width: 'fit-content',
			margin: 'auto',
			padding: 62
		}
	}),
	{ index: 1 }
);

export default useStyles;

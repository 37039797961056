import { useEffect, useState } from "react";
import SelectPostDialog from "../../components/Dialogs/SelectPostDialog";
import MetaTagsHeader from "../../components/Navigation/Components/MetaTags";
import Container from "../../components/Navigation/Container";
import Post from "types/Post";
import SocialProfile from "types/SocialProfile";
import useStyles from "./styles";
import ByPost from "./Views/ByPost";
import General from "./Views/General";
import Header from "./Views/Header";
import SelectProfileDialog from "../../components/Dialogs/SelectProfileDialog/SelectProfileDialog";
import Replies from "./Views/Replies";
import { useDispatch, useSelector } from "react-redux";
import { listSocialProfiles } from "redux/actions/socialProfiles/socialProfiles";
import { StoreState } from "setup/store";

export type ReplyViewType = "general" | "bySocialProfile" | "byPost" | "yourReplies";
export type DateRange = { startDate?: Date; endDate?: Date };

const ReplyPage = () => {
	const classes = useStyles();
	const [dateRange, setDateRange] = useState<DateRange>({});
	const [selectedView, setSelectedView] = useState<ReplyViewType>("general");
	const [selectedPosts, setSelectedPosts] = useState<Post[]>([]);
	const [selectedProfiles, setSelectedProfiles] = useState<SocialProfile[]>([]);
	const [profilesDialog, setProfilesDialog] = useState<boolean>(false);
	const [postsDialog, setPostsDialog] = useState<boolean>(false);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);

	const dispatch = useDispatch();

	const handlePostsDialogSubmit = (selectedPosts: Array<Post>) => {
		setSelectedPosts(selectedPosts);
		setSelectedView("byPost");
		setPostsDialog(false);

		setSelectedProfiles([]);
	};

	const handleProfilesDialogSubmit = (selectedProfiles: Array<SocialProfile>) => {
		setSelectedProfiles(selectedProfiles);
		setSelectedView("bySocialProfile");
		setProfilesDialog(false);

		setSelectedPosts([]);
	};
	useEffect(() => {
		if (currentTeamId) {
			dispatch(listSocialProfiles());
		}
	}, [currentTeamId]);

	useEffect(() => {
		if (selectedView === "general" || selectedView === "yourReplies") {
			setSelectedPosts([]);
			setSelectedProfiles([]);
		}
	}, [selectedView]);

	return (
		<Container page="Reply">
			<MetaTagsHeader title="Reply | HashtagHero" />

			<SelectPostDialog
				open={postsDialog}
				compare={false}
				handleClose={() => setPostsDialog(false)}
				handleSubmit={handlePostsDialogSubmit}
			/>

			<SelectProfileDialog
				profilesWithPostsAccessOnly
				open={profilesDialog}
				compare={false}
				handleClose={() => setProfilesDialog(false)}
				handleSubmit={handleProfilesDialogSubmit}
			/>

			<div className={classes.pageContainer}>
				<div className={classes.innerContainer}> 
					<Header
						selectedView={selectedView}
						selectedProfiles={selectedProfiles}
						dateRange={dateRange}
						onDateRangeChange={(range) => setDateRange(range)}
						setPostsDialog={setPostsDialog}
						setProfilesDialog={setProfilesDialog}
						setSelectedView={setSelectedView}
					/>

					{/* {selectedView !== "byPost" && (
						<div className={classes.dataHeader}>
							{generalReplyStats.map((item, index) => (
								<div key={index} className={classes.dataHeaderItem}>
									<div className={classes.dataItemTitle}>{item.title}</div>
									<div className={classes.dataItemValue}>{item.value}</div>
								</div>
							))}
						</div>
					)} */}

					{(selectedView === "general" || selectedView === "bySocialProfile") && (
						<General dateRange={dateRange} selectedProfile={selectedProfiles[0]} />
					)}
					{selectedView === "byPost" && <ByPost dateRange={dateRange} post={selectedPosts[0]} />}
					{selectedView === "yourReplies" && <Replies dateRange={dateRange} />}
				</div>
			</div>
		</Container>
	);
};

export default ReplyPage;

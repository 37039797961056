// TODO: Remove this file after API integration
// This data is used for testing purposes only

import { LineDataEntry } from "../components/Charts/LineChart";
import { PieDataEntry } from "../components/Charts/PieChart";

export const postDataStats: Array<{ title: string; value: number }> = [
	{ title: "Total Views", value: 11878 },
	{ title: "Total Likes", value: 4413 },
	{ title: "Total Dislikes", value: 1710 },
	{ title: "Total Reposts", value: 929 },
];

export const postDataStats2: Array<{ title: string; value: number }> = [
	{ title: "Total Mentions", value: 11878 },
	{ title: "Total Subscribes", value: 4413 },
	{ title: "Total Messages", value: 1710 },
	{ title: "Total Strikes", value: 929 },
];

export const comparisonViews: Array<LineDataEntry> = [
	{
		id: "Post #1 Views",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Post #2 Views",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
];

export const comparisonProfileViews: Array<LineDataEntry> = [
	{
		id: "Profile #1 Views",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Profile #2 Views",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
];

export const comparisonProfileSubscribes: Array<LineDataEntry> = [
	{
		id: "Profile #1 Subscribes",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Profile #2 Subscribes",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Profile #1 Messages",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Profile #2 Messages",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
];

export const comparisonReposts: Array<LineDataEntry> = [
	{
		id: "Post #1 Reposts",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Post #2 Reposts",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
];

export const profileComparisonMentions: Array<LineDataEntry> = [
	{
		id: "Profile #1 Reposts",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Profile #2 Reposts",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},

	{
		id: "Profile #1 Mentions",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Profile #2 Mentions",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
];

export const postComparisonLikes: Array<LineDataEntry> = [
	{
		id: "Post #1 Likes",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Post #2 Likes",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},

	{
		id: "Post #1 Dislikes",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
	{
		id: "Post #2 Dislikes",
		data: [
			{ x: "27 Oct", y: Math.random() * 10000 },
			{ x: "29 Oct", y: Math.random() * 10000 },
			{ x: "30 Oct", y: Math.random() * 10000 },
			{ x: "2 Nov", y: Math.random() * 10000 },
		],
	},
];

export const productivityData: Array<LineDataEntry> = [
	{
		id: "Tasks",
		data: [
			{ x: "27 Oct", y: 0 },
			{ x: "29 Oct", y: 2 },
			{ x: "30 Oct", y: 3 },
			{ x: "2 Nov", y: 5 },
		],
	},
];

export const postStatsLineChart: Array<LineDataEntry> = [
	{
		id: "Views",
		data: [
			{ x: "27 Oct", y: 5000 },
			{ x: "29 Oct", y: 4550 },
			{ x: "30 Oct", y: 4670 },
			{ x: "31 Oct", y: 5000 },
			{ x: "1 Nov", y: 5000 },
			{ x: "2 Nov", y: 4300 },
		],
	},
	{
		id: "Likes",
		data: [
			{ x: "27 Oct", y: 5000 },
			{ x: "29 Oct", y: 4550 },
			{ x: "30 Oct", y: 4670 },
			{ x: "31 Oct", y: 5000 },
			{ x: "1 Nov", y: 5000 },
			{ x: "2 Nov", y: 4300 },
		],
	},
	{
		id: "Dislikes",
		data: [
			{ x: "27 Oct", y: 3000 },
			{ x: "29 Oct", y: 2550 },
			{ x: "30 Oct", y: 1670 },
			{ x: "31 Oct", y: 4000 },
			{ x: "1 Nov", y: 2500 },
			{ x: "2 Nov", y: 2300 },
		],
	},
	{
		id: "Reposts",
		data: [
			{ x: "27 Oct", y: 1234 },
			{ x: "29 Oct", y: 3550 },
			{ x: "30 Oct", y: 3670 },
			{ x: "31 Oct", y: 5520 },
			{ x: "1 Nov", y: 2430 },
			{ x: "2 Nov", y: 2300 },
		],
	},
];

export const pieData: Array<PieDataEntry> = [
	{ id: "Facebook", label: "Facebook", value: 303 },
	{ id: "Instagram", label: "Instagram", value: 369 },
	{ id: "Twitter", label: "Twitter", value: 85 },
	{ id: "LinkedIn", label: "LinkedIn", value: 50 },
	{ id: "Others", label: "Others", value: 418 },
];

export const countryPieData: Array<PieDataEntry> = [
	{ id: "USA", label: "USA", value: 303 },
	{ id: "Canada", label: "Canada", value: 369 },
	{ id: "UK", label: "UK", value: 85 },
	{ id: "China", label: "China", value: 50 },
	{ id: "Russia", label: "Others", value: 418 },
];

import { Dispatch } from "react";

import config from "config";
import axios from "axios";
import { setSnackbarState } from "../snackBar";
import { StoreState } from "../../../setup/store";
import {
	DELETE_NOTE_LOADING,
	DELETE_NOTE_SUCCESS,
	LIST_NOTES_SUCCESS,
	SET_NOTES_COUNT,
} from "../../reducers/notes";

export const deleteNote = (id: string) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { notes, notesCount } = getState().notes;

		dispatch({
			type: DELETE_NOTE_LOADING,
			payload: true,
		});

		try {
			await axios.delete(`${config.apiUrl}/notes/${id}`, {});

			dispatch({
				type: DELETE_NOTE_SUCCESS,
				payload: true,
			});

			dispatch({
				type: LIST_NOTES_SUCCESS,
				payload: notes.filter((note) => note._id !== id),
			});

			dispatch({
				type: SET_NOTES_COUNT,
				payload: notesCount - 1,
			});
		} catch (e) {
			console.log(e);
			dispatch({
				type: DELETE_NOTE_LOADING,
				payload: true,
			});

			dispatch(setSnackbarState(true, "error", "Error deleting note..."));
		}
	};
};

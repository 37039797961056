import { Box, CircularProgress, useTheme } from "@material-ui/core";
import Tabs from "components/Tabs";
import { getSettingPageRoute } from "pages/Settings";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { listSubscriptionPlans, PaymentPeriod } from "redux/actions/team/subscriptions/plans";
import TSubscriptionPlan from "types/SubscriptionPlan";
import { StoreState } from "../../../../../../../setup/store";
import usePageStyles from "../../../../AccountSettings/styles";
import SubscriptionDialog from "../SubscriptionDialog";
import SubscriptionPlan from "./SubscriptionPlan/SubscriptionPlan";

const SubscriptionPlans = () => {
	const history = useHistory();
	const { t } = useTranslation("settings");
	const [selectedPeriod, setSelectedPeriod] = useState<PaymentPeriod>("monthly");
	const pageClasses = usePageStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const [selectedPlan, setSelectedPlan] = useState<TSubscriptionPlan>();
	const [subscriptionDialog, setSubscriptionDialog] = useState(false);
	const { plans, plansLoading } = useSelector((state: StoreState) => state.subscription);

	const handleSelectClick = (plan: TSubscriptionPlan) => {
		setSelectedPlan(plan);
		setSubscriptionDialog(true);
	};

	useEffect(() => {
		dispatch(listSubscriptionPlans());
	}, []);

	return (
		<Box>
			{selectedPlan && (
				<SubscriptionDialog
					defaultPeriod={selectedPeriod}
					open={subscriptionDialog}
					defaultPlanId={selectedPlan?.id}
					onClose={() => setSubscriptionDialog(false)}
				/>
			)}
			<Box className={pageClasses.viewTitle}>
				<span
					onClick={() => history.push(getSettingPageRoute('billingAndPayments'))}
					className={pageClasses.headerText}
				>
					{t("billingAndPayments")}
				</span>
				<span>&nbsp;{" > "}</span>
				<span className={pageClasses.headerText} style={{ color: theme.palette.primary.main }}>
					&nbsp;{t("subscriptionPlans")}
				</span>
			</Box>

			{plansLoading && (
				<Box width="fit-content" margin="auto" padding={6}>
					<CircularProgress size={20} />
				</Box>
			)}

			{!plansLoading && (
				<>
					<Box style={{ width: "fit-content", margin: "24px 8px 8px auto" }}>
						<Tabs
							tabs={["monthly", "yearly"]}
							onTabChange={(tab) => setSelectedPeriod(tab.name as PaymentPeriod)}
							selectedTabColor="primary"
							selectedTab={{ index: selectedPeriod === "monthly" ? 0 : 1, name: selectedPeriod }}
						/>
					</Box>
					<Box style={{ marginTop: 24 }} display="flex" alignItems="center">
						{plans.map((plan, index) => (
							<SubscriptionPlan
								selectedPeriod={selectedPeriod}
								onSelectClick={handleSelectClick}
								plan={plan}
								key={index}
							/>
						))}
					</Box>
				</>
			)}
		</Box>
	);
};

export default SubscriptionPlans;

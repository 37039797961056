import { Box, makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { CSSProperties, ReactNode } from "react";

const useStyles = makeStyles(() => ({
	titleText: {
		fontSize: 20,
		fontFamily: "Chau Philomene One",
		color: "#5B666E",
	},
}));

interface Props {
	primary?: boolean;
	style?: CSSProperties;
	className?: string;
	children?: ReactNode;
}

const TitleText = (props: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const { style, className, primary, children } = props;

	return (
		<Box
			style={{ ...style, color: primary ? theme.palette.primary.main : "" }}
			className={clsx(classes.titleText, className)}
		>
			{children}
		</Box>
	);
};

export default TitleText;

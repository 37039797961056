import { Dispatch } from "redux";
import { Socket } from "socket.io-client";
import { handleDeletedMessage, handleReceivedMessage } from "redux/actions/chat/socket";
import { getNotifications, sendDesktopNotification } from "redux/actions/notifications";
import { handleDeletedComment, handleReceivedComment } from "redux/actions/tasks/comments/socket";
import { SET_SOCKET } from "redux/reducers/user";
import store from "./store";

const enableSocketListeners = (socket: Socket) => {
	const dispatch = store.dispatch as Dispatch<any>;
	const { userData } = store.getState().user;

	const handleSocketConnect = () => {
		if (!userData) return;
		socket.emit("join", { userId: userData?._id });
		dispatch({
			type: SET_SOCKET,
			payload: socket,
		});
	};

	socket.on("notification", () => {
		dispatch(
			getNotifications({}, (notifications) => {
				if (notifications[0]) {
					sendDesktopNotification(notifications[0]);
				}
			})
		);
	});

	socket.on("message", (data) => {
		dispatch(handleReceivedMessage(data.message));
	});

	socket.on("comment", (data) => {
		dispatch(handleReceivedComment(data.comment));
	});

	socket.on("deleteComment", (data) => {
		dispatch(handleDeletedComment(data.comment));
	});

	socket.on("deleteMessage", (data) => {
		dispatch(handleDeletedMessage(data.messageId));
	});

	socket.on("connect", handleSocketConnect);
};

export default enableSocketListeners;

import TeamPicture from "components/Media/TeamPicture";
import Team from "types/User/Team";
import useTeamUserStyles from "./TeamUserCard/styles";

interface Props {
	team: Team;
	selected: boolean;
	onClick: (team: Team) => void;
}

const TeamItem = (props: Props) => {
	const { team, selected, onClick } = props;
	const classes = useTeamUserStyles();

	return (
		<div
			onClick={() => onClick(team)}
			className={classes.container}
			style={{
				cursor: "pointer",
				backgroundColor: selected ? "#D5E1F5" : "white",
			}}
		>
			<TeamPicture team={team} />
			<div className={classes.name}>{team.name}</div>
		</div>
	);
};

export default TeamItem;

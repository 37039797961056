import { withStyles, Theme, createStyles, LinearProgress } from "@material-ui/core";
import { numberWithSpaces } from "helpers/utils";
import { placeholderHashtagStats } from "../../mock/placeholderData";
import useHashtagAnalyticsStyles from "./styles/hashtag-analytics";

const PrimaryLinearProgress = withStyles((theme: Theme) =>
	createStyles({
		root: {
			height: 10,
			width: "100%",
			borderRadius: 5,
			marginLeft: 24,
			marginRight: 24,
		},
		colorPrimary: {
			backgroundColor: "transparent",
		},
		bar: {
			borderRadius: 5,
			backgroundColor: "#F04655",
		},
	})
)(LinearProgress);

const SecondaryLinearProgress = withStyles((theme: Theme) =>
	createStyles({
		root: {
			height: 10,
			width: "100%",
			borderRadius: 5,
			marginLeft: 24,
			marginRight: 24,
		},
		colorPrimary: {
			backgroundColor: "transparent",
		},
		bar: {
			borderRadius: 5,
			backgroundColor: "#D9E3EB",
		},
	})
)(LinearProgress);

const HashtagsAnalytics = () => {
	const classes = useHashtagAnalyticsStyles();

	return (
		<>
			<div className={classes.card} style={{ marginRight: 8 }}>
				<div className={classes.cardTitle}>Most Used Hashtags</div>
				{placeholderHashtagStats.map((hashtag, index) => (
					<div key={index} className={classes.hashtag}>
						<span style={{ width: 160 }}>{hashtag.title}</span>
						<PrimaryLinearProgress
							variant="determinate"
							value={
								((placeholderHashtagStats.length - index) / placeholderHashtagStats.length) * 100
							}
						/>
						<span style={{ marginLeft: "auto", width: 120 }}>
							{numberWithSpaces(hashtag.shares)}
						</span>
					</div>
				))}
			</div>
			<div className={classes.card} style={{ marginLeft: 8 }}>
				<div className={classes.cardTitle}>Most Popular Hashtags in the World</div>
				{placeholderHashtagStats.map((hashtag, index) => (
					<div key={index} className={classes.hashtag}>
						<span style={{ width: 160 }}>{hashtag.title}</span>
						<SecondaryLinearProgress
							variant="determinate"
							value={
								((placeholderHashtagStats.length - index) / placeholderHashtagStats.length) * 100
							}
						/>
						<span style={{ marginLeft: "auto", width: 120 }}>
							{numberWithSpaces(hashtag.shares)}
						</span>
					</div>
				))}
			</div>
		</>
	);
};

export default HashtagsAnalytics;

import { numberWithSpaces } from "helpers/utils";
import { countryAnalytics } from "../../mock/analyticsPlaceholderData";
import useCountryAnalyticsStyles from "./styles/country-analytics";

const CountryAnalytics = () => {
	const classes = useCountryAnalyticsStyles();

	return (
		<div className={classes.card}>
			<div className={classes.cardTitle}>Most Active Countries</div>
			<div className={classes.header}>
				<div className={classes.rowItem}>Views</div>
				<div className={classes.rowItem}>Likes</div>
				<div className={classes.rowItem}>Dislikes</div>
				<div className={classes.rowItem}>Comments</div>
				<div className={classes.rowItem}>Views in %</div>
			</div>

			{countryAnalytics.map((item, index) => (
				<div className={classes.row}>
					<div className={classes.country}>{item.country}</div>
					<div className={classes.rowItem}>{numberWithSpaces(item.views)}</div>
					<div className={classes.rowItem}>{numberWithSpaces(item.likes)}</div>
					<div className={classes.rowItem}>{numberWithSpaces(item.dislikes)}</div>
					<div className={classes.rowItem}>{numberWithSpaces(item.comments)}</div>
					<div className={classes.rowItem}>{item.viewsPercentage}</div>
				</div>
			))}
		</div>
	);
};

export default CountryAnalytics;

import { makeStyles } from "@material-ui/core";

const usePagesDialogStyles = makeStyles((theme) => ({
	paper: {
		padding: 24,
		paddingLeft: 52,
		paddingRight: 52,
		borderRadius: 12,
		width: 600,
		minHeight: 600,
		backgroundColor: "#ffffff",
	},

	container: {
		display: "relative",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	rightContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},

	title: {
		color: theme.palette.text.primary,
		fontWeight: 700,
		fontSize: 16,
		marginTop: 32,
	},

	subtitle: {
		fontSize: 12,
		marginTop: 6,
		color: theme.palette.text.primary,
	},

	profileImage: {
		width: 30,
		height: 30,
		borderRadius: "50%",
		marginRight: 8,
		objectFit: "cover",
	},

	currentProfileUsername: {
		color: "#4B7FA3",
		width: 150,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontSize: 12,
	},

	username: {
		fontSize: 14,
		marginLeft: 4,
		color: theme.palette.text.primary,
	},

	pagesContainer: {
		maxHeight: 300,
		overflowY: "scroll",
		marginTop: 32,
	},

	centeredContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
	},

	buttonsContainer: {
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		position: "absolute",
		bottom: 62,
		right: 52,
	},

	pageContainer: {
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
		padding: 12,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	resultHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderBottom: "1px solid #4B7FA3",
	},

	tab: {
		cursor: "pointer",
		userSelect: "none",
		fontFamily: "Avenir",
		color: "#3E4C55",
		paddingRight: 16,
		paddingBottom: 14,
		textTransform: "capitalize",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
}));

export default usePagesDialogStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "100vh",
		paddingTop: 16,
		width: "100%",
		paddingBottom: 82,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},

	title: {
		fontFamily: "Chau Philomene One",
		fontSize: 18,
		letterSpacing: 0.4,
		marginLeft: 32,
		color: "#5B666E",
	},

	container: {
		width: "100%",
		height: 600,
		paddingTop: 16,
		paddingLeft: 6,
		backgroundColor: "white",
		position: "relative",
		borderRadius: 20,
	},

	horizontalContainer: {
		display: "flex",
		flexDirection: "row",
		maxWidth: 1100,
		width: "100%",
		margin: "0px auto 0px auto",
	},
}));

export default useStyles;

export default function SuccessIconSvg({
	width,
	height,
	color,
}: {
	width: number;
	height: number;
	color?: string;
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.8"
				d="M6.59689 12.3641L1.72315 7.20703L0 9.02495L6.59689 16L20 1.81793L18.2767 0L6.59689 12.3641Z"
				fill={color || "#F7F7F7"}
			/>
		</svg>
	);
}

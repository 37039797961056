import { Box, Popover } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SettingsIcon } from "assets";
import TeamItem from "modules/Settings/Team/TeamItem";
import { setCurrentTeamId } from "redux/actions/team/post";
import { StoreState } from "../../../setup/store";
import useTeamSelectorStyles from "./styles";
import { getSettingPageRoute } from "pages/Settings";

interface Props {
	anchorEl: HTMLElement | null;
	handleClose: () => void;
}

const TeamSelector = (props: Props) => {
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const dispatch = useDispatch();
	const { t } = useTranslation("workspaces");
	const { anchorEl, handleClose } = props;
	const classes = useTeamSelectorStyles();
	const history = useHistory();

	const handleTeamSelection = (teamId: string) => {
		window.location.reload();
		dispatch(setCurrentTeamId(teamId));
	};

	const handleSettingsClick = () => {
		handleClose();
		history.push(getSettingPageRoute("workspaces"));
	};

	return (
		<Popover
			anchorEl={anchorEl}
			onClose={handleClose}
			open={Boolean(anchorEl)}
			classes={{ paper: classes.paper }}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			transformOrigin={{ vertical: "top", horizontal: "center" }}
		>
			<Box display="flex" alignItems="center" style={{ padding: 16 }}>
				<div className={classes.title}>{t("switchWorkspace")}</div>

				<div className={classes.settingsIconContainer}>
					<img
						src={SettingsIcon}
						style={{ width: 16, cursor: "pointer" }}
						onClick={handleSettingsClick}
					/>
				</div>
			</Box>

			{userData?.teams.map((team, index) => (
				<TeamItem
					key={index}
					team={team}
					selected={currentTeamId === team._id}
					onClick={(team) => handleTeamSelection(team._id)}
				/>
			))}
		</Popover>
	);
};

export default TeamSelector;

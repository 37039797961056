import { Box, CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DeleteConfirmImage, DeleteSuccessImage } from "assets";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import EmptyPageMessage from "components/EmptyPageMessage";
import MetaTagsHeader from "components/Navigation/Components/MetaTags";
import Container from "components/Navigation/Container";
import UnapprovedPost from "modules/Posts/UnapprovedPost/UnapprovedPost";
import { listPosts } from "redux/actions/posts/get";
import { deletePost } from "redux/actions/posts/update";
import { StoreState } from "../../../setup/store";
import Post from "types/Post";
import UnapprovedPageHeader from "./Header";
import useUnapprovedPageStyles from "./styles";

const UnapprovedPostsPage = () => {
	const dispatch = useDispatch();
	const [selectedPost, setSelectedPost] = useState<Post>();
	const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
	const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const classes = useUnapprovedPageStyles();
	const { posts, listPostsLoading } = useSelector((state: StoreState) => state.posts);
	const { t } = useTranslation("posts");
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);

	const handleDeleteClick = (post: Post) => {
		setSelectedPost(post);
		setDeleteConfirm(true);
	};

	const handleDelete = (post: Post) => {
		if (!deleteConfirm) {
			setSelectedPost(post);
			setDeleteConfirm(true);
		} else {
			if (!post._id) return;
			setDeleteConfirm(false);
			setSelectedPost(undefined);
			setDeleteSuccess(true);

			setDeleteLoading(true);
			dispatch(
				deletePost(post?._id, (success) => {
					if (success) {
						setDeleteConfirm(false);
						setSelectedPost(undefined);
						setDeleteSuccess(true);
						setDeleteLoading(false);
					}
				})
			);
		}
	};

	useEffect(() => {
		if (!currentTeamId) return;
		dispatch(
			listPosts({
				status: ["pending", "returned"],
			})
		);
	}, [currentTeamId]);

	return (
		<Box>
			<MetaTagsHeader title={t("postsForApprovement")} />

			<ConfirmationDialog
				open={deleteConfirm}
				onClose={() => setDeleteConfirm(false)}
				onAction={() => handleDelete(selectedPost as Post)}
				confirmButton={t("deletePost")}
				message={t("deletePostConfirmContent")}
				actionLoading={deleteLoading}
				image={DeleteConfirmImage}
			/>

			<ConfirmationDialog
				open={deleteSuccess}
				onClose={() => setDeleteSuccess(false)}
				message={t("deletePostSuccess")}
				image={DeleteSuccessImage}
				autoClose={true}
				hideButtons={true}
			/>

			<Container page={t("postsForApprovement")}>
				<Box className={classes.pageContainer}>
					<Box className={classes.innerContainer}>
						<UnapprovedPageHeader />

						{listPostsLoading && posts.length === 0 && (
							<Box className={classes.centeredContainer}>
								<CircularProgress size={25} />
							</Box>
						)}

						{!listPostsLoading && posts.length === 0 && (
							<Box className={classes.centeredContainer}>
								<EmptyPageMessage title={t("emptyPosts")} />
							</Box>
						)}

						<Box style={{ marginTop: 32 }}>
							{posts.map((post, index) => (
								<UnapprovedPost onDeleteClick={handleDeleteClick} post={post} key={index} />
							))}
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default UnapprovedPostsPage;

import { Box } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

const MessagesSkeleton = ({ amount }: { amount?: number }) => {
	return (
		<>
			{Array.from(Array(amount || 2).keys()).map((key) => (
				<Box key={key} style={{ marginTop: 24 }}>
					<Box display="flex">
						<Box>
							<Box
								style={{ marginBottom: 8 }}
								alignItems="center"
								display="flex"
								width="fit-content"
							>
								<Skeleton
									style={{
										width: 30,
										height: 30,
										marginRight: 16,
										borderRadius: "50%",
									}}
								/>
								<Skeleton style={{ width: 100 }} />
							</Box>

							<Skeleton style={{ width: 200, height: 35 }} />
						</Box>
					</Box>
				</Box>
			))}
		</>
	);
};

export default MessagesSkeleton;

import useAuthStyles from "./styles";
import { AuthIllustration as Image } from "../../assets";

const AuthIllustration = () => {
	const classes = useAuthStyles();

	return (
		<div className={classes.leftSection}>
			<img src={Image} className={classes.groupImage} />
		</div>
	);
};

export default AuthIllustration;

import SearchInput from "components/Basic/Inputs/SearchInput";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "../styles/dialog-styles";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import PostsFilterMenu, { PostFilters } from "components/Popovers/PostsFilter";
import { listPosts } from "redux/actions/posts/get";

interface Props {
	open: boolean;
	compare?: boolean;
}

const SelectPostDialogHeader: React.FC<Props> = (props) => {
	const [searchValue, setSearchValue] = useState("");
	const [appliedFilters, setAppliedFilters] = useState<PostFilters>([]);
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const { compare, open } = props;

	const debouncedSearch = useCallback(
		debounce((searchValue) => {
			dispatch(listPosts({ socialPosts: true, searchValue }));
		}, 300),
		[appliedFilters]
	);

	const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		debouncedSearch(e.target.value);
	};

	useEffect(() => {
		if (open) {
			setSearchValue("");
		}
	}, [open]);

	return (
		<div className={classes.header}>
			<div className={classes.dialogTitle}>
				{compare ? t("select2PostsToCompare") : t("selectPost")}
			</div>
			<div className={classes.alignRight}>
				<SearchInput
					value={searchValue}
					style={{ marginRight: 10 }}
					placeholder={t("searchByKeyword")}
					onChange={handleSearchValueChange}
				/>
				<PostsFilterMenu appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
			</div>
		</div>
	);
};

export default SelectPostDialogHeader;

import { makeStyles } from "@material-ui/core";

const useTeamSelectorStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: "white",
		borderRadius: 12,
		width: 260,
		paddingTop: 4,
		paddingBottom: 16,
		marginTop: 16,
	},

	title: {
		fontSize: 14,
	},

	settingsIconContainer: {
		width: "fit-content",
		marginLeft: "auto",
		paddingRight: 16,
		marginBottom: -4,
	},
}));

export default useTeamSelectorStyles;

import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Dropdown from "components/Basic/Inputs/Dropdown";
import SelectCampaign from "components/Popovers/CampaignSelector";
import { StoreState } from "../../../../../setup/store";
import { PostPayload } from "types/Post";
import usePostCreatorStyles from "../styles/post-creator-styles";

interface Props {
	setPostPayload: Dispatch<SetStateAction<PostPayload>>;
	postPayload: PostPayload | undefined;
}

const PostCampaign = (props: Props) => {
	const [campaignsMenu, setCampaignsMenu] = useState<HTMLElement | null>(null);
	const { campaigns } = useSelector((state: StoreState) => state.campaigns);
	const classes = usePostCreatorStyles();
	const { t } = useTranslation("posts");
	const { postPayload, setPostPayload } = props;

	return (
		<Box className={classes.verticalContainer}>
			<span className={classes.dropdownTitle}>{t("postCampaign")}</span>
			<span className={classes.dropdownSubtitle}>{t("postCampaignSubtitle")}</span>
			<Dropdown
				anchorEl={campaignsMenu}
				handleClose={() => setCampaignsMenu(null)}
				title={
					campaigns.find((campaign) => campaign._id === postPayload?.campaign)?.title ||
					t("selectCampaign")
				}
				onClick={(e) => setCampaignsMenu(e.currentTarget)}
				dropdownComponent={
					<SelectCampaign
						selectedCampaignId={postPayload?.campaign}
						onChange={(campaign) => {
							setCampaignsMenu(null);
							setPostPayload({ ...postPayload, campaign: campaign?._id });
						}}
					/>
				}
			/>
		</Box>
	);
};

export default PostCampaign;

import { Box } from "@material-ui/core";
import CheckIconSvg from "assets/SVG/CheckIconSvg";
import Card from "components/Basic/Card";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SubscriptionPayload } from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import useSubscriptionInfoDialogStyles from "../styles";

interface Props {
	scheduledChanges?: boolean;
	subscriptionPayload: SubscriptionPayload;
}

const SubscriptionInfoChangesCard = (props: Props) => {
	const classes = useSubscriptionInfoDialogStyles();
	const { t } = useTranslation("settings");
	const { currentTeamSubscription, plans } = useSelector((state: StoreState) => state.subscription);
	const { scheduledChanges, subscriptionPayload } = props;

	return (
		<Card>
			{/* Scheduled Changes */}
			{scheduledChanges && currentTeamSubscription?.id && (
				<>
					{/* Updated Plan*/}
					{currentTeamSubscription.planInfo.id !== subscriptionPayload.planId && (
						<>
							<Box className={classes.changeTitle}>{t("subscriptionPlan")}:</Box>
							<Box className={classes.changeText}>
								{t("changedFrom")} <strong>{currentTeamSubscription?.planInfo.title}</strong>{" "}
								{t("to")}{" "}
								<strong>
									{plans.find((plan) => plan.id === subscriptionPayload.planId)?.title}
								</strong>
							</Box>
						</>
					)}

					{/* Updated Billing Period Unit */}
					{subscriptionPayload.paymentPeriod.replace("ly", "") !==
						currentTeamSubscription.billingPeriodUnit && (
						<>
							<Box className={classes.changeTitle}>{t("prepaidBillingPeriod")}</Box>
							<Box className={classes.changeText}>
								{t("changedFrom")}{" "}
								<strong>
									{t(subscriptionPayload.paymentPeriod === "monthly" ? "yearly" : "monthly")}
								</strong>{" "}
								{t("to")} <strong>{t(subscriptionPayload.paymentPeriod)}</strong>
							</Box>
						</>
					)}

					{/* Updated Add-ons */}
					{subscriptionPayload.userAddons !== currentTeamSubscription.userAddons && (
						<>
							<Box className={classes.changeTitle}>{t("addons")}</Box>
							<Box className={classes.changeText}>
								{t("extraTeamMembersQuantity")}{" "}
								{(subscriptionPayload.userAddons || 0) > (currentTeamSubscription.userAddons || 0)
									? t("increasing")
									: t("decreasing")}{" "}
								{t("from")} <strong>{currentTeamSubscription.userAddons || 0}</strong> {t("to")}{" "}
								<strong>{subscriptionPayload.userAddons}</strong>
							</Box>
						</>
					)}
				</>
			)}

			{/* New Subscription Info */}
			{!scheduledChanges && (
				<>
					<Box style={{ gap: 12 }} display="flex" alignItems="center">
						<CheckIconSvg size={18} />
						<Box style={{ marginBottom: 0 }} className={classes.changeTitle}>
							{t("subscriptionPlan")} -{" "}
							{plans.find((plan) => plan.id === subscriptionPayload.planId)?.title}
						</Box>
					</Box>

					{Boolean(subscriptionPayload.userAddons) && (
						<>
							<Box
								style={{ gap: 12, marginTop: 16, marginBottom: 8 }}
								display="flex"
								alignItems="center"
							>
								<CheckIconSvg size={18} />
								<Box style={{ marginBottom: 0 }} className={classes.changeTitle}>
									{t("addons")}:
								</Box>
							</Box>

							<Box className={classes.changeText} style={{ marginLeft: 29 }}>
								{t("extraTeamMembers")} - {subscriptionPayload.userAddons}
							</Box>
						</>
					)}
				</>
			)}
		</Card>
	);
};

export default SubscriptionInfoChangesCard;

import React from "react";

export default function NotificationIcon({ size }: { size?: number }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.0951 14.0299C24.0951 10.4947 21.3661 7.62891 17.9998 7.62891C14.6335 7.62891 11.9046 10.4947 11.9046 14.0299C11.9046 21.4978 8.85693 23.6315 8.85693 23.6315H27.1427C27.1427 23.6315 24.0951 21.4978 24.0951 14.0299Z"
				stroke="#F04755"
				strokeWidth={2.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.05 27.8984C19.3931 28.9162 17.9426 29.2627 16.8102 28.6723C16.4531 28.4861 16.1563 28.2194 15.9492 27.8984"
				stroke="#F04755"
				strokeLinecap="round"
				strokeLinejoin="bevel"
			/>
			<path
				d="M29.3137 6.6863C35.5621 12.9347 35.5621 23.0654 29.3137 29.3137C23.0653 35.5621 12.9346 35.5621 6.6863 29.3137C0.437901 23.0653 0.437901 12.9346 6.6863 6.6863C12.9347 0.437901 23.0654 0.437901 29.3137 6.6863"
				stroke="#F04755"
				strokeWidth={2.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

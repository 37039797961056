import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "components/Basic/Button";

const UnapprovedPageHeader = () => {
	const history = useHistory();
	const { t } = useTranslation("posts");

	return (
		<Box display="flex" alignItems="center">
			<Button style={{ width: 150, marginRight: 12 }} onClick={() => history.push("/posts/new")}>
				{t("createNewPost")}
			</Button>

			<Button
				secondary
				style={{ width: 150, marginRight: 12 }}
				onClick={() => history.push("/posts/schedule")}
			>
				{t("seePostsSchedule")}
			</Button>

			<Button
				secondary
				style={{ width: 150, marginRight: 12 }}
				onClick={() => history.push("/posts/schedule")}
			>
				{t("editDrafts")}
			</Button>
		</Box>
	);
};

export default UnapprovedPageHeader;

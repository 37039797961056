import { Box, Dialog, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AddProfileIllustration } from "../../../assets";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import config from "config";
import { getAccessToken } from "../../../helpers/utils";
import { StoreState } from "../../../setup/store";
import Network from "types/Network";
import useAddProfileDialogStyles from ".//styles";

interface Props {
	open: boolean;
	onClose: () => void;
}

const AddProfileDialog = (props: Props) => {
	const { open, onClose } = props;
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);
	const limitReached = useMemo(
		() => profiles.length >= Number(currentTeamSubscription?.planInfo.features.socialProfiles || 0),
		[currentTeamSubscription, open]
	);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { t } = useTranslation("settings");
	const classes = useAddProfileDialogStyles({ limitExceeded: limitReached });
	const theme = useTheme();
	const handleNetworkClick = (network: Network) => {
		if (limitReached) return;
		const accessToken = getAccessToken();
		const baseUrl = `${config.apiUrl}/socialProfiles/${currentTeamId}`;

		if (network === "facebook") {
			window.location.href = `${baseUrl}/addFacebookProfile/${accessToken}`;
		} else if (network === "instagram") {
			window.location.href = `${baseUrl}/addInstagramProfile/${accessToken}`;
		} else if (network === "linkedin") {
			window.location.href = `${baseUrl}/addLinkedInProfile/${accessToken}`;
		} else if (network === "twitter") {
			window.location.href = `${baseUrl}/addTwitterProfile/${accessToken}`;
		}
	};

	return (
		<Dialog classes={{ paper: classes.paper }} onClose={onClose} open={open}>
			<Box className={classes.container}>
				<Box className={classes.header}>
					<Box className={classes.dialogTitle}>{t("socialProfileAdding")}</Box>
					<Box onClick={onClose} className={classes.closeIconContainer}>
						<CloseIconSvg size={15} />
					</Box>
				</Box>

				{!limitReached && <Box className={classes.label}>{t("selectNetworkHint")}:</Box>}

				{limitReached && (
					<Box className={clsx(classes.label, classes.errorLabel)}>
						{t("socialProfilesLimitReached", {
							limit: currentTeamSubscription?.planInfo.features.socialProfiles || "",
						})}
					</Box>
				)}

				<Box className={classes.linearContainer}>
					<Box style={{ width: "60%" }}>
						{currentTeamSubscription?.planInfo.networks.map((network, index) => (
							<Box
								key={index}
								className={classes.socialNetworkItem}
								onClick={() => handleNetworkClick(network)}
							>
								<NetworkIcon
									network={network}
									color={theme.palette.text.secondary}
									size={25}
									strokeWidth={3}
								/>
								<span style={{ marginLeft: 12 }}>{t(network)}</span>
							</Box>
						))}
					</Box>

					<Box style={{ width: "40%" }}>
						<img src={AddProfileIllustration} style={{ height: 150, marginLeft: 32 }} />
					</Box>
				</Box>

				<Box className={classes.note}>
					{t("storiesWarning")}{" "}
					<span
						style={{
							color: theme.palette.text.secondary,
							textDecoration: "underline",
							cursor: "pointer",
						}}
					>
						{t("learnMore")}
					</span>
				</Box>
			</Box>
		</Dialog>
	);
};

export default AddProfileDialog;

const ResizeIconSvg = ({ size }: { size?: number }) => {
	return (
		<svg
			width={size || "64"}
			height={size || "64"}
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.1421 16.143L12.1421 37.5989C12.1421 41.1625 16.4507 42.9472 18.9706 40.4273L40.4264 18.9714C42.9463 16.4516 41.1616 12.143 37.598 12.143H16.1421C13.933 12.143 12.1421 13.9339 12.1421 16.143Z"
				fill="#F04755"
				stroke="white"
				stroke-width="4"
			/>
			<path
				d="M51.4975 47.4976L51.4975 26.0418C51.4975 22.4781 47.1889 20.6935 44.6691 23.2133L23.2132 44.6692C20.6934 47.189 22.478 51.4976 26.0417 51.4976H47.4975C49.7066 51.4976 51.4975 49.7067 51.4975 47.4976Z"
				fill="#F04755"
				stroke="white"
				stroke-width="4"
			/>
		</svg>
	);
};

export default ResizeIconSvg;

import axios from "axios";
import { Dispatch } from "react";
import config from "config";

import { StoreState } from "../../../setup/store";
import { ListProfilePages, UpdateConnectedPages } from "types/ReduxActions";
import { setSnackbarState } from "../snackBar";
import { listSocialProfiles } from "./socialProfiles";

export const listFacebookPages: ListProfilePages = (profileId, callback) => {
	return async (dispatch: Dispatch<any>) => {
		try {
			const result = await axios.get(`${config.apiUrl}/socialProfiles/${profileId}/facebook/pages`);

			callback(result.data);
		} catch (e) {
			console.log(e);
			callback([]);
			dispatch(setSnackbarState(true, "error", "Error loading pages, try again later."));
		}
	};
};

export const updateConnectedFacebookPages: UpdateConnectedPages = (
	currentProfile,
	checkedIds,
	callback
) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { profiles } = getState().socialProfiles;

		let disconnectedIds: string[] = [];
		let connectedIds: string[] = [];

		profiles.forEach((profile) => {
			if (profile.type !== "page") return;

			if (
				profile.parentProfile === currentProfile._id &&
				!checkedIds.includes(profile.socialId || "")
			) {
				disconnectedIds.push(profile.socialId || "");
			}
		});

		checkedIds.forEach((socialId) => {
			if (profiles.filter((profile) => profile.socialId === socialId).length === 0) {
				connectedIds.push(socialId);
			}
		});

		await Promise.all(
			connectedIds.map(async (socialId) => {
				try {
					await axios.get(
						`${config.apiUrl}/socialProfiles/${currentProfile._id}/facebook/pages/connect/${socialId}`
					);
				} catch (e) {
					if (e.response.data.error === "ProfilesLimitReached") {
						dispatch(setSnackbarState(true, "error", "ProfilesLimitReached"));
					} else {
						dispatch(setSnackbarState(true, "error", "ConnectPageError"));
					}
				}
			})
		);

		await Promise.all(
			disconnectedIds.map(async (socialId) => {
				try {
					await axios.delete(`${config.apiUrl}/socialProfiles/${socialId}?idType=social`, {});
				} catch (e) {
					dispatch(setSnackbarState(true, "error", "ConnectPageError"));
				}
			})
		);

		dispatch(
			listSocialProfiles(() => {
				if (callback) callback();
			})
		);
	};
};

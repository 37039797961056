import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { CSSProperties } from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Task from "types/Task";
import useTaskChipStyles from "./styles/task-chip";
import TaskAssignees from "./TaskCardAssigneesPictures";

interface Props {
	task: Task;
	style?: CSSProperties;
	showDeadline?: boolean;
	searchValue?: string;
	onClick: (task: Task) => void;
}

const TaskChip = (props: Props) => {
	const classes = useTaskChipStyles();
	const { t } = useTranslation("tasks");
	const theme = useTheme();
	const { task, style, showDeadline, searchValue, onClick } = props;
	const { deadline, urgent, finishedAt, status, createdAt } = task;

	return (
		<Box
			onClick={() => onClick(task)}
			style={{
				...style,
				border: showDeadline && urgent ? `1px solid ${theme.palette.primary.main}` : "",
			}}
			className={classes.container}
		>
			<Box style={{ width: "65%" }}>
				<Box className={classes.title}>
					<Highlighter
						textToHighlight={task.title || ""}
						searchWords={searchValue?.split(" ") || []}
						highlightClassName={classes.highlighter}
					/>
				</Box>
				{showDeadline ? (
					<Box
						style={{
							fontSize: 11,
							color: urgent ? theme.palette.primary.main : "",
							fontWeight: urgent ? 500 : 400,
						}}
					>
						{t("deadline")}:&nbsp;
						{urgent ? t("urgent") : deadline ? moment(deadline).format("D MM hh:mm A") : "-"}
					</Box>
				) : (
					<Box style={{ fontSize: 11 }}>
						{status === "inProgress" ? t("started") : t("finished")}:&nbsp;
						{moment(status === "inProgress" ? createdAt : finishedAt).format("DD MMM hh:mm A")}
					</Box>
				)}
			</Box>

			<Box className={classes.assignees}>
				<TaskAssignees task={task} />
			</Box>
		</Box>
	);
};

export default TaskChip;

import { useState, useEffect } from "react";
import useStyles from "../styles";
import CampaignCard from "modules/Campaigns/Campaign";
import PlusIconTransparent from "assets/plus-icon-transparent.png";
import Campaign from "types/Campaign";
import useCampaignStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { setCampaignDialog } from "redux/actions/modals";

const Campaigns = () => {
	const { campaigns, listCampaignsLoading: loading } = useSelector(
		(state: StoreState) => state.campaigns
	);
	const [filteredCampaigns, setFilteredCampaigns] = useState<
		Array<Campaign & { active?: boolean }>
	>([]);
	const classes = useCampaignStyles();
	const mainClasses = useStyles();
	const { t } = useTranslation("campaigns");
	const dispatch = useDispatch();

	const handleCampaignClick = (campaign: Campaign) => {
		dispatch(setCampaignDialog(true, campaign));
	};

	useEffect(() => {
		let filteredCampaigns: Array<Campaign & { active?: boolean }> = [];

		campaigns.forEach((campaign) => {
			if (
				moment().isBefore(moment(campaign.endDate)) &&
				moment().isAfter(moment(campaign.startDate))
			) {
				filteredCampaigns.push({ ...campaign, active: true });
			} else if (moment().isBefore(moment(campaign.endDate))) {
				filteredCampaigns.push({ ...campaign });
			}
		});

		setFilteredCampaigns([...filteredCampaigns]);
	}, [campaigns]);

	return (
		<div style={{ width: "50%", marginTop: 36 }}>
			<div className={classes.header}>
				<span className={mainClasses.title} style={{ marginLeft: 56 }}>
					{t("campaigns")}
				</span>
				<img
					src={PlusIconTransparent}
					className={classes.plusIcon}
					onClick={() => dispatch(setCampaignDialog(true))}
				/>
			</div>
			<div className={classes.container} style={{ width: "100%" }}>
				{/* Campaign Now */}
				{!loading && (
					<div className={classes.section}>
						<span className={classes.subTitle}>{t("campaignsNow")}</span>
						<div className={classes.campaigns}>
							{filteredCampaigns
								.filter((campaign) => campaign.active)
								.map((campaign, index) => (
									<CampaignCard
										key={index}
										onClick={() => handleCampaignClick(campaign)}
										campaign={campaign}
									/>
								))}

							{filteredCampaigns.filter((campaign) => campaign.active).length === 0 && (
								<div className={classes.emptyText}>{t("noCampaignsFound")}</div>
							)}
						</div>
					</div>
				)}

				{/* Campaign Coming Soon */}
				{!loading && (
					<div className={classes.section}>
						<span className={classes.subTitle}>{t("campiagnsComingSoon")}</span>
						<div className={classes.campaigns}>
							{filteredCampaigns
								.filter((campaign) => !campaign.active)
								.map((campaign, index) => (
									<CampaignCard
										key={index}
										onClick={() => handleCampaignClick(campaign)}
										campaign={campaign}
									/>
								))}

							{filteredCampaigns.filter((campaign) => !campaign.active).length === 0 && (
								<div className={classes.emptyText}>{t("noCampaignsFound")}</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Campaigns;

import { makeStyles } from "@material-ui/core";

const useTeamUserSelectorStyles = makeStyles((theme) => ({
	paper: {
		backgroundColor: "white",
		borderRadius: 12,
		width: 260,
		paddingTop: 4,
		paddingBottom: 16,
		marginTop: 16,
	},

	title: {
		fontSize: 14,
		paddingLeft: 6,
	},

	user: {
		display: "flex",
		alignItems: "center",
		paddingTop: 8,
		paddingBottom: 8,
		cursor: "pointer",
	},

	name: {
		fontSize: 12,
	},
}));

export default useTeamUserSelectorStyles;

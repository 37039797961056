import axios from "axios";
import { Dispatch } from "react";

import config from "config";
import { SubmitNoteFunc } from "types/ReduxActions";
import { CREATE_NOTE_ERROR, CREATE_NOTE_LOADING, CREATE_NOTE_SUCCESS } from "../../reducers/notes";
import { listNotes } from "./get";
import { StoreState } from "../../../setup/store";

export const createNote: SubmitNoteFunc = (payload, selectedNoteId) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { content } = payload;

		dispatch({
			type: CREATE_NOTE_LOADING,
			payload: true,
		});

		const errors: { [index: string]: string } = {};

		if (!content) {
			errors.content = "Content Required";
		}

		if (Object.keys(errors).length > 0) {
			return dispatch({
				type: CREATE_NOTE_ERROR,
				payload: errors,
			});
		}

		let url = `${config.apiUrl}/notes?teamId=${currentTeamId}`;

		if (selectedNoteId) {
			url = `${config.apiUrl}/notes/${selectedNoteId}`;
		}

		try {
			if (selectedNoteId) {
				await axios.put(url, payload);
			} else {
				await axios.post(url, payload);
			}

			dispatch({
				type: CREATE_NOTE_SUCCESS,
				payload: true,
			});

			dispatch(listNotes());
		} catch (err) {
			console.log(err.response?.data);
			if (err.response?.data) {
				dispatch({
					type: CREATE_NOTE_ERROR,
					payload: err.response.data,
				});
			}
		}
	};
};

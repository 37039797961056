import { AnyAction, Dispatch } from "redux";
import { taskQuery } from "config";
import Campaign from "types/Campaign";
import Post from "types/Post";
import Task from "types/Task";
import {
	FullScreenMediaPreviewState,
	SET_ADD_PROFILE_DIALOG,
	SET_BILLING_METHOD_DIALOG,
	SET_CAMPAIGN_DIALOG,
	SET_COMING_SOON_DIALOG,
	SET_FULL_SCREEN_MEDIA_PREVIEW,
	SET_POST_PREVIEW_DIALOG,
	SET_TASK_DIALOG,
	SET_USER_INFO_POPPER,
	UserInfoPopoverState,
} from "../reducers/modals";
import { getTaskById } from "./tasks/get";
import { PaymentMethod } from "types/Billing";

export const openDialogFromQuery = (query: URLSearchParams) => {
	return (dispatch: Dispatch<any>) => {
		if (query.get(taskQuery)) {
			dispatch(
				getTaskById(query.get(taskQuery) || "", (task) => {
					if (task) {
						dispatch(setTaskDialog(true, task));
					}
				})
			);
		}
	};
};

export const setPostPreviewDialog = (
	open: boolean,
	disableActions?: boolean,
	post?: Post | null
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_POST_PREVIEW_DIALOG,
			payload: { open, disableActions, post },
		});
	};
};

export const setTaskDialog = (
	open: boolean,
	selectedTask?: Task,
	onSubmit?: (updatedTask: Task, deleted?: boolean) => void
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_TASK_DIALOG,
			payload: { open, selectedTask, onSubmit },
		});
	};
};

export const setFullScreenMediaPreview = (state: FullScreenMediaPreviewState) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_FULL_SCREEN_MEDIA_PREVIEW,
			payload: state,
		});
	};
};

export const setCampaignDialog = (open: boolean, selectedCampaign?: Campaign | null) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_CAMPAIGN_DIALOG,
			payload: { open, selectedCampaign },
		});
	};
};

export const setBillingMethodDialog = (open: boolean, selectedPaymentMethod?: PaymentMethod) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_BILLING_METHOD_DIALOG,
			payload: { open, selectedPaymentMethod },
		});
	};
};

export const setUserInfoPopper = (state: UserInfoPopoverState) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_USER_INFO_POPPER,
			payload: state,
		});
	};
};

export const setAddProfileDialog = (open: boolean) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_ADD_PROFILE_DIALOG,
			payload: open,
		});
	};
};

export const setComingSoonDialog = (open: boolean) => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: SET_COMING_SOON_DIALOG,
			payload: open,
		});
	};
};

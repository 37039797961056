import { Box } from "@material-ui/core";
import HeartIconSvg from "assets/SVG/HeartIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import MediaAttachment from "components/Media/MediaAttachment";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import moment from "moment";
import ClampLines from "react-clamp-lines";
import { useDispatch } from "react-redux";
import { setFullScreenMediaPreview } from "redux/actions/modals";
import Interaction from "types/Interaction";
import SocialProfile from "types/SocialProfile";
import useReplyDialogStyles from "../styles";

export const OriginalPost = ({
	interaction,
	socialProfile,
}: {
	interaction: Interaction;
	socialProfile: SocialProfile;
}) => {
	const classes = useReplyDialogStyles();

	return (
		<div className={classes.originalPostContainer}>
			<div className={classes.linearContainer}>
				{interaction?.post?.media?.[0] && (
					<img className={classes.postImage} src={String(interaction.post.media[0].value)} />
				)}
				<div>
					<span className={classes.dateText}>
						Posted {moment(interaction?.post?.createdAt).format("MMMM Do YYYY, h:mm a")}
					</span>
					<br />
					<div className={classes.linearContainer}>
						<span className={classes.postText} style={{ width: "77%" }}>
							<ClampLines
								id="post-content-2"
								text={interaction?.post?.content as string}
								lines={2}
								ellipsis="..."
								buttons={false}
							/>
						</span>
					</div>
				</div>
				<div className={classes.linearContainer} style={{ marginLeft: "auto" }}>
					<NetworkIcon size={24} network={socialProfile.network} />
					<SocialProfilePicture style={{ marginLeft: 8 }} profile={socialProfile} />
					<div className={classes.postUsername}>
						{socialProfile.displayName || socialProfile.username}
					</div>
				</div>
			</div>
		</div>
	);
};

export const CommentMentionOriginalPost = ({
	interaction,
	socialProfile,
}: {
	interaction: Interaction;
	socialProfile: SocialProfile;
}) => {
	const classes = useReplyDialogStyles();
	const { post } = interaction;

	return (
		<div className={classes.originalPostContainer}>
			<div className={classes.linearContainer}>
				<img
					className={classes.postMentionUserPicture}
					src={socialProfile && socialProfile.picture}
				/>
				<div>
					<div className={classes.linearContainer}>
						<div className={classes.postUsername} style={{ paddingRight: 10, fontSize: 14 }}>
							{socialProfile.username}
						</div>
						<NetworkIcon network={socialProfile.network!} size={15} />
						<div
							className={classes.dateText}
							style={{ paddingRight: 10, paddingLeft: 10, fontSize: 12 }}
						>
							{moment(post?.createdAt).format("MMMM Do YYYY, h:mm a")}
						</div>
					</div>
					<div className={classes.content} style={{ paddingLeft: 8 }}>
						{post?.content}
					</div>
				</div>
				{post.media?.map((mediaItem, index) => (
					<div key={index} className={classes.postAttachmentPreviewContainer}>
						<img src={String(mediaItem.value)} className={classes.postAttachmentPreview} />
					</div>
				))}
			</div>
		</div>
	);
};

export const PostMentionOriginalPost = ({
	interaction,
	socialProfile,
}: {
	interaction: Interaction;
	socialProfile: SocialProfile;
}) => {
	const classes = useReplyDialogStyles();

	return (
		<div className={classes.originalPostContainer}>
			<div className={classes.linearContainer}>
				{/* TODO: Show placeholder if profielPictureUrl is undefined */}
				<img className={classes.postMentionUserPicture} src={interaction.from?.profilePictureUrl} />
				<div className={classes.linearContainer} style={{ width: "100%" }}>
					<div className={classes.postUsername} style={{ paddingRight: 10, fontSize: 14 }}>
						{interaction.from?.displayName}
					</div>
					<NetworkIcon network={socialProfile.network} size={15} />
					<div
						className={classes.dateText}
						style={{ paddingRight: 10, paddingLeft: 10, fontSize: 12 }}
					>
						{moment(interaction.createdAt).format("MMMM Do YYYY, h:mm a")}
					</div>
					<div className={classes.mentionText}>Mention in a Post</div>
				</div>
			</div>
			<div className={classes.content} style={{ paddingTop: 6, paddingBottom: 6 }}>
				{interaction?.message}
			</div>
			{interaction.attachment && (
				<div className={classes.linearContainer}>
					<img src={interaction.attachment} className={classes.postMentionAttachment} />
				</div>
			)}
		</div>
	);
};

export const OriginalInteraction = ({ interaction }: { interaction: Interaction }) => {
	const classes = useReplyDialogStyles();
	const dispatch = useDispatch();

	const handleAttachmentClick = (index: number) => {
		if (interaction.media) {
			dispatch(
				setFullScreenMediaPreview({
					open: true,
					media: interaction.media,
					initialSelectedIndex: index,
					createdAt: interaction.createdAt,
				})
			);
		}
	};

	return (
		<div className={classes.interactionContainer}>
			<div className={classes.linearContainer}>
				<SocialProfilePicture
					style={{ width: 45, height: 45, marginRight: 16 }}
					url={interaction.from?.profilePictureUrl}
				/>

				<div className={classes.interactionUsername}>
					{interaction.from?.displayName || interaction.from?.username}
				</div>
				<div className={classes.interactionDate}>
					{moment(interaction.createdAt).format("MMMM Do YYYY, h:mm a")}
				</div>

				{(interaction.type === "repost" || interaction.type === "comment") && (
					<div style={{ paddingLeft: 32 }} className={classes.linearContainer}>
						<HeartIconSvg selected={interaction.isLiked} />
						<span style={{ paddingLeft: 5 }} className={classes.content}>
							{interaction.likes}
						</span>
					</div>
				)}

				{interaction.mentionType === "comment" && (
					<div className={classes.mentionText}>Mention in a Comment</div>
				)}
			</div>
			<div className={classes.content} style={{ paddingTop: 12 }}>
				{interaction.message}
			</div>

			<Box marginTop={2} display="flex" alignItems="center">
				{interaction.media?.map((attachment, index) => (
					<MediaAttachment
						attachment={attachment.value}
						type={attachment.type}
						thumbnailKey={attachment.thumbnail}
						key={index}
						onAttachmentClick={() => handleAttachmentClick(index)}
					/>
				))}
			</Box>
		</div>
	);
};

import { Dispatch, SetStateAction } from "react";
import ProfileSelector from "components/Popovers/ProfilesSelector/ProfilesSelector";
import SocialProfileChip from "modules/Settings/SocialProfile/SocialProfileChip";
import { PostPayload } from "types/Post";
import SocialProfile from "types/SocialProfile";

interface Props {
	postPayload: PostPayload | undefined;
	setPostPayload: Dispatch<SetStateAction<PostPayload>>;
}

const PostSocialProfiles = (props: Props) => {
	const { postPayload, setPostPayload } = props;

	const handleProfilesChange = (profiles: SocialProfile[]) => {
		setPostPayload({ ...postPayload, socialProfiles: profiles });
	};

	const handleProfileDelete = (id: string | undefined) => {
		if (!id) return;
		postPayload?.socialProfiles?.forEach((profile, index) => {
			if (profile._id === id) {
				postPayload?.socialProfiles?.splice(index, 1);
			}
		});
		setPostPayload({ ...postPayload });
	};

	return (
		<>
			<ProfileSelector
				selectedProfiles={postPayload?.socialProfiles || []}
				onChange={handleProfilesChange}
			/>

			{postPayload?.socialProfiles?.map((profile, index) => (
				<SocialProfileChip
					key={index}
					profile={profile}
					onDeleteClick={(profile) => handleProfileDelete(profile._id)}
				/>
			))}
		</>
	);
};

export default PostSocialProfiles;

import React from "react";
import { ScheduleItem } from "..";
import ListPost from "modules/Posts/ListPost";
import Post from "types/Post";
import useStyles from "../styles/list-view-styles";
interface Props {
	listItem: ScheduleItem;
	handlePostClick: (post: Post) => void;
}

export default function ListViewItem(props: Props) {
	const classes = useStyles();

	const { listItem, handlePostClick } = props;

	return (
		<div className={classes.container}>
			<div className={classes.date}>
				<span className={classes.dateText}>{listItem.date}</span>
			</div>

			<div className={classes.postsContainer}>
				{listItem.posts.map((post, index) => (
					<ListPost key={index} post={post} onClick={handlePostClick} />
				))}
			</div>
		</div>
	);
}

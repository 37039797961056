export default function QuestionMarkIconSvg({ size, color }: { size?: number; color?: string }) {
	return (
		<svg
			width={size || "26"}
			height={size || "26"}
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.9997 15.4999V12.9999H13.833C15.2137 12.9999 16.333 11.8806 16.333 10.4999V10.3333C16.333 9.04459 15.2883 7.99992 13.9997 7.99992H12.9997C11.619 7.99992 10.4997 9.11921 10.4997 10.4999M12.1663 17.9999H13.833M12.9997 24.6666C6.55635 24.6666 1.33301 19.4432 1.33301 12.9999C1.33301 6.5566 6.55635 1.33325 12.9997 1.33325C19.443 1.33325 24.6663 6.5566 24.6663 12.9999C24.6663 19.4432 19.443 24.6666 12.9997 24.6666Z"
				stroke={color || "#3E4C55"}
			/>
		</svg>
	);
}

const ProfileIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.2778 11.2229C10.2778 8.61486 12.392 6.50065 15 6.50065C17.608 6.50065 19.7222 8.61486 19.7222 11.2229C19.7222 13.8309 17.608 15.9451 15 15.9451C12.392 15.9451 10.2778 13.8309 10.2778 11.2229Z"
				fill={color || "#F04755"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 0.833984C7.17595 0.833984 0.833313 7.17662 0.833313 15.0007C0.833313 22.8247 7.17595 29.1673 15 29.1673C22.824 29.1673 29.1666 22.8247 29.1666 15.0007C29.1666 7.17662 22.824 0.833984 15 0.833984ZM2.7222 15.0007C2.7222 8.21982 8.21915 2.72287 15 2.72287C21.7808 2.72287 27.2778 8.21982 27.2778 15.0007C27.2778 18.4943 25.8186 21.6471 23.4764 23.8829C23.1912 20.4947 20.3508 17.834 16.8889 17.834H13.1111C9.64921 17.834 6.80872 20.4947 6.52353 23.8829C4.18138 21.6471 2.7222 18.4943 2.7222 15.0007Z"
				fill={color || "#F04755"}
			/>
		</svg>
	);
};

export default ProfileIconSvg;

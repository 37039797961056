import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar-custom-styles.css";
import DeleteIcon from "assets/delete-icon.png";
import Button from "../../Basic/Button";
import TimePicker, { Time } from "./Components/TimePicker";
import moment from "moment";
import useDateTimePickerStyles from "./styles";
import { useEffect } from "react";

interface Props {
	handleClose: () => void;
	onSubmit?: (date: Date | undefined) => void;
	defaultValue?: Date;
	reminder?: boolean;
	anchorEl: HTMLElement | null;
	hideTimePicker?: boolean;
	allowPastDate?: boolean;
}

export default function ScheduleSelector(props: Props) {
	const [selectedDate, setSelectedDate] = useState<Date>();
	const [selectedTime, setSelectedTime] = useState<Time>();
	const [error, setError] = useState<string>();
	const classes = useDateTimePickerStyles();
	const { handleClose, onSubmit, defaultValue, anchorEl, reminder, hideTimePicker, allowPastDate } =
		props;

	const handleRemoveClick = () => {
		setSelectedDate(undefined);
		setSelectedTime(undefined);
		onSubmit && onSubmit(undefined);
		handleClose();
	};

	const handleSubmit = () => {
		if (selectedDate && selectedTime) {
			let hours = selectedTime.hours;

			if (selectedTime.dayTime === "PM" && selectedTime.hours < 12) {
				hours = hours + 12;
			} else if (selectedTime.dayTime === "AM" && selectedTime.hours === 12) {
				hours = hours - 12;
			}

			let date = new Date(
				selectedDate.getFullYear(),
				selectedDate.getMonth(),
				selectedDate.getDate(),
				hours,
				selectedTime.minutes
			);

			if (moment(new Date()).diff(moment(date)) > 0 && !allowPastDate) {
				setError("Cannot set date in the past");
			} else {
				setError("");
				onSubmit && onSubmit(date);
				handleClose();
			}
		} else if (selectedDate) {
			let date = new Date(
				selectedDate.getFullYear(),
				selectedDate.getMonth(),
				selectedDate.getDate()
			);

			onSubmit && onSubmit(date);
			handleClose();
		}
	};

	const handleTimePickerChange = (time: Time) => {
		setSelectedTime(time);
	};

	const handleCalendarChange = (date: Date | Date[]) => {
		setSelectedDate(date as Date);
	};

	useEffect(() => {
		if (!selectedDate) {
			setSelectedDate(defaultValue || moment().add(1, "days").toDate());
		}
	}, []);

	return (
		<Dialog open={Boolean(anchorEl)} onClose={handleClose}>
			<div className={classes.container}>
				<Calendar
					defaultValue={defaultValue || moment().add(1, "days").toDate()}
					className={classes.calendarContainer}
					onChange={handleCalendarChange}
				/>

				{!hideTimePicker && (
					<TimePicker
						onChange={handleTimePickerChange}
						defaultValue={defaultValue?.toISOString()}
					/>
				)}

				{error && <div className={classes.error}>{error}</div>}

				{reminder && (
					<div className={classes.deleteReminder} onClick={handleRemoveClick}>
						<img src={DeleteIcon} height={15} alt="delete" />
						<span className={classes.removeText}>Remove Reminder</span>
					</div>
				)}

				<div className={classes.buttons}>
					<Button onClick={handleClose} secondary style={{ width: 160, marginRight: 16 }}>
						Cancel
					</Button>
					<Button onClick={handleSubmit} secondary style={{ width: 160 }}>
						Submit
					</Button>
				</div>
			</div>
		</Dialog>
	);
}

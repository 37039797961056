import { Box } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import CheckIconSvg from "assets/SVG/CheckIconSvg";
import DashIconSvg from "assets/SVG/DashIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { useTranslation } from "react-i18next";
import SubscriptionPlan from "types/SubscriptionPlan";
import useSubscriptionPlanStyles from "./styles";

interface Props {
	plan: SubscriptionPlan;
	style?: CSSProperties;
	hideNetworks?: boolean;
}

export const PlanNetworks = ({ plan, style }: Props) => {
	return (
		<Box style={style} display="flex" alignItems="center">
			{plan.networks.map((network, index) => (
				<Box style={{ marginRight: 6 }}>
					<NetworkIcon strokeWidth={2} size={25} network={network} key={index} />
				</Box>
			))}
		</Box>
	);
};

const Feature = ({ label, value }: { label: string; value: any }) => {
	const { t } = useTranslation("settings");

	const classes = useSubscriptionPlanStyles();
	const isNumber = typeof value === "number";
	const isUnlimited = isNumber && value === 999;

	return (
		<Box
			style={{ opacity: !value ? 0.6 : 1 }}
			className={classes.featureItem}
			display="flex"
			alignItems="center"
		>
			{value ? <CheckIconSvg size={20} /> : <DashIconSvg size={20} />}
			<Box style={{ marginLeft: 6 }}>
				{isNumber && (isUnlimited ? "Unlimited" : value)}{" "}
				{value === 1 && label === "postsPerDay" ? t("postPerDay") : t(label)}
			</Box>
		</Box>
	);
};

const features = [
	"postsPerDay",
	"socialProfiles",
	"teamMembers",
	"replyOption",
	"listenAudience",
	"analytics",
];

const PlanFeatures = (props: Props) => {
	const { plan, style, hideNetworks } = props;

	return (
		<Box style={style}>
			{!hideNetworks && <PlanNetworks plan={plan} />}
			<Box style={{ marginTop: 16 }}>
				{features.map((feature) => (
					<Feature key={feature} label={feature} value={plan.features[feature]} />
				))}
			</Box>
		</Box>
	);
};
export default PlanFeatures;

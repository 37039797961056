import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "components/Basic/Button";
import Input from "components/Basic/Inputs/Input";
import ImagePicker from "components/Media/ImagePicker/ImagePicker";
import { uploadImage } from "redux/actions/media/upload";
import { setSnackbarState } from "redux/actions/snackBar";
import { createTeam, setCurrentTeamId, updateTeam } from "redux/actions/team/post";
import { CircularProgress, Box } from "@material-ui/core";
import Team from "types/User/Team";
import useWorkspacesStyles from "../../../styles";
import { PlaceholderPicture } from "assets";
import { useTranslation } from "react-i18next";

interface Props {
	team?: Team;
	setNewTeam: Dispatch<SetStateAction<boolean>>;
}

const TeamInfoSettings = (props: Props) => {
	const [selectedFile, setSelectedFile] = useState<File>();
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<{ [index: string]: string }>({});
	const [name, setName] = useState<string>("");
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const { t } = useTranslation("workspaces");
	const { team, setNewTeam } = props;
	const dispatch = useDispatch();
	const classes = useWorkspacesStyles();

	const handleImageSubmit = (image: File | undefined) => {
		setSelectedFile(image);
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setErrors({ ...errors });
		setName(e.target.value);
	};

	const validateInputs = () => {
		const errors: { [index: string]: string } = {};

		if (!name) {
			errors.name = t("workspaceNameRequired");
		}

		setErrors({ ...errors });
		return Object.keys(errors).length > 0;
	};

	const handleSaveClick = async () => {
		if (validateInputs()) return;

		setLoading(true);
		let payload: { name: string; image?: string } = { name };
		if (selectedFile) {
			try {
				let imageKey = await uploadImage(selectedFile, () => {});

				if (imageKey) {
					payload.image = imageKey;
				}
			} catch (e) {
				dispatch(setSnackbarState(true, "error", "TeamImageUploadError"));
			}
		}

		if (team) {
			dispatch(
				updateTeam(team._id, payload, () => {
					setLoading(false);
					setSubmitDisabled(true);
				})
			);
		} else {
			dispatch(
				createTeam(payload, (createdTeam, error) => {
					setLoading(false);
					if (createdTeam) {
						setErrors({});
						dispatch(setCurrentTeamId(createdTeam._id));
						setNewTeam(false)
					} else if (error) {
						setErrors({ name: t(error) });
					}
				})
			);
		}

		setSelectedFile(undefined);
	};

	useEffect(() => {
		if (team) {
			setName(team.name);
		} else {
			setName("");
		}
		setErrors({});
	}, [team]);

	useEffect(() => {
		let submitDisabled = true;

		if (team) {
			if (team.name !== name || selectedFile) {
				submitDisabled = false;
			}
		} else if (name) {
			submitDisabled = false;
		}

		if (loading || Object.keys(errors).length > 0) {
			submitDisabled = true;
		}

		setSubmitDisabled(submitDisabled);
	}, [team, name, loading, errors, selectedFile]);

	// TODO: Add team image size limit error handling

	return (
		<Box className={classes.teamInfoContainer} display="flex" alignItems="flex-start">
			<ImagePicker
				imageUrl={team?.image}
				onSubmit={handleImageSubmit}
				placeholderImage={PlaceholderPicture}
				size={90}
			/>

			<div style={{ marginLeft: 24 }}>
				<div className={classes.inputTitle}>{t("workspaceName")}</div>
				<Box display="flex">
					<Box>
						<Input
							value={name}
							onChange={handleInputChange}
							error={errors.name}
							errorStyle={{ maxWidth: 250 }}
							onKeyDown={(e) => e.key === "Enter" && handleSaveClick()}
							style={{ width: 280 }}
						/>
					</Box>

					<Button
						onClick={handleSaveClick}
						style={{ width: 120, marginLeft: 12 }}
						disabled={submitDisabled}
						color="secondary"
					>
						{loading ? <CircularProgress size={20} /> : t("saveChanges")}
					</Button>
				</Box>
			</div>
		</Box>
	);
};

export default TeamInfoSettings;

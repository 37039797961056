import { useTheme } from "@material-ui/core";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import CheckBox from "components/Basic/Inputs/CheckBox";
import usePagesDialogStyles from "modules/Settings/styles/pages-selection-dialog";
import { Dispatch, SetStateAction } from "react";
import SocialProfile from "types/SocialProfile";
import { SubProfileType } from "../SubProfilesDialog";

interface Props {
	profileTypes: SubProfileType[];
	checkedProfileIds: string[];
	selectedTab?: SubProfileType;
	currentProfile: SocialProfile;
	subProfiles: SocialProfile[];
	onSelectAllClick: () => void;
	setSelectedTab: Dispatch<SetStateAction<SubProfileType | undefined>>;
}

const ResultHeader: React.FC<Props> = (props) => {
	const theme = useTheme();
	const classes = usePagesDialogStyles();
	const {
		profileTypes,
		checkedProfileIds,
		selectedTab,
		subProfiles,
		currentProfile,
		onSelectAllClick,
		setSelectedTab,
	} = props;

	const selectedTabStyle = {
		color: theme.palette.text.secondary,
		fontWeight: 500,
		borderBottom: `2px solid ${theme.palette.text.secondary}`,
	};

	return (
		<div className={classes.resultHeader}>
			{/* Profile Tabs */}
			{profileTypes.map((profileType, index) => {
				const selected = selectedTab === profileType;
				return (
					<div
						onClick={() => setSelectedTab(profileType)}
						style={selected ? selectedTabStyle : {}}
						className={classes.tab}
						key={index}
					>
						<NetworkIcon
							size={20}
							color={selected ? theme.palette.text.secondary : theme.palette.text.primary}
							strokeWidth={selected ? 4 : 2}
							network={profileType === "instagram" ? "instagram" : currentProfile.network}
						/>
						<span style={{ paddingLeft: 8 }}>{profileType}</span>
					</div>
				);
			})}

			<div className={classes.rightContainer}>
				<div className={classes.linearContainer}>
					<div style={{ fontSize: 14, color: theme.palette.text.secondary, userSelect: "none" }}>
						Select All
					</div>

					<CheckBox
						checked={checkedProfileIds.length === subProfiles.length}
						onChange={() => onSelectAllClick()}
					/>
				</div>
			</div>
		</div>
	);
};

export default ResultHeader;

import { ReduxAction } from "types/ReduxActions";

export interface InsightsReducerState {
	dailyPostReactions: InsightsEntry[];
	profileImpressions: InsightsEntry[];
	profileEngagements: InsightsEntry[];
	dailyProfileLikes: InsightsEntry[];
	totalProfileLikes: number;
	totalViews: number
}

export type InsightName =
	| "totalFollowers"
	| "dailyPostReactions"
	| "lifeTimeViews"
	| "totalProfileLikes"
	| "profileImpressions"
	| "profileEngagements"
	| 'dailyProfileLikes';

export type ReactionInsightType = {
	type: "like" | "dislike" | "anger" | "sorry" | "haha" | "wow" | "love";
	color: string;
};
export const allReactions: ReactionInsightType[] = [
	{ type: "like", color: "#2d86ff" },
	{ type: "dislike", color: "" },
	{ type: "anger", color: "#8c0618" },
	{ type: "sorry", color: "#e37964" },
	{ type: "haha", color: "#3f48f2" },
	{ type: "wow", color: "rgb(247, 177, 37)" },
	{ type: "love", color: "rgb(243, 62, 88)" },
];

export type InsightsEntry = {
	metadata: {
		value: number;
		socialProfile: string;
		team: string;
		reactionType?: ReactionInsightType["type"];
		name: InsightName;
	};
	time: Date;
};

let initialState: InsightsReducerState = {
	dailyPostReactions: [],
	profileImpressions: [],
	dailyProfileLikes: [],
	profileEngagements: [],
	totalViews: 0,
	totalProfileLikes: 0,
};

export const SET_INSIGHTS_STATE = "SET_INSIGHTS_STATE";

export default function insightsReducer(
	state: InsightsReducerState = initialState,
	action: ReduxAction
): InsightsReducerState {
	switch (action.type) {
		case SET_INSIGHTS_STATE: {
			return { ...state, ...action.payload };
		}

		default:
			return state;
	}
}

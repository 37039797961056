import { useState, useEffect } from "react";
import useStyles from "../styles";
import { CircularProgress } from "@material-ui/core";
import Masonry from "react-masonry-css";
import NoteCard from "modules/Notes/Note";
import NoteDialog from "modules/Notes/NoteDialog";
import Note from "types/Note";
import PlusIconTransparent from "assets/plus-icon-transparent.png";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { listNotes } from "redux/actions/notes/get";
import EmptyPageMessage from "components/EmptyPageMessage";
import { useTranslation } from "react-i18next";
import useNotesCardStyles from "./styles";
import InfiniteScroll from "react-infinite-scroll-component";

const Notes = () => {
	const mainClasses = useStyles();
	const classes = useNotesCardStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { notes, notesQueryTotal, notesLoading } = useSelector((state: StoreState) => state.notes);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const [noteDialog, setNoteDialog] = useState<boolean>(false);
	const [selectedNote, setSelectedNote] = useState<Note>();

	const fetchMore = () => {
		dispatch(listNotes(false, { from: notes.length, pageLimit: 10 }));
	};

	const handleNoteClick = (note: Note) => {
		setSelectedNote(note);
		setNoteDialog(true);
	};

	const handleAddNoteClick = () => {
		setSelectedNote(undefined);
		setNoteDialog(true);
	};

	useEffect(() => {
		if (currentTeamId) {
			dispatch(listNotes(false, { pageLimit: 10 }));
		}
	}, [currentTeamId]);

	return (
		<div style={{ width: "50%", marginTop: 36 }}>
			<div className={classes.header}>
				<span className={mainClasses.title} style={{ marginLeft: 26 }}>
					{t("notes")}
				</span>
				<img onClick={handleAddNoteClick} src={PlusIconTransparent} className={classes.plusIcon} />
			</div>
			<div className={mainClasses.container} style={{ height: 440, marginTop: 12, width: "100%" }}>
				<div className={classes.notesContainer}>
					<InfiniteScroll
						style={{ overflowX: "hidden" }}
						hasMore={notesQueryTotal > notes.length}
						loader={<h4>Loading...</h4>}
						height={380}
						next={fetchMore}
						dataLength={notes.length}
					>
						{notesLoading && (
							<div className={classes.loadingContainer}>
								<CircularProgress />
							</div>
						)}
						{!notesLoading && notes?.length === 0 && (
							<div style={{ marginTop: 16 }}>
								<EmptyPageMessage
									titleStyle={{ fontSize: 28 }}
									illustrationStyle={{ width: 400 }}
									title={t("noNotesFound")}
								/>
							</div>
						)}

						{!notesLoading && (
							<Masonry
								breakpointCols={2}
								className="masonry-grid"
								columnClassName="masonry-grid_column"
							>
								{notes?.map((note, index) => (
									<NoteCard onClick={handleNoteClick} key={index} note={note} />
								))}
							</Masonry>
						)}
					</InfiniteScroll>
				</div>

				<NoteDialog
					open={noteDialog}
					handleClose={() => setNoteDialog(false)}
					selectedNote={selectedNote}
				/>
			</div>
		</div>
	);
};

export default Notes;

import { Box, CircularProgress } from "@material-ui/core";
import { debounce } from "lodash";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { listTasks, TasksFilters } from "redux/actions/tasks/get";
import { StoreState } from "../../../setup/store";
import Task from "types/Task";
import Dropdown from "../../Basic/Inputs/Dropdown";
import SearchInput from "../../Basic/Inputs/SearchInput";
import MenuOption from "../MenuOption";

interface Props {
	anchorEl: HTMLElement | null;
	setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
	onSelect: (task: Task | undefined) => void;
	selectedTaskId?: string;
	title: string;
}

const selectedColor = "rgb(247, 247, 247)";

const TaskSelector = (props: Props) => {
	const { anchorEl, title, selectedTaskId, setAnchorEl, onSelect } = props;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [fetched, setFetched] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState("");
	const { tasks, listTasksLoading, tasksQueryTotal } = useSelector(
		(state: StoreState) => state.tasks
	);

	const debouncedSearch = useCallback(
		debounce((searchValue) => {
			dispatch(listTasks(false, { status: "inProgress", searchValue, pageLimit: 10 }));
		}, 300),
		[]
	);

	const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchValue(value);
		debouncedSearch(value);
		setFetched(false);
	};

	const fetchTasks = (next?: boolean) => {
		let filters: TasksFilters = {
			status: "inProgress",
			pageLimit: 10,
			descending: true,
		};

		if (next) {
			filters.from = tasks.length;
		}

		dispatch(listTasks(false, filters));
	};

	useEffect(() => {
		if (anchorEl && !fetched) {
			fetchTasks();
			setFetched(true);
			setSearchValue("");
		}
	}, [anchorEl]);

	return (
		<Dropdown
			anchorEl={anchorEl}
			handleClose={() => setAnchorEl(null)}
			title={title}
			onClick={(e) => setAnchorEl(e.currentTarget)}
			dropdownComponent={
				<Box style={{ maxHeight: 280, width: 200 }}>
					<Box style={{ padding: 12 }}>
						<SearchInput
							inputStyle={{ width: 160, fontSize: 12 }}
							iconStyle={{ marginTop: 2, marginLeft: 2 }}
							iconSize={16}
							style={{ width: 180 }}
							value={searchValue}
							placeholder={t("searchTasks")}
							onChange={handleSearchInputChange}
						/>
					</Box>

					<InfiniteScroll
						dataLength={tasks.length}
						next={() => fetchTasks(true)}
						loader={<div style={{ padding: 12, fontSize: 12 }}>{t("loading")}...</div>}
						height={245}
						hasMore={tasksQueryTotal > tasks.length}
					>
						<MenuOption
							onClick={() => onSelect(undefined)}
							style={{ backgroundColor: !selectedTaskId ? selectedColor : "" }}
						>
							{t("none")}
						</MenuOption>

						{listTasksLoading && (
							<Box style={{ padding: 16, width: "fit-content", margin: "auto" }}>
								<CircularProgress size={20} />
							</Box>
						)}

						{!listTasksLoading &&
							tasks.map((task) => (
								<MenuOption
									key={task._id}
									style={{
										backgroundColor: task._id === selectedTaskId ? selectedColor : "",
									}}
									onClick={() => onSelect(task)}
								>
									{task.title}
								</MenuOption>
							))}
					</InfiniteScroll>
				</Box>
			}
		/>
	);
};

export default TaskSelector;

import { Box } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { TodayItem } from "../..";
import Post from "types/Post";
import TodayViewItem from "./TodayViewItem";
import useStyles from "../../styles/styles";
import EmptyPageMessage from "components/EmptyPageMessage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../../setup/store";

interface Props {
	onPostClick: (post: Post) => void;
}

const TodayView = (props: Props) => {
	const [timelineSeparator, setTimelineSeparator] = useState<string>();
	const [todayItems, setTodayItems] = useState<TodayItem[]>([]);
	const { t } = useTranslation("posts");
	const { posts } = useSelector((state: StoreState) => state.posts);
	const { onPostClick } = props;
	const classes = useStyles();

	useEffect(() => {
		// Today Items
		let todayItems: TodayItem[] = [];

		posts.forEach((post) => {
			if (moment().date() !== moment(post.scheduledAt).date()) return;
			const postDate = new Date(post.scheduledAt!);
			postDate.setMinutes(0);
			const hour = moment(postDate.toString()).format("hh:mm A");
			let itemFound = false;
			todayItems.forEach((item) => {
				if (item.hour === hour) {
					itemFound = true;
					item.posts.push(post);
				}
			});

			if (!itemFound) {
				todayItems.push({
					hour,
					posts: [post],
				});
			}
		});

		setTodayItems([...todayItems]);
	}, [posts]);

	return (
		<Box>
			{todayItems.length === 0 && (
				<Box className={classes.centeredContainer}>
					<EmptyPageMessage title={t("emptyPosts")} />
				</Box>
			)}
			{todayItems.map((item, index) => (
				<TodayViewItem
					todayItem={item}
					onPostClick={onPostClick}
					key={index}
					timelineSeparator={timelineSeparator}
					setTimelineSeparator={setTimelineSeparator}
				/>
			))}
		</Box>
	);
};

export default TodayView;

import { Box, Tooltip } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationIconActive from "assets/SVG/NotificationIconActiveSvg";
import NotificationIcon from "assets/SVG/NotificationIconSvg";
import WorkspaceSvg from "assets/SVG/WorkspaceSvg";
import NotificationsModal from "modules/Notifications/NotificationsModal";
import { readNotifications } from "redux/actions/notifications";
import { StoreState } from "../../../setup/store";
import SearchInput from "../../Basic/Inputs/SearchInput";
import TeamPicture from "../../Media/TeamPicture";
import TeamSelector from "../../Popovers/TeamSelector";
import useStyles from "../styles/main-header-styles";
import { useTranslation } from "react-i18next";

interface Props {
	page: string;
	hideSearch?: boolean;
	drawerOpen: boolean;
	searchValue: string;
	setSearchValue: (value: string) => void;
}

export default function MainHeader(props: Props) {
	const [notificationsModal, setNotificationsModal] = useState<HTMLElement | null>(null);
	const [teamSelector, setTeamSelector] = useState<HTMLElement | null>(null);
	const { modalNotifications } = useSelector((state: StoreState) => state.notifications);
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation();
	const { page, hideSearch, drawerOpen, searchValue, setSearchValue } = props;
	const currentTeam = userData?.teams.find((team) => team._id === currentTeamId);

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const handleNotificationsModalClose = (readAll: boolean) => {
		setNotificationsModal(null);

		if (readAll && modalNotifications.length > 0) {
			dispatch(readNotifications(modalNotifications.map((item) => item._id)));
		}
	};

	return (
		<>
			<NotificationsModal
				anchorEl={notificationsModal}
				handleClose={handleNotificationsModalClose}
			/>

			<TeamSelector anchorEl={teamSelector} handleClose={() => setTeamSelector(null)} />

			<div className={classes.headerContainer} style={{ paddingLeft: drawerOpen ? 50 : 0 }}>
				<div className={classes.innerContainer}>
					<span className={classes.pageName}>
						{searchValue ? `Search Results for “${searchValue}“` : page}
					</span>
					<div className={classes.rightSection}>
						{!hideSearch && <SearchInput onChange={handleSearchChange} value={searchValue} />}

						<Tooltip title={t("notifications") as string}>
							<div
								onClick={(e) => setNotificationsModal(e.currentTarget)}
								style={{ height: 28 }}
								className={classes.bellContainer}
							>
								{modalNotifications.find((notif) => !notif.read) ? (
									<>
										<NotificationIconActive size={28} />
										<div className={classes.notificationActiveCircle}></div>
									</>
								) : (
									<NotificationIcon size={28} />
								)}
							</div>
						</Tooltip>

						<Tooltip title={t("workspace") as string}>
							<Box onClick={(e) => setTeamSelector(e.currentTarget)}>
								{currentTeam?.image ? (
									<TeamPicture className={classes.teamIconContainer} team={currentTeam} />
								) : (
									<Box style={{ marginLeft: 16, height: 28, cursor: "pointer" }}>
										<WorkspaceSvg size={28} />
									</Box>
								)}
							</Box>
						</Tooltip>
					</div>
				</div>
			</div>
		</>
	);
}

import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import TabsNew from "components/TabsNew";
import Task from "types/Task";
import TaskComments from "../Comments/TaskComments";
import ViewModeHeader from "./Header";
import Summary from "./Summary";
import ViewModeActions from "./Actions";

interface Props {
	selectedTask: Task;
	setEditMode: Dispatch<SetStateAction<boolean>>;
	onSubmit?: (updatedTask: Task, deleted?: boolean) => void;
	onClose: () => void;
}

const TaskDialogViewMode = (props: Props) => {
	const { selectedTask, onClose, onSubmit, setEditMode } = props;
	const [selectedTab, setSelectedTab] = useState<string>("summary");

	return (
		<Box>
			<ViewModeHeader selectedTask={selectedTask} />

			<Box style={{ marginTop: 24 }}>
				<TabsNew
					tabs={["summary", "comments"]}
					style={{ marginLeft: 8 }}
					tabStyle={{ width: 90 }}
					tabTextStyle={{ fontSize: 14, marginBottom: 8 }}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
				/>

				{selectedTab === "summary" && <Summary selectedTask={selectedTask} />}

				{selectedTab === "comments" && <TaskComments selectedTask={selectedTask} />}
			</Box>

			{selectedTask && selectedTab !== "comments" && (
				<ViewModeActions
					selectedTask={selectedTask}
					setEditMode={setEditMode}
					onClose={onClose}
					onSubmit={onSubmit}
				/>
			)}
		</Box>
	);
};

export default TaskDialogViewMode;

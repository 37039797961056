import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		maxHeight: "90vh",
		paddingTop: 16,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
	},

	loadingContainer: {
		display: "flex",
		marginTop: 52,
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
	},
}));

export default useStyles;

import axios from "axios";
import { Dispatch } from "react";
import config from "config";
import { StoreState } from "../../../setup/store";
import {
	AcceptTeamInvitationFunc,
	DeleteTeamInvitationFunc,
	RemoveTeamUserFunc,
	SendTeamInvitationFunc,
	UpdateTeamUserRoleFunc,
} from "types/ReduxActions";
import { SET_TEAMS_DATA } from "../../reducers/teams";
import { setSnackbarState } from "../snackBar";
import { setCurrentTeamId } from "./post";
import { getHeaders } from "helpers/utils";

export const sendTeamInvitation: SendTeamInvitationFunc = (teamId, email, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams } = getState().teams;

		try {
			const result = await axios.post(
				`${config.apiUrl}/teams/${teamId}/invitations`,
				{ email },
				{ params: { teamId } }
			);
			const { invitations } = result.data || {};
			const teamIndex = teams.findIndex((team) => team._id === teamId);

			if (teamIndex >= 0) {
				teams.splice(teamIndex, 1, { ...teams[teamIndex], invitations });

				dispatch({
					type: SET_TEAMS_DATA,
					payload: [...teams],
				});
			}

			dispatch(setSnackbarState(true, "success", "InvitationSendSuccess"));
			cb(true, null);
		} catch (e) {
			console.log(e.response);
			cb(false, e.response?.data?.error || "SomethingWentWrongError");
		}
	};
};

export const deleteTeamInvitation: DeleteTeamInvitationFunc = (teamId, email, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams } = getState().teams;

		try {
			await axios.delete(`${config.apiUrl}/teams/${teamId}/invitations/${email}`, {
				params: { teamId },
			});
			const teamIndex = teams.findIndex((team) => team._id === teamId);

			if (teamIndex >= 0) {
				teams.splice(teamIndex, 1, {
					...teams[teamIndex],
					invitations: teams[teamIndex].invitations?.filter(
						(invitation) => invitation.email !== email
					),
				});

				dispatch({
					type: SET_TEAMS_DATA,
					payload: [...teams],
				});
			}

			cb(true);
		} catch (e) {
			console.log(e.response.data);
			cb(false);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		}
	};
};

export const acceptTeamInvitation: AcceptTeamInvitationFunc = (teamId, callback) => {
	return async (dispatch: Dispatch<any>) => {
		try {
			// Do not remove getHeader() here
			await axios.get(`${config.apiUrl}/teams/${teamId}/accept`, { headers: getHeaders() });

			callback(true, null);
			dispatch(setSnackbarState(true, "success", "InvitationAcceptSuccess"));
			dispatch(setCurrentTeamId(teamId));
			window.location.reload();
		} catch (e) {
			const { error } = e.response?.data || {};
			console.log(error);
			callback(false, error || "SomethingWentWrongError");

			dispatch(setSnackbarState(true, "error", error));
		}
	};
};

export const removeTeamUser: RemoveTeamUserFunc = (teamId, teamUserId, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams } = getState().teams;

		try {
			await axios.delete(`${config.apiUrl}/teams/${teamId}/users/${teamUserId}`, {});

			callback && callback(true, null);
			dispatch(setSnackbarState(true, "success", "TeamUserRemoveSuccess"));

			const team = teams.find((team) => team._id === teamId);
			if (team) {
				team.users = team?.users.filter((user) => user._id !== teamUserId);
			}

			dispatch({
				type: SET_TEAMS_DATA,
				payload: [...teams],
			});
		} catch (e) {
			console.log(e.response.data);
			callback && callback(false, e.response?.data?.error || "SomethingWentWrongError");
			dispatch(
				setSnackbarState(true, "error", e.response?.data?.error || "SomethingWentWrongError")
			);
		}
	};
};

export const updateTeamUserRole: UpdateTeamUserRoleFunc = (teamId, teamUserId, role, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { teams } = getState().teams;
		const { userData } = getState().user;
		try {
			await axios.put(`${config.apiUrl}/teams/${teamId}/users/${teamUserId}`, { role });

			callback && callback(true, null);
			dispatch(setSnackbarState(true, "success", "RoleUpdateSuccess"));

			const team = teams.find((team) => team._id === teamId);
			const teamUser = team?.users.find((teamUser) => teamUser._id === teamUserId);

			if (teamUser) {
				teamUser.role = role;
			}
			// If team ownership is changed, set the current user's role to be just an "admin"
			if (role === "owner") {
				const currentTeamUserIndex = team?.users.findIndex(
					(teamUser) => teamUser.user?._id === userData?._id
				);

				if (typeof currentTeamUserIndex === "number" && currentTeamUserIndex > -1) {
					team!.users[currentTeamUserIndex] = {
						...team!.users[currentTeamUserIndex],
						role: "admin",
					};
				}
			}

			dispatch({
				type: SET_TEAMS_DATA,
				payload: [...teams],
			});
		} catch (e) {
			console.log(e.response.data);
			callback && callback(false, e.response?.data?.error || "SomethingWentWrongError");
			dispatch(
				setSnackbarState(true, "error", e.response?.data?.error || "SomethingWentWrongError")
			);
		}
	};
};

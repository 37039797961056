import { Box } from "@material-ui/core";
import { CSSProperties, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaAttachment from "components/Media/MediaAttachment";
import { setFullScreenMediaPreview } from "redux/actions/modals";
import { StoreState } from "../../../../setup/store";
import { Message as MessageType } from "types/Chat/Chat";
import { TaskComment } from "types/Task";
import { TeamUser } from "types/User/Team";
import MessageHeader from "./Header";
import MessageOptions from "./Options";
import useMessageStyles from "./styles";
import MarkdownText from "components/Basic/Text/MarkdownText";

interface Props {
	message: Partial<TaskComment & MessageType>;
	style?: CSSProperties;
	hideHeader?: boolean;
	type: "message" | "comment";
}

const Message = (props: Props) => {
	const [teamUser, setTeamUser] = useState<TeamUser>();
	const { teams, currentTeamId } = useSelector((state: StoreState) => state.teams);
	const classes = useMessageStyles();
	const { message, style, hideHeader, type } = props;
	const dispatch = useDispatch();

	const handleAttachmentClick = (index: number) => {
		if (message.media) {
			dispatch(
				setFullScreenMediaPreview({
					open: true,
					media: message.media,
					initialSelectedIndex: index,
					author: teamUser,
					createdAt: message.updatedAt,
				})
			);
		}
	};

	useEffect(() => {
		const author = message.user || message.sender;
		teams.forEach((team) => {
			if (team._id === currentTeamId) {
				setTeamUser(team.users.find((teamUser) => teamUser.user?._id === author));
			}
		});
	}, [teams, currentTeamId, message]);

	if (!teamUser) return <></>;

	return (
		<Box
			style={{
				padding: 16,
				paddingBottom: hideHeader ? 0 : 8,
				paddingTop: hideHeader ? 0 : 16,
				...style,
			}}
		>
			{!hideHeader && <MessageHeader message={message} teamUser={teamUser} type={type} />}

			<Box className={classes.content}>
				<Box>
					<MarkdownText text={message.content || ""} />

					{Boolean(message.media?.length) && (
						<Box
							style={{ gap: 12, width: "100%" }}
							flexWrap="wrap"
							display="flex"
							alignItems="center"
						>
							{message.media?.map((attachment, index) => (
								<MediaAttachment
									key={index}
									attachment={attachment.value}
									type={attachment.type}
									thumbnailKey={attachment.thumbnail}
									style={{
										maxWidth: 999,
										borderRadius: 6,
										marginRight: 0,
										height: message.media?.length! > 4 ? 60 : message.media?.length! > 2 ? 80 : 90,
									}}
									playButtonStyle={{ height: 20 }}
									onAttachmentClick={() => handleAttachmentClick(index)}
								/>
							))}
						</Box>
					)}
				</Box>
				{hideHeader && <MessageOptions message={message} type={type} />}
			</Box>
		</Box>
	);
};

export default Message;

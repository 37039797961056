import { Box } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

const WatchTeamSkeleton = () => {
	return (
		<>
			{Array.from(Array(4).keys()).map(() => (
				<Box display="flex" style={{ marginTop: 24 }}>
					<Skeleton style={{ width: 30, height: 30, borderRadius: "50%" }} />

					<Box display="flex" flexDirection="column" style={{ marginRight: 12, marginLeft: 12 }}>
						<Skeleton style={{ width: 400, marginBottom: 8 }} />
						<Skeleton style={{ width: 400, height: 20, marginBottom: 8 }} />
					</Box>
				</Box>
			))}
		</>
	);
};

export default WatchTeamSkeleton;

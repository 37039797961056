import { makeStyles } from "@material-ui/core";

const useAttachmentsStyles = makeStyles(() => ({
	mediaContainer: {
		display: "flex",
		alignItems: "center",
		gap: 12,
		padding: 8,
		paddingLeft: 16,
		paddingRight: 16,
		borderRadius: 8,
		background: "#F1F4F8",
		marginTop: 16,
	},
}));

export default useAttachmentsStyles;

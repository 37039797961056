import { makeStyles } from "@material-ui/core";

const useSubscriptionDialogStyles = makeStyles(() => ({
	sectionTitle: {
		fontSize: 14,
		marginLeft: 24,
		fontWeight: 700,
		marginBottom: 16,
	},

	planBox: {
		padding: 16,
		width: "100%",
		paddingLeft: 32,
		border: "1px solid grey",
		borderRadius: 12,
	},

	rightColumn: {
		display: "flex",
		flexDirection: "column",
		width: "50%",
		overflowY: "auto",
	},

	planTitle: {
		color: "#868C91",
		fontFamily: "Chau Philomene One",
		fontSize: 16,
		textTransform: "uppercase",
	},

	buttonsContainer: {
		display: "flex",
		alignItems: "center",
		position: "absolute",
		bottom: 42,
		width: "100%",
		paddingLeft: 96,
		left: -32,
	},

	inputCard: {
		padding: 16,
		paddingLeft: 24,
		paddingRight: 24,
		backgroundColor: "#F1F4F8",
		width: "50%",
		borderRadius: 8,
	},

	inputCardTitle: {
		fontWeight: 700,
	},
}));

export default useSubscriptionDialogStyles;

import useStyles from "./styles";
import Container from "components/Navigation/Container";
import Header from "./Components/Header";
import ListPost from "modules/Posts/ListPost";
import MetaTagsHeader from "components/Navigation/Components/MetaTags";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { useEffect, useState } from "react";
import { listPosts } from "redux/actions/posts/get";
import { LIST_POSTS } from "redux/reducers/posting";
import { CircularProgress } from "@material-ui/core";
import EmptyPageMessage from "components/EmptyPageMessage";
import Post from "types/Post";
import { PostFilters } from "components/Popovers/PostsFilter";
import { setPostPreviewDialog } from "redux/actions/modals";

export default function PostsArchive() {
	const classes = useStyles();
	const [appliedFilters, setAppliedFilters] = useState<PostFilters>([]);
	const [searchValue, setSearchValue] = useState<string>("");
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { posts, listPostsLoading } = useSelector((state: StoreState) => state.posts);
	const dispatch = useDispatch();

	const handlePostClick = (post: Post) => {
		dispatch(setPostPreviewDialog(true, true, post));
	};

	const scrollToTop = () => {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;
	};

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: LIST_POSTS,
				payload: [],
			});
		};
	}, []);

	useEffect(() => {
		if (currentTeamId)
			dispatch(
				listPosts({
					archive: true,
					filters: appliedFilters,
					searchValue,
				})
			);

		scrollToTop();
	}, [appliedFilters, searchValue, currentTeamId]);

	return (
		<Container page="Post Archive">
			<MetaTagsHeader title="Posts - Archive" />
			<div className={classes.pageContainer}>
				<div className={classes.innerContainer}>
					<Header
						appliedFilters={appliedFilters}
						setSearchValue={setSearchValue}
						setAppliedFilters={setAppliedFilters}
					/>

					{listPostsLoading && (
						<div className={classes.loadingContainer}>
							<CircularProgress size={20} />
						</div>
					)}

					{!listPostsLoading && posts.length === 0 && (
						<div className={classes.centeredContainer}>
							<EmptyPageMessage title="No posts found" />
						</div>
					)}

					{!listPostsLoading && posts.length > 0 && (
						<div className={classes.postsContainer}>
							{posts.map((post) => (
								<ListPost
									onClick={handlePostClick}
									style={{ width: "100%" }}
									post={post}
									archive={true}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</Container>
	);
}

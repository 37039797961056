import { Box, Typography, useTheme } from "@material-ui/core";

const Disclaimer = () => {
	const theme = useTheme();

	return (
		<Box
			style={{
				width: 320,
				textAlign: "center",
				marginTop: 32,
				marginLeft: "auto",
				marginRight: "auto",
			}}
		>
			<Typography style={{ fontSize: "12px", color: theme.palette.text.secondary }}>
				By continuing, you agree to HashtagHero's{" "}
				<a target="_blank" rel='noreferrer' href="https://hashtaghero.io/privacy">
					Privacy Policy
				</a>{" "}
				and{" "}
				<a target="_blank" rel='noreferrer' href="https://hashtaghero.io/terms">
					Terms of Service
				</a>
			</Typography>
		</Box>
	);
};

export default Disclaimer;

import { Dispatch } from "react";

import config from "config";
import axios from "axios";
import { setSnackbarState } from "../snackBar";
import {
	LIST_CAMPAIGNS_LOADING,
	LIST_CAMPAIGNS_RESULT,
	SET_NEXT_CAMPAIGN,
} from "../../reducers/campaigns";
import { StoreState } from "../../../setup/store";

type CampaignsQuery = {
	startDate?: string;
	endDate?: string;
};

export const listCampaigns = (query: CampaignsQuery) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		dispatch({
			type: LIST_CAMPAIGNS_LOADING,
			payload: true,
		});

		let startDate = new Date();
		startDate.setHours(0);
		startDate.setMinutes(0);
		startDate.setSeconds(0);

		if (!query.startDate) {
			query.startDate = startDate.toISOString();
		}

		try {
			const result = await axios.get(`${config.apiUrl}/campaigns?teamId=${currentTeamId}`, {
				params: query,
			});

			dispatch({
				type: LIST_CAMPAIGNS_RESULT,
				payload: result.data.campaigns,
			});
		} catch (e) {
			console.log(e);
			dispatch({
				type: LIST_CAMPAIGNS_LOADING,
				payload: false,
			});

			dispatch(setSnackbarState(true, "error", "Error fetching campaigns..."));
		}
	};
};

export const getNextCampaign = () => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		if (!currentTeamId) return;

		try {
			const result = await axios.get(`${config.apiUrl}/campaigns/next`, {
				params: { teamId: currentTeamId },
			});

			dispatch({
				type: SET_NEXT_CAMPAIGN,
				payload: result.data.campaign,
			});
		} catch (e) {
			console.log(e);
		}
	};
};

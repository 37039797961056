import { OnboardingStepName } from "components/Onboarding/data";
import { Socket } from "socket.io-client";
import { ReduxAction } from "types/ReduxActions";
import UserData from "types/User/UserData";

export interface UserReducerState {
	authLoading: boolean;
	userDataLoading: boolean;
	updateSettingsLoading: boolean;
	signUpError: { [index: string]: any } | null;
	signUpResponse: { [index: string]: any } | null;

	signInResponse: { [index: string]: any } | null;
	signInError: { [index: string]: any } | null;

	checkAuthResponse: { [index: string]: any } | null;
	checkAuthError: { [index: string]: any } | null;

	userData: UserData | null; // contains a list of teams without user population

	email: string | null;
	password: string | null;

	socket: Socket | null;

	authenticated: boolean;
	onboardingStepName: OnboardingStepName | null;
}

let initialState: UserReducerState = {
	authLoading: true,
	userDataLoading: false,
	updateSettingsLoading: false,
	signUpError: null,
	signUpResponse: null,

	signInResponse: null,
	signInError: null,

	checkAuthResponse: null,
	checkAuthError: null,
	userData: null,

	email: null,
	password: null,

	socket: null,

	authenticated: false,
	onboardingStepName: null,
};

export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const SET_SIGN_UP_ERROR = "SET_SIGN_UP_ERROR";
export const SET_SIGN_UP_RESPONSE = "SET_SIGN_UP_RESPONSE";
export const SET_SIGN_IN_ERROR = "SET_SIGN_IN_ERROR";
export const SET_SIGN_IN_RESPONSE = "SET_SIGN_IN_RESPONSE";
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const CHECK_AUTH_RESOPNSE = "CHECK_AUTH_RESPONSE";
export const CHECK_AUTH_ERROR = "CHECH_AUTH_ERROR";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_DATA_LOADING = "SET_USER_DATA_LOADING";
export const UPDATE_SETTINGS_LOADING = "UPDATE_SETTINGS_LOADING";
export const SET_SOCKET = "SET_SOCKET";
export const SET_ON_BOARDING_STEP = "SET_ON_BOARDING_STEP";

const userReducer = (
	state: UserReducerState = initialState,
	action: ReduxAction
): UserReducerState => {
	switch (action.type) {
		case SET_USER_DATA: {
			return {
				...state,
				authLoading: false,
				userDataLoading: false,
				userData: action.payload.userData || state.userData,
				authenticated: action.payload.authenticated || state.authenticated,
			};
		}
		case SET_USER_DATA_LOADING: {
			return { ...state, userDataLoading: action.payload };
		}
		case CHECK_AUTH_RESOPNSE: {
			return {
				...state,
				authLoading: false,
				checkAuthResponse: action.payload,
			};
		}
		case CHECK_AUTH_ERROR: {
			return { ...state, authLoading: false, checkAuthError: action.payload };
		}
		case SET_AUTH_LOADING: {
			return { ...state, authLoading: action.payload };
		}
		case SET_SIGN_UP_ERROR: {
			return { ...state, authLoading: false, signUpError: action.payload };
		}
		case SET_SIGN_UP_RESPONSE: {
			return { ...state, authLoading: false, signUpResponse: action.payload };
		}
		case SET_SIGN_IN_ERROR: {
			return { ...state, authLoading: false, signInError: action.payload };
		}
		case SET_SIGN_IN_RESPONSE: {
			return { ...state, authLoading: false, signInResponse: action.payload };
		}
		case SET_CREDENTIALS: {
			return {
				...state,
				email: action.payload.email,
				password: action.payload.password,
			};
		}
		case UPDATE_SETTINGS_LOADING: {
			return { ...state, updateSettingsLoading: action.payload };
		}
		case SET_SOCKET: {
			return { ...state, socket: action.payload };
		}

		case SET_ON_BOARDING_STEP: {
			return { ...state, onboardingStepName: action.payload };
		}

		default:
			return state;
	}
};

export default userReducer;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "row",
		minHeight: 150,
		marginTop: 12,
		position: "relative",
		borderRadius: 8,
	},

	date: {
		width: 200,
		display: "flex",
		flexDirection: "row",
		marginTop: 24,
		justifyContent: "center",
	},

	dateText: {
		color: "#5B666E",
		textTransform: "uppercase",
		fontWeight: 500,
		letterSpacing: 0.4,
		fontSize: 16,
	},

	postsContainer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		marginTop: 8,
		marginBottom: 16,
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useMessageInputStyles = makeStyles((theme) => ({
	container: {
		width: "95%",
		position: "absolute",
		bottom: 12,
		left: 12,
		background: "white",
		borderRadius: 8,
		// height: 100,
		border: "1px solid #B6BFC4",
	},

	input: {
		resize: "none",
		width: "100%",
		padding: 12,
		paddingBottom: 0,
		fontFamily: "Avenir",
		lineHeight: 1,
		borderRadius: 8,
		color: theme.palette.text.primary,
		border: "none",
		maxWidth: "85%",
		"&::placeholder": {
			color: "#B6BFC4",
		},
	},

	actions: {
		position: "absolute",
		display: "flex",
		bottom: 2,
		right: 4,
	},

	actionItem: {
		height: 28,
		width: 28,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		borderRadius: "50%",
		"&:hover": {
			backgroundColor: "#ebebeb",
		},
	},

	attachments: {
		display: "flex",
		alignItems: "center",
		gap: 16,
		padding: 12,
		maxWidth: "85%",
		overflowX: "hidden",
		overflowY: "hidden",
	},

	sendButtonContainer: {
		position: "absolute",
		top: 8,
		right: 8,
		cursor: "pointer",
	},
}));

export default useMessageInputStyles;

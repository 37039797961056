import { PaymentMethod } from "types/Billing";
import Campaign from "types/Campaign";
import Post from "types/Post";
import { ReduxAction } from "types/ReduxActions";
import Task, { Media } from "types/Task";
import { TeamUser } from "types/User/Team";

export type FullScreenMediaPreviewState = {
	open: boolean;
	media: Media[];
	author?: TeamUser;
	createdAt?: string;
	initialSelectedIndex: number;
} | null;

export type UserInfoPopoverState = { anchorEl: HTMLElement; userId: string } | null;

export interface ModalsReducerState {
	postPreviewDialog: { open: boolean; post: Post; disableActions: boolean } | null;
	addProfileDialog: boolean;
	userInfoPopper: UserInfoPopoverState;
	campaignDialog: { open: boolean; selectedCampaign: Campaign } | null;
	taskDialog: {
		open: boolean;
		selectedTask?: Task;
		onSubmit?: (updatedTask: Task, deleted?: boolean) => void;
	} | null;
	fullScreenMediaPreview: FullScreenMediaPreviewState;
	billingMethodDialog: { open: boolean; selectedPaymentMethod?: PaymentMethod } | null;
	comingSoonDialog: boolean;
}

const initialState: ModalsReducerState = {
	postPreviewDialog: null,
	campaignDialog: null,
	taskDialog: null,
	fullScreenMediaPreview: null,
	billingMethodDialog: null,
	userInfoPopper: null,
	addProfileDialog: false,
	comingSoonDialog: false,
};

export const SET_POST_PREVIEW_DIALOG = "SET_POST_PREVIEW_DIALOG";
export const SET_CAMPAIGN_DIALOG = "SET_CAMPAIGN_DIALOG";
export const SET_TASK_DIALOG = "SET_TASK_DIALOG";
export const SET_FULL_SCREEN_MEDIA_PREVIEW = "SET_FULL_SCREEN_MEDIA_PREVIEW";
export const SET_BILLING_METHOD_DIALOG = "SET_BILLING_METHOD_DIALOG";
export const SET_USER_INFO_POPPER = "SET_USER_INFO_POPPER";
export const SET_ADD_PROFILE_DIALOG = "SET_ADD_PROFILE_DIALOG";
export const SET_COMING_SOON_DIALOG = "SET_COMING_SOON_DIALOG";

const modalsReducer = (
	state: ModalsReducerState = initialState,
	action: ReduxAction
): ModalsReducerState => {
	switch (action.type) {
		case SET_FULL_SCREEN_MEDIA_PREVIEW: {
			return {
				...state,
				fullScreenMediaPreview: action.payload,
			};
		}

		case SET_POST_PREVIEW_DIALOG: {
			return {
				...state,
				postPreviewDialog: action.payload,
			};
		}

		case SET_CAMPAIGN_DIALOG: {
			return {
				...state,
				campaignDialog: action.payload,
			};
		}

		case SET_TASK_DIALOG: {
			return {
				...state,
				taskDialog: action.payload,
			};
		}

		case SET_BILLING_METHOD_DIALOG: {
			return {
				...state,
				billingMethodDialog: action.payload,
			};
		}

		case SET_USER_INFO_POPPER: {
			return { ...state, userInfoPopper: action.payload };
		}

		case SET_ADD_PROFILE_DIALOG: {
			return { ...state, addProfileDialog: action.payload };
		}

		case SET_COMING_SOON_DIALOG: {
			return { ...state, comingSoonDialog: action.payload };
		}

		default:
			return state;
	}
};

export default modalsReducer;

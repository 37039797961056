import { Box } from "@material-ui/core";
import NumberInput from "components/Basic/Inputs/NumberInput";
import HintText from "components/Basic/Text/HintText";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SubscriptionPayload } from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import useSubscriptionDialogStyles from "../styles";

interface Props {
	payload: Partial<SubscriptionPayload>;
	setPayload: (payload: Partial<SubscriptionPayload>) => void;
}

const UserAddonsInput: React.FC<Props> = (props) => {
	const { t } = useTranslation("settings");
	const { addons } = useSelector((store: StoreState) => store.subscription);
	const { payload, setPayload } = props;
	const classes = useSubscriptionDialogStyles();
	const userAddon = addons.find((addon) => addon.id.includes("User"));
	const userAddonPrice =
		payload.paymentPeriod === "yearly" ? userAddon?.price?.yearly : userAddon?.price?.monthly;

	return (
		<Box display="flex" flexDirection="column" className={classes.inputCard}>
			<Box className={classes.inputCardTitle}>{t("extraTeamMembers")}</Box>
			<HintText style={{ marginBottom: 12 }}>${userAddonPrice}/mth per member</HintText>

			<NumberInput
				value={payload.userAddons || 0}
				onChange={(value) =>
					setPayload({
						...payload,
						userAddons: value > 100 ? 100 : value,
					})
				}
			/>
		</Box>
	);
};

export default UserAddonsInput;

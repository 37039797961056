import { makeStyles } from "@material-ui/core";

const useSocialProfilesStyles = makeStyles((theme) => ({
	container: {
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},

	profilePhotoContainer: {
		position: "relative",
	},

	expiredIcon: {
		position: 'absolute',
		top: 8,
		left: 8,
		width: 'fit-content'
	},

	networkIcon: {
		position: "absolute",
		bottom: 8,
		left: 8,
		width: "fit-content",
	},

	profilePhoto: {
		borderRadius: "50%",
		objectFit: "cover",
		width: 140,
		height: 140,
	},

	username: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		fontSize: 14,
		width: 140,
		textAlign: "center",
		marginTop: 8,
	},

	type: {
		fontWeight: 500,
		color: "#868C91",
		textTransform: "uppercase",
		letterSpacing: 0.5,
		fontSize: 14,
		marginTop: 4,
		textAlign: "center",
	},
}));

export default useSocialProfilesStyles;

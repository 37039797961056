import { ButtonHTMLAttributes } from "react";
import { Box, CircularProgress, makeStyles, useTheme } from "@material-ui/core";
import clsx from "clsx";

interface Props extends ButtonHTMLAttributes<any> {
	secondary?: boolean;
	selected?: boolean;
	lightBlue?: boolean;
	small?: boolean;
	loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
	button: {
		position: "relative",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		letterSpacing: 0.3,
		height: 36,
		width: 385,
		fontWeight: 500,
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 8,
		"&:focus": {
			outline: "none",
		},
		userSelect: "none",
		fontSize: (props: Props) => (props.small ? 11 : 12),
		transition: "0.3s",
		"&:hover": {
			background: (props: Props) => {
				if (props.disabled || props.loading) return "";
				return props.secondary || props.lightBlue ? "#AAB1B8 !important" : "#AD1D25 !important";
			},
			color: ({ selected }: Props) => (selected ? "white !important" : ""),
			opacity: "1 !important",
		},
	},

	loadingOverlay: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

export default function Button(props: Props) {
	const classes = useStyles(props);
	const theme = useTheme();
	const { secondary, className, selected, lightBlue, loading, ...filteredProps } = props;

	const isDisabled = props.disabled || loading;

	return (
		<button
			{...filteredProps}
			className={className ? clsx(classes.button, className) : classes.button}
			disabled={isDisabled}
			style={{
				backgroundColor:
					isDisabled || selected ? "#F7F7F7" : lightBlue ? "#B6D8F0" : secondary ? "#D9E3EB" : "",
				color: isDisabled
					? "#868C91"
					: selected
					? "#4B7FA3"
					: secondary || lightBlue
					? "#3E4C55"
					: "",
				fontFamily: secondary ? "Avenir" : "",
				cursor: isDisabled ? "default" : "pointer",
				border: isDisabled
					? `1px solid ${theme.palette.secondary.main}`
					: selected
					? "2px solid #D1DDE6"
					: "none",
				opacity: isDisabled || selected ? 0.6 : 1,
				pointerEvents: isDisabled ? "none" : "all",
				...props.style,
			}}
		>
			{loading && (
				<div className={classes.loadingOverlay}>
					<CircularProgress size={20} />
				</div>
			)}
			{props.children}
		</button>
	);
}

import { Dispatch } from "react";
import { v4 as uuidv4 } from "uuid";
import { StoreState } from "../../../../setup/store";
import { CreateTaskCommentFunc } from "types/ReduxActions";
import { TaskComment } from "types/Task";
import { SET_TASK_COMMENTS } from "../../../reducers/tasks";
import { setSnackbarState } from "../../snackBar";
import axios from "axios";
import config from "config";

export const createTaskComment: CreateTaskCommentFunc = (taskId, comment, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;
		const { currentTeamId } = getState().teams;
		const { taskComments, taskCommentsTotal } = getState().tasks;

		if (!userData) return;

		const placeholderComment: TaskComment = {
			_id: uuidv4(),
			updatedAt: "",
			media: comment.media,
			user: userData._id,
			createdAt: new Date().toISOString(),
			task: taskId,
			team: currentTeamId,
			content: comment.value,
			mentions: comment.mentions,
		};

		dispatch({
			type: SET_TASK_COMMENTS,
			payload: {
				taskComments: [placeholderComment, ...taskComments],
				taskCommentsTotal: taskCommentsTotal + 1,
			},
		});

		try {
			const result = await axios.post(
				`${config.apiUrl}/tasks/${taskId}/comments`,
				{
					content: placeholderComment.content,
					media: placeholderComment.media,
					mentions: placeholderComment.mentions,
				},
				{ params: { teamId: currentTeamId } }
			);

			if (result.data) {
				dispatch({
					type: SET_TASK_COMMENTS,
					payload: {
						taskComments: [
							result.data,
							...taskComments.filter((comment) => comment._id !== placeholderComment._id),
						],
					},
				});
			}
			cb && cb();
		} catch (e) {
			console.log(e.response);
			dispatch(setSnackbarState(true, "error", "SendCommentError"));
		}
	};
};

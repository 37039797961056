import { makeStyles } from "@material-ui/core";

interface Props {
	isNewBillingMethod?: boolean;
}

const useBillingMethodDialogStyles = makeStyles((theme) => ({
	paper: {
		maxWidth: 800,
		maxHeight: 1000,
		borderRadius: 12,
		backgroundColor: "white",
	},

	container: {
		width: 520,
		height: ({ isNewBillingMethod }: Props) => (isNewBillingMethod ? 740 : 440),
		backgroundColor: "white",
		padding: 42,
		position: "relative",
	},

	dialogTitle: {
		fontSize: 22,
		fontFamily: "Chau Philomene One",
		marginBottom: 32,
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		position: "absolute",
		bottom: 42,
		right: 42,
	},

	defaultPaymentMethodBox: {
		display: "flex",
		alignItems: "center",
		width: "fit-content",
		marginLeft: "auto",
		marginTop: 32,
	},

	formContainer: {
		maxHeight: 620,
		paddingRight: 8,
		paddingBottom: 8,
		overflowY: "auto",
		overflowX: "visible",
	},

	"@media (max-height: 800px)": {
		container: {
			maxHeight: ({ isNewBillingMethod }: Props) => (isNewBillingMethod ? "80vh" : ""),
		},

		formContainer: {
			// maxHeight: '40vh',
		},

		buttonsContainer: {
			position: 'static',
			width: 'fit-content',
			marginLeft: 'auto',
			display: 'flex',
			marginTop: 24,
			paddingBottom: 32,
		}
	},
}));

export default useBillingMethodDialogStyles;

import { ReduxAction } from "types/ReduxActions";
import Task, { TaskComment } from "types/Task";

export interface TasksReducerState {
	tasks: Task[];
	tasksQueryTotal: number;
	tasksSearchQueryTotal: number;

	activeTasks: Task[];
	currentUserTasks: Task[];
	tasksSearchResult: Task[];
	listTasksLoading: boolean;
	chatTasksLoading: boolean;
	tasksSearchLoading: boolean;
	productivity: Array<Task & { assignees: string[] }>;
	productivityLoading: boolean;

	tasksCount: number;
	totalCurrentUserTasks: number;

	submitTaskSuccess: boolean;
	submitTaskLoading: boolean;

	deleteLoading: boolean;

	submitTaskError: { [index: string]: any } | null;

	selectedTask: Task | null;
	taskComments: TaskComment[];
	taskCommentsTotal: number;
	commentsLoading: boolean;
}

let initialState: TasksReducerState = {
	tasks: [],
	tasksSearchResult: [],
	tasksSearchQueryTotal: 0,
	tasksQueryTotal: 0,

	activeTasks: [],
	currentUserTasks: [],
	listTasksLoading: false,
	tasksSearchLoading: false,
	chatTasksLoading: false,

	productivity: [],
	productivityLoading: false,

	tasksCount: 0,
	totalCurrentUserTasks: 0,
	deleteLoading: false,

	submitTaskSuccess: false,
	submitTaskLoading: false,
	submitTaskError: null,

	selectedTask: null,
	taskComments: [],
	taskCommentsTotal: 0,
	commentsLoading: false,
};

export const LIST_TASKS = "LIST_TASKS";
export const LIST_ACTIVE_TASKS = "LIST_ACTIVE_TASKS";
export const LIST_CURRENT_USER_TASKS = "LIST_CURRENT_USER_TASKS";
export const LIST_TASKS_LOADING = "LIST_TASKS_LOADING";

export const SET_PRODUCTIVITY_LOADING = "SET_PRODUCTIVITY_LOADING";
export const SET_PRODUCTIVITY = "SET_PRODUCTIVITY";

export const DELETE_TASK_LOADING = "DELETE_TASK_LOADING";
export const SET_TASKS_COUNT = "SET_TASKS_COUNT";

export const SUBMIT_TASK_ERROR = "SUBMIT_TASK_ERROR";
export const SUBMIT_TASK_SUCCESS = "SUBMIT_TASK_SUCCESS";
export const SUBMIT_TASK_LOADING = "SUBMIT_TASK_LOADING";

export const SET_TASK_COMMENTS = "SET_TASK_COMMENTS";
export const SET_TASK_COMMENTS_LOADING = "SET_TASK_COMMENTS_LOADING";
export const SET_CHAT_TASKS_LOADING = "SET_CHAT_TASKS_LOADING";

export const SET_SELECTED_TASK = "SET_SELECTED_TASK";

export const SET_TASKS_SEARCH_LOADING = "SET_TASKS_SEARCH_LOADING";
export const SET_TASKS_SEARCH_RESULT = "SET_TASKS_SEARCH_RESULT";

export default function tasksReducer(
	state: TasksReducerState = initialState,
	action: ReduxAction
): TasksReducerState {
	switch (action.type) {
		case SET_TASKS_SEARCH_LOADING:
			return {
				...state,
				tasksSearchLoading: action.payload,
			};
		case SET_TASKS_SEARCH_RESULT:
			return {
				...state,
				tasksSearchResult: action.payload.tasks,
				tasksSearchQueryTotal: action.payload.count || state.tasksSearchQueryTotal,
				tasksSearchLoading: false,
			};
		case SET_SELECTED_TASK:
			return {
				...state,
				selectedTask: action.payload,
			};
		case SET_PRODUCTIVITY:
			return {
				...state,
				productivity: action.payload,
				productivityLoading: false,
			};

		case SET_PRODUCTIVITY_LOADING:
			return {
				...state,
				productivityLoading: action.payload,
			};
		case SET_TASKS_COUNT:
			return {
				...state,
				tasksCount: action.payload,
			};
		case LIST_CURRENT_USER_TASKS:
			return {
				...state,
				...action.payload,
				chatTasksLoading: false,
				listTasksLoading: false,
			};
		case LIST_TASKS:
			return {
				...state,
				tasks: action.payload.tasks,
				tasksQueryTotal: action.payload.count || state.tasksQueryTotal,
				listTasksLoading: false,
			};
		case LIST_TASKS_LOADING:
			return {
				...state,
				listTasksLoading: action.payload,
			};
		case SUBMIT_TASK_SUCCESS:
			return {
				...state,
				submitTaskSuccess: action.payload,
				submitTaskLoading: false,
				submitTaskError: null,
			};

		case SUBMIT_TASK_ERROR:
			return {
				...state,
				submitTaskError: action.payload,
				submitTaskLoading: false,
				submitTaskSuccess: false,
			};

		case SUBMIT_TASK_LOADING:
			return {
				...state,
				submitTaskLoading: action.payload,
			};

		case DELETE_TASK_LOADING:
			return {
				...state,
				deleteLoading: action.payload,
			};
		case SET_TASK_COMMENTS:
			return {
				...state,
				commentsLoading: false,
				...action.payload,
			};

		case SET_TASK_COMMENTS_LOADING:
			return {
				...state,
				commentsLoading: action.payload,
			};
		case LIST_ACTIVE_TASKS:
			return {
				...state,
				listTasksLoading: false,
				chatTasksLoading: false,
				...action.payload,
			};
		case SET_CHAT_TASKS_LOADING:
			return {
				...state,
				chatTasksLoading: action.payload,
			};
		default:
			return state;
	}
}

import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Task from "types/Task";
import SocialProfileChip from "../../../Settings/SocialProfile/SocialProfileChip";
import useSummaryStyles from "../../styles/summary";

interface Props {
	selectedTask: Task;
}

const SummarySocialProfiles = (props: Props) => {
	const classes = useSummaryStyles({});
	const { t } = useTranslation("tasks");
	const { selectedTask } = props;
	const { socialProfiles } = selectedTask;

	return (
		<Box>
			<Typography className={classes.sectionLabel}>{t("socialProfiles")}:</Typography>

			{!Boolean(socialProfiles.length) && (
				<div className={classes.emptySectionMessage}>{t("noSocialProfiles")}</div>
			)}

			{socialProfiles.map((profile, index) => (
				<SocialProfileChip
					profile={profile}
					key={index}
					textStyle={{ width: 150 }}
					networkIconSize={18}
					style={{
						background: "transparent",
						width: 300,
						paddingLeft: 0,
						height: 25,
					}}
				/>
			))}
		</Box>
	);
};

export default SummarySocialProfiles;

import { makeStyles } from "@material-ui/core";

const useInteractionItemStyles = makeStyles((theme) => ({
	container: {
		border: "1px solid #E5E5E5",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: 10,
		borderRadius: 8,
		marginTop: 10,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	username: {
		color: theme.palette.text.primary,
		fontWeight: 500,
	},

	date: {
		color: theme.palette.text.primary,
		paddingLeft: 24,
		paddingRight: 24,
		fontSize: 12,
		width: "fit-content",
		marginLeft: "auto",
	},

	postContent: {
		fontSize: 12,
		color: theme.palette.text.primary,
	},

	content: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontSize: 12,
		color: theme.palette.text.primary,
	},

	expandIcon: {
		color: theme.palette.text.primary,
		cursor: "pointer",
	},

	collapseIconContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
	},

	postInfo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	postImage: {
		height: 35,
		width: 35,
		borderRadius: 8,
		marginRight: 12,
	},

	socialProfileImage: {
		borderRadius: "50%",
		marginRight: 6,
		marginLeft: 6,
		height: 23,
		width: 23,
	},

	interactionStatus: {
		textTransform: "uppercase",
		fontSize: 12,
		fontWeight: 500,
		border: "1px solid #3E4C55",
		textAlign: "center",
		height: 18,
		width: 60,
		paddingTop: 1,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		color: "#3E4C55",
	},
}));

export default useInteractionItemStyles;

import { ProfilePicturePlaceholder } from "assets";
import CheckBox from "components/Basic/Inputs/CheckBox";
import usePagesDialogStyles from "modules/Settings/styles/pages-selection-dialog";
import SocialProfile from "types/SocialProfile";

interface Props {
	profile: SocialProfile;
	isChecked: boolean;
	onClick: (socialId: string) => void;
}

const SubProfileItem: React.FC<Props> = (props) => {
	const classes = usePagesDialogStyles();

	const { profile, isChecked, onClick } = props;

	return (
		<div onClick={() => onClick(profile.socialId!)} className={classes.pageContainer}>
			<img src={profile.picture || ProfilePicturePlaceholder} className={classes.profileImage} />
			<div className={classes.username}>{profile.displayName}</div>
			<div className={classes.rightContainer}>
				<CheckBox checked={isChecked} />
			</div>
		</div>
	);
};

export default SubProfileItem;

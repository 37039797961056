import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "50%",
		marginRight: 16,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		marginBottom: 16,
		paddingLeft: 32,
		paddingRight: 23,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	socialProfileInfo: {
		display: "flex",
		flexDirection: "row",
		width: "fit-content",
		alignItems: "center",
	},

	socialIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 23,
		marginRight: 8,
	},

	profilePicture: {
		height: 23,
		marginRight: 8,
	},

	username: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		marginRight: 8,
	},

	indexIndicator: {
		width: 22,
		height: 22,
		color: "white",
		borderRadius: "50%",
		marginLeft: "auto",
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},

	dataBox: {
		height: 180,
		backgroundColor: "white",
		borderRadius: 10,
		width: "100%",
		padding: 16,
	},

	dataItemContainer: {
		width: "25%",
		textAlign: "center",
		flexDirection: "column",
		display: "flex",
	},
}));

export default useStyles;

import { ReduxAction } from "types/ReduxActions";

export interface SnackBarReducerState {
	open: boolean;
	severity: "success" | "warning" | "error";
	message: string;
	priority: number
}

let initialState: SnackBarReducerState = {
	open: false,
	severity: "success",
	message: "Testing Message",
	priority: 1
};

export const SET_SNACKBAR_STATE = "SET_SNACKBAR_STATE";

export default function snackbarReducer(
	state: SnackBarReducerState = initialState,
	action: ReduxAction
) {
	switch (action.type) {
		case SET_SNACKBAR_STATE:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
}

import { makeStyles } from "@material-ui/core";

const useFullScreenPreviewStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#3E4C55B3",
		display: "flex",
		alignItems: "flexStart",
		justifyContent: "flexStart",
	},

	paper: {
		maxWidth: "100vw",
		maxHeight: "100vh",
		width: "100vw",
		height: "100vh",
		margin: 0,
		boxShadow: "none",
		backgroundColor: "transparent",
		userSelect: "none",
	},

	header: {
		width: "100%",
		height: 70,
		color: "white",
		display: "flex",
		alignItems: "center",
		paddingLeft: 32,
		paddingRight: 32,
		backgroundColor: "#3E4C55",
	},

	authorName: {
		fontWeight: 600,
		fontSize: 16,
	},

	role: {
		marginLeft: 16,
		textTransform: "capitalize",
		fontWeight: 300,
	},

	image: {
		objectFit: "cover",
		width: "100%",
		height: "100%",
		borderRadius: 8,
		maxWidth: "80vw",
		maxHeight: "80vh",
	},

	actionsContainer: {
		position: "absolute",
		bottom: 32,
		left: 32,
	},
}));

export default useFullScreenPreviewStyles;

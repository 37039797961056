import { Box } from "@material-ui/core";
import { TeamMembersIllustration } from "assets";
import AddProfileIconSvg from "assets/SVG/AddProfileIconSvg";
import UpgradeIcon from "assets/SVG/UpgradeIcon";
import Button from "components/Basic/Button";
import SubscriptionDialog from "pages/Settings/Components/Workspaces/components/Payment/SubscriptionDialog";
import TeamMembersAddonsDialog from "pages/Settings/Components/Workspaces/components/Payment/TeamMembersAddonsDialog/TeamMembersAddonsDialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isFreeSubscription } from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import useTeamMembersLimitCardStyles from "./styles";

interface Props {
	teamUsersLimit: number;
}

export const TeamMembersLimitCard = (props: Props) => {
	const classes = useTeamMembersLimitCardStyles();
	const { t } = useTranslation("workspaces");
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const [subscriptionDialog, setSubscriptionDialog] = useState(false);
	const [teamMembersAddonsDialog, setTeamMembersAddonsDialog] = useState(false);
	const { teamUsersLimit } = props;

	return (
		<>
			<SubscriptionDialog open={subscriptionDialog} onClose={() => setSubscriptionDialog(false)} />
			<TeamMembersAddonsDialog
				open={teamMembersAddonsDialog}
				onClose={() => setTeamMembersAddonsDialog(false)}
			/>

			<Box className={classes.card}>
				<Box display="flex" alignItems="center">
					<img src={TeamMembersIllustration} className={classes.illustration} />

					<Box className={classes.cardText}>
						{teamUsersLimit ? t("teamMembersLimitReached") : t("noTeamMembersAllowed")}
					</Box>
				</Box>

				<Box className={classes.actionsContainer}>
					<Button
						onClick={() => setSubscriptionDialog(true)}
						small
						lightBlue
						style={{ marginRight: 16, width: 180 }}
					>
						<UpgradeIcon size={15} />
						<span style={{ marginLeft: 8 }}>{t("updateSubscriptionPlan")}</span>
					</Button>
					<Button
						onClick={() => setTeamMembersAddonsDialog(true)}
						disabled={!currentTeamSubscription || isFreeSubscription(currentTeamSubscription)}
						small
						lightBlue
						style={{ width: 180 }}
					>
						<AddProfileIconSvg size={15} />
						<span style={{ marginLeft: 8 }}>{t("addExtraTeamMembers")}</span>
					</Button>
				</Box>
			</Box>
		</>
	);
};

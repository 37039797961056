import React from "react";

export default function FilterIconSvg({ size }: { size?: number }) {
	return (
		<svg
			width={size || 18}
			height={size || 18}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.38781 0.816406H15.6119C16.5669 0.816406 17.3332 1.60948 17.3332 2.57975V3.87276C17.3332 4.34395 17.1503 4.79553 16.8241 5.12544L11.1851 10.8612L11.1856 15.2043C11.1856 15.402 11.086 15.5841 10.9246 15.691L10.8518 15.732L7.14923 17.4802C6.76362 17.6623 6.32024 17.38 6.32024 16.9525L6.31999 10.848L1.12552 5.10191C0.862699 4.81178 0.704436 4.44097 0.672511 4.04984L0.666504 3.90233V2.57975C0.666504 1.60948 1.43279 0.816406 2.38781 0.816406ZM15.6119 1.98287H2.38784C2.08327 1.98287 1.82933 2.24569 1.82933 2.57974V3.90232C1.82933 4.05838 1.88591 4.20683 1.98649 4.31786L7.33233 10.2309C7.42933 10.3382 7.48306 10.4779 7.48306 10.6227L7.48281 16.0325L10.0223 14.8342L10.0228 10.6227C10.0228 10.5001 10.0613 10.3815 10.1316 10.283L10.1902 10.2133L15.9974 4.30524C16.1073 4.19403 16.1704 4.03823 16.1704 3.87275V2.57974C16.1704 2.24569 15.9165 1.98287 15.6119 1.98287Z"
				fill="#3E4C55"
			/>
		</svg>
	);
}

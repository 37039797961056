import { CircularProgress } from "@material-ui/core";
import { trim } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router";
import { getNotificationContent, handleNotificationClick } from "redux/actions/notifications";
import Notification from "types/Notification";
import useNotificationsStyles from "../styles";

interface Props {
	notification: Notification;
	fullDate?: boolean;
}

const NotificationItem = (props: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const history = useHistory();
	const { notification, fullDate } = props;
	const classes = useNotificationsStyles();

	const handleClick = async () => {
		if (loading) return;

		setLoading(true);
		await handleNotificationClick(notification, history);
		setLoading(false);
	};

	return (
		<div onClick={handleClick} className={classes.notificationItem}>
			<span
				dangerouslySetInnerHTML={{
					__html: trim(getNotificationContent(notification)),
				}}
			/>
			{loading && <CircularProgress style={{ marginLeft: 16 }} size={16} />}
			<div className={classes.notificationTime}>
				{moment(notification.createdAt).format(fullDate ? "MMM DD, h:mm" : "h:mm")}
				<span style={{ fontSize: 11, marginLeft: 4 }}>
					{moment(notification.createdAt).format("A")}
				</span>
			</div>
		</div>
	);
};

export default NotificationItem;

import axios from "axios";
import config from "config";
import { Dispatch } from "react";
import { SET_CURRENT_TEAM_PAYMENT_METHODS } from "redux/reducers/subscription";
import store, { StoreState } from "setup/store";
import {
	CancelSetupIntentFunc,
	DeletePaymentMethodFunc,
	GetSetupIntentFunc,
	ListPaymentMethodsFunc,
	SetPrimaryPaymentMethodFunc,
	SubmitSetupIntentFunc,
	UpdatePaymentMethodAddressFunc,
} from "types/ReduxActions";
import { setSnackbarState } from "../../snackBar";

export type SetupIntent = {
	id: string;
	clientSecret: string;
};

/**
 * Retrieves a new Stripe SetupIntent from the server
 * SetupIntent is required to be able to store a payment method for later use
 */
export const getSetupIntent: GetSetupIntentFunc = async (teamId) => {
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		const result = await axios.get(`${config.apiUrl}/subscriptions/payment/setup-intents/create`, {
			params: { teamId },
		});
		return result.data;
	} catch (e) {
		console.log(e);
		dispatch(setSnackbarState(true, "error", "GetSetupIntentError"));
		return null;
	}
};

/** Called to disregard SetupIntents that are no longer needed (in our case once the CardDialog is closed) */
export const cancelSetupIntent: CancelSetupIntentFunc = async (id, teamId) => {
	try {
		await axios.get(`${config.apiUrl}/subscriptions/payment/setup-intents/${id}/cancel`, {
			params: { teamId },
		});
	} catch (e) {
		console.log(e);
	}
};

/** Submits the SetupIntent ID to Chargebee to create and store the customer's payment method */
export const submitSetupIntent: SubmitSetupIntentFunc = async (id, teamId, setAsDefaultMethod) => {
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		await axios.get(
			`${config.apiUrl}/subscriptions/payment/setup-intents/${id}/submit`,
			{ params: { teamId, setAsDefaultMethod } }
		);
		return true;
	} catch (e) {
		dispatch(setSnackbarState(true, "error", "SubmitIntentError"));
		return false;
	}
};

export const listPaymentMethods: ListPaymentMethodsFunc = () => {
	return async (dispatch: Dispatch<any>) => {
		const { currentTeamId } = store.getState().teams;
		if (!currentTeamId) {
			return;
		}
		try {
			const result = await axios.get(`${config.apiUrl}/subscriptions/payment/sources`, {
				params: { teamId: currentTeamId },
			});

			dispatch({
				type: SET_CURRENT_TEAM_PAYMENT_METHODS,
				payload: [...result.data.paymentSources],
			});
		} catch (e) {
			console.log(e.response);
			dispatch(setSnackbarState(true, "error", "ListPaymentMethodsError"));
			return null;
		}
	};
};

export const deletePaymentMethod: DeletePaymentMethodFunc = (methodId, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { paymentMethods } = getState().subscription;
		const { currentTeamId } = getState().teams;
		if (!currentTeamId) {
			return;
		}
		try {
			await axios.delete(`${config.apiUrl}/subscriptions/payment/sources/${methodId}`, {
				params: { teamId: currentTeamId },
			});

			dispatch({
				type: SET_CURRENT_TEAM_PAYMENT_METHODS,
				payload: [...paymentMethods.filter((method) => method.id !== methodId)],
			});
			dispatch(setSnackbarState(true, "success", "DeletePaymentMethodSuccess"));
			cb?.();
		} catch (e) {
			dispatch(setSnackbarState(true, "error", e.data.error));
			cb?.();
		}
	};
};

export const setPrimaryPaymentMethod: SetPrimaryPaymentMethodFunc = (methodId, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { paymentMethods } = getState().subscription;
		const { currentTeamId } = getState().teams;

		if (!currentTeamId) {
			return;
		}
		try {
			await axios.get(`${config.apiUrl}/subscriptions/payment/sources/${methodId}/set-primary`, {
				params: { teamId: currentTeamId },
			});

			paymentMethods.forEach((method) => {
				if (method.id === methodId) {
					method.primary = true;
				} else {
					method.primary = false;
				}
			});

			dispatch({
				type: SET_CURRENT_TEAM_PAYMENT_METHODS,
				payload: [...paymentMethods],
			});
			cb?.();
		} catch (e) {
			console.log(e.response.data);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
			cb?.();
		}
	};
};

export const updatePaymentMethodAddress: UpdatePaymentMethodAddressFunc = async (
	methodId,
	teamId,
	payload
) => {
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		await axios.put(`${config.apiUrl}/subscriptions/payment/sources/${methodId}`, payload, {
			params: { teamId },
		});

		return true;
	} catch (e) {
		console.log(e.response.data);
		dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		return false;
	}
};

import { makeStyles } from "@material-ui/core";

const useTeamMembersLimitCardStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: theme.custom.palette.secondaryCardBackground,
		borderRadius: theme.shape.borderRadius,
		padding: 16,
		paddingLeft: 24,
		paddingRight: 24,
		width: "100%",
        marginTop: 32
	},

	illustration: {
		width: 70,
	},

	cardText: {
		marginLeft: 24,
		fontSize: 12,
        fontWeight: 500,
		color: theme.palette.text.primary,
	},

	actionsContainer: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
        marginTop: 24
	},
}));

export default useTeamMembersLimitCardStyles;

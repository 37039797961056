import InteractionsSection from "modules/Reply/InteractionsSection/InteractionsSection";
import { DateRange } from "pages/Reply";

interface Props {
	dateRange: DateRange;
}

const Replies: React.FC<Props> = ({ dateRange }) => {
	return (
		<div style={{ marginTop: 18 }}>
			<InteractionsSection
				tabs={["REPLIED COMMENTS", "SCHEDULED REPLIES"]}
				// items={replyComments}
				sectionType="commentReplies"
				dateRange={dateRange}
			/>

			<InteractionsSection
				tabs={["REPLIED MESSAGES", "SCHEDULED REPLIES"]}
				// items={replyMessages}
				sectionType="messageReplies"
				dateRange={dateRange}
			/>
		</div>
	);
};

export default Replies;

import axios from "axios";
import { Dispatch } from "react";
import store from "../../../../setup/store";
import {
	SET_CURRENT_TEAM_SUBSCRIPTION,
	SET_PLANS,
	SET_PLANS_LOADING,
} from "../../../reducers/subscription";
import config from "config";
import { setSnackbarState } from "../../snackBar";
import { GetTeamSubscriptionFunc, UpdateTeamSubscriptionFunc } from "types/ReduxActions";
import { getHeaders } from "helpers/utils";
import { SubscriptionInfo } from "types/SubscriptionPlan";

export type PaymentPeriod = "monthly" | "yearly";

export type SubscriptionPayload = {
	planId: string;
	paymentMethodId: string;
	paymentPeriod: PaymentPeriod;
	userAddons?: number;
	applyChangesImmediately?: boolean;
};

// if a team doesn't have a subscription it will automatically have a planInfo of the free plan
// and wouldn't have an id but just a "planInfo" object

// TODO: If team users have been restricted (add a "teamUsersRestricted" flag to response, refetch the team info)
export const getTeamSubscription: GetTeamSubscriptionFunc = async (teamId) => {
	const { currentTeamId } = store.getState().teams;
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		const result = await axios.get(`${config.apiUrl}/subscriptions/current`, {
			params: { teamId: teamId || currentTeamId },
			headers: getHeaders(),
		});

		if (!teamId || teamId === currentTeamId) {
			dispatch({
				type: SET_CURRENT_TEAM_SUBSCRIPTION,
				payload: result.data,
			});
		}

		return result.data;
	} catch (e) {
		console.log(e);
	}
};

export const listSubscriptionPlans = () => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_PLANS_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(`${config.apiUrl}/subscriptions/plans`, {});
			const { addons, pricingPlans } = result.data;

			dispatch({
				type: SET_PLANS,
				payload: { plans: pricingPlans, addons: addons },
			});
		} catch (e) {
			console.log(e);
			dispatch({
				type: SET_PLANS_LOADING,
				payload: false,
			});
			dispatch(setSnackbarState(true, "error", "ListPlansError"));
		}
	};
};

export const updateTeamSubscription: UpdateTeamSubscriptionFunc = async (teamId, payload) => {
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		await axios.post(`${config.apiUrl}/subscriptions/update`, payload, {
			params: { teamId },
		});

		dispatch(setSnackbarState(true, "success", "SubscriptionSubmitSuccess"));
		getTeamSubscription();
		return true;
	} catch (e) {
		const { error } = e.response.data;
		if (error === "PaymentMethodMissing") {
			dispatch(setSnackbarState(true, "error", "PaymentMethodMissing"));
		} else {
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		}
		return false;
	}
};

export const cancelTeamSubscription = async (teamId: string) => {
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		await axios.get(`${config.apiUrl}/subscriptions/cancel`, {
			params: { teamId },
		});
		dispatch(setSnackbarState(true, "success", "SubscriptionCancelSuccess"));
		getTeamSubscription();
	} catch (e) {
		console.log(e);
		dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		return false;
	}
};

export const removeScheduledCancellation = async (teamId: string) => {
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		await axios.get(`${config.apiUrl}/subscriptions/remove-scheduled-cancellation`, {
			params: { teamId },
		});
		dispatch(setSnackbarState(true, "success", "RemoveScheduledCancellationSuccess"));
		getTeamSubscription();
	} catch (e) {
		console.log(e.response.data);
		dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		return false;
	}
};

export const getScheduledChanges = (teamId: string): Promise<SubscriptionInfo | null> => {
	return new Promise(async (resolve) => {
		const dispatch = store.dispatch as Dispatch<any>;
		try {
			const result = await axios.get(`${config.apiUrl}/subscriptions/scheduled-changes`, {
				params: { teamId },
			});
			resolve(result.data);
		} catch (e) {
			console.log(e.response.data);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
			resolve(null);
		}
	});
};

export const removeScheduledChanges = (teamId: string): Promise<boolean> => {
	return new Promise(async (resolve) => {
		const dispatch = store.dispatch as Dispatch<any>;
		const { currentTeamSubscription } = store.getState().subscription;

		try {
			await axios.get(`${config.apiUrl}/subscriptions/remove-scheduled-changes`, {
				params: { teamId },
			});

			dispatch({
				type: SET_CURRENT_TEAM_SUBSCRIPTION,
				payload: { ...(currentTeamSubscription || {}), hasScheduledChanges: false },
			});
			resolve(false);
		} catch (e) {
			console.log(e.response.data);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
			resolve(false);
		}
	});
};

export const reactivateSubscription = (teamId: string): Promise<boolean> => {
	return new Promise(async (resolve) => {
		const dispatch = store.dispatch as Dispatch<any>;

		try {
			await axios.get(`${config.apiUrl}/subscriptions/reactivate`, {
				params: { teamId },
			});

			dispatch(setSnackbarState(true, "success", "SubscriptionReactivateSuccess"));

			await getTeamSubscription();

			resolve(true);
		} catch (e) {
			console.log(e.response.data);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
			resolve(false);
		}
	});
};

export const isFreeSubscription = (subscription: SubscriptionInfo) => {
	return (
		!subscription.id || (Boolean(subscription.id) && subscription?.planInfo.id.includes("Free"))
	);
};

/**
 * An enterprise subscription is one that the user cannot modify manually and
 * has to contact us to make any changes to it
 */
export const isEnterpriseSubscription = (subscription: SubscriptionInfo) => {
	console.log(subscription)
	return subscription?.planInfo.id.includes('Enterprise')
};

export const getPlanUserAddonsPrice = (planId: string, period: "monthly" | "yearly") => {
	const { addons } = store.getState().subscription;

	const price = addons.find((addon) =>
		planId.endsWith("-Pro") ? addon.id === "Additional-User-Pro" : addon.id === "Additional-User"
	)?.price || { monthly: 10, yearly: 10 };

	return price?.[period];
};

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#FFF8E0",
		width: 380,
		minHeight: 270,
		padding: 16,
		paddingLeft: 24,
		paddingRight: 24,
		boxShadow: "2px 2px 0px #FFE39D",
		position: "relative",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	title: {
		color: theme.palette.text.primary,
		fontSize: 18,
		fontFamily: "Avenir",
	},

	reminderContainer: {
		marginLeft: "auto",
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		cursor: "pointer",
	},

	dateText: {
		color: "#3E4C55",
		marginLeft: 8,
		fontFamily: "Avenir",
		fontSize: 12,
	},

	textArea: {
		height: 160,
		resize: "none",
		width: "100%",
		marginTop: 16,
		border: 0,
		backgroundColor: "#FFF8E0",
		"&:focus": {
			outline: "none",
		},
		fontFamily: "Avenir",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		marginLeft: "auto",
		width: "fit-content",
	},

	error: {
		fontSize: 12,
		color: theme.palette.error.main,
		paddingTop: 8,
		paddingBottom: 8,
	},
}));

export default useStyles;

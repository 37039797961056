import { makeStyles, Typography } from "@material-ui/core";
import Post, { PostPayload } from "types/Post";
import OptionsVertical from "assets/options-vertical.png";
import TagIcon from "assets/instagram-tag-icon.png";
import LikeIcon from "assets/insta-like.png";
import ShareIcon from "assets/insta-share.png";
import SaveIcon from "assets/insta-save.png";
import CommentIcon from "assets/insta-comment.png";
import LikesIcon from "@material-ui/icons/Favorite";
import SocialProfile from "types/SocialProfile";

import clsx from "clsx";
import SocialPreviewMedia from "./SocialPreviewMedia";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";
import { NewPlaceholderImage } from "assets";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	container: {
		width: 'fit-content',
		minWidth: 400,
		boxShadow: "2px 2px 12px rgba(62, 76, 85, 0.1)",
		fontFamily: "sans-serif",
		paddingBottom: 12,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: 16,
	},

	headerTextContainer: {
		display: "flex",
		flexDirection: "column",
	},

	usernameContainer: {
		display: "flex",
		flexDirection: "row",
	},

	username: {
		fontSize: 16,
		fontFamily: "inherit",
		color: "#2A2A2A",
		fontWeight: 600,
	},

	locationText: {
		color: "#7F7F7F",
		fontSize: 14,
		marginTop: 2,
	},

	optionsContainer: {
		width: "fit-content",
		whiteSpace: "pre-line",
		marginLeft: "auto",
		paddingRight: 8,
	},

	imageContainer: {
		minHeight: 200,
		backgroundColor: "#f0f0f0",
		width: "100%",
		position: "relative",
	},

	imagePlaceholderContainer: {
		display: "flex",
		minHeight: 200,
		marginTop: 32,
		marginBottom: 32,
		alignItems: "center",
		justifyContent: "center",
	},

	imagePlaceholderCard: {
		border: "2px solid #D9E3EB",
		padding: "32px 42px 32px 42px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 16,
	},

	imagePlaceholder: {
		width: 100,
		height: 100,
		objectFit: "cover",
	},

	imagePlaceholderText: {
		fontWeight: 500,
		fontSize: 14,
		color: theme.palette.text.secondary,
		marginTop: 32,
	},

	image: {
		width: "100%",
		objectFit: "cover",
	},

	tagIcon: {
		position: "absolute",
		bottom: 24,
		left: 24,
		height: 25,
	},

	actionsContainer: {
		display: "flex",
		flexDirection: "row",
		padding: 16,
		paddingBottom: 8,
	},

	action: {
		height: 20,
		marginLeft: 8,
		marginRight: 8,
	},

	likesContainer: {
		display: "flex",
		flexDirection: "row",
		color: "#2A2A2A",
		fontSize: 14,
		paddingLeft: 18,
		alignItems: "center",
		fontWeight: 600,
	},

	content: {
		display: "flex",
		flexDirection: "row",
		paddingBottom: 8,
		color: "#2A2A2A",
		paddingLeft: 18,
		marginTop: -6,
		flexWrap: "wrap",
	},
}));

interface Props {
	postPayload?: PostPayload | Post;
	scheduled?: boolean;
	width?: number;
	className?: string;
}

const InstagramPreview = (props: Props) => {
	const classes = useStyles();
	const { postPayload, width, className } = props;
	const { t } = useTranslation("posts");

	const profile = postPayload?.socialProfiles?.filter(
		(profile: SocialProfile) => profile.network === "instagram"
	)[0];

	if (!postPayload) {
		return <></>;
	}

	return (
		<div style={{ width: width || "" }} className={clsx(classes.container, className)}>
			<div className={classes.header}>
				{profile && (
					<SocialProfilePicture
						profile={profile}
						style={{ width: 40, height: 40, marginRight: 8 }}
					/>
				)}

				<div className={classes.headerTextContainer}>
					<div className={classes.usernameContainer}>
						<span className={classes.username}>{profile?.displayName}</span>
						<span
							style={{
								fontSize: 12,
								marginLeft: 6,
								marginRight: 6,
								marginTop: 2,
							}}
						>
							•
						</span>
						<span style={{ color: "#6bbfff" }} className={classes.username}>
							Follow
						</span>
					</div>

					<span className={classes.locationText}></span>
				</div>

				<div className={classes.optionsContainer}>
					<img src={OptionsVertical} style={{ width: 5 }} />
				</div>
			</div>

			{Boolean(postPayload.media?.length) ? (
				<div className={classes.imageContainer}>
					<SocialPreviewMedia className={classes.image} post={postPayload} />

					<img src={TagIcon} className={classes.tagIcon} />
				</div>
			) : (
				<div className={classes.imagePlaceholderContainer}>
					<div className={classes.imagePlaceholderCard}>
						<img src={NewPlaceholderImage} className={classes.imagePlaceholder} />

						<Typography className={classes.imagePlaceholderText}>{t("noPhotoSelected")}</Typography>
					</div>
				</div>
			)}

			<div className={classes.actionsContainer}>
				<img src={LikeIcon} className={classes.action} style={{ marginLeft: 4 }} />
				<img src={CommentIcon} className={classes.action} />
				<img src={ShareIcon} className={classes.action} />

				<div style={{ width: "fit-content", marginLeft: "auto" }}>
					<img src={SaveIcon} className={classes.action} />
				</div>
			</div>

			<div className={classes.likesContainer}>
				<LikesIcon style={{ fontSize: 16, marginRight: 8 }} />
				948 likes
			</div>

			<div className={classes.content}>
				<div style={{ fontWeight: 600, marginRight: 8, marginTop: 8, height: 10 }}>
					{profile?.displayName}
				</div>
				<div style={{ paddingTop: 8, marginBottom: -4 }}>{postPayload?.content as string}</div>
			</div>
		</div>
	);
};

export default InstagramPreview;

import { makeStyles } from "@material-ui/core";

const useAuthStyles = makeStyles((theme) => ({
	pageContainer: {
		display: "flex",
	},

	authTabsContainer: {
		width: "100%",
		borderBottom: "2px solid #D9E3EB",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	authTab: {
		fontFamily: "Chau Philomene One",
		fontSize: 14,
		paddingBottom: 6,
		letterSpacing: 0.4,
		marginRight: 16,
		color: "#868C90",
		cursor: "pointer",
		transition: "0.3s",
		marginBottom: -2,
		borderBottom: "2px solid #D9E3EB",
		userSelect: "none",
	},

	leftSection: {
		backgroundColor: "white",
		width: "50%",
		minHeight: "98.2vh",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},

	rightSection: {
		backgroundColor: "#F7F7F7",
		width: "50%",
		flexDirection: "column",
		alignItems: "center",
		paddingBottom: 100,
		position: "relative",
	},

	backText: {
		color: "#868C91",
		fontSize: 16,
		position: "absolute",
		top: 24,
		left: 24,
		cursor: "pointer",
		userSelect: "none",
	},

	emailConfirmation: {
		textAlign: "center",
		fontWeight: 500,
		fontSize: 22,
		letterSpacing: 0.5,
		paddingBottom: 0,
		paddingTop: 52,
		color: theme.palette.primary.main,
	},

	resendText: {
		fontSize: 14,
		textAlign: "center",
		marginTop: 32,
		color: theme.palette.text.primary,
		cursor: "pointer",
		textDecoration: "underline",
	},

	descriptionText: {
		fontSize: 14,
		padding: 16,
		maxWidth: 300,
		color: "#3E4C55",
		lineHeight: 1.5,
	},

	message: {
		color: theme.palette.primary.main,
		fontSize: 12,
		marginTop: 12,
		textAlign: "center",
	},

	addProfilePhoto: {
		color: theme.palette.text.primary,
		marginTop: 24,
		fontSize: 18,
		textAlign: "center",
	},

	profileImage: {
		height: 220,
		marginTop: 32,
		borderRadius: 50000,
	},

	profileImageContainer: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "fit-content",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		paddingTop: 100,
	},

	groupImage: { height: 600 },

	formContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		flexDirection: "column",
		display: "flex",
		marginTop: 24,
	},

	inputHeader: {
		color: theme.palette.text.primary,
		paddingBottom: 8,
		fontSize: 12,
		letterSpacing: 0.5,
	},

	inputContainer: {
		marginTop: 18,
		display: "flex",
		flexDirection: "column",
	},

	buttonContainer: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "fit-content",
		paddingTop: 32,
	},

	noAccountMessage: {
		marginTop: 16,
		fontSize: 14,
		fontFamily: "Avenir",
		fontWeight: 400,
		color: theme.palette.text.primary,
		textAlign: "center",
		display: "flex",
		flexDirection: "row",
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
	},

	joinSpan: {
		color: theme.palette.primary.main,
		textDecoration: "underline",
		fontWeight: 600,
		cursor: "pointer",
	},

	checkBoxLabel: {
		fontSize: 12,
		marginTop: 2,
		color: theme.palette.text.primary,
	},

	checkBox: {
		marginRight: 8,
	},

	emailImage: {
		width: 150,
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: 32,
	},

	emailConfirmationText: {
		color: theme.palette.text.primary,
		letterSpacing: 0.5,
		fontSize: 16,
		marginTop: 24,
		textAlign: "center",
		lineHeight: 1.6,
	},

	finalStepContainer: {
		background: "#F7F7F7",
		minHeight: "98.2vh",
		overflowY: "scroll",
	},

	innerContainer: {
		maxWidth: 1100,
		width: "100%",
		marginLeft: "auto",
		marginRight: "auto",
		backgroundColor: "white",
		minHeight: "98.2vh",
		display: "flex",
		flexDirection: "column",
	},

	welcomePageSection: {
		marginTop: 62,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		zIndex: 5,
	},

	herosImageContainer: {
		width: "100%",
		position: "fixed",
		zIndex: 0,
		top: 200,
	},

	herosImage: {
		height: 460,
		marginLeft: 24,
	},

	photoEditorContainer: {
		flexDirection: "column",
		display: "flex",
		alignItems: "center",
	},

	photoEditorTitle: {
		color: theme.palette.text.primary,
		fontSize: 28,
		letterSpacing: 0.5,
		marginTop: 42,
		marginBottom: 32,
	},

	photoEditorCancel: {
		position: "fixed",
		padding: 24,
		top: 0,
		right: 0,
		cursor: "pointer",
		borderRadius: 50,
		transition: "0.3",
		"&:hover": {
			backgroundColor: "#ededed",
		},
	},

	editorContainer: {
		height: 400,
		overflow: "hidden",
	},

	passwordValidation: {
		textAlign: "right",
		fontSize: 12,
		marginTop: 8,
		color: "#5B666E",
		overflow: "hidden",
		transition: "0.3s",
	},

	divider: {
		width: "32.5%",
		height: 3,
		background: "#D9E3EB",
	},

	altSignUpText: {
		fontSize: 14,
		fontFamily: "Chau Philomene One",
		color: "#868C90",
		marginLeft: 16,
		marginRight: 16,
	},

	socialButton: {
		background: "#D9E3EB",
		borderRadius: "50%",
		width: 40,
		height: 40,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		transition: "0.3s",
		"&:hover": {
			background: "#c1cbd4",
		},
		marginTop: 16,
		marginLeft: 8,
		marginRight: 8,
	},

	error: {
		color: theme.palette.error.main,
		fontSize: 12,
		marginTop: 8,
	},

	forgotPasswordLink: {
		color: "#868C91",
		fontSize: 12,
		width: "fit-content",
		marginLeft: "auto",
		marginTop: 12,
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},

	resizer: {
		position: "absolute",
		bottom: -18,
		right: -16,
		cursor: "nwse-resize",
	},
}));

export default useAuthStyles;

import { Dialog } from "@material-ui/core";
import { useEffect, useState } from "react";
import Network, { networkIcons } from "types/Network";
import Post from "types/Post";
import Button from "components/Basic/Button";
import FacebookPreview from "../../SocialPreviews/FacebookPreview";
import InstagramPreview from "../../SocialPreviews/InstagramPreview";
import TwitterPreview from "../../SocialPreviews/TwitterPreview";
import useStyles from "./styles";
import LinkedInPreview from "../../SocialPreviews/LinkedInPreview";
import moment from "moment";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { PostDeleteConfirm } from "assets";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { deletePost, updatePost } from "redux/actions/posts/update";
import { StoreState } from "../../../setup/store";
import { listPosts } from "redux/actions/posts/get";

interface Props {
	post?: Post;
	open: boolean;
	disableActions?: boolean;
	onClose: () => void;
}

const PostPreviewDialog = (props: Props) => {
	const classes = useStyles();
	const [selectedNetwork, setSelectedNetwork] = useState<Network>();
	const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const { createPostLoading } = useSelector((state: StoreState) => state.posts);
	const history = useHistory();
	const dispatch = useDispatch();
	const { post, open, disableActions, onClose } = props;

	const handleSaveAsDraft = () => {
		if (!post) return;
		dispatch(
			updatePost(post._id || "", null, { draft: true }, () => {
				setDeleteConfirmation(false);
				onClose();
				if (history.location.pathname === "/posting") {
					dispatch(listPosts({ scheduled: true }));
				}
			})
		);
	};

	const handleEditClick = () => {
		onClose();
		history.push(`/posts/edit/${post?._id}`);
	};

	const handlePostDelete = () => {
		if (!post?._id) return;

		setDeleteLoading(true);
		dispatch(
			deletePost(post?._id, (success) => {
				if (success) {
					onClose();
				}

				setDeleteLoading(false);
			})
		);
	};

	useEffect(() => {
		if (!post || !post.socialProfiles) return;
		setSelectedNetwork(post.socialProfiles[0].network);
	}, [post]);

	return (
		<Dialog onClose={onClose} open={open}>
			<ConfirmationDialog
				open={deleteConfirmation}
				onClose={() => setDeleteConfirmation(false)}
				message="Are you sure you want to remove this post from schedule?"
				confirmButton="Delete Post"
				closeButton="Cancel"
				image={PostDeleteConfirm}
				middleButton={"Save Draft"}
				onMiddleButtonClick={handleSaveAsDraft}
				onAction={handlePostDelete}
				actionLoading={deleteLoading}
				middleActionLoading={createPostLoading}
			/>

			<div className={classes.container}>
				<div className={classes.header}>
					{selectedNetwork && (
						<div className={classes.networkContainer}>
							<img src={networkIcons[selectedNetwork]} className={classes.selectedNetworkIcon} />
							<span className={classes.selectedNetworkTitle}>{selectedNetwork}</span>
						</div>
					)}

					<div className={classes.otherNetworks}>
						{post?.networks?.map((network, index) => {
							if (network !== selectedNetwork)
								return (
									<img
										key={index}
										onClick={() => setSelectedNetwork(network)}
										src={networkIcons[network]}
										className={classes.otherNetworkIcon}
									/>
								);
						})}
					</div>
				</div>

				<div className={classes.previewContainer}>
					{selectedNetwork === "facebook" && <FacebookPreview postPayload={post} width={500} />}

					{selectedNetwork === "instagram" && (
						<InstagramPreview postPayload={post} scheduled={true} width={500} />
					)}

					{selectedNetwork === "twitter" && (
						<TwitterPreview postPayload={post} scheduled={true} width={500} />
					)}

					{selectedNetwork === "linkedin" && (
						<LinkedInPreview postPayload={post} scheduled={true} width={500} />
					)}
				</div>

				<div className={classes.buttonsContainer}>
					<Button
						disabled={disableActions || Boolean(post?.publishedAt)}
						onClick={() => setDeleteConfirmation(true)}
						secondary
						style={{ width: 110 }}
					>
						Delete Post
					</Button>

					<div className={classes.rightContainer}>
						<div className={classes.scheduleContainer}>
							{post?.publishedAt ? "Published" : "Scheduled"}{" "}
							{moment(post?.publishedAt || post?.scheduledAt).format("DD MMM'YY h:mm a")}
						</div>
						<Button
							disabled={disableActions || Boolean(post?.publishedAt)}
							onClick={handleEditClick}
							style={{ width: 100, marginLeft: 10 }}
						>
							Edit
						</Button>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default PostPreviewDialog;

import { Box, Dialog } from "@material-ui/core";
import {
	isFreeSubscription,
	listSubscriptionPlans,
	SubscriptionPayload,
} from "redux/actions/team/subscriptions/plans";
import useSubscriptionInfoDialogStyles from "./styles";
import { CSSProperties, MouseEvent, useMemo } from "react";
import TitleText from "components/Basic/Text/TitleText";
import { useTranslation } from "react-i18next";
import SubscriptionInfoChangesCard from "./components/ChangesCard";
import SubscriptionPriceSummary from "../SubscriptionPriceSummary";
import { StoreState } from "setup/store";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Basic/Button";
import { useEffect } from "react";
import LoadingSpinner from "components/Basic/LoadingSpinner";
import moment from "moment";
import HintText from "components/Basic/Text/HintText";

export type DialogActionButton = {
	text: string;
	secondary?: boolean;
	style?: CSSProperties;
	onClick: (e: MouseEvent<HTMLElement>) => void;
};
export interface SubscriptionInfoDialogState {
	open: boolean;
	scheduledChanges?: boolean;
	title?: string;
	actionButtons: DialogActionButton[];
	subscriptionPayload: SubscriptionPayload;
}

interface Props extends SubscriptionInfoDialogState {
	onClose: () => void;
}

export const SubscriptionInfoDialog = (props: Props) => {
	const classes = useSubscriptionInfoDialogStyles();
	const { open, title, subscriptionPayload, scheduledChanges, actionButtons, onClose } = props;
	const { t } = useTranslation("settings");
	const { plans, currentTeamSubscription, plansLoading } = useSelector(
		(state: StoreState) => state.subscription
	);
	const dispatch = useDispatch();
	const selectedPlan = useMemo(
		() => plans.find((plan) => plan.id === subscriptionPayload.planId),
		[plans]
	);
	const currentTermEndDate = moment
		.unix(currentTeamSubscription?.currentTermEnd || 0)
		.format("DD MMM yyyy");

	const handleActionButtonClick = (e: MouseEvent<HTMLElement>, button: DialogActionButton) => {
		button.onClick(e);
	};

	useEffect(() => {
		if (!open) return;
		if (plans.length === 0) {
			dispatch(listSubscriptionPlans());
		}
	}, [open]);

	console.log(currentTeamSubscription);

	return (
		<Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
			<Box className={classes.subscriptionInfoContainer}>
				<TitleText style={{ marginBottom: 42, paddingLeft: 24 }}>
					{title ||
						(scheduledChanges ? t("subscriptionPlanScheduledChanges") : t("yourSubscriptionPlan"))}
				</TitleText>

				{plansLoading && <LoadingSpinner />}

				<SubscriptionInfoChangesCard
					scheduledChanges={scheduledChanges}
					subscriptionPayload={subscriptionPayload}
				/>

				{selectedPlan && (
					<Box className={classes.priceSummaryContainer}>
						<SubscriptionPriceSummary
							plan={selectedPlan}
							period={subscriptionPayload.paymentPeriod}
							userAddons={subscriptionPayload.userAddons || 0}
						/>
					</Box>
				)}

				{currentTeamSubscription?.hasScheduledChanges ||
					(currentTeamSubscription && !isFreeSubscription(currentTeamSubscription) && (
						<Box className={classes.hintTextContainer}>
							<HintText isWarningIconVisible>
								{t("updateSubscriptionHintText")}: {currentTermEndDate}
							</HintText>
							{currentTeamSubscription?.hasScheduledChanges && (
								<HintText isWarningIconVisible style={{ marginTop: 12 }}>
									{t("alreadyHasScheduledChanges")}
								</HintText>
							)}
						</Box>
					))}

				<Box className={classes.actionButtonsContainer}>
					{actionButtons.map((button, index) => (
						<Button
							onClick={(e) => handleActionButtonClick(e, button)}
							key={index}
							style={{ width: 150, ...button.style }}
							secondary={button.secondary}
						>
							{button.text}
						</Button>
					))}
				</Box>
			</Box>
		</Dialog>
	);
};

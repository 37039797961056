import { ReduxAction } from "types/ReduxActions";
import SocialProfile from "types/SocialProfile";

export interface SocialProfilesReducerState {
	profilesLoading: boolean;
	createLoading: boolean;
	permissionsLoading: boolean;

	profiles: SocialProfile[];

	createProfileResponse: { [index: string]: any } | null;
	createProfileError: { [index: string]: any } | null;

	permissions: { permission: string; status: "granted" | "declined" }[];
}

let initialState = {
	profilesLoading: false,
	createLoading: false,
	permissionsLoading: false,

	profiles: [],

	createProfileResponse: null,
	createProfileError: null,

	permissions: [],
};

export const LIST_SOCIAL_PROFILES = "LIST_SOCIAL_PROFILES";
export const SET_PROFILES_LOADING = "SET_PROFILES_LOADING";

export const CREATE_PROFILE_LOADING = "CREATE_PROFILE_LOADING";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_ERROR = "CREATE_PROFILE_ERROR";
export const LIST_PERMISSIONS_LOADING = "LIST_PERMISSIONS_LOADING";
export const LIST_PERMISSIONS = "LIST_PERMISSIONS";

export default function socialProfilesReducer(
	state: SocialProfilesReducerState = initialState,
	action: ReduxAction
) {
	switch (action.type) {
		case LIST_PERMISSIONS_LOADING: {
			return {
				...state,
				permissionsLoading: action.payload,
			};
		}

		case LIST_PERMISSIONS: {
			return {
				...state,
				permissionsLoading: false,
				permissions: action.payload,
			};
		}

		case LIST_SOCIAL_PROFILES: {
			return { ...state, profilesLoading: false, profiles: action.payload };
		}

		case SET_PROFILES_LOADING: {
			return {
				...state,
				profilesLoading: action.payload,
			};
		}

		case CREATE_PROFILE_LOADING: {
			return {
				...state,
				createLoading: action.payload,
			};
		}

		case CREATE_PROFILE_ERROR: {
			return {
				...state,
				createLoading: false,
				createProfileError: action.payload,
			};
		}

		case CREATE_PROFILE_SUCCESS: {
			return {
				...state,
				createLoading: false,
				createProfileSuccess: action.payload,
			};
		}

		default:
			return state;
	}
}

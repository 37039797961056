import { makeStyles } from "@material-ui/core";

const useAudienceAnalyticsStyles = makeStyles((theme) => ({
	card: {
		height: 350,
		width: "50%",
		backgroundColor: "white",
		borderRadius: 10,
		padding: 28,
		display: "flex",
		flexDirection: "row",
	},

	cardTitle: {
		fontSize: 16,
		color: theme.palette.text.primary,
		paddingBottom: 16,
	},

	label: {
		color: theme.palette.text.primary,
	},

	hashtagText: {
		fontSize: 12,
		color: "#4B7FA3",
	},

	hashtagSection: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
	},
}));

export default useAudienceAnalyticsStyles;

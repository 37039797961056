import { makeStyles } from "@material-ui/core";

const useWorkspacesStyles = makeStyles((theme) => ({
	rightContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},

	label: {
		color: theme.palette.text.primary,
		fontSize: 14,
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
	},

	teamUsersContainer: {
		minHeight: 100,
		marginTop: 12,
	},

	teamInfoContainer: {
		marginBottom: 16,
	},

	inputTitle: {
		fontSize: 14,
		marginBottom: 8,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		letterSpacing: 0.4,
	},
}));

export default useWorkspacesStyles;

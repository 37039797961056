import useStyles from "./styles/personal-info-styles";
import usePageStyles from "../styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../setup/store";
import { uploadProfilePicture } from "redux/actions/user/user-data";
import ImagePicker from "components/Media/ImagePicker/ImagePicker";
import { useTranslation } from "react-i18next";

const ProfilePhoto = () => {
	const { userData } = useSelector((state: StoreState) => state.user);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const pageClasses = usePageStyles();
	const { t } = useTranslation("settings");

	const handleSubmit = (file: File | undefined) => {
		if (!file) return;
		setLoading(true);
		dispatch(
			uploadProfilePicture(file, () => {
				setLoading(false);
			})
		);
	};

	return (
		<div className={classes.profilePicSection}>
			<div className={pageClasses.cardTitle}>{t("userPicture")}</div>
			<div style={{ marginTop: 62, marginLeft: 62 }}>
				<ImagePicker
					imageUrl={userData?.profilePicture}
					loading={loading}
					onSubmit={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default ProfilePhoto;

import axios from "axios";
import { Dispatch } from "react";
import config from "config";

import { StoreState } from "../../../setup/store";
import { ListNotesFunc } from "types/ReduxActions";
import { LIST_NOTES_LOADING, LIST_NOTES_SUCCESS, SET_NOTES_COUNT } from "../../reducers/notes";
import { setSnackbarState } from "../snackBar";

export type NotesFilters = {
	from?: number;
	pageLimit?: number;
};

/**
 * @param count if provided, only the total count of team notes will be returned
 */
export const listNotes: ListNotesFunc = (count, filters) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { notes } = getState().notes;

		if (!filters?.from) {
			dispatch({
				type: LIST_NOTES_LOADING,
				payload: true,
			});
		}

		let params: { [index: string]: any } = {
			...filters,
		};

		if (count) {
			params.count = count;
		}

		try {
			const result = await axios.get(`${config.apiUrl}/notes?teamId=${currentTeamId}`, {
				params,
			});

			if (count) {
				dispatch({
					type: SET_NOTES_COUNT,
					payload: result.data.count,
				});
			} else {
				dispatch({
					type: LIST_NOTES_SUCCESS,
					payload: {
						notes: filters?.from ? [...notes, ...result.data.notes] : result.data.notes,
						notesQueryTotal: result.data.count,
					},
				});
			}
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "Error fetching notes..."));
			dispatch({
				type: LIST_NOTES_LOADING,
				payload: false,
			});
		}
	};
};

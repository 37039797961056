import { Box, CircularProgress } from "@material-ui/core";
import SearchResultPost from "modules/Posts/SearchResultPost";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import usePageStyles from "../page-styles";
import NoResultsMessage from "./NoResultsMessage";

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
}

const PostsSearch = (props: Props) => {
	const { t } = useTranslation();
	const classes = usePageStyles();
	const { postsSearchResult, postsSearchLoading } = useSelector(
		(state: StoreState) => state.posts
	);
	const { searchValue, setSearchValue } = props;

	return (
		<Box>
			{postsSearchLoading && (
				<Box className={classes.loadingContainer}>
					<CircularProgress color="primary" />
				</Box>
			)}

			{!postsSearchLoading && (
				<>
					{postsSearchResult.length > 0 ? (
						<Box className={classes.sectionTitle}>
							{t("scheduledPosts")} ({postsSearchResult.length}{" "}
							{postsSearchResult.length !== 1 ? "results" : "result"})
						</Box>
					) : (
						<NoResultsMessage searchValue={searchValue} setSearchValue={setSearchValue} />
					)}

					{postsSearchResult.map((post) => (
						<SearchResultPost searchValue={searchValue} post={post} />
					))}
				</>
			)}
		</Box>
	);
};

export default PostsSearch;

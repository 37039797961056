import { makeStyles } from "@material-ui/core";

const useTeamMembersAddonsDialogStyles = makeStyles(() => ({
	sectionTitle: {
		fontWeight: 700,
	},

	summaryContainer: {
		fontSize: 14,
		padding: 12,
		paddingTop: 24,
		width: "50%",
		marginLeft: "auto",
	}
}));

export default useTeamMembersAddonsDialogStyles;

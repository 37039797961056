import React from "react";
import { Dialog } from "@material-ui/core";
import Button from "../../Basic/Button";
import CheckBox from "../../Basic/Inputs/CheckBox";
import useStyles from "./styles";

interface Props {
	open: boolean;
	handleClose: () => void;
}

const days = [
	{ number: 1, name: "Sunday" },
	{ number: 2, name: "Monday" },
	{ number: 3, name: "Tuesday" },
	{ number: 4, name: "Wednesday" },
	{ number: 5, name: "Thursday" },
	{ number: 6, name: "Friday" },
	{ number: 7, name: "Saturday" },
];

const Day = ({ day }: { day: { number: number; name: string } }) => {
	const classes = useStyles();

	return (
		<div className={classes.dayContainer}>
			<span className={classes.dayTitle}>{day.name}</span>
			<div className={classes.checkBoxContainer}>
				<CheckBox />
			</div>
		</div>
	);
};

export default function SelectDaysDialog(props: Props) {
	const classes = useStyles();
	const { open, handleClose } = props;

	const handleCancelClick = () => {
		handleClose();
	};

	const handleSubmitClick = () => {
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<div className={classes.container}>
				<span className={classes.title}>Repeat</span>
				<div className={classes.headButtons}>
					<Button secondary={true} style={{ width: 100, height: 45, marginRight: 16 }}>
						Weekdays
					</Button>
					<Button secondary={true} style={{ width: 100, height: 45 }}>
						Weekends
					</Button>
				</div>

				<div className={classes.days}>
					{days.map((day, index) => (
						<Day key={index} day={day} />
					))}
				</div>

				<div className={classes.finalButtons}>
					<Button
						onClick={handleCancelClick}
						secondary={true}
						style={{ width: 200, height: 45, marginBottom: 8 }}
					>
						Don't Repeat
					</Button>
					<Button onClick={handleSubmitClick} style={{ width: 200, height: 45 }}>
						Submit
					</Button>
				</div>
			</div>
		</Dialog>
	);
}

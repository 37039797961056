import { useState, ChangeEvent, useEffect, MouseEvent } from "react";
import CalendarEvent from "types/CalendarEvent";
import useStyles from "../styles/dialog-styles";
import { CircularProgress, Dialog } from "@material-ui/core";
import BellIcon from "assets/Bell.png";
import Input from "components/Basic/Inputs/Input";
import Button from "components/Basic/Button";
import Network, { networkIcons, networks, selectedNetworkIcons } from "types/Network";
import ColorMark from "assets/SVG/ColorMark";
import ScheduleSelector from "../../../components/Dialogs/DateTimePicker";
import SelectDaysDialog from "../../../components/Dialogs/DaysSelector";
import DateInput from "components/Basic/Inputs/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { submitCalendarEvent } from "redux/actions/calendar-events/submit";
import { CREATE_EVENT_SUCCESS, DELETE_EVENT_SUCCESS } from "redux/reducers/calendar-events";
import { deleteCalendarEvent } from "redux/actions/calendar-events/delete";
import { formatDate } from "helpers/dateAndTime";
import { useTranslation } from "react-i18next";

interface Props {
	open: boolean;
	handleClose: () => void;
	selectedEvent?: CalendarEvent;
}

const colors = ["#E42631", "#F2994A", "#F2C94C", "#27AE60", "#2D9CDB", "#BB6BD9"];

export default function CalendarEventDialog(props: Props) {
	const classes = useStyles();
	const {
		createEventErrors: errors,
		createEventSuccess: success,
		createEventLoading: loading,
		deleteEventLoading: deleteLoading,
		deleteEventSuccess: deleteSuccess,
	} = useSelector((state: StoreState) => state.calendarEvents);
	const dispatch = useDispatch();
	const [updatedEvent, setUpdatedEvent] = useState<Partial<CalendarEvent>>({
		networks: [],
	});
	const [reminderMenu, setReminderMenu] = useState<HTMLElement | null>(null);
	const [selectDaysDialog, setSelectDaysDialog] = useState<boolean>(false);
	const { t } = useTranslation("calendar");
	const { open, handleClose, selectedEvent } = props;

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		let updates: any = {};
		updates[name] = value;
		setUpdatedEvent({ ...updatedEvent, ...updates });
	};

	const handleReminderClick = (e: MouseEvent<HTMLElement>) => {
		setReminderMenu(e.currentTarget);
	};

	const handleDeleteClick = () => {
		if (!selectedEvent?._id) return;
		dispatch(deleteCalendarEvent(selectedEvent._id));
	};

	const handleNetworkClick = (network: Network) => {
		if (!updatedEvent) return;
		const { networks } = updatedEvent;
		if (networks?.includes(network)) {
			networks?.splice(networks.indexOf(network), 1);
		} else {
			networks?.push(network);
		}

		setUpdatedEvent({ ...updatedEvent });
	};

	const handleColorClick = (color: string) => {
		setUpdatedEvent({ ...updatedEvent, color });
	};

	const handleReminderChange = (date: Date | undefined) => {
		setUpdatedEvent({
			...updatedEvent,
			reminder: date ? date.toISOString() : undefined,
		});
	};

	const handleCancelClick = () => {
		setUpdatedEvent({});
		handleClose();
	};

	const handleSaveClick = () => {
		if (selectedEvent) {
			dispatch(submitCalendarEvent(updatedEvent, selectedEvent._id));
		} else {
			dispatch(submitCalendarEvent(updatedEvent));
		}
	};

	useEffect(() => {
		if (selectedEvent) {
			setUpdatedEvent({ ...selectedEvent });
		} else setUpdatedEvent({ networks: [] });
	}, [selectedEvent, open]);

	useEffect(() => {
		if (success) {
			dispatch({
				type: CREATE_EVENT_SUCCESS,
				payload: false,
			});
			setUpdatedEvent({ networks: [] });
			handleClose();
		}
	}, [success, errors]);

	useEffect(() => {
		if (deleteSuccess) {
			dispatch({
				type: DELETE_EVENT_SUCCESS,
				payload: false,
			});
			handleClose();
			setUpdatedEvent({ networks: [] });
		}
	}, [deleteSuccess]);

	return (
		<Dialog classes={{ paper: classes.paper }} open={open} onClose={handleClose}>
			<ScheduleSelector
				anchorEl={reminderMenu}
				onSubmit={handleReminderChange}
				handleClose={() => setReminderMenu(null)}
				defaultValue={updatedEvent.reminder ? new Date(updatedEvent.reminder) : new Date()}
				reminder={true}
			/>

			<SelectDaysDialog open={selectDaysDialog} handleClose={() => setSelectDaysDialog(false)} />

			<div className={classes.dialogContainer}>
				<div className={classes.header}>
					<span className={classes.dialogTitle}>
						{!selectedEvent && t("new")} {t("calendarEvent")}
					</span>
					<div className={classes.reminder} onClick={handleReminderClick}>
						<img src={BellIcon} height={18} style={{ marginTop: -4 }} />
						<span className={classes.reminderText}>
							{updatedEvent.reminder ? formatDate(updatedEvent.reminder) : t("addReminder")}
						</span>
					</div>
				</div>

				<div className={classes.form}>
					<div className={classes.inputContainer}>
						<span className={classes.inputTitle}>{t("eventTitle")}</span>
						<Input
							style={{ width: "100%", marginTop: 4, fontSize: 12 }}
							name="title"
							error={errors?.title}
							value={updatedEvent.title || ""}
							onChange={handleInputChange}
						/>
					</div>

					<div className={classes.inputContainer}>
						<span className={classes.inputTitle}>{t("eventDescription")}</span>
						<Input
							style={{ width: "100%", marginTop: 4, fontSize: 12 }}
							rows={5}
							multiline
							name="description"
							error={errors?.description}
							value={updatedEvent.description || ""}
							onChange={handleInputChange}
						/>
					</div>

					<div className={classes.inputContainer} style={{ marginTop: 20 }}>
						<span className={classes.inputTitle}>{t("scheduleFor")}</span>
						<div className={classes.flexContainer}>
							<DateInput
								style={{ marginRight: 16 }}
								value={updatedEvent.scheduledAt}
								error={errors?.scheduledAt}
								onChange={(date) =>
									setUpdatedEvent({
										...updatedEvent,
										scheduledAt: date.toString(),
									})
								}
							/>
							{/* <Button
                                secondary={true}
                                onClick={() => setSelectDaysDialog(true)}
                                style={{ width: 150 }}>
                                Make Repeatable
                            </Button> */}
						</div>
					</div>

					<div className={classes.inputContainer} style={{ marginTop: 32 }}>
						<span className={classes.inputTitle}>{t("selectNetwork")}</span>
						<div className={classes.networksContainer}>
							{networks.map((network, index) => (
								<div
									key={index}
									className={classes.iconContainer}
									onClick={() => handleNetworkClick(network)}
								>
									<img
										height={network === "linkedin" ? 33 : 28}
										src={
											updatedEvent?.networks?.includes(network)
												? selectedNetworkIcons[network]
												: networkIcons[network]
										}
									/>
								</div>
							))}
						</div>
					</div>

					{errors?.networks && <div className={classes.error}>{errors.networks}</div>}

					<div className={classes.inputContainer} style={{ marginTop: 16 }}>
						<span className={classes.inputTitle}>Add Color Mark</span>
						<div className={classes.networksContainer}>
							{colors.map((color, index) => (
								<div
									key={index}
									className={classes.iconContainer}
									onClick={() => handleColorClick(color)}
								>
									<ColorMark color={color} height={35} selected={updatedEvent.color === color} />
								</div>
							))}
						</div>
					</div>

					<div className={classes.bottomButtons}>
						{selectedEvent && (
							<Button
								disabled={deleteLoading || loading}
								onClick={handleDeleteClick}
								secondary
								style={{ width: 120 }}
							>
								{deleteLoading ? <CircularProgress size={20} /> : "Delete Event"}
							</Button>
						)}
						<div className={classes.rightButtons}>
							<Button
								disabled={loading || deleteLoading}
								onClick={handleCancelClick}
								style={{ width: 120, marginRight: 8 }}
								secondary
							>
								Cancel
							</Button>
							<Button
								disabled={loading || deleteLoading}
								onClick={handleSaveClick}
								style={{ width: 120 }}
							>
								{loading ? <CircularProgress size={20} /> : "Save"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

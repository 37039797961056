import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { barData, genderPieData } from "../../mock/analyticsPlaceholderData";
import useAudienceAnalyticsStyles from "./styles/audience-analytics";

const AudienceAnalytics = () => {
	const classes = useAudienceAnalyticsStyles();
	return (
		<>
			<div className={classes.card} style={{ marginRight: 8 }}>
				<div style={{ width: "50%" }}>
					<div className={classes.cardTitle}>Audience Gender Statistics</div>
					<div style={{ width: "90%", height: 210 }}>
						<ResponsivePie
							data={genderPieData}
							margin={{ top: 10, bottom: 15, left: -20 }}
							innerRadius={0.75}
							colors={["#2D9CDB", "#F04755", "#F2C94C"]}
							borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
							enableRadialLabels={false}
							enableSliceLabels={false}
						/>
					</div>
					<div style={{ display: "flex", flexDirection: "row", width: "100%F" }}>
						<div className={classes.label} style={{ width: "33.3%" }}>
							Women: <br />
							<strong>46.6%</strong> {/* TODO: Replace placeholder */}
						</div>
						<div className={classes.label} style={{ width: "33.3%" }}>
							Men: <br />
							<strong>42.2%</strong> {/* TODO: Replace placeholder */}
						</div>
						<div className={classes.label} style={{ width: "33.3%" }}>
							Other: <br />
							<strong>11.5%</strong> {/* TODO: Replace placeholder */}
						</div>
					</div>
				</div>
				<div style={{ width: "50%", paddingLeft: 32 }}>
					<div className={classes.cardTitle}>Most Popular Themes</div>

					<div className={classes.hashtagSection} style={{ marginTop: 16 }}>
						<div className={classes.label} style={{ width: 100 }}>
							<strong>For Women:</strong>
						</div>
						<div style={{ flexDirection: "column", display: "flex" }}>
							{/* TODO: Replace placeholders */}
							<span className={classes.hashtagText}>#happywinter</span>
							<span className={classes.hashtagText}>#goodmood</span>
							<span className={classes.hashtagText}>#itsmylife</span>
							<span className={classes.hashtagText}>#environment</span>
						</div>
					</div>

					<div className={classes.hashtagSection} style={{ marginTop: 16 }}>
						<div className={classes.label} style={{ width: 100 }}>
							<strong>For Men:</strong>
						</div>
						<div style={{ flexDirection: "column", display: "flex" }}>
							{/* TODO: Replace placeholders */}
							<span className={classes.hashtagText}>#polytics</span>
							<span className={classes.hashtagText}>#hightechnology</span>
							<span className={classes.hashtagText}>#technochallenge</span>
							<span className={classes.hashtagText}>#environment</span>
						</div>
					</div>

					<div className={classes.hashtagSection} style={{ marginTop: 16 }}>
						<div className={classes.label} style={{ width: 100 }}>
							<strong>For Others:</strong>
						</div>
						<div style={{ flexDirection: "column", display: "flex" }}>
							{/* TODO: Replace placeholders */}
							<span className={classes.hashtagText}>#happywinter</span>
							<span className={classes.hashtagText}>#goodmood</span>
							<span className={classes.hashtagText}>#itsmylife</span>
							<span className={classes.hashtagText}>#environment</span>
						</div>
					</div>
				</div>
			</div>

			<div className={classes.card} style={{ marginLeft: 8 }}>
				<div style={{ width: "50%" }}>
					<div className={classes.cardTitle}>Audience Age Statistics</div>
					<div style={{ width: "100%", height: 250 }}>
						<ResponsiveBar
							data={barData}
							keys={["number"]}
							indexBy="age"
							margin={{ top: 50, right: 0, bottom: 50, left: 30 }}
							padding={0.4}
							groupMode="grouped"
							valueScale={{ type: "linear" }}
							indexScale={{ type: "band", round: true }}
							colors={["#F04755", "#F2C94C"]}
							gridYValues={5}
							defs={[
								{
									id: "dots",
									type: "patternDots",
									background: "inherit",
									color: "#38bcb2",
									size: 4,
									padding: 1,
									stagger: true,
								},
								{
									id: "lines",
									type: "patternLines",
									background: "inherit",
									color: "#eed312",
									rotation: -45,
									lineWidth: 6,
									spacing: 10,
								},
							]}
							borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
							axisTop={null}
							axisRight={null}
							axisBottom={{
								tickSize: 0,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: "middle",
								legendOffset: 32,
							}}
							axisLeft={{
								tickSize: 5,
								tickValues: 5,
								tickPadding: 5,
								tickRotation: 0,
								legendPosition: "middle",
								legendOffset: -40,
							}}
							enableLabel={false}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
							legends={[]}
							animate={true}
							motionStiffness={90}
							motionDamping={15}
						/>
					</div>
				</div>
				<div style={{ width: "50%", paddingLeft: 32 }}>
					<div className={classes.cardTitle}>Most Popular Themes</div>
					<div className={classes.hashtagSection} style={{ marginTop: 16 }}>
						<div className={classes.label} style={{ width: 100 }}>
							<strong>For 20-30:</strong>
						</div>
						<div style={{ flexDirection: "column", display: "flex" }}>
							{/* TODO: Replace placeholders */}
							<span className={classes.hashtagText}>#happywinter</span>
							<span className={classes.hashtagText}>#goodmood</span>
							<span className={classes.hashtagText}>#itsmylife</span>
							<span className={classes.hashtagText}>#environment</span>
						</div>
					</div>

					<div className={classes.hashtagSection} style={{ marginTop: 16 }}>
						<div className={classes.label} style={{ width: 100 }}>
							<strong>For 10-20 years:</strong>
						</div>
						<div style={{ flexDirection: "column", display: "flex" }}>
							{/* TODO: Replace placeholders */}
							<span className={classes.hashtagText}>#polytics</span>
							<span className={classes.hashtagText}>#hightechnology</span>
							<span className={classes.hashtagText}>#technochallenge</span>
							<span className={classes.hashtagText}>#environment</span>
						</div>
					</div>

					<div className={classes.hashtagSection} style={{ marginTop: 16 }}>
						<div className={classes.label} style={{ width: 100 }}>
							<strong>For 40-50 Years:</strong>
						</div>
						<div style={{ flexDirection: "column", display: "flex" }}>
							{/* TODO: Replace placeholders */}
							<span className={classes.hashtagText}>#happywinter</span>
							<span className={classes.hashtagText}>#goodmood</span>
							<span className={classes.hashtagText}>#itsmylife</span>
							<span className={classes.hashtagText}>#environment</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AudienceAnalytics;

import Note from "types/Note";
import { ReduxAction } from "types/ReduxActions";

export interface NotesReducerState {
	createNoteErrors: { [index: string]: string } | null;
	createNoteSuccess: boolean;
	deleteNoteSuccess: boolean;

	createNoteLoading: boolean;
	deleteNoteLoading: boolean;
	notesLoading: boolean;

	notes: Note[];
	notesCount: number;
	notesQueryTotal: number;
}

const initialState: NotesReducerState = {
	createNoteErrors: null,
	createNoteSuccess: false,
	deleteNoteSuccess: false,

	createNoteLoading: false,
	deleteNoteLoading: false,
	notesLoading: false,

	notes: [],
	notesCount: 0,
	notesQueryTotal: 0,
};

export const CREATE_NOTE_LOADING = "CREATE_NOTE_LOADING";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_ERROR = "CREATE_NOTE_ERROR";

export const DELETE_NOTE_LOADING = "DELETE_NOTE_LOADING";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";

export const LIST_NOTES_LOADING = "LIST_NOTES_LOADING";
export const LIST_NOTES_SUCCESS = "LIST_NOTES_SUCCESS";

export const SET_NOTES_COUNT = "SET_NOTES_COUNT";

export default function notesReducer(
	state: NotesReducerState = initialState,
	action: ReduxAction
): NotesReducerState {
	switch (action.type) {
		case SET_NOTES_COUNT: {
			return {
				...state,
				notesCount: action.payload,
			};
		}
		case CREATE_NOTE_LOADING: {
			return {
				...state,
				createNoteLoading: true,
			};
		}
		case CREATE_NOTE_ERROR: {
			return {
				...state,
				createNoteLoading: false,
				createNoteErrors: action.payload,
			};
		}
		case CREATE_NOTE_SUCCESS: {
			return {
				...state,
				createNoteLoading: false,
				createNoteSuccess: action.payload,
				createNoteErrors: null,
			};
		}

		case LIST_NOTES_LOADING: {
			return {
				...state,
				notesLoading: true,
			};
		}

		case LIST_NOTES_SUCCESS: {
			return {
				...state,
				notesLoading: false,
				...action.payload,
			};
		}

		case DELETE_NOTE_SUCCESS: {
			return {
				...state,
				deleteNoteLoading: false,
				deleteNoteSuccess: action.payload,
			};
		}
		case DELETE_NOTE_LOADING: {
			return {
				...state,
				deleteNoteLoading: action.payload,
			};
		}

		default:
			return state;
	}
}

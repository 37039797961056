import { Box, useTheme } from "@material-ui/core";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Basic/Button";
import DateInput from "components/Basic/Inputs/DateInput";
import Task from "types/Task";
import useTaskDialogStyles from "../../styles/task-dialog";

interface Props {
	selectedTask?: Task;
	inputValues: Partial<Task>;
	setInputValues: Dispatch<SetStateAction<Partial<Task>>>;
}

const EditModeHeader = (props: Props) => {
	const { selectedTask, inputValues, setInputValues } = props;
	const theme = useTheme();
	const classes = useTaskDialogStyles();
	const { t } = useTranslation("tasks");

	return (
		<Box display="flex" alignItems="center">
			{/* Title */}
			<div className={classes.title}>{selectedTask ? t("editTask") : t("newTask")}</div>

			{/* Deadline/Urgency */}
			<div className={classes.rightContainer}>
				<DateInput
					iconColor="#868C91"
					label={`${t("deadline")}: ${
						inputValues.deadline ? moment(inputValues.deadline).format("DD MMM hh:mm A") : ""
					}`}
					onChange={(date) =>
						setInputValues({
							...inputValues,
							deadline: date,
						})
					}
					style={{
						borderTop: "none",
						borderLeft: "none",
						borderRight: "none",
						borderBottom: "1px solid #868C91",
						borderRadius: 0,
						height: 30,
						paddingLeft: 0,
						paddingRight: 0,
					}}
				/>

				<Button
					secondary
					style={{
						background: inputValues.urgent ? "white" : "",
						color: inputValues.urgent ? theme.palette.primary.main : "",
						border: inputValues.urgent ? `1px solid ${theme.palette.primary.main}` : "none",
					}}
					onClick={() => setInputValues({ ...inputValues, urgent: !inputValues.urgent })}
					className={classes.urgentButton}
				>
					{inputValues.urgent ? t("urgent") : t("setAsUrgent")}
				</Button>
			</div>
		</Box>
	);
};

export default EditModeHeader;

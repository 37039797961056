import { MentionsInput as ReactMentionsInput, Mention, OnChangeHandlerFunc } from "react-mentions";
import { useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import { CSSProperties, KeyboardEvent } from "react";

interface Props {
	value: string;
	controlStyle?: CSSProperties;
	inputStyle?: CSSProperties;
	placeholder?: string;
	onKeyDown?: (e: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>) => void;
	onChange: OnChangeHandlerFunc;
}

const MentionsInput: React.FC<Props> = (props) => {
	const { value, controlStyle, inputStyle, placeholder, onChange, onKeyDown } = props;
	const theme = useTheme();
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId, teams } = useSelector((state: StoreState) => state.teams);
	const currentTeam = teams.find((team) => team._id === currentTeamId);

	return (
		<ReactMentionsInput
			value={value}
			style={{
				highlighter: { boxSizing: "border-box", overflow: "hidden", height: 70 },
				"&multiLine": {
					control: {
						fontFamily: "monospace",
						minHeight: 155,
						...controlStyle,
					},
					highlighter: {
						padding: 9,
						fontFamily: "Avenir",
						border: "1px solid transparent",
					},
					input: {
						padding: 9,
						color: theme.palette.text.primary,
						border: `1px solid ${theme.palette.secondary.main}`,
						borderRadius: 8,
						fontFamily: "Avenir",
						fontSize: 12,
						...inputStyle,
					},
				},
				suggestions: {
					list: {
						backgroundColor: "white",
						border: "1px solid rgba(0,0,0,0.15)",
						fontSize: 14,
					},
					item: {
						padding: "5px 15px",
						borderBottom: "1px solid rgba(0,0,0,0.15)",
						"&focused": {
							backgroundColor: theme.palette.secondary.main,
						},
					},
				},
			}}
			onChange={onChange}
			onKeyDown={onKeyDown}
			placeholder={placeholder}
		>
			<Mention
				trigger="@"
				style={{
					backgroundColor: theme.palette.secondary.main,
					fontFamily: "Avenir",
				}}
				data={
					currentTeam?.users
						.filter(({ user }) => user?._id !== userData?._id)
						.map(({ user }) => ({
							id: "user/" + user?._id!,
							display: `${user?.firstName} ${user?.lastName}`,
						})) || []
				}
			/>
		</ReactMentionsInput>
	);
};

export default MentionsInput;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		margin: "16px auto 16px auto",
		alignItems: "center",
	},

	inputBox: {
		height: 30,
		width: 30,
		border: "1px solid #D5E1F5",
		background: "#F7F7F7",
		fontSize: 14,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	verticalContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	arrow: {
		height: 8,
		color: "#868C91",
		marginTop: 8,
		cursor: "pointer",
		userSelect: "none",
		"&:hover": {
			transform: "scale(1.2)",
		},
	},

	input: {
		color: theme.palette.text.primary,
		fontSize: 14,
		alignSelf: "center",
		textAlign: "center",
		background: "transparent",
		border: "none",
		width: 40,
		fontFamily: "Avenir",
		"&:focus": {
			outline: "none",
		},
	},

	timeSeparator: {
		marginLeft: 8,
		marginRight: 8,
		fontSize: 16,
		userSelect: "none",
	},
}));

export default useStyles;

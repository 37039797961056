import { InfoDialogImage } from "assets";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "./ConfirmationDialog";

interface Props {
	open: boolean;
	message?: string;
	hideTitle?: boolean;
	onClose: () => void;
}

const ComingSoonDialog = (props: Props) => {
	const { t } = useTranslation();
	const { open, message, hideTitle, onClose } = props;

	return (
		<ConfirmationDialog
			open={open}
			title={hideTitle ? '' : t("comingSoonTitle")}
			message={message || t("comingSoonMessage")}
			image={InfoDialogImage}
			onClose={onClose}
		/>
	);
};

export default ComingSoonDialog;

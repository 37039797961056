import { Box, useTheme } from "@material-ui/core";
import { MasterCardIcon, VisaIcon } from "assets";
import AmericanExpressIconSvg from "assets/SVG/AmericanExpressIconSvg";
import EditIconSvg from "assets/SVG/EditIconSvg";
import Radio from "components/Basic/Inputs/Radio";
import { CSSProperties, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentMethod } from "types/Billing";
import BillingMethodOptionsPopover from "./BillingMethodOptionsPopover";
import useBillingMethodStyles from "./styles";

interface Props {
	method: PaymentMethod;
	hideTitle?: boolean;
	inputContainerStyle?: CSSProperties;
	style?: CSSProperties;
	selected?: boolean;
	onSelect?: (method: PaymentMethod) => void;
	onUpdateBillingAddressClick?: (method: PaymentMethod) => void;
}

const brandImages: { [index: string]: string | ReactNode } = {
	visa: VisaIcon,
	mastercard: MasterCardIcon,
	american_express: <AmericanExpressIconSvg />,
};

const HiddenCardValue = () => {
	const classes = useBillingMethodStyles({});

	return (
		<div className={classes.hiddenValuesContainer}>
			<div className={classes.hiddenValueCircle}></div>
			<div className={classes.hiddenValueCircle}></div>
			<div className={classes.hiddenValueCircle}></div>
			<div className={classes.hiddenValueCircle}></div>
		</div>
	);
};

export const BillingMethodItem = (props: Props) => {
	const [optionsPopover, setOptionsPopover] = useState<HTMLElement | null>(null);
	const {
		method,
		hideTitle,
		inputContainerStyle,
		style,
		selected,
		onSelect,
		onUpdateBillingAddressClick,
	} = props;
	const classes = useBillingMethodStyles({ selectable: Boolean(onSelect), selected });
	const theme = useTheme();
	const { t } = useTranslation("settings");

	return (
		<div style={style} onClick={() => onSelect && onSelect(method)} className={classes.container}>
			<BillingMethodOptionsPopover
				method={method}
				anchorEl={optionsPopover}
				onClose={() => setOptionsPopover(null)}
				onUpdateBillingAddressClick={onUpdateBillingAddressClick}
			/>
			{!hideTitle && (
				<div
					style={{ color: method.primary ? theme.palette.error.main : "" }}
					className={classes.methodTitle}
				>
					{method.primary ? t("defaultBillingMethod") : t("billingMethod")}
				</div>
			)}
			<div className={classes.linearContainer}>
				{Boolean(onSelect) && <Radio style={{ marginLeft: -4 }} checked={selected} />}
				<div style={inputContainerStyle} className={classes.inputContainer}>
					<HiddenCardValue />
					<HiddenCardValue />
					<HiddenCardValue />
					{/* Last four digits of credit card number */}
					{method.card.last4}
					{/* Payment Method Type */}
					<div className={classes.paymentMethodTypeContainer}>
						{typeof brandImages[method.card.brand] === "string" ? (
							<img
								src={String(brandImages[method.card.brand])}
								className={classes.paymentMethodImage}
							/>
						) : (
							<Box style={{ marginRight: 8 }}>{brandImages[method.card.brand] || ""}</Box>
						)}

						{t(method.card.brand)}
					</div>
				</div>

				{onUpdateBillingAddressClick && (
					<div
						onClick={(e) => setOptionsPopover(e.currentTarget)}
						className={classes.editIconContainer}
					>
						<EditIconSvg size={20} />
					</div>
				)}
			</div>
		</div>
	);
};

import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { Dispatch } from "react";
import config from "config";
import { StoreState } from "../../../setup/store";
import {
	ChangePasswordFunc,
	ConfirmPasswordFunc,
	SendPassRecoveryEmailFunc,
} from "types/ReduxActions";
import { setSnackbarState } from "../snackBar";

const poolData = {
	ClientId: config.CognitoClientId,
	UserPoolId: config.UserPoolId,
};

const userPool = new CognitoUserPool(poolData);

export const changePassword: ChangePasswordFunc = (currentPassword, newPassword, callback) => {
	return (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;
		if (!userData) return;

		const cognitoUser = new CognitoUser({
			Username: userData.username,
			Pool: userPool,
		});

		cognitoUser.getSession(() => {
			cognitoUser.changePassword(currentPassword, newPassword, (err) => {
				if (err) {
					callback && callback(false, err.name);
					return;
				}

				callback && callback(true, null);
				dispatch(setSnackbarState(true, "success", "Password updated successfully!"));
			});
		});
	};
};

export const sendPassRecoveryEmail: SendPassRecoveryEmailFunc = (email, cb) => {
	return () => {
		const cognitoUser = new CognitoUser({
			Username: email,
			Pool: userPool,
		});

		cognitoUser.getSession(() => {
			cognitoUser.forgotPassword({
				onSuccess: (data) => {
					cb(true, null);
				},
				onFailure: (err) => {
					cb(false, err);
				},
			});
		});
	};
};

export const confirmPassword: ConfirmPasswordFunc = (email, code, password, cb) => {
	return () => {
		const cognitoUser = new CognitoUser({
			Username: email,
			Pool: userPool,
		});

		cognitoUser.getSession(() => {
			cognitoUser.confirmPassword(code, password, {
				onSuccess: () => {
					cb(true, null);
				},

				onFailure: (err) => {
					cb(false, err);
				},
			});
		});
	};
};

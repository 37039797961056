import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 16,
		marginBottom: 42,
	},

	postOverview: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "white",
		borderRadius: 12,
		width: "56%",
		height: 65,
		padding: 16,
		paddingLeft: 32,
		paddingRight: 32,
	},

	postData: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "44.5%",
		backgroundColor: "white",
		marginLeft: 12,
		borderRadius: 12,
	},

	postDataItem: {
		width: "25%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: 65,
	},

	postImage: {
		height: 50,
		width: 50,
		objectFit: "cover",
		marginRight: 12,
		borderRadius: 8,
	},

	postText: {
		width: "33%",
		fontSize: 11,
		fontWeight: 300,
	},

	socialDetails: {
		width: "40%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},

	socialIcon: {
		marginRight: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	profilePicture: {
		height: 20,
		marginRight: 8,
	},

	username: {
		fontSize: 13,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
	},

	postDateText: {
		fontSize: 11,
		textAlign: "right",
		width: "23%",
		color: theme.palette.text.primary,
	},

	chartTitle: {
		color: "#5B666E",
		fontWeight: 600,
		marginLeft: 24,
		marginBottom: 6,
		fontSize: 18,
		borderRadius: 12,
	},

	lineChartContainer: {
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
		height: 400,
		paddingLeft: 16,
	},

	pieChartTitle: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		fontSize: 14,
		marginBottom: 8,
	},

	pieChartsContainer: {
		display: "flex",
		flexDirection: "row",
		width: "105%",
		alignItems: "center",
		marginTop: 24,
		paddingLeft: 32,
	},

	pieChartItem: {
		width: "25%",
		height: 100,
	},

	pieChart: {
		width: 200,
		height: 110,
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useUserInfoPopperStyles = makeStyles((theme) => ({
	userPopper: {
		zIndex: 99999,
		backgroundColor: theme.palette.secondary.main,
		borderRadius: 8,
		padding: 12,
		boxShadow: "0px 4px 24px rgba(217, 227, 235, 0.8)",
		width: 350,
		border: '1px solid white'
	},

	userName: {
		fontWeight: 500,
		fontSize: 14,
	},

	emailAddress: {
		fontSize: 12,
	},

	role: {
		width: "fit-content",
		marginLeft: "auto",
		fontSize: 14,
		textTransform: "uppercase",
		fontWeight: 500,
		color: theme.palette.primary.main,
	},
}));

export default useUserInfoPopperStyles;

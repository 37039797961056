import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import usePageStyles from "../styles";
import { SummaryElementType } from "./elements";

interface Props {
	element: SummaryElementType;
	value: number;
	index: number;
	isLast: boolean;
}

const SummaryElement = (props: Props) => {
	const classes = usePageStyles();
	const { t } = useTranslation();
	const { element, isLast, index, value } = props;

	return (
		<Box
			className={classes.element}
			style={{
				marginLeft: index === 0 ? 0 : 6,
				marginRight: isLast ? 0 : 6,
			}}
		>
			<img src={element.icon} className={classes.icon} />
			<Box className={classes.elementText}>
				<span className={classes.summaryTitle}>
					{value || 0}
					{/* Remove plural "s" letter from string if value is less than 2 */}
					&nbsp;{value < 2 ? t(element.title).slice(0, -1) : t(element.title)}
				</span>
				<span className={classes.summarySubtitle}>{t(element.subtitle)}</span>
			</Box>
		</Box>
	);
};

export default SummaryElement;

import { makeStyles } from "@material-ui/core";

const useGeneralStyles = makeStyles((theme) => ({
	container: {
		marginTop: 16,
	},

	socialProfileContainer: {
		marginTop: 16,
		marginLeft: 32,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	selectedProfileImage: {
		height: 25,
		width: 25,
		marginLeft: 4,
		marginRight: 4,
		borderRadius: "50%",
	},

	selectedProfileUsername: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.text.primary,
	},
}));

export default useGeneralStyles;

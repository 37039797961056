import { Box } from "@material-ui/core";
import Task from "types/Task";
import useSummaryStyles from "../../styles/summary";
import SummaryAssignments from "./Assignments";
import SummaryAttachments from "./Attachments";
import SummaryDescription from "./Description";
import SummarySocialProfiles from "./SocialProfiles";

interface Props {
	selectedTask: Task;
}

const Summary = (props: Props) => {
	const { selectedTask } = props;
	const classes = useSummaryStyles({});

	return (
		<Box className={classes.summary} display="flex">
			<Box style={{ width: "50%", paddingRight: 32 }}>
				<SummaryDescription selectedTask={selectedTask} />

				<SummarySocialProfiles selectedTask={selectedTask} />
			</Box>
			<Box style={{ width: "50%", paddingLeft: 32 }}>
				<SummaryAssignments selectedTask={selectedTask} />

				<SummaryAttachments selectedTask={selectedTask} />
			</Box>
		</Box>
	);
};

export default Summary;

export default function MenuDotsSvg({
	width,
	height,
	color,
}: {
	width?: number;
	height?: number;
	color?: string;
}) {
	return (
		<svg
			width={width || 4}
			height={height || 20}
			viewBox="0 0 4 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="2" cy="2" r="2" fill={color || "#4B7FA3"} />
			<circle cx="2" cy="10" r="2" fill={color || "#4B7FA3"} />
			<circle cx="2" cy="18" r="2" fill={color || "#4B7FA3"} />
		</svg>
	);
}

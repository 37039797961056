import { useEffect, useState, KeyboardEvent } from "react";
import { useHistory } from "react-router";
import useStyles from "../styles";
import SignUpImage from "assets/signup-4.png";
import FormHeader from "../FormHeader";
import Input from "components/Basic/Inputs/Input";
import Button from "components/Basic/Button";
import SignUpStepper from "./Stepper";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";
import { updateUser, validateUsername } from "redux/actions/user/user-data";
import { checkAuth } from "redux/actions/auth/checkAuth";
import { createTeam } from "redux/actions/team/post";
import { useTranslation } from "react-i18next";
import { StoreState } from "setup/store";
import { validateEmail } from "helpers/utils";
import { signOut } from "redux/actions/auth";
import TextButton from "components/Basic/TextButton";

type InputValues = {
	firstName: string;
	lastName: string;
	teamName: string;
	email: string;
};

export default function UserInfoStep() {
	const [isPolicyAccepted, setIsPolicyAccepted] = useState<boolean>(false);

	const [inputValues, setInputValues] = useState<InputValues>({
		firstName: "",
		lastName: "",
		teamName: "",
		email: "",
	});
	const { userData } = useSelector((state: StoreState) => state.user);
	const [errors, setErrors] = useState<Partial<InputValues>>({});
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === "Enter" && isPolicyAccepted) {
			handleSubmit();
		}
	};

	const handleSubmit = () => {
		const { firstName, lastName, teamName, email } = inputValues;
		let errors: Partial<InputValues> = {};

		const firstNameError = validateUsername(firstName);
		const lastNameError = validateUsername(lastName);

		if (firstNameError) {
			errors.firstName = t(firstNameError);
		}

		if (lastNameError) {
			errors.lastName = t(lastNameError);
		}

		if (!teamName) {
			errors.teamName = t("companyNameRequired");
		}

		if (!userData?.email) {
			if (email && !validateEmail(email)) {
				errors.email = t("invalidEmail");
			} else if (!email) {
				errors.email = t("emailRequired");
			}
		}

		if (Object.keys(errors).length > 0) {
			setErrors({ ...errors });
		} else {
			setLoading(true);
			dispatch(
				updateUser({ firstName, lastName, email, companyName: teamName }, () => {
					/**
					 * The team will only be created if the user's email address is already verified (in case it's been done through cognito)
					 * If the user has to verify their email address first, then the team creation will be done using the "companyName" user property
					 * after verification is complete
					 */
					if (userData?.email && userData?.emailConfirmed) {
						dispatch(
							createTeam({ name: teamName }, () => {
								history.push("/signup/profile-picture");
								setLoading(false);
							})
						);
					} else {
						history.push("signup/verification");
					}
				})
			);
		}
	};

	useEffect(() => {
		dispatch(
			checkAuth((currentUser, userData, error) => {
				const { teams, firstName, lastName, email } = userData || {};

				if (!userData?.emailConfirmed && userData?.email) {
					history.push("signup/verification");
				} else if (userData && !userData?.email) {
					history.push("/signup/general-info");
				} else if (!currentUser) {
					history.push("/login");
				} else if (currentUser && firstName && lastName && email && teams && teams[0]) {
					history.push("/");
				}
			})
		);
	}, []);

	return (
		<>
			<div className={classes.pageContainer}>
				<div className={classes.leftSection}>
					<img src={SignUpImage} className={classes.groupImage} />
				</div>
				<div className={classes.rightSection}>
					<FormHeader includeText={false} />
					<div className={classes.emailConfirmation}>{t("auth:signupStep3Header")}</div>
					<div className={classes.formContainer}>
						{!userData?.email && (
							<div className={classes.inputContainer}>
								<span className={classes.inputHeader}>{t("auth:yourEmail")}</span>
								<Input
									name="email"
									onChange={handleInputChange}
									onKeyDown={handleKeyDown}
									placeholder="Your E-mail"
									value={inputValues.email}
									error={errors.email}
								/>
							</div>
						)}

						<div className={classes.inputContainer}>
							<span className={classes.inputHeader}>{t("auth:yourFirstName")}</span>
							<Input
								name="firstName"
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								placeholder={t("auth:yourFirstName")}
								value={inputValues.firstName}
								error={errors.firstName}
							/>
						</div>

						<div className={classes.inputContainer}>
							<span className={classes.inputHeader}>{t("auth:yourLastName")}</span>
							<Input
								name="lastName"
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								placeholder={t("auth:yourLastName")}
								value={inputValues.lastName}
								error={errors.lastName}
							/>
						</div>

						<div className={classes.inputContainer}>
							<span className={classes.inputHeader}>{t("auth:yourCompanyName")}</span>
							<Input
								name="teamName"
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								placeholder={t("auth:yourCompanyName")}
								value={inputValues.teamName}
								error={errors.teamName}
							/>
						</div>

						<span className={classes.noAccountMessage}>
							<input
								type="checkbox"
								className={classes.checkBox}
								onChange={() => setIsPolicyAccepted(!isPolicyAccepted)}
							/>
							<div className={classes.checkBoxLabel}>
								I have read and accept the{" "}
								<u
									style={{ cursor: "pointer" }}
									onClick={() => window.open("https://hashtaghero.io/privacy.html", "_blank")}
								>
									Privacy Policy
								</u>{" "}
								and{" "}
								<u
									style={{ cursor: "pointer" }}
									onClick={() => window.open("https://hashtaghero.io/privacy.html", "_blank")}
								>
									Terms of Use.
								</u>
							</div>
						</span>

						<div className={classes.buttonContainer}>
							<Button disabled={loading || !isPolicyAccepted} onClick={handleSubmit}>
								{loading ? <CircularProgress size={20} /> : t("continue")}
							</Button>

							<Box width="fit-content" margin="12px auto 12px auto">
								<TextButton onClick={() => dispatch(signOut())} secondary>
									{t("signOut")}
								</TextButton>
							</Box>
						</div>
					</div>
				</div>
			</div>
			<SignUpStepper selectedStep={3} />
		</>
	);
}

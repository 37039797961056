import { MenuItem } from "@material-ui/core";
import { PostFilters, PostFilterType } from "..";
import Network, { networkIcons } from "types/Network";
import useStyles from "../styles";

interface Props {
	network: Network;
	appliedFilters: PostFilters;
	onFilterClick: (type: PostFilterType, value: string) => void;
}

const NetworkFilterItem = (props: Props) => {
	const classes = useStyles();
	const { network, onFilterClick, appliedFilters } = props;
	return (
		<MenuItem
			style={{
				backgroundColor: appliedFilters.find((filter) => filter.value === network) ? "#e6e6e6" : "",
			}}
			onClick={() => onFilterClick("network", network)}
			className={classes.menuItem}
		>
			<img className={classes.networkIcon} src={networkIcons[network]} />
			<span style={{ textTransform: "capitalize" }}>{network}</span>
		</MenuItem>
	);
};

export default NetworkFilterItem;

import { Box, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIconSvg from "assets/SVG/DownloadIconSvg";
import Button from "components/Basic/Button";
import AttachmentPreviewDialog from "components/Dialogs/AttachmentPreview/AttachmentPreview";
import MediaAttachment from "components/Media/MediaAttachment";
import { downloadFiles } from "redux/actions/media/download";
import Task, { Media } from "types/Task";
import useSummaryStyles from "../../styles/summary";

interface Props {
	selectedTask: Task;
}

const SummaryAttachments = (props: Props) => {
	const { selectedTask } = props;
	const classes = useSummaryStyles({});
	const [attachmentPreview, setAttachmentPreview] = useState<boolean>(false);
	const [selectedAttachment, setSelectedAttachment] = useState<Media>();
	const { media } = selectedTask;
	const { t } = useTranslation("tasks");

	const handleAttachmentClick = (media: Media) => {
		setSelectedAttachment(media);
		setAttachmentPreview(true);
	};

	const handleDownloadClick = () => {
		downloadFiles(media.map((item) => item.value.toString()));
	};

	return (
		<Box>
			<AttachmentPreviewDialog
				open={attachmentPreview}
				onClose={() => setAttachmentPreview(false)}
				attachment={selectedAttachment}
				disableActions={true}
			/>
			<Typography className={classes.sectionLabel}>{t("attachments")}:</Typography>

			{!Boolean(media.length) && (
				<div className={classes.emptySectionMessage}>{t("noAttachments")}</div>
			)}

			{Boolean(media.length) && (
				<Box className={classes.attachmentsBox}>
					<Box className={classes.attachments}>
						{media.map((mediaItem, index) => (
							<MediaAttachment
								key={index}
								type={mediaItem.type}
								playButtonStyle={{ top: 18, left: 18 }}
								style={{ height: 60, width: 60, maxWidth: 60, borderRadius: 6 }}
								onAttachmentClick={() => handleAttachmentClick(mediaItem)}
								attachment={mediaItem.value}
								thumbnailKey={mediaItem.thumbnail}
							/>
						))}
					</Box>

					<Button
						onClick={handleDownloadClick}
						secondary
						style={{ width: "100%", backgroundColor: "#B6D8F0" }}
					>
						<Box display="flex" alignItems="center" justifyContent="center">
							<DownloadIconSvg size={20} />
							<span style={{ marginLeft: 8 }}>{t("downloadAllFiles")}</span>
						</Box>
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default SummaryAttachments;

import { makeStyles, Dialog as MUIDialog, Box } from "@material-ui/core";
import TitleText from "components/Basic/Text/TitleText";
import { CSSProperties, ReactNode } from "react";

interface Props {
	open: boolean;
	style?: CSSProperties;
	containerStyle?: CSSProperties;
	title?: ReactNode;
	titleStyle?: CSSProperties;
	onClose: () => void;
}

const useStyles = makeStyles(() => ({
	paper: {
		maxWidth: 1050,
		maxHeight: 800,
		borderRadius: 12,
		backgroundColor: "white",
	},

	container: {
		backgroundColor: "white",
		padding: 24,
		paddingTop: 32,
		paddingBottom: 32,
		position: "relative",
	},
}));

const Dialog: React.FC<Props> = (props) => {
	const { open, style, children, title, containerStyle, titleStyle, onClose } = props;
	const classes = useStyles();

	return (
		<MUIDialog classes={{ paper: classes.paper }} open={open} style={style} onClose={onClose}>
			<Box style={containerStyle} className={classes.container}>
				<TitleText style={{ marginBottom: 32, ...titleStyle }}>{title}</TitleText>
				{children}
			</Box>
		</MUIDialog>
	);
};

export default Dialog;

import { CircularProgress, Popover } from "@material-ui/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import NotificationItem from "../../pages/Notifications/components/NotificationItem";
import { getNotifications } from "redux/actions/notifications";
import { StoreState } from "../../setup/store";
import useNotificationsModalStyles from "./styles/notifications-modal";

interface Props {
	anchorEl: HTMLElement | null;
	handleClose: (readAll: boolean) => void;
}

const NotificationsModal = (props: Props) => {
	const { modalNotifications, loading } = useSelector((state: StoreState) => state.notifications);
	const history = useHistory();
	const { anchorEl, handleClose } = props;
	const classes = useNotificationsModalStyles();
	const dispatch = useDispatch();

	const handleViewAllClick = () => {
		handleClose(false);
		history.push("/notifications");
	};

	useEffect(() => {
		if (anchorEl && modalNotifications.length === 0) {
			dispatch(getNotifications({}));
		}
	}, [anchorEl]);

	return (
		<Popover
			classes={{ paper: classes.paper }}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={() => handleClose(true)}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
		>
			{modalNotifications.length === 0 && (
				<div className={classes.centeredContainer}>No new notifications</div>
			)}

			{loading && (
				<div className={classes.centeredContainer}>
					<CircularProgress size={22} />
				</div>
			)}
			{modalNotifications.map((notification) => (
				<NotificationItem notification={notification} key={notification._id} />
			))}

			<div onClick={handleViewAllClick} className={classes.viewAllButton}>
				View All
			</div>
		</Popover>
	);
};

export default NotificationsModal;

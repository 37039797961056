import { CircularProgress, Divider } from "@material-ui/core";
import ExpandIconSvg from "assets/SVG/ExpandIconSvg";
import FilterIconSvg from "assets/SVG/FilterIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import CheckBox from "components/Basic/Inputs/CheckBox";
import Dropdown from "components/Basic/Inputs/Dropdown";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { listSocialProfiles } from "redux/actions/socialProfiles/socialProfiles";
import { StoreState } from "setup/store";
import SocialProfile from "types/SocialProfile";
import useFilterMenuStyles from "./styles";

export type InteractionsSectionFilters = {
	socialProfiles?: SocialProfile[];
};

export type Sort = "asc" | "desc";

interface Props {
	onFiltersChange: (filters: InteractionsSectionFilters) => void;
	onSortChange: (sort: Sort) => void;
	hideSocialProfiles?: boolean;
	filters?: InteractionsSectionFilters;
	sort?: Sort;
}

const InteractionsSectionFiltersMenu: React.FC<Props> = (props) => {
	const classes = useFilterMenuStyles();
	const { t } = useTranslation("reply");

	return (
		<>
			<Dropdown
				dropdownComponent={<DropdownComponent {...props} />}
				buttonComponent={
					<div className={classes.filterButton}>
						<FilterIconSvg size={15} />
						<span style={{ paddingLeft: 6, paddingRight: 6 }}>{t("sortFilter")}</span>
						<ExpandIconSvg />
					</div>
				}
			/>
		</>
	);
};

const DropdownComponent: React.FC<Props> = (props) => {
	const classes = useFilterMenuStyles();
	const { t } = useTranslation("reply");
	const dispatch = useDispatch();
	const { profiles, profilesLoading } = useSelector((state: StoreState) => state.socialProfiles);
	const { onSortChange, onFiltersChange, hideSocialProfiles, sort, filters } = props;

	const handleProfileClick = (profile: SocialProfile) => {
		let updatedFilters = { ...(filters || {}) };
		const checkedProfile = updatedFilters.socialProfiles?.findIndex(
			({ _id }) => _id === profile._id
		);

		if (typeof checkedProfile === "number" && checkedProfile > -1) {
			updatedFilters!.socialProfiles!.splice(checkedProfile, 1);
		} else {
			updatedFilters = {
				...(updatedFilters || {}),
				socialProfiles: [...(updatedFilters.socialProfiles || []), profile],
			};
		}

		onFiltersChange({ ...updatedFilters });
	};

	useEffect(() => {
		if (profiles.length === 0) {
			dispatch(listSocialProfiles());
		}
	}, []);

	return (
		<div className={classes.menuContainer}>
			<div onClick={() => onSortChange("desc")} className={classes.menuItem}>
				<span>{t("newestFirst")}</span>
				<div className={classes.checkboxContainer}>
					<CheckBox checked={sort === "desc"} width={13} height={13} borderRadius={"50%"} />
				</div>
			</div>
			<div onClick={() => onSortChange("asc")} className={classes.menuItem}>
				<span>{t("oldestFirst")}</span>
				<div className={classes.checkboxContainer}>
					<CheckBox checked={sort === "asc"} height={13} borderRadius={"50%"} width={13} />
				</div>
			</div>

			{!hideSocialProfiles && (
				<>
					<Divider style={{ marginTop: 8 }} />
					<div style={{ paddingTop: 8, paddingBottom: 8 }} className={classes.menuItem}>
						{t("pickFromSocialProfiles")}:
					</div>
					{profilesLoading && (
						<div className={classes.loadingContainer}>
							<CircularProgress size={15} />
						</div>
					)}
					{!profilesLoading &&
						profiles.map((profile, index) => (
							<div
								onClick={() => handleProfileClick(profile)}
								key={index}
								className={classes.socialProfileItem}
							>
								<NetworkIcon size={20} network={profile.network} />
								<SocialProfilePicture style={{ marginLeft: 6, marginRight: 6 }} profile={profile} />

								<span className={classes.socialProfileName}>
									{profile.displayName || profile.username}
								</span>
								<div className={classes.checkboxContainer}>
									<CheckBox
										checked={Boolean(
											filters?.socialProfiles?.find(({ _id }) => _id === profile._id)
										)}
										height={15}
										width={15}
									/>
								</div>
							</div>
						))}
				</>
			)}
		</div>
	);
};

export default InteractionsSectionFiltersMenu;

import { makeStyles } from "@material-ui/core";

const usePreviewSectionStyles = makeStyles(() => ({
	container: {
		borderRadius: 12,
		width: "50%",
		height: "80vh",
		maxHeight: "760px",
		marginTop: 16,
		marginLeft: 8,
		overflowY: "auto",
		paddingBottom: 16,
	},

	noContentMessage: {
		textAlign: "center",
		color: "#868C91",
		fontSize: 16,
		fontFamily: "Avenir",
		paddingTop: 84,
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
	},

	previewContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: 16,
		borderRadius: 16,
		overflow: "hidden",
		background: "white",
		boxShadow: "2px 2px 12px rgba(62, 76, 85, 0.1)",
	},

	title: {
		fontSize: 14,
		fontWeight: 700,
		color: "#B6BFC4",
		padding: 16,
		textTransform: "uppercase",
	},

	tabsContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},
}));

export default usePreviewSectionStyles;

import { CircularProgress } from "@material-ui/core";
import { SetStateAction, Dispatch, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Basic/Button";
import Input from "components/Basic/Inputs/Input";
import useAuthStyles from "../styles";

interface Props {
	email: string;
	sentEmails: number;
	emailError: string;
	loading: boolean;
	onSubmit: () => void;
	setEmail: Dispatch<SetStateAction<string>>;
}

const EmailEntry = (props: Props) => {
	const classes = useAuthStyles();
	const { t } = useTranslation("auth");
	const { email, setEmail, onSubmit, emailError, loading, sentEmails } = props;

	const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
		if (loading) return;
		if (e.key === "Enter") {
			onSubmit();
		}
	};

	return (
		<div>
			<div className={classes.descriptionText}>{t("recoveryEnterEmail")}</div>
			<div className={classes.inputContainer} style={{ paddingLeft: 16, paddingRight: 16 }}>
				<span className={classes.inputHeader}>{t("yourEmail")}</span>
				<Input
					name="email"
					error={emailError}
					style={{ width: 350 }}
					onChange={(e) => setEmail(e.target.value)}
					onKeyDown={handleKeyDown}
					value={email}
					placeholder="example@mail.com"
				/>
			</div>

			<Button disabled={loading} style={{ marginTop: "15vh" }} onClick={onSubmit}>
				{loading ? (
					<CircularProgress size={18} />
				) : sentEmails === 0 ? (
					t("recoverMyPassword")
				) : (
					t("submit")
				)}
			</Button>
		</div>
	);
};

export default EmailEntry;

export default function EyeIconSvg({ size }: { size?: number }) {
	return (
		<svg
			width={size || 30}
			height={size || 30}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.3028 15.7793C23.8725 15.7793 23.5236 15.4304 23.5236 15.0001C23.5236 10.3002 19.7 6.47645 14.9999 6.47645C14.5696 6.47645 14.2207 6.12752 14.2207 5.69721C14.2207 5.2669 14.5696 4.91797 14.9999 4.91797C20.5592 4.91797 25.082 9.44077 25.082 15C25.0821 15.4305 24.7332 15.7793 24.3028 15.7793Z"
				fill="#868C91"
			/>
			<path
				d="M15 25.082C9.44077 25.082 4.91797 20.5592 4.91797 14.9999C4.91797 14.5696 5.26684 14.2207 5.69721 14.2207C6.12758 14.2207 6.47645 14.5696 6.47645 14.9999C6.47645 19.6999 10.3002 23.5236 15.0001 23.5236C15.4304 23.5236 15.7793 23.8725 15.7793 24.3028C15.7793 24.7331 15.4304 25.082 15 25.082Z"
				fill="#868C91"
			/>
			<path
				d="M15 25.0855C11.0549 25.0855 7.22332 23.4011 3.91921 20.2143C1.4496 17.8324 0.149283 15.4731 0.0951418 15.3738C-0.0317139 15.1413 -0.0317139 14.8602 0.0951418 14.6277C0.149283 14.5284 1.4496 12.169 3.91921 9.78716C7.22326 6.60041 11.0549 4.91602 15 4.91602C18.945 4.91602 22.7766 6.60041 26.0807 9.78716C28.5503 12.1691 29.8506 14.5284 29.9048 14.6277C30.0316 14.8602 30.0316 15.1413 29.9048 15.3738C29.8506 15.4731 28.5503 17.8325 26.0807 20.2143C22.7767 23.4011 18.945 25.0855 15 25.0855ZM1.68509 15.0011C2.1389 15.735 3.28089 17.4409 5.03402 19.1242C8.07668 22.0457 11.4297 23.5271 15.0001 23.5271C22.5617 23.5271 27.2685 16.689 28.3151 15.0005C27.8613 14.2665 26.7193 12.5606 24.9661 10.8774C21.9235 7.95587 18.5704 6.4745 15 6.4745C7.4383 6.47444 2.73151 13.3125 1.68509 15.0011Z"
				fill="#868C91"
			/>
			<path
				d="M14.9999 10.0957C13.089 10.0957 11.4345 11.1893 10.625 12.784L12.9049 15.0002L10.625 17.2164C11.4345 18.8113 13.089 19.9049 14.9999 19.9049C17.7087 19.9049 19.9046 17.709 19.9046 15.0002C19.9046 12.2914 17.7087 10.0957 14.9999 10.0957Z"
				fill="#868C91"
			/>
			<path
				d="M14.9999 20.6842C12.848 20.6842 10.9054 19.4907 9.93013 17.5692C9.77567 17.265 9.83714 16.8956 10.0818 16.6578L11.787 15.0003L10.0818 13.3428C9.83714 13.1049 9.77567 12.7355 9.93013 12.4313C10.9054 10.51 12.848 9.31641 14.9999 9.31641C18.134 9.31641 20.6837 11.8662 20.6837 15.0002C20.6837 18.1341 18.134 20.6842 14.9999 20.6842ZM11.6078 17.3479C12.3726 18.4566 13.6277 19.1257 15 19.1257C17.2747 19.1257 19.1253 17.2751 19.1253 15.0003C19.1253 12.7255 17.2748 10.8749 15 10.8749C13.6278 10.8749 12.3726 11.544 11.6078 12.6526L13.4481 14.4415C13.599 14.5882 13.6842 14.7898 13.6842 15.0003C13.6842 15.2108 13.599 15.4123 13.4481 15.5591L11.6078 17.3479Z"
				fill="#868C91"
			/>
		</svg>
	);
}

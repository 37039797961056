export default function AttentionIconSvg({ size }: { size: number }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="24" cy="24" r="20" fill="#F04755" />
			<path
				d="M6.85715 24.1734C6.85715 14.6101 14.6098 6.85742 24.1732 6.85742C33.7365 6.85742 41.4892 14.6101 41.4892 24.1734C41.4892 33.7368 33.7365 41.4895 24.1732 41.4895C14.6098 41.4895 6.85715 33.7368 6.85715 24.1734Z"
				fill="#F04755"
			/>
			<path
				d="M25.6732 31.5946V30.0946H22.6732V31.5946H25.6732ZM22.6732 31.6193V33.1193H25.6732V31.6193H22.6732ZM22.6732 15.5154V25.4103H25.6732V15.5154H22.6732ZM22.6732 31.5946V31.6193H25.6732V31.5946H22.6732ZM24.1732 39.9895C15.4382 39.9895 8.35715 32.9084 8.35715 24.1734H5.35715C5.35715 34.5652 13.7814 42.9895 24.1732 42.9895V39.9895ZM39.9892 24.1734C39.9892 32.9084 32.9081 39.9895 24.1732 39.9895V42.9895C34.565 42.9895 42.9892 34.5652 42.9892 24.1734H39.9892ZM24.1732 8.35742C32.9081 8.35742 39.9892 15.4385 39.9892 24.1734H42.9892C42.9892 13.7816 34.565 5.35742 24.1732 5.35742V8.35742ZM24.1732 5.35742C13.7814 5.35742 5.35715 13.7816 5.35715 24.1734H8.35715C8.35715 15.4385 15.4382 8.35742 24.1732 8.35742V5.35742Z"
				fill="white"
			/>
		</svg>
	);
}

import { Box } from "@material-ui/core";
import Button from "components/Basic/Button";
import { getSettingPageRoute } from "pages/Settings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
	isEnterpriseSubscription,
	isFreeSubscription,
} from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import SubscriptionDialog from "../../../SubscriptionDialog";
import useCurrentSubscriptionPlanStyles from "../styles";

const CurrentSubscriptionPlanActions = () => {
	const history = useHistory();
	const [subscriptionDialog, setSubscriptionDialog] = useState(false);
	const { t } = useTranslation("settings");
	const classes = useCurrentSubscriptionPlanStyles();
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);

	const handleSubscriptionSettingsClick = () => {
		/**
		 * open the subscription dialog directly if a team has an active subscription
		 * that's not a free or an enterprise one
		 */
		if (
			currentTeamSubscription &&
			!isFreeSubscription(currentTeamSubscription) &&
			!isEnterpriseSubscription(currentTeamSubscription)
		) {
			setSubscriptionDialog(true);

			// otherwise redirect to the plans list
		} else {
			history.push(getSettingPageRoute("billingAndPayments", "plans"));
		}
	};

	return (
		<Box className={classes.actionButtonsContainer}>
			<SubscriptionDialog open={subscriptionDialog} onClose={() => setSubscriptionDialog(false)} />

			<Button secondary className={classes.actionButton} onClick={handleSubscriptionSettingsClick}>
				{t("subscriptionSettings")}
			</Button>
		</Box>
	);
};

export default CurrentSubscriptionPlanActions;

import { useTheme } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
	DeleteConfirmImage,
	PageIllustration,
	ProfileIllustration,
	RedirectionIllustration,
} from "assets";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { deleteSocialProfile } from "redux/actions/socialProfiles/socialProfiles";
import SocialProfile from "types/SocialProfile";
import useProfileDetailsStyles from "../../styles/social-profile-details";
import SocialProfileDetailsHeader from "./Header";
import SubProfilesCard from "./SubProfilesCard";
import Permissions from "./Permissions";
import DataCards from "./DataCards";
import SocialProfileDetailsActions from "./Actions";
import { useTranslation } from "react-i18next";
import { getSettingPageRoute, selectedSettingTabQuery } from "pages/Settings";

interface Props {
	profile: SocialProfile | undefined;
}

const SocialProfileDetails = ({ profile }: Props) => {
	const { t } = useTranslation("settings");
	const [redirectConfirmation, setRedirectConfirmation] = useState<boolean>(false);
	const [deleteDialog, setDeleteDialog] = useState<{
		open: boolean;
		message: string;
	}>({ open: false, message: "" });
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
	const theme = useTheme();
	const dispatch = useDispatch();
	const classes = useProfileDetailsStyles();
	const history = useHistory();

	const deleteProfile = () => {
		if (!profile?._id || !profile.type) return;
		setDeleteLoading(true);
		dispatch(
			deleteSocialProfile(profile._id, (success) => {
				setDeleteLoading(false);
				setDeleteDialog({ message: "", open: false });
				if (success) {
					setDeleteConfirmation(true);
					history.push(getSettingPageRoute("socialProfiles"));
				}
			})
		);
	};

	const handlePermissionsRedirect = () => {
		if (!profile) return;
		if (profile.network === "facebook") {
			window.open("https://www.facebook.com/settings?tab=business_tools&ref=settings", "_blank");
			setRedirectConfirmation(false);
		}
	};

	if (!profile) {
		return null;
	}

	return (
		<div>
			<ConfirmationDialog
				image={DeleteConfirmImage}
				imageStyle={{ borderRadius: "50%" }}
				open={deleteDialog.open}
				confirmButton="Delete Profile"
				onClose={() => setDeleteDialog({ open: false, message: "" })}
				onAction={deleteProfile}
				actionLoading={deleteLoading}
				message={deleteDialog.message}
				messageStyle={{ fontSize: 14, maxWidth: 400 }}
			/>

			<ConfirmationDialog
				hideButtons
				autoClose
				image={ProfileIllustration}
				open={deleteConfirmation}
				onClose={() => setDeleteConfirmation(false)}
				message={t("socialProfileDeleteConfirm")}
			/>

			<ConfirmationDialog
				open={redirectConfirmation}
				image={RedirectionIllustration}
				onClose={() => setRedirectConfirmation(false)}
				textMaxWidth={340}
				confirmButton="Continue"
				message={t("perrmissionsRedirectConfirmation")}
				onAction={handlePermissionsRedirect}
			/>

			<div className={classes.viewTitle}>
				<span
					onClick={() => history.push(`/settings?${selectedSettingTabQuery}=socialProfiles`)}
					className={classes.headerText}
				>
					{t("socialProfileSettings")}
				</span>
				<span>&nbsp;{" > "}</span>
				<span className={classes.headerText} style={{ color: theme.palette.primary.main }}>
					&nbsp;{t("socialProfileDetails")}
				</span>
			</div>

			<div className={classes.linearContainer} style={{ marginTop: 12 }}>
				<div className={classes.card} style={{ marginRight: 6 }}>
					<SocialProfileDetailsHeader profile={profile} />

					<div className={classes.linearContainer} style={{ marginTop: 42 }}>
						<Permissions profile={profile} />
						<DataCards />
					</div>

					<SocialProfileDetailsActions
						profile={profile}
						setDeleteDialog={setDeleteDialog}
						setRedirectConfirmation={setRedirectConfirmation}
					/>
				</div>

				{profile.type === "profile" &&
				(profile.network === "facebook" || profile.network === "linkedin") ? (
					<SubProfilesCard currentProfile={profile} />
				) : (
					<div className={classes.pageIllustrationContainer}>
						<img className={classes.pageIllustartion} src={PageIllustration} />
					</div>
				)}
			</div>
		</div>
	);
};

export default SocialProfileDetails;

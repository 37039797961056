import { makeStyles } from "@material-ui/core";

const usePageStyles = makeStyles((theme) => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "90vh",
		paddingTop: 12,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignitems: "center",
	},

	dataHeader: {
		width: "100%",
		backgroundColor: "white",
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		borderRadius: 12,
		marginTop: 16,
	},

	dataItemTitle: {
		fontFamily: "Avenir",
		fontSize: 12,
		marginBottom: 1,
	},

	dataItemValue: {
		fontWeight: 500,
		fontSize: 16,
	},

	dataHeaderItem: {
		width: "12.5%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		height: 65,
	},
}));

export default usePageStyles;

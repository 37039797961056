import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Tabs, { Tab } from "../../components/Tabs";
import usePageStyles from "./page-styles";
import debounce from "lodash.debounce";
import { listPosts } from "redux/actions/posts/get";
import { Box } from "@material-ui/core";
import PostsSearch from "./components/PostsSearch";
import { useHistory } from "react-router";
import { listTasks } from "redux/actions/tasks/get";
import TasksSearch from "./components/TasksSearch";

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
}

export type SearchTab = "posts" | "tasks";
const tabs: SearchTab[] = ["posts", "tasks"];

const GeneralSearchPage = (props: Props) => {
	const classes = usePageStyles();
	const [selectedTab, setSelectedTab] = useState<Tab>();
	const { searchValue, setSearchValue } = props;
	const dispatch = useDispatch();
	const history = useHistory();

	const debouncedSearch = useCallback(
		debounce((searchValue) => {
			if (searchValue) {
				if (selectedTab?.name === "posts") {
					dispatch(listPosts({ searchValue }));
				} else if (selectedTab?.name === "tasks") {
					dispatch(listTasks(undefined, { searchValue }));
				}
			}
		}, 350),
		[selectedTab]
	);

	useEffect(() => {
		debouncedSearch(searchValue);
	}, [searchValue, selectedTab]);

	useEffect(() => {
		const { pathname } = history.location;

		if (pathname === "/home" || pathname === "/") {
			setSelectedTab({ index: 1, name: "tasks" });
		} else {
			setSelectedTab({ index: 0, name: "posts" });
		}
	}, []);

	return (
		<Box className={classes.pageContainer}>
			<Box className={classes.innerContainer}>
				<Box className={classes.tabsContainer}>
					<Tabs tabs={tabs} selectedTab={selectedTab} onTabChange={setSelectedTab} />
				</Box>

				<Box className={classes.resultSection}>
					{selectedTab?.name === "posts" && (
						<PostsSearch searchValue={searchValue} setSearchValue={setSearchValue} />
					)}

					{selectedTab?.name === "tasks" && (
						<TasksSearch searchValue={searchValue} setSearchValue={setSearchValue} />
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default GeneralSearchPage;

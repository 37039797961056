import { Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TeamUserCard from "modules/Settings/Team/TeamUserCard";
import { StoreState } from "../../../../../../../setup/store";
import Team, { TeamUserRole } from "types/User/Team";
import usePageStyles from "../../../../../page-styles";
import useWorkspacesStyles from "../../../styles";
import InviteUser from "./InviteUser";
import TeamUserInvitationCard from "modules/Settings/Team/TeamUserCard/TeamUserInvitationCard";
import { useMemo } from "react";
import TeamMembersLimitCard from "modules/Settings/Team/TeamMembersLimitCard";
import LoadingSpinner from "components/Basic/LoadingSpinner";

interface Props {
	team?: Team;
	currentUserRole?: TeamUserRole;
}

const TeamUsers = (props: Props) => {
	const { team, currentUserRole } = props;
	const classes = useWorkspacesStyles();
	const { t } = useTranslation("workspaces");
	const { teamsLoading } = useSelector((state: StoreState) => state.teams);
	const pageClasses = usePageStyles();
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const pendingInvitations = useMemo(
		() => team?.invitations?.filter((invitation) => !invitation.deleted),
		[team]
	);

	const { teamUsersLimit, limitReached } = useMemo(() => {
		if (currentTeamSubscription?.status === "cancelled" || !team) {
			return { teamUsersLimit: 0, limitReached: true };
		}
		let teamUsersLimit =
			(currentTeamSubscription &&
				(Number(currentTeamSubscription.planInfo?.features?.teamMembers) || 0) +
					(currentTeamSubscription.userAddons || 0)) ||
			0;

		return {
			teamUsersLimit,
			limitReached: teamUsersLimit <= team.users.length - 1 + (team.invitations?.length || 0),
		};
	}, [currentTeamSubscription, team]);

	if (!team) {
		return null;
	}

	return (
		<Box>
			<div className={classes.label}>
				{t("yourTeam")} {team.users.length ? `(${team.users.length})` : ""}
			</div>

			<div className={classes.teamUsersContainer}>
				<LoadingSpinner isVisible={teamsLoading} />

				{team.users?.map((teamUser, index) => (
					<TeamUserCard
						team={team}
						key={index}
						index={index}
						isOwner={currentUserRole === "owner"}
						teamUser={teamUser}
					/>
				))}
			</div>

			{currentUserRole === "owner" && (
				<>
					{pendingInvitations && Boolean(pendingInvitations?.length) && (
						<>
							<div style={{ marginTop: 24 }} className={classes.label}>
								{t("sentInvitations")}{" "}
								{Boolean(pendingInvitations.length) ? `(${pendingInvitations.length})` : ""}
							</div>

							<div
								style={{ minHeight: 50, marginBottom: 16 }}
								className={classes.teamUsersContainer}
							>
								{pendingInvitations.map((invitation, index) => (
									<TeamUserInvitationCard index={index} invitation={invitation} key={index} />
								))}
							</div>
						</>
					)}

					{/* TODO: Animate the transition between the two components */}
					{!limitReached && <InviteUser teamUsersLimit={teamUsersLimit} team={team} />}
					{limitReached && currentTeamSubscription?.status !== "cancelled" && (
						<TeamMembersLimitCard teamUsersLimit={teamUsersLimit} />
					)}
				</>
			)}
		</Box>
	);
};

export default TeamUsers;

import { SetStateAction, Dispatch } from "react";
import { TodayItem } from "../..";
import ListPost from "modules/Posts/ListPost";
import Post from "types/Post";
import useStyles from "../../styles/today-view-styles";

interface Props {
	todayItem: TodayItem;
	onPostClick: (post: Post) => void;
	timelineSeparator: string | undefined;
	setTimelineSeparator: Dispatch<SetStateAction<string | undefined>>;
}

const TimelineSeparator = () => {
	const classes = useStyles();

	return (
		<div className={classes.separator}>
			<div className={classes.separatorCircle}></div>
		</div>
	);
};

const TodayViewItem = (props: Props) => {
	const classes = useStyles();

	const { todayItem, setTimelineSeparator, timelineSeparator, onPostClick } = props;

	const compareTime = (postDateString: string) => {
		const postDate = new Date(postDateString);
		const currentDate = new Date();

		if (currentDate > postDate) {
			return true;
		} else return false;
	};

	return (
		<div>
			<div className={classes.container}>
				<div className={classes.date}>
					<span className={classes.dateText}>{todayItem.hour}</span>
				</div>

				<div className={classes.postsContainer}>
					{todayItem.posts.map((post, index) => (
						<>
							<ListPost
								style={{
									backgroundColor: !compareTime(post.schedule as string) ? "white" : "",
									paddingLeft: 16,
									borderRadius: 8,
									marginBottom: 0,
									width: "100%",
								}}
								disabled={compareTime(post.schedule as string)}
								imageStyle={{ borderRadius: 4 }}
								key={index}
								post={post}
								onClick={onPostClick}
							/>

							{/* Show the timeline separator below the last schedule post that has been already posted */}
							{/* timelineSeparator state is there to make sure it doesn't get rendered twice */}
							{compareTime(post.scheduledAt as string) &&
								(timelineSeparator ? post.scheduledAt === timelineSeparator : true) &&
								!compareTime(todayItem.posts[index + 1]?.scheduledAt as string) && (
									<>
										{setTimelineSeparator(post.scheduledAt)}
										<TimelineSeparator />
									</>
								)}
						</>
					))}
				</div>
			</div>
		</div>
	);
};

export default TodayViewItem;

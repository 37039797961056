import { useState, useEffect, ChangeEvent, KeyboardEvent, MouseEvent } from "react";
import { CircularProgress, Dialog } from "@material-ui/core";
import Note from "types/Note";
import BellIcon from "assets/note-bell.png";
import Button from "components/Basic/Button";
import ScheduleSelector from "../../components/Dialogs/DateTimePicker";
import useStyles from "./styles/dialog-styles";
import { createNote } from "redux/actions/notes/create";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../setup/store";
import { CREATE_NOTE_SUCCESS, DELETE_NOTE_SUCCESS } from "redux/reducers/notes";
import { deleteNote } from "redux/actions/notes/delete";
import { formatDate } from "helpers/dateAndTime";
import { useTranslation } from "react-i18next";

interface Props {
	open: boolean;
	handleClose: () => void;
	selectedNote?: Note;
}

const TEXT_AREA_ID = "note-text-area";

const NoteDialog = (props: Props) => {
	const [note, setNote] = useState<Partial<Note>>({});
	const { t } = useTranslation();
	const [scheduleMenu, setScheduleMenu] = useState<HTMLElement | null>();
	const {
		createNoteLoading: loading,
		createNoteSuccess: success,
		createNoteErrors: errors,
		deleteNoteSuccess,
		deleteNoteLoading,
	} = useSelector((state: StoreState) => state.notes);
	const classes = useStyles();
	const dispatch = useDispatch();

	const { open, handleClose, selectedNote } = props;

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.target;
		setNote({
			...note,
			content: value,
		});
	};

	const handleReminderChange = (date: Date | undefined) => {
		setNote({
			...note,
			reminder: date ? date.toISOString() : undefined,
		});
	};

	const handleScheduleClick = (e: MouseEvent<HTMLElement>) => {
		setScheduleMenu(e.currentTarget);
	};

	const handleCancel = () => {
		if (selectedNote) {
			dispatch(deleteNote(selectedNote._id));
		} else {
			handleClose();
		}
	};

	const handleSave = () => {
		if (!note || loading || deleteNoteLoading) {
			return;
		}

		if (selectedNote) {
			dispatch(createNote(note, selectedNote._id));
		} else {
			dispatch(createNote(note));
		}
	};

	useEffect(() => {
		if (!open) return;

		setTimeout(() => {
			document.getElementById(TEXT_AREA_ID)?.focus();
		}, 200);

		if (selectedNote) {
			setNote({ ...selectedNote });
		} else {
			setNote({});
		}
	}, [selectedNote, open]);

	useEffect(() => {
		if (success) {
			dispatch({
				type: CREATE_NOTE_SUCCESS,
				payload: false,
			});
			handleClose();
		}
	}, [success]);

	useEffect(() => {
		if (deleteNoteSuccess) {
			dispatch({
				type: DELETE_NOTE_SUCCESS,
				payload: false,
			});
			handleClose();
		}
	}, [deleteNoteSuccess]);

	return (
		<Dialog open={open} onClose={handleClose}>
			<ScheduleSelector
				onSubmit={handleReminderChange}
				defaultValue={note.reminder ? new Date(note.reminder) : undefined}
				anchorEl={scheduleMenu as HTMLElement | null}
				handleClose={() => setScheduleMenu(null)}
			/>

			<div className={classes.container}>
				{/* Note Header */}
				<div className={classes.header}>
					<span className={classes.title}>{selectedNote ? t("notes") : t("newNote")}</span>

					<div className={classes.reminderContainer} onClick={handleScheduleClick}>
						<img src={BellIcon} alt="bell" height={15} />
						<span className={classes.dateText}>
							{note.reminder ? formatDate(note.reminder) : t("addReminder")}
						</span>
					</div>
				</div>

				{/* Note Text */}
				<textarea
					id={TEXT_AREA_ID}
					className={classes.textArea}
					value={note?.content}
					onChange={handleChange}
				/>

				{errors &&
					Object.keys(errors).map((key) => <div className={classes.error}>{t(errors[key])}</div>)}

				<div className={classes.buttonsContainer}>
					<Button
						disabled={loading || deleteNoteLoading}
						onClick={handleCancel}
						style={{ width: 90, marginRight: 8 }}
						secondary={true}
					>
						{deleteNoteLoading ? (
							<CircularProgress size={20} />
						) : selectedNote ? (
							t("remove")
						) : (
							t("cancel")
						)}
					</Button>
					<Button
						disabled={loading || deleteNoteLoading || !note.content}
						onClick={handleSave}
						style={{ width: 90 }}
					>
						{loading ? <CircularProgress size={20} /> : t("save")}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default NoteDialog;

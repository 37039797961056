const HashtagIconSvg = ({ size }: { size?: number }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.75 8.83333H23.6667M0.75 17.1667H23.6667M10.125 0.5L5.95833 25.5M18.4583 0.5L14.2917 25.5"
				stroke="#4B7FA3"
				stroke-width="2"
			/>
		</svg>
	);
};

export default HashtagIconSvg;

import { makeStyles } from "@material-ui/core";

const useAttachmentPreviewStyles = makeStyles(() => ({
	paper: {
		borderRadius: 8,
		maxWidth: 800,
		maxHeight: 800,
	},

	actionsContainer: {
		position: "absolute",
		top: 16,
		right: 16,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		zIndex: 99,
	},

	action: {
		width: 70,
		height: 70,
		borderRadius: 8,
		backgroundColor: "#3E4C55",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		color: "white",
		fontWeight: 500,
		marginLeft: 12,
		fontSize: 14,
		opacity: 0.6,
		cursor: "pointer",
		transition: "0.3s",
		userSelect: "none",
		"&:hover": {
			opacity: 0.9,
		},
	},

	container: {
		padding: 2,
		backgroundColor: "#C4C4C4",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: 700,
		height: 700,
	},

	image: {
		objectFit: "cover",
		width: "100%",
		height: "100%",
		borderRadius: 8,
	},
}));

export default useAttachmentPreviewStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	headerContainer: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
	},

	button: {
		width: 145,
		marginRight: 10,
	},

	rightSection: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "fit-content",
	},
}));

export default useStyles;

import { Dispatch } from "react";
import { readMessages } from ".";
import config, { chatBoxQuery, ExternalLogoUrl, selectedChatQuery } from "config";
import { StoreState } from "../../../setup/store";
import { Message } from "types/Chat/Chat";
import {
	SET_CONVERSATIONS,
	SET_CONVERSATION_MESSAGES,
	SET_UNREAD_MESSAGES,
} from "../../reducers/chat";

/** Handle emitted delete message socket emit  */
export const handleDeletedMessage = (messageId: string) => {
	return (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { conversations } = getState().chat;
		conversations.forEach((convo) => {
			convo.messages = convo.messages.filter((message) => message._id !== messageId);
		});

		dispatch({
			type: SET_CONVERSATIONS,
			payload: [...conversations],
		});
	};
};

export const handleReceivedMessage = (message: Message) => {
	return (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { selectedConvo, isChatOpen, conversations, messagesLoading } = getState().chat;
		let { unreadMessages } = getState().chat;
		const { userData } = getState().user;

		if (!userData || currentTeamId !== message.team) {
			return;
		}

		const { desktopMessagesNotifs, desktopNotificationSoundNotifs } = userData.settings || {};

		// Add the received message to unreadMessage
		const setAsUnread = () => {
			unreadMessages = { ...(unreadMessages || {}) };
			if (message.receiver) {
				unreadMessages[message.sender] = (unreadMessages[message.sender] || 0) + 1;
			} else {
				unreadMessages["general"] = (unreadMessages["general"] || 0) + 1;
			}

			dispatch({
				type: SET_UNREAD_MESSAGES,
				payload: unreadMessages,
			});
		};

		const messageReceiver = message.receiver;
		if (isChatOpen && !messagesLoading && selectedConvo) {
			let convoFound = false;
			conversations.forEach((conversation) => {
				const { receiver } = conversation;
				if (selectedConvo.receiver !== receiver) return;

				// Update the open conversation with the received message
				if (
					(receiver === message.sender && messageReceiver) ||
					(receiver === "general" && !messageReceiver)
				) {
					// Read the received message
					dispatch(readMessages(selectedConvo.receiver || "general", message._id));
					convoFound = true;
					dispatch({
						type: SET_CONVERSATION_MESSAGES,
						payload: {
							...conversation,
							messages: [message, ...conversation.messages],
						},
					});
				}
			});

			if (!convoFound) {
				setAsUnread();
			}
		}

		if (!isChatOpen) {
			setAsUnread();
			if (!desktopMessagesNotifs || desktopMessagesNotifs === "enabled") {
				const { firstName, lastName } = message.senderData || {};
				const desktopNotif = new Notification(`${firstName} ${lastName} sent a message`, {
					icon: ExternalLogoUrl,
					body: message.content,
				});

				desktopNotif.onclick = () => {
					window.open(
						`${config.clientUrl}?${chatBoxQuery}=true${`&${selectedChatQuery}=${
							messageReceiver ? message.sender : "general"
						}`}`
					);
				};
			} else if (!desktopNotificationSoundNotifs || desktopNotificationSoundNotifs === "enabled") {
				const audio = new Audio(`${window.location.hostname}/assets/message-notification.mp3`);
				audio.play();
			}
		}
	};
};

import axios from "axios";
import { Dispatch } from "react";

import { setSnackbarState } from "../snackBar";
import config from "config";
import { SubmitCampaignFunc } from "types/ReduxActions";
import {
	CREATE_CAMPAIGN_ERROR,
	CREATE_CAMPAIGN_LOADING,
	CREATE_CAMPAIGN_SUCCESS,
	LIST_CAMPAIGNS_RESULT,
} from "../../reducers/campaigns";
import { StoreState } from "../../../setup/store";
import Campaign from "types/Campaign";
import moment from "moment";

export const submitCampaign: SubmitCampaignFunc = (payload, selectedCampaignId) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { title, networks, startDate, endDate } = payload;
		const { campaigns } = getState().campaigns;

		dispatch({
			type: CREATE_CAMPAIGN_LOADING,
			payload: true,
		});

		const errors: { [index: string]: string } = {};
		if (!networks || networks.length < 1) {
			errors.networks = "Network Required";
		}

		if (!title) {
			errors.title = "Title Required";
		} else if (title.length > 50) {
			errors.title = "Title cannot exceed 50 characters";
		}

		if (endDate && startDate && moment(endDate).diff(moment(startDate)) < 0) {
			errors.endDate = "End date cannot be before start date!";
		}

		if (!startDate) {
			errors.startDate = "Start date is required";
		}

		if (!endDate) {
			errors.endDate = "End date is required";
		}

		if (Object.keys(errors).length > 0) {
			return dispatch({
				type: CREATE_CAMPAIGN_ERROR,
				payload: errors,
			});
		}

		let url = `${config.apiUrl}/campaigns?teamId=${currentTeamId}`;

		if (selectedCampaignId) {
			url = `${config.apiUrl}/campaigns/${selectedCampaignId}`;
		}

		try {
			let result: { data: Campaign } | null = null;
			if (selectedCampaignId) {
				result = await axios.put(url, payload);

				campaigns.forEach((campaign, index) => {
					if (campaign._id === selectedCampaignId) {
						if (result) {
							campaigns[index] = result.data;
						}
					}
				});

				dispatch(setSnackbarState(true, "success", "Campaign updated successfully!"));
			} else {
				result = await axios.post(url, payload);

				if (result) {
					campaigns.push(result.data);
				}
				dispatch(setSnackbarState(true, "success", "Calendar Event created successfully!"));
			}

			dispatch({
				type: CREATE_CAMPAIGN_SUCCESS,
				payload: true,
			});

			dispatch({
				type: LIST_CAMPAIGNS_RESULT,
				payload: [...campaigns],
			});
		} catch (err) {
			console.log(err);
			dispatch({
				type: CREATE_CAMPAIGN_ERROR,
				payload: err.response.data,
			});
		}
	};
};

import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserPicture from "components/Media/UserPicture";
import Task from "types/Task";
import { TeamUser } from "types/User/Team";
import useWatchTeamStyles from "../styles/watch-team";

interface Props {
	teamUser: TeamUser;
	activeTasks: Array<Task & { assignees: string[] }>;
	onClick: (user: TeamUser) => void;
}

const WatchTeamUser = (props: Props) => {
	const classes = useWatchTeamStyles();
	const { t } = useTranslation("tasks");
	const [inProgressTasksCount, setInProgressTasksCount] = useState<number>(0);
	const [finishedTasksCount, setFinishedTasksCount] = useState<number>(0);
	const { teamUser, activeTasks, onClick } = props;

	useEffect(() => {
		let inProgressTasksCount = 0;
		let finishedTasksCount = 0;

		activeTasks?.forEach((task) => {
			if (task.assignees.includes(teamUser.user!._id)) {
				if (task.status === "inProgress") {
					inProgressTasksCount++;
				} else if (task.status === "finished") {
					finishedTasksCount++;
				}
			}
		});

		setInProgressTasksCount(inProgressTasksCount);
		setFinishedTasksCount(finishedTasksCount);
	}, [activeTasks]);

	return (
		<Box onClick={() => onClick(teamUser)} className={classes.container}>
			<UserPicture user={teamUser.user} style={{ width: 35, height: 35 }} />

			<Box style={{ paddingLeft: 8 }}>
				<Box className={classes.title}>{teamUser.user!.email}</Box>

				<Box className={classes.subtitle}>{t(teamUser.role)}</Box>
			</Box>

			<Box display="flex" width="fit-content" marginLeft="auto">
				<Box style={{ paddingRight: 16, textAlign: "center" }}>
					<Box className={classes.title}>{inProgressTasksCount}</Box>

					<Box className={classes.subtitle}>{t("userInProgress")}</Box>
				</Box>

				<Box style={{ textAlign: "center" }}>
					<Box className={classes.title}>{finishedTasksCount}</Box>

					<Box className={classes.subtitle}>{t("doneFor24h")}</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default WatchTeamUser;

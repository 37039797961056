import Post from "types/Post";
import { ReduxAction } from "types/ReduxActions";

export interface PostsReducerState {
	posts: Post[];
	postsSearchResult: Post[];
	post: Post | null;

	createPostResult: { [index: string]: any } | null;
	createPostError: { [index: string]: any } | null;

	listPostsLoading: boolean;
	postsSearchLoading: boolean;
	createPostLoading: boolean;
	getPostLoading: boolean;

	scheduledPostsCount: number;
}

let initialState: PostsReducerState = {
	posts: [],
	postsSearchResult: [],
	post: null,

	createPostResult: null,
	createPostError: null,

	listPostsLoading: false,
	postsSearchLoading: false,
	createPostLoading: false,
	getPostLoading: false,

	scheduledPostsCount: 0,
};

export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";
export const CREATE_POST_LOADING = "CREATE_POST_LOADING";

export const LIST_POSTS = "LIST_POSTS";
export const LIST_POSTS_LOADING = "LIST_POSTS_LOADING";

export const LIST_POST_SEARCH_RESULT = "LIST_POST_SEARCH_RESULT";
export const POSTS_SEARCH_RESULT_LOADING = "POSTS_SEARCH_RESULT_LOADING";

export const GET_POST = "GET_POST";
export const GET_POST_LOADING = "GET_POST_LOADING";

export const SET_SCHEDULED_POSTS_COUNT = "SET_SCHEDULED_POSTS_COUNT";

export default function postsReducer(
	state: PostsReducerState = initialState,
	action: ReduxAction
): PostsReducerState {
	switch (action.type) {
		case SET_SCHEDULED_POSTS_COUNT: {
			return {
				...state,
				scheduledPostsCount: action.payload,
			};
		}
		case GET_POST: {
			return {
				...state,
				post: action.payload,
				getPostLoading: false,
			};
		}

		case GET_POST_LOADING: {
			return { ...state, getPostLoading: action.payload };
		}

		case LIST_POST_SEARCH_RESULT: {
			return {
				...state,
				postsSearchResult: action.payload,
				postsSearchLoading: false,
			};
		}

		case POSTS_SEARCH_RESULT_LOADING: {
			return { ...state, postsSearchLoading: action.payload };
		}

		case LIST_POSTS: {
			return {
				...state,
				posts: action.payload,
				listPostsLoading: false,
				postsSearchLoading: false
			};
		}

		case LIST_POSTS_LOADING: {
			return { ...state, listPostsLoading: action.payload };
		}

		case CREATE_POST_SUCCESS: {
			return {
				...state,
				createPostLoading: false,
				createPostResult: action.payload,
			};
		}

		case CREATE_POST_ERROR: {
			return {
				...state,
				createPostLoading: false,
				createPostError: action.payload,
			};
		}

		case CREATE_POST_LOADING: {
			return { ...state, createPostLoading: action.payload };
		}

		default:
			return state;
	}
}

import { Box, Tooltip } from "@material-ui/core";
import AdminIconSvg from "assets/SVG/AdminIconSvg";
import WriterIconSvg from "assets/SVG/WriterIconSvg";
import { capitalize } from "lodash";
import { CSSProperties } from "react";
import { TeamUserRole } from "types/User/Team";

interface Props {
	role: TeamUserRole;
	style?: CSSProperties;
	color?: string;
}

const UserRoleIcon: React.FC<Props> = (props) => {
	const { role, color, style } = props;

	return (
		<Tooltip title={capitalize(role)}>
			<Box style={style}>
				{role === "admin" || role === "owner" ? (
					<AdminIconSvg size={19} color={color || "#868C91"} />
				) : (
					<WriterIconSvg size={19} color={color || "#868C91"} />
				)}
			</Box>
		</Tooltip>
	);
};

export default UserRoleIcon;

import { CircularProgress, Dialog } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Basic/Button";
import useStyles from "./styles";

interface Props {
	open: boolean;
	image?: string;
	title?: string;
	message: ReactNode;
	closeButton?: string;
	confirmButton?: string;
	middleButton?: string;
	actionLoading?: boolean;
	middleActionLoading?: boolean;
	onMiddleButtonClick?: () => void;
	onClose: () => void;
	onAction?: () => void;
	hideButtons?: boolean;
	autoClose?: boolean;
	textMaxWidth?: number;
	messageStyle?: CSSProperties;
	titleStyle?: CSSProperties;
	imageStyle?: CSSProperties;
}

const ConfirmationDialog = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		open,
		image,
		title,
		message,
		closeButton,
		confirmButton,
		middleButton,
		actionLoading,
		middleActionLoading,
		hideButtons,
		autoClose,
		textMaxWidth,
		messageStyle,
		imageStyle,
		titleStyle,
		onMiddleButtonClick,
		onClose,
		onAction,
	} = props;

	useEffect(() => {
		if (open && autoClose) {
			setTimeout(() => {
				onClose();
			}, 5000);
		}
	}, [open]);

	return (
		<Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
			<div className={classes.container}>
				{image && <img src={image} style={{ ...imageStyle }} className={classes.image} />}

				{Boolean(title) && (
					<span
						style={{ maxWidth: textMaxWidth || "", fontWeight: 600, ...titleStyle }}
						className={classes.message}
					>
						{title}
					</span>
				)}
				<span style={{ maxWidth: textMaxWidth || "", ...messageStyle }} className={classes.message}>
					{message}
				</span>

				{!hideButtons && (
					<div className={classes.buttonsContainer}>
						{onAction && (
							<Button
								onClick={onClose}
								disabled={actionLoading || middleActionLoading}
								style={{ marginRight: 16, minWidth: 180, width: "fit-content" }}
								secondary={true}
							>
								{closeButton || t("cancel")}
							</Button>
						)}

						{middleButton && (
							<Button
								secondary
								disabled={actionLoading || middleActionLoading}
								style={{ marginRight: 16, minWidth: 180, width: "fit-content" }}
								onClick={onMiddleButtonClick}
							>
								{middleActionLoading ? (
									<CircularProgress color="primary" size={20} />
								) : (
									middleButton || t("confirm")
								)}
							</Button>
						)}

						<Button
							onClick={() => (onAction ? onAction() : onClose())}
							disabled={actionLoading || middleActionLoading}
							style={{ minWidth: 180, width: "fit-content" }}
						>
							{actionLoading ? (
								<CircularProgress color="primary" size={20} />
							) : confirmButton || onAction ? (
								t("confirm")
							) : (
								t("ok")
							)}
						</Button>
					</div>
				)}
			</div>
		</Dialog>
	);
};

export default ConfirmationDialog;

import { useLocation } from "react-router";
import config, { AuthProviderLsKey } from "config";
import theme from "setup/Theme";

export const nameRegex = /^([A-Za-zéàzâáçéèêëîïôûùüÿñæœ\-\_]{2,40} ?)+$/;

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export const updateQueryParam = (name: string, value: string) => {
	const url = new URL(window.location.href);
	url.searchParams.set(name, value);
	window.history.replaceState(null, "", url);

	// popstate event is triggered in order for an 'onpopstate' window event listener to recognize it
	const event = new Event("popstate");
	window.dispatchEvent(event);
};

export const deleteQueryParam = (name: string) => {
	const url = new URL(window.location.href);
	url.searchParams.delete(name);
	window.history.replaceState(null, "", url);
	
	const event = new Event("popstate");
	window.dispatchEvent(event);
};

// Retrieve the value of a query parameter using its name
export const getQueryParam = (name: string) => {
	const url = new URL(window.location.href);
	return url.searchParams.get(name);
};

export const validateCharCase = (pwd: string) => {
	var regex = /[a-z].*[A-Z]|[A-Z].*[a-z]/;
	var valid = regex.test(pwd);
	return valid;
};

export const validateDigits = (pwd: string) => {
	var regex = /\d/;
	var valid = regex.test(pwd);
	return valid;
};

export const validatePassword = (pwd: string) => {
	return validateDigits(pwd) && validateCharCase(pwd);
};

export const validateEmail = (email: string) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const numberWithCommas = (x: number) => {
	// return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	return x.toLocaleString(undefined, {
		minimumFractionDigits: 2,
	});
};

export const numberWithSpaces = (x: number) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const getAccessToken = () => {
	let accessToken = "";

	Object.keys(localStorage).map((key: string) => {
		if (key.endsWith("accessToken")) {
			accessToken = localStorage.getItem(key) as string;
		}
	});

	return accessToken;
};

export const getHeaders = () => {
	let headers: { [index: string]: string } = {};
	let accessToken = "";
	let idToken = "";

	Object.keys(localStorage).forEach((key: string) => {
		if (key.endsWith("accessToken")) {
			accessToken = localStorage.getItem(key) || "";
		} else if (key.endsWith("idToken")) {
			idToken = localStorage.getItem(key) || "";
		}
	});

	headers.Authorization = `Bearer ${accessToken}, IDToken ${idToken}`;

	return headers;
};

export const getLastAuthUser = () => {
	let username: string | null = null;
	Object.keys(localStorage).forEach((key) => {
		if (key.endsWith("LastAuthUser")) {
			username = localStorage.getItem(key);
		}
	});
	return username;
};

export const getCognitoClientId = (provider?: string) => {
	let clientId = "";
	if (!provider) provider = localStorage.getItem(AuthProviderLsKey) as string;

	const { GoogleCognitoClientId, FacebookCognitoClientId, CognitoClientId } = config;

	if (provider === "Google") clientId = GoogleCognitoClientId;
	else if (provider === "Facebook") clientId = FacebookCognitoClientId;
	else {
		clientId = CognitoClientId;
	}

	return clientId;
};

export const getImage = (key: string) => {
	console.log(key)
	if(key.startsWith('https')) {
		return key;
	};

	const apiUrl = config.apiUrl + "/images/";
	return apiUrl + key;
};

export const getVideo = (key: string) => {
	const apiUrl = config.apiUrl + "/videos/";
	return apiUrl + key;
};

export const toBase64 = (file: File | string) =>
	new Promise((resolve, reject) => {
		if (typeof file === "string") {
			const imageUrl = getImage(file);
			var xhr = new XMLHttpRequest();
			xhr.onload = function () {
				var reader = new FileReader();
				reader.onloadend = function () {
					resolve(reader.result);
				};
				reader.readAsDataURL(xhr.response);
			};
			xhr.open("GET", imageUrl);
			xhr.responseType = "blob";
			xhr.send();
		} else {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		}
	});

export const base64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getVideoThumbnail = (file: File, seekTo = 1) => {
	return new Promise((resolve, reject) => {
		// load the file to a video player
		const videoPlayer = document.createElement("video");
		videoPlayer.setAttribute("src", URL.createObjectURL(file));
		videoPlayer.load();
		videoPlayer.addEventListener("error", () => {
			reject("error when loading video file");
		});
		// load metadata of the video to get video duration and dimensions
		videoPlayer.addEventListener("loadedmetadata", () => {
			// seek to user defined timestamp (in seconds) if possible
			if (videoPlayer.duration < seekTo) {
				reject("video is too short.");
				return;
			}
			// delay seeking or else 'seeked' event won't fire on Safari
			setTimeout(() => {
				videoPlayer.currentTime = seekTo;
			}, 200);
			// extract video thumbnail once seeking is complete
			videoPlayer.addEventListener("seeked", () => {
				// define a canvas to have the same dimension as the video
				const canvas = document.createElement("canvas");
				canvas.width = videoPlayer.videoWidth;
				canvas.height = videoPlayer.videoHeight;
				// draw the video frame to canvas
				const ctx = canvas.getContext("2d");
				if (ctx) {
					ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
					// return the canvas image as a blob
					ctx.canvas.toBlob(
						(blob) => {
							resolve(blob);
						},
						"image/jpeg",
						0.75 /* quality */
					);
				}
			});
		});
	});
};

export const loadVideo = (file: File) =>
	new Promise((resolve, reject) => {
		try {
			let video = document.createElement("video");
			video.preload = "metadata";

			video.onloadedmetadata = function () {
				resolve(this);
			};

			video.onerror = function () {
				reject("Invalid video. Please select a video file.");
			};

			video.src = window.URL.createObjectURL(file);
		} catch (e) {
			reject(e);
		}
	});

export const abbreviateNumber = (value: number) => {
	let newValue = "";

	if (value >= 1000) {
		newValue = `${(value / 1000).toFixed(1)}K`;
	}
	if (value >= 1000000) {
		newValue = `${(value / 1000000).toFixed(1)}M`;
	}
	return newValue;
};

export const getDragActiveStyles = (isDragActive: boolean) => {
	if (!isDragActive) {
		return {};
	} else {
		return {
			outline: isDragActive ? `2px solid ${theme.palette.primary.main}` : "",
			backgroundColor: isDragActive ? theme.palette.primary.main + "1A" : "",
		};
	}
};

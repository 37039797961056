import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import MetaTagsHeader from "../../components/Navigation/Components/MetaTags";
import Container from "../../components/Navigation/Container";
import { useQuery } from "helpers/utils";
import { acceptTeamInvitation } from "redux/actions/team/users";
import AccountSettings from "./Components/AccountSettings/AccountSettings";
import FrequentlyAsked from "./Components/FrequentlyAsked";
import Header from "./Components/Header";
import Notifications from "./Components/NotificationSettings";
import SocialProfiles from "./Components/SocialProfiles/SocialProfiles";
import TimeSettings from "./Components/TimeSettings/index";
import Workspaces from "./Components/Workspaces";
import usePageStyles from "./page-styles";
import { StoreState } from "setup/store";
import BillingAndPaymentsSettings from "./Components/BillingAndPayments/BillingAndPaymentsSettings";
import { teamInviteQuery } from "config";
import { useTranslation } from "react-i18next";

export type SettingsTab =
	| "myAccount"
	| "socialProfiles"
	| "workspaces"
	| "billingAndPayments"
	| "notifications"
	| "localization"
	| "faq";

export const selectedSettingTabQuery = "setting";

const SettingsPage = () => {
	const classes = usePageStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const { t } = useTranslation("settings");
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const selectedTab = query.get(selectedSettingTabQuery);

	useEffect(() => {
		if (query.get("teamInvite")) {
			dispatch(
				acceptTeamInvitation(query.get(teamInviteQuery)!, () => {
					history.push(getSettingPageRoute('workspaces'));
				})
			);
		}
	}, [userData]);

	return (
		<Container page={t("settings")}>
			<MetaTagsHeader title={`${t("settings")} | HashtagHero`} />
			<div className={classes.pageContainer}>
				<div className={classes.innerContainer}>
					<Header />

					{(selectedTab === "myAccount" || !selectedTab) && <AccountSettings />}
					{selectedTab === "socialProfiles" && <SocialProfiles />}
					{selectedTab === "workspaces" && <Workspaces />}
					{selectedTab === "billingAndPayments" && currentTeamRole === "owner" && (
						<BillingAndPaymentsSettings />
					)}
					{selectedTab === "notifications" && <Notifications />}
					{selectedTab === "localization" && <TimeSettings />}
					{selectedTab === "faq" && <FrequentlyAsked />}
				</div>
			</div>
		</Container>
	);
};

export const getSettingPageRoute = (setting: SettingsTab, view?: "plans" | 'edit' | 'overview') => {
	let route = `/settings?${selectedSettingTabQuery}=${setting}`;

	if (view) {
		route += `&view=${view}`;
	}

	return route;
};

export default SettingsPage;

import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listTasks, TasksFilters } from "redux/actions/tasks/get";
import { StoreState } from "../../../setup/store";
import TaskType, { TaskStatus } from "types/Task";
import usePageStyles from "../styles";
import TabsNew, { Tab } from "components/TabsNew";
import moment from "moment";
import Tasks from "./Tabs/Tasks";
import WatchTeam from "./Tabs/WatchTeam";
import clsx from "clsx";
import AddTaskButton from "modules/Tasks/AddTaskButton";
import { setTaskDialog } from "redux/actions/modals";

export type TasksTab = "inProgress" | "newTasks" | "finished" | "watchTeam";

const TasksCard = () => {
	const classes = usePageStyles();
	const [selectedTab, setSelectedTab] = useState<TasksTab>("newTasks");
	const [isAddButtonVisible, setIsAddButtonVisible] = useState<boolean>(true);
	const { tasks } = useSelector((state: StoreState) => state.tasks);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const dispatch = useDispatch();

	const handleAddClick = () => {
		dispatch(setTaskDialog(true, undefined, () => setSelectedTab("newTasks")));
	};

	const handleTaskClick = (task: TaskType) => {
		dispatch(setTaskDialog(true, task));
	};

	const fetchTasks = (next?: boolean) => {
		if (!currentTeamId) return;
		let filters: TasksFilters = {
			pageLimit: 20,
		};

		if (selectedTab === "newTasks") {
			const startDate = moment().hours(0).minutes(0).seconds(0).toISOString();
			const endDate = moment().hours(24).minutes(60).seconds(60).toISOString();

			filters = {
				...filters,
				startDate,
				endDate,
				status: "inProgress",
			};
		} else if (selectedTab === "finished" || selectedTab === "inProgress") {
			filters = {
				...filters,
				status: selectedTab as TaskStatus,
			};
		}

		if (next) {
			filters.from = tasks.length;
		}

		if (selectedTab !== "watchTeam") {
			setIsAddButtonVisible(true);
			dispatch(listTasks(false, filters));
		}
	};

	useEffect(() => {
		fetchTasks();
	}, [currentTeamId, selectedTab]);

	return (
		<Box style={{ paddingTop: 32, width: "66%" }}>
			<Box display="flex" alignItems="center">
				<TabsNew
					style={{ marginLeft: 32 }}
					tabs={["newTasks", "inProgress", "finished"]}
					selectedTab={selectedTab}
					setSelectedTab={(tab) => setSelectedTab(tab as TasksTab)}
				/>

				<Box width="fit-content" marginLeft="auto">
					<Tab
						tab={"watchTeam"}
						selected={selectedTab === "watchTeam"}
						onClick={() => setSelectedTab("watchTeam")}
					/>
				</Box>
			</Box>

			<Box
				style={{
					overflowY: selectedTab === "watchTeam" ? "scroll" : "initial",
				}}
				className={clsx(classes.card, classes.tasksCard)}
			>
				{isAddButtonVisible && selectedTab === "watchTeam" && (
					<AddTaskButton onAddClick={handleAddClick} />
				)}

				{selectedTab !== "watchTeam" && (
					<Tasks
						selectedTab={selectedTab}
						fetchMore={() => fetchTasks(true)}
						onTaskClick={handleTaskClick}
						onAddClick={handleAddClick}
					/>
				)}

				{selectedTab === "watchTeam" && <WatchTeam setIsAddButtonVisible={setIsAddButtonVisible} />}
			</Box>
		</Box>
	);
};

export default TasksCard;

import { IconButton, makeStyles, Popover } from "@material-ui/core";
import QuestionMarkIconSvg from "assets/SVG/QuestionMarkIconSvg";
import { CSSProperties, useState } from "react";

interface Props {
	style: CSSProperties;
}

const useStyles = makeStyles(() => ({
	popoverPaper: {
		backgroundColor: "white",
		padding: 16,
        maxWidth: 350,
        whiteSpace: 'pre-line'
	},
}));

const MoreInfoButton: React.FC<Props> = (props) => {
	const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
	const classes = useStyles();
	const { style, children } = props;

	return (
		<>
			<Popover
				classes={{ paper: classes.popoverPaper }}
				open={Boolean(popoverAnchorEl)}
				anchorEl={popoverAnchorEl}
				onClose={() => setPopoverAnchorEl(null)}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				{children}
			</Popover>
			<IconButton
				onClick={(e) => setPopoverAnchorEl(e.currentTarget)}
				style={{ ...style, padding: 4 }}
			>
				<QuestionMarkIconSvg size={14} />
			</IconButton>
		</>
	);
};

export default MoreInfoButton;

import { useState, useEffect } from "react";
import Container from "components/Navigation/Container";
import { ContentType } from "types/Content";
import Header from "./Components/Header";
import CreatorSection from "./Components/CreatorSection";
import useStyles from "./styles";
import PreviewSection from "./Components/PreviewSection";
import SocialProfile, { ProfileType } from "types/SocialProfile";
import { PostPayload } from "types/Post";
import Network from "types/Network";
import { Tab } from "components/Tabs";
import { Prompt, useHistory, useParams } from "react-router";
import { PostConfirmImage, SaveScheduleConfirm } from "assets";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import MetaTagsHeader from "components/Navigation/Components/MetaTags";
import { useDispatch, useSelector } from "react-redux";
import { createPost } from "redux/actions/posts/create";
import { StoreState } from "../../../setup/store";
import { getPostById } from "redux/actions/posts/get";
import { Box, CircularProgress } from "@material-ui/core";
import { updatePost } from "redux/actions/posts/update";
import { GET_POST } from "redux/reducers/posting";
import { useBeforeunload } from "react-beforeunload";
import { FileUploadProgress } from "redux/actions/media/upload";
import { useTranslation } from "react-i18next";
import Task, { Media } from "types/Task";
import HashtagsDrawer from "./Components/HashtagsDrawer/HashtagsDrawer";
import { Hashtag } from "redux/reducers/stats";

export type PostCreatorInitialState = {
	content: string;
	media: Media[];
	socialProfiles: SocialProfile[];
	task: Task;
};
interface Props {
	initialState?: Partial<PostCreatorInitialState>;
}

const PostEditorPage = (props: Props) => {
	const { initialState } = props;
	const [selectedContentType, setSelectedContentType] = useState<ContentType>("post");
	const [publishNowDialog, setPublishNowDialog] = useState<boolean>(false);
	const [saveToScheduleDialog, setSaveToScheduleDialog] = useState<boolean>(false);
	const [filesUploadProgress, setFilesUploadProgress] = useState<FileUploadProgress[]>([]);
	const [postContent, setPostContent] = useState<string>("");
	let [postPayload, setPostPayload] = useState<PostPayload>({});
	const [selectedPreview, setSelectedPreview] = useState<Tab>();
	const [availablePreviews, setAvailablePreviews] = useState<Network[]>([]);
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [editPost, setEditPost] = useState<boolean>(false);
	const [isHashtagsDrawerOpen, setIsHashtagsDrawerOpen] = useState(false);
	const { createPostLoading, getPostLoading, post } = useSelector(
		(state: StoreState) => state.posts
	);
	const { currentTeamRole, currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { post_id } = useParams<Record<string, string | undefined>>();
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation("posts");

	const handleUploadProgressChange = (newProgress: FileUploadProgress) => {
		let fileFound = false;

		filesUploadProgress.forEach((fileProgress, index) => {
			if (
				fileProgress.fileType === newProgress.fileType &&
				fileProgress.index === newProgress.index
			) {
				filesUploadProgress[index] = { ...newProgress };
				fileFound = true;
			}
		});

		if (!fileFound) {
			filesUploadProgress.push(newProgress);
		}

		setFilesUploadProgress([...filesUploadProgress]);
	};

	const handleSubmit = (publishNow?: boolean, draft?: boolean) => {
		if (!publishNowDialog && publishNow) {
			setPublishNowDialog(true);
		} else {
			if (!postPayload || !postPayload.content) return;
			let profiles: { type: ProfileType; _id: string }[] = [];

			const { socialProfiles, content, schedule, media, campaign, task } = postPayload;

			if (!socialProfiles?.length) return;

			socialProfiles?.forEach((profile) => {
				if (!profile._id || !profile.type) return;
				profiles.push({ type: profile.type, _id: profile._id });
			});

			const requestData = {
				content,
				media,
				draft,
				publishNow,
				schedule: !publishNow && !draft ? schedule : undefined,
				campaign,
				task: task?._id || null,
			};

			const updateRoute = () => {
				setSubmitted(true);

				setTimeout(() => {
					if (draft) {
						history.push("/posts/drafts");
					} else if (currentTeamRole === "writer") {
						history.push("/posts/unapproved");
					} else {
						history.push("/posts/schedule");
					}
				}, 50);
			};

			if (editPost && post) {
				dispatch(
					updatePost(
						post._id || "",
						profiles,
						{ ...requestData },
						handleUploadProgressChange,
						() => {
							updateRoute();
						}
					)
				);
				setPublishNowDialog(false);
			} else {
				dispatch(
					createPost(profiles, requestData, handleUploadProgressChange, () => {
						updateRoute();
					})
				);
				setPublishNowDialog(false);
			}
		}
	};

	const handleHashtagClick = (hashtag: Hashtag) => {
		const updatedContent = postContent + " " + hashtag.name;

		setPostContent(updatedContent);
		setPostPayload({ ...postPayload, content: updatedContent });
	};

	useBeforeunload((event) => {
		if (createPostLoading || Boolean(postPayload)) {
			event.preventDefault();
		}
	});

	// Update post content depending on whether a new one is being created or existing one is being edited
	useEffect(() => {
		if (!currentTeamId) {
			return;
		}

		if (post_id) {
			setEditPost(true);
			dispatch(
				getPostById(post_id, (post) => {
					if (post) {
						setPostContent(post.content || '');
						setPostPayload({
							content: post.content,
							socialProfiles: post.socialProfiles,
							media: post.media,
							schedule: post.scheduledAt,
							task: post.task,
							campaign: typeof post.campaign !== "string" ? post.campaign?._id : undefined,
						});
					}
				})
			);
		} else {
			if (!initialState) {
				setAvailablePreviews([]);
			}
			setPostContent(initialState?.content || "");
			setPostPayload({ ...(initialState || {}) });
			setSelectedPreview(undefined);
			setEditPost(false);
			dispatch({ type: GET_POST, payload: null });
		}
	}, [post_id, currentTeamId]);

	useEffect(() => {
		return function cleanup() {
			setFilesUploadProgress([]);
			setSubmitted(false);
		};
	}, []);

	return (
		<Container page={t("newPostCreation")}>
			<Prompt
				when={(createPostLoading || Boolean(postPayload)) && !submitted}
				message={!createPostLoading ? t("unsavedChangesWarning") : t("unloadPrompt")}
			/>
			<MetaTagsHeader title={`Posts - ${post ? t("editPost") : t("newPost")}`} />
			<ConfirmationDialog
				open={publishNowDialog}
				actionLoading={createPostLoading}
				message={t("publishNowConfirmation")}
				onClose={() => setPublishNowDialog(false)}
				onAction={() => handleSubmit(true)}
				confirmButton={t("publishNowConfirmationButton")}
				image={PostConfirmImage}
			/>

			<ConfirmationDialog
				open={saveToScheduleDialog}
				message={t("savingToSchedule")}
				image={SaveScheduleConfirm}
				onClose={() => setSaveToScheduleDialog(false)}
				autoClose={true}
				hideButtons={true}
			/>

			{getPostLoading && (
				<Box className={classes.loadingContainer}>
					<CircularProgress size={25} />
				</Box>
			)}

			{!getPostLoading && (
				<Box className={classes.pageContainer}>
					<Box className={classes.innerContainer}>
						<Header
							selectedContentType={selectedContentType}
							setSelectedContentType={setSelectedContentType}
							editPost={editPost}
						/>

						<Box className={classes.linearContainer}>
							<CreatorSection
								handleSubmit={handleSubmit}
								selectedContentType={selectedContentType}
								postPayload={postPayload}
								postContent={postContent}
								setPostContent={setPostContent}
								setPostPayload={setPostPayload}
								isHashtagsDrawerOpen={isHashtagsDrawerOpen}
								setIsHashtagsDrawerOpen={setIsHashtagsDrawerOpen}
								filesUploadProgress={filesUploadProgress}
							/>

							<PreviewSection
								selectedContentType={selectedContentType}
								selectedPreview={selectedPreview}
								postPayload={postPayload}
								setSelectedPreview={setSelectedPreview}
								setAvailablePreviews={setAvailablePreviews}
								availablePreviews={availablePreviews}
							/>
						</Box>
					</Box>
				</Box>
			)}

			<HashtagsDrawer
				open={isHashtagsDrawerOpen}
				onHashtagClick={handleHashtagClick}
				onClose={() => setIsHashtagsDrawerOpen(false)}
			/>
		</Container>
	);
};

export default PostEditorPage;

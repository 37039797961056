const CalendarIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || 25}
			height={size || 25}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path d="M15.9987 14.666H9.33203V21.3327H15.9987V14.666Z" fill={color || "#4B7FA3"} />
				<path
					d="M25.3334 5.3327H24V2.66602H21.3334V5.3327H10.6667V2.66602H8V5.3327H6.66669C5.19337 5.3327 4.01338 6.52601 4.01338 7.99939L4 26.666C4 28.1393 5.19331 29.3327 6.66669 29.3327H25.3334C26.8067 29.3327 28 28.1394 28 26.666V7.99932C28 6.52601 26.8067 5.3327 25.3334 5.3327ZM25.3334 26.666H6.66669V11.9993H25.3334V26.666Z"
					fill={color || "#4B7FA3"}
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect
						width="26.6667"
						height="26.6667"
						fill="white"
						transform="translate(2.66797 2.66602)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default CalendarIconSvg;

import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UserPicture from "components/Media/UserPicture";
import { StoreState } from "../../../../setup/store";
import { TeamUser } from "types/User/Team";
import ProductivityGraph from "../../ProductivityGraph";
import useWatchTeamStyles from "../../styles/watch-team";

interface Props {
	teamUser: TeamUser;
	onClose: () => void;
}

const WatchTeamUserDetailsHeader = (props: Props) => {
	const { teamUser, onClose } = props;
	const { productivity } = useSelector((state: StoreState) => state.tasks);
	const classes = useWatchTeamStyles();
	const { t } = useTranslation("tasks");

	return (
		<Box style={{ paddingLeft: 8 }}>
			<Box onClick={onClose} className={classes.sectionLabel}>
				{t("watchTeamUserBack")}
			</Box>

			<Box display="flex" alignItems="center" style={{ height: 100, marginTop: -16 }}>
				{/* User Info */}
				<Box style={{ marginTop: 24, marginLeft: 12 }} display="flex" alignItems="center">
					<UserPicture user={teamUser.user} style={{ width: 35, height: 35 }} />

					<Box>
						<Box style={{ fontSize: 16 }} className={classes.title}>
							{teamUser.user?.email}
						</Box>

						<Box className={classes.subtitle}>{t(teamUser.role)}</Box>
					</Box>
				</Box>

				{/* TODO: Show no data message when graph is empty */}
				{/* Productivity Graph */}
				<ProductivityGraph
					tasks={productivity.filter((task) => task.assignees.includes(teamUser.user!._id))}
				/>
			</Box>
		</Box>
	);
};

export default WatchTeamUserDetailsHeader;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	paper: {
		background: "#FFFFFF",
		padding: 32,
		paddingLeft: 0,
		paddingRight: 0,
	},
	dialogContainer: {
		width: 600,
		height: 700,
		paddingLeft: 32,
		paddingRight: 32,
		flexDirection: "column",
		display: "flex",
		background: "#FFFFFF",
	},

	header: {
		display: "flex",
		flexDirection: "row",
	},

	dialogTitle: {
		color: "#5B666E",
		letterSpacing: 0.5,
		fontWeight: 700,
		fontSize: 18,
	},

	reminder: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: 8,
		transition: "0.3s",
		cursor: "pointer",
		borderRadius: 4,
		"&:hover": {
			backgroundColor: "#e0e0e0",
		},
	},

	reminderText: {
		color: theme.palette.text.primary,
		fontSize: 12,
		marginLeft: 8,
	},

	form: {
		marginTop: 16,
		display: "flex",
		flexDirection: "column",
		width: "100%",
	},

	flexContainer: {
		display: "flex",
		flexDirection: "row",
		marginTop: 4,
	},

	inputTitle: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		letterSpacing: 0.4,
	},

	inputContainer: {
		marginTop: 16,
	},

	markAsDraft: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 24,
	},

	draftText: {
		color: theme.palette.text.primary,
		marginRight: 8,
		fontSize: 13,
		fontFamily: "Avenir",
	},

	networksContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 8,
	},

	iconContainer: {
		marginRight: 26,
		cursor: "pointer",
	},

	bottomButtons: {
		width: "90%",
		marginTop: "auto",
		display: "flex",
		flexDirection: "row",
		position: "absolute",
		bottom: 32,
		right: 32,
	},

	rightButtons: {
		marginLeft: "auto",
		width: "fit-content",
		display: "flex",
		alignItems: "center",
	},

	error: {
		whiteSpace: "pre-line",
		color: theme.palette.primary.main,
		fontSize: 12,
		marginTop: 6,
	},
}));

export default useStyles;

import { Switch, Route } from "react-router-dom";
import HomePage from "pages/Home";

import CalendarPage from "pages/Calendar";
import PostingSchedule from "pages/Posting/PostingSchedule";
import PostEditorPage, { PostCreatorInitialState } from "pages/Posting/Creator";
import PostsArchive from "pages/Posting/Archive";
import DraftsPage from "pages/Posting/Drafts";
import ListenPage from "pages/Listen";
import ReplyPage from "pages/Reply";
import SettingsPage from "pages/Settings";
import AnalyticsPage from "pages/Analytics";
import NotificationsPage from "pages/Notifications";
import AuthPage from "pages/Auth";
import PasswordRecovery from "pages/Auth/PasswordRecovery";
import UnapprovedPostsPage from "pages/Posting/Unapproved/Unapproved";
import EmailVerificationStep from "pages/Auth/SignUp/Step2";
import UserInfoStep from "pages/Auth/SignUp/Step3";
import ProfilePhotoStep from "pages/Auth/SignUp/Step4";
import WelcomePage from "pages/Auth/SignUp/WelcomePage";
import PrivacyPage from "pages/Privacy";

export default function Router() {
	return (
		<Switch>
			<Route exact path="/" component={HomePage} />
			<Route exact path="/home" component={HomePage} />
			<Route exact path="/calendar" component={CalendarPage} />

			{/* Posting */}
			<Route exact path="/posts/schedule" component={PostingSchedule} />
			<Route
				exact
				path="/posts/new"
				render={(props) => (
					<PostEditorPage initialState={props.location.state as Partial<PostCreatorInitialState>} />
				)}
			/>
			<Route exact path="/posts/edit/:post_id" component={PostEditorPage} />
			<Route exact path="/posts/archive" component={PostsArchive} />
			<Route exact path="/posts/drafts" component={DraftsPage} />
			<Route exact path="/posts/unapproved" component={UnapprovedPostsPage} />

			{/* Auth */}
			<Route exact path="/login" component={AuthPage} />
			<Route exact path="/signup" component={AuthPage} />
			<Route exact path="/signup/verification" component={EmailVerificationStep} />
			<Route exact path="/signup/general-info" component={UserInfoStep} />
			<Route exact path="/signup/profile-picture" component={ProfilePhotoStep} />
			<Route exact path="/signup/welcome" component={WelcomePage} />
			<Route exact path="/recovery" component={PasswordRecovery} />

			{/* Listen */}
			<Route exact path="/listen" component={ListenPage} />

			{/* Reply */}
			<Route exact path="/reply" component={ReplyPage} />

			{/* Analytics */}
			<Route exact path="/analytics" component={AnalyticsPage} />

			{/* Settings */}
			<Route exact path="/settings" component={SettingsPage} />

			{/* Notifications */}
			<Route exact path="/notifications" component={NotificationsPage} />

			<Route exact path="/privacy" component={PrivacyPage} />
		</Switch>
	);
}

import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { notificationTabs } from "..";
import Button from "components/Basic/Button";
import DateInput from "components/Basic/Inputs/DateInput";
import useNotificationsStyles from "../styles";

interface Props {
	selectedTab: string;
	setSelectedTab: Dispatch<SetStateAction<string>>;
	selectedDate: string;
	setSelectedDate: Dispatch<SetStateAction<string>>;
}

const NotificationsHeader = (props: Props) => {
	const { selectedTab, setSelectedTab, selectedDate, setSelectedDate } = props;
	const classes = useNotificationsStyles();

	return (
		<Box className={classes.header}>
			{notificationTabs.map((tab, index) => (
				<Button
					key={index}
					selected={selectedTab === tab.value}
					onClick={() => setSelectedTab(tab.value)}
					secondary
					style={{ marginRight: 16, width: 120 }}
				>
					{tab.label}
				</Button>
			))}

			<DateInput
				allowPastDate={true}
				hideTimePicker={true}
				value={selectedDate}
				onChange={setSelectedDate}
			/>
		</Box>
	);
};

export default NotificationsHeader;

import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import Interaction from "types/Interaction";
import SocialProfile from "types/SocialProfile";
import useReplyDialogStyles from "../styles";

interface Props {
	interaction: Interaction;
	socialProfile: SocialProfile;
}
const ReplyInputHeader: React.FC<Props> = (props) => {
	const classes = useReplyDialogStyles();
	const { interaction, socialProfile } = props;

	const handleGoToOriginalClick = () => {
		const { network } = socialProfile;

		let url = "";

		if (interaction.type === "comment") {
			if (network === "instagram" || network === "facebook") {
				url = interaction.post.url || "";
			} else if (network === "twitter") {
				url = `https://twitter.com/${interaction.from.username}/status/${interaction.socialId}`;
			}
		}

		if (url) {
			window.open(url, "_blank");
		}
	};

	return (
		<div className={classes.linearContainer}>
			<NetworkIcon size={24} network={socialProfile.network} />
			<SocialProfilePicture style={{ marginLeft: 8 }} profile={socialProfile} />
			<div className={classes.postUsername}>
				{socialProfile.displayName || socialProfile.username}
			</div>

			{interaction.type !== "message" && (
				<div onClick={handleGoToOriginalClick} className={classes.originalInteractionBox}>
					Go to Original&nbsp;{" "}
					<span style={{ textTransform: "capitalize" }}>{interaction.type}</span>
				</div>
			)}
		</div>
	);
};

export default ReplyInputHeader;

import { Box, IconButton, makeStyles } from "@material-ui/core";
import { ChangeEvent, CSSProperties, useEffect, useRef } from "react";
import SearchIconSvg from "assets/SVG/SearchIconSvg";
import CloseIconSvg from "assets/SVG/CloseIconSvg";

const useStyles = makeStyles((theme) => ({
	container: {
		border: `1px solid ${theme.palette.secondary.main}`,
		width: 230,
		height: 35,
		borderRadius: 8,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	input: {
		color: "#3E4C55",
		opacity: "0.8",
		border: "none",
		backgroundColor: "transparent",
		height: 36,
		width: "calc(100% - 20px)",
		"&:focus": {
			outline: "none",
		},
		paddingLeft: 16,
		fontFamily: "Avenir",
	},

	searchIcon: {
		marginLeft: "auto",
		paddingRight: 16,
		width: "fit-content",
		marginBottom: -4,
	},
}));

interface Props {
	placeholder?: string;
	style?: CSSProperties;
	inputStyle?: CSSProperties;
	iconStyle?: CSSProperties;
	iconSize?: number;
	iconColor?: string;
	value?: string;
	autoFocus?: boolean;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SearchInput = (props: Props) => {
	const classes = useStyles();
	const inputRef = useRef<HTMLInputElement>(null);
	const {
		placeholder,
		style,
		inputStyle,
		iconStyle,
		iconColor,
		iconSize,
		value,
		autoFocus,
		onChange,
	} = props;

	useEffect(() => {
		if (autoFocus) {
			inputRef.current?.focus();
		}
	}, [autoFocus]);

	return (
		<Box style={{ ...style }} className={classes.container}>
			<input
				ref={inputRef}
				style={inputStyle}
				value={value || ""}
				onChange={onChange}
				className={classes.input}
				placeholder={placeholder || "Search"}
			/>

			{!value && (
				<Box style={iconStyle} className={classes.searchIcon}>
					<SearchIconSvg size={iconSize || 16} color={iconColor} />
				</Box>
			)}

			{value && (
				<Box width="fit-content" marginLeft="auto" marginRight={1}>
					<IconButton
						onClick={() =>
							onChange?.({ target: { value: "" } } as unknown as ChangeEvent<HTMLInputElement>)
						}
						style={{ padding: 6 }}
					>
						<CloseIconSvg size={12} />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

export default SearchInput;

import { CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import axios from "axios";
import { getCognitoClientId, getLastAuthUser } from "helpers/utils";
import store from "./store";
import config from "config";
import { Dispatch } from "react";
import { setCurrentTeamId } from "redux/actions/team/post";
import { dispatch } from "d3";
import { LIST_SOCIAL_PROFILES } from "redux/reducers/socialProfiles";
import { setSnackbarState } from "redux/actions/snackBar";

const { UserPoolId } = config;

export const setupAuthInterceptor = () => {
	const interceptor = axios.interceptors.request.use(async (config) => {
		const { userData } = store.getState().user;

		const username = userData?.username || getLastAuthUser();
		return new Promise((resolve) => {
			if (username) {
				const clientId = getCognitoClientId(localStorage.getItem("AuthProvider") || "");

				const poolData = {
					ClientId: clientId,
					UserPoolId: UserPoolId,
				};
				const userPool = new CognitoUserPool(poolData);

				const cognitoUser = new CognitoUser({
					Username: username,
					Pool: userPool,
				});

				cognitoUser.getSession((error: any, session: CognitoUserSession) => {
					if (!session || error) {
						resolve(config);
					}

					let accessToken = session.getAccessToken().getJwtToken();
					let idToken = session.getIdToken().getJwtToken();

					config.headers.Authorization = `Bearer ${accessToken}, IDToken ${idToken}`;
					resolve(config);
				});
			} else {
				console.log("NO TOKEN");
				resolve(config);
			}
		});
	});

	return interceptor;
};

/** If a "403 RestrictedTeamAccess" error is returned, select another team that the user is a member of */
export const setupResponseInterceptor = () => {
	const interceptor = axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			const dispatch = store.dispatch as Dispatch<any>;

			if (error.response?.data?.error === "RestrictedTeamAccess") {
				const { teams } = store.getState().teams;
				const { userData } = store.getState().user;

				// select any other team than the ones that the user is restricted in
				for (const team of teams) {
					if (!team.users.find((user) => user.user?._id === userData?._id && user.restricted)) {
						dispatch(setCurrentTeamId(team._id));
						window.location.reload();
						// TODO: once the UI is ready show the access restricted page/dialog here
					}
				}
			} else if (error.response?.data?.error?.type === "ProfileDisconnected") {
				const { profiles } = store.getState().socialProfiles;

				const profile = profiles.find(
					(profile) => profile._id === error.response?.data?.error?.profileId
				);

				if (profile) {
					profile.disconnected = true;
				}

				dispatch({
					type: LIST_SOCIAL_PROFILES,
					payload: [...profiles],
				});

				dispatch(setSnackbarState(true, "error", "SocialProfileDisconnected", 2));
			}

			return Promise.reject(error);
		}
	);

	return interceptor;
};

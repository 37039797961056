export const countryAnalytics = [
	{
		country: "USA",
		likes: 154864,
		views: 135616,
		dislikes: 12351,
		comments: 12451,
		viewsPercentage: 30,
	},
	{
		country: "Canada",
		likes: 1614864,
		views: 1516,
		dislikes: 32353,
		comments: 52451,
		viewsPercentage: 10,
	},
	{
		country: "Russia",
		likes: 1614864,
		views: 135123516,
		dislikes: 32353,
		comments: 52451,
		viewsPercentage: 10,
	},
	{
		country: "China",
		likes: 1614864,
		views: 13516,
		dislikes: 32353,
		comments: 52451,
		viewsPercentage: 10,
	},
];

export const timeAnalytics = [
	{ title: "Sunday", value: 80 },
	{ title: "Monday", value: 55 },
	{ title: "Monday", value: 60 },
	{ title: "Tuesday", value: 90 },
	{ title: "Wednesday", value: 100 },
	{ title: "Thursday", value: 20 },
	{ title: "Friday", value: 100 },
	{ title: "Saturday", value: 95 },
];

export const genderPieData = [
	{ id: "Men", label: "Men", value: 303 },
	{ id: "Women", label: "Women", value: 369 },
	{ id: "Other", label: "Other", value: 85 },
];

export const barData: any = [
	{ age: 5, number: 100 },
	{ number: 100, age: 10 },
	{ number: 200, age: 15 },
	{ number: 300, age: 20 },
	{ number: 600, age: 25 },
	{ number: 500, age: 30 },
];

import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import WatchTeamSkeleton from "modules/Tasks/WatchTeam/WatchTeamSkeleton";
import WatchTeamUser from "modules/Tasks/WatchTeam/WatchTeamUser";
import { listActiveTasks } from "redux/actions/tasks/get";
import { StoreState } from "../../../../setup/store";
import Task from "types/Task";
import { TeamUser } from "types/User/Team";
import usePageStyles from "../../styles";
import WatchTeamUserDetails from "./WatchTeamUserDetails";

const sections = ["admins", "writers"];

interface Props {
	setIsAddButtonVisible: Dispatch<SetStateAction<boolean>>;
}

const WatchTeam = (props: Props) => {
	const { setIsAddButtonVisible } = props;
	const classes = usePageStyles();
	const [selectedUser, setSelectedUser] = useState<TeamUser>();
	const { listTasksLoading, activeTasks } = useSelector((state: StoreState) => state.tasks);
	const { currentTeamId, teams } = useSelector((state: StoreState) => state.teams);
	const currentTeam = teams.find((team) => team._id === currentTeamId);
	const dispatch = useDispatch();
	const { t } = useTranslation("tasks");

	const handleUserClick = (user: TeamUser) => {
		setSelectedUser(user);
		setIsAddButtonVisible(false);
	};

	const handleUserClose = () => {
		setSelectedUser(undefined);
		setIsAddButtonVisible(true);
	};

	useEffect(() => {
		if (currentTeamId) dispatch(listActiveTasks({ unreadComments: true }));
	}, [currentTeamId]);

	if (selectedUser) {
		return <WatchTeamUserDetails teamUser={selectedUser} onClose={handleUserClose} />;
	}

	return (
		<Box>
			{/* Skeleton Loading */}
			{listTasksLoading && (
				<Box style={{ marginTop: 32 }}>
					<WatchTeamSkeleton />
				</Box>
			)}

			{!listTasksLoading &&
				sections.map((section, index) => {
					const teamUsers = currentTeam?.users.filter((user) =>
						section === "admins"
							? user.role === "admin" || user.role === "owner"
							: user.role === "writer"
					);

					if (!Boolean(teamUsers?.length)) return <></>;

					return (
						<Box key={index} style={{ width: "77%" }}>
							<Box style={{ padding: 16, paddingBottom: 0 }} className={classes.label}>
								{t(section)}
							</Box>

							<Box style={{ paddingLeft: 18 }}>
								{teamUsers
									?.filter((teamUser) => teamUser.user)
									.map((teamUser, index) => (
										<WatchTeamUser
											key={index}
											onClick={handleUserClick}
											activeTasks={activeTasks as Array<Task & { assignees: string[] }>}
											teamUser={teamUser}
										/>
									))}
							</Box>
						</Box>
					);
				})}
		</Box>
	);
};

export default WatchTeam;

import { makeStyles } from "@material-ui/core";

const useFilterMenuStyles = makeStyles((theme) => ({
	filterButton: {
		display: "flex",
		flexDirection: "row",
		border: "1px solid #D5E1F5",
		width: 110,
		fontSize: 12,
		justifyContent: "center",
		alignItems: "center",
		color: theme.palette.text.primary,
		paddingTop: 4,
		paddingBottom: 4,
		cursor: "pointer",
	},

	menuContainer: {
		width: 230,
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 12,
	},

	menuItem: {
		paddingBottom: 3,
		paddingTop: 3,
		marginBottom: -8,
		width: "100%",
		fontSize: 14,
		color: theme.palette.text.primary,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		cursor: "pointer"
	},

	socialProfileItem: {
		cursor: 'pointer',
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: -10,
		fontSize: 12,
		paddingTop: 6,
		paddingBottom: 6,
		color: theme.palette.text.primary,
	},

	socialProfileImage: {
		marginLeft: 6,
		marginRight: 6,
		height: 20,
		width: 20,
		borderRadius: "50%",
	},

	checkboxContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},

	loadingContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 32,
	},

	socialProfileName: {
		maxWidth: 150,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
}));

export default useFilterMenuStyles;

import { Box, capitalize, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DaySection } from "..";
import { StoreState } from "../../../setup/store";
import useNotificationsStyles from "../styles";
import NotificationItem from "./NotificationItem";

interface Props {
	section: string;
	unreadNotificationsDays: DaySection[];
}

const NotificationsPageSection = (props: Props) => {
	const classes = useNotificationsStyles();
	const { t } = useTranslation("notifications");
	const { readNotifications, unreadNotifications, loading } = useSelector(
		(state: StoreState) => state.notifications
	);

	const { section, unreadNotificationsDays } = props;
	return (
		<Box>
			<Box className={classes.cardTitle}>{t(section)}</Box>

			<Box className={classes.card}>
				{loading && (
					<Box className={classes.centeredContainer}>
						<CircularProgress size={25} />
					</Box>
				)}

				{!loading &&
					((section === "unreadNotifications" && unreadNotifications.length === 0) ||
						(section === "readNotifications" && readNotifications.length === 0)) && (
						<Box className={classes.centeredContainer}>{t(`no${capitalize(section)}`)}</Box>
					)}

				{section === "unreadNotifications" &&
					!loading &&
					unreadNotificationsDays.map((day, index) => (
						<Box key={index} style={{ marginBottom: 12 }}>
							<Box className={classes.cardInnerTitle}>{day.date}</Box>
							{day.items.map((item, index) => (
								<NotificationItem notification={item} key={index} />
							))}
						</Box>
					))}

				{section === "readNotifications" &&
					!loading &&
					readNotifications.map((item, index) => (
						<NotificationItem fullDate={true} notification={item} key={index} />
					))}
			</Box>
		</Box>
	);
};

export default NotificationsPageSection;

import { Box, makeStyles, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TeamPicture from "components/Media/TeamPicture";
import { StoreState } from "../../../setup/store";
import Team from "types/User/Team";
import { useTranslation } from "react-i18next";

interface Props {
	team: Team;
	selected: boolean;
	onClick: (team: Team) => void;
}

const useTeamCardStyles = makeStyles((theme) => ({
	container: {
		width: 203,
		height: 203,
		cursor: "pointer",
		borderRadius: 12,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "white",
		boxShadow: "0px 4px 24px rgba(217, 227, 235, 0.4)",
	},

	picture: {
		width: 100,
		borderRadius: "50%",
		marginBottom: 16,
	},

	name: {
		fontWeight: 500,
		color: theme.palette.text.primary,
		fontSize: 14,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: "90%",
	},

	role: {
		color: "#868C91",
		textTransform: "uppercase",
		marginTop: 4,
		fontWeight: 500,
		fontSize: 12,
	},

	restricted: {
		color: theme.palette.error.main,
		fontSize: 12,
		marginTop: 4,
	},
}));

const TeamCard = (props: Props) => {
	const { team, selected, onClick } = props;
	const theme = useTheme();
	const { userData } = useSelector((state: StoreState) => state.user);
	const classes = useTeamCardStyles();
	const { t } = useTranslation("workspaces");
	const { role, restricted } =
		(userData && team.users.find((teamUser) => teamUser.user?._id === userData?._id)) || {};

	return (
		<Box
			onClick={() => !restricted && onClick(team)}
			className={classes.container}
			style={{
				border: selected ? `1px solid ${theme.palette.primary.main}` : "",
				opacity: restricted ? 0.7 : 1,
				pointerEvents: restricted ? "none" : "all",
			}}
		>
			<TeamPicture
				placeholderImage
				style={{ marginRight: 0, marginBottom: 8 }}
				team={team}
				size={100}
			/>

			<Box className={classes.name}>{team.name}</Box>

			<Box className={classes.role}>{role}</Box>
			{Boolean(restricted) && <Box className={classes.restricted}>{t("accessRestricted")}</Box>}
		</Box>
	);
};

export default TeamCard;

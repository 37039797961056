import { makeStyles } from "@material-ui/core";

const useTeamUserStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "#F1F4F8",
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
		borderRadius: 4,
		transition: "0.1s",
	},

	name: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.text.primary,
	},

	email: {
		fontSize: 11,
		color: theme.palette.text.secondary,
	},

	ownerTag: {
		color: theme.palette.primary.main,
	},

	role: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		color: "#868C91",
		fontWeight: 500,
		fontSize: 14,
		paddingRight: 16,
		textTransform: "uppercase",
		justifyContent: "flex-start",
	},

	deleteUserIcon: {
		backgroundColor: "#4B7FA3",
		color: "white",
		borderRadius: "50%",
		width: 20,
		height: 20,
		marginLeft: 16,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
	},

	restrictedError: {
		fontSize: 11,
		fontWeight: 500,
		color: theme.palette.error.main,
	},

	sentAt: {
		color: theme.palette.text.secondary,
		fontSize: 11,
	},
}));

export default useTeamUserStyles;

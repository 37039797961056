import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NotificationsIllustration } from "assets";
import SettingsSelectInput from "components/Basic/Inputs/SettingsSelectInput";
import { capitalize } from "helpers/utils";
import { updateUserSettings } from "redux/actions/user/settings";
import { StoreState } from "../../../setup/store";
import UserSettings, { NotificationSettingValue } from "types/User/UserSettings";
import usePageStyles from "../page-styles";

let dropdownOptions: NotificationSettingValue[] = ["enabled", "disabled"];

const sections = ["desktopNotifications", "emailNotifications"];
const desktopSettings = [
	"remindersNotifs",
	"newCampaignStartNotifs",
	"newCampaignFinishNotifs",
	// "messagesNotifs",
	"tasksNotifs",
	"assignedTasksCommentsNotifs",
	"desktopNotificationSound",
];
export const emailSettings = ["remindersNotifs", "newCampaignStartNotifs", "newCampaignFinishNotifs"];

const NotificationSettings = () => {
	const { userData } = useSelector((state: StoreState) => state.user);
	const [inputValues, setInputValues] = useState<Partial<UserSettings>>({});
	const { t } = useTranslation("settings");
	const dispatch = useDispatch();

	const pageClasses = usePageStyles();

	const handleInputChange = (name: string, value: string) => {
		const updatedInputValues = { ...inputValues, [name]: value };
		setInputValues(updatedInputValues);
		dispatch(updateUserSettings(updatedInputValues));
	};

	useEffect(() => {
		if (userData?.settings) {
			setInputValues({ ...userData.settings });
		}
	}, [userData]);

	return (
		<>
			<Box className={pageClasses.linearContainer}>
				<Box style={{ width: "60%" }}>
					{sections.map((section, index) => {
						const notifications =
							section === "desktopNotifications" ? desktopSettings : emailSettings;
						return (
							<>
								<Box key={index} className={pageClasses.cardTitle} style={{ marginTop: 32 }}>
									{t(section)}
								</Box>
								<Box style={{ width: "100%" }}>
									<Box style={{ minHeight: 250, paddingTop: 16 }} className={pageClasses.card}>
										{notifications.map((option, index) => {
											const name = `${
												section === "desktopNotifications" ? "desktop" : "email"
											}${capitalize(option)}`;

											return (
												<SettingsSelectInput
													name={name}
													label={t(option.replace('Notifs', ''))}
													key={index}
													value={(inputValues as any)[name]}
													style={{ marginTop: 16 }}
													valueColor={
														(inputValues as any)[name] === "disabled" ? "#F04755" : "#219653"
													}
													options={dropdownOptions.map((option) => ({
														label: t(option),
														value: option,
													}))}
													onChange={handleInputChange}
												/>
											);
										})}
									</Box>
								</Box>
							</>
						);
					})}
				</Box>
				<Box
					style={{
						width: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img style={{ width: "75%", marginLeft: 24 }} src={NotificationsIllustration} />
				</Box>
			</Box>
		</>
	);
};

export default NotificationSettings;

import { makeStyles } from "@material-ui/core";

const useTimeSettingsStyles = makeStyles((theme) => ({
	illustrationsContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: 32,
	},

	clockImage: {
		height: 120,
		width: 120,
		marginRight: 12,
	},

	timeIllustrationContainer: {
		color: theme.palette.text.primary,
		fontSize: 16,
		marginLeft: 14,
		marginTop: 4,
	},
}));

export default useTimeSettingsStyles;

import axios from "axios";
import config from "config";
import { Dispatch } from "react";
import {
	SET_TEAM_HASHTAGS,
	SET_TEAM_HASHTAGS_LOADING,
	SET_TRENDING_HASHTAGS,
	SET_TRENDING_HASHTAGS_LOADING,
} from "redux/reducers/stats";
import { StoreState } from "setup/store";
import { setSnackbarState } from "../snackBar";

export const listTrendingHashtagsAndTopics = () => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_TRENDING_HASHTAGS_LOADING,
			payload: true,
		});
		try {
			const result = await axios.get(`${config.apiUrl}/stats/trends/hashtags`);
			const { hashtags, topics } = result.data;

			dispatch({
				type: SET_TRENDING_HASHTAGS,
				payload: {
					hashtags,
					topics,
				},
			});
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
			dispatch({
				type: SET_TRENDING_HASHTAGS_LOADING,
				payload: false,
			});
		}
	};
};

/** Returns previously used and saved hashtags for the currently selected team */
export const listTeamHashtags = () => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		dispatch({
			type: SET_TEAM_HASHTAGS_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(`${config.apiUrl}/teams/${currentTeamId}/stats/hashtags`);

			dispatch({
				type: SET_TEAM_HASHTAGS,
				payload: result.data.hashtags,
			});
		} catch (e) {
			console.log(e);
			dispatch({
				type: SET_TEAM_HASHTAGS_LOADING,
				payload: false,
			});
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		}
	};
};

export const updateTeamHashtag = (name: string, saved: boolean) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { teamHashtags, trendingHashtags } = getState().stats;

		try {
			await axios.put(
				`${config.apiUrl}/teams/${currentTeamId}/stats/hashtags/${encodeURIComponent(name)}`,
				{ saved }
			);
			let updatedTeamHashtag = teamHashtags.find((hashtag) => hashtag.name === name);
			if (updatedTeamHashtag) {
				updatedTeamHashtag.saved = saved;
			} else {
				teamHashtags.push({
					name,
					usages: 0,
					saved,
				});
			}

			let updatedTrendingHashtag = trendingHashtags.find((hashtag) => hashtag.name === name);

			if (updatedTrendingHashtag) {
				updatedTrendingHashtag.saved = saved;
			}

			dispatch({
				type: SET_TEAM_HASHTAGS,
				payload: [...teamHashtags],
			});

			dispatch({
				type: SET_TRENDING_HASHTAGS,
				payload: {
					hashtags: [...trendingHashtags],
				},
			});
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		}
	};
};

import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import InteractionsSection from "modules/Reply/InteractionsSection/InteractionsSection";
import SocialProfile from "types/SocialProfile";
import useGeneralStyles from "./styles";
import { DateRange } from "pages/Reply";

interface Props {
	selectedProfile?: SocialProfile;
	dateRange: DateRange;
}

const General = (props: Props) => {
	const classes = useGeneralStyles();
	const { selectedProfile, dateRange } = props;

	return (
		<div className={classes.container}>
			{selectedProfile && (
				<div className={classes.socialProfileContainer}>
					<NetworkIcon size={23} network={selectedProfile.network} />
					<SocialProfilePicture
						style={{ marginLeft: 6, marginRight: 6 }}
						profile={selectedProfile}
					/>
					<div className={classes.selectedProfileUsername}>
						{selectedProfile.displayName || selectedProfile.username}
					</div>
				</div>
			)}

			{/* TODO: Lazy load sections (fetch data only when it's scrolled to) */}

			<InteractionsSection
				sectionType="comments"
				selectedSocialProfile={selectedProfile}
				tabs={[
					"allComments",
					//  "unreadComments",
					"unrepliedComments",
				]}
				dateRange={dateRange}
			/>
			<InteractionsSection
				selectedSocialProfile={selectedProfile}
				sectionType="messages"
				tabs={["allMessages", "unrepliedMessages"]}
				dateRange={dateRange}
			/>
			{/* 

			<SectionCard
				tabs={["ALL MENTIONS", "UNREAD MENTIONS", "UNREPLIED MENTIONS"]}
				items={mentions}
			/> */}

			{/* <SectionCard tabs={["ALL REPOSTS", "UNREAD REPOSTS", "UNREPLIED REPOSTS"]} items={reposts} /> */}
		</div>
	);
};

export default General;

import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "react";
import { v4 } from "uuid";
import { ProfilePicturePlaceholder } from "../../assets";
import { getImage } from "helpers/utils";
import UserData from "types/User/UserData";

const useStyles = makeStyles(() => ({
	picture: {
		height: 25,
		width: 25,
		borderRadius: "50%",
		marginRight: 12,
		marginLeft: 4,
		border: "1px solid white",
	},
}));

interface Props {
	/**
	 * if user object is not provided, the placeholder will be shown instead
	 */
	user?: UserData;
	style?: CSSProperties;
}

const UserPicture = (props: Props) => {
	const { user, style } = props;
	const classes = useStyles();
	const imageId = `${user?._id}-profile-image-${v4()}`;

	const handleImageLoadError = () => {
		const imageElement = document.getElementById(imageId) as HTMLImageElement;
		if (!imageElement) return;

		imageElement.src = ProfilePicturePlaceholder;
	};

	return (
		<img
			id={imageId}
			onError={handleImageLoadError}
			style={{ ...style }}
			className={classes.picture}
			src={user?.profilePicture ? getImage(user?.profilePicture) : ProfilePicturePlaceholder}
		/>
	);
};

export default UserPicture;

import { Dialog } from "@material-ui/core";
import Interaction from "types/Interaction";
import useReplyDialogStyles from "./styles";
import SocialProfile from "types/SocialProfile";
import Button from "components/Basic/Button";
import {
	CommentMentionOriginalPost,
	OriginalInteraction,
	OriginalPost,
	PostMentionOriginalPost,
} from "./components/MentionInfo";
import ReplyInputHeader from "./components/ReplyInputHeader";
import ReplyInput from "./components/ReplyInput";
import TitleText from "components/Basic/Text/TitleText";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { StoreState } from "setup/store";
import { useTranslation } from "react-i18next";
import ErrorText from "components/Basic/Text/ErrorText";
import { replyToInteraction } from "redux/actions/social-interactions/social-interactions";

interface Props {
	interaction: Interaction;
	open: boolean;
	socialProfile: SocialProfile;
	onClose: () => void;
}

const ReplyDialog = (props: Props) => {
	const [error, setError] = useState("");
	const { interaction, open, socialProfile, onClose } = props;
	const { type, mentionType, post } = interaction || {};
	const [inputValue, setInputValue] = useState("");
	const { t } = useTranslation("reply");
	const classes = useReplyDialogStyles();
	const dispatch = useDispatch();
	const replyLoading = useSelector((state: StoreState) => state.socialInteractions.replyLoading);

	const handleSubmit = () => {
		if (!inputValue || replyLoading) {
			return;
		}

		dispatch(
			replyToInteraction(interaction, inputValue, (error) => {
				if (error) {
					setError(error);
				} else {
					setError("");
					onClose();
				}
			})
		);
	};

	useEffect(() => {
		if (open) {
			setError("");
			setInputValue("");
		}
	}, [open]);

	return (
		<Dialog classes={{ paper: classes.paper }} onClose={onClose} open={open}>
			<div className={classes.container}>
				<TitleText>
					Reply to the <span style={{ textTransform: "capitalize" }}>{interaction?.type}</span>
				</TitleText>

				{/* Comment Mention Original Comment */}
				{type === "comment" && (
					<OriginalPost interaction={interaction} socialProfile={socialProfile} />
				)}

				{/* Comment Mention Original Post */}
				{type === "mention" && mentionType === "comment" && socialProfile && post && (
					<CommentMentionOriginalPost interaction={interaction} socialProfile={socialProfile} />
				)}

				{/* Post Mention */}
				{type === "mention" && mentionType === "post" && (
					<PostMentionOriginalPost socialProfile={socialProfile} interaction={interaction} />
				)}

				<OriginalInteraction interaction={interaction} />

				<div className={classes.replyContainer}>
					<ReplyInputHeader interaction={interaction} socialProfile={socialProfile} />
					<ReplyInput
						value={inputValue}
						onChange={(value) => setInputValue(value)}
						onSubmit={handleSubmit}
					/>

					{error && <ErrorText>{t(error)}</ErrorText>}

					<div className={classes.buttonsContainer}>
						{/* <Button secondary={type !== "message"} style={{ width: 150 }}>
							Reply in Messages
						</Button> */}
						<Button
							loading={replyLoading}
							onClick={handleSubmit}
							style={{ marginLeft: 12, width: 150 }}
						>
							{t("reply")}
						</Button>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default ReplyDialog;

import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import ActiveIconSvg from "assets/SVG/Active";
import permissionsIndex from "helpers/permissions";
import { listPermissions } from "redux/actions/socialProfiles/socialProfiles";
import { StoreState } from "../../../../../setup/store";
import SocialProfile from "types/SocialProfile";
import useProfileDetailsStyles from "../../styles/social-profile-details";
import { useSelector, useDispatch } from "react-redux";

interface Props {
	profile: SocialProfile;
}

const Permissions = ({ profile }: Props) => {
	const { permissions, permissionsLoading } = useSelector(
		(state: StoreState) => state.socialProfiles
	);
	const classes = useProfileDetailsStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!profile || profile.disconnected) {
			return;
		}
		if ((profile.type === "page" || profile.network === "instagram") && profile.parentProfile) {
			dispatch(listPermissions(profile.parentProfile));
		} else {
			dispatch(listPermissions(profile._id || ""));
		}
	}, [profile]);

	return (
		<div>
			{(permissions.length > 0 || permissionsLoading) && (
				<div className={classes.title}>Permissions</div>
			)}

			{permissionsLoading && (
				<div style={{ padding: 16 }} className={classes.centeredContainer}>
					<CircularProgress size={25} color="primary" />
				</div>
			)}
			<div className={classes.permissions}>
				{!permissionsLoading &&
					permissions.map((permission, index) => (
						<div key={index} style={{ marginTop: 8 }} className={classes.linearContainer}>
							<ActiveIconSvg size={18} />
							<div className={classes.permissionText}>
								{permissionsIndex[permission.permission] || permission.permission}
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default Permissions;

import { useTheme } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDates } from "helpers/dateAndTime";
import { getCalendarData } from "redux/actions/calendar-events/get";
import { StoreState } from "../../../setup/store";
import CalendarEvent from "types/CalendarEvent";
import WeeklyCalendarItem from "types/WeeklyCalendar";
import CalendarCell from "./Components/CalendarCell";
import useWeeklyCalendarStyles from "./styles";

interface Props {
	height?: number;
	width?: number;
}

export type NestedItem = {
	day: number;
	hour: string;
	items: CalendarEvent[];
};

let hours: string[] = [];

// Get all hours starting from 8 AM in AMPM format
for (let i = 8; i <= 31; i++) {
	let currentHour = i > 24 ? i - 24 : i;
	if (currentHour > 12) currentHour = currentHour - 12;
	let dayTime = "AM";
	if (i > 3 + 8 && i < 16 + 8) dayTime = "PM";

	// Add a zero before single digit hours
	hours.push(
		`${currentHour.toString().length === 1 ? `0${currentHour}` : currentHour}:00 ${dayTime}`
	);
}

const WeeklyEvents = (props: Props) => {
	const [nestedItems, setNestedItems] = useState<NestedItem[]>([]);
	const [calendarData, setCalendarData] = useState<WeeklyCalendarItem[]>([]);
	const dispatch = useDispatch();
	const classes = useWeeklyCalendarStyles();
	const theme = useTheme();
	const { userData } = useSelector((state: StoreState) => state.user);
	const { height, width } = props;
	const { weekCalendarData, createEventSuccess } = useSelector(
		(state: StoreState) => state.calendarEvents
	);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { calendarEvents: events } = weekCalendarData || {};

	useEffect(() => {
		let nestedItems: NestedItem[] = [];
		calendarData.forEach((dayColumn) => {
			hours.forEach((hour) => {
				const filteredItems = dayColumn.items.filter((item) => {
					let scheduleHour = new Date(item.scheduledAt!);
					scheduleHour.setMinutes(0);

					return moment(scheduleHour.toString()).format("hh:mm A") === hour;
				});
				if (filteredItems.length > 1) {
					nestedItems.push({
						day: dayColumn.day,
						hour,
						items: filteredItems,
					});
				}
			});
		});

		setNestedItems([...nestedItems]);
	}, [calendarData]);

	useEffect(() => {
		dispatch(getCalendarData("week"));
	}, [currentTeamId]);

	useEffect(() => {
		if (!userData) return;
		let calendarData: WeeklyCalendarItem[] = [];

		events?.forEach((event) => {
			let dayFound = false;
			calendarData.forEach((dataItem) => {
				if (dataItem.day === moment(event.scheduledAt).date()) {
					dayFound = true;
					dataItem.items.push(event);
				}
			});

			if (!dayFound) {
				calendarData.push({
					day: moment(event.scheduledAt).date(),
					items: [event],
				});
			}
		});

		setCalendarData([...calendarData]);
	}, [events, userData, weekCalendarData]);

	return (
		<div style={{ height: height || "", width: width || "" }} className={classes.container}>
			<div className={classes.daysHeaderContainer}>
				{getDates(new Date(), 7).map((day, index) => (
					<div
						key={index}
						style={{
							fontWeight: index === 0 ? 600 : 400,
							color: index === 0 ? theme.palette.primary.main : "",
						}}
						className={classes.daysHeaderItem}
					>
						{day}
					</div>
				))}
			</div>

			{/* Hours list */}
			<div className={classes.linearContainer}>
				<div className={classes.hoursColumn}>
					{hours.map((hour, index) => (
						<div key={index} className={classes.hourItem}>
							{hour}
						</div>
					))}
				</div>

				{getDates(new Date(), 7, true).map((day, index) => {
					let Column: ReactNode | null = null;
					calendarData.map((dayItem, index) => {
						if (dayItem.day === moment(day).date()) {
							Column = (
								<div key={index} className={classes.itemsColumn}>
									{hours.map((hour, index) => (
										<CalendarCell
											dayItem={dayItem}
											hour={hour}
											nestedItems={nestedItems}
											key={index}
										/>
									))}
								</div>
							);
						}
					});

					// Return an empty column if no data found about this specific day
					if (!Column) {
						return <div className={classes.itemsColumn} key={index}></div>;
					} else return Column;
				})}
			</div>
		</div>
	);
};

export default WeeklyEvents;

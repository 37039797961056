import { Box, CircularProgress } from "@material-ui/core";
import { MembersIllustration } from "assets";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import TextButton from "components/Basic/TextButton";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { formatDate } from "helpers/dateAndTime";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbarState } from "redux/actions/snackBar";
import { deleteTeamInvitation, sendTeamInvitation } from "redux/actions/team/users";
import { StoreState } from "setup/store";
import { TeamInvitation } from "types/User/Team";
import useTeamUserStyles from "./styles";

interface Props {
	invitation: TeamInvitation;
	index: number;
}

const TeamUserInvitationCard = (props: Props) => {
	const { invitation, index } = props;
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [resendLoading, setResendLoading] = useState(false);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { t } = useTranslation("workspaces");
	const classes = useTeamUserStyles();
	const eventIndex = index % 2;
	const dispatch = useDispatch();
	const isResendDisabled = moment().diff(moment(invitation.emailSentAt)) < 900000;

	const handleDelete = () => {
		if (!currentTeamId) {
			return;
		}

		setDeleteLoading(true);

		dispatch(
			deleteTeamInvitation(currentTeamId, invitation.email, () => {
				setDeleteLoading(false);
				setDeleteConfirmation(false);
			})
		);
	};

	const handleResendClick = () => {
		if (!currentTeamId) return;

		setResendLoading(true);

		dispatch(
			sendTeamInvitation(currentTeamId, invitation.email, (success, err) => {
				setResendLoading(false);
				if (err) {
					dispatch(setSnackbarState(true, "error", err));
				}
			})
		);
	};

	return (
		<>
			<ConfirmationDialog
				open={deleteConfirmation}
				image={MembersIllustration}
				onAction={handleDelete}
				onClose={() => setDeleteConfirmation(false)}
				message={t("deleteInvitationConfirmation")}
				actionLoading={deleteLoading}
			/>
			<div
				className={classes.container}
				style={{
					backgroundColor: eventIndex ? "#F1F4F8" : "#f7f9fd",
				}}
			>
				<div style={{ marginLeft: 40 }}>
					<div className={classes.name}>{invitation.email}</div>
					<div className={classes.sentAt}>
						{t("lastSentAt")}
						{formatDate(invitation.emailSentAt)}
					</div>

					<Box display="flex" alignItems="center">
						<TextButton
							secondary
							disabled={isResendDisabled || resendLoading}
							onClick={handleResendClick}
						>
							{t(isResendDisabled ? "emailSent" : "resendEmail")}
						</TextButton>
						{resendLoading && <CircularProgress size={12} style={{ marginLeft: 12 }} />}
					</Box>
				</div>

				<div
					style={{ marginLeft: "auto", marginRight: 46 }}
					onClick={() => setDeleteConfirmation(true)}
					className={classes.deleteUserIcon}
				>
					<CloseIconSvg color="white" size={8} strokeWidth={3} />
				</div>
			</div>
		</>
	);
};

export default TeamUserInvitationCard;

import { Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getSettingPageRoute, selectedSettingTabQuery } from "../../..";
import Button from "components/Basic/Button";
import { StoreState } from "../../../../../setup/store";
import { accountViewQuery } from "../AccountSettings";
import usePageStyles from "../styles";
import ProfilePhoto from "./ProfilePhoto";
import useStyles from "./styles/personal-info-styles";
import { useState } from "react";
import { SignOutConfirmImage } from "assets";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { signOut } from "redux/actions/auth";

const dataItems = ["firstName", "lastName", "email", "companyName"];

const PersonalInfo = () => {
	const [signoutDialog, setSignoutDialog] = useState<boolean>(false);
	const [signoutLoading, setSignoutLoading] = useState<boolean>(false);

	const { userData, authLoading } = useSelector((state: StoreState) => state.user);
	const classes = useStyles();
	const pageClasses = usePageStyles();
	const { t } = useTranslation("settings");
	const history = useHistory();
	const dispatch = useDispatch();

	const handleSignout = () => {
		setSignoutLoading(true);
		dispatch(signOut());
	};

	return (
		<Box className={pageClasses.linearContainer}>
			<ConfirmationDialog
				open={signoutDialog}
				onClose={() => setSignoutDialog(false)}
				onAction={() => handleSignout()}
				actionLoading={signoutLoading}
				confirmButton="Sign Out"
				message={t("signoutConfirm")}
				image={SignOutConfirmImage}
				textMaxWidth={200}
			/>

			<Box style={{ width: "55%", marginRight: 16 }}>
				<Box className={pageClasses.cardTitle}>{t("personalInformation")}</Box>

				<Box className={pageClasses.card}>
					{userData &&
						!authLoading &&
						dataItems.map((item, index) => {
							const value = { ...userData }[item];

							if (!value) {
								return <></>;
							}

							return (
								<Box key={index} className={classes.personalInfoPair}>
									<Box className={classes.personalInfoLabel}>{t(item)}:</Box>
									<Box className={classes.personalInfoValue}>{value}</Box>
								</Box>
							);
						})}

					{authLoading && (
						<Box className={pageClasses.loadingContainer}>
							<CircularProgress size={20} />
						</Box>
					)}
					<Box className={classes.buttonsContainer}>
						<Box className={classes.rightButtonsContainer}>
							<Button
								onClick={() => setSignoutDialog(true)}
								style={{ width: 100, marginRight: 24 }}
								secondary
							>
								{t("signout")}
							</Button>
							<Button
								disabled={authLoading}
								onClick={() =>
									history.push(
										getSettingPageRoute('myAccount', 'edit')
									)
								}
								style={{ width: 170 }}
							>
								{t("editPersonalInformation")}
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>

			<ProfilePhoto />
		</Box>
	);
};

export default PersonalInfo;

import { makeStyles } from "@material-ui/core";

const usePostCreatorStyles = makeStyles((theme) => ({
	creatorContainer: {},
	postContentContainer: {
		outline: "1px solid #4B7FA3",
		height: 280,
		width: "100%",
		borderRadius: 10,
		marginTop: 16,
		position: "relative",
		transition: "0.2s",
		boxSizing: "border-box",
	},

	textArea: {
		resize: "none",
		"&:focus": {
			outline: "none",
		},
		fontFamily: "Avenir",
		border: 0,
		width: "100%",
		borderRadius: 10,
		padding: 16,
		color: theme.palette.text.primary,
	},

	attachments: {
		display: "flex",
		flexDirection: "row",
		overflowX: "auto",
		padding: 16,
		paddingTop: 32,
	},

	profilesContainer: {
		flexWrap: "wrap",
		display: "flex",
		flexDirection: "row",
		gap: 8
	},

	socialProfilePlatformImg: {
		width: 18,
		marginRight: 6,
	},

	linearContainer: {
		marginTop: 26,
		display: "flex",
		flexDirection: "row",
	},

	verticalContainer: {
		marginTop: 26,
		display: "flex",
		flexDirection: "column",
	},

	dropdownTitle: {
		color: theme.palette.text.primary,
		fontSize: 12,
		fontWeight: 500,
	},

	dropdownSubtitle: {
		color: "#868C90",
		fontSize: 11,
		marginBottom: 6,
	},

	postingTimeButton: {
		minWidth: 160,
		maxWidth: 250,
		width: "fit-content",
		paddingLeft: 6,
		paddingRight: 6,
		background: "#B6D8F0 !important",
		color: "#3E4C55",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	form: {
		paddingBottom: 16,
	},
	bottomContainer: { width: "100%" },

	saveAsDraftContainer: {
		color: theme.palette.text.secondary,
		fontWeight: 500,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		fontSize: 12,
		cursor: "pointer",
	},

	postActions: {
		position: "absolute",
		display: "flex",
		alignItems: "center",
		bottom: 4,
		right: 16,
	},

	postAction: {
		height: 28,
		width: 28,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		borderRadius: "50%",
		"&:hover": {
			backgroundColor: "#ebebeb",
		},
	},

	error: { fontSize: 12, color: theme.palette.error.main, marginTop: 4 },
}));

export default usePostCreatorStyles;

import { makeStyles } from "@material-ui/core";

const useMessageStyles = makeStyles((theme) => ({
	header: {
		display: "flex",
		alignItems: "center",
	},

	username: {
		fontWeight: 500,
		fointSize: 14,
		color: theme.palette.text.primary,
		marginRight: 16,
	},

	date: {
		color: "#868C91",
		fontSize: 12,
	},

	content: {
		fontSize: 14,
		color: theme.palette.text.primary,
		marginTop: 0,
		marginLeft: 42,
		display: "flex",
		whiteSpace: "pre-wrap",
	},

	highlighter: {
		backgroundColor: "#D5E1F5",
		borderRadius: 4,
	},
}));

export default useMessageStyles;

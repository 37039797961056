import { makeStyles } from "@material-ui/core";
import { ImagePlaceholder } from "assets";
import clsx from "clsx";
import { getPostImage } from "modules/SocialPreviews/utils";
import { useState, useEffect, CSSProperties } from "react";
import Post from "types/Post";

interface Props {
	post: Post;
	className?: string;
	style?: CSSProperties;
}

const useStyles = makeStyles(() => ({
	attachment: {
		height: 50,
		width: 50,
		objectFit: "cover",
	},
}));

const PostImage: React.FC<Props> = (props) => {
	const { post, className, style } = props;
	const [image, setImage] = useState<string>("");
	const classes = useStyles();

	const handleImageLoadError = () => {
		const imageElement = document.getElementById(`${post._id}-image`) as HTMLImageElement;
		if (!imageElement) {
			return;
		}

		imageElement.src = ImagePlaceholder;
	};

	useEffect(() => {
		getPostImage(post).then((image) => {
			setImage(image);
		});
	}, [post]);

	return (
		<img
			id={`${post._id}-image`}
			style={style}
			src={image || ImagePlaceholder}
			alt="attachment"
			className={clsx(classes.attachment, className)}
			onError={handleImageLoadError}
		/>
	);
};

export default PostImage;

import { formatDate } from "helpers/dateAndTime";
import { networkIcons } from "types/Network";
import Post from "types/Post";
import Button from "components/Basic/Button";
import useStyles from "./styles/draft-post";
import CalendarIconSvg from "assets/SVG/CalendarIconSvg";
import { Box } from "@material-ui/core";
import MediaAttachment from "../../components/Media/MediaAttachment";
import { useDispatch } from "react-redux";
import { setFullScreenMediaPreview } from "redux/actions/modals";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";

interface Props {
	post: Post;
	handleDeleteClick: (post: Post) => void;
	handleEditClick: (post: Post) => void;
}

const DraftPost = (props: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { post, handleDeleteClick, handleEditClick } = props;
	const { socialProfiles } = post;

	const handleAttachmentClick = (index: number) => {
		dispatch(
			setFullScreenMediaPreview({
				open: true,
				media: post.media || [],
				initialSelectedIndex: index,
			})
		);
	};

	return (
		<div className={classes.container}>
			<div
				style={{ marginBottom: 32, alignItems: "flex-start" }}
				className={classes.linearContainer}
			>
				<span style={{ opacity: !post.content ? 0.6 : 1 }} className={classes.textContent}>
					{post.content || "no text yet"}
				</span>

				<div className={classes.socialDetails}>
					<img
						src={networkIcons[post.socialProfiles ? post.socialProfiles[0].network : ""]}
						className={classes.socialIcon}
					/>

					{socialProfiles && socialProfiles[0] && (
						<SocialProfilePicture style={{ width: 25, height: 25 }} profile={socialProfiles[0]} />
					)}

					<span className={classes.username}>
						{post.socialProfiles ? post.socialProfiles[0].displayName : ""}
					</span>
				</div>
			</div>

			<div className={classes.linearContainer}>
				<div className={classes.attachmentsContainer}>
					{post.media?.map((attachment, index) => (
						<MediaAttachment
							key={index}
							onAttachmentClick={() => handleAttachmentClick(index)}
							thumbnailKey={attachment.thumbnail}
							attachment={attachment.value}
							type={attachment.type}
							style={{ width: 70, height: 70 }}
						/>
					))}
				</div>

				<div className={classes.postActions}>
					<div className={classes.dateContainer}>
						<Box style={{ height: 22 }}>
							<CalendarIconSvg size={20} />
						</Box>

						<span style={{ marginLeft: 8 }}>{post.createdAt && formatDate(post.createdAt)}</span>
					</div>

					<div className={classes.buttonsContainer}>
						<Button
							onClick={() => handleDeleteClick(post)}
							style={{ width: 120, marginRight: 16 }}
							secondary={true}
						>
							Delete Draft
						</Button>

						<Button onClick={() => handleEditClick(post)} className={classes.lightBlueButton}>
							Edit Draft
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DraftPost;

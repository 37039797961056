const ArrowsRightIcon = ({ size }: { size?: number }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 25 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M24.875 13L11.4135 25.5L10.2332 24.229L22.3259 13L10.2332 1.77104L11.4135 0.5L24.875 13ZM15.3352 13L1.87362 25.5L0.693371 24.229L12.7861 13L0.693371 1.77104L1.87362 0.5L15.3352 13Z"
				fill="#3E4C55"
			/>
		</svg>
	);
};

export default ArrowsRightIcon;

import { Box } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

const TaskChipsSkeleton = () => {
	return (
		<>
			{Array.from(Array(2).keys()).map((key) => (
				<Box key={key} style={{ marginTop: 24 }}>
					<Box display="flex">
						<Box>
							<Skeleton style={{ width: 200, marginBottom: 2 }} />
							<Skeleton style={{ width: 100, marginBottom: 8 }} />
						</Box>

						<Box display="flex" width="fit-content" marginLeft="auto">
							<Skeleton style={{ width: 30, height: 30, borderRadius: "50%" }} />
							<Skeleton
								style={{
									width: 30,
									height: 30,
									borderRadius: "50%",
									marginLeft: 6,
								}}
							/>
						</Box>
					</Box>
				</Box>
			))}
		</>
	);
};

export default TaskChipsSkeleton;

import { makeStyles } from "@material-ui/core";

const useImagePickerStyles = makeStyles(() => ({
	pictureContainer: {
		width: "fit-content",
		position: "relative",
	},

	imageOverlayContainer: {
		position: "absolute",
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		transition: "0.3s",
		top: 0,
	},

	profilePicture: {
		height: 200,
		width: 200,
		minHeight: 200,
		minWidth: 200,
		objectFit: "cover",
		transition: "0.3s",
		borderRadius: "50%",
	},
}));

export default useImagePickerStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		minWidth: 500,
		minHeight: 500,
		overflowY: "scroll",
		backgroundColor: "white",
		paddingBottom: 16,
		position: "relative",
	},

	header: {
		padding: 18,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
	},

	networkContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	selectedNetworkIcon: {
		height: 30,
	},

	selectedNetworkTitle: {
		fontSize: 20,
		color: theme.palette.text.primary,
		marginLeft: 16,
		textTransform: "capitalize",
	},

	otherNetworkIcon: {
		width: 22,
		marginLeft: 18,
		cursor: "pointer",
		transition: "0.3s",
	},

	otherNetworks: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginLeft: "auto",
		transition: "0.3s",
	},

	previewContainer: {
		padding: 16,
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: 62,
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		padding: 16,
		position: "absolute",
		bottom: 8,
		left: 0,
		width: "100%",
	},

	scheduleContainer: {
		border: "1px solid #4B7FA3",
		height: 38,
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		color: theme.palette.text.primary,
		fontSize: 12,
		padding: 8,
		borderRadius: 8,
	},

	rightContainer: {
		marginLeft: "auto",
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
}));

export default useStyles;

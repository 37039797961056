import EditPersonalInfo from "./Components/EditPersonalInfo/EditPersonalInfo";
import ProfileOverview from "./Views/ProfileOverview";
import { Box } from "@material-ui/core";
import { useQuery } from "helpers/utils";

export const accountViewQuery = "view";

const ProfilePage = () => {
	const query = useQuery();
	const currentView = query.get(accountViewQuery);

	return (
		<Box>
			{(!currentView || currentView === "overview") && <ProfileOverview />}
			{currentView === "edit" && <EditPersonalInfo />}
		</Box>
	);
};

export default ProfilePage;

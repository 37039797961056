import { Box } from "@material-ui/core";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import useCurrentSubscriptionPlanStyles from "../styles";

const CurrentSubscriptionPlanInfo = () => {
	const classes = useCurrentSubscriptionPlanStyles();
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const { t } = useTranslation("settings");

	if (!currentTeamSubscription) return <></>;

	const { title, description, networks, id } = currentTeamSubscription.planInfo || {};
	const { price, billingPeriodUnit } = currentTeamSubscription;

	return (
		<Box display="flex">
			<Box>
				<Box className={classes.planTitle}>{title}</Box>
				<Box className={classes.planDescription}>{description}</Box>
				<Box display="flex" style={{ marginTop: 16 }}>
					<Box>
						{networks.map((network, index) => (
							<NetworkIcon
								key={index}
								network={network}
								size={25}
								strokeWidth={3}
								style={{ marginRight: 4 }}
							/>
						))}
					</Box>
				</Box>
			</Box>

			<Box width="28%" marginLeft="auto">
				<Box className={classes.priceTitle}>
					{id === "Hashtag-Hero-Free" && "FREE"}
					{Boolean(price) && (
						<>
							{price}
							<span style={{ fontSize: 16 }}>$</span>
							<Box className={classes.priceSubtitle}>
								{t(`per${capitalize(billingPeriodUnit || "month")}`)}
							</Box>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default CurrentSubscriptionPlanInfo;

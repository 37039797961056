import { makeStyles } from "@material-ui/core";

const useHashtagAnalyticsStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	card: {
		height: 350,
		width: "50%",
		backgroundColor: "white",
		borderRadius: 10,
		padding: 28,
	},

	hashtag: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		fontSize: 14,
		fontFamily: "Avenir",
		color: theme.palette.text.primary,
		width: "100%",
		paddingTop: 8,
	},

	cardTitle: {
		fontSize: 16,
		color: theme.palette.text.primary,
		paddingBottom: 16,
	},
}));

export default useHashtagAnalyticsStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: 1250,
		backgroundColor: "white",
		display: "flex",
		flexDirection: "row",
		height: 165,
		marginTop: 12,
		position: "relative",
		borderRadius: 15,
	},

	date: {
		width: 150,
		paddingRight: 10,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},

	dateText: {
		color: "#5B666E",
		textTransform: "uppercase",
		fontWeight: 500,
		letterSpacing: 0.4,
		fontSize: 14,
	},

	postsContainer: {
		overflowX: "scroll",
		overflowY: "hidden",
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginRight: 4,
	},

	backArrow: {
		background: "linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
		position: "absolute",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		paddingLeft: 24,
		width: 100,
		left: 120,
		height: 165,
		transition: "0.3s",
		cursor: "pointer",
		borderRadius: 15,
	},

	nextArrow: {
		background: "linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
		position: "absolute",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		paddingRight: 12,
		width: 100,
		right: 0,
		height: 165,
		transition: "0.3s",
		cursor: "pointer",
		borderRadius: 15,
	},
}));

export default useStyles;

const BookmarkIconSvg = ({ size, filled }: { size?: number; filled?: boolean }) => {
	return (
		<svg
			width={size || 18}
			height={size || 18}
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{filled ? (
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M25.7136 29.8334V3.16677L6.66602 3.16675V29.8334L16.1898 22.2144L25.7136 29.8334Z"
					fill="#F04755"
				/>
			) : (
				<path
					d="M25.7136 29.8334L25.4013 30.2239C25.5514 30.3439 25.757 30.3673 25.9302 30.2841C26.1034 30.2008 26.2136 30.0256 26.2136 29.8334H25.7136ZM25.7136 3.16677H26.2136V2.66677L25.7136 2.66677L25.7136 3.16677ZM6.66602 3.16675L6.66602 2.66675L6.16602 2.66675V3.16675H6.66602ZM6.66602 29.8334H6.16602C6.16602 30.0256 6.27619 30.2008 6.44942 30.2841C6.62265 30.3673 6.82828 30.3439 6.97836 30.2239L6.66602 29.8334ZM16.1898 22.2144L16.5022 21.8239C16.3196 21.6779 16.0601 21.6779 15.8775 21.8239L16.1898 22.2144ZM26.2136 29.8334V3.16677H25.2136V29.8334H26.2136ZM6.16602 3.16675V29.8334H7.16602V3.16675H6.16602ZM6.97836 30.2239L16.5022 22.6048L15.8775 21.8239L6.35367 29.443L6.97836 30.2239ZM15.8775 22.6048L25.4013 30.2239L26.026 29.443L16.5022 21.8239L15.8775 22.6048ZM25.7136 2.66677L6.66602 2.66675L6.66602 3.66675L25.7136 3.66677L25.7136 2.66677Z"
					fill="black"
				/>
			)}
		</svg>
	);
};

export default BookmarkIconSvg;

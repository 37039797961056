const AddProfileIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || 30}
			height={size || 30}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.6667 2.5C8.44528 2.5 5.83333 5.11105 5.83333 8.33362C5.83333 11.5562 8.44528 14.1672 11.6667 14.1672C14.8881 14.1672 17.5 11.5562 17.5 8.33362C17.5 5.11105 14.8881 2.5 11.6667 2.5Z"
				fill="#3E4C55"
			/>
			<path
				d="M22.5 10.8384V14.1738H19.1667V15.8414H22.5V19.1768H24.1667V15.8414H27.5V14.1738H24.1667V10.8384H22.5Z"
				fill={color || "#3E4C55"}
			/>
			<path
				d="M15 17.4993L8.33333 17.4993C5.11186 17.4994 2.5 20.1116 2.5 23.3354V27.5L20.8333 27.5V23.3354C20.8333 20.1116 18.2215 17.4993 15 17.4993Z"
				fill={color || "#3E4C55"}
			/>
		</svg>
	);
};

export default AddProfileIconSvg;

import axios from "axios";
import { Dispatch } from "react";
import config, { unsubscribeEmailsQuery } from "config";

import { StoreState } from "../../../setup/store";
import { UpdateUserSettingsFunc } from "types/ReduxActions";
import { SET_USER_DATA, UPDATE_SETTINGS_LOADING } from "../../reducers/user";
import { setSnackbarState } from "../snackBar";
import { capitalize, deleteQueryParam } from "helpers/utils";
import { emailSettings } from "pages/Settings/Components/NotificationSettings";

export const updateUserSettings: UpdateUserSettingsFunc = (payload, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;
		dispatch({
			type: UPDATE_SETTINGS_LOADING,
			payload: true,
		});

		// To remove objects from the old structure
		Object.keys(payload).forEach((key) => {
			if (typeof (payload as any)[key] === "object") {
				delete (payload as any)[key];
			}
		});

		try {
			const result = await axios.put(`${config.apiUrl}/user/current/settings`, {
				settings: payload,
			});

			dispatch({
				type: SET_USER_DATA,
				payload: {
					userData: { ...userData, settings: result.data.settings },
				},
			});

			callback && callback(true);
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "Error updating settings..."));
			console.log(e);
		}

		dispatch({
			type: UPDATE_SETTINGS_LOADING,
			payload: true,
		});
	};
};

export const handleUnsubscribeEmailsQuery = () => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;
		if (!userData) {
			return;
		}

		let updatedSettings = {
			...userData.settings,
			notifications: { ...(userData.settings || {}) },
		};

		emailSettings.forEach((setting) => {
			(updatedSettings.notifications as any)[`email${capitalize(setting)}`] = "disabled";
		});

		dispatch(
			updateUserSettings(updatedSettings, () => {
				deleteQueryParam(unsubscribeEmailsQuery);
				dispatch(setSnackbarState(true, "success", "UnsubscribeEmailsSuccess"));
			})
		);
	};
};

import ActiveIconSvg from "assets/SVG/Active";
import AttentionIconSvg from "assets/SVG/AttentionIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import moment from "moment";
import { useMemo } from "react";
import { isProfileDisconnected } from "redux/actions/socialProfiles/socialProfiles";
import SocialProfile from "types/SocialProfile";
import useProfileDetailsStyles from "../../styles/social-profile-details";

interface Props {
	profile: SocialProfile;
}

const SocialProfileDetailsHeader = (props: Props) => {
	const classes = useProfileDetailsStyles();
	const { profile } = props;

	const isDisconnected = useMemo(() => isProfileDisconnected(profile), [profile]);

	const expiryDate = moment(profile.tokenExpiresAt).format("DD MMM, hh:mm A");

	return (
		<div className={classes.linearContainer}>
			<SocialProfilePicture
				profile={profile}
				style={{ height: 110, width: 110, marginRight: 16 }}
			/>

			<div>
				<div className={classes.username}>{profile.displayName}</div>

				<div className={classes.profileType}>
					{`${profile.network} ${profile.type === "page" ? "Page" : "Account"}`}
				</div>

				<div className={classes.linearContainer}>
					<div className={classes.linearContainer}>
						{isDisconnected ? <AttentionIconSvg size={22} /> : <ActiveIconSvg filled size={22} />}
						<div className={isDisconnected ? classes.inactive : classes.active}>
							{isDisconnected
								? profile.tokenExpiresAt
									? `Disconnected at ${expiryDate}`
									: "Disconnected"
								: profile.tokenExpiresAt
								? `Connected until ${expiryDate}`
								: "Connected"}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SocialProfileDetailsHeader;

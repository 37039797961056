import { makeStyles } from "@material-ui/core";

const useOptionsModalStyles = makeStyles((theme) => ({
	option: {
		color: theme.palette.text.primary,
		fontSize: 12,
		padding: 12,
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "#f7f7f7",
		},
	},

	popoverContainer: {
		backgroundColor: "white",
		borderRadius: 5,
		width: 200,
	},
}));

export default useOptionsModalStyles;

import { makeStyles } from "@material-ui/core";

const useSubscriptionPlanStyles = makeStyles(() => ({
	subscriptionPlan: {
		borderRadius: 12,
		marginRight: 12,
		marginLeft: 12,
		backgroundColor: "white",
		padding: 24,
		paddingTop: 32,
		height: "70vh",
		maxHeight: 640,
		overflowY: "auto",
		width: "25%",
		boxShadow: "0px 4px 24px rgba(217, 227, 235, 0.4)",
	},

	title: {
		color: "#868C91",
		fontFamily: "Chau Philomene One",
		fontSize: 18,
		textTransform: "uppercase",
	},

	price: {
		fontFamily: "Chau Philomene One",
		color: "#F05D6A",
		fontSize: 32,
		marginTop: 42,
		textTransform: "uppercase",
		marginBottom: 24,
	},

	description: {
		color: "#5B666E",
		fontSize: 14,
	},

	featureItem: {
		fontSize: 14,
		marginTop: 10,
		marginBottom: 10,
	},

	actionContainer: {
		marginTop: 86,
		width: "100%",
	},

	"@media (max-height: 850px)": {
		actionContainer: {
			marginTop: 32,
		},
	},
}));

export default useSubscriptionPlanStyles;

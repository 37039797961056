const AttachIconSvg = ({
	width,
	height,
	color,
}: {
	width?: number;
	height?: number;
	color?: string;
}) => {
	return (
		<svg
			width={width || "26"}
			height={height || "28"}
			viewBox="0 0 26 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.3393 14L14.0583 24.281C12.6375 25.7018 10.7105 26.5 8.70114 26.5C4.51696 26.5 1.125 23.108 1.125 18.9239C1.125 16.9145 1.9232 14.9875 3.344 13.5667L13.9314 2.97933C14.8786 2.03213 16.1633 1.5 17.5028 1.5C20.2923 1.5 22.5536 3.7613 22.5536 6.55076C22.5536 7.89031 22.0214 9.17499 21.0742 10.1222L10.7932 20.4032C10.3196 20.8768 9.6773 21.1429 9.00752 21.1429C7.61279 21.1429 6.48214 20.0122 6.48214 18.6175C6.48214 17.9477 6.74821 17.3054 7.22181 16.8318L17.1964 6.85714"
				stroke={color || "#3E4C55"}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default AttachIconSvg;

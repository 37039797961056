// import DownloadIcon from "assets/SVG/DownloadIconSvg";
// import FullScreenIcon from "assets/SVG/FullScreenIconSvg";
import moment, { Moment } from "moment";
import { useMemo } from "react";
import LineChart, { LineDataEntry } from "../../components/Charts/LineChart";
import useStyles from "./styles/line-data-card";

interface Props {
	title: string;
	data: Array<LineDataEntry>;
	colors: Array<string>;
}

export const LINE_CHART_DATE_FORMAT = "D MMM";

const LineDataCard = (props: Props) => {
	const classes = useStyles();
	const { title, data, colors } = props;

	const { minX, maxX } = useMemo(() => {
		let minDate = moment();
		let maxDate: Moment | null = null;

		data.forEach((item) => {
			if (item.data.length === 0) {
				return;
			}

			const lastItem = item.data[item.data.length - 1];
			const itemMinDate = moment(item.data[0].x);
			const itemMaxDate = moment(lastItem.x);

			if (!minDate || (minDate && itemMinDate.isBefore(minDate))) {
				minDate = itemMinDate;
			}
			if (!maxDate || (maxDate && itemMaxDate.isAfter(maxDate))) {
				maxDate = itemMaxDate;
			}
		});

		return {
			minX: minDate.format(LINE_CHART_DATE_FORMAT),
			maxX: (maxDate as unknown as Moment)?.format(LINE_CHART_DATE_FORMAT),
		};
	}, [data]);

	return (
		<div className={classes.root}>
			<div className={classes.cardTitle}>{title}</div>
			<div className={classes.cardContainer}>
				<div className={classes.iconsContainer}>
					{/* <div style={{ cursor: "pointer" }}>
						<FullScreenIcon size={17} />
					</div> */}
					{/* <div style={{ marginTop: 0, marginLeft: -24, cursor: "pointer" }}>
						<DownloadIcon size={17} />
					</div> */}
				</div>
				<div className={classes.chartContainer}>
					<LineChart data={data} minX={minX} maxX={maxX} colors={colors} />
				</div>
			</div>
		</div>
	);
};

export default LineDataCard;

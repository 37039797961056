import { Box, CircularProgress } from "@material-ui/core";
import WarningIconSvg from "assets/SVG/WarningIconSvg";
import TextButton from "components/Basic/TextButton";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import moment from "moment";
import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
	getScheduledChanges,
	removeScheduledChanges,
	SubscriptionPayload,
} from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import theme from "setup/Theme";
import SubscriptionDialog from "../../../SubscriptionDialog";
import SubscriptionInfoDialog from "../../../SubscriptionInfoDialog";

interface Props {
	style?: CSSProperties;
}

const ScheduledChangesButton = ({ style }: Props) => {
	const [scheduledChanges, setScheduledChanges] = useState<SubscriptionPayload | null>(null);
	const [scheduledChangesDialog, setScheduledChangesDialog] = useState(false);
	const [removeChangesConfirmDialog, setRemoveChangesConfirmDialog] = useState(false);
	const [scheduledChangesLoading, setScheduledChangesLoading] = useState(false);
	const [removeChangesLoading, setRemoveChangesLoading] = useState(false);
	const [subscriptionDialog, setSubscriptionDialog] = useState(false);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const currentTermEndDate = moment
		.unix(currentTeamSubscription?.currentTermEnd || 0)
		.format("DD MMM yyyy");

	const { t } = useTranslation("settings");

	const handleScheduledChangesClick = async () => {
		if (!currentTeamId || scheduledChangesLoading) {
			return;
		}

		setScheduledChangesLoading(true);
		const result = await getScheduledChanges(currentTeamId);
		setScheduledChangesLoading(false);

		if (result) {
			const { planInfo, billingPeriodUnit, userAddons, paymentSourceId: paymentMethodId } = result;
			setScheduledChanges({
				planId: planInfo.id,
				userAddons,
				paymentPeriod: billingPeriodUnit === "month" ? "monthly" : "yearly",
				paymentMethodId,
			});
		}

		setScheduledChangesDialog(true);
	};

	const handleRemoveScheduledChanges = async () => {
		if (!currentTeamId) return;

		setRemoveChangesLoading(true);
		await removeScheduledChanges(currentTeamId);
		setRemoveChangesLoading(false);
	};

	return (
		<>
			<ConfirmationDialog
				open={removeChangesConfirmDialog}
				actionLoading={removeChangesLoading}
				message={t("removeSchedueldChangesConfirm")}
				onAction={handleRemoveScheduledChanges}
				textMaxWidth={500}
				onClose={() => setRemoveChangesConfirmDialog(false)}
			/>
			<SubscriptionDialog open={subscriptionDialog} onClose={() => setSubscriptionDialog(false)} />

			{scheduledChanges && (
				<SubscriptionInfoDialog
					open={scheduledChangesDialog}
					onClose={() => setScheduledChangesDialog(false)}
					scheduledChanges={true}
					actionButtons={[
						{
							text: t("editChanges"),
							secondary: true,
							onClick: () => {
								setScheduledChangesDialog(false);
								setSubscriptionDialog(true);
							},
						},
						{
							text: t("removeScheduledChanges"),
							style: { width: 200 },
							secondary: true,
							onClick: () => {
								setRemoveChangesConfirmDialog(true);
							},
						},
						{
							text: t("ok"),
							onClick: () => {
								setScheduledChangesDialog(false);
							},
						},
					]}
					subscriptionPayload={scheduledChanges}
				/>
			)}

			<Box style={style}>
				<TextButton style={{ fontSize: 12 }} onClick={handleScheduledChangesClick}>
					<Box style={{ marginRight: 8, marginBottom: -4 }}>
						<WarningIconSvg color={theme.palette.text.hint} size={15} />
					</Box>
					{t("scheduledChanges")} - {currentTermEndDate}
					{scheduledChangesLoading && <CircularProgress style={{ marginLeft: 16 }} size={18} />}
				</TextButton>
			</Box>
		</>
	);
};

export default ScheduledChangesButton;

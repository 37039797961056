import { makeStyles } from "@material-ui/core";
import { MonthlyEventsProps } from ".";

const useStyles = makeStyles(() => ({
	daySlot: {
		fontFamily: "Avenir",
		fontSize: 11,
		textAlign: "center",
		width: "14.2%",
		backgroundColor: "white",
		paddingTop: 12,
	},

	dayNumber: {
		textAlign: "center",
		width: (props: MonthlyEventsProps) => (props.mainCalendar ? 28 : 22),
		height: (props: MonthlyEventsProps) => (props.mainCalendar ? 28 : 22),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		margin: (props: MonthlyEventsProps) =>
			props.mainCalendar ? "-4px auto -12px auto" : "-10px auto -12px auto",
		borderRadius: "50%",
		color: "#606060",
		fontSize: (props: MonthlyEventsProps) => (props.mainCalendar ? 14 : 12),
	},

	headerWrapper: {
		fontSize: (props: MonthlyEventsProps) => (props.mainCalendar ? 18 : 12),
		fontWeight: (props: MonthlyEventsProps) => (props.mainCalendar ? 500 : 400),
	},
}));

export default useStyles;

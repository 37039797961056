import axios from "axios";
import { Dispatch } from "react";
import config from "config";
import { getHeaders } from "helpers/utils";

import store, { StoreState } from "../../../setup/store";
import { ReconnectProfileFunc } from "types/ReduxActions";
import {
	LIST_PERMISSIONS,
	LIST_PERMISSIONS_LOADING,
	LIST_SOCIAL_PROFILES,
	SET_PROFILES_LOADING,
} from "../../reducers/socialProfiles";
import { setSnackbarState } from "../snackBar";
import SocialProfile from "types/SocialProfile";
import moment from "moment";
import permissionsIndex from "helpers/permissions";

export const listSocialProfiles = (callback?: () => void) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		dispatch({
			type: SET_PROFILES_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(`${config.apiUrl}/socialProfiles`, {
				params: { teamId: currentTeamId },
			});
			dispatch({
				type: LIST_SOCIAL_PROFILES,
				payload: result.data,
			});
			if (callback) callback();
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "Something went wrong, try again later."));
			if (callback) callback();
			console.log(e.response.data);
		}
	};
};

export const deleteSocialProfile = (_id: string, callback?: (success: boolean) => void) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		let { profiles } = getState().socialProfiles;

		try {
			await axios.delete(`${config.apiUrl}/socialProfiles/${_id}`, {});

			dispatch(
				listSocialProfiles(() => {
					if (callback) callback(true);
				})
			);

			dispatch({
				type: LIST_SOCIAL_PROFILES,
				payload: [...profiles],
			});
		} catch (e) {
			if (callback) callback(false);
			dispatch(setSnackbarState(true, "error", "Something went wrong, try again later."));
			console.log(e);
		}
	};
};

export const listPermissions = (profileId: string) => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: LIST_PERMISSIONS_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(`${config.apiUrl}/socialProfiles/${profileId}/permissions`);

			dispatch({
				type: LIST_PERMISSIONS,
				payload: result.data,
			});
		} catch (e) {
			console.log(e);

			dispatch({
				type: LIST_PERMISSIONS_LOADING,
				payload: false,
			});

			dispatch(setSnackbarState(true, "error", "Error loading permissions, try again later."));
		}
	};
};

export const reconnectProfile: ReconnectProfileFunc = async (selectedProfile, permissions) => {
	const { currentTeamId } = store.getState().teams;
	const { profiles } = store.getState().socialProfiles;
	const headers = getHeaders();
	const { network, type } = selectedProfile;
	const baseUrl = `${config.apiUrl}/socialProfiles`;

	if (network === "facebook") {
		if (type === "profile") {
			window.location.href = `${baseUrl}/${currentTeamId}/addFacebookProfile/${headers.Authorization}`;
		} else if (type === "page") {
			try {
				await axios.get(
					`${baseUrl}/${selectedProfile.parentProfile}/facebook/pages/connect/${selectedProfile.socialId}`
				);

				return true;
			} catch (e) {
				console.log(e);
			}
		}
	} else if (network === "instagram") {
		const parentProfile = profiles.find((profile) => profile._id === selectedProfile.parentProfile);

		if (!parentProfile) {
			return;
		}

		const allInstagramPermissions = Object.keys(permissionsIndex).filter((key) =>
			key.startsWith("instagram")
		);

		const profileInstagramPermissions = permissions?.filter((permission) =>
			permission.startsWith("instagram")
		);

		if (
			(profileInstagramPermissions?.length || 0) < allInstagramPermissions.length ||
			parentProfile?.disconnected
		) {
			window.location.href = `${baseUrl}/${currentTeamId}/addInstagramProfile/${headers.Authorization}`;
			return false;
		}

		await axios.get(
			`${baseUrl}/${selectedProfile.parentProfile}/instagram/accounts/connect/${selectedProfile.metadata?.page_id}/${selectedProfile.socialId}`
		);

		return true;
	} else if (network === "linkedin") {
		if (type === "page") {
			await axios.get(
				`${baseUrl}/${selectedProfile.parentProfile}/linkedin/pages/connect/${selectedProfile.socialId}`
			);
			return true;
		} else {
			window.location.href = `${baseUrl}/${currentTeamId}/addLinkedInProfile/${headers.Authorization}`;
		}
	} else if (network === "twitter") {
		window.location.href = `${baseUrl}/${currentTeamId}/addTwitterProfile/${headers.Authorization}`;
	}
};

export const validateProfileToken = async (profileId: string) => {
	const dispatch = store.dispatch as Dispatch<any>;

	try {
		const result = await axios.get(`${config.apiUrl}/socialProfiles/${profileId}/validate-token`);
	} catch (e) {
		console.log(e);
		dispatch(setSnackbarState(true, "error", "SomethingWentWrong"));
	}
};

export const isProfileDisconnected = (profile: SocialProfile) => {
	return (
		profile.disconnected ||
		(profile.tokenExpiresAt && moment().diff(moment(profile.tokenExpiresAt)) > 0)
	);
};

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 6,
		paddingLeft: 5,
		width: "98%",
		height: 60,
		marginTop: 8,
		marginRight: 8,
		border: "1px solid #E5E5E5",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		cursor: "pointer",
		overflow: "hidden",
	},

	postContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "50%",
	},



	textContent: {
		color: theme.palette.text.primary,
		fontSize: 12,
		fontFamily: "Avenir",
		paddingLeft: 24,
	},

	socialDetails: {
		width: "30%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	socialIcon: {
		marginRight: 8,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	profilePicture: {
		height: 25,
		width: 25,
		objectFit: "cover",
		borderRadius: "50%",
		marginRight: 8,
	},

	username: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
	},

	postActions: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},

	actionText: {
		width: 200,
		fontFamily: "Avenir",
		textAlign: "center",
		color: theme.palette.text.primary,
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		border: "1px solid #D5E1F5",
		width: 310,
		height: 140,
		backgroundColor: "white",
		display: "flex",
		flexDirection: "row",
		marginLeft: 8,
		cursor: "pointer",
	},

	left: {
		width: 300,
		display: "flex",
		flexDirection: "column",
	},

	attachment: {
		padding: 16,
		paddingBottom: 14,
		paddingRight: 8,
		height: 152,
		marginTop: -8,
		borderRadius: 12,
		objectFit: "cover",
		width: "100%",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		marginLeft: 8,
		marginTop: 8,
		alignItems: "center",
	},

	userInfo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	userImage: {
		height: 25,
		width: 25,
		objectFit: "cover",
		borderRadius: "50%",
	},

	username: {
		fontSize: 11,
		paddingLeft: 12,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: 100,
	},

	socialIcon: {
		marginLeft: "auto",
		marginTop: 4,
		marginRight: -4,
	},

	content: {
		letterSpacing: 0.05,
		width: 152,
		paddingLeft: 8,
		paddingTop: 4,
		marginTop: 8,
		lineHeight: 1.5,
		maxHeight: 60,
		fontFamily: "Avenir",
		fontSize: 11,
		color: theme.palette.text.primary,
		wordWrap: "break-word",
		textOverflow: "ellipsis",
	},

	buttons: {
		display: "flex",
		flexDirection: "row",
		width: "fit-content",
		marginTop: "auto",
		marginBottom: 8,
		marginLeft: 8,
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		backgroundColor: "#F7F7F7",
		display: "flex",
		flexDirection: "row",
		position: "fixed",
		height: 65,
		paddingTop: 8,
		width: "100%",
		top: 0,
		zIndex: 5,
		transition: "0.8s",
	},

	logoContainer: {
		marginLeft: 8,
		position: "fixed",
		left: -7,
	},

	logo: {
		height: 50,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		height: 55,
		marginLeft: "auto",
		marginRight: "auto",
		zIndex: 99999,
	},

	pageName: {
		fontFamily: "Chau Philomene One",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		maxWidth: "70%",
		marginBottom: -6,
		fontSize: 26,
		color: theme.palette.primary.main,
	},

	rightSection: {
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	notificationIcon: {
		marginLeft: 24,
		width: 26,
		cursor: "pointer",
	},

	notificationActiveCircle: {
		position: "absolute",
		width: 8,
		height: 8,
		borderRadius: "50%",
		top: 18,
		right: 1,
		background: "linear-gradient(137.86deg, #F05D6A 14.29%, #F0765D 85.89%)",
	},

	bellContainer: {
		position: "relative",
		marginLeft: 24,
		cursor: "pointer",
	},

	teamIconContainer: {
		border: "solid #F04755",
		borderWidth: 2,
		borderRadius: "50%",
		marginLeft: 16,
		width: 28,
		height: 28,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		objectFit: "cover",
	},
}));

export default useStyles;

import axios from "axios";
import moment from "moment";
import { Dispatch } from "react";
import config, { ExternalLogoUrl } from "config";

import i18n from "i18next";

import store, { StoreState } from "../../../setup/store";
import NotificationType from "types/Notification";
import {
	GetNotificationContentFunc,
	GetNotificationsFunc,
	HandleNotificationClickFunc,
	ReadNotificationsFunc,
} from "types/ReduxActions";
import {
	LIST_MODAL_NOTIFICATIONS,
	LIST_READ_NOTIFICATIONS,
	LIST_UNREAD_NOTIFICATIONS,
	NOTIFICATIONS_LOADING,
} from "../../reducers/notifications";
import { getPostById } from "../posts/get";
import { setSnackbarState } from "../snackBar";
import { setCampaignDialog, setPostPreviewDialog, setTaskDialog } from "../modals";
import Campaign from "types/Campaign";
import { getTaskById } from "../tasks/get";
import { getSettingPageRoute } from "pages/Settings";
import { getSocialProfileRoute } from "setup/router/utils";

export type NotificationsQuery = {
	read?: boolean;
	type?: string;
	date?: string;
};

export const getNotifications: GetNotificationsFunc = (query, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		dispatch({
			type: NOTIFICATIONS_LOADING,
			payload: true,
		});
		const { read, type, date } = query;

		let params: { [index: string]: string | boolean } = {
			read: read || false,
		};

		if (type && type !== "all") {
			params.type = type;
		}

		if (date) {
			params.date = date;
		}

		try {
			const result = await axios.get(`${config.apiUrl}/notifications?team=${currentTeamId}`, {
				params,
			});

			const { notifications, count: totalCount } = result.data;

			dispatch({
				type:
					read === false
						? LIST_UNREAD_NOTIFICATIONS
						: read === true
						? LIST_READ_NOTIFICATIONS
						: LIST_MODAL_NOTIFICATIONS,
				payload: {
					notifications,
					totalCount,
				},
			});

			if (callback) {
				callback(notifications);
			}
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "Error fetching notifications..."));
			dispatch({
				type: NOTIFICATIONS_LOADING,
				payload: false,
			});
		}
	};
};

export const readNotifications: ReadNotificationsFunc = (notifications) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		try {
			await axios.post(`${config.apiUrl}/notifications/read?team=${currentTeamId}`, {
				notifications,
			});

			dispatch({
				type: LIST_MODAL_NOTIFICATIONS,
				payload: { notifications: [], totalCount: 0 },
			});
		} catch (e) {
			console.log(e);
		}
	};
};

export const getNotificationContent: GetNotificationContentFunc = (notification, noHtml) => {
	const { content, reference, sender } = notification;
	let template = "";

	const date =
		reference?.model?.startDate &&
		moment().dayOfYear() === moment(reference.model.startDate).dayOfYear()
			? "today"
			: "tomorrow";

	template = i18n.t(`notifications:${noHtml ? content : `HTML${content}`}`, {
		campaignTitle: reference?.model?.title,
		sender: `${sender?.firstName} ${sender?.lastName}`,
		day: date,
		teamName: reference?.model?.name,
		profileName: reference?.model?.displayName || reference?.model?.username,
	});

	return template;
};

export const handleNotificationClick: HandleNotificationClickFunc = (notification, history) => {
	return new Promise((resolve) => {
		const { model, type } = notification.reference || {};
		const dispatch = store.dispatch as Dispatch<any>;

		if (!model && type) {
			resolve(true);
			return dispatch(setSnackbarState(true, "error", `${type} no longer exists`));
		}

		if (type === "Post") {
			dispatch(
				getPostById(model._id!, (post) => {
					if (post) {
						dispatch(setPostPreviewDialog(true, false, post));
					}
					resolve(true);
				})
			);
		} else if (type === "Campaign") {
			dispatch(setCampaignDialog(true, model as Campaign));
			resolve(true);
		} else if (type === "Task") {
			dispatch(
				getTaskById(model._id!, (task) => {
					if (task) {
						dispatch(setTaskDialog(true, task));
					}
					resolve(true);
				})
			);
		} else if (type === "Team") {
			history.push(getSettingPageRoute("workspaces"));
			resolve(true);
		} else if(type === 'SocialProfile') {
			history.push(getSocialProfileRoute(model._id!))
		} else {
			resolve(true);
		}
	});
};

export const sendDesktopNotification = (notification: NotificationType) => {
	const { userData } = store.getState().user;
	const { content } = notification;

	if (userData?.settings) {
		const {
			desktopRemindersNotifs,
			desktopNewCampaignStartNotifs,
			desktopCurrentCampaignFinishNotifs,
			desktopTasksNotifs,
		} = userData.settings;

		if (
			content === "CampaignStartReminder" &&
			desktopNewCampaignStartNotifs &&
			desktopNewCampaignStartNotifs !== "enabled"
		)
			return;
		if (
			content === "CampaignEndReminder" &&
			desktopCurrentCampaignFinishNotifs &&
			desktopCurrentCampaignFinishNotifs !== "enabled"
		)
			return;
		if (
			(content === "NoteReminder" || content === "CalendarEventReminder") &&
			desktopRemindersNotifs &&
			desktopRemindersNotifs !== "enabled"
		)
			return;
		if (content === "NewTaskAssigned" && desktopTasksNotifs !== "enabled") {
			return;
		}
	}

	if (Notification.permission !== "granted") {
		Notification.requestPermission();
	} else {
		const desktopNotif = new Notification("HashtagHero Notification", {
			icon: ExternalLogoUrl,
			body: getNotificationContent(notification, true),
		});

		desktopNotif.onclick = function () {
			window.open("https://api.hashtaghero.io"); // TODO: Replace with a relevant URL
		};
	}
};

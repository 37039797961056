import axios from "axios";
import { Dispatch } from "react";
import config from "config";

import { StoreState } from "../../../setup/store";
import { SubmitTaskFunc } from "types/ReduxActions";
import Task from "types/Task";
import UserData from "types/User/UserData";
import {
	LIST_CURRENT_USER_TASKS,
	LIST_TASKS,
	SET_TASKS_SEARCH_RESULT,
	SUBMIT_TASK_ERROR,
	SUBMIT_TASK_LOADING,
	SUBMIT_TASK_SUCCESS,
} from "../../reducers/tasks";
import { uploadMediaArray } from "../media/upload";

const validateInputs = (inputValues: Partial<Task>, selectedTaskId?: string) => {
	let errors: { [index: string]: string } = {};
	const { title } = inputValues;

	if ((title === "" && selectedTaskId) || (!selectedTaskId && !title)) {
		errors.title = "TitleRequired";
	}

	return errors;
};

export const submitTask: SubmitTaskFunc = (
	inputValues,
	selectedTaskId,
	onProgressUpdate,
	callback
) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;
		const { currentTeamId } = getState().teams;
		const { tasks, currentUserTasks, tasksSearchResult, totalCurrentUserTasks, tasksQueryTotal } =
			getState().tasks;
		const errors = validateInputs(inputValues, selectedTaskId);

		if (!currentTeamId || !userData) {
			return;
		}

		dispatch({
			type: SUBMIT_TASK_LOADING,
			payload: true,
		});

		if (Object.keys(errors).length > 0) {
			callback && callback(undefined);
			return dispatch({
				type: SUBMIT_TASK_ERROR,
				payload: errors,
			});
		}

		let payload: { [index: string]: any } = {
			...inputValues,
			socialProfiles: inputValues.socialProfiles
				? inputValues.socialProfiles.map((profile) => profile._id)
				: undefined,
			assignees: inputValues.assignees
				? inputValues.assignees.map((profile) => profile._id)
				: undefined,
		};

		Object.keys(payload).forEach((key) => {
			if (!payload[key] && typeof payload[key] !== "string" && typeof payload[key] !== "number") {
				delete payload[key];
			}
		});

		if (payload.media) {
			payload.media = await uploadMediaArray(payload.media, onProgressUpdate);
		}

		let url = `${config.apiUrl}/tasks`;

		if (selectedTaskId) {
			url = `${config.apiUrl}/tasks/${selectedTaskId}`;
		}

		try {
			if (selectedTaskId) {
				const result = await axios.put(url, payload, {
					params: { teamId: currentTeamId },
				});

				const currentUserAssigned = result.data.assignees.find(
					(user: UserData) => user._id === userData._id
				);

				const currentUserTaskIndex = currentUserTasks.findIndex(
					(task) => task._id === selectedTaskId
				);

				if (currentUserTaskIndex > -1) {
					if (!currentUserAssigned) {
						currentUserTasks.splice(currentUserTaskIndex, 1);
					} else {
						currentUserTasks[currentUserTaskIndex] = {
							...result.data,
						};
					}
				}

				if (currentUserTaskIndex < 0 && currentUserAssigned) {
					currentUserTasks.unshift(result.data);
				}

				dispatch({
					type: LIST_CURRENT_USER_TASKS,
					payload: {
						currentUserTasks: [...currentUserTasks],
						totalCurrentUserTasks: totalCurrentUserTasks + 1,
					},
				});

				// Update tasks list
				const taskIndex = tasks.findIndex((task) => task._id === result.data._id);

				if (taskIndex > -1) {
					tasks[taskIndex] = {
						...result.data,
					};
				}

				dispatch({
					type: LIST_TASKS,
					payload: {
						tasks: [...tasks],
					},
				});

				// Update search result list
				const searchResultTaskIndex = tasksSearchResult.findIndex(
					(task) => task._id === result.data._id
				);

				if (searchResultTaskIndex > -1) {
					tasksSearchResult[searchResultTaskIndex] = {
						...result.data,
					};
				}

				dispatch({
					type: SET_TASKS_SEARCH_RESULT,
					payload: {
						tasks: [...tasksSearchResult],
					},
				});

				callback && callback(result.data);
			} else {
				const result = await axios.post(url, payload, {
					params: { teamId: currentTeamId },
				});

				// Add the new task to current list of tasks and tasks count
				dispatch({
					type: LIST_TASKS,
					payload: {
						tasks: [result.data, ...tasks],
						tasksQueryTotal: tasksQueryTotal + 1,
					},
				});
				callback && callback(result.data);
			}
			dispatch({
				type: SUBMIT_TASK_SUCCESS,
				payload: true,
			});
		} catch (e) {
			console.log(e.response?.data || e);
			if (e.response?.data) {
				dispatch({
					type: SUBMIT_TASK_ERROR,
					payload: e.response?.data,
				});
			}

			callback && callback(undefined);
		}
	};
};

import { useEffect, useState } from "react";
import TablePost from "modules/Posts/TablePost";
import Post from "types/Post";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import useStyles from "../styles/table-view-styles";
import { ScheduleItem } from "..";
interface Props {
	tableItem: ScheduleItem;
	handlePostClick: (post: Post) => void;
}

const TableViewItem = (props: Props) => {
	const classes = useStyles();
	const [maxScrollLeft, setMaxScrollLeft] = useState<boolean>(true);
	const [maxScrollRight, setMaxScrollRight] = useState<boolean>(false);
	const { tableItem, handlePostClick } = props;

	useEffect(() => {
		const container = document.getElementById(`tableItem-${tableItem.id.toString()}`);
		if (!container) return;

		container.onscroll = () => {
			if (container.scrollWidth - container.scrollLeft <= 931) {
				setMaxScrollRight(true);
			} else {
				setMaxScrollRight(false);
			}

			if (container.scrollLeft === 0) {
				setMaxScrollLeft(true);
			} else {
				setMaxScrollLeft(false);
			}
		};
	}, []);

	const scrollToRight = () => {
		const container = document.getElementById(`tableItem-${tableItem.id.toString()}`);
		if (!container) return;

		container.scrollBy({
			left: 200,
			behavior: "smooth",
		});
	};

	const scrollToLeft = () => {
		const container = document.getElementById(`tableItem-${tableItem.id.toString()}`);
		if (!container) return;

		container.scrollBy({
			left: -300,
			behavior: "smooth",
		});
	};

	return (
		<div className={classes.container}>
			<div className={classes.date}>
				<span className={classes.dateText}>{tableItem.date}</span>
			</div>
			{!maxScrollLeft && (
				<div className={classes.backArrow} onClick={scrollToLeft}>
					<NavigateBeforeIcon />
				</div>
			)}
			<div className={classes.postsContainer} id={`tableItem-${tableItem.id.toString()}`}>
				{tableItem.posts.map((post, index) => (
					<TablePost onClick={handlePostClick} key={index} post={post} />
				))}
			</div>
			{!maxScrollRight && tableItem.posts.length > 3 && (
				<div className={classes.nextArrow} onClick={scrollToRight}>
					<NavigateNextIcon />
				</div>
			)}
		</div>
	);
};

export default TableViewItem;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	title: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		fontSize: 14,
	},

	postPreview: {
		display: "flex",
		flexDirection: "row",
		border: "1px solid #E5E5E5",
		width: 160,
		height: 80,
		padding: 6,
	},

	chartContainer: {
		height: 100,
		width: 170,
		marginTop: 16,
	},

	socialStatsContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},

	postImage: {
		width: "45%",
		height: "auto",
		objectFit: "cover",
		marginRight: 12,
	},

	postText: {
		fontSize: 12,
		width: "50%",
		fontWeight: 300,
	},

	postDate: {
		fontSize: 10,
		fontWeight: 500,
		color: theme.palette.text.primary,
		marginTop: 4,
	},
}));

export default useStyles;

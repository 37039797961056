import { Box, RadioGroup } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { PaymentPeriod, SubscriptionPayload } from "redux/actions/team/subscriptions/plans";
import { useTranslation } from "react-i18next";
import BillingMethods from "./BillingMethods";
import { PaymentMethod } from "types/Billing";
import useSubscriptionDialogStyles from "../styles";
import RadioWithLabel from "components/Basic/Inputs/Radio";
import RadioWithlabel from "components/Basic/Inputs/Radio";
import UserAddonsInput from "./UserAddonsInput";

interface Props {
	payload: Partial<SubscriptionPayload>;
	setPayload: Dispatch<SetStateAction<Partial<SubscriptionPayload>>>;
}

const SubscriptionDialogInputs = (props: Props) => {
	const { payload, setPayload } = props;
	const { t } = useTranslation("settings");
	const { paymentPeriod } = payload;
	const classes = useSubscriptionDialogStyles();

	return (
		<Box>
			<Box style={{ marginBottom: 32 }}>
				<BillingMethods
					selectedPaymentMethodId={payload.paymentMethodId}
					onPaymentMethodSelect={(method) => setPayload({ ...payload, paymentMethodId: method.id })}
				/>
			</Box>

			<Box display="flex" style={{ gap: 24 }}>
				{/* Pre-paid Billing Period Card */}
				<Box className={classes.inputCard}>
					<Box className={classes.inputCardTitle}>{t("prepaidBillingPeriod")}</Box>
					<RadioGroup
						style={{ padding: 8 }}
						value={paymentPeriod}
						onChange={(e) =>
							setPayload({ ...payload, paymentPeriod: e.target.value as PaymentPeriod })
						}
					>
						<RadioWithLabel label={t("monthly")} value="monthly" />
						<RadioWithlabel label={t("yearly")} value="yearly" />
					</RadioGroup>
				</Box>

				<UserAddonsInput payload={payload} setPayload={setPayload} />
			</Box>
		</Box>
	);
};

export default SubscriptionDialogInputs;

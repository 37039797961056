import { Box } from "@material-ui/core";
import LogoSvg from "assets/SVG/LogoSvg";
import SearchInput from "components/Basic/Inputs/SearchInput";
import HintText from "components/Basic/Text/HintText";
import TitleText from "components/Basic/Text/TitleText";
import Tabs, { Tab } from "components/Tabs";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";

interface Props {
	extended: boolean;
	selectedTab: Tab | undefined;
	searchValue: string;
	onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onTabChange: (selectedTab: Tab) => void;
}

const DrawerHeader: React.FC<Props> = (props) => {
	const { extended, selectedTab, searchValue, onSearchChange, onTabChange } = props;
	const { t } = useTranslation("posts");

	return (
		<>
			{extended ? (
				<Box
					width="fit-content"
					marginLeft="auto"
					marginRight="auto"
					display="flex"
					alignItems="center"
					marginTop={2}
				>
					<LogoSvg size={20} />
					<TitleText style={{ marginLeft: 8, whiteSpace: "nowrap" }} primary>
						{t("hashtagsManager")}
					</TitleText>
				</Box>
			) : (
				<Box style={{ marginTop: 12, paddingLeft: 32 }}>
					<TitleText primary>{t("popularHashtags")}</TitleText>
					<HintText style={{ marginTop: 6 }}>{t("last24Hours")}</HintText>
				</Box>
			)}
			<Box style={{ paddingLeft: 32 }}>
				{extended && (
					<>
						<Box width="fit-content" marginTop={4}>
							<Tabs
								compact
								tabs={["popular", "recent", "saved", "topics"]}
								selectedTab={selectedTab}
								onTabChange={onTabChange}
							/>
						</Box>

						<Box style={{ fontSize: 12, marginBottom: 4, marginTop: 16 }}>
							Search {t(selectedTab?.name === "topics" ? "topics" : "hashtags")}
						</Box>
						<SearchInput
							onChange={onSearchChange}
							value={searchValue}
							style={{ marginBottom: -16, width: "90%" }}
						/>
					</>
				)}
			</Box>
		</>
	);
};

export default DrawerHeader;

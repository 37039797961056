import { makeStyles } from "@material-ui/core";

const useCurrentSubscriptionPlanStyles = makeStyles((theme) => ({
	title: {
		fontFamily: "Chau Philomene One",
		fontSize: 16,
		color: "#868C91",
	},

	planTitle: {
		textTransform: "uppercase",
		fontWeight: 700,
		color: theme.palette.primary.main,
		fontSize: 16,
	},

	priceTitle: {
		color: "#868C91",
		fontFamily: "Chau Philomene One",
		fontSize: 32,
		marginTop: -8,
	},

	priceSubtitle: {
		fontFamily: "Avenir",
		color: "#868C91",
		fontSize: 12,
	},

	planDescription: {
		fontFamily: "Avenir",
		color: theme.palette.text.primary,
		maxWidth: "70%",
		marginTop: 16,
		fontSize: 12,
	},

	actionButtonsContainer: {
		display: "flex",
		alignItems: "center",
		position: "absolute",
		bottom: 32,
		right: 32,
	},

	actionButton: {
		width: 150,
		marginTop: 12,
		fontSize: 12,
		fontWeight: 500,
	},

	textButton: {
		marginLeft: 22,
		marginRight: 8,
		marginTop: 4,
		fontSize: 11,
	},
}));

export default useCurrentSubscriptionPlanStyles;

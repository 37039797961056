import { makeStyles } from "@material-ui/core";

const usePageStyles = makeStyles((theme) => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "90vh",
		paddingTop: 12,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	dataItemTitle: {
		fontFamily: "Avenir",
		fontSize: 12,
		marginBottom: 1,
	},

	dataItemValue: {
		fontWeight: 500,
		fontSize: 16,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignitems: "center",
	},

	dataBox: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		borderRadius: 12,
		backgroundColor: "white",
		height: 300,
		marginTop: 32,
		padding: 16,
		paddingLeft: 32,
		paddingRight: 32,
	},

	section: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		marginTop: 32,
	},

	subSection: {
		width: "50%",
		borderRadius: 12,
	},

	leftSubSection: {
		width: "56%",
		marginRight: 24,
		borderRadius: 12,
	},

	hashtagText: {
		color: "#4285F4",
		fontSize: 12,
		marginTop: 6,
	},

	label: {
		color: theme.palette.text.primary,
		fontSize: 12,
		opacity: 0.9,
	},

	value: {
		color: theme.palette.text.primary,
		fontSize: 12,
		fontWeight: 500,
	},

	title: {
		color: theme.palette.text.primary,
		fontWeight: 500,
		fontSize: 14,
	},
}));

export default usePageStyles;

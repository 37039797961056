import { CircularProgress } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReconnectIcon from "assets/SVG/ReconnectIvonSvg";
import Button from "components/Basic/Button";
import { listSocialProfiles, reconnectProfile } from "redux/actions/socialProfiles/socialProfiles";
import { StoreState } from "../../../../../setup/store";
import SocialProfile from "types/SocialProfile";
import useProfileDetailsStyles from "../../styles/social-profile-details";
import { setSnackbarState } from "redux/actions/snackBar";

interface Props {
	profile: SocialProfile;
	setDeleteDialog: Dispatch<SetStateAction<{ open: boolean; message: string }>>;
	setRedirectConfirmation: Dispatch<SetStateAction<boolean>>;
}

const SocialProfileDetailsActions = (props: Props) => {
	const { profiles, permissions, permissionsLoading } = useSelector(
		(state: StoreState) => state.socialProfiles
	);
	const [reconnectLoading, setReconnectLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { profile, setRedirectConfirmation, setDeleteDialog } = props;
	const classes = useProfileDetailsStyles();

	const handleDeleteClick = () => {
		if (!profile) return;

		let message = "Are you sure you want to remove this Social Profile?";
		let childProfilesCount = 0;
		profiles.forEach((socialProfile) => {
			if (socialProfile.parentProfile === profile._id) {
				childProfilesCount += 1;
			}
		});

		if (childProfilesCount > 0) {
			message = `You are about to remove access to this Social Account. 
            With this action you will also lose access to ${childProfilesCount} Social Pages.

            Are you sure?`;
		}

		setDeleteDialog({ open: true, message });
	};

	const handleReconnectClick = async () => {
		if (!profile) return;

		setReconnectLoading(true);
		try {
			const success = await reconnectProfile(
				profile,
				permissions
					.filter((permission) => permission.status === "granted")
					.map((permission) => permission.permission)
			);
			if (success) {
				dispatch(setSnackbarState(true, "success", "ProfileReconnectSuccess"));
				dispatch(
					listSocialProfiles(() => {
						setReconnectLoading(false);
					})
				);
			}
		} catch (e) {
			setReconnectLoading(false);
		}
	};

	return (
		<div className={classes.buttonsContainer}>
			{profile.network === "facebook" && (
				<Button
					onClick={() => setRedirectConfirmation(true)}
					style={{ width: 150, marginRight: 16 }}
					secondary
				>
					Change Permissions
				</Button>
			)}

			<Button onClick={handleDeleteClick} style={{ width: 150 }} secondary>
				Remove Social Profile
			</Button>

			<div className={classes.rightContainer}>
				<Button
					loading={reconnectLoading}
					disabled={reconnectLoading || permissionsLoading}
					className={classes.button}
					onClick={handleReconnectClick}
				>
					<ReconnectIcon size={15} />
					<span style={{ marginLeft: 8 }}>Reconnect</span>
				</Button>
			</div>
		</div>
	);
};

export default SocialProfileDetailsActions;

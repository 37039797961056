import { makeStyles } from "@material-ui/core";

const useAnalyticsStyles = makeStyles((theme) => ({
	sectionTitle: {
		color: "#5B666E",
		fontWeight: 700,
		paddingBottom: 16,
		paddingLeft: 24,
		fontSize: 16,
	},

	section: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	card: {
		height: 350,
		width: "50%",
		marginRight: 12,
		backgroundColor: "white",
		borderRadius: 10,
	},
}));

export default useAnalyticsStyles;

const DownloadIconSvg = ({
	size,
	color,
	strokeWidth,
}: {
	strokeWidth?: number;
	size?: number;
	color?: string;
}) => {
	return (
		<svg
			width={size || 24}
			height={size || 24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 17.2L6.58333 12.4M12 17.2L17 12.4M12 17.2V2M22 11.6V22H2V11.6"
				strokeWidth={strokeWidth}
				stroke={color || "#3E4C55"}
			/>
		</svg>
	);
};

export default DownloadIconSvg;

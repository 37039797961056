import { Box } from "@material-ui/core";
import { OnBoardingAvatar } from "assets";
import Button from "components/Basic/Button";
import TextButton from "components/Basic/TextButton";
import { onboardingQuery } from "config";
import { useQuery } from "helpers/utils";
import { getSettingPageRoute, selectedSettingTabQuery } from "pages/Settings";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setOnboardingStep, updateUser } from "redux/actions/user/user-data";
import { StoreState } from "setup/store";
import { OnboardingAction, onboardingSteps } from "./data";
import useOnboardingStyles from "./styles";

const Onboarding = () => {
	const { t } = useTranslation("common");
	const { userData, onboardingStepName } = useSelector((state: StoreState) => state.user);
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const classes = useOnboardingStyles();
	const query = useQuery();
	const history = useHistory();
	const dispatch = useDispatch();

	const onboardingStep = useMemo(
		() => onboardingStepName && onboardingSteps[onboardingStepName],
		[onboardingStepName]
	);

	const contentData = useMemo(
		() => ({
			firstName: userData?.firstName || "",
			subscriptionPlanName: currentTeamSubscription?.planInfo.title || "",
			socialProfilesLimit:
				(currentTeamSubscription?.planInfo.features.socialProfiles as number) || 0,
		}),
		[userData, currentTeamSubscription]
	);

	const handleActionClick = (action: OnboardingAction) => {
		const { type } = action;

		if (type === "next" && onboardingStepName === "home") {
			dispatch(setOnboardingStep("home2"));
		} else if (type === "addSocialProfile") {
			history.push(`${getSettingPageRoute("socialProfiles")}&${onboardingQuery}=true`);
		} else if (type === "seeMySubscriptionPlan") {
			history.push(getSettingPageRoute("billingAndPayments", "plans"));
			dispatch(updateUser({ onboardingCompleted: true, preventLoading: true }));
		} else if (type === "close") {
			dispatch(setOnboardingStep(null));
			dispatch(updateUser({ onboardingCompleted: true, preventLoading: true }));
		}
	};

	const handleSkipOnboardingClick = () => {
		dispatch(setOnboardingStep(null));
		dispatch(updateUser({ onboardingCompleted: true }));
	};

	useEffect(() => {
		if (!userData || userData.onboardingCompleted) {
			dispatch(setOnboardingStep(null));
			return;
		}

		if (history.location.pathname === "/" || history.location.pathname === "/home") {
			dispatch(setOnboardingStep("home"));
		} else if (
			history.location.pathname === `/settings` &&
			query.get(selectedSettingTabQuery) === "socialProfiles" &&
			query.get(onboardingQuery)
		) {
			dispatch(setOnboardingStep("socialProfiles"));
		} else {
			dispatch(setOnboardingStep(null));
		}
	}, [userData, history.location]);

	if (!onboardingStep) {
		return null;
	}

	return (
		<Box className={classes.container} style={{ pointerEvents: "none" }}>
			<Box style={{ pointerEvents: "all" }} className={classes.boxAndArrowContainer}>
				<Box className={classes.box}>
					{onboardingStep.getContent(contentData)}

					<Box className={classes.actions}>
						{onboardingStep.actions.map((action, index) => (
							<Button
								style={{ width: "fit-content", paddingLeft: 16, paddingRight: 16, minWidth: 100 }}
								onClick={() => handleActionClick(action)}
								secondary={action.secondary}
								key={index}
							>
								{t(action.type)}
							</Button>
						))}
					</Box>

					<Box width="fit-content" marginLeft="auto" marginRight="auto" style={{ marginTop: 12 }}>
						<TextButton onClick={handleSkipOnboardingClick}>{t("skipOnBoarding")}</TextButton>
					</Box>
				</Box>
				<Box className={classes.arrow}></Box>
			</Box>

			<img className={classes.onboardingAvatar} src={OnBoardingAvatar} />
		</Box>
	);
};

export default Onboarding;

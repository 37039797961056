import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: 10,
	},

	dialogContainer: {
		width: 600,
		height: 300,
		padding: 24,
		background: "white",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingLeft: 16,
		paddingRight: 16,
	},

	headerText: {
		fontSize: 20,
		fontFamily: "Avenir",
		letterSpacing: 0.5,
	},

	closeIconContainer: {
		marginLeft: "auto",
		width: "fit-content",
		cursor: "pointer",
	},

	hashtags: {
		display: "flex",
		flexDirection: "column",
		paddingBottom: 16,
		paddingTop: 24,
	},

	hashtag: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		fontSize: 14,
		fontFamily: "Avenir",
		color: theme.palette.text.primary,
		width: "100%",
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 8,
	},
}));

export default useStyles;

const EmojiIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || "30"}
			height={size || "30"}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.4429 17.0786L9.84286 16.2786L8.24286 17.4786L8.84286 18.2786L10.4429 17.0786ZM21.1571 18.2786L21.7571 17.4786L20.1571 16.2786L19.5571 17.0786L21.1571 18.2786ZM15 26.5C8.64873 26.5 3.5 21.3513 3.5 15H1.5C1.5 22.4558 7.54416 28.5 15 28.5V26.5ZM26.5 15C26.5 21.3513 21.3513 26.5 15 26.5V28.5C22.4558 28.5 28.5 22.4558 28.5 15H26.5ZM15 3.5C21.3513 3.5 26.5 8.64873 26.5 15H28.5C28.5 7.54416 22.4558 1.5 15 1.5V3.5ZM15 1.5C7.54416 1.5 1.5 7.54416 1.5 15H3.5C3.5 8.64873 8.64873 3.5 15 3.5V1.5ZM8.75 12.4286H10.5357V10.4286H8.75V12.4286ZM19.4643 12.4286H21.25V10.4286H19.4643V12.4286ZM19.5571 17.0786C17.2786 20.1167 12.7214 20.1167 10.4429 17.0786L8.84286 18.2786C11.9214 22.3833 18.0786 22.3833 21.1571 18.2786L19.5571 17.0786Z"
				fill={color || "#4B7FA3"}
			/>
		</svg>
	);
};

export default EmojiIconSvg;

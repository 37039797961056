export const permissionsIndex: Record<string, string> = {
	email: "Read Primary Email",
	pages_show_list: "Pages List",
	pages_read_engagement: "Page Content",
	pages_manage_metadata: "Page Updates and Activity",
	pages_read_user_content: "User Content",
	pages_manage_posts: "Manage Posts",
	pages_manage_engagement: "Manage Engagement",
	public_profile: "Public Profile",
	instagram_basic: "Instagram Access",
	// TODO: Re-enable when the reply page is ready for production
	// instagram_manage_messages: "Instagram Messaging",
	// instagram_manage_comments: "Manage Instagram Comments",
	// instagram_manage_insights: "Manage Instagram Insights",
	instagram_content_publish: "Publish Instagram Content",
	pages_messaging: "Pages Messaging"
};

export default permissionsIndex;

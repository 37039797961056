import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PlusButtonSvg from "assets/SVG/PlusButtonSvg";
import Button from "components/Basic/Button";
import { StoreState } from "../../setup/store";

interface Props {
	onAddClick: () => void;
}

const useStyles = makeStyles(() => ({
	button: {
		position: "absolute",
		right: 24,
		top: 16,
	},
}));

const AddTaskButton = ({ onAddClick }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation("tasks");
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);

	return (
		<Box className={classes.button}>
			{(currentTeamRole === "owner" || currentTeamRole === "admin") && (
				<Button onClick={onAddClick} style={{ width: 120 }}>
					<Box display="flex" alignItems="center" justifyContent="center">
						<PlusButtonSvg size={18} />
						<Box style={{ marginLeft: 8 }}>{t("addTask")}</Box>
					</Box>
				</Button>
			)}
		</Box>
	);
};

export default AddTaskButton;

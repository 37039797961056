import { ChangeEvent, useState } from "react";
import useAuthStyles from "../styles";
import { KeyboardEvent } from "react";
import Input from "components/Basic/Inputs/Input";
import Button from "components/Basic/Button";
import { CircularProgress } from "@material-ui/core";
import { validatePassword } from "helpers/utils";
import PasswordValidator from "../SignUp/PasswordValidator";
import { useDispatch } from "react-redux";
import { confirmPassword } from "redux/actions/auth/password";
import { setSnackbarState } from "redux/actions/snackBar";
import SnackBar from "components/Basic/Snackbar";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

interface Props {
	email: string;
}

const NewPasswordEntry = (props: Props) => {
	const classes = useAuthStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation("auth");
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<{ [index: string]: string }>({});
	const [inputValues, setInputValues] = useState<{ [index: string]: string }>({});
	const history = useHistory();
	const { email } = props;

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputValues({ ...inputValues, [name]: value });
		setErrors({ ...errors, [name]: "" });
	};

	const validateInputs = () => {
		let errors: { [index: string]: string } = {};
		const { code, password, confirmPassword } = inputValues;

		if (!code || code.length < 6) {
			errors.code = t("invalidCode");
		}

		if (!password || !validatePassword(password)) {
			errors = {
				...errors,
				password: t("invalidPassword"),
			};
		}

		if (confirmPassword !== password) {
			errors = {
				...errors,
				confirmPassword: t("passwordsDoNotMatch"),
			};
		}

		setErrors({ ...errors });

		return Object.keys(errors).length > 0;
	};

	const handleSubmit = () => {
		if (validateInputs()) return;

		setLoading(true);

		const { code, password } = inputValues;

		dispatch(
			confirmPassword(email, code, password, (success, error) => {
				setLoading(false);
				if (success) {
					dispatch(setSnackbarState(true, "success", t("passwordChangeSuccess")));
					history.push("/login");
				} else if (error) {
					const { code } = error;
					setErrors({ code });
				}
			})
		);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
		if (loading || Object.keys(inputValues).length < 3) return;

		if (e.key === "Enter") {
			handleSubmit();
		}
	};

	return (
		<div>
			<div
				className={classes.descriptionText}
				style={{ textAlign: "center", maxWidth: 999, whiteSpace: "pre-wrap" }}
			>
				{t("enterVerificationCode")}
			</div>

			<div className={classes.inputContainer} style={{ paddingLeft: 16, paddingRight: 16 }}>
				<span className={classes.inputHeader}>{t("verificationCode")}</span>
				<Input
					name="code"
					error={errors.code}
					style={{ width: 100 }}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
				/>
			</div>

			<div className={classes.inputContainer} style={{ paddingLeft: 16, paddingRight: 16 }}>
				<span className={classes.inputHeader}>{t("createNewPassword")}</span>
				<Input
					name="password"
					type="password"
					error={errors.password}
					style={{ width: 350 }}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder="Type your password here"
				/>

				<PasswordValidator password={inputValues.password} />
			</div>

			<div className={classes.inputContainer} style={{ paddingLeft: 16, paddingRight: 16 }}>
				<span className={classes.inputHeader}>{t("confirmPassword")}</span>
				<Input
					name="confirmPassword"
					type="password"
					error={errors.confirmPassword}
					style={{ width: 350 }}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder={t("retypePassword")}
				/>
			</div>

			<Button
				disabled={loading || Object.keys(inputValues).length < 3}
				style={{ marginTop: "15vh" }}
				onClick={handleSubmit}
			>
				{loading ? <CircularProgress size={18} /> : t("updatePassword")}
			</Button>
		</div>
	);
};

export default NewPasswordEntry;

import { Box } from "@material-ui/core";
import { numberWithCommas } from "helpers/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPlanUserAddonsPrice, PaymentPeriod } from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import TSubscriptionPlan from "types/SubscriptionPlan";

interface Props {
	plan: TSubscriptionPlan;
	period: PaymentPeriod;
	userAddons: number;
}

const SubscriptionPriceSummary = (props: Props) => {
	const { period, plan, userAddons } = props;
	const { addons } = useSelector((state: StoreState) => state.subscription);
	const { t } = useTranslation("settings");

	const userAddonPrice = useMemo(
		() => getPlanUserAddonsPrice(plan.id, period),
		[addons, plan, period]
	);

	return (
		<Box style={{ fontSize: 14, padding: 12, paddingTop: 42 }}>
			<Box display="flex">
				<Box>{t("plan")}</Box>

				<Box width="fit-content" marginLeft="auto">
					${numberWithCommas(plan.price[period])}
				</Box>
			</Box>
			<Box marginTop={1} display="flex">
				<Box>
					{t("extraTeamMembers")} {userAddons ? `(${userAddons})` : ""}
				</Box>

				<Box width="fit-content" marginLeft="auto">
					${numberWithCommas(userAddons * userAddonPrice)}
				</Box>
			</Box>

			<Box marginTop={3} style={{ fontSize: 16, fontWeight: 600 }} display="flex">
				<Box>{t("total")}</Box>

				<Box width="fit-content" marginLeft="auto">
					${numberWithCommas(plan.price[period] + userAddons * userAddonPrice)} /{" "}
					{period === "monthly" ? t("month") : t("year")}
				</Box>
			</Box>
		</Box>
	);
};

export default SubscriptionPriceSummary;

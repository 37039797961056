import { Box } from "@material-ui/core";
import { useQuery } from "helpers/utils";
import BillingMethods from "../Workspaces/components/Payment/BillingMethods";
import CurrentSubscriptionPlan from "../Workspaces/components/Payment/SubscriptionPlans/CurrentSubscriptionPlan";
import SubscriptionPlans from "../Workspaces/components/Payment/SubscriptionPlans/SubscriptionPlans";

const BillingAndPaymentsSettings = () => {
	const query = useQuery();

	if (query.get("view") === "plans") {
		return <SubscriptionPlans />;
	}

	return (
		<Box>
			<Box display="flex" style={{ marginTop: 32 }}>
				<Box style={{ width: "60%" }}>
					<BillingMethods />
				</Box>
				<Box style={{ width: "40%", marginLeft: 32 }}>
					<CurrentSubscriptionPlan />
				</Box>
			</Box>
		</Box>
	);
};

export default BillingAndPaymentsSettings;

import { Box } from "@material-ui/core";
import { PaymentMethodCreateParams } from "@stripe/stripe-js";
import Input from "components/Basic/Inputs/Input";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

export type TBillingAddressInputs = PaymentMethodCreateParams.BillingDetails.Address;

interface Props {
	inputValues: TBillingAddressInputs;
	errors: Partial<TBillingAddressInputs>;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const validateBillingAddressInputs = (inputValues: TBillingAddressInputs) => {
	let errors: TBillingAddressInputs = {};
	const requiredInputs = ["line1", "city", "state"];

	requiredInputs.forEach((input) => {
		if (!(inputValues as { [index: string]: string })[input]) {
			(errors as { [index: string]: string })[input] = "required";
		}
	});

	return errors;
};

const BillingAddressInputs = (props: Props) => {
	const { inputValues, errors, onChange } = props;
	const { t } = useTranslation("settings");

	return (
		<Box style={{ marginBottom: 16 }}>
			<Input
				label={`${t("addressLine")} 1`}
				name="line1"
				required
				placeholder={`${t("addressLine")} 1`}
				value={inputValues.line1}
				error={errors.line1 && t(errors.line1)}
				containerStyle={{ width: "100%", marginTop: 8 }}
				inputStyle={{ width: "100%" }}
				onChange={onChange}
			/>

			<Input
				label={`${t("addressLine")} 2`}
				name="line2"
				placeholder={`${t("addressLine")} 2`}
				value={inputValues.line2}
				error={errors.line2 && t(errors.line2)}
				containerStyle={{ width: "100%", marginTop: 8 }}
				inputStyle={{ width: "100%" }}
				onChange={onChange}
			/>

			<Box display="flex" alignItems="center" style={{ marginTop: 8 }}>
				<Input
					label={t("city")}
					required
					name="city"
					placeholder={t("city")}
					onChange={onChange}
					containerStyle={{ width: "49%" }}
					value={inputValues.city || ""}
					error={errors.city && t(errors.city)}
				/>
				<Input
					label={t("stateProvinceRegion")}
					required
					name="state"
					placeholder={t("stateProvinceRegion")}
					onChange={onChange}
					containerStyle={{ width: "49%", marginLeft: 12 }}
					value={inputValues.state || ""}
					error={errors.state && t(errors.state)}
				/>
			</Box>
		</Box>
	);
};

export default BillingAddressInputs;

import React, { useState, MouseEvent, useEffect } from "react";
import { makeStyles, Popover } from "@material-ui/core";
import ScheduleSelector from "../../Dialogs/DateTimePicker";
import CalendarIcon from "assets/red-calendar.png";
import { getCurrentDate } from "helpers/dateAndTime";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "white",
		border: `1px solid ${theme.palette.secondary.main}`,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		cursor: "pointer",
		paddingLeft: 16,
		borderRadius: 8,
		color: theme.palette.text.primary,
		opacity: 0.9,
		fontSize: 12,
	},

	calendarIcon: {
		marginLeft: "auto",
		marginRight: 16,
		height: 18,
	},

	menuContainer: {
		background: "white",
		display: "flex",
		flexDirection: "row",
		height: 50,
		width: 340,
		alignItems: "center",
		justifyContent: "center",
	},
}));

interface Props {
	width: number;
	height: number;
	startDate?: Date | undefined;
	endDate?: Date | undefined;
	onChange?: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

type DateType = "start" | "end";

export default function DurationInput(props: Props) {
	const classes = useStyles();
	const [selectedDateType, setSelectedDateType] = useState<DateType>("start");
	const [calendarMenu, setCalendarMenu] = useState<HTMLElement | null>();
	const [inputsMenu, setInputsMenu] = useState<HTMLElement | null>();
	const [startDate, setStartDate] = useState<Date>();
	const [endDate, setEndDate] = useState<Date>();

	const { width, height, startDate: startDateProp, endDate: endDateProp, onChange } = props;
	
	const handleContainerClick = (e: MouseEvent<HTMLElement>) => {
		setInputsMenu(e.currentTarget);
	};

	const handleDateClick = (e: MouseEvent<HTMLElement>, type: DateType) => {
		setCalendarMenu(e.currentTarget);
		setSelectedDateType(type);
	};

	const handleDateSubmit = (date: Date | undefined) => {
		if (selectedDateType === "start") {
			setStartDate(date);
		} else {
			setEndDate(date);
		}

		setCalendarMenu(null);
	};

	useEffect(() => {
		onChange?.(startDate, endDate);
	}, [startDate, endDate]);

	return (
		<>
			<div
				onClick={handleContainerClick}
				className={classes.container}
				style={{ width: width || 150, height: height || 45 }}
			>
				{startDate || startDateProp ? moment(startDate || startDateProp).format("DD MMM YYYY") : "Unset"}
				{" - "}
				{endDate || endDateProp ? moment(endDate || endDateProp).format("DD MMM YYYY") : "Unset"}
				<CalendarIconElement />
			</div>

			<ScheduleSelector
				onSubmit={handleDateSubmit}
				anchorEl={calendarMenu as HTMLElement | null}
				handleClose={() => setCalendarMenu(null)}
				hideTimePicker
			/>

			<Popover
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				anchorEl={inputsMenu}
				open={Boolean(inputsMenu)}
				onClose={() => setInputsMenu(null)}
			>
				<div className={classes.menuContainer}>
					<div
						onClick={(e) => handleDateClick(e, "start")}
						className={classes.container}
						style={{ width: 155, height: height || 30 }}
					>
						{startDateProp || startDate
							? moment(startDateProp || startDate).format("DD MMM YYYY")
							: "Unset"}

						<CalendarIconElement />
					</div>
					<span style={{ marginLeft: 8, marginRight: 8, fontSize: 18 }}> - </span>

					<div
						onClick={(e) => handleDateClick(e, "end")}
						className={classes.container}
						style={{ width: 155, height: height || 30 }}
					>
						{endDate || endDateProp ? moment(endDateProp || endDate).format("DD MMM YYYY") : "Unset"}
						<CalendarIconElement />
					</div>
				</div>
			</Popover>
		</>
	);
}

const CalendarIconElement = () => {
	const classes = useStyles();

	return <img alt="calendar-icon" src={CalendarIcon} className={classes.calendarIcon} />;
};

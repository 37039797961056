import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { useEffect } from "react";
import { getNextCampaign } from "redux/actions/campaigns/get";
import moment from "moment";
import { listPosts } from "redux/actions/posts/get";
import { listNotes } from "redux/actions/notes/get";
import { listTasks } from "redux/actions/tasks/get";
import usePageStyles from "../styles";
import { Box } from "@material-ui/core";
import summaryElements from "./elements";
import SummaryElement from "./SummaryElement";

const TopSummary = () => {
	const [data, setData] = useState<{ [index: string]: number }>({});
	const classes = usePageStyles();
	const dispatch = useDispatch();
	const { nextCampaign } = useSelector((state: StoreState) => state.campaigns);
	const { notesCount } = useSelector((state: StoreState) => state.notes);
	const { tasksCount } = useSelector((state: StoreState) => state.tasks);
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { scheduledPostsCount } = useSelector((state: StoreState) => state.posts);

	const getCardsData = () => {
		let data: { [index: string]: number } = {};
		if (nextCampaign) {
			const { startDate } = nextCampaign;
			data.nextCampaign = moment(startDate).diff(moment(), "days");
		}

		if (scheduledPostsCount) {
			data.scheduledPosts = scheduledPostsCount;
		}

		if (notesCount) {
			data.notes = notesCount;
		}
		if (tasksCount) {
			data.tasks = tasksCount;
		}

		if (userData) {
			const team = userData.teams.find((team) => team._id === currentTeamId);
			data.users = team?.users.length || 0;
		}

		setData({ ...data });
	};

	useEffect(() => {
		if (currentTeamId) {
			// Next Campaign
			dispatch(getNextCampaign());

			// Posts
			const endDate = new Date();
			endDate.setUTCHours(23, 59, 59, 999);
			dispatch(
				listPosts({
					scheduled: true,
					count: true,
					startDate: new Date(),
					endDate,
				})
			);

			// Notes
			dispatch(listNotes(true));

			// Tasks
			dispatch(listTasks(true));
		}
	}, [currentTeamId]);

	useEffect(() => {
		getCardsData();
	}, [nextCampaign, notesCount, userData, scheduledPostsCount, tasksCount]);

	return (
		<Box className={classes.container}>
			{summaryElements.map((element, index) => (
				<SummaryElement
					key={index}
					index={index}
					element={element}
					isLast={index === summaryElements.length - 1}
					value={data[element.key]}
				/>
			))}
		</Box>
	);
};

export default TopSummary;

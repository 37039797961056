import { makeStyles } from "@material-ui/core";

export const cellHeight = 54;
export const cellWidth = "14.2%";

const useWeeklyCalendarStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		maxHeight: "100%",
	},

	daysHeaderContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 46,
	},

	daysHeaderItem: {
		fontSize: 16,
		fontFamily: "Avenir",
		width: 170,
		color: theme.palette.text.primary,
		justifyContent: "center",
		display: "flex",
		flexDirection: "column",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		overflowY: "scroll",
	},

	hoursColumn: {
		display: "flex",
		flexDirection: "column",
		marginTop: 16,
	},

	itemsColumn: {
		display: "flex",
		flexDirection: "column",
		width: "12.2%",
		marginTop: 16,
	},

	hourCell: {
		height: cellHeight,
		minHeight: cellHeight,
		maxHeight: cellHeight,
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		marginTop: 6,
		marginLeft: 4,
	},

	hourItem: {
		minHeight: cellHeight,
		fontFamily: "Avenir",
		color: "#868C91",
		width: 40,
		fontSize: 11,
		marginTop: 6,
	},
}));

export default useWeeklyCalendarStyles;

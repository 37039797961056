import { useState } from "react";
import ExpandIconSvg from "assets/SVG/ExpandIconSvg";
import useQuestionStyles from "./styles/expandable-question";

interface Props {
	question: string;
	answer: string;
}

const ExpandableQuestion = ({ question, answer }: Props) => {
	const classes = useQuestionStyles();
	const [expanded, setExpanded] = useState<boolean>(false);
	return (
		<div
			style={{
				maxHeight: !expanded ? 80 : 500,
				minHeight: !expanded ? 25 : 80,
				overflowY: expanded ? "scroll" : "hidden",
			}}
			className={classes.container}
		>
			<div className={classes.header} onClick={() => setExpanded(!expanded)}>
				<div className={classes.question}>{question}</div>
				<div className={classes.arrowIconContainer}>
					<ExpandIconSvg />
				</div>
			</div>
			{expanded && <div className={classes.answer}>{answer}</div>}
		</div>
	);
};

export default ExpandableQuestion;

import React, { useEffect, useState } from "react";
import PieChart from "../../components/Charts/PieChart";
import Post from "types/Post";
import useStyles from "./styles/post-stats";
import moment from "moment";
interface PostPreviewProps {
	title: string;
	post: Post;
}

interface SocialMediaPostStats {
	title: string;
	data: any;
	width?: string;
}

export const PostPreview = (props: PostPreviewProps) => {
	const [contentPreview, setContentPreview] = useState<string>();
	const classes = useStyles();
	const { title, post } = props;

	useEffect(() => {
		if (post.content && post.content.length > 30) {
			setContentPreview(post.content.substring(0, 30) + "...");
		} else setContentPreview(post.content || '');
	}, [post]);

	return (
		<div style={{ width: "50%" }}>
			<div style={{ marginBottom: 8 }} className={classes.title}>
				{title}
			</div>
			<div className={classes.postPreview}>
				<img src={post.attachments[0]} className={classes.postImage} />
				<div className={classes.postText}>{contentPreview}</div>
			</div>
			<div className={classes.postDate}>{moment(post.schedule).format("MMM DD, h:mm:ss")}</div>
		</div>
	);
};

export const SocialMediaPostStats = (props: SocialMediaPostStats) => {
	const classes = useStyles();
	const { title, data, width } = props;

	return (
		<div style={{ width: width || "50%" }}>
			<div className={classes.socialStatsContainer}>
				<div style={{ marginBottom: -12 }} className={classes.title}>
					{title}
				</div>
				<div className={classes.chartContainer}>
					<PieChart data={data} />
				</div>
			</div>
		</div>
	);
};

import React, { ChangeEvent } from "react";
import { Checkbox as MUICheckBox } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface Props {
	checked?: boolean;
	onChange?: (checked: boolean) => void;
	width?: number;
	height?: number;
	borderRadius?: any;
	style?: CSSProperties;
	disabled?: boolean
}

export default function CheckBox(props: Props) {
	const { checked, style, onChange, width, height, borderRadius, disabled } = props;

	return (
		<MUICheckBox
			style={style}
			disabled={disabled}
			onChange={(e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.checked)}
			checked={checked || false}
			checkedIcon={
				<div
					style={{
						width: width || 18,
						height: height || 18,
						borderRadius: borderRadius || 4,
						display: "flex",
						border: "1px solid #868C91",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							width: width! - 4 || 12,
							height: height! - 4 || 12,
							backgroundColor: "#E42631",
							marginLeft: "auto",
							marginRight: "auto",
							borderRadius: borderRadius || 3,
						}}
					></div>
				</div>
			}
			icon={
				<div
					style={{
						width: width || 18,
						height: width || 18,
						border: "1px solid #868C91",
						borderRadius: borderRadius || 4,
					}}
				/>
			}
		/>
	);
}

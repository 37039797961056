import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		height: 150,
		maxHeight: 150,
		transition: "0.8s",
		backgroundColor: "white",
		marginTop: 16,
		borderRadius: 12,
		padding: 32,
		position: "relative",
	},

	linearContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
	},

	socialDetailsContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "fit-content",
	},

	username: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
	},

	dateContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginLeft: "auto",
		width: "fit-content",
		fontWeight: 500,
		color: "#4B7FA3",
	},

	postContent: {
		fontSize: 12,
		color: theme.palette.text.primary,
	},

	highlighter: {
		backgroundColor: "#D5E1F5",
		borderRadius: 4,
	},

	viewMoreText: {
		fontSize: 12,
		color: "#4B7FA3",
		textDecoration: "underline",
		cursor: "pointer",
		userSelect: "none",
		position: "absolute",
		bottom: 16,
	},

	attachmentsContainer: {
		maxWidth: "50%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 32,
		paddingBottom: 16,
	},

	attachment: {
		width: 80,
		height: 80,
		borderRadius: 10,
		marginRight: 16,
		objectFit: "cover",
	},

	seeOriginalButton: {
		marginLeft: "auto",
		width: "fit-content",
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useUnapprovedPostStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		position: "relative",
		alignItems: "center",
		backgroundColor: "white",
		borderRadius: 12,
		marginTop: 12,
		marginBottom: 12,
		padding: 32,
		paddingTop: 20,
		paddingBottom: 20,
		minHeight: 120,
		width: "100%",
		filter: "drop-shadow(0px 4px 24px rgba(217, 227, 235, 0.4))",
	},

	imagesContainer: {
		display: "flex",
		width: 150,
		flexWrap: "wrap",
	},

	postImage: {
		borderRadius: 8,
		objectFit: "cover",
		width: 60,
		height: 60,
		marginRight: 8,
		marginBottom: 8,
	},

	overflowImage: {
		height: 52,
		width: 52,
		background: "#B6BFC4",
		color: "white",
		fontWeight: 700,
		fontSize: 18,
		marginLeft: 8,
		marginTop: 8,
		borderRadius: 8,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		userSelect: "none",
		cursor: "pointer",
	},

	textContent: {
		color: theme.palette.text.primary,
		fontSize: 12,
		fontFamily: "Avenir",
	},

	scheduleText: {
		fontSize: 12,
		color: "#4B7FA3",
		fontWeight: 500,
		marginBottom: 6,
	},

	sectionLabel: {
		fontSize: 12,
		color: "#868C90",
		fontWeight: 600,
	},

	taskTitle: {
		fontSize: 14,
		fontWeight: 500,
		marginTop: 4,
		marginBottom: 4,
		maxWidth: "80%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		cursor: "pointer",
	},

	taskDate: {
		fontSize: 12,
		fontWeight: 300,
	},

	returned: {
		textOrientation: "mixed",
		position: "absolute",
		top: 0,
		left: 0,
		height: "100%",
		writingMode: "vertical-rl",
		transform: "rotate(-180deg)",
		textAlign: "center",
		color: "white",
		fontWeight: 700,
		letterSpacing: 1.2,
		background: theme.palette.primary.main,
		borderTopRightRadius: 6,
		borderBottomRightRadius: 6,
		textTransform: "uppercase",
		fontSize: 12,
	},
}));

export default useUnapprovedPostStyles;

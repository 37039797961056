import { Tooltip, ListItem, ListItemIcon, ListItemText, Box } from "@material-ui/core";
import AttentionIconSvg from "assets/SVG/AttentionIconSvg";
import clsx from "clsx";
import { index } from "d3";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setComingSoonDialog } from "redux/actions/modals";
import { isProfileDisconnected } from "redux/actions/socialProfiles/socialProfiles";
import { StoreState } from "setup/store";
import { DrawerListItem } from "../pages";
import useStyles from "../styles/container-styles";

interface Props {
	item: DrawerListItem;
	selected: boolean;
}

const DrawerItem: React.FC<Props> = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation("settings");
	const { item, selected } = props;
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);

	const hasDisconnectedSocialProfile = useMemo(
		() => item.title === "Settings" && profiles.find(isProfileDisconnected),
		[item, profiles]
	);

	const handleClick = () => {
		if (item.disabled && item.isComingSoon) {
			dispatch(setComingSoonDialog(true));
		}
	};

	return (
		<Tooltip key={item.title} title={item.title}>
			<Box onClick={handleClick} style={{ position: "relative" }}>
				<Link style={{ pointerEvents: item.disabled ? "none" : "all" }} to={item.url}>
					<ListItem
						className={clsx(classes.listItem, item.disabled ? classes.disabledListItem : "")}
						button
					>
						{selected && <div className={classes.selectedHighlighter}></div>}
						<ListItemIcon>
							<img
								src={selected ? item.selectedIcon : item.icon}
								className={classes.icon}
								alt={`page-${index}`}
							/>
						</ListItemIcon>
						<ListItemText
							classes={{ primary: classes.listItemText }}
							style={{ color: selected ? "#E42631" : "#3E4C55" }}
							primary={item.title}
						/>
					</ListItem>

					{hasDisconnectedSocialProfile && (
						<Tooltip title={t("youHaveDisconnectedProfile") as string}>
							<Box style={{ position: "absolute", left: 32, top: 0 }}>
								<AttentionIconSvg size={20} />
							</Box>
						</Tooltip>
					)}
				</Link>
			</Box>
		</Tooltip>
	);
};

export default DrawerItem;

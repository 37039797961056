import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/Basic/Inputs/Input";
import Task from "types/Task";
import useTaskDialogStyles from "../../styles/task-dialog";
import { EditModeInputValues } from "../TaskDialog";
import MentionsInput from "components/Basic/Inputs/MentionsInput";

interface Props {
	inputValues: Partial<Task>;
	errors: { [index: string]: string };
	setInputValues: Dispatch<SetStateAction<EditModeInputValues>>;
	onSubmit: () => void;
	handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Inputs = (props: Props) => {
	const { inputValues, errors, setInputValues, onSubmit, handleInputChange } = props;
	const classes = useTaskDialogStyles();

	const { t } = useTranslation("tasks");

	const handleKeyPress = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === "Enter") {
			onSubmit();
		}
	};

	return (
		<div>
			<div className={classes.inputContainer}>
				<span className={classes.inputTitle}>{t("taskTitle")}</span>
				<Input
					style={{ width: "100%", marginTop: 4, fontSize: 12 }}
					inputStyle={{ width: "100%" }}
					name="title"
					onKeyPress={handleKeyPress}
					error={t(errors.title || "")}
					value={inputValues.title}
					onChange={handleInputChange}
				/>
			</div>

			<div className={classes.inputContainer}>
				<span className={classes.inputTitle}>{t("taskDescription")}</span>

				<MentionsInput
					value={inputValues.description || ""}
					onChange={(e, newValue, newPlainTextValue, mentions) => {
						setInputValues({
							...inputValues,
							description: newValue,
							mentions: mentions.map((mention) => mention.id.split("user/")[1]),
						});
					}}
				/>
			</div>
		</div>
	);
};

export default Inputs;

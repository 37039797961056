import { Box, Popover } from "@material-ui/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import WorkspaceSvg from "assets/SVG/WorkspaceSvg";
import { getUserData } from "redux/actions/user/user-data";
import { StoreState } from "../../../setup/store";
import { TeamUser } from "types/User/Team";
import UserPicture from "../../Media/UserPicture";
import useTeamUserSelectorStyles from "./styles";

interface Props {
	anchorEl: HTMLElement | null;
	title: string;
	onUserSelect: (user: TeamUser | undefined) => void;
	onClose: () => void;
}

const TeamUserSelector = (props: Props) => {
	const { anchorEl, title, onUserSelect, onClose } = props;
	const { t } = useTranslation("workspaces");
	const { teams, currentTeamId } = useSelector((state: StoreState) => state.teams);
	const dispatch = useDispatch();
	const classes = useTeamUserSelectorStyles();
	const currentTeam = teams.find((team) => team._id === currentTeamId);

	const handleUserClick = (user: TeamUser | undefined) => {
		onUserSelect(user);
		onClose();
	};

	useEffect(() => {
		if (anchorEl && teams.length === 0) dispatch(getUserData({ populateTeamUsers: true }));
	}, [anchorEl]);

	return (
		<Popover
			anchorEl={anchorEl}
			onClose={onClose}
			open={Boolean(anchorEl)}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			transformOrigin={{ vertical: "top", horizontal: "center" }}
			classes={{ paper: classes.paper }}
		>
			<Box style={{ padding: 16 }}>
				<div className={classes.title}>{title}</div>

				<Box style={{ marginTop: 12 }}>
					<Box onClick={() => handleUserClick(undefined)} className={classes.user}>
						<Box style={{ marginLeft: 4 }}>
							<WorkspaceSvg size={25} color="#868C91" />
						</Box>

						<Box style={{ marginLeft: 14, fontWeight: 500 }} className={classes.name}>
							{t("allTeam")}
						</Box>
					</Box>

					{currentTeam?.users
						.filter((teamUser) => teamUser.user)
						.map((teamUser, index) => (
							<Box onClick={() => handleUserClick(teamUser)} className={classes.user} key={index}>
								<UserPicture user={teamUser.user} />
								<Box className={classes.name}>{teamUser.user!.email}</Box>
							</Box>
						))}
				</Box>
			</Box>
		</Popover>
	);
};

export default TeamUserSelector;

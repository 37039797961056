import { makeStyles } from "@material-ui/core";

const useNotificationsModalStyles = makeStyles((theme) => ({
	paper: {
		paddingLeft: 24,
		paddingRight: 24,
		paddingTop: 12,
		borderRadius: 12,
		background: "white",
		dropShadow: "(4px 4px 24px rgba(62, 76, 85, 0.2))",
		maxHeight: 300,
		width: 350,
	},

	notificationItem: {
		fontSize: 14,
		color: theme.palette.text.primary,
		paddingBottom: 8,
		paddingTop: 8,
	},

	viewAllButton: {
		width: "fit-content",
		marginLeft: "auto",
		color: "#4B7FA3",
		fontSize: 14,
		padding: 8,
		borderRadius: 8,
		marginBottom: 12,
		cursor: "pointer",
		"&:hover": {
			background: "#f5f5f5",
		},
	},

	centeredContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		marginTop: 32,
		marginBottom: 24,
	},
}));

export default useNotificationsModalStyles;

import { makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "components/Basic/Button";
import { ContentType } from "types/Content";
import { useDispatch } from "react-redux";
import { setComingSoonDialog } from "redux/actions/modals";

const useStyles = makeStyles(() => ({
	container: {
		display: "flex",
		flexDirection: "row",
	},
}));

interface Props {
	selectedContentType: ContentType;
	setSelectedContentType: Dispatch<SetStateAction<ContentType>>;
	editPost: boolean;
}

const Header = (props: Props) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation("posts");
	const { selectedContentType, setSelectedContentType, editPost } = props;

	const handleCreatePostClick = () => {
		setSelectedContentType("post");
		history.push("/posts/new");
	};

	return (
		<div className={classes.container}>
			<Button
				secondary={true}
				disabled={selectedContentType === "post" && !editPost}
				onClick={handleCreatePostClick}
				style={{ width: 150, marginRight: 12 }}
			>
				{t("createNewPost")}
			</Button>
			<Button
				style={{ width: 150, marginRight: 12 }}
				disabled={selectedContentType === "story"}
				onClick={() => dispatch(setComingSoonDialog(true))}
				secondary={true}
			>
				{t("createStory")}
			</Button>
			<Button
				onClick={() => history.push("/posts/drafts")}
				style={{ width: 150, marginRight: 12 }}
				secondary={true}
			>
				{t("editDrafts")}
			</Button>
		</div>
	);
};

export default Header;

import { Box, CircularProgress } from "@material-ui/core";
import Button from "components/Basic/Button";
import TextButton from "components/Basic/TextButton";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { selectedTeamQuery } from "config";
import { useQuery } from "helpers/utils";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
	updateTeamSubscription,
	SubscriptionPayload,
	removeScheduledCancellation,
	cancelTeamSubscription,
	isFreeSubscription,
} from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import SubscriptionInfoDialog from "../../SubscriptionInfoDialog";
import {
	DialogActionButton,
	SubscriptionInfoDialogState,
} from "../../SubscriptionInfoDialog/SubscriptionInfoDialog";
import useSubscriptionDialogStyles from "../styles";

interface Props {
	onClose: () => void;
	isSubscriptionUpdated: boolean;
	payload: Partial<SubscriptionPayload>;
}

const SubscriptionDialogActions = (props: Props) => {
	const [submitLoading, setSubmitLoading] = useState(false);
	const history = useHistory();
	const query = useQuery();
	const { payload, isSubscriptionUpdated, onClose } = props;
	const classes = useSubscriptionDialogStyles();
	const { t } = useTranslation("settings");
	const [cancelConfirmation, setCancelConfirmation] = useState(false);
	const [removeCancellationConfirmation, setRemoveCancellationConfirmation] = useState(false);
	const [cancelLoading, setCancelLoading] = useState(false);
	const { currentTeamId, teams } = useSelector((state: StoreState) => state.teams);
	const currentTeam = teams.find((team) => team._id === currentTeamId);
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const [subscriptionInfoDialog, setSubscriptionInfoDialog] =
		useState<SubscriptionInfoDialogState | null>(null);

	const hasActiveSubscription = useMemo(
		() => Boolean(currentTeamSubscription?.id),
		[currentTeamSubscription]
	);

	const handleSubmit = async () => {
		if (!payload.paymentMethodId || !payload.paymentPeriod || !currentTeam) {
			return;
		}

		setSubmitLoading(true);

		const success = await updateTeamSubscription(currentTeam._id, payload as SubscriptionPayload);
		setSubmitLoading(false);
		if (success) {
			onClose();
		}
	};

	const handleSubmitClick = () => {
		const actionButtons: DialogActionButton[] = [
			{
				text: `< ${t("back")}`,
				secondary: true,
				onClick: () => setSubscriptionInfoDialog(null),
			},
			{
				text: hasActiveSubscription ? `${t("confirmChanges")}` : `${t("confirmSubscription")}`,
				onClick: () => {
					handleSubmit();
					setSubscriptionInfoDialog(null);
				},
			},
		];

		setSubscriptionInfoDialog({
			open: true,
			scheduledChanges: hasActiveSubscription,
			actionButtons,
			subscriptionPayload: payload as SubscriptionPayload,
		});
	};

	const handleSelectAnotherPlanClick = () => {
		onClose();
		let url = `/settings?setting=billingAndPayments&view=plans`;
		const selectedTeam = query.get("selectedTeam");

		if (selectedTeam) {
			url += `&${selectedTeamQuery}=${selectedTeam}`;
		}

		history.push(url);
	};

	const handleSubscriptionCancel = async () => {
		if (!currentTeam) return;

		setCancelLoading(true);
		await cancelTeamSubscription(currentTeam._id);
		setCancelLoading(false);
		setCancelConfirmation(false);
		onClose();
	};

	const handleRemoveCancellation = async () => {
		if (!currentTeam) return;

		setCancelLoading(true);
		await removeScheduledCancellation(currentTeam._id);
		setCancelLoading(false);
		setRemoveCancellationConfirmation(false);
	};

	return (
		<Box className={classes.buttonsContainer}>
			<ConfirmationDialog
				open={cancelConfirmation}
				actionLoading={cancelLoading}
				message={t("cancelSubscriptionConfirmation")}
				onAction={handleSubscriptionCancel}
				textMaxWidth={500}
				confirmButton={t("cancelSubscription")}
				onClose={() => setCancelConfirmation(false)}
			/>

			<ConfirmationDialog
				open={removeCancellationConfirmation}
				actionLoading={cancelLoading}
				message={t("removeSubscriptionCancellationConfirmation")}
				onAction={handleRemoveCancellation}
				textMaxWidth={500}
				confirmButton={t("removeScheduledCancellation")}
				onClose={() => setRemoveCancellationConfirmation(false)}
			/>

			{subscriptionInfoDialog && (
				<SubscriptionInfoDialog
					{...subscriptionInfoDialog}
					onClose={() => setSubscriptionInfoDialog(null)}
				/>
			)}

			{currentTeamSubscription && !isFreeSubscription(currentTeamSubscription) && (
				<TextButton
					onClick={() =>
						currentTeamSubscription?.status === "non_renewing"
							? setRemoveCancellationConfirmation(true)
							: setCancelConfirmation(true)
					}
				>
					{currentTeamSubscription?.status === "non_renewing"
						? t("removeScheduledCancellation")
						: t("cancelSubscription")}
				</TextButton>
			)}

			<Box width="fit-content" marginLeft="auto" display='flex'>
				<Button onClick={onClose} secondary style={{ width: 150, marginRight: 16 }}>
					{"< "} {t("back")}
				</Button>

				{hasActiveSubscription && (
					<Button
						secondary
						disabled={
							currentTeamSubscription?.status === "non_renewing" ||
							currentTeamSubscription?.status === "cancelled"
						}
						style={{ width: 200, marginRight: 16 }}
						onClick={handleSelectAnotherPlanClick}
					>
						{t("selectAnotherPlan")}
					</Button>
				)}

				<Button
					disabled={
						!payload.paymentMethodId ||
						submitLoading ||
						currentTeamSubscription?.status === "non_renewing" ||
						currentTeamSubscription?.status === "cancelled" ||
						(Boolean(currentTeamSubscription?.id) && !isSubscriptionUpdated)
					}
					style={{ width: 200 }}
					onClick={handleSubmitClick}
				>
					{submitLoading ? (
						<CircularProgress size={20} />
					) : currentTeamSubscription?.id ? (
						t("updateSubscription")
					) : (
						t("confirmSubscription")
					)}
				</Button>
			</Box>
		</Box>
	);
};

export default SubscriptionDialogActions;

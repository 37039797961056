import { Box } from "@material-ui/core";
import ErrorIconSvg2 from "assets/SVG/ErrorIconSvg2";
import SuccessIconSvg from "assets/SVG/SuccessIconSvg";
import { validateCharCase, validateDigits } from "helpers/utils";
import useStyles from "../styles";

interface Props {
	password: string | undefined;
}

interface ConditionProps {
	title: string;
	passed: boolean;
}

const Condition = ({ title, passed }: ConditionProps) => {
	return (
		<Box display="flex" alignItems="center" justifyContent="flex-end">
			<span style={{ paddingRight: 8 }}>{title}</span>
			{passed ? (
				<SuccessIconSvg width={10} height={14} color="#27AE60" />
			) : (
				<ErrorIconSvg2 width={10} height={14} />
			)}
		</Box>
	);
};

const PasswordValidator = ({ password }: Props) => {
	const classes = useStyles();

	return (
		<div
			style={{
				minHeight: password ? 50 : 0,
				maxHeight: password ? 50 : 0,
			}}
			className={classes.passwordValidation}
		>
			<Condition
				title="Includes lower and upper case letters"
				passed={validateCharCase(password || "")}
			/>
			<Condition title="Includes digits" passed={validateDigits(password || "")} />
			<Condition title="Includes at least 8 characters" passed={(password?.length || 0) >= 8} />
		</div>
	);
};

export default PasswordValidator;

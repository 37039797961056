import axios from "axios";
import config from "config";
import { Dispatch } from "react";
import {
	SET_SOCIAL_MESSAGES_FETCH_MORE_LOADING,
	SET_SOCIAL_MESSAGES_LOADING,
	SET_SOCIAL_MESSAGES,
	SET_SOCIAL_COMMENTS,
	SET_SOCIAL_COMMENTS_FETCH_MORE_LOADING,
	SET_SOCIAL_COMMENTS_LOADING,
	SET_REPLY_LOADING,
} from "redux/reducers/social-interactions";
import { StoreState } from "setup/store";
import Interaction, { InteractionType } from "types/Interaction";
import { setSnackbarState } from "../snackBar";

export type ListSocialInteractionsFilters = {
	socialProfileIds?: string[];
	socialPostIds?: string[];
	unreplied?: boolean;
	startDate?: string;
	endDate?: string;
	from?: number;
	sort?: "asc" | "desc";
};

export const listSocialInteractions = (
	type: InteractionType,
	filters?: ListSocialInteractionsFilters
) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { messages: oldMessages, comments: oldComments } = getState().socialInteractions;

		if (!currentTeamId) {
			return;
		}
		if (type === "message") {
			dispatch({
				type: filters?.from ? SET_SOCIAL_MESSAGES_FETCH_MORE_LOADING : SET_SOCIAL_MESSAGES_LOADING,
				payload: true,
			});
		} else if (type === "comment") {
			dispatch({
				type: filters?.from ? SET_SOCIAL_COMMENTS_FETCH_MORE_LOADING : SET_SOCIAL_COMMENTS_LOADING,
				payload: true,
			});
		}

		try {
			const result = await axios.get(
				`${config.apiUrl}/social-interactions?teamId=${currentTeamId}`,
				{
					params: { ...filters, type: type + "s" },
				}
			);

			const newInteractions = result.data.interactions.map((message: any) => ({
				...message,
				type,
			}));

			if (type === "message") {
				dispatch({
					type: SET_SOCIAL_MESSAGES,
					payload: {
						total: result.data.total,
						syncErrors: result.data.syncErrors,
						messages: filters?.from ? [...oldMessages, ...newInteractions] : newInteractions,
					},
				});
			} else if (type === "comment") {
				dispatch({
					type: SET_SOCIAL_COMMENTS,
					payload: {
						total: result.data.total,
						syncErrors: result.data.syncErrors,
						comments: filters?.from ? [...oldComments, ...newInteractions] : newInteractions,
					},
				});
			}
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		}
	};
};

export const replyToInteraction = (
	interaction: Interaction,
	reply: string,
	cb?: (error?: string) => void
) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { messages, comments } = getState().socialInteractions;

		dispatch({
			type: SET_REPLY_LOADING,
			payload: true,
		});

		try {
			await axios.post(
				`${config.apiUrl}/social-interactions/${interaction._id}/reply`,
				{ reply },
				{ params: { teamId: interaction.team, type: interaction.type } }
			);

			const oldInteractions = (
				interaction.type === "message" ? messages : comments
			) as Interaction[];

			const updatedInteraction = oldInteractions.find(({ _id }) => _id === interaction._id);

			if (updatedInteraction) {
				updatedInteraction.replied = true;
			}

			if (interaction.type === "message") {
				dispatch({
					type: SET_SOCIAL_MESSAGES,
					payload: {
						messages: [...messages],
					},
				});
			} else if (interaction.type === "comment") {
				dispatch({
					type: SET_SOCIAL_COMMENTS,
					payload: {
						comments: [...comments],
					},
				});
			}
			cb?.();
		} catch (e) {
			cb?.(e.response.data.error);
			if (e.response.code === 500) {
				dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
			}
		}

		dispatch({
			type: SET_REPLY_LOADING,
			payload: false,
		});
	};
};

import { Dispatch } from "react";

import config from "config";
import axios from "axios";
import { setSnackbarState } from "../snackBar";
import { StoreState } from "../../../setup/store";
import {
	DELETE_CAMPAIGN_LOADING,
	DELETE_CAMPAIGN_SUCCESS,
	LIST_CAMPAIGNS_RESULT,
} from "../../reducers/campaigns";

export const deleteCampaign = (_id: string) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		let { campaigns } = getState().campaigns;

		dispatch({
			type: DELETE_CAMPAIGN_LOADING,
			payload: true,
		});

		try {
			await axios.delete(`${config.apiUrl}/campaigns/${_id}`, {});
			dispatch({
				type: LIST_CAMPAIGNS_RESULT,
				payload: [...campaigns.filter((campaign) => campaign._id !== _id)],
			});

			dispatch({
				type: DELETE_CAMPAIGN_SUCCESS,
				payload: true,
			});

			dispatch(setSnackbarState(true, "success", "Campaign deleted successfully!"));
		} catch (e) {
			console.log(e);
			dispatch({
				type: DELETE_CAMPAIGN_LOADING,
				payload: false,
			});

			dispatch(setSnackbarState(true, "error", "Error deleting campaign!"));
		}
	};
};

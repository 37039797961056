import Reply from "types/Reply";
import { placeholderSocialProfiles, posts } from "./placeholderData";

export const generalReplyStats: Array<{ title: string; value: number }> = [
	{ title: "Total Comments", value: 0 },
	{ title: "Unread Comments", value: 0 },
	{ title: "Unreplied Comments", value: 0 },
	{ title: "Total Mentions", value: 0 },
	{ title: "Total Reposts", value: 0 },
	{ title: "Total Messages", value: 0 },
	{ title: "Unread Messages", value: 0 },
	{ title: "Unreplied Messages", value: 0 },
];

export const postReplyStats: Array<{ title: string; value: number }> = [
	{ title: "Total Comments", value: 0 },
	{ title: "Unread Comments", value: 0 },
	{ title: "Unreplied Comments", value: 0 },
	{ title: "Total Reposts", value: 0 },
	{ title: "Unread Reposts", value: 0 },
	{ title: "Unreplied Reposts", value: 0 },
];

export const comments: Array<any> = [
	{
		type: "comment",
		message:
			"I literally loose my mind with your new app! I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		user: {
			picture:
				"https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
			name: "John Doe",
		},
		likes: 743,
		liked: true,
		read: true,
		post: posts[0],
		socialProfile: placeholderSocialProfiles[0],
	},
	{
		type: "comment",
		message:
			"I literally loose my mind with your new app! I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		user: {
			picture: "https://i.imgur.com/cBfXYY1.png",
			name: "Sarah Lean",
		},
		likes: 23,
		post: posts[2],
		replied: true,
		socialProfile: placeholderSocialProfiles[1],
	},
];

export const messages: Array<any> = [
	{
		type: "message",
		message:
			"I literally loose my mind with your new app! I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		user: {
			picture:
				"https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
			name: "John Doe",
		},
		socialProfile: placeholderSocialProfiles[0],
	},
	{
		type: "message",
		message:
			"I literally loose my mind with your new app! I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		user: {
			picture: "https://i.imgur.com/cBfXYY1.png",
			name: "Sarah Lean",
		},
		socialProfile: placeholderSocialProfiles[1],
	},
];

export const mentions: Array<any> = [
	{
		type: "mention",
		mentionType: "comment",
		replied: false,
		read: false,
		message:
			"I literally loose my mind with your new app! @soqu_official, I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		likes: 54,
		socialProfile: placeholderSocialProfiles[1],
		user: {
			picture: "https://i.imgur.com/cBfXYY1.png",
			name: "Sarah Lean",
		},
		post: posts[1],
	},
	{
		type: "mention",
		mentionType: "post",
		replied: false,
		read: false,
		likes: 54,
		socialProfile: placeholderSocialProfiles[1],
		message:
			"I literally loose my mind with your new app! @soqu_official, I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		user: {
			name: "Jake Adams",
			picture:
				"https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
		},
		attachments: [
			"https://i.imgur.com/t6IUBCw.png",
			"https://i.imgur.com/cBfXYY1.png",
			"https://i.imgur.com/ZjOCYK1.png",
		],
	},
];

export const reposts: Array<any> = [
	{
		type: "repost",
		message:
			"I literally loose my mind with your new app! I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		user: {
			picture:
				"https://t3.ftcdn.net/jpg/02/94/62/14/360_F_294621430_9dwIpCeY1LqefWCcU23pP9i11BgzOS0N.jpg",
			name: "John Doe",
		},
		likes: 743,
		liked: true,
		read: true,
		post: posts[0],
		socialProfile: placeholderSocialProfiles[0],
	},
	{
		type: "repost",
		message:
			"I literally loose my mind with your new app! I'm sure you will blow out all the digital market! What are your next steps in its development? I found suddenly in App Store and adviced it to all my friends already. Are you going to localize it for Spain? My Spanish friend are possesed by it but can't use one in their country. Please tell me about your world promotion plans",
		user: {
			picture: "https://i.imgur.com/cBfXYY1.png",
			name: "Sarah Lean",
		},
		likes: 23,
		post: posts[2],
		replied: true,
		socialProfile: placeholderSocialProfiles[1],
	},
];

export const replyComments: Array<Reply> = [
	{
		socialProfile: placeholderSocialProfiles[0],
		interaction: comments[1],
		scheduled: true,
		comments: 12,
		message: "Thank you for your warm words! We do appreciate your feedback.",
		likes: 32,
	},
	{
		socialProfile: placeholderSocialProfiles[1],
		interaction: comments[0],
		comments: 2,
		message: "Thank you for your warm words! We do appreciate your feedback.",
		likes: 32,
	},
];

export const replyMessages: Array<Reply> = [
	{
		socialProfile: placeholderSocialProfiles[0],
		interaction: messages[1],
		message: "Thank you for your warm words! We do appreciate your feedback.",
		likes: 32,
	},
	{
		socialProfile: placeholderSocialProfiles[1],
		interaction: messages[0],
		message: "Thank you for your warm words! We do appreciate your feedback.",
		likes: 32,
	},
];

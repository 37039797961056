import { makeStyles, Popover } from "@material-ui/core";
import { useEffect, useState, MouseEvent } from "react";
import { NestedItem } from "..";
import { MessageIconBlue } from "assets";
import CalendarEvent from "types/CalendarEvent";
import CalendarItem from "./CalendarItem";

const useStyles = makeStyles((theme) => ({
	tagContainer: {
		position: "absolute",
		top: 6,
		right: 6,
		color: "#4B7FA3",
		fontSize: 11,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},

	icon: {
		height: 8,
	},

	menuPaper: { backgroundColor: "white" },
	menuItemContainer: {
		width: 150,
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
	},
}));

interface TagProps {
	menuAnchor: HTMLElement | null;
	setMenuAnchor: (anchor: HTMLElement | null) => void;
	nestedItems: NestedItem[];
	selectedItem: CalendarEvent;
	day: number;
	hour: string;
}

interface MenuProps {
	menuAnchor: HTMLElement | null;
	listItems: CalendarEvent[];
	handleClose: () => void;
}

const NestedItemsTag = (props: TagProps) => {
	const [listItems, setListItems] = useState<CalendarEvent[]>([]);
	const classes = useStyles();
	const { nestedItems, day, menuAnchor, setMenuAnchor, hour } = props;

	useEffect(() => {
		let listItems: CalendarEvent[] = [];
		nestedItems?.forEach((item) => {
			if (item.day === day && item.hour === hour) {
				listItems.push(...item.items);
			}
		});

		setListItems([...listItems]);
	}, [nestedItems]);

	const handleTagClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setMenuAnchor(e.currentTarget);
	};

	useEffect(() => {
		if (listItems.length === 0) {
			setMenuAnchor(null);
		}
	}, [listItems]);

	if (listItems.length < 2) return <></>;
	return (
		<>
			<NestedItemsMenu
				menuAnchor={menuAnchor}
				listItems={listItems}
				handleClose={() => setMenuAnchor(null)}
			/>

			<div onClick={handleTagClick} className={classes.tagContainer}>
				<div>
					{`+${listItems.length - 1}`}
					<img src={MessageIconBlue} className={classes.icon} />
				</div>
			</div>
		</>
	);
};

const NestedItemsMenu = (props: MenuProps) => {
	const { menuAnchor, listItems, handleClose } = props;
	const classes = useStyles();

	return (
		<Popover
			classes={{ paper: classes.menuPaper }}
			onClose={() => handleClose()}
			anchorEl={menuAnchor}
			open={Boolean(menuAnchor)}
		>
			<div>
				{listItems.map((item, index) => {
					// Skip the first item since it will be the one displayed in the time slot
					if (index === 0) return <></>;
					return (
						<div className={classes.menuItemContainer}>
							<CalendarItem key={index} event={item} hideNestedTag={true} />
						</div>
					);
				})}
			</div>
		</Popover>
	);
};

export default NestedItemsTag;

import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles(() => ({
	footer: {
		width: "100%",
		background: "#D9E3EB",
		height: 25,
		bottom: 0,
		flexDirection: "row",
		alignItems: "center",
		display: "flex",
		zIndex: 999,
	},

	text: {
		width: "50%",
		fontSize: 10,
		color: "#3E4C55",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	},
}));

const Footer = () => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<>
			<div
				className={classes.footer}
				style={{
					position: !history.location.pathname.includes("auth") ? "absolute" : "fixed",
				}}
			>
				<div className={classes.text}>
					Powered by HashtagHero © in {new Date().getFullYear()}. All rights reserved.
				</div>
			</div>
		</>
	);
};

export default Footer;

let config: { [index: string]: any } = {
	secure: false,
	stripeKey:
		"pk_test_51KKqRJHKIPtBwRw7RUBvGMdTpO91gXXh1fWyveebruKuDuzlXTqsWB2mbzdFziB2koQ7ONv4uPuKDCLTS1VH74EK00lqxHjiDZ",
};

if (process.env.NODE_ENV === "development") {
	config = {
		...config,
		cognitoCookiesDomain: "localhost",
		CognitoClientId: "35hbj0aqtu1ka55f1j7cshrllm",
		GoogleCognitoClientId: "6p5c9c8pkcfeiqi0pd18tgjdcu",
		FacebookCognitoClientId: "6juhj43tuj66fj5lcmje8t20t2",
		UserPoolId: "eu-central-1_TmvbLt3T9",
		apiBaseUrl: "http://127.0.0.1:8000",
		apiUrl: "http://127.0.0.1:8000/api",
		prodApiUrl: "https://api.hashtaghero.io/api",
		cognitoAuthUrl: "https://hashtaghero.auth.eu-central-1.amazoncognito.com",
		cognitoCallbackURL: "http://localhost:3000/login",
		clientUrl: `http://localhost:3000`,
	};
} else if (process.env.REACT_APP_ENV === "staging") {
	config = {
		...config,
		cognitoCookiesDomain: "localhost",
		CognitoClientId: "35hbj0aqtu1ka55f1j7cshrllm",
		GoogleCognitoClientId: "6p5c9c8pkcfeiqi0pd18tgjdcu",
		FacebookCognitoClientId: "6juhj43tuj66fj5lcmje8t20t2",
		UserPoolId: "eu-central-1_TmvbLt3T9",
		apiBaseUrl: "https://api.hashtaghero.io",
		apiUrl: "https://api.hashtaghero.io/api",
		prodApiUrl: "https://api.hashtaghero.io/api",
		cognitoAuthUrl: "https://hashtaghero.auth.eu-central-1.amazoncognito.com",
		cognitoCallbackURL: "http://d16f9wg2qhm3t5.cloudfront.net/login",
		clientUrl: `http://d16f9wg2qhm3t5.cloudfront.net`,
	};
} else if (process.env.REACT_APP_ENV === "production") {
	config = {
		...config,
		cognitoCookiesDomain: "app.hashtaghero.io",
		CognitoClientId: "35hbj0aqtu1ka55f1j7cshrllm",
		GoogleCognitoClientId: "6p5c9c8pkcfeiqi0pd18tgjdcu",
		FacebookCognitoClientId: "6juhj43tuj66fj5lcmje8t20t2",
		UserPoolId: "eu-central-1_TmvbLt3T9",
		apiBaseUrl: "https://api.hashtaghero.io",
		apiUrl: "https://api.hashtaghero.io/api",
		cognitoAuthUrl: "https://hashtaghero.auth.eu-central-1.amazoncognito.com",
		cognitoCallbackURL: "https://app.hashtaghero.io/login",
		clientUrl: `https://app.hashtaghero.io`,
	};
}

export const CurrentTeamLsKey = "CurrentTeam";
export const AuthProviderLsKey = "AuthProvider";
export const ExternalLogoUrl =
	"https://hashtaghero-public.s3.eu-central-1.amazonaws.com/logo-red.png";

export const chatBoxQuery = "chat"; // boolean
export const taskQuery = "task"; // task ID
export const selectedChatQuery = "selectedChat"; // channel name or user ID for private chat
export const selectedTeamQuery = "selectedTeam";
export const emailConfirmationCodeQuery = "confirmCode";
export const confirmationSuccessQuery = 'confirmSuccess';
export const unsubscribeEmailsQuery = 'unsubscribeEmails';
export const unsubscribleSuccessQuery = 'unsubscribeSuccess';
export const teamInviteQuery = "teamInvite";
export const onboardingQuery = 'onboarding';

export default config;

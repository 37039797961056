import axios from "axios";
import { Dispatch } from "react";
import config from "config";

import { StoreState } from "../../../setup/store";
import { DeleteMessageFunc } from "types/ReduxActions";
import { setSnackbarState } from "../snackBar";

export const deleteMessage: DeleteMessageFunc = (messageId, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		try {
			await axios.delete(`${config.apiUrl}/messages/${messageId}?teamId=${currentTeamId}`, {});
			cb();
		} catch (e) {
			console.log(e);
			cb();
			dispatch(setSnackbarState(true, "error", "DeleteMessageError"));
		}
	};
};

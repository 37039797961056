import { Dispatch } from "react";
import config from "config";
import {
	CALENDAR_LOADING,
	LIST_DAY_CALENDAR_EVENTS,
	SET_MONTH_CALENDAR_DATA,
	SET_WEEK_CALENDAR_DATA,
} from "../../reducers/calendar-events";
import axios from "axios";
import { setSnackbarState } from "../snackBar";
import { StoreState } from "../../../setup/store";
import { ListCalendarEventsFunc } from "types/ReduxActions";

export type CalendarEventsFilters = {
	from?: number;
	pageLimit?: number;
};

export const listCalendarEvents: ListCalendarEventsFunc = (filters) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		if (!filters?.from) {
			dispatch({
				type: CALENDAR_LOADING,
				payload: true,
			});
		}

		const date = new Date();
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);

		const params = {
			type: "day",
			date,
			...filters,
		};

		try {
			const result = await axios.get(`${config.apiUrl}/calendarEvents?teamId=${currentTeamId}`, {
				params,
			});

			dispatch({
				type: LIST_DAY_CALENDAR_EVENTS,
				payload: result.data.calendarEvents,
			});
		} catch (e) {
			console.log(e);
			dispatch({
				type: CALENDAR_LOADING,
				payload: false,
			});
			dispatch(setSnackbarState(true, "error", "Error fetching calendar events..."));
		}
	};
};

export const getCalendarData = (type: "month" | "week") => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		dispatch({
			type: CALENDAR_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(`${config.apiUrl}/calendarEvents/calendar-data`, {
				params: {
					teamId: currentTeamId,
					type,
				},
			});

			if (type === "month") {
				dispatch({
					type: SET_MONTH_CALENDAR_DATA,
					payload: result.data,
				});
			} else {
				dispatch({
					type: SET_WEEK_CALENDAR_DATA,
					payload: result.data,
				});
			}
		} catch (e: any) {
			console.log(e.response.data);
			dispatch({
				type: CALENDAR_LOADING,
				payload: false,
			});

			dispatch(setSnackbarState(true, "error", "GetCalendarDataError"));
		}
	};
};

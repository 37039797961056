import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "100vh",
		paddingTop: 12,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		maxWidth: 1100,
		width: "100%",
		marginLeft: "auto",
		marginRight: "auto",
		position: "relative",
		overflow: "hidden",
	},

	postsContainer: {
		display: "flex",
		flexDirection: "column",
		marginTop: 16,
		width: "100%",
	},

	centeredContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 120,
	},

	noResultsText: {
		fontSize: 35,
		fontWeight: 700,
		marginBottom: 24,
		textTransform: "uppercase",
		textAlign: "center",
		color: theme.palette.primary.main,
	},
}));

export default useStyles;

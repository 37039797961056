import { useEffect, useMemo, useState } from "react";
import useStyles from "./styles";
import { Tab } from "../../../components/Tabs";
import { InteractionsSectionFilters, Sort } from "../FilterMenu/FilterMenu";
import Interaction, { SocialComment, SocialMessage } from "types/Interaction";
import InteractionItem from "../InteractionItem";
import Button from "components/Basic/Button";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "setup/store";

import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextButton from "components/Basic/TextButton";
import Post from "types/Post";
import SocialProfile from "types/SocialProfile";

import InteractionsSectionHeader from "./SectionHeader";
import {
	listSocialInteractions,
	ListSocialInteractionsFilters,
} from "redux/actions/social-interactions/social-interactions";
import { capitalize } from "lodash";
import { DateRange } from "pages/Reply";

export type InteractionSectionType =
	| "comments"
	| "messages"
	| "reposts"
	| "mentions"
	| "commentReplies"
	| "messageReplies";

interface Props {
	tabs: string[];
	selectedPosts?: Post[];
	selectedSocialProfile?: SocialProfile;
	sectionType: InteractionSectionType;
	dateRange?: DateRange;
}

const InteractionsSection: React.FC<Props> = (props) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [selectedTab, setSelectedTab] = useState<Tab>();
	const [filters, setFilters] = useState<InteractionsSectionFilters>({});
	const [sort, setSort] = useState<Sort>("desc");
	const { t } = useTranslation("reply");
	const dispatch = useDispatch();
	const classes = useStyles();
	const { tabs, sectionType, selectedPosts, selectedSocialProfile, dateRange } = props;
	const { interactions, interactionsLoading, fetchMoreLoading, total, currentTeamId } = useSelector(
		(state: StoreState) => {
			const {
				comments,
				messages,
				totalMessages,
				totalComments,
				commentsFetchMoreLoading,
				commentsLoading,
				messagesLoading,
				messagesFetchMoreLoading,
			} = state.socialInteractions;

			let interactions: Partial<SocialComment & SocialMessage>[] = [];
			let interactionsLoading = false;
			let fetchMoreLoading = false;
			let total = 0;

			if (sectionType === "comments") {
				interactions = comments;
				total = totalComments;
				interactionsLoading = commentsLoading;
				fetchMoreLoading = commentsFetchMoreLoading;
			} else if (sectionType === "messages") {
				interactions = messages;
				total = totalMessages;
				interactionsLoading = messagesLoading;
				fetchMoreLoading = messagesFetchMoreLoading;
			}

			return {
				interactionsLoading,
				fetchMoreLoading,
				interactions,
				total,
				currentTeamId: state.teams.currentTeamId,
			};
		}
	);

	const hasMore = useMemo(() => interactions.length < total, [interactions, total]);

	const fetchData = (from?: number) => {
		if (!currentTeamId) {
			return;
		}

		let requestFilters: ListSocialInteractionsFilters = {};

		if (dateRange?.startDate) {
			requestFilters.startDate = dateRange?.startDate.toISOString();
		}

		if (dateRange?.endDate) {
			requestFilters.endDate = dateRange?.endDate.toISOString();
		}

		requestFilters.sort = sort;
		requestFilters.from = from;

		if (selectedPosts?.length) {
			requestFilters.socialProfileIds = [];

			selectedPosts.forEach((post) => {
				requestFilters.socialPostIds = [
					...(requestFilters.socialProfileIds || []),
					...(post.socialIds || []),
				];
			});
		}

		if (filters.socialProfiles) {
			requestFilters.socialProfileIds = filters.socialProfiles.map((profile) => profile._id);
		}

		if (
			selectedSocialProfile &&
			!requestFilters.socialPostIds?.includes(selectedSocialProfile._id)
		) {
			requestFilters.socialProfileIds = [
				...(requestFilters.socialProfileIds || []),
				selectedSocialProfile._id,
			];
		}

		if (sectionType === "comments") {
			if (selectedTab?.name === "unrepliedComments") {
				requestFilters = { ...(filters || {}), unreplied: true };
			}

			dispatch(listSocialInteractions("comment", requestFilters));
		} else if (sectionType === "messages") {
			dispatch(listSocialInteractions("message", requestFilters));
		}
	};

	const handleLoadMoreClick = () => {
		fetchData(interactions.length);
	};

	// TODO: Debounce data fetching when changing filters and sort
	// TODO: Show a loading spinner when a debounce is in effect
	useEffect(() => {
		fetchData();
	}, [currentTeamId, selectedTab, selectedPosts, selectedSocialProfile, filters, sort, dateRange]);

	return (
		<div className={classes.container}>
			<InteractionsSectionHeader
				tabs={tabs}
				selectedTab={selectedTab}
				selectedSocialProfile={selectedSocialProfile}
				sort={sort}
				filters={filters}
				sectionType={sectionType}
				setSort={setSort}
				setFilters={setFilters}
				setSelectedTab={setSelectedTab}
			/>
			<div className={classes.itemsContainer}>
				{interactionsLoading && (
					<Box className={classes.loadingContainer}>
						<CircularProgress size={20} />
					</Box>
				)}

				{!interactionsLoading && interactions.length === 0 && (
					<Typography style={{ fontSize: 12, marginLeft: 12 }}>
						{t(`no${capitalize(sectionType)}Found`)}
					</Typography>
				)}

				{/* Show only the most recent 3 interactions if the section is not expanded */}
				{!interactionsLoading &&
					(isExpanded && interactions.length > 3 ? interactions : interactions.slice(0, 3))?.map(
						(interaction, index) => (
							// sectionType === "commentReplies" ? (
							// 	<ReplyItem key={index} reply={interaction as Reply} />
							// ) :
							<InteractionItem key={index} interaction={interaction as Interaction} />
						)
					)}
			</div>
			{!interactionsLoading && !isExpanded && interactions.length > 3 && (
				<div className={classes.viewAllButtonContainer}>
					<Button onClick={() => setIsExpanded(true)} secondary className={classes.viewAllButton}>
						{t("viewAll")}
					</Button>
				</div>
			)}

			{isExpanded && hasMore && (
				<Box marginTop={2} width="fit-content" marginLeft="auto" marginRight="auto">
					<TextButton disabled={fetchMoreLoading} onClick={handleLoadMoreClick}>
						{t("loadMore")}

						{fetchMoreLoading && <CircularProgress size={15} style={{ marginLeft: 8 }} />}
					</TextButton>
				</Box>
			)}
		</div>
	);
};

export default InteractionsSection;

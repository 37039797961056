import { Dialog } from "@material-ui/core";
import { getDragActiveStyles } from "helpers/utils";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { setSelectedTask } from "redux/actions/tasks/get";
import { SET_TASK_COMMENTS } from "redux/reducers/tasks";
import Task, { MediaType } from "types/Task";
import useTaskDialogStyles from "../styles/task-dialog";
import TaskDialogEditMode from "./EditMode/EditMode";
import TaskDialogViewMode from "./ViewMode/ViewMode";

interface Props {
	open: boolean;
	selectedTask?: Task;
	onSubmit?: (updatedTask: Task, deleted?: boolean) => void;
	onClose: () => void;
}

export type EditModeInputValues = Partial<Task> & {
	media?: Array<{ type: "video" | "image"; value: File | string }>;
};

const TaskDialog = (props: Props) => {
	const [inputValues, setInputValues] = useState<EditModeInputValues>({});
	const [editMode, setEditMode] = useState<boolean>(false);
	const { open, selectedTask, onSubmit, onClose } = props;
	const classes = useTaskDialogStyles();
	const dispatch = useDispatch();

	const handleFilesChange = (files: FileList | null) => {
		if (!files) return;

		setInputValues({
			...inputValues,
			media: [
				...(inputValues.media || []),
				...Array.from(files).map((file) => ({
					type: file.type.split("/")[0] as MediaType,
					value: file,
				})),
			],
		});
	};

	useEffect(() => {
		setEditMode(false);
		if (open) {
			if (selectedTask) {
				dispatch(setSelectedTask(selectedTask));
			}
		} else {
			dispatch({
				type: SET_TASK_COMMENTS,
				payload: [],
			});
			dispatch(setSelectedTask(undefined));
		}
	}, [open]);

	return (
		<Dialog classes={{ paper: classes.paper }} onClose={onClose} open={open}>
			<Dropzone
				disabled={!editMode && Boolean(selectedTask)}
				accept={{ "video/*": [], "image/*": [] }}
				onDrop={(files) => handleFilesChange(files as unknown as FileList)}
			>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<div
						className={classes.container}
						style={{
							...getDragActiveStyles(isDragActive),
						}}
						{...getRootProps()}
						onClick={(e) => e.stopPropagation()}
					>
						<input {...getInputProps()} />
						{(!selectedTask || editMode) && (
							<TaskDialogEditMode
								selectedTask={selectedTask}
								inputValues={inputValues}
								onFilesChange={handleFilesChange}
								setInputValues={setInputValues}
								onSubmit={onSubmit}
								setEditMode={setEditMode}
								onClose={onClose}
							/>
						)}

						{selectedTask && !editMode && (
							<TaskDialogViewMode
								selectedTask={selectedTask}
								setEditMode={setEditMode}
								onSubmit={onSubmit}
								onClose={onClose}
							/>
						)}
					</div>
				)}
			</Dropzone>
		</Dialog>
	);
};

export default TaskDialog;

import { Box, makeStyles } from "@material-ui/core";
import { CSSProperties } from "react";

interface Props {
	style?: CSSProperties;
}

const useStyles = makeStyles(() => ({
	card: {
		padding: 24,
		borderRadius: 8,
		backgroundColor: "#F1F4F8",
	},
}));

const Card: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { children, style } = props;

	return (
		<Box style={style} className={classes.card}>
			{children}
		</Box>
	);
};

export default Card;

import { Box } from "@material-ui/core";
import WatchTeamUserDetailsHeader from "modules/Tasks/WatchTeam/WatchTeamUserDetails/Header";
import WatchTeamUserTasksSection from "modules/Tasks/WatchTeam/WatchTeamUserDetails/Section";
import { TaskStatus } from "types/Task";
import { TeamUser } from "types/User/Team";

interface Props {
	teamUser: TeamUser;
	onClose: () => void;
}

const sections: TaskStatus[] = ["inProgress", "finished"];

const WatchTeamUserDetails = (props: Props) => {
	const { teamUser, onClose } = props;

	return (
		<Box>
			<WatchTeamUserDetailsHeader teamUser={teamUser} onClose={onClose} />

			<Box display="flex" style={{ marginTop: 62, paddingLeft: 8 }}>
				{sections.map((section, index) => (
					<WatchTeamUserTasksSection key={index} user={teamUser.user!} section={section} />
				))}
			</Box>
		</Box>
	);
};

export default WatchTeamUserDetails;

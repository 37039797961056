const AttachmentIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || "30"}
			height={size || "30"}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M26.3393 15L16.0583 25.281C14.6375 26.7018 12.7105 27.5 10.7011 27.5C6.51696 27.5 3.125 24.108 3.125 19.9239C3.125 17.9145 3.9232 15.9875 5.344 14.5667L15.9314 3.97933C16.8786 3.03213 18.1633 2.5 19.5028 2.5C22.2923 2.5 24.5536 4.7613 24.5536 7.55076C24.5536 8.89031 24.0214 10.175 23.0742 11.1222L12.7932 21.4032C12.3196 21.8768 11.6773 22.1429 11.0075 22.1429C9.61279 22.1429 8.48214 21.0122 8.48214 19.6175C8.48214 18.9477 8.74821 18.3054 9.22181 17.8318L19.1964 7.85714"
				stroke={color || "#4B7FA3"}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default AttachmentIconSvg;

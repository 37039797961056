// import useProfileDetailsStyles from "../../styles/social-profile-details";

const DataCards = () => {
	// const classes = useProfileDetailsStyles();

	return <></>

	// TODO: To be implemented
	// return (
	// 	<div className={classes.dataCards}>
	// 		<div className={classes.dataCard}>
	// 			<div>
	// 				<div className={classes.cardValue}>no data</div>
	// 				<div className={classes.cardTitle}>Views for last 7 days</div>
	// 			</div>
	// 		</div>
	// 		<div className={classes.dataCard}>
	// 			<div>
	// 				<div className={classes.cardValue}>no data</div>
	// 				<div className={classes.cardTitle}>Likes for last 7 days</div>
	// 			</div>
	// 		</div>
	// 		<div className={classes.dataCard}>
	// 			<div>
	// 				<div className={classes.cardValue}>no data</div>
	// 				<div className={classes.cardTitle}>Followers for last 7 days</div>
	// 			</div>
	// 		</div>
	// 		<div className={classes.dataCard}>
	// 			<div>
	// 				<div className={classes.cardValue}>no data</div>
	// 				<div className={classes.cardTitle}>Last Update</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// );
};

export default DataCards;

import { createStyles, LinearProgress, withStyles } from "@material-ui/core";
import ClockChart from "../../components/Charts/ClockChart";
import { timeAnalytics } from "../../mock/analyticsPlaceholderData";
import useHashtagAnalyticsStyles from "./styles/hashtag-analytics";
import useTimeAnalyticsStyles from "./styles/time-analytics";

const PrimaryLinearProgress = withStyles(() =>
	createStyles({
		root: {
			height: 10,
			width: "100%",
			borderRadius: 5,
			marginLeft: 24,
			marginRight: 24,
		},
		colorPrimary: {
			backgroundColor: "transparent",
		},
		bar: {
			borderRadius: 5,
			backgroundColor: "#F04655",
		},
	})
)(LinearProgress);

const TimeAnalytics = () => {
	const hashtagClasses = useHashtagAnalyticsStyles();
	const classes = useTimeAnalyticsStyles();

	return (
		<>
			<div className={hashtagClasses.card} style={{ marginRight: 8 }}>
				<div className={hashtagClasses.cardTitle}>Weekly Activity Statistics</div>
				{timeAnalytics.map((day, index) => (
					<div key={index} style={{ marginTop: 5 }} className={hashtagClasses.hashtag}>
						<span style={{ width: 160 }}>{day.title}</span>
						<PrimaryLinearProgress variant="determinate" value={day.value} />
					</div>
				))}
			</div>

			<div className={classes.chartContainer}>
				<div className={classes.clockContainer}>
					<ClockChart />
					<div className={classes.clockLabel}>
						Most Active Time:
						<br />
						6:00 PM - 7:00 PM
					</div>
				</div>

				<div className={classes.clockContainer}>
					<ClockChart />
					<div className={classes.clockLabel}>
						Least Active Time:
						<br />
						6:00 PM - 7:00 PM
					</div>
				</div>
			</div>
		</>
	);
};

export default TimeAnalytics;

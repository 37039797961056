import moment from "moment";
import { NestedItem } from "..";
import WeeklyCalendarItem from "types/WeeklyCalendar";
import useWeeklyCalendarStyles from "../styles";
import CalendarItem from "./CalendarItem";

interface Props {
	dayItem: WeeklyCalendarItem;
	hour: string;
	nestedItems: NestedItem[];
}

const CalendarCell = (props: Props) => {
	const classes = useWeeklyCalendarStyles();
	const { dayItem, hour, nestedItems } = props;

	return (
		<div className={classes.hourCell}>
			{dayItem.items.map((eventItem, index) => {
				let scheduleHour = moment(eventItem.scheduledAt!);
				scheduleHour.set({ minute: 0 });

				if (moment(scheduleHour.toString()).format("hh:mm A") === hour) {
					return (
						<CalendarItem
							key={index}
							hour={hour}
							day={dayItem.day}
							nestedItems={nestedItems}
							event={eventItem}
						/>
					);
				}
			})}
		</div>
	);
};

export default CalendarCell;

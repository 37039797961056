import { useRef, useState } from "react";
import { PaymentMethodIllustration } from "assets";
import Button from "components/Basic/Button";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import BillingMethodItem from "modules/Profile/Billing/BillingMethodItem";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { listPaymentMethods } from "redux/actions/team/subscriptions/billing";
import { PaymentMethod } from "types/Billing";
import { Box, CircularProgress } from "@material-ui/core";
import EmptyPageMessage from "components/EmptyPageMessage";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "setup/store";
import TitleText from "components/Basic/Text/TitleText";
import { setBillingMethodDialog } from "redux/actions/modals";

const BillingMethods = () => {
	const [methodConfirmation, setMethodConfirmation] = useState<boolean>(false);
	const { t } = useTranslation("settings");
	const classes = useStyles();
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { paymentMethods, paymentMethodsLoading } = useSelector(
		(state: StoreState) => state.subscription
	);
	/** Team ID that the current methods list is for */
	const listedTeamId = useRef<string>("");
	const dispatch = useDispatch();

	const handleAddMethodClick = () => {
		dispatch(setBillingMethodDialog(true));
	};

	const handleUpdateBillingAddressClick = (method: PaymentMethod) => {
		dispatch(setBillingMethodDialog(true, method));
	};

	useEffect(() => {
		if (paymentMethods.length === 0 || (currentTeamId && listedTeamId.current !== currentTeamId)) {
			listedTeamId.current = currentTeamId;
			dispatch(listPaymentMethods());
		}
	}, [currentTeamId]);

	if (!currentTeamId) return null;

	return (
		<>
			<ConfirmationDialog
				open={methodConfirmation}
				onClose={() => setMethodConfirmation(false)}
				onAction={() => {}}
				hideButtons={true}
				image={PaymentMethodIllustration}
				message={t("paymentMethodSuccess")}
				autoClose
			/>

			<TitleText style={{ marginLeft: 32 }}>{t("billingMethods")}</TitleText>
			<div className={classes.card} style={{ display: "flex", flexDirection: "column" }}>
				<Box style={{ maxHeight: "80%", overflowY: "auto" }}>
					{paymentMethods.map((method, index) => (
						<BillingMethodItem
							onUpdateBillingAddressClick={handleUpdateBillingAddressClick}
							method={method}
							key={index}
						/>
					))}
				</Box>

				{!paymentMethodsLoading && paymentMethods.length === 0 && (
					<div style={{ marginTop: 16 }}>
						<EmptyPageMessage
							titleStyle={{ fontSize: 22 }}
							illustrationStyle={{ width: 250 }}
							title={t("noBillingMethods")}
						/>
					</div>
				)}

				{paymentMethodsLoading && (
					<Box style={{ margin: "auto" }}>
						<CircularProgress size={25} />
					</Box>
				)}

				<div className={classes.buttonContainer}>
					<Button onClick={handleAddMethodClick} secondary style={{ width: 170 }}>
						+ {t("addBillingMethod")}
					</Button>
				</div>
			</div>
		</>
	);
};

export default BillingMethods;

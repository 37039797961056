import React, { MouseEvent, useState } from "react";
import { ListenViewType } from "../..";
import Button from "components/Basic/Button";
import DurationInput from "components/Basic/Inputs/DurationInput";
import SocialProfile from "types/SocialProfile";
import CompareMenu from "./CompareMenu";
import useStyles from "./styles";

interface Props {
	selectedView: ListenViewType;
	selectedProfiles: SocialProfile[];
	setSelectedView: (view: ListenViewType) => void;
	setPostsDialog: (dialog: "single" | "compare") => void;
	setProfilesDialog: (dialog: "single" | "compare") => void;
}

const Header = (props: Props) => {
	const classes = useStyles();
	const [compareMenu, setCompareMenu] = useState<HTMLElement | null>(null);
	const { selectedView, selectedProfiles, setSelectedView, setPostsDialog, setProfilesDialog } =
		props;

	const handleCompareClick = (e: MouseEvent<HTMLElement>) => {
		setCompareMenu(e.currentTarget);
	};

	const handleOptionClick = (option: "comparePosts" | "compareProfiles") => {
		if (option === "comparePosts") {
			setPostsDialog("compare");
		} else {
			setProfilesDialog("compare");
		}
	};

	return (
		<div className={classes.headerContainer}>
			<CompareMenu
				anchorEl={compareMenu}
				handleClose={() => setCompareMenu(null)}
				handleOptionClick={handleOptionClick}
			/>
			<div className={classes.buttonsContainer}>
				<Button
					className={classes.button}
					secondary
					selected={selectedView === "general"}
					onClick={() => setSelectedView("general")}
				>
					General
				</Button>

				<Button
					className={classes.button}
					secondary
					selected={selectedView === "bySocialProfile"}
					onClick={() => setProfilesDialog("single")}
				>
					{selectedView === "bySocialProfile" ? selectedProfiles[0].network : "By Social Profile"}
				</Button>

				<Button
					className={classes.button}
					secondary
					selected={selectedView === "byPost"}
					onClick={() => setPostsDialog("single")}
				>
					By Post
				</Button>

				<Button
					className={classes.button}
					secondary
					selected={selectedView === "comparePosts" || selectedView === "compareSocialProfiles"}
					onClick={handleCompareClick}
				>
					Compare
				</Button>
			</div>

			<div className={classes.rightSection}>
				<DurationInput height={36} width={220} />
				<Button secondary style={{ width: 91, marginLeft: 16 }}>
					Today
				</Button>
			</div>
		</div>
	);
};

export default Header;

import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PaymentPeriod } from "redux/actions/team/subscriptions/plans";
import TSubscriptionPlan from "types/SubscriptionPlan";
import PlanFeatures, { PlanNetworks } from "../../SubscriptionPlans/SubscriptionPlan/PlanFeatures";
import SubscriptionPriceSummary from "../../SubscriptionPriceSummary";
import useSubscriptionDialogStyles from "../styles";

interface Props {
	period: PaymentPeriod;
	plan: TSubscriptionPlan;
	userAddons: number;
}

const SubscriptionDialogSummary = ({ plan, period, userAddons }: Props) => {
	const classes = useSubscriptionDialogStyles();
	const { t } = useTranslation("settings");

	return (
		<Box className={classes.rightColumn}>
			<div className={classes.sectionTitle}>{t("subscriptionPlan")}</div>

			<Box className={classes.planBox}>
				<Box display="flex" alignItems="center">
					<Box className={classes.planTitle}>{plan.title}</Box>

					<Box width="fit-content" marginLeft="auto">
						<PlanNetworks plan={plan} />
					</Box>
				</Box>
				<PlanFeatures hideNetworks plan={plan} style={{ marginTop: 16 }} />
			</Box>

			<SubscriptionPriceSummary plan={plan} period={period} userAddons={userAddons} />
		</Box>
	);
};

export default SubscriptionDialogSummary;

import { makeStyles } from "@material-ui/core";

const useWatchTeamStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		padding: 16,
		paddingTop: 8,
		paddingBottom: 8,
		display: "flex",
		alignItems: "center",
		backgroundColor: "#F7F7F7",
		marginTop: 12,
		borderRadius: 8,
		cursor: "pointer",
	},

	title: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.text.primary,
	},

	subtitle: {
		fontSize: 12,
		color: "#5B666E",
	},

	sectionLabel: {
		color: "#868C91",
		fontWeight: 600,
		cursor: "pointer",
		fontSize: 14,
	},
}));

export default useWatchTeamStyles;

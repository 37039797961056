import React, { useState, ChangeEvent, useEffect } from "react";
import Campaign from "types/Campaign";
import { Dialog, CircularProgress } from "@material-ui/core";
import Network, { networkIcons, networks, selectedNetworkIcons } from "types/Network";
import useStyles from "./styles/dialog-styles";
import Input from "components/Basic/Inputs/Input";
import ColorMark from "assets/SVG/ColorMark";
import Button from "components/Basic/Button";
import DateInput from "components/Basic/Inputs/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../setup/store";
import { submitCampaign } from "redux/actions/campaigns/create";
import { CREATE_CAMPAIGN_SUCCESS, DELETE_CAMPAIGN_SUCCESS } from "redux/reducers/campaigns";
import { deleteCampaign } from "redux/actions/campaigns/delete";
import { useTranslation } from "react-i18next";

interface Props {
	open: boolean;
	selectedCampaign?: Campaign;
	onClose: () => void;
}

const colors = ["#E42631", "#F2994A", "#F2C94C", "#27AE60", "#2D9CDB", "#BB6BD9"];

export default function CampaignDialog(props: Props) {
	const classes = useStyles();
	const {
		createCampaignErrors: errors,
		createCampaignSuccess: success,
		createCampaignLoading: loading,
		deleteCampaignLoading: deleteLoading,
		deleteCampaignSuccess: deleteSuccess,
	} = useSelector((state: StoreState) => state.campaigns);
	const dispatch = useDispatch();
	const { t } = useTranslation("campaigns");
	const [inputValues, setInputValues] = useState<Partial<Campaign>>({});
	const { open, onClose, selectedCampaign } = props;

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleNetworkClick = (network: Network) => {
		if (!inputValues) return;
		let { networks } = inputValues;
		if (!networks) {
			networks = [];
		}

		if (networks.includes(network)) {
			networks.splice(networks.indexOf(network), 1);
		} else {
			networks.push(network);
		}

		setInputValues({ ...inputValues, networks });
	};

	const handleColorClick = (color: string) => {
		setInputValues({ ...inputValues, color });
	};

	const handleDeleteClick = () => {
		if (selectedCampaign) {
			dispatch(deleteCampaign(selectedCampaign._id!));
		}
	};

	const handleCancelClick = () => {
		onClose();
		setInputValues({});
	};

	const handleSaveClick = () => {
		if (selectedCampaign) {
			dispatch(submitCampaign(inputValues, selectedCampaign._id));
		} else {
			dispatch(submitCampaign(inputValues));
		}
	};

	useEffect(() => {
		if (selectedCampaign) {
			setInputValues({ ...selectedCampaign });
		} else {
			setInputValues({});
		}
	}, [selectedCampaign]);

	useEffect(() => {
		if (success || deleteSuccess) {
			dispatch({
				type: CREATE_CAMPAIGN_SUCCESS,
				payload: false,
			});
			dispatch({
				type: DELETE_CAMPAIGN_SUCCESS,
				payload: false,
			});
			setInputValues({});
			onClose();
		}
	}, [success, deleteSuccess, errors]);

	return (
		<Dialog classes={{ paper: classes.paper }} open={open} onClose={onClose}>
			<div className={classes.dialogContainer}>
				<div className={classes.header}>
					<span className={classes.dialogTitle}>
						{selectedCampaign ? t("campaign") : t("newCampaign")}
					</span>
				</div>

				<div className={classes.form}>
					<div className={classes.inputContainer}>
						<span className={classes.inputTitle}>{t("campaignTitle")}</span>
						<Input
							style={{ width: "100%", marginTop: 4, fontSize: 12 }}
							name="title"
							error={errors?.title}
							value={inputValues.title || ""}
							onChange={handleInputChange}
						/>
					</div>

					<div className={classes.inputContainer} style={{ marginTop: 12 }}>
						<span className={classes.inputTitle}>{t("campaignDescription")}</span>
						<Input
							style={{ width: "100%", marginTop: 4, fontSize: 12 }}
							rows={5}
							multiline
							name="description"
							error={errors?.description}
							value={inputValues.description || ""}
							onChange={handleInputChange}
						/>
					</div>

					<div className={classes.flexContainer}>
						<div className={classes.inputContainer}>
							<span className={classes.inputTitle}>{t("fromDate")}</span>
							<DateInput
								value={inputValues.startDate}
								error={errors?.startDate}
								style={{ marginRight: 16, marginTop: 4 }}
								onChange={(date) =>
									setInputValues({
										...inputValues,
										startDate: date.toString(),
									})
								}
							/>
						</div>
						<div className={classes.inputContainer}>
							<span className={classes.inputTitle}>{t("tillDate")}</span>
							<DateInput
								value={inputValues.endDate}
								error={errors?.endDate}
								style={{ marginRight: 16, marginTop: 4 }}
								onChange={(date) =>
									setInputValues({
										...inputValues,
										endDate: date.toString(),
									})
								}
							/>
						</div>
					</div>

					<div className={classes.inputContainer} style={{ marginTop: 16 }}>
						<span className={classes.inputTitle}>{t("selectNetwork")}</span>
						<div className={classes.networksContainer}>
							{networks.map((network, index) => (
								<div
									key={index}
									className={classes.iconContainer}
									onClick={() => handleNetworkClick(network)}
								>
									<img
										height={network === "linkedin" ? 33 : 28}
										src={
											inputValues?.networks?.includes(network)
												? selectedNetworkIcons[network]
												: networkIcons[network]
										}
									/>
								</div>
							))}
						</div>
					</div>

					{errors?.networks && <div className={classes.error}>{errors.networks}</div>}

					<div className={classes.inputContainer} style={{ marginTop: 16 }}>
						<span className={classes.inputTitle}>{t("addColorMark")}</span>
						<div className={classes.networksContainer}>
							{colors.map((color, index) => (
								<div
									key={index}
									className={classes.iconContainer}
									onClick={() => handleColorClick(color)}
								>
									<ColorMark color={color} height={35} selected={inputValues.color === color} />
								</div>
							))}
						</div>
					</div>

					<div className={classes.bottomButtons}>
						{selectedCampaign && (
							<Button
								onClick={handleDeleteClick}
								disabled={deleteLoading || loading}
								secondary
								style={{ width: 120 }}
							>
								{deleteLoading ? <CircularProgress size={20} /> : t("deleteCampaign")}
							</Button>
						)}

						<div className={classes.rightButtons}>
							<Button
								disabled={loading || deleteLoading}
								onClick={handleCancelClick}
								style={{ width: 120, marginRight: 8 }}
								secondary={true}
							>
								{t("cancel")}
							</Button>
							<Button
								disabled={loading || deleteLoading}
								onClick={handleSaveClick}
								style={{ width: 120 }}
							>
								{loading ? <CircularProgress size={20} /> : t("save")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
}

import { Box, makeStyles } from "@material-ui/core";
import { CSSProperties, Fragment } from "react";
import UserPicture from "../../components/Media/UserPicture";
import Task from "types/Task";

interface Props {
	task: Task;
	spacedCircles?: boolean;
	circleStyle?: CSSProperties;
}

const useStyles = makeStyles((theme) => ({
	unreadCommentBadge: {
		backgroundColor: theme.palette.primary.main,
		border: "1px solid white",
		position: "absolute",
		width: 10,
		height: 10,
		borderRadius: "50%",
		zIndex: 9,
		top: 0,
		right: 12,
	},
}));

const TaskCardAssigneesPictures = (props: Props) => {
	const classes = useStyles();
	const { task, circleStyle, spacedCircles } = props;

	return (
		<Box display="flex" alignItems="center">
			{task.assignees.map((assignee, index) => {
				if (index > 3) return <Fragment key={index} />;

				return (
					<Box
						key={index}
						style={{
							marginRight: !spacedCircles ? -24 : 0,
							position: "relative",
						}}
					>
						<UserPicture
							user={assignee}
							style={{
								width: 30,
								height: 30,
								border: "1px solid white",
								...circleStyle,
							}}
						/>

						{task.comments?.find((comment) => comment.user === assignee._id) && (
							<Box
								style={{ right: spacedCircles ? 6 : 12 }}
								className={classes.unreadCommentBadge}
							/>
						)}
					</Box>
				);
			})}
		</Box>
	);
};

export default TaskCardAssigneesPictures;

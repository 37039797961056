import { Box, useTheme } from "@material-ui/core";
import HintText from "components/Basic/Text/HintText";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import CancelledSubscriptionButton from "../../SubscriptionPlans/CurrentSubscriptionPlan/components/CancelledSubscriptionButton";
import { TeamMembersAddonsDialogPayload } from "../TeamMembersAddonsDialog";

interface Props {
	payload: TeamMembersAddonsDialogPayload;
	userAddonPrice: number;
}

const TeamMembersAddonsWarnings: React.FC<Props> = (props) => {
	const { currentTeamSubscription } = useSelector((store: StoreState) => store.subscription);
	const theme = useTheme();
	const { t } = useTranslation("settings");
	const { userAddonPrice, payload } = props;

	const getNewSubscriptionPrice = () => {
		if (!currentTeamSubscription) return 0;

		return currentTeamSubscription.price + userAddonPrice * (payload.extraTeamMembers || 0);
	};

	if (!currentTeamSubscription) {
		return null;
	}

	const { nextBillingAt, status, hasScheduledChanges } = currentTeamSubscription;

	return (
		<>
			{payload.applyChangesAt === "immediately" && nextBillingAt && (
				<HintText isWarningIconVisible style={{ marginTop: 24 }}>
					{t("newSubscriptionPriceWarning", {
						nextBillingAt: moment.unix(nextBillingAt).format("DD MMM yyyy"),
						nextSubscriptionPrice: `$${getNewSubscriptionPrice()} / ${
							currentTeamSubscription?.billingPeriodUnit
						}`,
					})}
				</HintText>
			)}

			{payload.applyChangesAt === "nextBillingPeriod" && nextBillingAt && (
				<HintText isWarningIconVisible style={{ marginTop: 24 }}>
					{t("nextBillingPeriodWarning", {
						nextBillingAt: moment.unix(nextBillingAt).format("DD MMM yyyy"),
					})}
				</HintText>
			)}

			{hasScheduledChanges && (
				<HintText isWarningIconVisible style={{ marginTop: 8 }}>
					{t("alreadyHasScheduledChanges")}
				</HintText>
			)}

			{status === "cancelled" && <CancelledSubscriptionButton style={{ marginTop: 16 }} />}

			{status === "non_renewing" && (
				<HintText
					isWarningIconVisible
					style={{ color: theme.palette.error.dark, maxWidth: "50%", marginTop: 16 }}
				>
					<Box>{t("schedulledCancellationError")}</Box>
				</HintText>
			)}
		</>
	);
};

export default TeamMembersAddonsWarnings;

import Button from "components/Basic/Button";
import TableIconSelected from "assets/table-icon-selected.png";
import TableIcon from "assets/table-icon.png";
import ListIcon from "assets/list-icon.png";
import ListIconSelected from "assets/list-icon-selected.png";
import DurationInput from "components/Basic/Inputs/DurationInput";
import { useHistory } from "react-router";
import PostsFilterMenu, { PostFilters, PostFilterType } from "components/Popovers/PostsFilter";
import useHeaderStyles from "../styles/header-styles";
import { DateFilters, PostScheduleView } from "..";
import PostFilterTab from "modules/Posts/FilterTab";
import { CircularProgress } from "@material-ui/core";
import { StoreState } from "../../../../setup/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
	selectedView: PostScheduleView;
	appliedFilters: PostFilters;
	dateFilters: DateFilters;
	setDateFilters: (filters: DateFilters) => void;
	setAppliedFilters: (appliedFilters: PostFilters) => void;
	setSelectedView: (view: PostScheduleView) => void;
}

const Header = (props: Props) => {
	const classes = useHeaderStyles();
	const history = useHistory();
	const { t } = useTranslation("posts");
	const { posts, listPostsLoading } = useSelector((state: StoreState) => state.posts);
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const {
		selectedView,
		setSelectedView,
		appliedFilters,
		setAppliedFilters,
		dateFilters,
		setDateFilters,
	} = props;

	const handleDurationInputChange = (startDate: Date | undefined, endDate: Date | undefined) => {
		setDateFilters({
			startDate: startDate || new Date(),
			endDate,
		});
	};

	const handleFilterDelete = (type: PostFilterType, value: string) => {
		appliedFilters.forEach((filter, index) => {
			if (filter.value === value) {
				appliedFilters.splice(index, 1);
			}
		});

		setAppliedFilters([...appliedFilters]);
	};

	return (
		<>
			<div className={classes.container}>
				<div className={classes.linearContainer}>
					<Link to="/posts/new" style={{ textDecoration: "none" }}>
						<Button style={{ width: 150, fontSize: 12 }}>{t("createNewPost")}</Button>
					</Link>

					<Link to="/posts/unapproved" style={{ textDecoration: "none" }}>
						<Button
							secondary
							style={{
								width: currentTeamRole === "writer" ? 150 : 100,
								fontSize: 12,
								marginLeft: 12,
							}}
						>
							{currentTeamRole === "writer" ? t("forApproval") : t("toApprove")}
						</Button>
					</Link>

					{currentTeamRole !== "writer" && (
						<Link to="/posts/archive" style={{ textDecoration: "none" }}>
							<Button secondary style={{ width: 100, marginLeft: 12, fontSize: 12 }}>
								{t("archive")}
							</Button>
						</Link>
					)}

					<Link to="/posts/drafts" style={{ textDecoration: "none" }}>
						<Button style={{ width: 110, marginLeft: 12 }} secondary>
							{t("editDrafts")}
						</Button>
					</Link>

					{listPostsLoading && posts.length > 0 && (
						<div style={{ marginLeft: 24 }}>
							<CircularProgress size={20} />
						</div>
					)}
				</div>
				<div className={classes.rightSection}>
					<img
						src={selectedView === "table" ? TableIconSelected : TableIcon}
						className={classes.icon}
						onClick={() => setSelectedView("table")}
						alt="table"
					/>
					<img
						src={selectedView === "list" ? ListIconSelected : ListIcon}
						className={classes.icon}
						onClick={() => setSelectedView("list")}
						alt="list"
					/>
					<div style={{ marginLeft: 14 }}>
						<PostsFilterMenu
							appliedFilters={appliedFilters}
							setAppliedFilters={setAppliedFilters}
						/>
					</div>
					<div style={{ marginLeft: 14 }}>
						<DurationInput
							{...dateFilters}
							onChange={handleDurationInputChange}
							height={36}
							width={220}
						/>
					</div>
					<div style={{ marginLeft: 14 }}>
						<Button
							onClick={() => setSelectedView("today")}
							secondary={true}
							disabled={selectedView === "today"}
							style={{ width: 110, fontSize: 12 }}
						>
							Today
						</Button>
					</div>
				</div>
			</div>
			{appliedFilters.length > 0 && (
				<div className={classes.filterTabsContainer}>
					{appliedFilters.map((filter) => (
						<PostFilterTab key={filter.value} onDeleteClick={handleFilterDelete} filter={filter} />
					))}
				</div>
			)}
		</>
	);
};

export default Header;

import { MouseEvent } from "react";
import useStyles from "./styles";
import CalendarEvent from "types/CalendarEvent";
import { smallNetworkIcons } from "types/Network";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { useEffect } from "react";
import { listCalendarEvents } from "redux/actions/calendar-events/get";
import { getHoursList } from "helpers/dateAndTime";
import { CircularProgress, Fade } from "@material-ui/core";
import moment from "moment";

const Event = ({
	event,
	onClick,
}: {
	event?: CalendarEvent;
	onClick?: (e: MouseEvent<HTMLElement>) => void;
}) => {
	const classes = useStyles();

	return (
		<div
			style={{ backgroundColor: `${event?.color}33` }}
			className={classes.itemContainer}
			onClick={onClick}
		>
			<div
				className={classes.itemColor}
				style={{ backgroundColor: event?.color || "#868C91" }}
			></div>
			<div className={classes.itemText}>
				<span className={classes.itemTitle}>{event?.title}</span>
			</div>
			<div className={classes.socialMediaContainer}>
				{event?.networks.map((network, index) => (
					<img src={smallNetworkIcons[network]} key={index} className={classes.socialMediaIcon} />
				))}
			</div>
		</div>
	);
};

interface Props {
	eventDialog: boolean;
	height?: number;
	setEventDialog: (dialog: boolean) => void;
	setSelectedEvent?: (event: CalendarEvent) => void;
}

export default function DailyCalendar(props: Props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const {
		createEventSuccess,
		calendarLoading,
		dayCalendarEvents: events,
	} = useSelector((state: StoreState) => state.calendarEvents);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { height, setSelectedEvent, setEventDialog } = props;

	const filterEvents = (hour: string) => {
		const filteredEvents = events?.filter((event) => {
			let eventHour = moment(event.scheduledAt!).format("hh:mm A").substring(0, 2);
			let pm = moment(event.scheduledAt!).format("hh:mm A").includes("PM");

			if (hour.startsWith(eventHour) && (pm ? hour.includes("PM") : hour.includes("AM"))) {
				return true;
			}
		});

		return filteredEvents;
	};

	const handleEventClick = (event: CalendarEvent) => {
		setSelectedEvent && setSelectedEvent(event as CalendarEvent);
		setEventDialog(true);
	};

	useEffect(() => {
		if (!createEventSuccess && currentTeamId) {
			dispatch(listCalendarEvents());
		}
	}, [createEventSuccess, currentTeamId]);

	return (
		<>
			<Fade unmountOnExit timeout={{ enter: 300, exit: 0 }} in={calendarLoading}>
				<div className={classes.loadingContainer}>
					<CircularProgress size={25} />
				</div>
			</Fade>

			{/* TODO: Add timeline separator */}
			<Fade unmountOnExit timeout={{ enter: 300, exit: 0 }} in={!calendarLoading}>
				<div className={classes.container} style={{ height: height || 400 }}>
					{getHoursList().map((hour, index) => {
						let dayTime = hour.split(" ")[1];
						const filteredEvents = filterEvents(hour);
						return (
							<div key={index} className={classes.periodContainer}>
								<div className={classes.timeContainer}>
									<span className={classes.timeText}>
										{hour.split(" ")[0] + " "}
										<span style={{ fontSize: 9 }}>{dayTime}</span>
									</span>
								</div>
								<div className={classes.eventsContainer}>
									{filteredEvents?.map((event, index) => (
										<Event
											key={index}
											event={event as CalendarEvent}
											onClick={() => handleEventClick(event)}
										/>
									))}

									{filteredEvents?.length === 0 && (
										<div style={{ pointerEvents: "none" }}>
											<Event />
										</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</Fade>
		</>
	);
}

import { makeStyles, MenuItem, Popover } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	option: {
		borderTop: "1px solid #D5E1F5",
		color: theme.palette.text.primary,
		fontSize: 12,
		padding: 12,
		paddingTop: 8,
		paddingBottom: 8,
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	menuRoot: {
		borderRadius: 5,
	},
	menuPaper: {
		border: `1px solid ${theme.palette.secondary.main}`,
		boxShadow: "none",
		borderRadius: 5,
	},
}));

interface Props {
	anchorEl: HTMLElement | null;
	handleClose: () => void;
	handleOptionClick: (option: "comparePosts" | "compareProfiles") => void;
}

const CompareMenu = (props: Props) => {
	const classes = useStyles();
	const { anchorEl, handleClose, handleOptionClick } = props;

	return (
		<Popover
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleClose}
			classes={{
				root: classes.menuRoot,
				paper: classes.menuPaper,
			}}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
		>
			<div className={classes.menuRoot} style={{ background: "white" }}>
				<MenuItem
					onClick={() => {
						handleClose();
						handleOptionClick("comparePosts");
					}}
					className={classes.option}
				>
					Compare Posts
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						handleOptionClick("compareProfiles");
					}}
					className={classes.option}
				>
					Compare Social Profiles
				</MenuItem>
			</div>
		</Popover>
	);
};

export default CompareMenu;

import moment from "moment";
import UserData from "types/User/UserData";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

var shortMonths = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

function MonthAsString(monthIndex: number) {
	var d = new Date();
	var month = new Array();
	month[0] = "Jan";
	month[1] = "Feb";
	month[2] = "Mar";
	month[3] = "Apr";
	month[4] = "May";
	month[5] = "Jun";
	month[6] = "Jul";
	month[7] = "Aug";
	month[8] = "Sep";
	month[9] = "Oct";
	month[10] = "Nov";
	month[11] = "Dec";

	return month[monthIndex];
}

function DayAsString(dayIndex: number) {
	var weekdays = new Array(7);
	weekdays[0] = "SUN";
	weekdays[1] = "MON";
	weekdays[2] = "TUE";
	weekdays[3] = "WED";
	weekdays[4] = "THU";
	weekdays[5] = "FRI";
	weekdays[6] = "SAT";

	return weekdays[dayIndex];
}

export const getCurrentDate = (shorthand?: boolean) => {
	const dateObj = new Date();

	let month = dateObj.getMonth();
	let day = dateObj.getDate();
	let year = dateObj.getFullYear();

	return `${shorthand ? shortMonths[month] : months[month]} ${day}, ${year}`;
};

export const formatTime = (date: string, userData: UserData) => {
	return moment(date).format(
		`hh:mm ${userData?.settings?.timeFormat === "24" ? "" : "A"}`
	);
};

export const formatDate = (date: string | number) => {
	const dateObj = new Date(date);

	let month = dateObj.getMonth();
	let day = dateObj.getDate();
	let year = dateObj.getFullYear();

	let result = `${day} ${shortMonths[month]}'${year.toString().slice(-2)}, ${moment(date).format(
		"hh:mm A"
	)}`;

	return result;
};

export const getDates = (startDate: Date, daysToAdd: number, fullDate?: boolean) => {
	var aryDates = [];
	var fullDates: string[] = [];

	for (var i = 0; i <= daysToAdd; i++) {
		var currentDate = new Date();
		currentDate.setDate(startDate.getDate() + i);
		fullDates.push(currentDate.toISOString());
		aryDates.push(
			DayAsString(currentDate.getDay()) +
				", " +
				currentDate.getDate() +
				" " +
				MonthAsString(currentDate.getMonth())
		);
	}
	if (fullDate) {
		return fullDates;
	} else return aryDates;
};

export const getHoursList = () => {
	let hours: string[] = [];
	for (let i = 1; i <= 24; i++) {
		let dayTime = "AM";
		if (hours.length > 10) dayTime = "PM";
		if (i > 12)
			hours.push(`${(i - 12).toString().length === 1 ? `0${i - 12}` : i - 12}:00 ${dayTime}`);
		else hours.push(`${i.toString().length === 1 ? `0${i}` : i}:00 ${dayTime}`);
	}

	return hours;
};

// Add a number of days to specific date
export const addDays = (date: Date, days: number) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};

// Add a number of days to specific date
export const addMonths = (date: Date, months: number) => {
	var result = new Date(date);
	result.setMonth(result.getMonth() + months);
	return result;
};

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		padding: 6,
		overflow: "hidden",
		marginBottom: 8,
		cursor: "pointer",
		transition: "0.2s",
		"&:hover": {
			background: "#f0f0f0",
		},
	},

	imageContainer: {
		marginRight: 24,
		width: 50,
		position: "relative",
	},

	selectionIndexCircle: {
		position: "absolute",
		backgroundColor: "white",
		borderRadius: "50%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: 20,
		height: 20,
		bottom: 5,
		right: -6,
	},

	selectionIndexInnerCircle: {
		background: theme.palette.primary.main,
		color: "white",
		fontFamily: "Avenir",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50%",
		width: 18,
		height: 18,
	},

	postContent: {
		color: theme.palette.text.primary,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "40%",
		overflow: "hidden",
		paddingRight: 16,
		fontWeight: 300,
		fontSize: 12, 
	},

	socialDetails: {
		width: "25%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	socialIcon: {
		marginRight: 16,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 23,
	},

	profilePicture: {
		height: 25,
		marginRight: 8,
	},

	username: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
	},

	dateText: {
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "fit-content",
		marginLeft: "auto",
		fontSize: 12,
		paddingRight: 4,
	},
}));

export default useStyles;

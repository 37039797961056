import { Box, CircularProgress } from "@material-ui/core";
import Button from "components/Basic/Button";
import BillingMethodItem from "modules/Profile/Billing/BillingMethodItem";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { listPaymentMethods } from "redux/actions/team/subscriptions/billing";
import { PaymentMethod } from "types/Billing";
import useSubscriptionDialogStyles from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "setup/store";
import { setBillingMethodDialog } from "redux/actions/modals";

interface Props {
	selectedPaymentMethodId?: string;
	onPaymentMethodSelect: (method: PaymentMethod) => void;
}

const BillingMethods = (props: Props) => {
	const classes = useSubscriptionDialogStyles();
	const { t } = useTranslation("settings");
	const { selectedPaymentMethodId, onPaymentMethodSelect } = props;
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { paymentMethods, paymentMethodsLoading } = useSelector(
		(state: StoreState) => state.subscription
	);
	/** Team ID that the current methods list is for */
	const listedTeamId = useRef<string>("");
	const dispatch = useDispatch();

	
	useEffect(() => {
		if (paymentMethods.length === 0 || (currentTeamId && listedTeamId.current !== currentTeamId)) {
			listedTeamId.current = currentTeamId;
			dispatch(listPaymentMethods());
		}
	}, [currentTeamId]);

	if (!currentTeamId) {
		return <></>;
	}

	return (
		<Box>
			<div className={classes.sectionTitle}>{t("selectBillingMethod")}</div>

			{paymentMethodsLoading && (
				<Box style={{ padding: 32, width: "fit-content", margin: "0px auto 0px auto" }}>
					<CircularProgress size={20} />
				</Box>
			)}

			{!paymentMethodsLoading &&
				paymentMethods.map((method, index) => (
					<BillingMethodItem
						hideTitle
						selected={selectedPaymentMethodId === method.id}
						inputContainerStyle={{ padding: 0 }}
						style={{ paddingLeft: 32, paddingRight: 32, width: "100%" }}
						onSelect={onPaymentMethodSelect}
						method={method}
						key={index}
					/>
				))}

			{!paymentMethodsLoading && (
				<>
					{paymentMethods.length === 0 && (
						<Box width="fit-content" margin="auto" padding={3}>
							{t("noBillingMethods")}
						</Box>
					)}
					<Box style={{ marginTop: 24, marginLeft: 24 }}>
						<Button
							style={{ width: 150 }}
							secondary
							onClick={() => dispatch(setBillingMethodDialog(true))}
						>
							+ {t("addBillingMethod")}
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
};

export default BillingMethods;

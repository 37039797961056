import { Box } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTaskDialog } from "redux/actions/modals";
import Post from "types/Post";
import Task from "types/Task";
import TaskAssignees from "../../Tasks/TaskCardAssigneesPictures";
import useUnapprovedPostStyles from "./styles";

interface Props {
	post: Post;
}

const PostTask = (props: Props) => {
	const [postTask, setPostTask] = useState<Task>();
	const { t } = useTranslation("posts");
	const classes = useUnapprovedPostStyles();
	const dispatch = useDispatch();
	const { post } = props;

	const handleTitleClick = () => {
		if (!post.task) return;

		dispatch(
			setTaskDialog(true, post.task, (updatedTask) => {
				setPostTask(updatedTask);
			})
		);
	};

	useEffect(() => {
		setPostTask(post.task);
	}, [post]);

	if (!postTask) return <></>;

	return (
		<Box style={{ paddingLeft: 62, width: "25%" }}>
			<Box className={classes.sectionLabel}>{t("socials")}:</Box>
			<Box onClick={handleTitleClick} className={classes.taskTitle}>
				{postTask.title}
			</Box>
			{postTask.deadline && (
				<Box className={classes.taskDate}>
					{t("deadline")}: {moment(postTask.deadline).format("DD MMM hh:mm A")}
				</Box>
			)}

			<Box style={{ marginTop: 4 }}>
				<TaskAssignees task={postTask} />
			</Box>
		</Box>
	);
};

export default PostTask;

import React from "react";

export default function ReconnectIcon({ size }: { size?: number }) {
	return (
		<svg
			width={size || "24"}
			height={size || "24"}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9999 1.88954C6.41571 1.88954 1.88881 6.41644 1.88881 12.0007H0.333252C0.333252 5.55733 5.5566 0.333984 11.9999 0.333984C15.3806 0.333984 18.4256 1.77264 20.5555 4.0688V0.333984H22.111V6.55621L15.8888 6.55621V5.00065L19.2962 5.00065C17.4553 3.0822 14.867 1.88954 11.9999 1.88954ZM11.9999 22.1118C17.5841 22.1118 22.111 17.5849 22.111 12.0007H23.6666C23.6666 18.444 18.4432 23.6673 11.9999 23.6673C8.61924 23.6673 5.5742 22.2287 3.44436 19.9325V23.6673H1.88881V17.4451H8.11103V19.0007H4.70366C6.54458 20.9191 9.13279 22.1118 11.9999 22.1118Z"
				fill="white"
			/>
		</svg>
	);
}

import { useState, ChangeEvent, useEffect } from "react";
import useStyles from "../styles";
import FormHeader from "../FormHeader";
import Button from "components/Basic/Button";
import SignUpStepper from "./Stepper";
import { useHistory } from "react-router";
import ProfilePhotoEditor from "components/Media/PhotoCropper";
import { ProfilePicturePlaceholder } from "assets";
import { useDispatch, useSelector } from "react-redux";
import { uploadProfilePicture } from "redux/actions/user/user-data";
import { CircularProgress } from "@material-ui/core";
import { StoreState } from "../../../setup/store";
import { checkAuth } from "redux/actions/auth/checkAuth";

const ProfilePhotoStep = () => {
	const classes = useStyles();
	const { userData } = useSelector((state: StoreState) => state.user);
	const [selectedFile, setSelectedFile] = useState<File>();
	const [editorDialog, setEditorDialog] = useState<boolean>(false);
	const [imageUrl, setImageUrl] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const handleSubmitClick = () => {
		console.log(selectedFile);
		if (!selectedFile) return;
		setLoading(true);
		dispatch(
			uploadProfilePicture(selectedFile, () => {
				setLoading(false);
				history.push("/signup/welcome");
			})
		);
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;
		if (!files || !files[0]) return;

		setSelectedFile(files[0]);
		setEditorDialog(true);
	};

	useEffect(() => {
		dispatch(
			checkAuth((user, userData) => {
				if (!userData?.emailConfirmed && userData?.email) {
					history.push("signup/verification");
				} else if (userData && !userData?.email) {
					history.push("/signup/general-info");
				}
			})
		);
	}, []);

	useEffect(() => {
		if (userData && userData.profilePicture) {
			history.push("/");
		}
	}, [userData]);

	return (
		<>
			<ProfilePhotoEditor
				file={selectedFile as File}
				setSelectedFile={setSelectedFile}
				open={editorDialog}
				handleClose={() => setEditorDialog(false)}
				setImagePreview={setImageUrl}
			/>
			<div className={classes.finalStepContainer}>
				<div className={classes.innerContainer}>
					<FormHeader includeText={false} />
					<div className={classes.emailConfirmation} style={{ paddingTop: 32 }}>
						{imageUrl ? "Nice to Meet You!" : "Let's Get Acquainted!"}
					</div>
					{!imageUrl && (
						<div className={classes.addProfilePhoto}>Please add your profile photo</div>
					)}
					<div className={classes.profileImageContainer}>
						<img
							src={imageUrl ? imageUrl : ProfilePicturePlaceholder}
							className={classes.profileImage}
						/>
					</div>
					<div className={classes.buttonsContainer}>
						<Button
							disabled={loading}
							onClick={() =>
								!imageUrl
									? history.push("/signup/welcome")
									: document.getElementById("input-label")?.click()
							}
							secondary={true}
							style={{ marginRight: 8, width: 150 }}
						>
							{imageUrl ? "Change Photo" : "Skip This"}
						</Button>
						<input
							accept="image/*"
							id="upload-input"
							type="file"
							style={{ display: "none" }}
							onChange={handleFileChange}
							name="file"
						/>
						<label htmlFor="upload-input" id="input-label">
							<Button
								disabled={loading}
								onClick={() =>
									imageUrl ? handleSubmitClick() : document.getElementById("input-label")?.click()
								}
								style={{ width: 210 }}
							>
								{loading ? (
									<CircularProgress size={20} />
								) : imageUrl ? (
									"Continue"
								) : (
									"Upload Your Photo"
								)}
							</Button>
						</label>
					</div>
					<div style={{ marginBottom: 120, marginTop: 42 }}>
						<SignUpStepper selectedStep={imageUrl ? 5 : 4} static={true} />
					</div>
				</div>
			</div>
		</>
	);
};

export default ProfilePhotoStep;

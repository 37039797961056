import { getImage } from "helpers/utils";
import { getPostAttachmentUrl } from "redux/actions/posts/get";
import Post, { PostPayload } from "types/Post";

export const getPostImage = async (post: PostPayload | Post | any) => {
	if (!post) return "";
	const { media } = post;
	let image: string = "";

	if (media?.[0]) {
		if (typeof media[0].value === "string") {
			// A post that's added by syncing social posts can be have its media as external URL's
			if (media[0].value.startsWith("https")) {
				image = media[0].value;
			} else if (media[0].type === "image") {
				image = getImage(media[0].value);
			} else if (media[0].type === "video" && media[0].thumbnail) {
				image = getImage(media[0].thumbnail);
			}
		} else if (media[0].value) {
			console.log(media[0]);
			image = await getPostAttachmentUrl(media[0].value);
		}
	}

	return image;
};

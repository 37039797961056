import { makeStyles } from "@material-ui/core";

const useProfileDetailsStyles = makeStyles((theme) => ({
	viewTitle: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		fontSize: 12,
	},

	headerText: {
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},

	card: {
		backgroundColor: "white",
		padding: 32,
		borderRadius: 12,
		width: "65%",
		minHeight: 300,
		position: "relative",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	profilePicture: {
		height: 110,
		width: 110,
		objectFit: "cover",
		borderRadius: "50%",
		marginRight: 16,
	},

	username: {
		color: theme.palette.text.primary,
		fontWeight: 600,
		fontSize: 16,
	},

	profileType: {
		color: "#868C91",
		letterSpacing: 0.8,
		textTransform: "uppercase",
		fontSize: 14,
		fontWeight: 500,
		marginTop: 8,
		marginBottom: 8,
	},

	active: {
		color: "#27AE60",
		marginLeft: 8,
		fontWeight: 600,
		fontSize: 14,
	},

	inactive :{
		color: theme.palette.error.main,
		marginLeft: 8,
		fontWeight: 600,
		fontSize: 14,
	},

	title: {
		fontWeight: 700,
		color: "#5B666E",
		marginBottom: 16,
		fontSize: 16,
	},

	permissions: {
		minWidth: 200,
		maxHeight: 235,
		overflowY: "scroll",
		marginBottom: 62
	},

	permissionText: {
		marginLeft: 8,
		fontSize: 12,
		fontWeight: 500,
	},

	centeredContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
	},

	pageIllustrationContainer: {
		marginTop: -80,
		width: "32%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	},

	pageIllustartion: {
		height: 240,
		width: 240,
	},

	dataCards: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
		flexWrap: "wrap",
		width: "fit-content",
	},

	dataCard: {
		display: "flex",
		flexDirection: "row",
		margin: 8,
		width: 180,
		height: 70,
		padding: 16,
		paddingLeft: 24,
		borderRadius: 15,
		boxShadow: "0px 4px 24px rgba(217, 227, 235, 0.4)",
	},

	cardValue: {
		color: theme.palette.text.primary,
		fontSize: 16,
		fontWeight: 500,
	},

	cardTitle: {
		color: theme.palette.text.primary,
		fontSize: 11,
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		position: "absolute",
		bottom: 32,
		width: "91%",
		left: 32,
	},

	pagesHeader: {
		color: "#5B666E",
		fontWeight: 700,
		fontSize: 16,
	},

	pagesContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		flexWrap: "wrap",
		marginTop: 24,
	},

	button: {
		width: 150,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	},

	rightContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},

	addPageContainer: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		color: "#4B7FA3",
		fontWeight: 500,
		fontSize: 14,
		cursor: "pointer",
		userSelect: "none",
	},
}));

export default useProfileDetailsStyles;

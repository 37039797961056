import MetaTags from "react-meta-tags";

interface Props {
	title: string;
	description?: string;
}

const MetaTagsHeader = (props: Props) => {
	const { title, description } = props;

	return (
		<MetaTags>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta property="og:title" content={title} />
		</MetaTags>
	);
};

export default MetaTagsHeader;

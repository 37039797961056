import { useState } from "react";
import useStyles from "./styles";
import Container from "../../components/Navigation/Container";
import MainCalender from "./MainCalender";
import Notes from "./Notes/Notes";
import Campaigns from "./Campaigns/index";
import { Tab } from "../../components/Tabs";
import AltCalendar from "./AltCalendar";
import MonthOverview from "./MonthOverview";
import MetaTagsHeader from "../../components/Navigation/Components/MetaTags";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { listCampaigns } from "redux/actions/campaigns/get";
import { StoreState } from "../../setup/store";
import { useTranslation } from "react-i18next";

const CalendarPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [selectedTab, setSelectedTab] = useState<Tab>();
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { t } = useTranslation("calendar");

	useEffect(() => {
		if (currentTeamId) dispatch(listCampaigns({}));
	}, [currentTeamId]);

	return (
		<Container page={t("calendar")}>
			<MetaTagsHeader title={`${t("calendar")} | HashtagHero`} />
			<div className={classes.pageContainer}>
				{(selectedTab?.index === 2 || selectedTab?.index === 1) && (
					<MainCalender selectedTab={selectedTab as Tab} setSelectedTab={setSelectedTab} />
				)}

				{(selectedTab?.index === 0 || !selectedTab) && (
					<div className={classes.horizontalContainer}>
						<AltCalendar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
						<MonthOverview />
					</div>
				)}

				<div className={classes.horizontalContainer}>
					<Notes />
					<Campaigns />
				</div>
			</div>
		</Container>
	);
};

export default CalendarPage;

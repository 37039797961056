import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "react";
import { useHistory } from "react-router";
import { DeleteIcon } from "assets";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import SocialProfile from "types/SocialProfile";
import { getSocialProfileRoute } from "setup/router/utils";

interface Props {
	profile: SocialProfile;
	style?: CSSProperties;
	textStyle?: CSSProperties;
	networkIconSize?: number;
	onDeleteClick?: (profile: SocialProfile) => void;
}

const useStyles = makeStyles((theme) => ({
	socialProfile: {
		backgroundColor: "#F1F4F8",
		display: "flex",
		flexDirection: "row",
		paddingLeft: 16,
		paddingRight: 16,
		alignItems: "center",
		width: 190,
		height: 36,
		borderRadius: 10,
		// marginBottom: 8,
	},

	socialProfileName: {
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		fontSize: 12,
		width: 80,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},

	deleteIconContainer: {
		marginLeft: "auto",
		width: "fit-content",
		marginBottom: -2,
		cursor: "pointer",
	},
}));

const SocialProfileChip = (props: Props) => {
	const { profile, style, networkIconSize, textStyle, onDeleteClick } = props;
	const classes = useStyles();
	const history = useHistory();

	const handleClick = () => {
		history.push(getSocialProfileRoute(profile._id!));
	};

	return (
		<div style={{ ...style }} className={classes.socialProfile}>
			<NetworkIcon
				strokeWidth={3}
				size={networkIconSize || 20}
				style={{ marginRight: 6 }}
				network={profile.network}
			/>

			<SocialProfilePicture profile={profile} style={{ marginRight: 8 }} />

			<span onClick={handleClick} style={textStyle} className={classes.socialProfileName}>
				{profile.displayName}
			</span>

			{onDeleteClick && (
				<div className={classes.deleteIconContainer}>
					<img src={DeleteIcon} height={10} alt="delete" onClick={() => onDeleteClick(profile)} />
				</div>
			)}
		</div>
	);
};

export default SocialProfileChip;

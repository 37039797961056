import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import usePagesDialogStyles from "modules/Settings/styles/pages-selection-dialog";
import SocialProfile from "types/SocialProfile";
import { SubProfileType } from "../SubProfilesDialog";

interface Props {
	currentProfile: SocialProfile;
	profileTypes: SubProfileType[];
}

const DialogHeader: React.FC<Props> = (props) => {
	const classes = usePagesDialogStyles();
	const { currentProfile, profileTypes } = props;

	return (
		<>
			<div className={classes.linearContainer}>
				<NetworkIcon network={currentProfile.network} color={"#4B7FA3"} size={25} />

				<div className={classes.rightContainer}>
					<div className={classes.linearContainer}>
						<SocialProfilePicture profile={currentProfile} style={{ height: 30, width: 30, marginRight: 12 }} />

						<div className={classes.currentProfileUsername}>{currentProfile.displayName}</div>
					</div>
				</div>
			</div>

			{/* Title & Subtitle */}
			<div className={classes.title}>
				Please select {profileTypes.includes("page") ? "pages" : "Instagram accounts"} you want to
				manage with #Hashtaghero
			</div>

			<div className={classes.subtitle}>
				Pages and accounts not selected during Facebook sign in may not appear
			</div>
		</>
	);
};

export default DialogHeader;

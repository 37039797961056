import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DeleteConfirmImage, DeleteSuccessImage } from "assets";
import Button from "components/Basic/Button";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import Container from "components/Navigation/Container";
import MetaTagsHeader from "components/Navigation/Components/MetaTags";
import DraftPost from "modules/Posts/DraftPost";
import Post from "types/Post";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { LIST_POSTS } from "redux/reducers/posting";
import { StoreState } from "../../../setup/store";
import { listPosts } from "redux/actions/posts/get";
import { deletePost } from "redux/actions/posts/update";
import { CircularProgress } from "@material-ui/core";
import EmptyPageMessage from "components/EmptyPageMessage";
import { useTranslation } from "react-i18next";

const DraftsPage = () => {
	const classes = useStyles();
	const [selectedPost, setSelectedPost] = useState<Post>();
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
	const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const { posts, listPostsLoading } = useSelector((state: StoreState) => state.posts);
	const history = useHistory();
	const { t } = useTranslation("posts");
	const dispatch = useDispatch();

	const handleDelete = (post: Post) => {
		if (!deleteConfirmation) {
			setSelectedPost(post);
			setDeleteConfirmation(true);
		} else {
			if (!post._id) return;
			setDeleteConfirmation(false);
			setSelectedPost(undefined);
			setDeleteSuccess(true);

			setDeleteLoading(true);
			dispatch(
				deletePost(post?._id, (success) => {
					if (success) {
						setDeleteConfirmation(false);
						setSelectedPost(undefined);
						setDeleteSuccess(true);
						setDeleteLoading(false);
					}
				})
			);
		}
	};

	const handleEdit = (post: Post) => {
		history.push(`/posts/edit/${post._id}`);
	};

	useEffect(() => {
		if (currentTeamId) dispatch(listPosts({ draft: true }));

		return function cleanup() {
			dispatch({
				type: LIST_POSTS,
				payload: [],
			});
		};
	}, [currentTeamId]);

	return (
		<>
			<ConfirmationDialog
				open={deleteConfirmation}
				onClose={() => setDeleteConfirmation(false)}
				onAction={() => handleDelete(selectedPost as Post)}
				confirmButton={t("deleteDraft")}
				message={t("deleteDraftConfirmContent")}
				actionLoading={deleteLoading}
				image={DeleteConfirmImage}
			/>

			<ConfirmationDialog
				open={deleteSuccess}
				onClose={() => setDeleteSuccess(false)}
				message={t("draftDeleteSuccess")}
				image={DeleteSuccessImage}
				autoClose={true}
				hideButtons={true}
			/>

			<MetaTagsHeader title={t("postsDraft")} />

			<Container page={t("drafts")}>
				{!listPostsLoading && (
					<div className={classes.pageContainer}>
						<div className={classes.innerContainer}>
							<div className={classes.header}>
								<Button
									onClick={() => history.push("/posts/schedule")}
									secondary={true}
									style={{ marginRight: 16, width: 130 }}
								>
									{t("backToSchedule")}
								</Button>

								<Button
									onClick={() => history.push("/posts/new")}
									className={classes.lightBlueButton}
								>
									{t("createNewPost")}
								</Button>
							</div>

							{listPostsLoading && (
								<div className={classes.centeredContainer}>
									<CircularProgress size={20} />
								</div>
							)}

							{!listPostsLoading && posts.length === 0 && (
								<div className={classes.centeredContainer}>
									<EmptyPageMessage title={t("noPostsFound")} />
								</div>
							)}

							{posts.map((post, index) => (
								<DraftPost
									handleDeleteClick={handleDelete}
									handleEditClick={handleEdit}
									post={post}
									key={index}
								/>
							))}
						</div>
					</div>
				)}
			</Container>
		</>
	);
};

export default DraftsPage;

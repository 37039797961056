import SubscriptionPlan, { PlanAddon, SubscriptionInfo } from "types/SubscriptionPlan";
import { ReduxAction } from "types/ReduxActions";
import { PaymentMethod } from "types/Billing";

export interface SubscriptionReducerState {
	currentTeamSubscription: SubscriptionInfo | null;
	plans: SubscriptionPlan[];
	addons: PlanAddon[];
	plansLoading: boolean;
	paymentMethods: PaymentMethod[];
	paymentMethodsLoading: boolean;
}

let initialState: SubscriptionReducerState = {
	currentTeamSubscription: null,

	paymentMethods: [],
	paymentMethodsLoading: false,

	plans: [],
	addons: [],
	plansLoading: false,
};

export const SET_CURRENT_TEAM_SUBSCRIPTION = "SET_CURRENT_TEAM_SUBSCRIPTION";
export const SET_PLANS = "SET_PLANS";
export const SET_PLANS_LOADING = "SET_PLANS_LOADING";

export const SET_CURRENT_TEAM_PAYMENT_METHODS = "SET_CURRENT_TEAM_PAYMENT_METHODS";
export const SET_PAYMENT_METHODS_LOADING = "SET_PAYMENT_METHODS_LOADING";

export default function subscriptionReducer(
	state: SubscriptionReducerState = initialState,
	action: ReduxAction
) {
	switch (action.type) {
		case SET_CURRENT_TEAM_SUBSCRIPTION: {
			return {
				...state,
				currentTeamSubscription: action.payload,
			};
		}

		case SET_PLANS: {
			const { addons, plans } = action.payload;
			return {
				...state,
				plans: plans,
				addons: addons,
				plansLoading: false,
			};
		}

		case SET_PLANS_LOADING: {
			return {
				...state,
				plansLoading: action.payload,
			};
		}

		case SET_CURRENT_TEAM_PAYMENT_METHODS: {
			return { ...state, paymentMethods: action.payload, paymentMethodsLoading: false };
		}

		case SET_PAYMENT_METHODS_LOADING: {
			return { ...state, paymentMethodsLoading: action.payload };
		}

		default:
			return state;
	}
}

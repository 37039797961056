import { CircularProgress } from "@material-ui/core";
import { useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Basic/Button";
import Input from "components/Basic/Inputs/Input";
import { validateEmail } from "helpers/utils";
import { sendTeamInvitation } from "redux/actions/team/users";
import Team from "types/User/Team";
import usePageStyles from "../../../../../page-styles";
import useWorkspacesStyles from "../../../styles";
import HintText from "components/Basic/Text/HintText";
import { StoreState } from "setup/store";

interface Props {
	team: Team;
	teamUsersLimit: number;
}

const InviteUser = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const { team, teamUsersLimit } = props;
	const dispatch = useDispatch();
	const classes = useWorkspacesStyles();
	const pageClasses = usePageStyles();
	const { t } = useTranslation("workspaces");
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);

	const handleSendClick = () => {
		if (loading) {
			return;
		}

		if (!email) {
			return setError("Enter email");
		} else if (!validateEmail(email)) return setError("Invalid email");
		else if (team?.users.find((teamUser) => teamUser.user?.email === email))
			return setError("User is already a member");
		else {
			setLoading(true);
			dispatch(
				sendTeamInvitation(team._id, email, (success, err) => {
					setLoading(false);
					if (err) {
						console.log(err);
						setError(err);
					} else if (success) {
						setEmail("");
						setError("");
					}
				})
			);
		}
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleSendClick();
		}
	};

	return (
		<div style={{ marginTop: 24 }}>
			<div className={classes.label}>{t("inviteSomeone")}</div>
			<div
				className={pageClasses.linearContainer}
				style={{ alignItems: "flex-start", marginTop: 8 }}
			>
				<div style={{ width: "84%" }}>
					<Input
						value={email}
						error={error}
						placeholder={t("enterEmailAddress")}
						onKeyDown={handleKeyDown}
						onChange={(e) => setEmail(e.target.value)}
						style={{ width: "100%", marginRight: 12 }}
					/>
				</div>

				<Button
					secondary
					disabled={loading}
					onClick={handleSendClick}
					style={{ width: 200, marginLeft: 12 }}
				>
					{loading ? <CircularProgress size={20} /> : t("sendInvitation")}
				</Button>
			</div>

			<HintText style={{ width: 260, marginTop: 12 }}>
				{teamUsersLimit
					? t("invitationTeamPlan", {
							limit: teamUsersLimit,
							planTitle: currentTeamSubscription?.planInfo?.title?.toUpperCase(),
					  })
					: t("noSharingMessage")}
			</HintText>
		</div>
	);
};

export default InviteUser;

import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import Task from "types/Task";
import Button from "components/Basic/Button";
import AddProfileIconSvg from "assets/SVG/AddProfileIconSvg";
import { useTranslation } from "react-i18next";
import SocialProfile from "types/SocialProfile";
import { ProfileSelectorMenu } from "components/Popovers/ProfilesSelector/ProfilesSelector";
import SocialProfileChip from "../../../Settings/SocialProfile/SocialProfileChip";
import useTaskDialogStyles from "../../styles/task-dialog";

interface Props {
	inputValues: Partial<Task>;
	setInputValues: Dispatch<SetStateAction<Partial<Task>>>;
}

const AddSocialProfiles = (props: Props) => {
	const [profilesSelector, setProfilesSelector] = useState<HTMLElement | null>(null);
	const { inputValues, setInputValues } = props;
	const { t } = useTranslation("tasks");
	const classes = useTaskDialogStyles();

	const handleProfilesChange = (profiles: SocialProfile[]) => {
		setInputValues({
			...inputValues,
			socialProfiles: [...profiles],
		});
	};

	const handleDeleteClick = (selectedProfile: SocialProfile) => {
		setInputValues({
			...inputValues,
			socialProfiles: inputValues.socialProfiles?.filter(
				(profile) => profile._id !== selectedProfile._id
			),
		});
	};

	return (
		<Box>
			<ProfileSelectorMenu
				anchorEl={profilesSelector}
				selectedProfiles={inputValues.socialProfiles as SocialProfile[]}
				onChange={handleProfilesChange}
				postingOnly={true}
				handleClose={() => setProfilesSelector(null)}
			/>
			<Button
				secondary
				className={classes.actionButton}
				onClick={(e) => setProfilesSelector(e.currentTarget)}
				style={{ width: "100%", marginTop: 24, background: "#B6D8F0" }}
			>
				<Box display="flex" alignItems="center" justifyContent="center">
					<AddProfileIconSvg size={18} />
					<div style={{ marginLeft: 8 }}>{t("addSocialProfiles")}</div>
				</Box>
			</Button>

			<Box style={{ marginTop: 8, gap: 8 }} display="flex" flexWrap="wrap">
				{inputValues.socialProfiles?.map((profile, index) => (
					<SocialProfileChip
						style={{ maxWidth: "48.8%", margin: 0 }}
						profile={profile as SocialProfile}
						key={index}
						onDeleteClick={handleDeleteClick}
					/>
				))}
			</Box>
		</Box>
	);
};

export default AddSocialProfiles;

import Button from "components/Basic/Button";
import useStyles from "../styles";
import SearchIcon from "assets/black-search-icon.png";
import { useHistory } from "react-router";
import { useState, useCallback } from "react";
import PostsFilterMenu, { PostFilters, PostFilterType } from "components/Popovers/PostsFilter";
import PostFilterTab from "modules/Posts/FilterTab";
import { ChangeEvent } from "react";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
interface Props {
	appliedFilters: PostFilters;
	setSearchValue: (value: string) => void;
	setAppliedFilters: (appliedFilters: PostFilters) => void;
}

const Header = (props: Props) => {
	const classes = useStyles();
	const history = useHistory();
	const [searchInputValue, setSearchInputValue] = useState<string>("");
	const { t } = useTranslation("posts");
	const { appliedFilters, setAppliedFilters, setSearchValue } = props;

	const debouncedSearch = useCallback(
		debounce((searchValue) => {
			setSearchValue(searchValue);
		}, 350),
		[]
	);

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchInputValue(value);
		debouncedSearch(value);
	};

	const handleFilterDelete = (type: PostFilterType, value: string) => {
		appliedFilters.forEach((filter, index) => {
			if (filter.value === value) {
				appliedFilters.splice(index, 1);
			}
		});

		setAppliedFilters([...appliedFilters]);
	};

	return (
		<>
			<div className={classes.header}>
				<Button
					onClick={() => history.push("/posts/schedule")}
					secondary={true}
					style={{ width: 160, height: 40 }}
				>
					{t("postingSchedule")}
				</Button>

				<div className={classes.searchBar}>
					<input
						value={searchInputValue}
						onChange={handleSearchChange}
						className={classes.input}
						placeholder="Search by keyword"
					/>
					<img src={SearchIcon} className={classes.searchIcon} />
				</div>

				<PostsFilterMenu appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
			</div>

			{appliedFilters.length > 0 && (
				<div className={classes.filterTabsContainer}>
					{appliedFilters.map((filter) => (
						<PostFilterTab key={filter.value} onDeleteClick={handleFilterDelete} filter={filter} />
					))}
				</div>
			)}
		</>
	);
};

export default Header;

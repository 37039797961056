import { CSSProperties, MouseEvent, useEffect, useState } from "react";
import Post from "types/Post";
import ClockIcon from "assets/clock-icon.png";
import usePostStyles from "./shared-styles";
import Button from "components/Basic/Button";
import useStyles from "./styles/list-post";
import ClampLines from "react-clamp-lines";
import { ImagePlaceholder } from "../../assets";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { useHistory } from "react-router";
import { getPostImage } from "../SocialPreviews/utils";
import moment from "moment";
import { formatDate } from "helpers/dateAndTime";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";
import PostImage from "./PostImage";

interface Props {
	post: Post;
	onClick?: (post: Post) => void;
	archive?: boolean;
	disabled?: boolean;
	style?: CSSProperties;
	imageStyle?: CSSProperties;
}

const ListPost = (props: Props) => {
	const [image, setImage] = useState<string>("");
	const sharedPostClsases = usePostStyles();
	const { t } = useTranslation("posts");
	const history = useHistory();
	const classes = useStyles();

	const { post, archive, onClick, style, imageStyle, disabled } = props;

	const handleClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		onClick && onClick(post);
	};

	useEffect(() => {
		getPostImage(post).then((image) => {
			setImage(image);
		});
	}, [post]);

	console.log(post.content);

	return (
		<div onClick={handleClick} style={{ ...style }} className={classes.container}>
			<div className={classes.postContent}>
				<PostImage post={post} style={imageStyle} />
				<span className={classes.textContent}>
					{post.content && (
						<ClampLines
							id="post-content"
							text={post.content}
							lines={2}
							ellipsis="..."
							buttons={false}
						>
							{post.content}
						</ClampLines>
					)}
				</span>
			</div>

			<div className={classes.socialDetails}>
				<div className={classes.socialIcon}>
					{post.socialProfiles && (
						<div style={{ opacity: disabled ? 0.6 : 1, height: 20 }}>
							<NetworkIcon network={post.socialProfiles[0].network} size={20} />
						</div>
					)}
				</div>

				{post.socialProfiles?.[0] && (
					<>
						<SocialProfilePicture
							style={{
								opacity: disabled ? 0.6 : 1,
								width: 25,
								height: 25,
								marginRight: 8,
								marginLeft: 0,
							}}
							profile={post.socialProfiles[0]}
						/>
						<span className={classes.username}>{post.socialProfiles[0].displayName}</span>
					</>
				)}
			</div>

			<div className={classes.postActions}>
				{!archive && (
					<div className={sharedPostClsases.scheduleBox} style={{ marginRight: 8 }}>
						<img src={ClockIcon} className={sharedPostClsases.clockIcon} alt="clock" />
						<span className={sharedPostClsases.schedule}>
							{moment(post.scheduledAt as string).format("hh:mm A")}
						</span>
					</div>
				)}
				{!archive && (
					<Box onClick={(e) => e.stopPropagation()}>
						<Button
							onClick={() => history.push(`/posts/edit/${post._id}`)}
							disabled={disabled}
							style={{ width: 45, height: 25, fontSize: 12, borderRadius: 2 }}
						>
							{t("edit")}
						</Button>
					</Box>
				)}

				{archive && typeof post.campaign !== "string" && (
					<span className={classes.actionText}>{post.campaign?.title}</span>
				)}

				{archive && (
					<span className={classes.actionText}>
						<span>{formatDate(post.createdAt as string)}</span>
					</span>
				)}
			</div>
		</div>
	);
};

export default ListPost;

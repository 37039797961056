import { makeStyles } from "@material-ui/core";

const useTaskDialogStyles = makeStyles((theme) => ({
	paper: {
		background: "#FFFFFF",
		maxWidth: 900,
		padding: 2,
	},

	container: {
		width: 780,
		height: 650,
		padding: 32,
		paddingLeft: 42,
		paddingRight: 42,
		flexDirection: "column",
		display: "flex",
		background: "#FFFFFF",
		position: "relative",
		borderRadius: 6
	},

	title: {
		fontFamily: "Chau Philomene One",
		color: "#5B666E",
		fontSize: 18,
	},

	rightContainer: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
	},

	urgentButton: {
		width: 130,
		color: "#2B4455",
		background: "#B6D8F0",
		marginLeft: 16,
		fontWeight: 500
	},

	inputTitle: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		letterSpacing: 0.4,
	},

	inputContainer: {
		marginTop: 16,
	},

	actionButton: {
		width: "100%",
		background: "#B6D8F0 !important",
		fontWeight: 500
	},

	actions: {
		display: "flex",
		alignItems: "center",
		position: "absolute",
		bottom: 32,
		right: 42,
	},

	deadline: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontWeight: 500,
		display: "flex",
		alignItems: "center",
	},

	postsText: {
		color: "#5B666E",
		textDecoration: "underline",
		fontSize: 14,
		fontWeight: 500,
		marginTop: 8,
	},
}));

export default useTaskDialogStyles;

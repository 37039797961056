import { Box, IconButton, Typography, useTheme } from "@material-ui/core";
import BookmarkIconSvg from "assets/SVG/BookmarkIconSvg";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { abbreviateNumber } from "helpers/utils";
import moment from "moment";
import { useEffect, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateTeamHashtag } from "redux/actions/stats/hashtags";
import { Hashtag } from "redux/reducers/stats";
import useHashtagsDrawerStyles from "../styles";

interface Props {
	hashtag: Hashtag;
	extended?: boolean;
	stats?: boolean;
	isSaveButtonVisible?: boolean;
	onClick: (hashtag: Hashtag) => void;
}

const HashtagListItem: React.FC<Props> = (props) => {
	const { hashtag, stats, isSaveButtonVisible, onClick } = props;
	const [isSaved, setIsSaved] = useState(false);
	const classes = useHashtagsDrawerStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation("posts");

	const handleSaveClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();

		dispatch(updateTeamHashtag(hashtag.name, !isSaved));
		setIsSaved(!isSaved);
	};

	useEffect(() => {
		setIsSaved(Boolean(hashtag.saved));
	}, [hashtag]);

	return (
		<Box onClick={() => onClick(hashtag)} className={classes.hashtagListItem}>
			<Box>
				<Typography className={classes.hashtagName}>{hashtag.name}</Typography>

				{!stats && (
					<Box display="flex" alignItems="center">
						<NetworkIcon
							network="twitter"
							strokeWidth={3}
							style={{ marginRight: 6 }}
							color={theme.palette.text.hint}
							size={15}
						/>
						<Typography className={classes.hashtagsStats}>
							{abbreviateNumber(hashtag.usages)}
						</Typography>
					</Box>
				)}

				{stats && (
					<Box
						className={classes.hashtagsStats}
						color={theme.palette.text.hint}
						display="flex"
						alignItems="center"
					>
						<span style={{ paddingRight: 16 }}>
							{hashtag.usages || 0} Usage{hashtag.usages !== 1 ? "s" : ""}
						</span>
						<span>
							{hashtag.lastUsedAt &&
								`${t("lastUsage")}: ${moment(hashtag.lastUsedAt).format("DD MMM")}`}
						</span>
					</Box>
				)}
			</Box>

			{isSaveButtonVisible && (
				<Box width="fit-content" marginLeft="auto">
					<IconButton onClick={handleSaveClick} style={{ padding: 6 }}>
						<BookmarkIconSvg filled={isSaved} />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

export default HashtagListItem;

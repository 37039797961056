import FacebookIcon from "assets/facebook.png";
import InstagramIcon from "assets/instagram.png";
import TwitterIcon from "assets/twitter.png";
import LinkedInIcon from "assets/linkedin.png";

import FacebookSmall from "assets/facebook-sm.png";
import InstagramSmall from "assets/instagram-sm.png";
import TwitterSmall from "assets/twitter-sm.png";
import LinkedInSmall from "assets/linkedin-sm.png";

import FacebookSelectedIcon from "assets/facebook-selected.png";
import LinkedinSelectedIcon from "assets/linkedin-selected.png";
import InstagramSelectedIcon from "assets/instagram-selected.png";
import TwitterSelectedICon from "assets/twitter-selected.png";

type Network = "facebook" | "instagram" | "twitter" | "linkedin" | "google" | "apple";

export default Network;
export const networks: Network[] = ["facebook", "instagram", "linkedin"];

export let networkIcons: { [index: string]: string } = {
	facebook: FacebookIcon,
	instagram: InstagramIcon,
	twitter: TwitterIcon,
	linkedin: LinkedInIcon,
};

export let smallNetworkIcons: { [index: string]: string } = {
	facebook: FacebookSmall,
	instagram: InstagramSmall,
	twitter: TwitterSmall,
	linkedin: LinkedInSmall,
};

export let selectedNetworkIcons: { [index: string]: string } = {
	facebook: FacebookSelectedIcon,
	instagram: InstagramSelectedIcon,
	twitter: TwitterSelectedICon,
	linkedin: LinkedinSelectedIcon,
};

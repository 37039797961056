import { ResponsivePie } from "@nivo/pie";

export type PieDataEntry = {
	id: string;
	label: string;
	value: number;
};
interface PieChartProps {
	data: Array<PieDataEntry>;
}

const PieChart = ({ data }: PieChartProps) => (
	<ResponsivePie
		data={data}
		margin={{ top: 10, right: -60, bottom: 15, left: 15 }}
		innerRadius={0.75}
		colors={["#4285F4", "#F7931E", "#42DE51", "#948BFF", "#FED651"]}
		borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
		enableRadialLabels={false}
		enableSliceLabels={false}
		legends={[
			{
				anchor: "left",
				direction: "column",
				justify: false,
				translateX: -15,
				translateY: 0,
				itemsSpacing: 0,
				itemWidth: 92,
				itemHeight: 18,
				itemTextColor: "#3E4C55",
				itemDirection: "left-to-right",
				itemOpacity: 0.8,
				symbolSize: 8,
				symbolShape: "square",
			},
		]}
	/>
);

export default PieChart;

const ActiveIconSvg = ({ size, filled }: { size?: number; filled?: boolean }) => {
	if (filled) {
		return (
			<svg
				width={size || 28}
				height={size || 28}
				viewBox="0 0 36 36"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="18" cy="18" r="15" fill="#27AE60" />
				<path
					d="M11.625 18L17.0893 22.5536L24.375 13.4464M18 30.75C10.9584 30.75 5.25 25.0416 5.25 18C5.25 10.9584 10.9584 5.25 18 5.25C25.0416 5.25 30.75 10.9584 30.75 18C30.75 25.0416 25.0416 30.75 18 30.75Z"
					stroke="white"
					strokeWidth="2"
				/>
			</svg>
		);
	} else
		return (
			<svg
				width={size || 28}
				height={size || 28}
				viewBox="0 0 28 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7.625 14L13.0893 18.5536L20.375 9.44643M14 26.75C6.95837 26.75 1.25 21.0416 1.25 14C1.25 6.95837 6.95837 1.25 14 1.25C21.0416 1.25 26.75 6.95837 26.75 14C26.75 21.0416 21.0416 26.75 14 26.75Z"
					stroke="#27AE60"
					strokeWidth="2"
				/>
			</svg>
		);
};

export default ActiveIconSvg;

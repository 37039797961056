import { Box, useTheme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SnackBar from "components/Basic/Snackbar";
import { setSnackbarState } from "redux/actions/snackBar";
import { StoreState } from "../../setup/store";
import FormHeader from "./FormHeader";
import AuthIllustration from "./Illustration";
import Login from "./Login";
import SignUpStep1 from "./SignUp/Step1";
import SignUpStepper from "./SignUp/Stepper";
import useAuthStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { SET_SIGN_IN_ERROR } from "redux/reducers/user";
import { useHistory } from "react-router";

export type AuthTab = "login" | "signup";

const AuthPage = () => {
	const classes = useAuthStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation("auth");
	const { open, message, severity } = useSelector((state: StoreState) => state.snackbar);
	const history = useHistory();

	const tabStyles = (tab: AuthTab) => {
		let styles: CSSProperties = {};

		if (history.location.pathname.includes(tab)) {
			styles = {
				color: theme.palette.primary.main,
				borderBottom: `2px solid ${theme.palette.primary.main}`,
			};
		}

		return styles;
	};

	useEffect(() => {
		dispatch({
			type: SET_SIGN_IN_ERROR,
			payload: null,
		});
	}, []);

	return (
		<>
			<SnackBar
				open={open}
				severity={severity}
				message={message}
				handleClose={() => dispatch(setSnackbarState(false, severity, ""))}
			/>

			<Box className={classes.pageContainer}>
				<AuthIllustration />
				<Box className={classes.rightSection}>
					<FormHeader />

					{/* Form */}
					<Box className={classes.formContainer}>
						{/* Auth Tabs */}
						<Box className={classes.authTabsContainer}>
							<Box
								style={tabStyles("signup")}
								onClick={() => history.push("/signup")}
								className={classes.authTab}
							>
								{t("signup")}
							</Box>
							<Box
								style={tabStyles("login")}
								onClick={() => history.push("/login")}
								className={classes.authTab}
							>
								{t("login")}
							</Box>
						</Box>

						{history.location.pathname.includes("login") ? <Login /> : <SignUpStep1 />}
					</Box>
				</Box>
				{history.location.pathname.includes("signup") && <SignUpStepper selectedStep={0} />}
			</Box>
		</>
	);
};

export default AuthPage;

import { Box } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Post from "types/Post";
import useUnapprovedPostStyles from "./styles";
import LinesEllipsis from "react-lines-ellipsis";
import LinkText from "components/Basic/Text/LinkText";
import moment from "moment";

interface Props {
	post: Post;
}

const PostContent = (props: Props) => {
	const { post } = props;
	const classes = useUnapprovedPostStyles();
	const [expanded, setExpanded] = useState<boolean>(false);
	const [clamped, setClamped] = useState<boolean>(false);
	const { t } = useTranslation("posts");

	const handleTextReflow = (reflowState: { clamped: boolean; text: string }) => {
		setClamped(reflowState.clamped);
	};

	return (
		<Box style={{ maxWidth: "70%" }}>
			<Box className={classes.textContent}>
				<Box
					style={{
						fontStyle: !post.scheduledAt ? "italic" : "",
						fontFamily: !post.scheduledAt ? "Avenir" : "",
					}}
					className={classes.scheduleText}
				>
					{post.scheduledAt ? moment(post.schedule).format("DD MMM hh:mm A") : t("noDateScheduled")}
				</Box>

				<LinesEllipsis
					text={post.content}
					maxLine={expanded ? 99999 : 3}
					ellipsis="..."
					trimRight
					basedOn="letters"
					onReflow={handleTextReflow}
				/>

				{(clamped || expanded) && (
					<LinkText onClick={() => setExpanded(!expanded)}>
						{expanded ? t("seeLess") : t("seeMore")}
					</LinkText>
				)}
			</Box>
		</Box>
	);
};

export default PostContent;

import { Box, Menu } from "@material-ui/core";
import useStyles from "./styles";
import PopupState, { bindToggle, bindMenu } from "material-ui-popup-state";
import React, { useEffect } from "react";
import FilterButton from "../../Basic/FilterButton";
import { networks } from "types/Network";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../setup/store";
import { listSocialProfiles } from "redux/actions/socialProfiles/socialProfiles";
import { listCampaigns } from "redux/actions/campaigns/get";
import ProfileFilterItem from "./components/ProfileItem";
import NetworkFilterItem from "./components/NetworkItem";
import CampaignItem from "./components/CampaignItem";
import SubMenu from "./components/SubMenu";
import { useTranslation } from "react-i18next";

export const ParentPopupState = React.createContext(null);

export type PostFilterType = "network" | "socialProfile" | "campaign";
export type PostFilters = { value: string; type: PostFilterType }[];

interface Props {
	setAppliedFilters: (appliedFilters: PostFilters) => void;
	appliedFilters: PostFilters;
}

const PostsFilterMenu = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation("posts");
	const dispatch = useDispatch();
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);
	const { campaigns } = useSelector((state: StoreState) => state.campaigns);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { appliedFilters, setAppliedFilters } = props;

	const handleFilterClick = (type: PostFilterType, value: string) => {
		let valueFound = false;

		appliedFilters.forEach((filter, index) => {
			if (filter.value === value) {
				appliedFilters.splice(index, 1);
				valueFound = true;
			}
		});

		if (!valueFound) {
			appliedFilters.push({
				type,
				value,
			});
		}

		setAppliedFilters([...appliedFilters]);
	};

	useEffect(() => {
		if (campaigns.length === 0 && currentTeamId) {
			dispatch(listSocialProfiles());
			dispatch(listCampaigns({}));
		}
	}, [currentTeamId]);

	return (
		<PopupState variant="popover" popupId="demoMenu">
			{(popupState) => (
				<div>
					<FilterButton {...bindToggle(popupState)} />
					<ParentPopupState.Provider value={popupState as any}>
						<Menu
							classes={{ paper: classes.menuPaper }}
							{...bindMenu(popupState)}
							anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
							transformOrigin={{ vertical: "top", horizontal: "left" }}
							getContentAnchorEl={null}
						>
							{/* Network Filters */}
							<SubMenu popupId="networks" title={t("socialNetworks")}>
								{networks.map((network, index) => (
									<NetworkFilterItem
										appliedFilters={appliedFilters}
										onFilterClick={handleFilterClick}
										network={network}
										key={index}
									/>
								))}
							</SubMenu>

							{/* Social Profiles Filters */}
							<SubMenu popupId="socialProfiles" title={t("socialProfiles")}>
								{profiles.map((profile, index) => (
									<ProfileFilterItem
										appliedFilters={appliedFilters}
										onFilterClick={handleFilterClick}
										profile={profile}
										key={index}
									/>
								))}

								{profiles.length === 0 && (
									<Box className={classes.emptyMessage}>{t("noSocialProfiles")}</Box>
								)}
							</SubMenu>

							<SubMenu popupId="campiangs" title={t("campaigns")}>
								{campaigns.map((campaign, index) => (
									<CampaignItem
										key={index}
										campaign={campaign}
										onFilterClick={handleFilterClick}
										appliedFilters={appliedFilters}
									/>
								))}

								{campaigns.length === 0 && (
									<Box className={classes.emptyMessage}>{t("noCampaigns")}</Box>
								)}
							</SubMenu>
						</Menu>
					</ParentPopupState.Provider>
				</div>
			)}
		</PopupState>
	);
};

export default PostsFilterMenu;

import { LineSvgProps, ResponsiveLine } from "@nivo/line";
import { useMemo } from "react";

export type LineDataEntry = {
	id: string;
	data: Array<{ x: string | Date; y: string | number }>;
	color?: string;
};

export type GraphRange = {
	startDate: string;
	endDate: string;
};

interface Props {
	data: Array<LineDataEntry>;
	minX?: string;
	maxX?: string;
	colors?: Array<string>;
	disableLegend?: boolean;
	disableGrid?: boolean;
}

const legendBaseProps = {
	anchor: "top-left",
	translateY: -60,
	translateX: -25,
	direction: "row",
	itemHeight: 26,
	itemsSpacing: 60,
	itemOpacity: 0.8,
	symbolSize: 15,
	symbolShape: "circle",
};

const LineChart = (props: Props) => {
	const legends = useMemo(() => {
		if (props.disableLegend) {
			return [];
		}

		const data = props.data.map((item) => ({
			id: item.id,
			label: item.id,
			color: item.color || "",
		}));

		return [
			{
				...legendBaseProps,
				itemWidth: 500 / data.length,
				symbolSize: 15,
				itemsSpacing: 0,
				translateY: -50,
				data,
			},
		] as LineSvgProps["legends"];
	}, [props.data, props.disableLegend]);

	return (
		<ResponsiveLine
			data={props.data}
			margin={{ top: 80, right: 50, bottom: 50, left: 50 }}
			xScale={{ type: "point" }}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				stacked: false,
				reverse: false,
			}}
			curve="monotoneX"
			axisTop={null}
			axisRight={null}
			colors={props.colors}
			theme={{ axis: { ticks: { text: { fill: "#3E4C55", opacity: 0.6 } } } }}
			axisBottom={{
				orient: "bottom",
				tickSize: 0,
				tickPadding: 18,
				tickRotation: 0,
				legendPosition: "middle",
				format: (e) => {
					if (e === props.minX || e === props.maxX) {
						return String(e);
					} else {
						return "";
					}
				},
				// TODO: Remove placeholders
				// tickValues: [props.minX || "27 Oct", props.maxX || "2 Nov"],
			}}
			axisLeft={{
				orient: "left",
				tickSize: 0,
				tickPadding: 5,
				tickRotation: 0,
				legendOffset: -40,
				legendPosition: "middle",
				tickValues: 4,
				format: (e) => (Math.floor(parseInt(String(e))) === e ? e : ""),
			}}
			gridYValues={props.disableGrid ? 0 : 4}
			enableGridX={false}
			enablePoints={false}
			pointSize={10}
			pointColor={{ theme: "background" }}
			pointBorderWidth={2}
			pointBorderColor={{ from: "serieColor" }}
			pointLabelYOffset={-12}
			isInteractive={false}
			useMesh={true}
			legends={legends}
		/>
	);
};

export default LineChart;

const WriterIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || 30}
			height={size || 30}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.9226 2.74408C18.5972 2.41864 18.0695 2.41864 17.7441 2.74408L13.3334 7.1548L22.8452 16.6666L27.2559 12.2559C27.5814 11.9305 27.5814 11.4028 27.2559 11.0774L18.9226 2.74408Z"
				fill={color || "#4B7FA3"}
			/>
			<path
				d="M2.5 17.9882L12.1548 8.33331L21.6667 17.8452L12.0118 27.5H3.33333C2.8731 27.5 2.5 27.1269 2.5 26.6667V17.9882Z"
				fill={color || "#4B7FA3"}
			/>
			<path d="M15.8333 27.5H27.5V25.8333H15.8333V27.5Z" fill={color || "#4B7FA3"} />
		</svg>
	);
};

export default WriterIconSvg;

import { Box, makeStyles, MenuItem, Popover } from "@material-ui/core";
import { CSSProperties, useState, MouseEvent, ReactNode } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

interface Props {
	anchorEl?: HTMLElement | null;
	title?: string;
	label?: string;
	value?: string;
	options?: { value: string; title: string }[];
	dropdownComponent?: ReactNode;
	buttonComponent?: ReactNode;
	style?: CSSProperties;
	handleClose?: () => void;
	onClick?: (e: MouseEvent<HTMLElement>) => void;
	onChange?: (value: any) => void;
}

export const useMenuStyles = makeStyles((theme) => ({
	container: {
		border:  `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 5,
		fontFamily: "Avenir",
		width: 220,
		height: 35,
		cursor: "pointer",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: 16,
		fontSize: 12,
	},

	menuPaper: {
		border:  `1px solid ${theme.palette.secondary.main}`,
		boxShadow: "none",
		borderRadius: 5,
	},

	iconContainer: {
		marginLeft: "auto",
		marginTop: 2,
		marginRight: -10,
	},

	menuRoot: {
		borderRadius: 5,
	},

	title: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
}));

const Dropdown = (props: Props) => {
	const classes = useMenuStyles(props);
	const [optionsMenu, setOptionsMenu] = useState<HTMLElement | null>(null);
	const {
		style,
		title,
		value,
		label,
		anchorEl,
		handleClose,
		options,
		dropdownComponent,
		buttonComponent,
		onChange,
		onClick,
	} = props;

	const handleContainerClick = (e: MouseEvent<HTMLElement>) => {
		if (onClick) {
			onClick(e);
		} else {
			setOptionsMenu(e.currentTarget);
		}
	};

	const handleOptionClick = (value: any) => {
		onChange && onChange(value);
		setOptionsMenu(null);
	};

	return (
		<>
			<Popover
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				transformOrigin={{ vertical: "top", horizontal: "center" }}
				open={Boolean(optionsMenu || anchorEl)}
				anchorEl={anchorEl || optionsMenu}
				classes={{
					root: classes.menuRoot,
					paper: classes.menuPaper,
				}}
				onClose={() => {
					handleClose && handleClose();
					setOptionsMenu(null);
				}}
			>
				<div className={classes.menuRoot} style={{ background: "white" }}>
					{options &&
						options?.map((option, index) => (
							<MenuItem
								disabled={value === option.value}
								key={index}
								onClick={() => handleOptionClick(option.value)}
							>
								<Box style={{ fontSize: 12 }}>{option.title}</Box>
							</MenuItem>
						))}

					{dropdownComponent && dropdownComponent}
				</div>
			</Popover>

			{buttonComponent && <div onClick={handleContainerClick}>{buttonComponent}</div>}

			{!buttonComponent && (
				<>
					{label && (
						<div style={{ fontSize: 12, fontWeight: 500, marginBottom: 6 }}>{label}</div>
					)}
					<div style={{ ...style }} onClick={handleContainerClick} className={classes.container}>
						<span className={classes.title}>
							{title || options?.find((option) => option.value === value)?.title || value}
						</span>
						<div className={classes.iconContainer}>
							<ArrowDropDownIcon style={{ alignSelf: "flex-end", color: "#868C91" }} />
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Dropdown;

import { Dispatch, MouseEvent, useEffect, useState, SetStateAction } from "react";
import usePostCreatorStyles from "../styles/post-creator-styles";
import ScheduleSelector from "components/Dialogs/DateTimePicker";
import HashtagsStatsDialog from "components/Dialogs/HashtagsStats";
import moment from "moment";
import { Box, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../setup/store";
import { ContentType } from "types/Content";
import { PostPayload } from "types/Post";
import { listCampaigns } from "redux/actions/campaigns/get";
import PostSocialProfiles from "./PostSocialProfiles";
import PostCreatorErrors from "./Errors";
import PostContent from "./PostContent";
import { FileUploadProgress } from "redux/actions/media/upload";
import { useTranslation } from "react-i18next";
import PostCampaign from "./PostCampaign";
import PostActions from "./PostActions";
import PostTask from "./PostTask";
import CalendarIconSvg from "assets/SVG/CalendarIconSvg";
import Button from "components/Basic/Button";

export interface Props {
	selectedContentType: ContentType;
	postPayload: PostPayload | undefined;
	postContent: string;
	filesUploadProgress: FileUploadProgress[];
	isHashtagsDrawerOpen: boolean;
	setIsHashtagsDrawerOpen: Dispatch<SetStateAction<boolean>>;
	setPostContent: Dispatch<SetStateAction<string>>;
	setPostPayload: Dispatch<SetStateAction<PostPayload>>;
	handleSubmit: (publishNow?: boolean, draft?: boolean) => void;
}

const PostCreator = (props: Props) => {
	const [scheduleSelector, setScheduleSelector] = useState<HTMLElement | null>(null);
	const [hashtagStatsDialog, setHashtagStatsDialog] = useState<boolean>(false);
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

	/** Array of durations in seconds for each video in the postPayload.media array.
	 * On submission, the values in this array are appended to the media array and stored in the database
	 * along with the post
	 */
	const [errors, setErrors] = useState<string[]>([]);
	const { campaigns } = useSelector((state: StoreState) => state.campaigns);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { t } = useTranslation("posts");
	const classes = usePostCreatorStyles();
	const dispatch = useDispatch();
	const {
		handleSubmit,
		setPostPayload,
		setPostContent,
		setIsHashtagsDrawerOpen,
		filesUploadProgress,
		isHashtagsDrawerOpen,
		postPayload,
		postContent,
	} = props;

	const handleSchedulerOpen = (e: MouseEvent<HTMLElement>) => {
		setScheduleSelector(e.currentTarget);
	};

	useEffect(() => {
		if (
			postContent.length < 3 ||
			!postPayload?.socialProfiles ||
			postPayload?.socialProfiles?.length === 0
		) {
			return setSubmitDisabled(true);
		}

		const instagramProfiles = postPayload?.socialProfiles?.filter(
			(profile) => profile.network === "instagram"
		);

		/**
		 * Disable submit if socialProfiles include an instagram profile
		 * and there's no image added to postPayload
		 */

		if (instagramProfiles.length > 0 && !postPayload.media?.length) {
			return setSubmitDisabled(true);
		}

		setSubmitDisabled(false);
	}, [postPayload]);

	useEffect(() => {
		if (campaigns.length === 0 && currentTeamId) {
			dispatch(listCampaigns({}));
		}
	}, [currentTeamId]);

	return (
		<Box className={classes.creatorContainer}>
			<ScheduleSelector
				anchorEl={scheduleSelector}
				handleClose={() => setScheduleSelector(null)}
				onSubmit={(date) =>
					setPostPayload({
						...postPayload,
						schedule: date?.toISOString(),
					})
				}
				defaultValue={
					postPayload?.schedule ? moment(postPayload?.schedule).toDate() : moment().toDate()
				}
			/>

			<HashtagsStatsDialog
				open={hashtagStatsDialog}
				handleClose={() => setHashtagStatsDialog(false)}
			/>

			<Box className={classes.form}>
				<Box className={classes.profilesContainer}>
					<PostSocialProfiles postPayload={postPayload} setPostPayload={setPostPayload} />
				</Box>

				<PostContent
					postPayload={postPayload}
					postContent={postContent}
					filesUploadProgress={filesUploadProgress}
					isHashtagsDrawerOpen={isHashtagsDrawerOpen}
					setIsHashtagsDrawerOpen={setIsHashtagsDrawerOpen}
					setPostContent={setPostContent}
					setPostPayload={setPostPayload}
				/>

				<Box className={classes.linearContainer}>
					<Tooltip title="Schedule">
						<Button secondary className={classes.postingTimeButton} onClick={handleSchedulerOpen}>
							<Box style={{ marginBottom: -2, marginRight: 6 }}>
								<CalendarIconSvg size={16} color="#3E4C55" />
							</Box>
							{!postPayload?.schedule
								? t("selectPostingTime")
								: moment(postPayload.schedule).format("MMMM Do YYYY, h:mm A")}
						</Button>
					</Tooltip>
				</Box>

				<PostTask postPayload={postPayload} setPostPayload={setPostPayload} />

				<PostCampaign setPostPayload={setPostPayload} postPayload={postPayload} />

				<PostCreatorErrors errors={errors} setErrors={setErrors} postPayload={postPayload} />
			</Box>

			<PostActions
				handleSubmit={handleSubmit}
				postPayload={postPayload}
				errors={errors}
				submitDisabled={submitDisabled}
			/>
		</Box>
	);
};

export default PostCreator;

import NetworkIcon from "assets/SVG/NetworkIconSvg";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import SocialProfile from "types/SocialProfile";
import useStyles from "../styles";

interface Props {
	isSelected?: boolean;
	selectionIndex?: number;
	profile: SocialProfile;
	compare?: boolean;
	onClick: (profile: SocialProfile) => void;
}

const SocialProfileItem: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { profile, isSelected, selectionIndex, compare, onClick } = props;

	return (
		<div
			className={classes.profileItem}
			onClick={() => onClick(profile)}
			style={{
				backgroundColor: isSelected ? "#F7F7F7" : "",
			}}
		>
			<NetworkIcon network={profile.network} size={21} style={{ marginRight: 8 }} />
			<SocialProfilePicture profile={profile} style={{ height: 21, width: 21, marginRight: 10 }} />
			<div className={classes.profileText}>{profile.displayName || profile.username}</div>
			{isSelected && <div className={classes.selectedNumber}>{compare ? selectionIndex : ""}</div>}
		</div>
	);
};

export default SocialProfileItem;

import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import MediaAttachment from "components/Media/MediaAttachment";
import { setFullScreenMediaPreview } from "redux/actions/modals";
import Post from "types/Post";
import useUnapprovedPostStyles from "./styles";

interface Props {
	post: Post;
}

const PostMedia = (props: Props) => {
	const classes = useUnapprovedPostStyles();
	const dispatch = useDispatch();
	const { post } = props;
	let media = post.media || [];

	const handleAttachmentClick = (index: number) => {
		dispatch(
			setFullScreenMediaPreview({
				open: true,
				media: media || [],
				initialSelectedIndex: index,
			})
		);
	};

	return (
		<Box className={classes.imagesContainer}>
			{media?.map((attachment, index) => {
				const size = media.length > 1 ? 60 : 120;
				if (index > 3) {
					return <></>;
				}

				if (index === 3 && media.length > 4)
					return (
						<Box onClick={() => handleAttachmentClick(index)} className={classes.overflowImage}>
							+{media.length - 4}
						</Box>
					);
				return (
					<MediaAttachment
						key={index}
						attachment={attachment.value}
						type={attachment.type}
						thumbnailKey={attachment.thumbnail}
						style={{
							width: size,
							maxWidth: size,
							height: size,
							padding: 4,
							marginRight: 0,
						}}
						onAttachmentClick={() => handleAttachmentClick(index)}
					/>
				);
			})}
		</Box>
	);
};

export default PostMedia;

import { Box, CircularProgress, Divider } from "@material-ui/core";
import moment from "moment";
import { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LineChart, { LineDataEntry } from "../../components/Charts/LineChart";
import { StoreState } from "../../setup/store";
import theme from "../../setup/Theme";
import Task from "types/Task";

interface Props {
	style?: CSSProperties;
	tasks: Task[];
}

const ProductivityGraph = (props: Props) => {
	const { style, tasks } = props;
	const { productivityLoading } = useSelector((state: StoreState) => state.tasks);
	const [graphData, setGraphData] = useState<LineDataEntry[]>([]);
	const startDate = moment(new Date().setDate(new Date().getDate() - 30)).format("DD MMM");
	const endDate = moment().format("DD MMM");

	useEffect(() => {
		let graphDataEntry: LineDataEntry = {
			id: "Tasks",
			data: [],
		};

		// Add all month days to the graph
		Array.from(Array(30).keys()).forEach((e, index) => {
			graphDataEntry.data.push({
				x: moment(new Date().setDate(new Date().getDate() - 30 + index + 1)).format("DD MMM"),
				y: 0,
			});
		});

		tasks.reverse().forEach((task) => {
			let formattedDate = moment(task.finishedAt).format("DD MMM");

			graphDataEntry.data.forEach((item) => {
				if (item.x === formattedDate) {
					item.y = parseInt(String(item.y)) + 1;
				}
			});
		});

		setGraphData([graphDataEntry]);
	}, [tasks]);

	return (
		<Box
			style={{
				height: 190,
				width: "90%",
				paddingLeft: 38,
				...style,
			}}
		>
			{productivityLoading && (
				<Box
					style={{
						width: "fit-content",
						marginLeft: "auto",
						marginRight: "auto",
						marginTop: 100,
					}}
				>
					<CircularProgress size={25} />
				</Box>
			)}

			{!productivityLoading && (
				<>
					<LineChart
						disableLegend
						disableGrid
						data={graphData}
						minX={startDate}
						maxX={endDate}
						colors={[theme.palette.primary.main]}
					/>

					<Divider
						style={{
							marginTop: -52,
							width: "76.5%",
							color: "#3E4C55",
							marginLeft: 52,
						}}
					/>
				</>
			)}
		</Box>
	);
};

export default ProductivityGraph;

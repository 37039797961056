import { createMuiTheme, ThemeOptions } from "@material-ui/core";
declare module "@material-ui/core/styles/createMuiTheme" {
	interface Theme {
		custom: {
			palette: {
				title: string;
				secondaryCardBackground: string
			};
		};
	}

}

const theme = createMuiTheme({
	custom: {
		palette: {
			title: "#5B666E",
			secondaryCardBackground: "#F1F4F8"
		},
	},
	shape: {
		borderRadius: 8
	},
	palette: {
		type: "light",
		primary: { main: "#F04755" },
		secondary: { main: "#D9E3EB" },
		text: {
			primary: "#3E4C55",
			secondary: "#4B7FA3",
			hint: "#868C91",
		},
		background: {
			paper: "#EBEBEF",
			default: "#fff",
		},
	},
	typography: {
		fontFamily: "Avenir",
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1580,
			xl: 1920,
		},
	},
} as ThemeOptions);

export default theme;

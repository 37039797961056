import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	welcomeMessage: {
		textAlign: "center",
		marginLeft: "auto",
		marginRight: "auto",
		flexDirection: "column",
		display: "flex",
		paddingTop: 82,
		marginBottom: 52,
	},

	welcomeText: {
		fontSize: 16,
		marginBottom: -8,
		color: theme.palette.primary.main,
		fontWeight: 500,
	},

	welcomeHashtagHero: {
		fontSize: 30,
		fontWeight: 900,
		fontFamily: "Chau Philomene One",
		letterSpacing: 0.4,
		color: theme.palette.primary.main,
	},

	descriptionText: {
		color: "#3E4C55",
		marginTop: 12,
		width: 320,
		marginLeft: "auto",
		marginRight: "auto",
		letterSpacing: 0.5,
	},
}));

export default function FormHeader(props: { includeText?: boolean }) {
	const classes = useStyles();
	return (
		<div className={classes.welcomeMessage}>
			<span className={classes.welcomeText}>Welcome to</span>
			<span className={classes.welcomeHashtagHero}>#HashtagHero</span>
			{props.includeText && (
				<span className={classes.descriptionText}>
					Fast and simple professional social media scheduling and analyting web app
				</span>
			)}
		</div>
	);
}

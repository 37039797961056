import { makeStyles } from "@material-ui/core";

const useQuestionStyles = makeStyles((theme) => ({
	container: {
		borderBottom:  `1px solid ${theme.palette.secondary.main}`,
		paddingBottom: 6,
		maxWidth: 600,
		transition: "0.5s",
		userSelect: "none",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		cursor: "pointer",
	},

	question: {
		fontWeight: 500,
		fontSize: 14,
	},

	arrowIconContainer: {
		width: "fit-content",
		marginLeft: "auto",
	},

	answer: {
		fontSize: 12,
		paddingLeft: 32,
		marginTop: 12,
	},
}));

export default useQuestionStyles;

import useStyles from "./styles";
import ClampLines from "react-clamp-lines";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HeartIconSvg from "assets/SVG/HeartIconSvg";
import Button from "components/Basic/Button";
import { IconButton } from "@material-ui/core";
import moment from "moment";
import Interaction from "types/Interaction";
import SocialProfile from "types/SocialProfile";
import SocialProfileInfo from "./SocialProfileInfo";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import { useTranslation } from "react-i18next";

interface Props {
	interaction: Interaction;
	socialProfile: SocialProfile;
	handleExpand: () => void;
	handleReply: () => void;
}

const CollapsedItem = ({ interaction, socialProfile, handleExpand, handleReply }: Props) => {
	const classes = useStyles();
	const { t } = useTranslation("reply");
	const { replied, read, type } = interaction;
	const postInfoShown = type === "repost" || type === "comment";

	return (
		<div style={{ maxHeight: 58 }} className={classes.container}>
			<div style={{ width: postInfoShown ? "55%" : "75%" }} className={classes.linearContainer}>
				<SocialProfilePicture
					style={{ width: 45, height: 45, marginRight: 12 }}
					url={interaction.from?.profilePictureUrl}
				/>

				<div style={{ width: "85%" }}>
					<div className={classes.linearContainer}>
						<div className={classes.username}>
							{interaction.from?.displayName || interaction.from?.username}
						</div>

						<div
							style={{ width: "fit-content", marginLeft: "auto" }}
							className={classes.linearContainer}
						>
							<div className={classes.date}>
								{moment(interaction.createdAt).format("MMMM Do YYYY, h:mm a")}
							</div>

							{postInfoShown && (
								<div
									style={{ paddingLeft: 32, paddingRight: 16 }}
									className={classes.linearContainer}
								>
									<HeartIconSvg selected={interaction.isLiked} />
									{Boolean(interaction.likes) && (
										<span style={{ paddingLeft: 5 }} className={classes.content}>
											{interaction.likes}
										</span>
									)}
								</div>
							)}
						</div>

						{replied || read ? (
							<div
								style={{
									color: replied ? "#F04755" : "",
									border: replied ? "1px solid #F04755" : "",
								}}
								className={classes.interactionStatus}
							>
								{replied ? "Replied" : "Read"}
							</div>
						) : (
							<div style={{ width: 60 }}></div>
						)}
					</div>
					<div style={{ width: "100%" }} className={classes.content}>
						{interaction.message}
						{interaction.media?.length ? (
							<strong>
								{interaction.message ? " - " : ""}
								{t("attachments", { count: interaction.media.length })}
							</strong>
						) : (
							""
						)}
					</div>
				</div>
				<IconButton onClick={handleExpand}>
					<ExpandMoreIcon className={classes.expandIcon} />
				</IconButton>
			</div>

			<div
				className={classes.linearContainer}
				style={{ width: "45%", paddingLeft: postInfoShown ? 32 : 18 }}
			>
				<div className={classes.linearContainer} style={{ width: "100%" }}>
					{/* Post Info */}
					{postInfoShown && (
						<div className={classes.linearContainer} style={{ width: "45%" }}>
							{interaction.post?.media?.[0] && (
								<img
									src={String(
										interaction.post.media[0].thumbnail || interaction.post.media[0].value
									)}
									className={classes.postImage}
								/>
							)}

							<div style={{ width: "100%" }} className={classes.postContent}>
								<ClampLines
									id="post-content-2"
									text={interaction.post?.content as string}
									lines={1}
									ellipsis="..."
									buttons={false}
								>
									{interaction.post?.content}
								</ClampLines>
							</div>
						</div>
					)}

					<SocialProfileInfo
						collapsed
						socialProfile={socialProfile}
						postInfoShown={postInfoShown}
					/>

					<Button
						onClick={handleReply}
						style={{
							height: 25,
							width: 50,
							marginLeft: "auto",
							marginRight: 8,
						}}
					>
						Reply
					</Button>
				</div>
			</div>
		</div>
	);
};

export default CollapsedItem;

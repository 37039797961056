import { ReduxAction } from "types/ReduxActions";
import Team, { TeamUserRole } from "types/User/Team";

export interface TeamsReducerState {
	currentTeamId: string;
	currentTeamRole: TeamUserRole | null;

	teams: Team[]; // Teams list with all team users populated
	teamsLoading: boolean;
}

let initialState: TeamsReducerState = {
	currentTeamId: "",
	currentTeamRole: null,

	teams: [],
	teamsLoading: false,
};

export const SET_CURRENT_TEAM_ID = "SET_CURRENT_TEAM_ID";
export const SET_CURRENT_TEAM_ROLE = "SET_CURRENT_TEAM_ROLE";
export const SET_TEAMS_DATA = "SET_TEAMS_DATA";
export const SET_TEAMS_DATA_LOADING = "SET_TEAMS_DATA_LOADING";

export default function teamsReducer(
	state: TeamsReducerState = initialState,
	action: ReduxAction
): TeamsReducerState {
	switch (action.type) {
		case SET_CURRENT_TEAM_ID: {
			return { ...state, currentTeamId: action.payload };
		}

		case SET_TEAMS_DATA: {
			return { ...state, teams: action.payload, teamsLoading: false };
		}

		case SET_TEAMS_DATA_LOADING: {
			return { ...state, teamsLoading: action.payload };
		}

		case SET_CURRENT_TEAM_ROLE: {
			return {
				...state,
				currentTeamRole: action.payload,
			};
		}

		default:
			return state;
	}
}

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	container: {
		width: 280,
		height: 580,
		padding: 16,
		paddingLeft: 32,
		paddingRight: 32,
		backgroundColor: "white",
	},

	title: {
		textTransform: "uppercase",
		color: "rgba(91, 102, 110, 1)",
		fontSize: 24,
	},

	headButtons: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		marginTop: 24,
	},

	dayContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		marginBottom: 6,
		marginLeft: 8,
		fontFamily: "Avenir",
	},

	dayTitle: {
		color: "rgba(62, 76, 85, 1)",
		fontSize: 16,
	},

	days: {
		marginTop: 24,
		marginBottom: 16,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},

	checkBoxContainer: {
		marginLeft: "auto",
	},

	finalButtons: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		display: "flex",
		flexDirection: "column",
	},
}));

export default useStyles;

import { makeStyles, useTheme } from "@material-ui/core";
import moment from "moment";
import { CSSProperties, useState, MouseEvent } from "react";
import CalendarIconSvg from "assets/SVG/CalendarIconSvg";
import ScheduleSelector from "../../Dialogs/DateTimePicker";

const useStyles = makeStyles((theme) => ({
	container: {
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: 10,
		paddingLeft: 16,
		paddingRight: 16,
		backgroundColor: "white",
		height: 40,
		width: 190,
		cursor: "pointer",
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
	},

	value: {
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		fontSize: 12,
	},

	error: {
		whiteSpace: "pre-line",
		color: theme.palette.primary.main,
		fontSize: 12,
		marginTop: 6,
		transition: "0.3s",
		overflow: "hidden",
	},
}));

interface Props {
	onChange?: (date: string) => void;
	value?: string;
	error?: string;
	style?: CSSProperties;
	hideTimePicker?: boolean;
	allowPastDate?: boolean;
	iconColor?: string;
	label?: string;
}

export default function DateInput(props: Props) {
	const [scheduleMenu, setScheduleMenu] = useState<HTMLElement | null>(null);
	const classes = useStyles();
	const theme = useTheme();
	const { onChange, value, error, label, style, hideTimePicker, allowPastDate, iconColor } = props;

	const handleClick = (e: MouseEvent<HTMLElement>) => {
		setScheduleMenu(e.currentTarget);
	};

	const handleDateSubmit = (date: Date | undefined) => {
		if (date) {
			onChange && onChange(date.toISOString());
		}
	};

	return (
		<>
			<div>
				<div
					onClick={handleClick}
					className={classes.container}
					style={{
						border: error ? `1px solid ${theme.palette.primary.main}` : "",
						...style,
					}}
				>
					<span className={classes.value}>
						{label}
						{value
							? moment(value).format(hideTimePicker ? "DD MMM YYYY" : "DD MMM, hh:mm A")
							: !label && "Select Date"}
					</span>
					<div style={{ margin: "0px 0px -4px auto", width: "fit-content" }}>
						<CalendarIconSvg size={18} color={iconColor || theme.palette.primary.main} />
					</div>
				</div>
				<div
					style={{
						minHeight: error ? 15 : 0,
						maxHeight: error ? 15 : 0,
						marginTop: error ? 6 : 0,
					}}
					className={classes.error}
				>
					{error}
				</div>
			</div>

			<ScheduleSelector
				onSubmit={handleDateSubmit}
				hideTimePicker={hideTimePicker}
				allowPastDate={allowPastDate}
				defaultValue={value ? new Date(value) : new Date()}
				anchorEl={scheduleMenu as HTMLElement | null}
				handleClose={() => setScheduleMenu(null)}
			/>
		</>
	);
}

import useStyles from "../styles";
import FormHeader from "../FormHeader";
import Button from "components/Basic/Button";
import HerosImage from "assets/heros.png";
import { useHistory } from "react-router";

function WelcomePage() {
	const classes = useStyles();
	const history = useHistory();

	return (
		<>
			<div className={classes.finalStepContainer}>
				<div className={classes.innerContainer} style={{ flexDirection: "row" }}>
					<div className={classes.herosImageContainer}>
						<img src={HerosImage} className={classes.herosImage} />
					</div>
					<div className={classes.welcomePageSection}>
						<FormHeader includeText={false} />
						<span className={classes.addProfilePhoto} style={{ marginTop: 72, fontSize: 22 }}>
							Let’s go now to check
							<br />
							#Hashtaghero superpower
							<br />
							and start your social success!
						</span>
						<div className={classes.buttonContainer}>
							<Button
								onClick={() => history.push("/")}
								style={{
									width: 320,
									fontWeight: 600,
									marginTop: 42,
									marginBottom: 100,
								}}
							>
								LET'S GO!
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default WelcomePage;

import { createStore, combineReducers, applyMiddleware, Reducer } from "redux";
import thunk from "redux-thunk";
import postsReducer, { PostsReducerState } from "redux/reducers/posting";
import snackbarReducer, { SnackBarReducerState } from "redux/reducers/snackBar";
import socialProfilesReducer, { SocialProfilesReducerState } from "redux/reducers/socialProfiles";
import userReducer, { UserReducerState } from "redux/reducers/user";
import calendarEventsReducer, { CalendarEventsReducerState } from "redux/reducers/calendar-events";
import notesReducer, { NotesReducerState } from "redux/reducers/notes";
import campaignsReducer, { CampaignsReducerState } from "redux/reducers/campaigns";
import notificationsReducer, { NotificationsReducerState } from "redux/reducers/notifications";
import modalsReducer, { ModalsReducerState } from "redux/reducers/modals";
import tasksReducer, { TasksReducerState } from "redux/reducers/tasks";
import chatReducer, { ChatReducerState } from "redux/reducers/chat";
import teamsReducer, { TeamsReducerState } from "redux/reducers/teams";
import subscriptionReducer, { SubscriptionReducerState } from "redux/reducers/subscription";
import statsReducer, { StatsReducerState } from "redux/reducers/stats";
import socialInteractionsReducer, {
	SocialInteractionsReducerState,
} from "redux/reducers/social-interactions";
import insightsReducer, { InsightsReducerState } from "redux/reducers/insights";

export interface StoreState {
	user: UserReducerState;
	socialProfiles: SocialProfilesReducerState;
	snackbar: SnackBarReducerState;
	posts: PostsReducerState;
	calendarEvents: CalendarEventsReducerState;
	notes: NotesReducerState;
	campaigns: CampaignsReducerState;
	notifications: NotificationsReducerState;
	modals: ModalsReducerState;
	tasks: TasksReducerState;
	chat: ChatReducerState;
	teams: TeamsReducerState;
	subscription: SubscriptionReducerState;
	stats: StatsReducerState;
	insights: InsightsReducerState;
	socialInteractions: SocialInteractionsReducerState;
}

const store = createStore(
	combineReducers({
		user: userReducer,
		socialProfiles: socialProfilesReducer,
		snackbar: snackbarReducer,
		posts: postsReducer,
		calendarEvents: calendarEventsReducer,
		insights: insightsReducer,
		notes: notesReducer,
		campaigns: campaignsReducer,
		notifications: notificationsReducer,
		modals: modalsReducer,
		tasks: tasksReducer,
		chat: chatReducer,
		teams: teamsReducer,
		subscription: subscriptionReducer,
		stats: statsReducer,
		socialInteractions: socialInteractionsReducer,
	}) as Reducer<StoreState>,
	applyMiddleware(thunk)
);

export default store;

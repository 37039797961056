import { Box, useTheme } from "@material-ui/core";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { listTaskPosts } from "redux/actions/tasks/get";
import Task from "types/Task";
import useTaskDialogStyles from "../../styles/task-dialog";

interface Props {
	selectedTask: Task;
}

const ViewModeHeader = (props: Props) => {
	const { selectedTask } = props;
	// Number of posts in each one of the above postStates;
	const [postsCount, setPostsCount] = useState<{ [index: string]: number }>({});
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation("tasks");
	const classes = useTaskDialogStyles();
	const { urgent, deadline, status } = selectedTask;

	useEffect(() => {
		dispatch(
			listTaskPosts(selectedTask, (posts) => {
				let postsCount: { [index: string]: number } = {
					forApproval: 0,
					inArchive: 0,
					inSchedule: 0,
					drafts: 0,
				};

				posts.forEach((post) => {
					console.log(post);
					if (post.status === "pending") {
						postsCount.forApproval += 1;
					} else if (post.scheduledAt && !post.publishedAt) {
						postsCount.inSchedule += 1;
					} else if (post.publishedAt) {
						postsCount.inArchive += 1;
					} else if (post.draft) {
						postsCount.drafts += 1;
					}
				});

				setPostsCount(postsCount);
			})
		);
	}, [selectedTask]);

	return (
		<Box display="flex" style={{ paddingLeft: 6, paddingRight: 6 }}>
			{/* Title */}
			<Box>
				<Box className={classes.title} style={{ marginBottom: 8 }}>
					{selectedTask.title}
				</Box>
				<Box className={classes.deadline}>
					<Box style={{ marginRight: 8 }}>{t("deadline")}:</Box>
					<Box
						component="span"
						style={{
							color: urgent ? theme.palette.primary.main : "",
							textTransform: urgent ? "uppercase" : "initial",
							fontWeight: urgent ? 600 : 400,
						}}
					>
						{urgent ? t("urgent") : deadline ? moment(deadline).format("DD MMM hh:mm A") : "-"}
					</Box>
				</Box>
			</Box>

			<Box width="fit-content" marginLeft="auto">
				<Box className={classes.deadline}>
					<Box style={{ marginRight: 8 }}>
						{t("status")}: <span style={{ color: "#4285F4" }}>{t(status)}</span>
					</Box>
				</Box>

				<Box className={classes.postsText}>
					{Object.keys(postsCount).map((key) => {
						if (!postsCount[key]) {
							return <Fragment key={key} />;
						}

						return (
							<Box key={key}>
								{t(key)}: {postsCount[key]}
							</Box>
						);
					})}
				</Box>
			</Box>
		</Box>
	);
};

export default ViewModeHeader;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "100vh",
		paddingTop: 16,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		maxWidth: 1100,
		width: "100%",
		marginLeft: "auto",
		marginRight: "auto",
		position: "relative",
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	searchBar: {
		border: `1px solid ${theme.palette.secondary.main}`,
		width: 220,
		height: 40,
		borderRadius: 8,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		backgroundColor: "#FFFFFF",
		marginLeft: "auto",
		marginRight: 16,
	},

	input: {
		color: theme.palette.text.primary,
		opacity: "0.8",
		border: "none",
		backgroundColor: "transparent",
		height: 46,
		width: 185,
		"&:focus": {
			outline: "none",
		},
		paddingLeft: 16,
		fontFamily: "Avenir",
	},

	searchIcon: {
		height: 16,
	},

	postsContainer: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		padding: 16,
		marginTop: 16,
	},

	loadingContainer: {
		width: "fit-content",
		margin: "52px auto 16px auto",
	},

	centeredContainer: {
		width: "fit-content",
		margin: "52px auto 16px auto",
	},

	filterTabsContainer: {
		width: "100%",
		display: "flex",
		marginTop: 16,
		flexDirection: "row",
		alignItems: "center",
		flexWrap: "wrap",
	},
}));

export default useStyles;

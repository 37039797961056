import axios from "axios";
import { Dispatch } from "react";
import config from "config";
import { getHeaders, nameRegex } from "helpers/utils";
import { GetUserDataFunc, UpdateUserFunc, UploadProfilePictureFunc } from "types/ReduxActions";
import { SET_TEAMS_DATA, SET_TEAMS_DATA_LOADING } from "../../reducers/teams";
import {
	SET_AUTH_LOADING,
	SET_ON_BOARDING_STEP,
	SET_USER_DATA,
	SET_USER_DATA_LOADING,
} from "../../reducers/user";
import { resizeFile } from "../media/upload";
import { setSnackbarState } from "../snackBar";
import { OnboardingStepName } from "components/Onboarding/data";

export const getUserData: GetUserDataFunc = (query, callback) => {
	return async (dispatch: Dispatch<any>) => {
		try {
			dispatch({
				type: query?.populateTeamUsers ? SET_TEAMS_DATA_LOADING : SET_USER_DATA_LOADING,
				payload: true,
			});

			const result = await axios.get(`${config.apiUrl}/user/current`, {
				params: query?.populateTeamUsers ? { populateTeamUsers: "true" } : {},
				headers: getHeaders(),
			});

			const { data } = result;

			if (query?.populateTeamUsers) {
				dispatch({
					type: SET_TEAMS_DATA,
					payload: data.teams,
				});
			} else {
				dispatch({
					type: SET_USER_DATA,
					payload: { userData: result.data },
				});
			}

			if (callback) {
				callback(data);
			}
		} catch (e) {
			dispatch({
				type: SET_USER_DATA_LOADING,
				payload: false,
			});
			console.log(e);
		}
	};
};

export const updateUser: UpdateUserFunc = (updates, callback) => {
	return async (dispatch: Dispatch<any>) => {
		if (!updates.preventLoading) {
			dispatch({
				type: SET_AUTH_LOADING,
				payload: true,
			});
		}

		try {
			const { preventLoading, ...payload } = updates;
			
			const result = await axios.put(
				`${config.apiUrl}/user/current`,
				payload,
				{ headers: getHeaders() }
			);

			dispatch({
				type: SET_USER_DATA,
				payload: { userData: result.data },
			});

			if (callback) callback(result.data);
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "UpdateUserError"));
			console.log(e);
		}
	};
};

export const uploadProfilePicture: UploadProfilePictureFunc = (file, callback) => {
	return async (dispatch: Dispatch<any>) => {
		const formData = new FormData();
		const blob = await resizeFile(file);

		formData.append("file", blob as Blob);

		try {
			const result = await axios.post(`${config.apiUrl}/images`, formData, {});

			dispatch(
				updateUser({ profilePicture: result.data[0].key }, () => {
					if (callback) callback();
				})
			);
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "UploadFailedError"));
		}
	};
};

export const validateUsername = (name: string) => {
	let error: string = "";

	if (!nameRegex.test(name)) {
		if (!name) {
			error = "NameRequired";
		} else {
			error = "InvalidName";
		}
	} else if (name.length > 30) {
		error = "NameExceededLimit";
	}

	return error;
};

export const deleteUserData = (cb: () => void) => {
	return async (dispatch: Dispatch<any>) => {
		try {
			await axios.delete(`${config.apiUrl}/user/current`);

			cb();
		} catch (e) {
			console.log(e.response.data);
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
		}
	};
};

export const setOnboardingStep = (name: OnboardingStepName | null) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_ON_BOARDING_STEP,
			payload: name,
		});
	};
};

import { Box, IconButton, useTheme } from "@material-ui/core";
import { Remove, Add } from "@material-ui/icons";
import { CSSProperties } from "react";
import Input from "./Input";

interface Props {
	style?: CSSProperties;
	value: number;
	onChange?: (value: number) => void;
}

const NumberInput: React.FC<Props> = (props) => {
	const { style, value, onChange } = props;
	const theme = useTheme();

	return (
		<Box style={style} display="flex" alignItems="center" width="fit-content" margin="auto">
			<IconButton
				onClick={() => onChange?.(!value ? 0 : value - 1)}
				style={{ padding: 4, marginRight: 8 }}
			>
				<Remove style={{ fontSize: 14, color: "#868C91" }} />
			</IconButton>
			<Input
				value={value}
				onChange={(e) => onChange?.(parseInt(e.target.value))}
				type="number"
				inputStyle={{
					fontSize: 12,
					width: 40,
					height: 40,
					paddingLeft: 0,
					borderRadius: 0,
					paddingRight: 0,
					textAlign: "center",
					background: "white",
				}}
			/>
			<IconButton onClick={() => onChange?.(value + 1)} style={{ padding: 4, marginLeft: 8 }}>
				<Add style={{ fontSize: 14, color: theme.palette.primary.main }} />
			</IconButton>
		</Box>
	);
};

export default NumberInput;

import { Box, makeStyles } from "@material-ui/core";
import { CSSProperties, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	tabs: string[];
	selectedTab: string;
	disabledTabs?: string[];
	style?: CSSProperties;
	tabStyle?: CSSProperties;
	tabTextStyle?: CSSProperties;
	setSelectedTab: Dispatch<SetStateAction<string>>;
}

const useStyles = makeStyles((theme) => ({
	taskTab: {
		cursor: "pointer",
		color: "#5B666E",
		width: 110,
		transition: "0.2s",
		fontSize: 16,
		userSelect: "none",
	},

	taskTabUnderline: {
		width: "40%",
		height: 1,
		background: theme.palette.primary.main,
	},
}));

interface TabProps {
	tabStyle?: CSSProperties;
	tabTextStyle?: CSSProperties;
	disabled?: boolean;
	selected?: boolean;
	tab: string;
	onClick: () => void;
}

export const Tab = (props: TabProps) => {
	const { tabStyle, tabTextStyle, disabled, selected, tab, onClick } = props;
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box
			style={{
				...tabStyle,
				cursor: disabled ? "default" : "pointer",
				opacity: disabled ? 0.3 : selected ? 1 : 0.5,
				fontWeight: selected ? 500 : 400,
			}}
			onClick={onClick}
			className={classes.taskTab}
		>
			<Box
				style={{
					marginBottom: 16,
					...tabTextStyle,
				}}
			>
				{t(tab)}
			</Box>
			{selected && <Box className={classes.taskTabUnderline}></Box>}
		</Box>
	);
};

const TabsNew = (props: Props) => {
	const { tabs, style, tabStyle, disabledTabs, tabTextStyle, selectedTab, setSelectedTab } = props;

	return (
		<Box style={{ ...style }} display="flex" alignItems="center">
			{tabs.map((tab, index) => {
				const selected = tab === selectedTab;
				const disabled = disabledTabs?.includes(tab);
				return (
					<Tab
						key={index}
						tab={tab}
						tabStyle={tabStyle}
						tabTextStyle={tabTextStyle}
						onClick={() => !disabled && setSelectedTab(tab)}
						selected={selected}
						disabled={disabled}
					/>
				);
			})}
		</Box>
	);
};

export default TabsNew;

import { Box } from "@material-ui/core";
import Task from "types/Task";
import useTaskCommentsStyles from "../../styles/comments";
import CommentsList from "./CommentsList";
import CommentsSideBar from "./SideBar";
interface Props {
	selectedTask: Task;
}

const TaskComments = (props: Props) => {
	const { selectedTask } = props;

	const classes = useTaskCommentsStyles();

	return (
		<Box display="flex" style={{ padding: 0, height: 480 }} className={classes.container}>
			<CommentsSideBar selectedTask={selectedTask} />

			<Box className={classes.content}>
				<CommentsList infiniteScrollHeight={350} style={{ height: "100%" }} task={selectedTask} />
			</Box>
		</Box>
	);
};

export default TaskComments;

import { Dialog } from "@material-ui/core";
import LoadingSpinner from "components/Basic/LoadingSpinner";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { listPosts } from "redux/actions/posts/get";
import { StoreState } from "setup/store";
import Post from "types/Post";
import Button from "../../Basic/Button";
import SelectPostDialogHeader from "./Components/Header";
import PostItem from "./Components/PostItem";
import useStyles from "./styles/dialog-styles";

interface Props {
	compare?: boolean;
	open: boolean;
	handleClose: () => void;
	handleSubmit: (selectedPosts: Array<Post>) => void;
}

const SelectPostDialog = (props: Props) => {
	const classes = useStyles();
	const [selectedPosts, setSelectedPosts] = useState<Post[]>([]);
	const { t } = useTranslation();
	const { posts, listPostsLoading, postsSearchLoading } = useSelector(
		(state: StoreState) => state.posts
	);
	const { compare, open, handleClose, handleSubmit } = props;
	const dispatch = useDispatch();

	const handlePostClick = (clickedPost: Post) => {
		const clickedPostIndex = selectedPosts.findIndex((post) => post._id === clickedPost._id);

		if (clickedPostIndex > -1) {
			selectedPosts.splice(clickedPostIndex, 1);
			setSelectedPosts([...selectedPosts]);
		} else {
			if (selectedPosts.length === 1 || (compare && selectedPosts.length !== 2)) {
				setSelectedPosts([clickedPost]);
			} else {
				setSelectedPosts([...selectedPosts, clickedPost]);
			}
		}
	};

	const handleCancelClick = () => {
		setSelectedPosts([]);
		handleClose();
	};

	useEffect(() => {
		if (open) {
			setSelectedPosts([]);
			dispatch(listPosts({ socialPosts: true }));
		}
	}, [open]);

	const isSubmitDisabled = useMemo(() => {
		if ((compare && selectedPosts.length !== 2) || selectedPosts.length === 0) {
			return true;
		}

		return false;
	}, [selectedPosts]);

	return (
		<Dialog classes={{ paper: classes.paper }} open={open} onClose={handleClose}>
			<div className={classes.container}>
				<SelectPostDialogHeader open={open} compare={compare} />
				<div className={classes.postsList}>
					<LoadingSpinner isVisible={listPostsLoading || postsSearchLoading} />
					{!listPostsLoading &&
						!postsSearchLoading &&
						posts.map((post, index) => (
							<PostItem
								key={index}
								post={post}
								compare={Boolean(compare)}
								onClick={handlePostClick}
								selected={
									selectedPosts.filter((selectedPost) => selectedPost._id === post._id).length === 1
								}
								selectionIndex={selectedPosts[0]?._id === post._id ? 1 : 2}
							/>
						))}
				</div>
				<div className={classes.buttonsContainer}>
					<Button onClick={handleCancelClick} style={{ width: 130, marginRight: 10 }} secondary>
						{t("cancel")}
					</Button>
					<Button
						disabled={isSubmitDisabled}
						onClick={() => handleSubmit(selectedPosts)}
						style={{ width: 160 }}
					>
						{t("select")}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default SelectPostDialog;

const ArrowsLeftIcon = ({ size }: { size?: number }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 25 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0.125 13L13.5865 0.5L14.7668 1.77104L2.67407 13L14.7668 24.229L13.5865 25.5L0.125 13ZM9.66484 13L23.1264 0.5L24.3066 1.77104L12.2139 13L24.3066 24.229L23.1264 25.5L9.66484 13Z"
				fill="#3E4C55"
			/>
		</svg>
	);
};

export default ArrowsLeftIcon;

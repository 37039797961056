const DraftIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.1381 2.19526C14.8777 1.93491 14.4556 1.93491 14.1953 2.19526L10.6667 5.72384L18.2762 13.3333L21.8047 9.80474C22.0651 9.54439 22.0651 9.12228 21.8047 8.86193L15.1381 2.19526Z"
				fill={color || "#4B7FA3"}
			/>
			<path
				d="M2 14.3905L9.72388 6.66665L17.3334 14.2761L9.60948 22H2.66667C2.29848 22 2 21.7015 2 21.3333V14.3905Z"
				fill={color || "#4B7FA3"}
			/>
			<path d="M12.6667 22H22V20.6667H12.6667V22Z" fill={color || "#4B7FA3"} />
		</svg>
	);
};

export default DraftIconSvg;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	buttonContainer: {
		position: "absolute",
		bottom: 32,
		right: 32,
	},

	card: {
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
		marginTop: 8,
		height: 350,
		padding: 32,
		position: "relative",
	},
}));

export default useStyles;

import { SocialComment, SocialMessage } from "types/Interaction";
import { ReduxAction } from "types/ReduxActions";

export interface SocialInteractionsReducerState {
	comments: SocialComment[];
	messages: SocialMessage[];
	messagesSyncErrors: SyncError[];
	commentsSyncErrors: SyncError[];
	commentsLoading: boolean;
	messagesLoading: boolean;
	totalComments: number;
	totalMessages: number;
	commentsFetchMoreLoading: boolean;
	messagesFetchMoreLoading: boolean;
	replyLoading: boolean;
}

export type SyncError = {
	profileId: string;
	message: string;
};

const initialState: SocialInteractionsReducerState = {
	comments: [],
	messages: [],
	messagesSyncErrors: [],
	commentsSyncErrors: [],
	messagesLoading: false,
	totalMessages: 0,
	totalComments: 0,
	commentsLoading: false,
	commentsFetchMoreLoading: false,
	messagesFetchMoreLoading: false,
	replyLoading: false,
};

export const SET_SOCIAL_COMMENTS = "SET_SOCIAL_COMMENTS";
export const SET_SOCIAL_MESSAGES = "SET_SOCIAL_MESSAGES";
export const SET_SOCIAL_COMMENTS_LOADING = "SET_SOCIAL_COMMENTS_LOADING";
export const SET_SOCIAL_MESSAGES_LOADING = "SET_SOCIAL_MESSAGES_LOADING";
export const SET_REPLY_LOADING = "SET_REPLY_LOADING";
export const SET_SOCIAL_COMMENTS_FETCH_MORE_LOADING = "SET_SOCIAL_COMMENTS_FECTH_MORE_LOADING";
export const SET_SOCIAL_MESSAGES_FETCH_MORE_LOADING = "SET_SOCIAL_MESSAGES_FECTH_MORE_LOADING";

const socialInteractionsReducer = (
	state: SocialInteractionsReducerState = initialState,
	action: ReduxAction
): SocialInteractionsReducerState => {
	switch (action.type) {
		case SET_SOCIAL_COMMENTS: {
			return {
				...state,
				commentsLoading: false,
				commentsFetchMoreLoading: false,
				totalComments: action.payload.total || state.totalComments,
				comments: action.payload.comments || state.comments,
				commentsSyncErrors: action.payload.syncErrors || state.commentsSyncErrors,

			};
		}
		case SET_SOCIAL_MESSAGES: {
			return {
				...state,
				messagesLoading: false,
				messagesFetchMoreLoading: false,
				totalMessages: action.payload.total || state.totalMessages,
				messages: action.payload.messages || state.messages,
				messagesSyncErrors: action.payload.syncErrors || state.messagesSyncErrors,
			};
		}

		case SET_SOCIAL_COMMENTS_LOADING: {
			return {
				...state,
				commentsLoading: action.payload,
			};
		}
		case SET_SOCIAL_MESSAGES_LOADING: {
			return {
				...state,
				messagesLoading: action.payload,
			};
		}

		case SET_SOCIAL_COMMENTS_FETCH_MORE_LOADING: {
			return {
				...state,
				commentsFetchMoreLoading: action.payload,
			};
		}
		case SET_SOCIAL_MESSAGES_FETCH_MORE_LOADING: {
			return {
				...state,
				messagesFetchMoreLoading: action.payload,
			};
		}

		case SET_REPLY_LOADING: {
			return {
				...state,
				replyLoading: action.payload,
			};
		}

		default:
			return state;
	}
};

export default socialInteractionsReducer;

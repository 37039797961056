import { makeStyles } from "@material-ui/core";

const usePersonalInfoStyles = makeStyles((theme) => ({
	card: {
		height: "70vh",
		maxHeight: 600,
		paddingLeft: 42,
		paddingRight: 42,
	},
	personalInfoPair: {
		marginBottom: 24,
		display: "flex",
		alignItems: "center",
	},

	personalInfoLabel: {
		color: theme.palette.text.primary,
		width: 120,
		fontSize: 14,
	},

	personalInfoValue: {
		color: "#4B7FA3",
		fontStyle: "italic",
		fontWeight: 500,
		fontSize: 14,
		marginLeft: 16,
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
		position: "absolute",
		bottom: 42,
		width: "100%",
		left: 0,
		paddingRight: 42,
		paddingLeft: 42,
	},

	rightButtonsContainer: {
		width: "fit-content",
		marginLeft: "auto",
		display: 'flex',
		alignItems: 'center'
	},

	profilePicSection: {
		width: "45%",
	},

	pictureContainer: {
		marginLeft: 62,
		width: "fit-content",
		position: "relative",
	},

	imageOverlayContainer: {
		position: "absolute",
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		transition: "0.3s",
		top: 0,
	},

	profilePicture: {
		height: 200,
		width: 200,
		minHeight: 200,
		minWidth: 200,
		objectFit: "cover",
		marginTop: 32,
		transition: "0.3s",
		borderRadius: "50%",
	},

	inputContainer: {
		marginBottom: 16,
	},
}));

export default usePersonalInfoStyles;

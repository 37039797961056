// TODO: Remove this file after API integration
// This data is used for testing purposes only
import Hashtag from "types/Hashtag";
import Post from "types/Post";
import SocialProfile from "types/SocialProfile";

export const placeholderDynamics = [
	{ Views: 163, Likes: 64, Dislikes: 153, Reposts: 15 },
	{ Views: 61, Likes: 164, Dislikes: 172, Reposts: 181 },
	{ Views: 171, Likes: 73, Dislikes: 125, Reposts: 110 },
	{ Views: 34, Likes: 123, Dislikes: 34, Reposts: 190 },
	{ Views: 107, Likes: 192, Dislikes: 155, Reposts: 169 },
	{ Views: 185, Likes: 48, Dislikes: 98, Reposts: 56 },
	{ Views: 124, Likes: 151, Dislikes: 78, Reposts: 70 },
	{ Views: 95, Likes: 137, Dislikes: 68, Reposts: 159 },
	{ Views: 87, Likes: 35, Dislikes: 69, Reposts: 21 },
];

export let posts: Post[] = [
	{
		id: "j1oiasdfasdf24j1oasfg23i24j",
		user: {
			username: "soqu_official",
			picture: "https://i.imgur.com/rLD4mVh.png",
		},
		attachments: [
			"https://i.imgur.com/t6IUBCw.png",
			"https://i.imgur.com/cBfXYY1.png",
			"https://i.imgur.com/ZjOCYK1.png",
		],
		networks: ["facebook", "linkedin", "twitter"],
		campaign: "New Shop Campaign",
		schedule: "2020-12-30T12:30:00Z",
		content: `New shop opening soon! Hurry up to be first and get our early bird gift! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
		socialProfiles: [
			{
				_id: "asdfoej1235ga",
				network: "facebook",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Offical",
			},
			{
				_id: "asdfoj12a1335ga",
				network: "linkedin",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Trainings",
			},
			{
				_id: "asdj123j5ga",
				network: "instagram",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Official",
			},
		],
	},
	{
		id: "j1oi24j1oasfaafdg23i24j",
		user: {
			username: "soqu_official",
			picture: "https://i.imgur.com/rLD4mVh.png",
		},
		attachments: ["https://i.imgur.com/cBfXYY1.png"],
		networks: ["instagram", "twitter", "facebook"],
		campaign: "New Shop Campaign",
		schedule: "2020-12-30T12:00:00Z",
		content: `New shop opening soon!
        Hurry up to be first and get our early bird gift!`,
		socialProfiles: [
			{
				_id: "asdfoej1235ga",
				network: "facebook",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Offical",
			},
			{
				_id: "asdfoj12a1335ga",
				network: "twitter",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Trainings",
			},
			{
				_id: "asdj123j5ga",
				network: "instagram",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Official",
			},
		],
	},
	{
		id: "j1oi24j1oasdffasfg23i24j",
		user: {
			username: "soqu_official",
			picture: "https://i.imgur.com/rLD4mVh.png",
		},
		attachments: ["https://i.imgur.com/ZjOCYK1.png"],
		campaign: "Summer Campaign",
		networks: ["instagram", "twitter"],
		schedule: "2020-12-30T12:00:00Z",
		content: `Hi there! Are you ready for exellent mood? Good news for you! #alohacollection`,
		socialProfiles: [
			{
				_id: "asdfoej1235ga",
				network: "facebook",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Offical",
			},
			{
				_id: "asdfoj12a1335ga",
				network: "twitter",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Trainings",
			},
			{
				_id: "asdj123j5ga",
				network: "instagram",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Official",
			},
		],
	},
	{
		id: "j1dfdfdfi24j1oasfg23i24j",
		user: {
			username: "soqu_official",
			picture: "https://i.imgur.com/rLD4mVh.png",
		},
		attachments: ["https://i.imgur.com/ZjOCYK1.png"],
		networks: ["instagram", "twitter"],
		schedule: "2020-12-30T12:00:00Z",
		content: `Hi there! Are you ready for exellent mood? Good news for you! #alohacollection`,
		socialProfiles: [
			{
				_id: "asdfoej1235ga",
				network: "facebook",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Offical",
			},
			{
				_id: "asdfoj12a1335ga",
				network: "linkedin",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Trainings",
			},
			{
				_id: "asdj123j5ga",
				network: "instagram",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Official",
			},
		],
	},
	{
		id: "j1oi24j1oasfg23i24jaaa",
		user: {
			username: "soqu_official",
			picture: "https://i.imgur.com/rLD4mVh.png",
		},
		attachments: ["https://i.imgur.com/t6IUBCw.png"],
		networks: ["instagram", "linkedin"],
		schedule: "2020-12-30T12:00:00Z",
		content: `New shop opening soon!
        Hurry up to be first and get our early bird gift!`,
		socialProfiles: [
			{
				_id: "asdfoej1235ga",
				network: "facebook",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Offical",
			},
			{
				_id: "asdfoj12a1335ga",
				network: "linkedin",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Trainings",
			},
			{
				_id: "asdj123j5ga",
				network: "instagram",
				picture: "https://i.imgur.com/rLD4mVh.png",
				username: "SoQu Official",
			},
		],
	},
];

export const placeholderSocialProfiles: SocialProfile[] = [
	{
		_id: "asdfoej1235ga",
		network: "facebook",
		picture: "https://i.imgur.com/rLD4mVh.png",
		username: "SoQu Official",
	},
	{
		_id: "asgdfoja235ga",
		network: "facebook",
		picture: "https://i.imgur.com/rLD4mVh.png",
		username: "SoQu Events",
	},
	{
		_id: "asdfoj12a1335ga",
		network: "linkedin",
		picture: "https://i.imgur.com/rLD4mVh.png",
		username: "SoQu Trainings",
	},
	{
		_id: "asdj123j5ga",
		network: "linkedin",
		picture: "https://i.imgur.com/rLD4mVh.png",
		username: "SoQu Official",
	},
	{
		_id: "asdfoj1235aadgva",
		network: "twitter",
		picture: "https://i.imgur.com/rLD4mVh.png",
		username: "SoQu Official",
	},
	{
		_id: "asdfoj1123g235ga",
		network: "instagram",
		picture: "https://i.imgur.com/rLD4mVh.png",
		username: "SoQu Official",
	},
];

export const placeholderHashtagStats: Hashtag[] = [
	{ title: "#happyspring", shares: 8987824 },
	{ title: "#goodmood", shares: 6758594 },
	{ title: "#itslife", shares: 4768594 },
	{ title: "#polytics", shares: 2987824 },
	{ title: "#technology", shares: 1987824 },
];

import { networkIcons } from "types/Network";
import Post from "types/Post";
import useStyles from "../styles/post-item-styles";
import ClampLines from "react-clamp-lines";
import moment from "moment";
import usePageStyles from "../../../styles";
import { postDataStats } from "../../../../../mock/listenPlaceholderData";

interface Props {
	postIndex: number;
	post: Post;
}

const PostItem = (props: Props) => {
	const classes = useStyles();
	const pageClasses = usePageStyles();
	const { postIndex, post } = props;

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div className={classes.title}>Post {postIndex}</div>

				<div className={classes.socialProfileInfo}>
					<span className={classes.username}>{post.user.username}</span>
					<div className={classes.socialIcon}>
						<img src={post.user.picture} className={classes.profilePicture} alt="profile" />
						<img src={networkIcons[post.networks[0]]} height={21} alt="network" />
					</div>
				</div>
			</div>
			<div className={classes.innerBox}>
				<div className={classes.linearContainer}>
					<div className={classes.postContent}>
						{post.attachments[0] && (
							<img alt='post-attachment' src={post.attachments[0]} className={classes.postAttachment} />
						)}
						{post.content && (
							<span className={classes.postText}>
								<ClampLines
									id="post-content"
									text={post.content}
									lines={3}
									ellipsis="..."
									className="custom-class"
									buttons={false}
								/>
							</span>
						)}
					</div>
					<div className={classes.dateText}>
						{"Posted " + moment(post.schedule).format("DD MMM YYYY, h:mm:ss A")}
					</div>
				</div>

				<div className={classes.linearContainer} style={{ marginTop: 32 }}>
					{postDataStats.map((item, index) => (
						<div className={classes.dataItemContainer} key={index}>
							<span className={pageClasses.dataItemTitle}>{item.title}</span>
							<span className={pageClasses.dataItemValue}>{item.value}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PostItem;

import { Box } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

const TasksSkeleton = () => {
	return (
		<>
			{Array.from(Array(2).keys()).map((key) => (
				<Box key={key} display="flex" style={{ marginTop: 24 }}>
					{Array.from(Array(3).keys()).map((key) => (
						<Box
							key={key}
							display="flex"
							flexDirection="column"
							style={{ marginRight: 12, marginLeft: 12 }}
						>
							<Skeleton style={{ width: 180, marginBottom: 8 }} />
							<Skeleton style={{ width: 200, height: 40, marginBottom: 8 }} />
							<Box display="flex">
								<Skeleton style={{ width: 30, height: 30, borderRadius: "50%" }} />
								<Skeleton
									style={{
										width: 30,
										height: 30,
										borderRadius: "50%",
										marginLeft: 12,
									}}
								/>
							</Box>
						</Box>
					))}
				</Box>
			))}
		</>
	);
};

export default TasksSkeleton;

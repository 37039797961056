import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		height: 40,
		marginTop: 8,
		display: "flex",
		flexDirection: "row",
		borderRadius: 8,
		alignItems: "center",
		cursor: "pointer",
	},

	marker: {
		height: "100%",
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
		width: 5,
	},

	title: {
		marginLeft: 16,
		fontSize: 14,
		fontFamily: "Avenir",
		letterSpacing: 0.4,
		color: theme.palette.text.primary,
	},

	dateContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: 16,
	},

	dateText: {
		color: theme.palette.text.primary,
		fontSize: 12,
		opacity: 0.8,
	},
}));

export default useStyles;

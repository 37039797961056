import { Box, Popper } from "@material-ui/core";
import UserPicture from "components/Media/UserPicture";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import useUserInfoPopperStyles from "./styles";

interface Props {
	anchorEl: HTMLElement | null;
	userId: string;
}

const UserInfoPopper: React.FC<Props> = (props) => {
	const { teams, currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { anchorEl, userId } = props;
	const { t } = useTranslation("workspaces");
	const classes = useUserInfoPopperStyles();

	const teamUser = useMemo(
		() =>
			teams
				.find((team) => team._id === currentTeamId)
				?.users.find(({ user }) => user?._id === userId),
		[teams, currentTeamId]
	);

	return (
		<Popper open={Boolean(anchorEl)} anchorEl={anchorEl} className={classes.userPopper}>
			{!teamUser && <Box>{t("userNotFound")}</Box>}

			{teamUser?.user && (
				<Box display="flex" alignItems="center">
					<UserPicture user={teamUser.user} />

					<Box>
						<Box className={classes.userName}>
							{teamUser.user.firstName} {teamUser.user.lastName}
						</Box>

						<Box className={classes.emailAddress}>{teamUser.user.email}</Box>
					</Box>

					<Box className={classes.role}>{teamUser.role}</Box>
				</Box>
			)}
		</Popper>
	);
};

export default UserInfoPopper;

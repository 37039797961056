import { Box } from "@material-ui/core";
import MoreInfoButton from "components/Basic/MoreInfoButton";
import { numberWithCommas } from "helpers/utils";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import useTeamMembersAddonsDialogStyles from "../styles";
import { TeamMembersAddonsDialogPayload } from "../TeamMembersAddonsDialog";

interface Props {
	open: boolean;
	payload: TeamMembersAddonsDialogPayload;
	userAddonPrice: number;
}

const TeamMembersAddonsPriceSummary: React.FC<Props> = (props) => {
	const { currentTeamSubscription } = useSelector((store: StoreState) => store.subscription);
	const classes = useTeamMembersAddonsDialogStyles();
	const { t } = useTranslation("settings");

	const { payload, userAddonPrice, open } = props;

	/** Prorations are made when changes are made to an already active subscription
	 * and the "apply changes at" radio options are set to "immediately".
	 * More info about how proration is calculated: https://www.chargebee.com/docs/2.0/proration.html
	 */
	const getProratedAddonPrice = () => {
		if (!currentTeamSubscription) return 0;
		const { currentTermEnd, currentTermStart, billingPeriodUnit } = currentTeamSubscription;

		const totalTermDuration =
			moment.unix(currentTermStart).add(1, billingPeriodUnit).hours(0).minutes(0).valueOf() -
			moment.unix(currentTermStart).hours(0).minutes(0).valueOf();

		const remainingTermDuration = moment.unix(currentTermEnd).valueOf() - moment().valueOf();

		const pricePerMilliSecond = userAddonPrice / totalTermDuration;

		return Number((pricePerMilliSecond * remainingTermDuration).toFixed(2));
	};

	const proRatedUserAddonPrice = useMemo(
		() => getProratedAddonPrice(),
		[currentTeamSubscription, open]
	);

	if (!currentTeamSubscription) {
		return null;
	}

	const { planInfo, billingPeriodUnit, userAddons } = currentTeamSubscription;

	const billingPeriodType = billingPeriodUnit === "month" ? "monthly" : "yearly";
	const currentPlanPrice = planInfo.price[billingPeriodType];

	return (
		<Box className={classes.summaryContainer}>
			{payload.applyChangesAt === "immediately" && (
				<>
					<PriceSummaryItem
						moreInfo={t("newExtraTeamMembersMoreInfo")}
						label={`${t("extraTeamMembers")} (Pro-rated) (${payload.extraTeamMembers || 0})`}
						price={proRatedUserAddonPrice * (payload.extraTeamMembers || 0)}
					/>

					<PriceSummaryItem
						label={t("total")}
						bold
						price={proRatedUserAddonPrice * (payload.extraTeamMembers || 0)}
					/>
				</>
			)}

			{payload.applyChangesAt === "nextBillingPeriod" && (
				<>
					<PriceSummaryItem
						label={`${t("subscriptionPlan")} (${planInfo.title})`}
						price={currentPlanPrice || 0}
					/>

					{Boolean(userAddons) && (
						<PriceSummaryItem
							label={`${t("existingExtraTeamMembers")} (Pro-rated) (${userAddons})`}
							price={userAddons! * userAddonPrice}
						/>
					)}

					<PriceSummaryItem
						label={t("newExtraTeamMembers")}
						price={(payload.extraTeamMembers || 0) * userAddonPrice}
					/>

					<PriceSummaryItem
						periodUnit={billingPeriodUnit}
						label={t("nextPayment")}
						moreInfo={t("nextPaymentMoreInfo")}
						bold
						price={
							(currentPlanPrice || 0) +
							userAddons! * userAddonPrice +
							userAddonPrice * (payload.extraTeamMembers || 0)
						}
					/>
				</>
			)}
		</Box>
	);
};

const PriceSummaryItem = ({
	label,
	price,
	bold,
	periodUnit,
	moreInfo,
}: {
	label: string;
	price: number;
	bold?: boolean;
	moreInfo?: string;
	periodUnit?: "month" | "year";
}) => {
	return (
		<Box
			marginTop={bold ? 2 : 0.8}
			style={{ fontSize: bold ? 16 : 14, fontWeight: bold ? 600 : 400 }}
			display="flex"
		>
			<Box>{label}</Box>

			{moreInfo && <MoreInfoButton style={{ marginLeft: 8 }}>{moreInfo}</MoreInfoButton>}

			<Box width="fit-content" marginLeft="auto">
				${numberWithCommas(price)} {periodUnit ? "/" + periodUnit : ""}
			</Box>
		</Box>
	);
};

export default TeamMembersAddonsPriceSummary;

import { ComposableMap, Geographies, Geography } from "react-simple-maps";

const geoUrl =
	"https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

interface Props {
	inputValues: { [index: string]: string };
}

const TimeSettingsMap = ({ inputValues }: Props) => {
	return (
		<ComposableMap>
			<Geographies geography={geoUrl}>
				{({ geographies }) =>
					geographies.map((geo) => {
						let backgroundColor = "#B6BFC4";
						if (geo.properties.ISO_A2 === inputValues.country) {
							backgroundColor = "#868C91";
						}

						return (
							<Geography
								key={geo.rsmKey}
								geography={geo}
								style={{
									default: {
										fill: backgroundColor,
										outline: "none",
									},
									hover: { outline: "none", fill: backgroundColor },
									pressed: { outline: "none", fill: backgroundColor },
								}}
							/>
						);
					})
				}
			</Geographies>
		</ComposableMap>
	);
};

export default TimeSettingsMap;

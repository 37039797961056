import { Box } from "@material-ui/core";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationIllustration2 } from "../../assets";
import MetaTagsHeader from "../../components/Navigation/Components/MetaTags";
import Container from "../../components/Navigation/Container";
import {
	readNotifications as readNotificationsAction,
	getNotifications,
} from "redux/actions/notifications";
import { StoreState } from "../../setup/store";
import Notification from "types/Notification";
import NotificationsHeader from "./components/Header";
import NotificationsPageSection from "./components/NotificationsPageSection";
import useNotificationsStyles from "./styles";

export type Tab = {
	label: string;
	value: string;
};

export type DaySection = {
	date: string;
	items: Notification[];
};

export const notificationTabs = [
	{ label: "View All", value: "all" },
	{ label: "Tasks", value: "Task" },
	{ label: "Posts", value: "Post" },
	{ label: "Reminders", value: "Reminder" },
	{ label: "Campaigns", value: "Campaign" },
];

export const sections = ["unreadNotifications", "readNotifications"];

const NotificationsPage = () => {
	const dispatch = useDispatch();
	const { unreadNotifications } = useSelector((state: StoreState) => state.notifications);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const [unreadNotificationsDays, setUnreadNotificationsDays] = useState<DaySection[]>([]);
	const [selectedTab, setSelectedTab] = useState<string>("all");
	const [selectedDate, setSelectedDate] = useState<string>("");
	const classes = useNotificationsStyles();

	useEffect(() => {
		let days: DaySection[] = [];

		unreadNotifications.forEach((notification) => {
			let date = "Today";
			if (moment().dayOfYear() !== moment(notification.createdAt).dayOfYear()) {
				date = moment(notification.createdAt).format("MMMM DD");
			}

			let dateFound = false;
			days.forEach((day) => {
				if (day.date === date) {
					day.items.push(notification);
					dateFound = true;
				}
			});

			if (!dateFound) {
				days.push({
					date,
					items: [notification],
				});
			}
		});

		setUnreadNotificationsDays([...days]);
	}, [unreadNotifications]);

	useEffect(() => {
		const query = { type: selectedTab, date: selectedDate };
		if (currentTeamId) {
			dispatch(getNotifications({ read: true, ...query }));
			dispatch(getNotifications({ read: false, ...query }));
		}
	}, [selectedTab, selectedDate, currentTeamId]);

	useEffect(() => {
		if (unreadNotifications.length > 0 && currentTeamId) {
			dispatch(readNotificationsAction(unreadNotifications.map((item) => item._id)));
		}
	}, [unreadNotifications, currentTeamId]);

	return (
		<Container page="Notifications">
			<MetaTagsHeader title="Notifications | HashtagHero" />
			<Box className={classes.pageContainer}>
				<Box className={classes.innerContainer}>
					<NotificationsHeader
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
					/>
					<Box display="flex" flexDirection="row">
						<Box style={{ width: "65%" }}>
							{sections.map((section, index) => (
								<NotificationsPageSection
									unreadNotificationsDays={unreadNotificationsDays}
									section={section}
									key={index}
								/>
							))}
						</Box>

						<Box>
							<img className={classes.illustration} src={NotificationIllustration2} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};

export default NotificationsPage;

import { Box } from "@material-ui/core";
import HintText from "components/Basic/Text/HintText";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isFreeSubscription } from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import theme from "setup/Theme";
import CancelledSubscriptionButton from "../../SubscriptionPlans/CurrentSubscriptionPlan/components/CancelledSubscriptionButton";
import ScheduledChangesButton from "../../SubscriptionPlans/CurrentSubscriptionPlan/components/ScheduledChangesButton";

const SubscriptionDialogWarnings = () => {
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const { t } = useTranslation("settings");

	if (!currentTeamSubscription) {
		return <></>;
	}

	const { hasScheduledChanges, status } = currentTeamSubscription;

	return (
		<>
			{currentTeamSubscription && !isFreeSubscription(currentTeamSubscription) && (
				<Box style={{ marginTop: 42, marginLeft: 24, maxWidth: "70%" }}>
					{status === "non_renewing" && (
						<HintText isWarningIconVisible style={{ color: theme.palette.error.dark }}>
							<Box>{t("schedulledCancellationError")}</Box>
						</HintText>
					)}

					<HintText isWarningIconVisible style={{ marginTop: 12 }}>
						<Box>{t("updateSubscriptionHintText")}</Box>
					</HintText>

					{hasScheduledChanges && <ScheduledChangesButton style={{ marginTop: 12 }} />}

					{status === "cancelled" && <CancelledSubscriptionButton style={{ marginTop: 8 }} />}
				</Box>
			)}
		</>
	);
};

export default SubscriptionDialogWarnings;

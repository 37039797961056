import { useEffect, Dispatch, SetStateAction } from "react";
import { loadVideo } from "helpers/utils";
import Network from "types/Network";
import { PostPayload } from "types/Post";
import usePostCreatorStyles from "../styles/post-creator-styles";

interface Props {
	postPayload: PostPayload | undefined;
	errors: string[];
	setErrors: Dispatch<SetStateAction<string[]>>;
}

const PostCreatorErrors = (props: Props) => {
	const classes = usePostCreatorStyles();
	const { postPayload, errors, setErrors } = props;

	const hasNetwork = (network: Network) => {
		return postPayload?.socialProfiles?.find((profile) => profile.network === network);
	};

	const validatePayload = async () => {
		if (!postPayload) {
			return;
		}

		let errors: string[] = [];
		let hasVideo = false;
		let hasMovVideo = false;
		let hasLargeVideo = false;
		let videoDuration = 0;

		const { media, content } = postPayload;

		if (media) {
			await Promise.all(
				media.map(async (attachment) => {
					if (attachment.type === "video") {
						const video = (await loadVideo(attachment.value as File)) as any;
						attachment.videoDuration = video.duration;

						if (typeof attachment.value !== "string") {
							hasVideo = true;

							if (attachment.value.size > 200000000) {
								hasLargeVideo = true;
							}

							if (!attachment.type.includes("mp4")) {
								hasMovVideo = true;
							}
						}
					}
				})
			);
		}

		if (hasNetwork("twitter") && content?.length! > 280)
			errors.push("Twitter posts cannot contain more than 280 characters");

		if (hasNetwork("linkedin") && content?.length! > 3000)
			errors.push("LinkedIn posts cannot contain more than 3000 characters");

		// Instagram media count
		// if (hasNetwork("instagram") && media && media.length > 1)
		// 	errors.push("Instagram posts can only contain 1 image or video");

		if (hasLargeVideo) errors.push("Videos cannot exceed 200 Mbs");

		if (
			hasNetwork("instagram") &&
			media?.find((attachment) => (attachment.videoDuration || 0) > 60) &&
			(media?.length || 0) > 1
		) {
			errors.push(
				"Media has a video that's longer than 1 minute, which will be uploaded as a reel, thus cannot be posted along with other images/videos."
			);
		}

		if (hasNetwork("instagram") && (media?.length || 0) > 10) {
			errors.push("Instagram posts cannot contain more than 10 images/videos");
		}

		if (hasNetwork("linkedin") && videoDuration > 900)
			// LinkedIn video length
			errors.push("LinkedIn videos cannot be longer than 15 minutes");

		if (hasNetwork("twitter") && videoDuration > 120)
			errors.push("Twitter videos cannot be longer than 2 minutes");

		// Facebook video post media count
		if (hasNetwork("facebook") && hasVideo && media && media.length > 1)
			errors.push("Facebook video posts can only contain 1 video");

		// LinkedIn video post media count
		if (hasNetwork("linkedin") && hasVideo && media && media.length > 1)
			errors.push("LinkedIn video posts can only contain 1 video");

		// Video format
		// if (hasMovVideo)
		//     errors.push('Invalid video format. Only videos with the format MP4 are supportd');

		// LinkedIn images limit
		if (hasNetwork("linkedin") && !hasVideo && media && media.length > 9)
			errors.push("LinkedIn posts cannot have more than 9 images");

		setErrors([...errors]);
	};

	useEffect(() => {
		validatePayload();
	}, [postPayload]);

	return (
		<div style={{ marginTop: 32 }}>
			{errors.map((error, index) => (
				<div className={classes.error} key={index}>
					{error}
				</div>
			))}
		</div>
	);
};

export default PostCreatorErrors;

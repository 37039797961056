import { Box } from "@material-ui/core";
import PersonalInfoOverview from "../Components/PersonalInfoOverview";

const ProfileOverview = () => {
	return (
		<Box>
			<PersonalInfoOverview />
			{/* <ReferFriends /> */}
		</Box>
	);
};

export default ProfileOverview;

import Button from "components/Basic/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAddProfileDialog } from "redux/actions/modals";
import SocialProfile from "types/SocialProfile";
import useProfilesSelectorStyles from "../styles";

interface Props {
	checkedProfiles: string[];
	filteredProfiles: SocialProfile[];
	handleClose: () => void;
	onChange: (socialProfiles: SocialProfile[]) => void;
}

const ProfilesSelectorActions: React.FC<Props> = (props) => {
	const classes = useProfilesSelectorStyles();
	const { t } = useTranslation("socialProfiles");
	const { filteredProfiles, checkedProfiles, onChange, handleClose } = props;
	const dispatch = useDispatch();

	const handleSubmit = () => {
		let profiles: SocialProfile[] = [];

		filteredProfiles.forEach((profile) => {
			if (checkedProfiles.includes(profile._id!)) {
				profiles.push(profile);
			}
		});

		onChange(profiles);
		handleClose();
	};

	const handleAddClick = () => {
		handleClose();
		dispatch(setAddProfileDialog(true));
	};

	return (
		<div className={classes.buttonContainer}>
			<Button
				onClick={handleSubmit}
				disabled={checkedProfiles.length === 0}
				style={{ width: 180, height: 40 }}
			>
				{t("submit")}
			</Button>
			<span onClick={handleAddClick} className={classes.addNewProfile}>
				{t("addNewSocialProfile")}
			</span>
		</div>
	);
};

export default ProfilesSelectorActions;

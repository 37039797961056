import { Box, useTheme } from "@material-ui/core";
import WarningIconSvg from "assets/SVG/WarningIconSvg";
import { CSSProperties, ReactNode } from "react";

interface Props {
	className?: string;
	style?: CSSProperties;
	warning?: boolean;
	error?: boolean;
	isWarningIconVisible?: boolean;
}

const HintText: React.FC<Props> = (props) => {
	const theme = useTheme();
	const { className, style, children, error, warning, isWarningIconVisible } = props;

	return (
		<Box
			className={className}
			style={{
				overflow: "hidden",
				transition: "0.3s",
				color: error
					? theme.palette.error.dark
					: warning
					? theme.palette.warning.dark
					: theme.palette.text.hint,
				fontSize: 12,
				display: "flex",
				...style,
			}}
		>
			{isWarningIconVisible && (
				<Box style={{ marginRight: 8 }}>
					<WarningIconSvg color={style?.color || theme.palette.text.hint} size={15} />
				</Box>
			)}
			{children}
		</Box>
	);
};

export default HintText;

import { postDataStats, postDataStats2 } from "../../../../../mock/listenPlaceholderData";
import { networkIcons } from "types/Network";
import SocialProfile from "types/SocialProfile";
import usePageStyles from "../../../styles";
import useStyles from "../styles/profile-item-styles";

interface Props {
	profile: SocialProfile;
	profileIndex: number;
}

const ProfileItem = (props: Props) => {
	const classes = useStyles();
	const pageClasses = usePageStyles();
	const { profile, profileIndex } = props;

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div className={classes.socialProfileInfo}>
					<img src={networkIcons[profile?.network]} className={classes.socialIcon} />
					<img src={profile?.picture} className={classes.profilePicture} />
					<div className={classes.username}>{profile?.username}</div>
				</div>

				<div className={classes.indexIndicator}>{profileIndex}</div>
			</div>
			<div className={classes.dataBox}>
				<div className={classes.linearContainer} style={{ marginBottom: 32, marginTop: 12 }}>
					{/* TODO: Replace placeholders */}
					{postDataStats.map((item, index) => (
						<div className={classes.dataItemContainer} key={index}>
							<span className={pageClasses.dataItemTitle}>{item.title}</span>
							<span className={pageClasses.dataItemValue}>{item.value}</span>
						</div>
					))}
				</div>
				<div className={classes.linearContainer}>
					{postDataStats2.map((item, index) => (
						<div className={classes.dataItemContainer} key={index}>
							<span className={pageClasses.dataItemTitle}>{item.title}</span>
							<span className={pageClasses.dataItemValue}>{item.value}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProfileItem;

import { DeleteConfirmImage } from "assets";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import OptionsPopover from "components/Popovers/OptionsPopover";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
	deletePaymentMethod,
	setPrimaryPaymentMethod,
} from "redux/actions/team/subscriptions/billing";
import { PaymentMethod } from "types/Billing";

interface Props {
	method: PaymentMethod;
	anchorEl: HTMLElement | null;
	onClose: () => void;
	onUpdateBillingAddressClick?: (method: PaymentMethod) => void;
}

const BillingMethodOptionsPopover = (props: Props) => {
	const { anchorEl, method, onUpdateBillingAddressClick, onClose } = props;
	const { t } = useTranslation("settings");
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [makePrimaryLoading, setMakePrimaryLoading] = useState(false);
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const dispatch = useDispatch();

	const handleDelete = async () => {
		setDeleteLoading(true);
		dispatch(
			deletePaymentMethod(method.id, () => {
				setDeleteLoading(false);
				onClose();
			})
		);
	};

	const handleSetAsPrimary = async () => {
		setMakePrimaryLoading(true);
		dispatch(
			setPrimaryPaymentMethod(method.id, () => {
				setMakePrimaryLoading(false);
				onClose();
			})
		);
	};

	const handleOptionClick = (value: string) => {
		if (value === "deleteMethod") {
			setDeleteConfirmation(true);
		} else if (value === "setAsPrimary") {
			handleSetAsPrimary();
		} else if (value === "updateBillingAddress") {
			onUpdateBillingAddressClick?.(method);
		}
	};

	return (
		<>
			<ConfirmationDialog
				open={deleteConfirmation}
				onClose={() => setDeleteConfirmation(false)}
				onAction={handleDelete}
				confirmButton={t("deletePaymentMethod")}
				message={t("deletePaymentMethodMessage")}
				actionLoading={deleteLoading}
				image={DeleteConfirmImage}
			/>

			<OptionsPopover
				preventClose
				anchorEl={anchorEl}
				options={[
					{
						label: t("setAsPrimary"),
						value: "setAsPrimary",
						loading: makePrimaryLoading,
						disabled: method.primary,
					},
					{ label: t("updateBillingAddress"), value: "updateBillingAddress" },
					{ label: t("deleteMethod"), value: "deleteMethod" },
				]}
				onChange={handleOptionClick}
				onClose={onClose}
			/>
		</>
	);
};

export default BillingMethodOptionsPopover;

import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import { useHistory } from "react-router";
import useStyles from "../styles";
import Input from "components/Basic/Inputs/Input";
import Button from "components/Basic/Button";
import { useDispatch, useSelector } from "react-redux";
import { signIn, signUp } from "redux/actions/auth";
import { StoreState } from "../../../setup/store";
import { CircularProgress } from "@material-ui/core";
import { validateEmail, validatePassword } from "helpers/utils";
import PasswordValidator from "./PasswordValidator";
import SocialAuthButtons from "./SocialAuthButtons";
import { checkAuth } from "redux/actions/auth/checkAuth";
import { SET_SIGN_IN_RESPONSE, SET_SIGN_UP_ERROR } from "redux/reducers/user";
import { useTranslation } from "react-i18next";
import Disclaimer from "../Disclaimer";

type StepPayload = {
	email?: string;
	password?: string;
	confirmPassword?: string;
};

const SignUpStep1 = () => {
	const history = useHistory();
	const [inputValues, setInputValues] = useState<StepPayload>({});
	const [errors, setErrors] = useState<StepPayload>({});
	const { t } = useTranslation("auth");
	const dispatch = useDispatch();
	const { signInResponse, signUpError, signInError, authLoading } = useSelector(
		(state: StoreState) => state.user
	);
	const classes = useStyles();

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === "Enter") {
			handleSubmitClick();
		}
	};

	const handleSubmitClick = () => {
		const { email, password, confirmPassword } = inputValues;
		let errors: { [index: string]: string } = {};
		if (!email || !validateEmail(email)) {
			errors = { ...errors, email: t("invalidEmail") };
		}

		if (!password || !validatePassword(password)) {
			errors = {
				...errors,
				password: t("invalidPassword"),
			};
		}

		if (confirmPassword !== password) {
			errors = { ...errors, confirmPassword: t("passwordsDoNotMatch") };
		}

		setErrors({ ...errors });

		if (Object.keys(errors).length === 0 && email && password) {
			dispatch(signUp(email, password));
		}
	};

	useEffect(() => {
		const { email, password } = inputValues;
		if (signUpError && email && password) {
			if (signUpError.code === "UsernameExistsException") {
				dispatch(signIn(email || "", password));
			}
		}
	}, [signUpError]);

	useEffect(() => {
		if (signInError?.code === "UserNotConfirmedException") {
			history.push("signup/verification");
		} else if (signInError?.code === "NotAuthorizedException") {
			setErrors({
				...errors,
				email: t("emailAlreadyInUse"),
			});
		} else {
			setErrors({});
		}
	}, [signInError]);

	useEffect(() => {
		if (signInResponse) {
			setErrors({
				...errors,
				email: t("emailAlreadyInUse"),
			});
		}
	}, [signInResponse]);

	useEffect(() => {
		dispatch(
			checkAuth((user, userData) => {
				if (user) {
					history.push("/");
				} else if (!userData?.emailConfirmed && userData?.email) {
					history.push("signup/verification");
				} else if (userData && !userData?.email) {
					history.push("/signup/general-info");
				}
			})
		);

		return function cleanup() {
			dispatch({
				type: SET_SIGN_UP_ERROR,
				payload: {},
			});
			dispatch({
				type: SET_SIGN_IN_RESPONSE,
				payload: undefined,
			});
		};
	}, []);

	return (
		<>
			<div className={classes.inputContainer}>
				<span className={classes.inputHeader}>{t("yourEmail")}</span>
				<Input
					autoFocus
					name="email"
					error={errors.email}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder="example@mail.com"
				/>
			</div>

			<div className={classes.inputContainer}>
				<span className={classes.inputHeader}>{t("createYourPassword")}</span>
				<Input
					name="password"
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder={t("typeYourPassword")}
					type="password"
				/>
			</div>

			<PasswordValidator password={inputValues.password} />

			<div className={classes.inputContainer}>
				<span className={classes.inputHeader}>{t("passwordConfirmation")}</span>
				<Input
					name="confirmPassword"
					error={errors.confirmPassword}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder={t("retypePassword")}
					type="password"
				/>
			</div>

			<SocialAuthButtons authType="signup" />

			<div className={classes.buttonContainer}>
				<Button disabled={authLoading} onClick={handleSubmitClick}>
					{authLoading ? <CircularProgress size={20} /> : t("startSocialSuccess")}
				</Button>
			</div>

			<Disclaimer />

		</>
	);
};

export default SignUpStep1;

import { useTheme } from "@material-ui/core";
import { CameraAlt } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { PlaceholderPicture } from "../../assets";
import { getImage } from "helpers/utils";
import Team from "types/User/Team";
import { CSSProperties } from "react";
import clsx from "clsx";
import { v4 } from "uuid";

interface Props {
	team: Team;
	size?: number;
	placeholderIconSize?: number;
	placeholderImage?: boolean;
	style?: CSSProperties;
	className?: string;
}

const useStyles = makeStyles(() => ({
	picture: {
		borderRadius: "50%",
		marginRight: 12,
	},

	placeholderIconContainer: {
		backgroundColor: "white",
		borderRadius: "50%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginRight: 12,
	},
}));

const TeamPicture = (props: Props) => {
	const { team, size, placeholderImage, placeholderIconSize, style, className } = props;

	const classes = useStyles();
	const theme = useTheme();
	const imageId = `${team._id}-picture-${v4()}`;

	const handleImageLoadError = () => {
		const imageElement = document.getElementById(imageId) as HTMLImageElement;
		if (!imageElement) return;

		imageElement.src = PlaceholderPicture;
	};

	return (
		<>
			{team.image || placeholderImage ? (
				<img
					id={imageId}
					style={{
						width: size || 25,
						height: size || 25,
						...style,
					}}
					className={clsx(classes.picture, className)}
					onError={handleImageLoadError}
					src={team.image ? getImage(team.image) : PlaceholderPicture}
				/>
			) : (
				<div
					style={{
						width: size || 25,
						height: size || 25,
						...style,
					}}
					className={clsx(classes.placeholderIconContainer, className)}
				>
					<CameraAlt
						style={{
							color: theme.palette.text.secondary,
							fontSize: placeholderIconSize || 16,
						}}
					/>
				</div>
			)}
		</>
	);
};

export default TeamPicture;

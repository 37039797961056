import { Dialog } from "@material-ui/core";
import { useEffect, useState } from "react";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import EditIconSvg from "assets/SVG/EditIconSvg";
import useAttachmentPreviewStyles from "./styles";
import ReactPlayer from "react-player/lazy";
import { Media } from "types/Task";
import { getAttachmentPath } from "./utils";
import { useTranslation } from "react-i18next";

interface Props {
	attachment: Media | undefined;
	open: boolean;
	disableActions?: boolean;
	onClose: () => void;
	onEditClick?: () => void;
	onDeleteClick?: () => void;
}

const AttachmentPreviewDialog = (props: Props) => {
	const classes = useAttachmentPreviewStyles();
	const [path, setPath] = useState<string>();
	const [videoEditMode, setVideoEditMode] = useState<boolean>(false);
	const { t } = useTranslation();
	const { attachment, open, disableActions, onClose, onDeleteClick, onEditClick } = props;

	const handleDeleteClick = () => {
		onDeleteClick && onDeleteClick();
		onClose();
	};

	const handleEditClick = () => {
		if (!attachment) return;

		if (attachment.type === "image") {
			onEditClick && onEditClick();
		} else {
			setVideoEditMode(!videoEditMode);
		}
	};

	const getPath = async () => {
		if (attachment) {
			const path = await getAttachmentPath(attachment);
			setPath(path);
		}
	};

	useEffect(() => {
		if (open && attachment) {
			getPath();
		}
	}, [attachment]);

	useEffect(() => {
		if (!open) {
			setVideoEditMode(false);
		}
	}, [open]);

	if (!attachment) return <></>;

	return (
		<Dialog
			classes={{
				paper: classes.paper,
			}}
			onClose={onClose}
			open={open}
		>
			<div className={classes.container}>
				{!disableActions && (
					<div className={classes.actionsContainer}>
						{onEditClick && attachment.type !== "video" && (
							<div
								style={{
									opacity: videoEditMode ? 1 : 0.6,
								}}
								onClick={handleEditClick}
								className={classes.action}
							>
								<EditIconSvg color="white" size={18} />
								<span style={{ paddingTop: 8 }}>Edit</span>
							</div>
						)}
						<div onClick={handleDeleteClick} className={classes.action}>
							<CloseIconSvg color="white" size={18} />
							<span style={{ paddingTop: 8 }}>{t("delete")}</span>
						</div>
					</div>
				)}

				{attachment.type === "image" && path && (
					<img alt="preview" className={classes.image} src={path} />
				)}

				{attachment.type === "video" && (
					<>
						<ReactPlayer url={path} width={"100%"} height={"100%"} controls={true} />
					</>
				)}
			</div>
		</Dialog>
	);
};

export default AttachmentPreviewDialog;

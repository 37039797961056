import { Box, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../setup/store";
import Task from "types/Task";
import Team from "types/User/Team";
import useSummaryStyles from "../../styles/summary";
import TaskAssignee from "../components/Assignee";

interface Props {
	selectedTask: Task;
}

const SummaryAssignments = (props: Props) => {
	const classes = useSummaryStyles({});
	const { t } = useTranslation("tasks");
	const [currentTeam, setCurrentTeam] = useState<Team>();
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { selectedTask } = props;
	const { assignees } = selectedTask;

	useEffect(() => {
		if (currentTeamId) {
			setCurrentTeam(userData?.teams.find((team) => team._id === currentTeamId));
		}
	}, [currentTeamId, userData]);

	if (!currentTeam) return <></>;

	return (
		<Box style={{ minHeight: 175 }}>
			<Typography className={classes.sectionLabel}>{t("assignments")}:</Typography>

			{!Boolean(assignees.length) && (
				<div className={classes.emptySectionMessage}>{t("noAssignees")}</div>
			)}

			{assignees.map((assignee, index) => (
				<TaskAssignee
					key={index}
					style={{
						paddingLeft: 0,
						marginLeft: 0,
						borderRadius: 0,
						borderBottom: "1px solid #D6DADE",
					}}
					teamUser={{
						role:
							currentTeam.users.find(
								(teamUser) => typeof teamUser.user === "string" && teamUser.user === assignee._id
							)?.role || "writer",
						user: assignee,
					}}
				/>
			))}
		</Box>
	);
};

export default SummaryAssignments;

import axios from "axios";
import { Dispatch } from "react";

import { setSnackbarState } from "../snackBar";
import config from "config";
import { CreatePostFunc } from "types/ReduxActions";
import {
	CREATE_POST_ERROR,
	CREATE_POST_LOADING,
	CREATE_POST_SUCCESS,
} from "../../reducers/posting";
import { StoreState } from "../../../setup/store";
import { uploadMediaArray } from "../media/upload";
import { Media } from "types/Task";

export type CreatePostPayload = {
	content: string;
	media?: Media[];
	schedule?: string;
	draft?: boolean;
	campaign?: string;
	task?: string | null;
};

export const handleSubmitPostError = (e: any) => {
	return (dispatch: Dispatch<any>) => {
		const { error } = e.response?.data || {};
		console.log(e.response);
		dispatch(
			setSnackbarState(true, "error", error && error !== "ServerError" ? error : "CreatePostError")
		);
	};
};

export const createPost: CreatePostFunc = (socialProfiles, payload, onProgressUpdate, callback) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId, currentTeamRole } = getState().teams;
		const { content, draft, schedule, campaign, task, media } = payload;

		dispatch({
			type: CREATE_POST_LOADING,
			payload: true,
		});

		try {
			const uploadedMedia = media ? await uploadMediaArray(media, onProgressUpdate) : [];

			if (!schedule && !draft && currentTeamRole !== "writer") {
				dispatch(setSnackbarState(true, "success", "Your post is being processed..."));
				dispatch({
					type: CREATE_POST_LOADING,
					payload: false,
				});

				if (callback) callback(true);
			}

			const result = await axios.post(
				`${config.apiUrl}/posts`,
				{
					content,
					draft,
					socialProfiles,
					media: uploadedMedia,
					schedule,
					campaign,
					task,
				},
				{ params: { teamId: currentTeamId } }
			);

			dispatch({
				type: CREATE_POST_SUCCESS,
				payload: result.data,
			});

			if (currentTeamRole === "writer") {
				dispatch(setSnackbarState(true, "success", "WriterCreatePostSuccess"));
			}

			dispatch(setSnackbarState(true, "success", "AdminCreatePostSuccess"));

			if (callback) callback(true);
		} catch (e) {
			dispatch(handleSubmitPostError(e));
			dispatch({
				type: CREATE_POST_ERROR,
				payload: e.response?.data || e,
			});
			if (callback) callback(false);
		}
	};
};

import Notification from "types/Notification";
import { ReduxAction } from "types/ReduxActions";

export interface NotificationsReducerState {
	modalNotifications: Notification[];
	unreadNotifications: Notification[];
	readNotifications: Notification[];
	totalCount: number;
	totalReadCount: number;
	totalUnreadCount: number;
	loading: boolean;
}

let initialState = {
	modalNotifications: [],
	unreadNotifications: [],
	readNotifications: [],
	totalCount: 0,
	totalReadCount: 0,
	totalUnreadCount: 0,
	loading: false,
};

export const LIST_MODAL_NOTIFICATIONS = "LIST_MODAL_NOTIFICATIONS";
export const LIST_READ_NOTIFICATIONS = "LIST_READ_NOTIFICATIONS";
export const LIST_UNREAD_NOTIFICATIONS = "LIST_UNREAD_NOTIFICATIONS";
export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";

export default function notificationsReducer(
	state: NotificationsReducerState = initialState,
	action: ReduxAction
) {
	switch (action.type) {
		case LIST_MODAL_NOTIFICATIONS: {
			const { notifications, totalCount } = action.payload;
			return {
				...state,
				modalNotifications: notifications,
				totalCount,
				loading: false,
			};
		}

		case LIST_READ_NOTIFICATIONS: {
			const { notifications, totalCount } = action.payload;

			return {
				...state,
				totalReadCount: totalCount,
				readNotifications: notifications,
				loading: false,
			};
		}

		case LIST_UNREAD_NOTIFICATIONS: {
			const { notifications, totalCount } = action.payload;

			return {
				...state,
				totalUnreadCount: totalCount,
				unreadNotifications: notifications,
				loading: false,
			};
		}

		case NOTIFICATIONS_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}

import { Box } from "@material-ui/core";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchIconSvg from "assets/SVG/SearchIconSvg";
import SearchInput from "components/Basic/Inputs/SearchInput";
import useWatchTeamStyles from "../../styles/watch-team";

interface Props {
	onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
	searchValue: string;
	total: number;
	section: string;
}

const TasksSectionHeader = (props: Props) => {
	const [searchEnabled, setSearchEnabled] = useState<boolean>(false);
	const { onSearchChange, searchValue, total, section } = props;

	const classes = useWatchTeamStyles();
	const { t } = useTranslation("tasks");

	return (
		<Box display="flex" alignItems="center" style={{ marginBottom: 16 }}>
			<Box className={classes.sectionLabel}>
				{t(section)} {`- ${total}`}
			</Box>
			<Box width="fit-content" marginLeft="auto">
				{searchEnabled ? (
					<SearchInput
						inputStyle={{ width: 120, paddingLeft: 0 }}
						iconStyle={{ paddingRight: 0 }}
						iconSize={15}
						iconColor="#868C91"
						style={{
							borderRadius: 0,
							border: "none",
							borderBottom: "1px solid #B6BFC4",
							width: 170,
							height: 30,
						}}
						onChange={onSearchChange}
						value={searchValue}
					/>
				) : (
					<Box onClick={() => setSearchEnabled(true)} style={{ cursor: "pointer" }}>
						<SearchIconSvg size={15} color="#868C91" />
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default TasksSectionHeader;

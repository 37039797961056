import { Dispatch } from "react";
import { AnyAction } from "redux";
import { StoreState } from "setup/store";
import { SET_SNACKBAR_STATE } from "../reducers/snackBar";

export const setSnackbarState = (
	open: boolean,
	severity: "success" | "error" | "warning",
	message: string,
	priority?: number
) => {
	return async (dispatch: Dispatch<AnyAction>, getState: () => StoreState) => {
		const currentSnackbar = getState().snackbar;

		if (open && currentSnackbar.open && (priority || 1) < currentSnackbar.priority) {
			return;
		}

		dispatch({
			type: SET_SNACKBAR_STATE,
			payload: { open, severity, message, priority },
		});
	};
};

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		padding: 24,
		display: "flex",
		flexDirection: "column",
		paddingTop: 42,
		backgroundColor: "white",
		borderRadius: 15,
		marginBottom: 16,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
	},

	textContent: {
		color: theme.palette.text.primary,
		fontSize: 14,
		whiteSpace: "pre-line",
		width: "50%",
	},

	userProfile: {
		width: "fit-content",
		marginLeft: "auto",
		display: "flex",
		flexDirection: "row",
	},

	socialDetails: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "fit-content",
		marginLeft: "auto",
	},

	socialIcon: {
		marginRight: 12,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: 25,
	},

	username: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
	},

	attachmentsContainer: {
		display: "flex",
		flexDirection: "row",
	},

	attachment: {
		height: 70,
		maxWidth: 70,
		objectFit: "cover",
		borderRadius: 4,
		marginRight: 16,
	},

	postActions: {
		display: "flex",
		flexDirection: "column",
		marginLeft: "auto",
	},

	dateContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 8,
		marginLeft: "auto",
		width: "fit-content",
		fontWeight: 500,
		color: "#4B7FA3",
	},

	buttonsContainer: {
		display: "flex",
		flexDirection: "row",
	},

	lightBlueButton: {
		backgroundColor: "#B6D8F0",
		width: 160,
		fontFamily: "Avenir",
		"&:hover": {
			backgroundColor: "#91bcdb !important",
		},
		color: "#2B4455",
	},
}));

export default useStyles;

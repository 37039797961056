import { makeStyles } from "@material-ui/core";

const useHeaderStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		display: "flex",
		marginTop: 8,
		flexDirection: "row",
		alignItems: "center",
	},

	rightSection: {
		marginLeft: "auto",
		width: "fit-content",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	icon: {
		height: 25,
		marginLeft: 16,
		cursor: "pointer",
	},

	filterTabsContainer: {
		width: "100%",
		display: "flex",
		marginTop: 16,
		flexDirection: "row",
		alignItems: "center",
		flexWrap: "wrap",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
}));

export default useHeaderStyles;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEn from "./locales/en/common.json";
import workspacesEn from "./locales/en/workspaces.json";
import tasksEn from "./locales/en/tasks.json";
import socialProfilesEn from "./locales/en/socialProfiles.json";
import chatEn from "./locales/en/chat.json";
import campaignEn from "./locales/en/campaigns.json";
import settingsEn from "./locales/en/settings.json";
import calendarEn from "./locales/en/calendar.json";
import notificationsEn from "./locales/en/notifications.json";
import postsEn from "./locales/en/posts.json";
import authEn from "./locales/en/auth.json";
import replyEn from "./locales/en/reply.json";
import listenEn from "./locales/en/listen.json";

const resources = {
	en: {
		common: commonEn,
		workspaces: workspacesEn,
		tasks: tasksEn,
		socialProfiles: socialProfilesEn,
		chat: chatEn,
		campaigns: campaignEn,
		settings: settingsEn,
		calendar: calendarEn,
		posts: postsEn,
		notifications: notificationsEn,
		auth: authEn,
		reply: replyEn,
		listen: listenEn
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "en",
		keySeparator: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;

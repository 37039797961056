import { Box, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "components/Basic/Button";
import { posts } from "../../../mock/placeholderData";
import { updatePost, UpdatePostPayload } from "redux/actions/posts/update";
import { LIST_POSTS } from "redux/reducers/posting";
import { StoreState } from "../../../setup/store";
import Post from "types/Post";
import { ProfileType } from "types/SocialProfile";

interface Props {
	post: Post;
	onDeleteClick: (post: Post) => void;
}

const PostActions = ({ post, onDeleteClick }: Props) => {
	const { t } = useTranslation("posts");
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [returned, setReturned] = useState<boolean>(false);
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const { currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const { posts } = useSelector((state: StoreState) => state.posts);
	const isAdmin = currentTeamRole === "admin" || currentTeamRole === "owner";
	const history = useHistory();
	const dispatch = useDispatch();

	const handleEditClick = () => {
		history.push(`/posts/edit/${post._id}`);
	};

	const handleAdminAction = (status: "approved" | "returned") => {
		if (!post.socialProfiles) return;
		let profiles: { type: ProfileType; _id: string }[] = [];

		post.socialProfiles?.forEach((profile) => {
			if (!profile._id || !profile.type) return;
			profiles.push({ type: profile.type, _id: profile._id });
		});

		let payload: UpdatePostPayload = {
			status,
			media: post.media || [],
		};

		dispatch(
			updatePost(
				post._id!,
				profiles,
				payload,
				() => {},
				(success) => {
					setSubmitLoading(false);
					if (success) {
						if (status === "returned") {
							setReturned(true);
						} else {
							setSubmitted(true);
						}
					}

					if (status === "returned") {
						dispatch({
							type: LIST_POSTS,
							payload: posts.map((unapprovedPost) => {
								if (unapprovedPost._id === post._id) {
									return {
										...unapprovedPost,
										status: "returned",
									};
								} else return unapprovedPost;
							}),
						});
					}
				}
			)
		);
	};

	return (
		<Box width="fit-content" marginLeft="auto" display="flex" flexDirection="column">
			{!isAdmin && (
				<Button
					secondary
					disabled={submitLoading || submitted}
					onClick={() => onDeleteClick(post)}
					style={{ width: 120 }}
				>
					{t("remove")}
				</Button>
			)}

			<Button
				secondary
				disabled={submitLoading || submitted}
				onClick={handleEditClick}
				style={{
					backgroundColor: !isAdmin ? "#B6D8F0" : "#D9E3EB",
					width: 120,
					marginTop: !isAdmin ? 12 : 0,
				}}
			>
				{t("edit")}
			</Button>

			{isAdmin && (
				<>
					<Button
						disabled={submitLoading || submitted}
						secondary
						onClick={() => handleAdminAction("returned")}
						style={{ marginTop: 12, width: 120 }}
					>
						{returned ? t("returned") : t("returnToWriter")}
					</Button>
					<Button
						disabled={submitLoading || submitted || !post.scheduledAt}
						onClick={() => handleAdminAction("approved")}
						style={{ width: 120, marginTop: 12 }}
					>
						{submitLoading ? (
							<CircularProgress size={20} />
						) : submitted ? (
							t("addedToSchedule")
						) : (
							t("addToSchedule")
						)}
					</Button>
				</>
			)}
		</Box>
	);
};

export default PostActions;

import { withStyles, Theme, createStyles, Switch as MUISwitch } from "@material-ui/core";

const Switch = withStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 38,
			height: 18,
			padding: 0,
			display: "flex",
		},
		switchBase: {
			padding: 2,
			paddingLeft: 4,
			color: theme.palette.grey[500],
			"&$checked": {
				transform: "translateX(18px)",
				color: theme.palette.common.white,
				"& + $track": {
					opacity: 1,
					backgroundColor: theme.palette.primary.main,
					borderColor: theme.palette.primary.main,
				},
			},
		},
		thumb: {
			width: 14,
			height: 14,
			boxShadow: "none",
			backgroundColor: "#D9E3EB",
		},
		track: {
			border: `1px solid ${theme.palette.secondary.main}`,
			borderRadius: 20 / 2,
			opacity: 1,
			backgroundColor: theme.palette.common.white,
		},
		checked: {},
	})
)(MUISwitch);

export default Switch;

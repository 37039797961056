import axios from "axios";
import { Dispatch } from "react";
import config from "config";
import { StoreState } from "../../../setup/store";
import Post from "types/Post";
import { ListTasksFunc, ListUserTasksFunc } from "types/ReduxActions";
import Task, { TaskStatus } from "types/Task";
import {
	LIST_ACTIVE_TASKS,
	LIST_CURRENT_USER_TASKS,
	LIST_TASKS,
	LIST_TASKS_LOADING,
	SET_CHAT_TASKS_LOADING,
	SET_PRODUCTIVITY,
	SET_PRODUCTIVITY_LOADING,
	SET_SELECTED_TASK,
	SET_TASKS_COUNT,
	SET_TASKS_SEARCH_LOADING,
	SET_TASKS_SEARCH_RESULT,
} from "../../reducers/tasks";
import { setSnackbarState } from "../snackBar";

export type TasksFilters = {
	status?: TaskStatus;
	startDate?: string;
	endDate?: string;
	urgent?: boolean;
	taskIds?: string[];
	from?: number;
	pageLimit?: number;
	descending?: boolean;
	searchValue?: string;
};

/**
 * @param count if provided, only the total count of team notes will be returned
 */
export const listTasks: ListTasksFunc = (count, filters) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const { tasks } = getState().tasks;

		if (!filters?.from) {
			dispatch({
				type: filters?.searchValue ? SET_TASKS_SEARCH_LOADING : LIST_TASKS_LOADING,
				payload: true,
			});
		}

		let params: { [index: string]: any } = {
			teamId: currentTeamId,
			...filters,
		};

		if (count) {
			params.count = count;
		}

		try {
			const result = await axios.get(`${config.apiUrl}/tasks`, {
				params,
			});

			if (count) {
				dispatch({
					type: SET_TASKS_COUNT,
					payload: result.data.count,
				});
			} else {
				dispatch({
					type: filters?.searchValue ? SET_TASKS_SEARCH_RESULT : LIST_TASKS,
					payload: {
						tasks: filters?.from
							? filters.descending
								? [...tasks, ...result.data.tasks]
								: [...result.data.tasks, ...tasks]
							: result.data.tasks,
						count: result.data.count,
					},
				});
			}
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "ListTasksError"));

			dispatch({
				type: LIST_TASKS_LOADING,
				payload: false,
			});
		}
	};
};

export type ListActiveTasksParams = {
	unreadComments?: boolean;
	currentUserTasks?: boolean;
	excludeFinished?: boolean;
	from?: number;
	pageLimit?: number;
};

export const listActiveTasks = (params: ListActiveTasksParams) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;
		const { currentTeamId } = getState().teams;
		const { currentUserTasks: oldCurrentUserTasks } = getState().tasks;
		const { isChatOpen } = getState().chat;
		if (!userData) return;

		const { currentUserTasks, from } = params;

		if (!from) {
			dispatch({
				type: isChatOpen ? SET_CHAT_TASKS_LOADING : LIST_TASKS_LOADING,
				payload: true,
			});
		}

		try {
			const result = await axios.get(`${config.apiUrl}/tasks/watch`, {
				params: { ...params, teamId: currentTeamId },
			});

			const { total, tasks } = result.data;
			let payload: { [index: string]: any } = {};

			if (currentUserTasks) {
				payload.currentUserTasks = from ? [...oldCurrentUserTasks, ...tasks] : tasks;
				payload.totalCurrentUserTasks = total;
			} else {
				payload.activeTasks = tasks;
			}

			dispatch({
				type: currentUserTasks ? LIST_CURRENT_USER_TASKS : LIST_ACTIVE_TASKS,
				payload,
			});
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "ListTasksError"));
		}
	};
};

export type ListUserTasksParams = {
	from?: number;
	pageLimit?: number;
	status?: TaskStatus;
	searchValue?: string;
};

export const listUserTasks: ListUserTasksFunc = (userId, params, cb) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		if (!currentTeamId) return;

		try {
			const result = await axios.get(`${config.apiUrl}/tasks/users/${userId}`, {
				params: { ...params, teamId: currentTeamId },
			});

			const { tasks, count } = result.data;
			cb(tasks, count);
		} catch (e) {
			console.log(e?.response);
			dispatch(setSnackbarState(true, "error", "GetUserTasksError"));
		}
	};
};

export const getTeamProductivity = () => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		dispatch({
			type: SET_PRODUCTIVITY_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(`${config.apiUrl}/tasks/productivity`, {
				params: { teamId: currentTeamId },
			});

			dispatch({
				type: SET_PRODUCTIVITY,
				payload: result.data.tasks,
			});
		} catch (e) {
			console.log(e.response.data);
			dispatch(setSnackbarState(true, "error", "GetProductivityError"));
		}
	};
};

export const listTaskPosts = (task: Task, cb: (posts: Post[]) => void) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		try {
			if (task.posts.length > 0) {
				const result = await axios.get(`${config.apiUrl}/posts`, {
					params: { ids: task.posts, teamId: currentTeamId },
				});
				cb(result.data.posts);
			} else {
				cb([]);
			}
		} catch (e) {
			console.log(e);
			dispatch(setSnackbarState(true, "error", "GetTaskPostsError"));
		}
	};
};

export const getTaskById = (id: string, cb: (task?: Task) => void) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;

		try {
			const result = await axios.get(`${config.apiUrl}/tasks/${id}`, {
				params: { teamId: currentTeamId },
			});

			cb(result.data.task);
		} catch (e) {
			console.log(e);
			cb(undefined);
			dispatch(setSnackbarState(true, "error", "GetTaskError"));
		}
	};
};

export const setSelectedTask = (task: Task | undefined) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_SELECTED_TASK,
			payload: task,
		});
	};
};

const PlusButtonSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22.4C17.7438 22.4 22.4 17.7438 22.4 12C22.4 6.25624 17.7438 1.6 12 1.6C6.25624 1.6 1.6 6.25624 1.6 12C1.6 17.7438 6.25624 22.4 12 22.4ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
				fill={color || "white"}
			/>
			<path
				d="M12 7.19995C11.6212 7.19995 11.3142 7.50696 11.3142 7.88567V11.3142H7.88567C7.50696 11.3142 7.19995 11.6212 7.19995 12C7.19995 12.3787 7.50696 12.6857 7.88567 12.6857H11.3142V16.1142C11.3142 16.4929 11.6212 16.7999 12 16.7999C12.3787 16.7999 12.6857 16.4929 12.6857 16.1142V12.6857H16.1142C16.4929 12.6857 16.7999 12.3787 16.7999 11.9999C16.7999 11.6212 16.4929 11.3142 16.1142 11.3142H12.6857V7.88567C12.6857 7.50696 12.3787 7.19995 12 7.19995Z"
				fill={color || "white"}
			/>
		</svg>
	);
};

export default PlusButtonSvg;

import { makeStyles } from "@material-ui/core";

const useHashtagsDrawerStyles = makeStyles((theme) => ({
	hashtagsDrawerContainer: {
		position: "absolute",
		right: 0,
		top: 0,
		display: "flex",
		zIndex: 9999,
		overflow: "hidden",
	},

	buttonsContainer: {
		paddingTop: 32,
		paddingRight: 16,
		display: "flex",
		flexDirection: "column",
	},

	innerDrawerButtonsContainer: {
		position: "absolute",
		top: -16,
		left: 16,
	},

	hashtagsDrawer: {
		backgroundColor: "white",
		boxShadow: "0px 4px 24px rgba(217, 227, 235, 0.4)",
		height: "100vh",
		transition: "0.3s",
		paddingTop: 32,
		paddingBottom: 32,
	},

	iconButton: {
		border: "1px solid #D5E1F5",
		backgroundColor: "white",
		boxShadow: "0px 4px 24px rgba(217, 227, 235, 0.4)",
		padding: 6,
		borderRadius: 6,
		"&:hover": {
			backgroundColor: "white",
		},
	},

	hashtagListItem: {
		width: "100%",
		padding: 12,
		paddingLeft: 32,
		paddingRight: 32,
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		"&:hover": {
			backgroundColor: "#fafafa",
		},
	},

	hashtagName: {
		fontWeight: 500,
		marginBottom: 4,
		fontSize: 14,
	},

	hashtagsStats: {
		color: theme.palette.text.hint,
		fontSize: 12,
	},
}));

export default useHashtagsDrawerStyles;

import { makeStyles } from "@material-ui/core";

interface Props {
	selectable?: boolean;
	selected?: boolean;
}

const useBillingMethodStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		marginTop: 12,
		borderRadius: 8,
		backgroundColor: ({ selected }: Props) => (selected ? "#F1F4F8" : ""),
		padding: ({ selectable }: Props) => (selectable ? "0px 16px 0px 16px" : ""),
		"&:hover": {
			cursor: ({ selectable }: Props) => (selectable ? "pointer" : ""),
			backgroundColor: ({ selectable }: Props) => (selectable ? "#fafafa" : ""),
		},
	},

	methodTitle: {
		color: theme.palette.text.primary,
		marginBottom: 6,
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	inputContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 4,
		paddingLeft: 32,
		paddingRight: 32,
		height: 40,
		width: "90%",
	},

	editIconContainer: {
		marginLeft: 10,
		height: 40,
		width: 40,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		borderRadius: 8,
		"&:hover": {
			backgroundColor: "#dedede",
		},
		transition: "0.3s",
	},

	hiddenValueCircle: {
		height: 6,
		width: 6,
		borderRadius: "50%",
		background: "#868C91",
		marginRight: 5,
	},

	hiddenValuesContainer: {
		marginRight: 18,
		display: "flex",
		flexDirection: "row",
	},

	paymentMethodTypeContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "fit-content",
		marginLeft: "auto",
		textTransform: "capitalize",
	},

	paymentMethodImage: {
		width: 32,
		marginRight: 8,
	},
}));

export default useBillingMethodStyles;

import { makeStyles } from "@material-ui/core";

const useSubscriptionInfoDialogStyles = makeStyles(() => ({
	subscriptionInfoContainer: {
		padding: "42px 24px 122px 24px",
		width: "50vw",
		maxWidth: 650,
		position: "relative",
	},

	paper: {
		maxWidth: 650,
		background: "white",
	},

	changeTitle: {
		fontSize: 14,
		fontWeight: 700,
		marginBottom: 8,
	},

	changeText: {
		fontSize: 14,
		marginBottom: 20,
	},

	actionButtonsContainer: {
		position: "absolute",
		bottom: 42,
		right: 24,
		display: "flex",
		gap: 12,
	},

	priceSummaryContainer: {
		width: "55%",
		marginLeft: "auto",
	},

	hintTextContainer: {
		marginTop: 32,
		fontSize: 12,
		marginLeft: 24,
		maxWidth: "60%",
	},
}));

export default useSubscriptionInfoDialogStyles;

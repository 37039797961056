import { useEffect, useMemo, useState } from "react";
import { formatDate } from "helpers/dateAndTime";
import { networkIcons } from "types/Network";
import Post from "types/Post";
import useStyles from "./styles/search-post";
import Highlighter from "react-highlight-words";
import { Box, Fade } from "@material-ui/core";
import Button from "components/Basic/Button";
import CalendarIconSvg from "assets/SVG/CalendarIconSvg";
import { useTranslation } from "react-i18next";
import MediaAttachment from "../../components/Media/MediaAttachment";
import { setFullScreenMediaPreview, setPostPreviewDialog } from "redux/actions/modals";
import { useDispatch } from "react-redux";
import SocialProfilePicture from "../../components/Media/SocialProfilePicture";
import NetworkIcon from "assets/SVG/NetworkIconSvg";

interface Props {
	post: Post;
	searchValue: string;
}

const SearchResultPost = (props: Props) => {
	const classes = useStyles();
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { t } = useTranslation("posts");
	const { post, searchValue } = props;

	const socialProfile = post.socialProfiles?.[0];
	const handleAttachmentClick = (index: number) => {
		dispatch(
			setFullScreenMediaPreview({
				open: true,
				media: post.media || [],
				initialSelectedIndex: index,
			})
		);
	};

	const handleEditClick = () => {
		dispatch(setPostPreviewDialog(true, false, post));
	};

	// Post content overflow clamping
	// TODO: Can this just be replaced with ClampLines?
	const { clampedContent, isClamped } = useMemo(() => {
		const { content } = post;
		if (isExpanded) {
			return { clampedContent: content, isClamped: true };
		}
		if (content && content.length > 200) {
			if (content.indexOf(searchValue) > 100) {
				const endingDots = content.length > 100 + content.indexOf(searchValue) + 50 ? "..." : "";
				return {
					clampedContent:
						"..." + content.substr(99, content.indexOf(searchValue) + 50) + endingDots,
					isClamped: true,
				};
			} else {
				const endingDots = content.length > content.indexOf(searchValue) + 50 ? "..." : "";
				return {
					clampedContent: content.substr(0, content.indexOf(searchValue) + 100) + endingDots,
					isClamped: true,
				};
			}
		} else {
			return { clampedContent: content, isClamped: false };
		}
	}, [post, searchValue, isExpanded]);

	return (
		<div
			style={{
				maxHeight: isExpanded ? 1000 : 150,
				height: isExpanded ? "fit-content" : 150,
			}}
			className={classes.container}
		>
			<div style={{ alignItems: "center" }} className={classes.linearContainer}>
				<div className={classes.socialDetailsContainer}>
					{socialProfile && (
						<NetworkIcon network={socialProfile.network} size={25} strokeWidth={2} />
					)}

					{socialProfile && (
						<SocialProfilePicture
							profile={socialProfile}
							style={{ height: 30, width: 30, marginRight: 12, marginLeft: 6 }}
						/>
					)}
					<span className={classes.username}>
						{socialProfile?.displayName || socialProfile?.username}
					</span>
				</div>
				<div className={classes.dateContainer}>
					<CalendarIconSvg size={20} />
					<span style={{ marginLeft: 8 }}>
						{formatDate(post.publishedAt || (post.scheduledAt as string))}
					</span>
				</div>
			</div>
			<div style={{ marginTop: 16 }} className={classes.linearContainer}>
				<div style={{ width: "50%" }}>
					<div className={classes.postContent}>
						<Highlighter
							highlightClassName={classes.highlighter}
							searchWords={[searchValue]}
							autoEscape={true}
							textToHighlight={clampedContent || ''}
						/>
					</div>

					<Fade in={isExpanded} unmountOnExit timeout={{ enter: 500, exit: 0 }}>
						<Box>
							<div className={classes.attachmentsContainer}>
								{/* TODO: Handle attachment click */}
								{post.media?.map((attachment, index) => (
									<MediaAttachment
										key={index}
										onAttachmentClick={() => handleAttachmentClick(index)}
										thumbnailKey={attachment.thumbnail}
										attachment={attachment.value}
										type={attachment.type}
										style={{ width: 70, height: 70 }}
									/>
								))}
							</div>
						</Box>
					</Fade>

					{(isClamped || Boolean(post.media?.length)) && (
						<div onClick={() => setIsExpanded(!isExpanded)} className={classes.viewMoreText}>
							{isExpanded ? t("seeLess") : t("seeMore")}
						</div>
					)}
				</div>
				<div className={classes.seeOriginalButton}>
					<Button onClick={handleEditClick} style={{ width: 150 }} secondary>
						{t("seeOriginal")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default SearchResultPost;

import { CircularProgress, Dialog } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ProfilePicturePlaceholder } from "../../../assets";
import Button from "components/Basic/Button";
import CheckBox from "components/Basic/Inputs/CheckBox";
import { useQuery } from "helpers/utils";
import {
	listFacebookPages,
	updateConnectedFacebookPages,
} from "redux/actions/socialProfiles/facebook";
import {
	listInstagramAccounts,
	updateConnectedInstagramAccounts,
} from "redux/actions/socialProfiles/instagram";
import {
	listLinkedInPages,
	updateConnectedLinkedInPages,
} from "redux/actions/socialProfiles/linkedin";
import { StoreState } from "../../../setup/store";
import SocialProfile from "types/SocialProfile";
import usePagesDialogStyles from "../styles/pages-selection-dialog";
import { getSocialProfileRoute } from "setup/router/utils";
import DialogHeader from "./components/DialogHeader";
import ResultHeader from "./components/ResultHeader";
import SubProfileItem from "./components/SubProfileItem";

export type SubProfileType = "page" | "instagram";

interface Props {
	currentProfile: SocialProfile;
	open: boolean;
	profileTypes: SubProfileType[];
	handleClose: () => void;
}

/** SubProfiles can be instagram accounts and/or pages linked to a Facebook account.
 * Same for LinkedIn pages as well
 */
const SubProfileSelectionDialog = (props: Props) => {
	const [selectedTab, setSelectedTab] = useState<SubProfileType>();
	const [checkedProfileIds, setCheckedProfileIds] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const [subProfiles, setSubProfiles] = useState<Array<SocialProfile & { pageId?: string }>>([]);
	const { profiles } = useSelector((state: StoreState) => state.socialProfiles);
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const classes = usePagesDialogStyles();
	const { currentProfile, open, profileTypes, handleClose } = props;

	const handleProfileClick = (socialId: string) => {
		if (checkedProfileIds.includes(socialId)) {
			checkedProfileIds.splice(checkedProfileIds.indexOf(socialId), 1);
			setCheckedProfileIds([...checkedProfileIds]);
		} else {
			setCheckedProfileIds([...checkedProfileIds, socialId]);
		}
	};

	const handleSelectAllClick = () => {
		if (checkedProfileIds.length === subProfiles.length) {
			setCheckedProfileIds([]);
		} else {
			setCheckedProfileIds([...subProfiles.map((item) => item.socialId!)]);
		}
	};

	const handleSubmit = () => {
		setSubmitLoading(true);
		if (currentProfile.network === "facebook") {
			if (selectedTab === "page") {
				dispatch(
					updateConnectedFacebookPages(currentProfile, checkedProfileIds, () => {
						setSubmitLoading(false);
						handleClose();
					})
				);
			} else if (selectedTab === "instagram") {
				let payload: { socialId: string; pageId: string }[] = [];

				// Find the checked item and get pageId from its object
				checkedProfileIds.forEach((checkedItem) => {
					const pageId = subProfiles.filter((item) => item.socialId === checkedItem)[0].pageId;
					if (!pageId) {
						return;
					}

					payload.push({
						socialId: checkedItem,
						pageId: pageId,
					});
				});
				dispatch(
					updateConnectedInstagramAccounts(currentProfile, payload, () => {
						setSubmitLoading(false);
						handleClose();
						history.push(getSocialProfileRoute(query.get("pid") as string));
					})
				);
			}
		} else if (currentProfile.network === "linkedin") {
			dispatch(
				updateConnectedLinkedInPages(currentProfile, checkedProfileIds, () => {
					setSubmitLoading(false);
					handleClose();
				})
			);
		}
	};

	useEffect(() => {
		if (!open) {
			return;
		}

		setLoading(true);
		if (currentProfile.network === "facebook") {
			if (selectedTab === "page") {
				dispatch(
					listFacebookPages(currentProfile._id, (result) => {
						setLoading(false);
						setSubProfiles([...result]);
					})
				);
			} else if (selectedTab === "instagram") {
				dispatch(
					listInstagramAccounts(currentProfile._id, (result) => {
						setLoading(false);
						setSubProfiles([...result]);
					})
				);
			}
		} else if (currentProfile.network === "linkedin") {
			if (selectedTab === "page") {
				dispatch(
					listLinkedInPages(currentProfile._id, (result) => {
						setLoading(false);
						setSubProfiles([...result]);
					})
				);
			}
		}
	}, [selectedTab, currentProfile, open]);

	useEffect(() => {
		let checked: string[] = [];

		subProfiles.forEach((item) => {
			if (profiles.filter((profile) => profile.socialId === item.socialId).length > 0) {
				checked.push(item.socialId!);
			}
		});

		setCheckedProfileIds([...checked]);
	}, [subProfiles, profiles]);

	useEffect(() => {
		setSelectedTab(profileTypes[0] || "page");
	}, [profileTypes]);

	return (
		<Dialog classes={{ paper: classes.paper }} onClose={handleClose} open={open}>
			<div className={classes.container}>
				<DialogHeader currentProfile={currentProfile} profileTypes={profileTypes} />

				<div className={classes.pagesContainer}>
					<ResultHeader
						profileTypes={profileTypes}
						selectedTab={selectedTab}
						subProfiles={subProfiles}
						checkedProfileIds={checkedProfileIds}
						currentProfile={currentProfile}
						setSelectedTab={setSelectedTab}
						onSelectAllClick={handleSelectAllClick}
					/>
					{loading && (
						<div style={{ padding: 16 }} className={classes.centeredContainer}>
							<CircularProgress size={25} />
						</div>
					)}

					{!loading && subProfiles.length === 0 && (
						<div style={{ padding: 32 }} className={classes.centeredContainer}>
							No accounts found
						</div>
					)}

					{!loading &&
						subProfiles.map((profile, index) => (
							<SubProfileItem
								profile={profile}
								key={index}
								isChecked={checkedProfileIds.includes(profile.socialId!)}
								onClick={handleProfileClick}
							/>
						))}
				</div>

				<div className={classes.buttonsContainer}>
					<Button
						secondary
						disabled={submitLoading}
						onClick={handleClose}
						style={{ width: 110, marginRight: 12 }}
					>
						Cancel
					</Button>

					<Button
						onClick={handleSubmit}
						disabled={subProfiles.length === 0 || submitLoading}
						style={{ width: 150 }}
					>
						{submitLoading ? <CircularProgress size={20} /> : "Next"}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default SubProfileSelectionDialog;

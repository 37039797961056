import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ListIconSvg from "assets/SVG/ListIconSvg";
import UserPicture from "../../components/Media/UserPicture";
import TeamUserSelector from "../../components/Popovers/TeamUserSelector/TeamUserSelector";
import ProductivityGraph from "modules/Tasks/ProductivityGraph";
import { getTeamProductivity } from "redux/actions/tasks/get";
import { StoreState } from "../../setup/store";
import Task from "types/Task";
import { TeamUser } from "types/User/Team";
import usePageStyles from "./styles";

// Shows the whole team's productivity if the current user is an admin, otherwise just the current user's
const TeamProductivity = () => {
	const { t } = useTranslation("tasks");
	const classes = usePageStyles();
	const [teamUserSelector, setTeamUserSelector] = useState<HTMLElement | null>(null);
	const [selectedTeamUser, setSelectedTeamUser] = useState<TeamUser>();
	const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
	const { currentTeamId, currentTeamRole } = useSelector((state: StoreState) => state.teams);
	const { userData } = useSelector((state: StoreState) => state.user);
	const { productivity } = useSelector((state: StoreState) => state.tasks);
	const dispatch = useDispatch();
	const isAdmin = currentTeamRole !== "writer";

	const handleUserSelect = (user: TeamUser | undefined) => {
		setSelectedTeamUser(user);
	};

	useEffect(() => {
		if (currentTeamId) dispatch(getTeamProductivity());
	}, [currentTeamId]);

	useEffect(() => {
		if (!userData) return;

		if (!isAdmin) {
			setFilteredTasks(productivity.filter((task) => task.assignees.includes(userData._id)));
		} else {
			if (selectedTeamUser) {
				setFilteredTasks(
					productivity.filter((task) => task.assignees.includes(selectedTeamUser.user!._id))
				);
			} else {
				setFilteredTasks([...productivity]);
			}
		}
	}, [isAdmin, selectedTeamUser, productivity, userData]);

	if (!userData) return <></>;

	return (
		<Box style={{ paddingTop: 68, paddingLeft: 32 }}>
			<TeamUserSelector
				anchorEl={teamUserSelector}
				title={t("seeProductivityOf")}
				onUserSelect={handleUserSelect}
				onClose={() => setTeamUserSelector(null)}
			/>
			<Box
				onClick={(e) => setTeamUserSelector(e.currentTarget)}
				style={{ cursor: isAdmin ? "pointer" : "default" }}
				display="flex"
				alignItems="center"
			>
				{isAdmin && <ListIconSvg size={selectedTeamUser ? 18 : 20} />}
				<Box
					style={{
						marginLeft: 4,
						zIndex: 9,
						fontSize: selectedTeamUser ? 18 : "",
					}}
					className={classes.cardTitle}
				>
					{isAdmin
						? selectedTeamUser
							? `${t("productivity")} ${t("of")} ${
									[...(selectedTeamUser.user!.email?.split("@") || [])][0]
							  }`
							: t("teamProductivity")
						: `${t("productivity")}`}

					{selectedTeamUser && (
						<UserPicture
							user={selectedTeamUser.user}
							style={{ height: 25, width: 25, marginLeft: 8 }}
						/>
					)}
				</Box>
			</Box>

			<ProductivityGraph
				style={{
					width: "105%",
					height: 220,
					marginLeft: -32,
					paddingLeft: 0,
					marginTop: -32,
				}}
				tasks={filteredTasks}
			/>
		</Box>
	);
};

export default TeamProductivity;

import {
	pieData,
} from "../../../../mock/listenPlaceholderData";
import LineDataCard, { LINE_CHART_DATE_FORMAT } from "modules/ListenAudience/LineDataCard";
import useStyles from "./styles";
import usePageStyles from "../../styles";
import { PostPreview, SocialMediaPostStats } from "modules/ListenAudience/PostStats";
import { posts } from "../../../../mock/placeholderData";
import SocialProfile from "types/SocialProfile";
import NetworkIcon from "assets/SVG/NetworkIconSvg";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../setup/store";
import { LineDataEntry } from "../../../../components/Charts/LineChart";
import { capitalize } from "lodash";
import { allReactions } from "redux/reducers/insights";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
	selectedProfile?: SocialProfile;
}

export const headerEntries = [
	"totalViews",
	"totalProfileLikes",
	"totalReposts",
	"totalMentions",
	"totalSubscribes",
	"totalMessages",
];

const General = (props: Props) => {
	const classes = useStyles();
	const pageClasses = usePageStyles();
	const { t } = useTranslation("listen");
	const insights = useSelector((store: StoreState) => store.insights);
	const { selectedProfile } = props;

	const reactionsData = useMemo(() => {
		let chart: LineDataEntry[] = allReactions.map((reaction) => ({
			id: capitalize(reaction.type),
			data: [],
			color: reaction.color,
		}));

		insights.dailyPostReactions.forEach((entry) => {
			const chartEntry = chart.find(
				(item) => item.id.toLowerCase() === entry.metadata.reactionType
			);

			if (chartEntry) {
				chartEntry.data.push({
					x: moment(entry.time).format(LINE_CHART_DATE_FORMAT),
					y: entry.metadata.value,
				});
			}
		});

		return chart;
	}, [insights.dailyPostReactions]);

	const impressionsAndEngagements = useMemo(
		() => [
			{
				id: "Impressions",
				color: "#2d86ff",
				data: insights.profileImpressions.map((entry) => ({
					x: moment(entry.time).format(LINE_CHART_DATE_FORMAT),
					y: entry.metadata.value,
				})),
			},
			{
				id: "Engagements",
				color: "rgb(243, 62, 88)",
				data: insights.profileEngagements.map((entry) => ({
					x: moment(entry.time).format(LINE_CHART_DATE_FORMAT),
					y: entry.metadata.value,
				})),
			},
		],
		[insights.profileEngagements, insights.profileEngagements]
	);

	const dailyProfileLikes = useMemo(
		() => [
			{
				id: "Likes",
				color: "#2d86ff",
				data: insights.dailyProfileLikes.map((entry) => ({
					x: moment(entry.time).format(LINE_CHART_DATE_FORMAT),
					y: entry.metadata.value,
				})),
			},
		],
		[insights.dailyProfileLikes]
	);

	// TODO: Show a message when there's no data to show

	return (
		<div className={classes.container}>
			<div className={classes.generalDataContainer}>
				{headerEntries.map((item, index) => (
					<div key={index} className={classes.generalDataItem}>
						<span className={pageClasses.dataItemTitle}>{t(item)}</span>
						<span className={pageClasses.dataItemValue}>
							{(insights as Record<string, any>)[item] || 0}
						</span>
					</div>
				))}
			</div>

			{selectedProfile && (
				<div className={classes.socialProfileContainer}>
					<NetworkIcon size={25} network={selectedProfile.network} />
					<img src={selectedProfile.picture} className={classes.selectedProfileImage} />
					<div className={classes.selectedProfileUsername}>{selectedProfile.username}</div>
				</div>
			)}

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Reactions"
						data={reactionsData}
						colors={reactionsData.map((reaction) => reaction.color || "")}
					/>
				</div>

				{/* TODO: Replace placeholders */}
				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer}>
							<PostPreview title="Most Liked Post" post={posts[0]} />
							<SocialMediaPostStats title={"Most Liked on"} data={pieData} />
						</div>

						<div style={{ marginTop: 20 }} className={pageClasses.linearContainer}>
							<PostPreview title="Most Disliked Post" post={posts[2]} />
							<SocialMediaPostStats title={"Most Disliked on"} data={pieData} />
						</div>
					</div>
				</div>
			</div>

			<div className={pageClasses.section}>
				<LineDataCard
					title="Impressions & Engagements"
					data={impressionsAndEngagements}
					colors={["#2d86ff", "rgb(243, 62, 88)"]}
				/>
			</div>

			{/* Reposts and Mentions */}
			{/* <div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Reposts and Mentions"
						data={repostsAndMentionsData}
						colors={["#F2C94C", "#4285F4"]}
					/>
				</div>

				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer}>
							<PostPreview title="Most Reposted" post={posts[0]} />
							<SocialMediaPostStats title={"Most Reposted in"} data={pieData} />
						</div>

						<div style={{ marginTop: 20 }} className={pageClasses.linearContainer}>
							<div style={{ width: "50%" }}>
								<div className={classes.title}>Most Mentioned with</div>
								<div className={classes.hashtagText}>#newtechnology</div>
								<div className={classes.hashtagText}>#blackfriday</div>
								<div className={classes.hashtagText}>#wintercomes</div>
								<div className={classes.hashtagText}>#goodmood</div>
							</div>
							<SocialMediaPostStats title={"Most Mentioned in"} data={pieData} />
						</div>
					</div>
				</div>
			</div> */}

			{/* Subscribes and Messages */}
			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Profile Likes"
						data={dailyProfileLikes}
						colors={["#2d86ff"]}
					/>
				</div>

				<div className={pageClasses.subSection}>
					{/* <div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer}>
							<div style={{ width: "50%" }}>
								<div className={classes.title}>Subscribe Statistics</div>
								<span className={classes.label}>Users mostly subscribe on:</span>
								<br />
								<span className={classes.value}>Sundays</span>
								<br />
								<span className={classes.label}>Most popular time: </span>
								<span className={classes.value}>6:00 PM - 8:00 PM</span>
								<br />
								<span className={classes.label}>
									Most active country - <span className={classes.value}>USA</span>
								</span>
							</div>
							<SocialMediaPostStats title={"Most Subscribed on"} data={pieData} />
						</div>

						<div style={{ marginTop: 20 }} className={pageClasses.linearContainer}>
							<div style={{ width: "50%" }}>
								<div className={classes.title}>Message Statistics</div>
								<span className={classes.label}>Users mostly send messages on:</span>
								<br />
								<span className={classes.value}>Mondays</span>
								<br />
								<span className={classes.label}>Most popular time: </span>
								<span className={classes.value}>6:00 PM - 8:00 PM</span>
								<br />
								<span className={classes.label}>
									Most active country - <span className={classes.value}>USA</span>
								</span>
							</div>
							<SocialMediaPostStats title={"Most Messages in"} data={pieData} />
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default General;

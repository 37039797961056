import { Box } from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	listPaymentMethods,
	updatePaymentMethodAddress,
} from "redux/actions/team/subscriptions/billing";
import { StoreState } from "setup/store";
import { PaymentMethod } from "types/Billing";
import ActionButtons from "./ActionButtons";
import BillingAddressInputs, {
	TBillingAddressInputs,
	validateBillingAddressInputs,
} from "./BillingAddressInputs";

interface Props {
	selectedPaymentMethod: PaymentMethod;
	onClose: () => void;
}

const UpdateBillingAddressForm = (props: Props) => {
	const { selectedPaymentMethod, onClose } = props;
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<TBillingAddressInputs>({});
	const [inputValues, setInputValues] = useState<TBillingAddressInputs>({});
	const { t } = useTranslation("settings");
	const currentTeamId = useSelector((state: StoreState) => state.teams.currentTeamId);
	const handleBillingAddressInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setInputValues({ ...inputValues, [name]: value });
	};
	const dispatch = useDispatch();

	const handleSubmit = async () => {
		const errors = validateBillingAddressInputs(inputValues);
		setErrors({ ...errors });

		if (Object.keys(errors).length) {
			return;
		}

		setLoading(true);
		await updatePaymentMethodAddress(selectedPaymentMethod.id, currentTeamId, inputValues);
		dispatch(listPaymentMethods());
		onClose();
		setLoading(false);
	};

	useEffect(() => {
		let inputValues: { [index: string]: any } = {};

		const cardBillingAddressPropertyMapping: { [index: string]: string } = {
			billing_addr1: "line1",
			billing_addr2: "line2",
			billing_city: "city",
			billing_state: "state",
			billing_zip: "postal_code",
		};

		Object.keys(cardBillingAddressPropertyMapping).forEach((key) => {
			inputValues[cardBillingAddressPropertyMapping[key]] = (
				selectedPaymentMethod.card as { [index: string]: string }
			)[key];
		});
		setInputValues({ ...inputValues });
	}, [selectedPaymentMethod]);

	return (
		<Box>
			<BillingAddressInputs
				errors={errors}
				inputValues={inputValues}
				onChange={handleBillingAddressInputChange}
			/>

			<ActionButtons
				onSubmit={handleSubmit}
				onClose={onClose}
				loading={loading}
				actionButtonText={t("update")}
			/>
		</Box>
	);
};

export default UpdateBillingAddressForm;

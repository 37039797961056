import { makeStyles } from "@material-ui/core";

const useTaskChipStyles = makeStyles((theme) => ({
	container: {
		borderRadius: 8,
		background: "#F7F7F7",
		padding: "6px 16px 6px 16px",
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
		cursor: "pointer",
	},

	title: {
		color: theme.palette.text.primary,
		fontSize: 14,
		marginBottom: 4,
		fontWeight: 500,
		maxWidth: "100%",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},

	assignees: {
		width: "fit-content",
		marginLeft: "auto",
		paddingRight: 8,
		marginBottom: -6,
	},

	highlighter: {
		backgroundColor: "#D5E1F5",
		borderRadius: 4,
	},
}));

export default useTaskChipStyles;

import axios from "axios";
import { Dispatch } from "react";
import { getVideoThumbnail } from "helpers/utils";
import { setSnackbarState } from "../snackBar";
import config from "config";
import { GetPostFunc, ListPostsFunc } from "types/ReduxActions";
import {
	GET_POST,
	GET_POST_LOADING,
	LIST_POSTS,
	LIST_POSTS_LOADING,
	LIST_POST_SEARCH_RESULT,
	POSTS_SEARCH_RESULT_LOADING,
	SET_SCHEDULED_POSTS_COUNT,
} from "../../reducers/posting";
import { PostFilters } from "components/Popovers/PostsFilter";
import { StoreState } from "../../../setup/store";
import { PostStatus } from "types/Post";

export type ListPostsQuery = {
	scheduled?: boolean;
	startDate?: Date;
	endDate?: Date;
	draft?: boolean;
	archive?: boolean;
	searchValue?: string;
	from?: number;
	limit?: number;
	filters?: PostFilters;
	count?: boolean;
	status?: PostStatus[];
	socialPosts?: boolean;
};

export const getPostAttachmentUrl = async (attachment: File) => {
	let url = "";

	if (attachment.type.includes("video")) {
		url = URL.createObjectURL((await getVideoThumbnail(attachment)) as Blob);
	} else {
		url = URL.createObjectURL(attachment);
	}

	return url;
};

export const listPosts: ListPostsFunc = (query) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { currentTeamId } = getState().teams;
		const {
			socialPosts,
			scheduled,
			draft,
			archive,
			searchValue,
			startDate,
			endDate,
			filters,
			count,
			status,
		} = query;

		if (searchValue) {
			dispatch({
				type: POSTS_SEARCH_RESULT_LOADING,
				payload: true,
			});
		} else {
			dispatch({
				type: LIST_POSTS_LOADING,
				payload: true,
			});
		}

		const params: { [index: string]: any } = {
			scheduled,
			draft,
			archive,
			searchValue,
			count,
			startDate: startDate && startDate?.toISOString(),
			endDate: endDate && endDate?.toISOString(),
			networks: "",
			socialProfiles: "",
			status,
			socialPosts,
		};

		if (filters) {
			params.networks = filters
				.filter((filter) => filter.type === "network")
				.map((filter) => filter.value)
				.join(",");
			params.socialProfiles = filters
				.filter((filter) => filter.type === "socialProfile")
				.map((filter) => filter.value)
				.join(",");
			params.campaigns = filters
				.filter((filter) => filter.type === "campaign")
				.map((filter) => filter.value)
				.join(",");
		}

		// TODO: Add pagination
		try {
			const result = await axios.get(`${config.apiUrl}/posts`, {
				params: { ...params, teamId: currentTeamId },
			});

			if (count) {
				dispatch({
					type: SET_SCHEDULED_POSTS_COUNT,
					payload: result.data.count,
				});

				// Results for the SearchPage
			} else if (searchValue && !socialPosts && !archive) {
				dispatch({
					type: LIST_POST_SEARCH_RESULT,
					payload: result.data.posts,
				});
			} else {
				dispatch({
					type: LIST_POSTS,
					payload: result.data.posts,
				});
			}
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "Error fetching posts..."));
			console.log(e.response || e);
			dispatch({
				type: LIST_POSTS_LOADING,
				payload: false,
			});
		}
	};
};

export const getPostById: GetPostFunc = (_id, callback) => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: GET_POST_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(`${config.apiUrl}/posts/${_id}`, {});

			dispatch({
				type: GET_POST,
				payload: result.data,
			});

			if (callback) callback(result.data);
		} catch (e) {
			console.log(e.response?.data || e);
			dispatch({
				type: GET_POST_LOADING,
				payload: false,
			});

			if (callback) callback(null);
			dispatch(setSnackbarState(true, "error", "Error fetching post..."));
		}
	};
};

import { MenuItem } from "@material-ui/core";
import { PostFilters } from "..";
import { networkIcons } from "types/Network";
import SocialProfile from "types/SocialProfile";
import useStyles from "../styles";
import SocialProfilePicture from "../../../Media/SocialProfilePicture";

interface Props {
	profile: SocialProfile;
	appliedFilters: PostFilters;
	onFilterClick: (type: "network" | "socialProfile" | "campaign", value: string) => void;
}

const ProfileFilterItem = (props: Props) => {
	const classes = useStyles();
	const { profile, onFilterClick, appliedFilters } = props;
	return (
		<MenuItem
			style={{
				backgroundColor: appliedFilters.find((filter) => filter.value === profile._id)
					? "#e6e6e6"
					: "",
			}}
			onClick={() => onFilterClick("socialProfile", profile._id || "")}
			className={classes.menuItem}
		>
			{profile.network && (
				<img src={networkIcons[profile.network]} className={classes.networkIcon} />
			)}
			<SocialProfilePicture profile={profile} style={{ width: 20, height: 20, marginRight: 12 }} />
			<span>{profile.displayName || profile.username}</span>
		</MenuItem>
	);
};

export default ProfileFilterItem;

import { Box, CircularProgress } from "@material-ui/core";
import { debounce } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import LinkText from "components/Basic/Text/LinkText";
import { setTaskDialog } from "redux/actions/modals";
import { listUserTasks } from "redux/actions/tasks/get";
import Task, { TaskStatus } from "types/Task";
import UserData from "types/User/UserData";
import TaskChipsSkeleton from "../../LoadingSkeletons/TaskChipsSkeleton";
import TaskChip from "../../TaskChip";
import TaskDialog from "../../TaskDialog/TaskDialog";
import TasksSectionHeader from "./SectionHeader";

interface Props {
	user: UserData;
	section: TaskStatus;
}

const WatchTeamUserTasksSection = (props: Props) => {
	const [searchValue, setSearchValue] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [newPageLoading, setNewPageLoading] = useState<boolean>(false);
	const [tasks, setTasks] = useState<Task[]>([]);
	const [total, setTotal] = useState<number>(0);
	const dispatch = useDispatch();
	const { t } = useTranslation("tasks");
	const { section, user } = props;

	const getTasks = (searchValue?: string, from?: number) => {
		if (newPageLoading) return;

		if (from) setNewPageLoading(true);
		else setLoading(true);

		dispatch(
			listUserTasks(
				user._id,
				{ status: section, searchValue, from, pageLimit: 15 },
				(newTasks, total) => {
					if (from) {
						setTasks([...tasks, ...newTasks]);
						setNewPageLoading(false);
					} else {
						setTasks(newTasks);
						setLoading(false);
					}
					setTotal(total);
				}
			)
		);
	};

	const debouncedSearch = useCallback(
		debounce((value) => {
			getTasks(value);
		}, 200),
		[user]
	);

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchValue(value);
		debouncedSearch(value);
	};

	const handleTaskClick = (task: Task) => {
		dispatch(setTaskDialog(true, task, handleTaskSubmit));
	};

	const handleTaskSubmit = (updatedTask: Task, deleted?: boolean) => {
		if (deleted) {
			setTasks(tasks.filter((task) => task._id !== updatedTask._id));
		} else {
			tasks.forEach((task, index) => {
				if (task._id === updatedTask._id) {
					tasks[index] = { ...updatedTask };
				}
			});
			setTasks([...tasks]);
		}
	};

	useEffect(() => {
		getTasks();
	}, [user]);

	return (
		<Box style={{ width: "50%", paddingRight: 32 }}>
			<TasksSectionHeader
				section={section}
				total={total}
				searchValue={searchValue}
				onSearchChange={handleSearchChange}
			/>

			{loading && <TaskChipsSkeleton />}

			<Box style={{ overflowY: "scroll", height: "34vh" }}>
				{!loading &&
					tasks.map((task, index) => (
						<TaskChip onClick={handleTaskClick} task={task} key={index} searchValue={searchValue} />
					))}

				{total > tasks.length && (
					<Box textAlign="center" style={{ marginTop: 16, marginBottom: 16 }}>
						<LinkText onClick={() => getTasks(searchValue, tasks.length)}>
							{t("loadMore")}
							{newPageLoading && <CircularProgress size={18} />}
						</LinkText>
					</Box>
				)}

				{!loading && tasks.length === 0 && (
					<Box style={{ fontSize: 12, color: "#868C91" }}>{t("noTasks")}</Box>
				)}
			</Box>
		</Box>
	);
};

export default WatchTeamUserTasksSection;

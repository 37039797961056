import React from "react";
import {
	comparisonReposts,
	comparisonViews,
	countryPieData,
} from "../../../../mock/listenPlaceholderData";
import LineDataCard from "modules/ListenAudience/LineDataCard";
import { SocialMediaPostStats } from "modules/ListenAudience/PostStats";
import Post from "types/Post";
import usePageStyles from "../../styles";
import PostItem from "./components/PostItem";
import useStyles from "./styles/styles";

interface Props {
	posts: Post[];
}

const ComparePosts = (props: Props) => {
	const classes = useStyles();
	const pageClasses = usePageStyles();

	const { posts } = props;
	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<PostItem postIndex={1} post={posts[0]} />
				<PostItem postIndex={2} post={posts[1]} />
			</div>

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Views Statistics"
						data={comparisonViews}
						colors={["#3E4C55", "#F04755"]}
					/>
				</div>
				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer} style={{ marginBottom: 16 }}>
							<div className={classes.sectionSummaryContainer}>
								{/* TODO: Replace placeholders */}
								<div className={classes.title}>Post #1 Activity Statistics</div>
								<span className={classes.label}>Most active time</span>
								<span className={classes.value}>SU 28 Oct, 6:00 PM - 7:00 PM</span>

								<span className={classes.label}>Less active time</span>
								<span className={classes.value}>SU 28 Oct, 6:00 PM - 7:00 PM</span>
							</div>
							<SocialMediaPostStats title="Post #1 Most Viewed In" data={countryPieData} />
						</div>

						<div className={pageClasses.linearContainer}>
							<div className={classes.sectionSummaryContainer}>
								{/* TODO: Replace placeholders */}
								<div className={classes.title}>Post #2 Activity Statistics</div>
								<span className={classes.label}>Most active time</span>
								<span className={classes.value}>SU 28 Oct, 6:00 PM - 7:00 PM</span>

								<span className={classes.label}>Less active time</span>
								<span className={classes.value}>SU 28 Oct, 6:00 PM - 7:00 PM</span>
							</div>
							<SocialMediaPostStats title="Post #2 Most Viewed In" data={countryPieData} />
						</div>
					</div>
				</div>
			</div>

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Views Statistics"
						data={comparisonViews}
						colors={["#3E4C55", "#F04755"]}
					/>
				</div>
				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer} style={{ marginBottom: 16 }}>
							<SocialMediaPostStats
								width="35%"
								title="Post #1 Most Liked In"
								data={countryPieData}
							/>
							<div style={{ marginLeft: "auto" }}>
								<SocialMediaPostStats title="Post #1 Most Disliked In" data={countryPieData} />
							</div>
						</div>

						<div className={pageClasses.linearContainer}>
							<SocialMediaPostStats
								width="35%"
								title="Post #2 Most Liked In"
								data={countryPieData}
							/>
							<div style={{ marginLeft: "auto" }}>
								<SocialMediaPostStats title="Post #2 Most Disliked In" data={countryPieData} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={pageClasses.section}>
				<div className={pageClasses.leftSubSection}>
					<LineDataCard
						title="Reposts Statistics"
						data={comparisonReposts}
						colors={["#3E4C55", "#F04755"]}
					/>
				</div>
				<div className={pageClasses.subSection}>
					<div className={pageClasses.dataBox}>
						<div className={pageClasses.linearContainer} style={{ marginBottom: 16 }}>
							<div style={{ width: "50%" }}>
								{/* TODO: Remove placeholders */}
								<div className={classes.title}>Post #1 Most Reposted With</div>
								<div className={classes.hashtagText}>#newtechnology</div>
								<div className={classes.hashtagText}>#blackfriday</div>
								<div className={classes.hashtagText}>#wintercomes</div>
								<div className={classes.hashtagText}>#goodmood</div>
							</div>
							<SocialMediaPostStats title="Post #1 Most Reposted In" data={countryPieData} />
						</div>

						<div className={pageClasses.linearContainer}>
							<div style={{ width: "50%" }}>
								{/* TODO: Remove placeholders */}
								<div className={classes.title}>Post #2 Most Reposted With</div>
								<div className={classes.hashtagText}>#newtechnology</div>
								<div className={classes.hashtagText}>#blackfriday</div>
								<div className={classes.hashtagText}>#wintercomes</div>
								<div className={classes.hashtagText}>#goodmood</div>
							</div>
							<SocialMediaPostStats title="Post #2 Most Reposted In" data={countryPieData} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ComparePosts;

import { Box, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LinkText from "components/Basic/Text/LinkText";
import Task from "types/Task";
import useSummaryStyles from "../../styles/summary";
import MarkdownText from "components/Basic/Text/MarkdownText";
import splitLines from "split-lines";

interface Props {
	selectedTask: Task;
}

const SummaryDescription = (props: Props) => {
	const [textLines, setTextLines] = useState(0);
	const [expanded, setExpanded] = useState<boolean>(false);
	const { selectedTask } = props;
	const { description } = selectedTask;
	const classes = useSummaryStyles({ expanded });
	const { t } = useTranslation("tasks");

	useEffect(() => {
		if (description) {
			setTextLines(splitLines(description).length);
		}
	}, [description]);

	return (
		<Box style={{ minHeight: 140 }}>
			<Typography className={classes.sectionLabel}>{t("description")}:</Typography>

			{!description && <Box className={classes.emptySectionMessage}>{t("noDescription")}</Box>}

			{description && (
				<>
					<Box className={classes.textContainer}>
						<Box
							style={{
								maxHeight: expanded ? 9999 : 100,
								minHeight: expanded ? 100 : 60,
								overflowY: expanded ? "auto" : "hidden",
							}}
							className={classes.description}
						>
							<MarkdownText text={description} />
						</Box>
					</Box>

					{textLines > 4 && (
						<LinkText style={{ marginBottom: 24 }} onClick={() => setExpanded(!expanded)}>
							{t(expanded ? "seeLess" : "seeMore")}
						</LinkText>
					)}
				</>
			)}
		</Box>
	);
};

export default SummaryDescription;

import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "components/Basic/Button";
import TSubscriptionPlan from "types/SubscriptionPlan";
import useSubscriptionPlanStyles from "./styles";
import PlanFeatures from "./PlanFeatures";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import { intercomLauncherSelector } from "setup/App";

export const pricinPlanFeatures = [
	"postsPerDay",
	"socialProfiles",
	"teamMembers",
	"replyOption",
	"listenAudience",
	"analytics",
];

interface Props {
	plan: TSubscriptionPlan;
	selectedPeriod: "monthly" | "yearly";
	onSelectClick: (plan: TSubscriptionPlan) => void;
}

const SubscriptionPlan = (props: Props) => {
	const classes = useSubscriptionPlanStyles();
	const { t } = useTranslation("settings");
	const { plan, selectedPeriod, onSelectClick } = props;
	const hasPrice = plan.id !== "Hashtag-Hero-Free" && plan.id !== "Hashtag-Hero-Enterprise";
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);
	const selected =
		currentTeamSubscription?.planInfo?.id.replace("-Yearly", "").replace("-Monthly", "") ===
		plan.id;

	return (
		<Box className={classes.subscriptionPlan}>
			<Box className={classes.title}>{plan.title}</Box>

			<Box className={classes.price}>
				{plan.id === "Hashtag-Hero-Free" && t("free")}
				{plan.id === "Hashtag-Hero-Enterprise" && t("custom")}
				{selectedPeriod === "monthly" && hasPrice && (
					<span>
						{plan.price.monthly}
						<span style={{ fontSize: 16, textTransform: "none" }}>$ / {t("month")}</span>
					</span>
				)}
				{selectedPeriod === "yearly" && hasPrice && (
					<span>
						{plan.price.yearly}
						<span style={{ fontSize: 16, textTransform: "none" }}>$ / {t("year")}</span>
					</span>
				)}
			</Box>

			<Box className={classes.description}>{plan.description}</Box>
			<PlanFeatures plan={plan} style={{ marginTop: 42 }} />

			{plan.id !== "Hashtag-Hero-Free" && (
				<Box className={classes.actionContainer}>
					<Button
						id={plan.id === "Hashtag-Hero-Enterprise" ? intercomLauncherSelector : ""}
						disabled={selected}
						onClick={() => plan.id !== "Hashtag-Hero-Enterprise" && onSelectClick(plan)}
						style={{ width: "100%", fontFamily: "Chau Philomene One", fontSize: 14 }}
					>
						{selected
							? t("selected")
							: plan.id === "Hashtag-Hero-Enterprise"
							? t("contactUs")
							: t("select")}
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default SubscriptionPlan;

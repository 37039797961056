import { Box, FormControlLabel, makeStyles, Radio, RadioProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	icon: {
		borderRadius: "50%",
		boxShadow: "none",
		outline: `1px solid ${theme.palette.secondary.main}`,
		width: 16,
		height: 16,
		backgroundColor: "#f5f8fa",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
		"input:disabled ~ &": {
			opacity: 0.5,
		},
	},
	checkedIcon: {
		backgroundColor: theme.palette.primary.main,
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 16,
			height: 16,
			backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
			content: '""',
		},
		"input:hover ~ &": {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));

// Inspired by blueprintjs
const RadioWithlabel = (props: RadioProps & { label?: string }) => {
	const classes = useStyles();
	const { label } = props;

	return (
		<FormControlLabel
			control={
				<Radio
					className={classes.root}
					disableRipple
					color="default"
					checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
					icon={<span className={classes.icon} />}
					{...props}
				/>
			}
			label={label && <Box style={{ fontSize: 12 }}>{label}</Box>}
		/>
	);
};

export default RadioWithlabel;

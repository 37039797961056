import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Post from "types/Post";
import SocialProfileChip from "../../Settings/SocialProfile/SocialProfileChip";
import useUnapprovedPostStyles from "./styles";

interface Props {
	post: Post;
}

const PostSocialProfiles = (props: Props) => {
	const { post } = props;
	const classes = useUnapprovedPostStyles();
	const { t } = useTranslation("posts");

	// TODO: Test with overflow social profiles
	return (
		<Box style={{ width: "30%", paddingLeft: 52 }}>
			<Box className={classes.sectionLabel}>{t("socials")}:</Box>
			<Box style={{ maxHeight: "100%", overflowY: "auto" }}>
				{post.socialProfiles?.map((profile, index) => (
					<SocialProfileChip
						style={{
							width: "fit-content",
							background: "white",
							paddingLeft: 0,
						}}
						textStyle={{ width: 120 }}
						profile={profile}
						key={index}
					/>
				))}
			</Box>
		</Box>
	);
};

export default PostSocialProfiles;

import { Box } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CommentIconSvg from "assets/SVG/CommentIconSvg";
import theme from "../../setup/Theme";
import TaskType from "types/Task";
import useTaskStyles from "./styles/task";
import TaskAssignees from "./TaskCardAssigneesPictures";

interface Props {
	task: TaskType;
	onTaskClick: (task: TaskType) => void;
}

const Task = (props: Props) => {
	const classes = useTaskStyles();
	const { task, onTaskClick } = props;
	const { title, deadline, urgent, comments, assignees } = task;
	const { t } = useTranslation("tasks");

	return (
		<Box
			onClick={() => onTaskClick(task)}
			className={classes.taskCard}
			style={{
				backgroundColor: urgent ? "#fcd9dd" : "",
				boxShadow: urgent ? `2px 2px 0px #f7aeb4` : "",
			}}
		>
			<Box display="flex" alignItems="center">
				<Box
					title={
						Boolean(comments?.length)
							? t("unreadComments", { count: comments?.length })
							: t("noUnreadComments")
					}
					style={{
						color: urgent ? theme.palette.primary.main : "",
						fontWeight: urgent ? 500 :400,
					}}
					className={classes.deadline}
				>
					{t("deadline")}:{" "}
					{urgent ? t("urgent") : deadline ? moment(deadline).format("DD MMM hh:mm A") : "-"}
				</Box>

				<Box width="fit-content" marginLeft="auto">
					<CommentIconSvg
						color={Boolean(comments?.length) ? theme.palette.primary.main : "#868C91"}
						size={16}
					/>
				</Box>
			</Box>

			<Box className={classes.title}>{title}</Box>

			{Boolean(assignees?.length) && (
				<Box className={classes.assignees}>
					<TaskAssignees
						spacedCircles
						circleStyle={{
							width: 25,
							height: 25,
							marginRight: 2,
						}}
						task={task}
					/>
				</Box>
			)}
		</Box>
	);
};

export default Task;

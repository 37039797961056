import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useStyles from "../styles";
import EmailImage from "assets/email.png";
import FormHeader from "../FormHeader";
import SignUpStepper from "./Stepper";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../../setup/store";
import { resendConfirmation, signIn, signOut } from "redux/actions/auth";
import { Box, CircularProgress } from "@material-ui/core";
import Button from "components/Basic/Button";

import AuthIllustration from "../Illustration";
import TextButton from "components/Basic/TextButton";
import { useTranslation } from "react-i18next";
import { checkAuth } from "redux/actions/auth/checkAuth";
import { SET_SIGN_IN_RESPONSE, SET_SIGN_IN_ERROR } from "redux/reducers/user";

/**
 * Email Verification Step
 *
 * There are 2 types of email verifications, one that's performed through cognito and an internal one that we send ourselves
 * to confirm email addresses that the user enters after registering with Facebook (since some Facebook accounts do not have an email address associated)
 */
const EmailVerificationStep = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { userData } = useSelector((state: StoreState) => state.user);
	const [resendLoading, setResendLoading] = useState<boolean>(false);
	const [emailResent, setEmailResent] = useState<boolean>(false);
	const { email, password, authLoading, signInError, signInResponse } = useSelector(
		(state: StoreState) => state.user
	);

	const handleResendClick = async () => {
		setResendLoading(true);
		if (emailResent) return;

		await resendConfirmation();

		setResendLoading(false);
		setEmailResent(true);
	};

	const handleContinueClick = () => {
		dispatch(signIn(email || "", password || ""));
	};

	useEffect(() => {
		if (signInResponse) {
			history.push("/signup/general-info");
		}
	}, [signInResponse]);

	useEffect(() => {
		dispatch({
			type: SET_SIGN_IN_RESPONSE,
			payload: null,
		});

		dispatch({
			type: SET_SIGN_IN_ERROR,
			payload: null,
		});

		dispatch(
			checkAuth((user, userData) => {
				console.log(user, userData);
				if (userData?.emailConfirmed) {
					return history.push("/");
				}
				if (userData?.email && !userData.emailConfirmed) {
					return;
				} else if (userData && !userData.email) {
					return history.push("/signup/general-info");
				}

				if (user) {
					history.push("/");
				} else {
					if (!email && !password) {
						history.push("/signup");
					}
				}
			})
		);
	}, []);

	return (
		<>
			<div className={classes.pageContainer}>
				<AuthIllustration />
				<div className={classes.rightSection}>
					<FormHeader includeText={true} />
					<div className={classes.formContainer}>
						<img src={EmailImage} className={classes.emailImage} />
						<span className={classes.emailConfirmationText}>
							We just sent you the e-mail for identification. <br />
							Please confirm to continue registration
						</span>

						{!userData && (
							<Button
								disabled={authLoading}
								onClick={handleContinueClick}
								style={{ width: 150, alignSelf: "center", marginTop: 32 }}
							>
								{authLoading ? <CircularProgress size={20} /> : "Continue"}
							</Button>
						)}

						{signInError?.code === "UserNotConfirmedException" && (
							<div className={classes.message}>Email not confirmed.</div>
						)}

						<div onClick={handleResendClick} className={classes.resendText}>
							I didn't get the e-mail. Please resend it
						</div>

						{emailResent && !resendLoading && (
							<div className={classes.message}>Confirmation email resent!</div>
						)}

						{resendLoading && (
							<CircularProgress size={20} style={{ marginTop: 12, alignSelf: "center" }} />
						)}

						<Box width="fit-content" margin="12px auto 12px auto">
							<TextButton onClick={() => dispatch(signOut())} secondary>
								{t("signOut")}
							</TextButton>
						</Box>
					</div>
				</div>
			</div>
			<SignUpStepper selectedStep={1} />
		</>
	);
};

export default EmailVerificationStep;

import { Box, CircularProgress, IconButton } from "@material-ui/core";
import ArrowsLeftIcon from "assets/SVG/ArrowsLeftIconSvg";
import ArrowsRightIcon from "assets/SVG/ArrowsRightIconSvg";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import { Tab } from "components/Tabs";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { listTeamHashtags, listTrendingHashtagsAndTopics } from "redux/actions/stats/hashtags";
import { Hashtag } from "redux/reducers/stats";
import { StoreState } from "setup/store";
import DrawerHeader from "./components/DrawerHeader";
import HashtagListItem from "./components/HashtagListItem";
import HashtagsList from "./components/HashtagsList";
import useHashtagsDrawerStyles from "./styles";

interface Props {
	open: boolean;
	onHashtagClick: (hashtag: Hashtag) => void;
	onClose: () => void;
}

const COLLAPSED_WIDTH = 200;
const EXTENDED_WIDTH = 315;

const HashtagsDrawer = (props: Props) => {
	const [extended, setExtended] = useState(false);
	const [selectedTab, setSelectedTab] = useState<Tab>({ index: 0, name: "popular" });
	const [searchValue, setSearchValue] = useState("");
	const { open, onHashtagClick, onClose } = props;
	const classes = useHashtagsDrawerStyles();
	const {
		trendingHashtags,
		teamHashtags,
		trendingTopics,
		teamHashtagsLoading,
		trendingHashtagsLoading,
	} = useSelector((state: StoreState) => state.stats);

	const isDrawerLoading = teamHashtagsLoading || trendingHashtagsLoading;
	const dispatch = useDispatch();

	const hashtagsList = useMemo(() => {
		let hashtags: Hashtag[] = [];
		if (selectedTab.name === "popular") {
			hashtags = trendingHashtags;
		} else if (selectedTab.name === "topics") {
			hashtags = trendingTopics as Hashtag[];
		} else if (selectedTab.name === "saved") {
			hashtags = teamHashtags.filter((hashtag) => hashtag.saved);
		} else if (selectedTab.name === "recent") {
			hashtags = teamHashtags.filter((hashtag) => hashtag.usages);
		}

		if (searchValue) {
			const lowerCaseSearchValue = searchValue.toLowerCase();

			hashtags = hashtags.filter((hashtag) =>
				hashtag.name.toLowerCase().includes(lowerCaseSearchValue)
			);
		}

		return hashtags;
	}, [selectedTab, teamHashtags, trendingTopics, trendingHashtags, searchValue]);

	useEffect(() => {
		if (open) {
			if (!trendingHashtags.length) {
				dispatch(listTrendingHashtagsAndTopics());
			}
			dispatch(listTeamHashtags());
		}
	}, [open]);

	useEffect(() => {
		if (!extended) {
			setSelectedTab({ index: 0, name: "popular" });
		}
	}, [extended]);

	const drawerButtonsProps = {
		extended,
		onExtendClick: () => setExtended(!extended),
		onCloseClick: () => onClose(),
	};

	return (
		<Box style={{ width: open ? "" : 0 }} className={classes.hashtagsDrawerContainer}>
			{!extended && <DrawerButtons {...drawerButtonsProps} />}
			<Box
				style={{ width: open ? (extended ? EXTENDED_WIDTH : COLLAPSED_WIDTH) : 0 }}
				className={classes.hashtagsDrawer}
			>
				{extended && (
					<Box className={classes.innerDrawerButtonsContainer}>
						<DrawerButtons {...drawerButtonsProps} />
					</Box>
				)}

				<DrawerHeader
					selectedTab={selectedTab}
					extended={extended}
					searchValue={searchValue}
					onSearchChange={(e) => setSearchValue(e.target.value)}
					onTabChange={(tab) => setSelectedTab(tab)}
				/>

				{isDrawerLoading && (
					<Box width="fit-content" margin="auto" marginTop={3}>
						<CircularProgress size={20} />
					</Box>
				)}

				<HashtagsList
					isDrawerLoading={isDrawerLoading}
					hashtagsList={hashtagsList}
					selectedTab={selectedTab}
					extended={extended}
					onHashtagClick={onHashtagClick}
				/>
			</Box>
		</Box>
	);
};

export const DrawerButtons = ({
	onExtendClick,
	onCloseClick,
	extended,
}: {
	onExtendClick: () => void;
	onCloseClick: () => void;
	extended: boolean;
}) => {
	const classes = useHashtagsDrawerStyles();

	return (
		<Box className={classes.buttonsContainer}>
			<IconButton
				onClick={onCloseClick}
				className={classes.iconButton}
				style={{ marginBottom: 16 }}
			>
				<CloseIconSvg size={14} />
			</IconButton>
			<IconButton onClick={onExtendClick} className={classes.iconButton}>
				{!extended ? <ArrowsLeftIcon size={16} /> : <ArrowsRightIcon size={18} />}
			</IconButton>
		</Box>
	);
};

export default HashtagsDrawer;

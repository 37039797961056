import { makeStyles } from "@material-ui/core";

const useOnboardingStyles = makeStyles(() => ({
	container: {
		position: "fixed",
		bottom: 24,
		right: 62,
		width: "100%",
		display: "flex",
		flexDirection: "column",
		zIndex: 9999,
		alignItems: "flex-end",
	},

	boxAndArrowContainer: {
		marginRight: 60,
		width: "25%",
		minWidth: 380,
		maxWidth: 400,
		marginBottom: -62,
		filter: "drop-shadow(4px 4px 24px rgba(62, 76, 85, 0.2))",
	},

	box: {
		borderRadius: 12,
		padding: 24,
		textAlign: "center",
		backgroundColor: "white",
		position: "relative",
		zIndex: 2,
	},

	arrow: {
		width: 0,
		height: 0,
		marginTop: -32,
		borderLeft: "25px solid transparent",
		borderRight: "25px solid transparent",
		borderTop: "110px solid white",
		marginLeft: "auto",
		transform: "rotate(-35deg)",
		marginRight: 92,
		zIndex: 1,
	},

	actions: {
		marginTop: 32,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: 12,
	},

	onboardingAvatar: {
		maxWidth: 130,
		width: "12%",
	},
}));

export default useOnboardingStyles;

import { Dispatch } from "react";
import store, { StoreState } from "setup/store";
import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
	AuthenticationDetails,
} from "amazon-cognito-identity-js";
import {
	SET_SIGN_UP_ERROR,
	SET_AUTH_LOADING,
	SET_SIGN_UP_RESPONSE,
	SET_CREDENTIALS,
	SET_SIGN_IN_RESPONSE,
	SET_SIGN_IN_ERROR,
	SET_USER_DATA,
} from "../../reducers/user";
import config, { AuthProviderLsKey, emailConfirmationCodeQuery, confirmationSuccessQuery } from "config";
import { ResendConfirmationFunc, SignInFunc, SignUpFunc } from "types/ReduxActions";
import { checkAuth } from "./checkAuth";
import { createTeam, setCurrentTeamId } from "../team/post";
import axios from "axios";
import { deleteQueryParam, getHeaders, updateQueryParam } from "helpers/utils";
import { setSnackbarState } from "../snackBar";

const poolData = {
	ClientId: config.CognitoClientId,
	UserPoolId: config.UserPoolId,
};

export const userPool = new CognitoUserPool(poolData);

export const signUp: SignUpFunc = (email, password) => {
	return (dispatch: Dispatch<any>) => {
		console.log("sign up");
		dispatch({
			type: SET_AUTH_LOADING,
			payload: true,
		});

		const dataEmail = {
			Name: "email",
			Value: email.toLowerCase(),
		};

		const attributeEmail = new CognitoUserAttribute(dataEmail);
		let attributeList = [attributeEmail];

		userPool.signUp(email, password, attributeList, [], async (err, result) => {
			if (err) {
				console.log(err);
				dispatch({
					type: SET_SIGN_UP_ERROR,
					payload: err,
				});
			} else if (result) {
				try {
					dispatch(signIn(email, password));
					dispatch(setCurrentTeamId(""));

					dispatch({
						type: SET_SIGN_UP_RESPONSE,
						payload: result,
					});

					dispatch({
						type: SET_CREDENTIALS,
						payload: { email, password },
					});
				} catch (e) {
					console.log(e.response?.data);
				}
			}
		});
	};
};

export const signIn: SignInFunc = (email, password) => {
	return (dispatch: Dispatch<any>) => {
		dispatch({
			type: SET_AUTH_LOADING,
			payload: true,
		});

		var authenticationData = {
			Username: email,
			Password: password,
		};

		const authenticationDetails = new AuthenticationDetails(authenticationData);

		const cognitoUser = new CognitoUser({
			Username: email,
			Pool: userPool,
		});

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: (result) => {
				localStorage.setItem(AuthProviderLsKey, "Cognito");
				dispatch(setCurrentTeamId(""));

				dispatch({
					type: SET_SIGN_IN_RESPONSE,
					payload: { ...result },
				});

				dispatch(checkAuth());
			},
			onFailure: (err) => {
				dispatch({
					type: SET_SIGN_IN_ERROR,
					payload: err,
				});

				dispatch({
					type: SET_CREDENTIALS,
					payload: { email, password },
				});
			},
		});
	};
};

/***
 * There are 2 types of email verifications, one that's performed through cognito and an internal one that we send ourselves
 * to confirm email addresses that the user enters after registering with Facebook (since some Facebook accounts do not have an email address associated)
 */
export const resendConfirmation: ResendConfirmationFunc = async () => {
	return new Promise(async (resolve) => {
		/**
		 * If userData is in state, this means that the user has gotten passed the cognito authentication,
		 * thus our internal email confirmation will be sent instead
		 */
		const { email, userData } = store.getState().user;
		if (userData?.email && !userData?.emailConfirmed) {
			const result = await axios.get(`${config.apiUrl}/user/current/send-email-confirmation`);

			resolve(result.data.success);
		} else {
			if (!email) {
				return;
			}

			const cognitoUser = new CognitoUser({
				Username: email,
				Pool: userPool,
			});

			cognitoUser.resendConfirmationCode((err) => {
				resolve(!Boolean(err));
			});
		}
	});
};

/** [Internal Email Verification] Submits the confirmation code that's received from the redirectUrl from the confirmation email. */
export const confirmEmailAddress = (confirmationCode: string) => {
	return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;

		if (!userData) {
			return;
		}

		dispatch({
			type: SET_AUTH_LOADING,
			payload: true,
		});

		try {
			const result = await axios.get(
				`${config.apiUrl}/user/current/confirm-email?confirmCode=${confirmationCode}`
			);

			if (result.data.success) {
				dispatch(
					createTeam({ name: userData.companyName || `${userData.firstName}'s Team` }, () => {
						deleteQueryParam(emailConfirmationCodeQuery);
						updateQueryParam(confirmationSuccessQuery, "true")
						window.location.reload();
					})
				);
			}
		} catch (e) {
			dispatch(setSnackbarState(true, "error", "SomethingWentWrongError"));
			dispatch({
				type: SET_AUTH_LOADING,
				payload: false,
			});

			updateQueryParam(emailConfirmationCodeQuery, "");
			window.location.reload();
		}
	};
};

export const signOut = () => {
	return (dispatch: Dispatch<any>, getState: () => StoreState) => {
		const { userData } = getState().user;

		dispatch({
			type: SET_USER_DATA,
			payload: {
				userData: null,
				authenticated: false,
			},
		});

		if (userData) {
			const cognitoUser = new CognitoUser({
				Username: userData.username,
				Pool: userPool,
			});
			cognitoUser.signOut();
		}

		Object.keys(localStorage).forEach((key) => {
			if (key.includes("CognitoIdentityServiceProvider")) {
				localStorage.removeItem(key);
			}
		});

		window.location.reload();
	};
};

export const getUserHash = async () => {
	const { userData } = store.getState().user;

	if (!userData) {
		return null;
	}

	try {
		const result = await axios.get(`${config.apiUrl}/user/current/hash`, { headers: getHeaders() });
		if (result.data.hash) {
			return result.data.hash;
		}
	} catch (e) {
		console.log(e);
	}

	return null;
};

export default function WarningIconSvg({ size, color }: { size: number; color?: string }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.8">
				<path
					d="M9.00073 8.9801C9.00073 8.4301 9.44843 7.9801 10.0007 7.9801C10.553 7.9801 11.0007 8.4301 11.0007 8.9801V14.9801C11.0007 15.5301 10.553 15.9801 10.0007 15.9801C9.44843 15.9801 9.00073 15.5301 9.00073 14.9801V8.9801Z"
					fill={color || "#F7F7F7"}
				/>
				<path
					d="M10.0007 4.05017C9.44843 4.05017 9.00073 4.50017 9.00073 5.05017C9.00073 5.60017 9.44843 6.05017 10.0007 6.05017C10.553 6.05017 11.0007 5.60017 11.0007 5.05017C11.0007 4.50017 10.553 4.05017 10.0007 4.05017Z"
					fill={color || "#F7F7F7"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10 0C4.4772 0 0 4.48 0 10C0 15.52 4.4772 20 10 20C15.5228 20 20 15.52 20 10C20 4.48 15.5228 0 10 0ZM2 10C2 14.42 5.5817 18 10 18C14.4183 18 18 14.42 18 10C18 5.58 14.4183 2 10 2C5.5817 2 2 5.58 2 10Z"
					fill={color || "#F7F7F7"}
				/>
			</g>
		</svg>
	);
}

import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../setup/store";
import Task from "types/Task";
import Team from "types/User/Team";
import UserData from "types/User/UserData";
import useTaskCommentsStyles from "../../styles/comments";
import TaskAssignee from "../components/Assignee";

interface Props {
	selectedTask: Task;
}

const CommentsSideBar = (props: Props) => {
	const classes = useTaskCommentsStyles();
	const [currentTeam, setCurrentTeam] = useState<Team>();
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);

	const { assignees } = props.selectedTask;

	const getUserRole = (user: UserData) => {
		return (
			currentTeam?.users.find(
				(teamUser) => typeof teamUser.user === "string" && teamUser.user === user._id
			)?.role || "writer"
		);
	};

	useEffect(() => {
		if (currentTeamId) setCurrentTeam(userData?.teams.find((team) => team._id === currentTeamId));
	}, [currentTeamId]);

	if (!userData) {
		return <></>;
	}

	return (
		<Box className={classes.sidebar}>
			{assignees.map((assignee, index) => (
				<TaskAssignee
					key={index}
					teamUser={{
						role: getUserRole(assignee),
						user: assignee,
					}}
				/>
			))}

			{assignees.length === 0 && (
				<TaskAssignee
					teamUser={{
						role: getUserRole(userData),
						user: userData,
					}}
				/>
			)}
		</Box>
	);
};

export default CommentsSideBar;

import { Box } from "@material-ui/core";
import EmojiIconSvg from "assets/SVG/EmojiIconSvg";
import EmojiPickerPopover from "components/Popovers/EmojiPickerPopover";
import { IEmojiData } from "emoji-picker-react";
import { MouseEvent, useState } from "react";
import useReplyDialogStyles from "../styles";

interface Props {
	value: string;
	onSubmit: () => void;
	onChange: (value: string) => void;
}

const ReplyInput: React.FC<Props> = (props) => {
	const { onSubmit, onChange, value } = props;
	const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] = useState<HTMLElement | null>(null);
	const classes = useReplyDialogStyles();

	const handleEmojiClick = (e: MouseEvent, data: IEmojiData) => {
		const { emoji } = data;
		const updatedValue = value + emoji;

		onChange(updatedValue);
	};

	return (
		<div className={classes.postContentContainer}>
			<EmojiPickerPopover
				anchorEl={emojiPickerAnchorEl}
				onEmojiClick={handleEmojiClick}
				onClose={() => setEmojiPickerAnchorEl(null)}
			/>
			<textarea
				className={classes.textArea}
				value={value}
				placeholder="Write a reply..."
				onChange={(e) => onChange(e.target.value)}
				onKeyPress={(e) => e.key === "Enter" && onSubmit()}
				style={{ height: 130, fontSize: 12 }}
			/>

			<div className={classes.postActions}>
				<Box
					onClick={(e) => setEmojiPickerAnchorEl(e.currentTarget)}
					className={classes.postAction}
				>
					<EmojiIconSvg size={22} />
				</Box>
				{/* <input
                accept='image/*|video/*' id='upload-input'
                type='file' multiple={true}
                style={{ display: 'none' }}
                onChange={handleFilesChange}
            /> */}
				{/* <Box
                onClick={() => document.getElementById("upload-input")?.click()}
                className={classes.postAction}
            >
                <CloudUploadS size={22} />
            </Box> */}
			</div>
		</div>
	);
};

export default ReplyInput;

import { Box, makeStyles } from "@material-ui/core";
import { CSSProperties, ReactNode, MouseEvent } from "react";

interface Props {
	children: ReactNode;
	style?: CSSProperties;
	disabled?: boolean;
	onClick: (e: MouseEvent<HTMLElement>) => void;
}

interface UseStylesProps {
	disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
	option: {
		borderTop: "1px solid #D5E1F5",
		color: theme.palette.text.primary,
		fontSize: 12,
		padding: 12,
		paddingTop: 8,
		paddingBottom: 8,
		cursor: (props: UseStylesProps) => (!props.disabled ? "pointer" : "default"),
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		"&:hover": {
			backgroundColor: (props: UseStylesProps) => (!props.disabled ? "rgb(247, 247, 247)" : ""),
		},
	},
}));

const MenuOption = (props: Props) => {
	const { children, style, disabled, onClick } = props;
	const classes = useStyles({ disabled });

	return (
		<Box
			onClick={(e) => !disabled && onClick(e)}
			className={classes.option}
			style={{
				...style,
				opacity: disabled ? 0.7 : 1,
			}}
		>
			{children}
		</Box>
	);
};

export default MenuOption;

import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import AttachIconSvg from "assets/SVG/AttachIconSvg";
import Button from "components/Basic/Button";
import AttachmentPreviewDialog from "components/Dialogs/AttachmentPreview/AttachmentPreview";
import MediaAttachment from "components/Media/MediaAttachment";
import { FileUploadProgress } from "redux/actions/media/upload";
import { Media } from "types/Task";
import PhotoEditorDialog from "../../../Editors/PhotoEditorDialog";
import useAttachmentsStyles from "../../styles/attachments";
import useTaskDialogStyles from "../../styles/task-dialog";
import { EditModeInputValues } from "../TaskDialog";

interface Props {
	inputValues: EditModeInputValues;
	mediaUploadProgress: FileUploadProgress[];
	onFilesChange: (files: FileList | null) => void;
	setInputValues: Dispatch<SetStateAction<EditModeInputValues>>;
}

const fileInputId = "upload-input";

const Attachments = (props: Props) => {
	const classes = useAttachmentsStyles();
	const [attachmentPreview, setAttachmentPreview] = useState<boolean>(false);
	const [selectedAttachment, setSelectedAttachment] = useState<Media>();
	const [imageEditor, setImageEditor] = useState<boolean>(false);
	const dialogClasses = useTaskDialogStyles();
	const { inputValues, mediaUploadProgress, onFilesChange, setInputValues } = props;
	const { t } = useTranslation("tasks");

	const handleAttachmentClick = (attachment: Media) => {
		setSelectedAttachment(attachment);
		setAttachmentPreview(true);
	};

	const handleAttachmentDelete = (attachment?: Media) => {
		if (!attachment) return;

		setInputValues({
			...inputValues,
			media: [
				...(inputValues.media?.filter((mediaItem) => mediaItem.value !== attachment.value) || []),
			],
		});
	};

	const handleImageEditorSubmit = (updatedFile: File) => {
		if (!selectedAttachment) return;

		const existingMediaItem = inputValues?.media?.findIndex(
			(item) => item.value === selectedAttachment.value
		);

		if (existingMediaItem && existingMediaItem > -1) {
			inputValues.media![existingMediaItem] = {
				...inputValues.media![existingMediaItem],
				value: updatedFile,
			};
		}

		setInputValues({
			...inputValues,
		});
		setSelectedAttachment(undefined);
		setImageEditor(false);
	};

	const handleFileInputClick = () => {
		const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
		if (fileInput) {
			fileInput.value = "";
		}
	};

	return (
		<Box style={{ marginTop: 16 }}>
			<PhotoEditorDialog
				selectedAttachment={selectedAttachment}
				open={imageEditor}
				onSubmit={handleImageEditorSubmit}
				onClose={() => setImageEditor(false)}
			/>

			<AttachmentPreviewDialog
				open={attachmentPreview}
				onClose={() => setAttachmentPreview(false)}
				attachment={selectedAttachment}
				onEditClick={() => {
					setImageEditor(true);
					setAttachmentPreview(false);
				}}
				onDeleteClick={() => handleAttachmentDelete(selectedAttachment)}
			/>

			{/* Upload Input */}
			<input
				accept="image/*|video/*"
				id={fileInputId}
				type="file"
				multiple={true}
				style={{ display: "none" }}
				onChange={(e) => onFilesChange(e.target.files)}
				onClick={handleFileInputClick}
			/>

			<Button
				secondary
				onClick={() => document.getElementById(fileInputId)?.click()}
				className={dialogClasses.actionButton}
			>
				<Box display="flex" alignItems="center" justifyContent="center">
					<AttachIconSvg width={17} height={19} />
					<Box style={{ marginLeft: 8 }}>{t("attachFiles")}</Box>
				</Box>
			</Button>

			{/* Attachments */}
			{Boolean(inputValues?.media?.length) && (
				<Box className={classes.mediaContainer}>
					{inputValues?.media?.map((mediaItem, index) => (
						<MediaAttachment
							key={index}
							type={mediaItem.type}
							style={{
								height: 40,
								width: 40,
								maxWidth: 40,
								borderRadius: 6,
								marginRight: 0,
							}}
							progressSize={22}
							onAttachmentClick={() => handleAttachmentClick(mediaItem)}
							uploadProgress={mediaUploadProgress[index]}
							attachment={mediaItem.value}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

export default Attachments;

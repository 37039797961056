import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "50%",
		marginRight: 16,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 10,
		paddingRight: 32,
	},

	title: {
		fontWeight: 600,
		fontSize: 18,
		paddingLeft: 32,
	},

	socialProfileInfo: {
		display: "flex",
		flexDirection: "row",
		width: "fit-content",
		marginLeft: "auto",
		alignItems: "center",
	},

	socialIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	profilePicture: {
		height: 23,
		marginRight: 8,
	},

	username: {
		fontSize: 14,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		marginRight: 8,
	},

	innerBox: {
		backgroundColor: "white",
		width: "100%",
		height: 160,
		borderRadius: 12,
		padding: 16,
	},

	linearContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	postContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "60%",
	},

	postAttachment: {
		height: 50,
		width: 50,
		marginLeft: 32,
		marginRight: 12,
		objectFit: "cover",
	},

	postText: {
		color: theme.palette.text.primary,
		fontSize: 12,
	},

	dateText: {
		color: "#868C91",
		fontSize: 12,
		fontWeight: 500,
		width: 100,
		marginLeft: "auto",
		opacity: 0.6,
		marginRight: 32,
	},

	dataItemContainer: {
		width: "25%",
		textAlign: "center",
		flexDirection: "column",
		display: "flex",
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useMyAccountSettingsStyles = makeStyles((theme) => ({
	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignitems: "center",
		marginBottom: 16,
		marginTop: 24,
	},

	cardTitle: {
		fontFamily: "Chau Philomene One",
		color: "#5B666E",
		fontSize: 16,
		marginLeft: 32,
	},

	innerCardTitle: {
		fontSize: 16,
		fontFamily: "Chau Philomene One",
		marginBottom: 16,
		color: theme.custom.palette.title,
	},

	card: {
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
		marginTop: 8,
		height: 350,
		padding: 32,
		position: "relative",
	},

	inputTitle: {
		fontSize: 14,
		marginBottom: 8,
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		letterSpacing: 0.4,
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: 52,
	},

	viewTitle: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		fontSize: 12,
	},

	headerText: {
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},
}));

export default useMyAccountSettingsStyles;

import { Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "components/Basic/Button";
import useTaskDialogStyles from "../../styles/task-dialog";

interface Props {
	onCancelClick: () => void;
	onSubmitClick: () => void;
	loading: boolean;
}

const EditModeActions = (props: Props) => {
	const classes = useTaskDialogStyles();
	const { onCancelClick, onSubmitClick, loading } = props;
	const { t } = useTranslation("tasks");

	return (
		<Box className={classes.actions}>
			<Button onClick={onCancelClick} secondary style={{ width: 100 }}>
				{t("cancel")}
			</Button>
			<Button disabled={loading} style={{ width: 150, marginLeft: 12 }} onClick={onSubmitClick}>
				{loading ? <CircularProgress size={20} /> : t("submit")}
			</Button>
		</Box>
	);
};

export default EditModeActions;

import { Box, CircularProgress, RadioGroup } from "@material-ui/core";
import Button from "components/Basic/Button";
import Card from "components/Basic/Card";
import NumberInput from "components/Basic/Inputs/NumberInput";
import Radio from "components/Basic/Inputs/Radio";
import HintText from "components/Basic/Text/HintText";
import Dialog from "components/Dialogs/components/Dialog";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
	getPlanUserAddonsPrice,
	updateTeamSubscription,
} from "redux/actions/team/subscriptions/plans";
import { StoreState } from "setup/store";
import TeamMembersAddonsPriceSummary from "./components/PriceSummary";
import TeamMembersAddonsWarnings from "./components/Warnings";
import useTeamMembersAddonsDialogStyles from "./styles";

interface Props {
	open: boolean;
	onClose: () => void;
}

export type ApplyChangesAtOption = "immediately" | "nextBillingPeriod";

export type TeamMembersAddonsDialogPayload = {
	extraTeamMembers?: number;
	applyChangesAt?: ApplyChangesAtOption;
};

const TeamMembersAddonsDialog = (props: Props) => {
	const [payload, setPayload] = useState<TeamMembersAddonsDialogPayload>({});
	const [submitLoading, setSubmitLoading] = useState(false);
	const classes = useTeamMembersAddonsDialogStyles();
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { currentTeamSubscription } = useSelector((store: StoreState) => store.subscription);
	const { t } = useTranslation("settings");
	const { open, onClose } = props;

	const userAddonPrice = useMemo(
		() =>
			currentTeamSubscription
				? getPlanUserAddonsPrice(
						currentTeamSubscription.planInfo.id,
						currentTeamSubscription.billingPeriodUnit === "month" ? "monthly" : "yearly"
				  )
				: 0,
		[currentTeamSubscription]
	);

	const handleSubmit = async () => {
		if (!currentTeamId || !currentTeamSubscription || !payload.extraTeamMembers) {
			return;
		}
		const { extraTeamMembers, applyChangesAt } = payload;

		const { billingPeriodUnit, planInfo, userAddons, paymentSourceId } = currentTeamSubscription;

		setSubmitLoading(true);
		await updateTeamSubscription(currentTeamId, {
			planId: planInfo.id,
			userAddons: (userAddons || 0) + extraTeamMembers,
			paymentMethodId: paymentSourceId,
			paymentPeriod: billingPeriodUnit === "month" ? "monthly" : "yearly",
			applyChangesImmediately: applyChangesAt === "immediately",
		});

		setSubmitLoading(false);
		onClose();
	};

	useEffect(() => {
		if (open) {
			setPayload({
				extraTeamMembers: 1,
				applyChangesAt: "immediately",
			});
		}
	}, [open]);

	if (!currentTeamSubscription) {
		return null;
	}

	const { billingPeriodUnit, status } = currentTeamSubscription;
	return (
		<Dialog
			containerStyle={{ width: "80vh", maxWidth: 800 }}
			titleStyle={{ marginLeft: 24 }}
			open={open}
			onClose={onClose}
			title={t("addExtraTeamMembers")}
		>
			<Card>
				<Box display="flex">
					<Box width="60%">
						<Box className={classes.sectionTitle}>{t("extraTeamMembers")}</Box>
						<HintText style={{ marginBottom: 16 }}>
							${userAddonPrice}/{billingPeriodUnit} per member
						</HintText>

						<NumberInput
							style={{ margin: 0, marginLeft: 32 }}
							value={payload.extraTeamMembers || 0}
							onChange={(value) => setPayload({ ...payload, extraTeamMembers: value })}
						/>
					</Box>

					<Box width="40%">
						<Box className={classes.sectionTitle}>{t("applyChangesAt")}:</Box>

						<RadioGroup
							style={{ marginTop: 16, marginLeft: 12 }}
							value={payload.applyChangesAt}
							onChange={(e) =>
								setPayload({ ...payload, applyChangesAt: e.target.value as ApplyChangesAtOption })
							}
						>
							<Radio value="immediately" label={t("immediately")} />
							<Radio value="nextBillingPeriod" label={t("nextBillingPeriod")} />
						</RadioGroup>
					</Box>
				</Box>
			</Card>

			<TeamMembersAddonsPriceSummary
				open={open}
				payload={payload}
				userAddonPrice={userAddonPrice}
			/>

			<TeamMembersAddonsWarnings payload={payload} userAddonPrice={userAddonPrice} />

			<Box display="flex" style={{ marginTop: 52, width: "fit-content", marginLeft: "auto" }}>
				<Button secondary style={{ width: 150, marginRight: 12 }} onClick={() => onClose()}>
					{`< ${t("back")}`}
				</Button>

				<Button
					style={{ width: 150 }}
					disabled={submitLoading || status == "cancelled" || status === "non_renewing"}
					onClick={handleSubmit}
				>
					{submitLoading ? <CircularProgress size={18} /> : t("submit")}
				</Button>
			</Box>
		</Dialog>
	);
};

export default TeamMembersAddonsDialog;

import { Box } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState, MouseEvent } from "react";
import UserPicture from "components/Media/UserPicture";
import { TaskComment } from "types/Task";
import { TeamUser } from "types/User/Team";
import useMessageStyles from "./styles";
import { Message as MessageType } from "types/Chat/Chat";
import MessageOptions from "./Options";
import { useDispatch } from "react-redux";
import { setUserInfoPopper } from "redux/actions/modals";

interface Props {
	message: Partial<TaskComment & MessageType>;
	teamUser: TeamUser;
	type: "message" | "comment";
}

const MessageHeader = (props: Props) => {
	const classes = useMessageStyles();
	const { message, teamUser, type } = props;
	const [date, setDate] = useState<string>("");
	const dispatch = useDispatch();

	useEffect(() => {
		let dateString = "";
		const date = moment(message.createdAt || message.updatedAt);
		const isToday = date.date() === moment().date();

		if (isToday) {
			dateString = date.format("hh:mm A");
		} else {
			dateString = date.format("DD MMM, hh:mm A");
		}

		setDate(dateString);
	}, [message]);

	const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
		dispatch(setUserInfoPopper({ anchorEl: e.currentTarget, userId: teamUser.user?._id! }));
	};

	const handleMouseLeave = () => {
		dispatch(setUserInfoPopper(null));
	};

	return (
		<Box className={classes.header}>
			<Box
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				display="flex"
				alignItems="center"
			>
				<UserPicture style={{ width: 25, height: 25, marginRight: 12 }} user={teamUser.user} />

				<Box className={classes.username}>
					{teamUser.user?.firstName} {teamUser.user?.lastName}
				</Box>
			</Box>
			<Box className={classes.date}>{date}</Box>

			<MessageOptions message={message} type={type} />
		</Box>
	);
};

export default MessageHeader;

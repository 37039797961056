import { makeStyles } from "@material-ui/core";

const useTaskCommentsStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		borderRadius: 8,
		background: "#F1F4F8",
		height: 390,
		padding: 24,
		paddingTop: 16,
	},

	sidebar: {
		width: "35%",
		background: "#D9E3EB",
		height: "100%",
		paddingRight: 16,
		paddingTop: 16,
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
	},

	content: {
		width: "65%",
		position: "relative",
	},

	comments: {
		height: "75%",
		overflowY: "scroll",
	},
}));

export default useTaskCommentsStyles;

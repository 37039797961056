import moment from "moment";
import React from "react";
import Campaign from "types/Campaign";
import useStyles from "./styles/campaign-styles";

interface Props {
	campaign: Campaign;
	onClick?: (campaign: Campaign) => void;
}

export default function CampaignCard(props: Props) {
	const classes = useStyles();
	const { campaign, onClick } = props;
	const startDate = moment(campaign.startDate);
	const endDate = moment(campaign.endDate);

	return (
		<div
			onClick={() => onClick && onClick(campaign)}
			className={classes.container}
			style={{ backgroundColor: campaign.active ? "#FED651" : "#EDF0F7" }}
		>
			<div className={classes.marker} style={{ background: campaign.color }}></div>
			<span className={classes.title}>{campaign.title}</span>
			<div className={classes.dateContainer}>
				<span className={classes.dateText}>
					{`${startDate.format("MM")}.${startDate.format("DD")} - ${endDate.format(
						"MM"
					)}.${endDate.format("DD")}`}
				</span>
			</div>
		</div>
	);
}

import { makeStyles } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { ReactNode } from "react";
import { EmptyIllustration } from "assets";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
	},

	title: {
		fontSize: 36,
		fontWeight: 700,
		marginBottom: 24,
		textTransform: "uppercase",
		textAlign: "center",
		color: theme.palette.primary.main,
	},

	subtitle: {
		color: theme.palette.text.primary,
		textAlign: "center",
		marginBottom: 24,
		fontSize: 16,
	},
}));

interface Props {
	title: string;
	titleStyle?: CSSProperties;
	subTitleStyle?: CSSProperties;
	illustrationStyle?: CSSProperties;
	subtitle?: string;
	children?: ReactNode;
}

const EmptyPageMessage = (props: Props) => {
	const classes = useStyles();
	const { title, titleStyle, subtitle, subTitleStyle, illustrationStyle, children } = props;
	return (
		<div className={classes.container}>
			<div style={{ ...titleStyle }} className={classes.title}>
				{title}
			</div>
			{subtitle && (
				<div style={{ ...subTitleStyle }} className={classes.subtitle}>
					{subtitle}
				</div>
			)}

			{children}
			<img
				src={EmptyIllustration}
				style={{
					width: 500,
					...illustrationStyle,
				}}
			/>
		</div>
	);
};

export default EmptyPageMessage;

import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../../../../setup/store";
import Team from "types/User/Team";
import usePageStyles from "../../../../page-styles";
import TeamInfoSettings from "./components/TeamInfoSettings";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import LinkText from "components/Basic/Text/LinkText";
import DeleteIconSvg from "assets/SVG/DeleteIconSvg";
import { deleteTeam, leaveTeam, setCurrentTeamId } from "redux/actions/team/post";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { LeaveWorkspaceIllustration, RemoveWorkspaceIllustration } from "assets";
import { useTranslation } from "react-i18next";
import TeamUsers from "./components/TeamUsers";
import HintText from "components/Basic/Text/HintText";

interface Props {
	team?: Team;
	newTeam: boolean;
	setNewTeam: Dispatch<SetStateAction<boolean>>;
}

export const TeamDetails = (props: Props) => {
	const pageClasses = usePageStyles();
	const { t } = useTranslation("workspaces");
	const [userOwnedTeams, setUserOwnedTeams] = useState<Team[]>([]);
	const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
	const [leaveConfirmation, setLeaveConfirmation] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const { userData } = useSelector((state: StoreState) => state.user);
	const { teams } = useSelector((state: StoreState) => state.teams);
	const { team, newTeam, setNewTeam } = props;
	const { role: currentUserRole } =
		team?.users.find((teamUser) => teamUser.user?._id === userData?._id) || {};

	const dispatch = useDispatch();

	const handleRemoveWorkspace = () => {
		if (!team || deleteLoading) return;

		setDeleteLoading(true);
		dispatch(
			deleteTeam(team._id, () => {
				setDeleteLoading(false);
				setRemoveConfirmation(false);
				const newCurrentTeamId = teams.find(({ _id }) => _id !== team._id)?._id;
				if (newCurrentTeamId) {
					dispatch(setCurrentTeamId(newCurrentTeamId));
				}
			})
		);
	};

	const handleLeaveWorkspace = () => {
		if (!team || deleteLoading) return;
		setDeleteLoading(true);
		dispatch(
			leaveTeam(team._id, () => {
				setDeleteLoading(false);
				setLeaveConfirmation(false);
			})
		);
	};

	useEffect(() => {
		if (teams)
			// All teams that the current user has owner status over. Used to force the user to have at least 1 team that they own and not less
			setUserOwnedTeams(
				teams.filter((team) =>
					team.users.find(
						(teamUser) => teamUser.user?._id === userData?._id && teamUser.role === "owner"
					)
				)
			);
	}, [teams]);

	return (
		<div style={{ width: "53%", marginRight: 16 }}>
			<ConfirmationDialog
				open={removeConfirmation}
				message={t("removeWorkspaceConfirm")}
				onClose={() => setRemoveConfirmation(false)}
				confirmButton={t("remove")}
				messageStyle={{ fontSize: 14, maxWidth: 400 }}
				image={RemoveWorkspaceIllustration}
				onAction={handleRemoveWorkspace}
				actionLoading={deleteLoading}
			/>

			<ConfirmationDialog
				open={leaveConfirmation}
				message={t("leaveWorkspaceConfirm")}
				confirmButton={t("leave")}
				messageStyle={{ fontSize: 14, maxWidth: 400 }}
				image={LeaveWorkspaceIllustration}
				actionLoading={deleteLoading}
				onAction={handleLeaveWorkspace}
				onClose={() => setLeaveConfirmation(false)}
			/>

			<div className={pageClasses.cardTitle}>
				{team?.name || t("new")}&nbsp;{t("workspace")}
			</div>

			<div
				style={{ height: "70vh", maxHeight: 1000, overflowY: "auto" }}
				className={pageClasses.card}
			>
				{(currentUserRole === "owner" || newTeam) && (
					<TeamInfoSettings team={team} setNewTeam={setNewTeam} />
				)}

				<TeamUsers team={team} currentUserRole={currentUserRole} />

				{/* Hide the delete workspace button if the user has only 1 owned workspace remaining */}
				{team && !(currentUserRole === "owner" && userOwnedTeams?.length! < 2) && (
					<LinkText
						onClick={() =>
							currentUserRole === "owner" ? setRemoveConfirmation(true) : setLeaveConfirmation(true)
						}
						style={{ marginTop: 24 }}
					>
						<Box display="flex" alignItems="center">
							<DeleteIconSvg height={16} width={13} />
							<span style={{ marginLeft: 8, fontSize: 12 }}>
								{currentUserRole === "owner" ? t("remove") : t("leave")} {t("workspace")}
							</span>
						</Box>
					</LinkText>
				)}

				<HintText style={{ marginTop: 32 }}>
					{t("adminHintText")}
					<br />
					<br />
					{t("writerHintText")}
				</HintText>
			</div>
		</div>
	);
};

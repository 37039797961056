import { Box, makeStyles, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AuthProviderLsKey } from "config";
import ChangePassword from "./ChangePassword";
import ChangeProfileInfo from "./ChangeProfileInfo";
import usePageStyles from "../../styles";
import { useHistory } from "react-router";
import { HeroIllustrationGrey } from "assets";
import { getSettingPageRoute } from "../../../..";

const useStyles = makeStyles(() => ({
	illustrationContainer: {
		position: "absolute",
		bottom: 0,
		right: 0,
	},
}));

const EditPersonalInfo = () => {
	const pageClasses = usePageStyles();
	const theme = useTheme();
	const { t } = useTranslation("settings");
	const authProvider = localStorage.getItem(AuthProviderLsKey);
	const history = useHistory();
	const classes = useStyles();

	return (
		<Box>
			<Box className={pageClasses.viewTitle}>
				<span
					onClick={() => history.push(getSettingPageRoute("myAccount", "overview"))}
					className={pageClasses.headerText}
				>
					{t("profile")}
				</span>
				<span>&nbsp;{" > "}</span>
				<span className={pageClasses.headerText} style={{ color: theme.palette.primary.main }}>
					&nbsp;{t("personalInformation")}
				</span>
			</Box>

			<Box style={{ marginTop: 32 }} display="flex" alignItems="center">
				<ChangeProfileInfo />

				{authProvider !== "Google" && authProvider !== "Facebook" && <ChangePassword />}
			</Box>

			<Box className={classes.illustrationContainer}>
				<img
					style={{ height: "40vh", marginRight: 62 }}
					src={HeroIllustrationGrey}
					alt="heros-illustration"
				/>
			</Box>
		</Box>
	);
};

export default EditPersonalInfo;

import { Box } from "@material-ui/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AdminIconSvg from "assets/SVG/AdminIconSvg";
import WriterIconSvg from "assets/SVG/WriterIconSvg";
import { getUserData } from "redux/actions/user/user-data";
import { StoreState } from "../../../../setup/store";
import { TeamUser } from "types/User/Team";
import UserData from "types/User/UserData";
import useTaskAssignmentStyles from "../../styles/assignments";
import useTaskDialogStyles from "../../styles/task-dialog";
import TaskAssignee from "../components/Assignee";
import { EditModeInputValues } from "../TaskDialog";

interface Props {
	inputValues: EditModeInputValues;
	setInputValues: Dispatch<SetStateAction<EditModeInputValues>>;
}

const Assignments = (props: Props) => {
	const { inputValues, setInputValues } = props;
	const classes = useTaskAssignmentStyles();
	const dialogClasses = useTaskDialogStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation("tasks");
	const [users, setUsers] = useState<TeamUser[]>([]);
	const { teams, currentTeamId } = useSelector((state: StoreState) => state.teams);

	const handleUserClick = (selectedUser: UserData) => {
		let assignees = [...(inputValues.assignees || [])];
		let userFound = false;

		assignees.forEach((user, index) => {
			if (user._id === selectedUser._id) {
				userFound = true;
				assignees.splice(index, 1);
			}
		});

		if (!userFound) {
			assignees.push(selectedUser);
		}

		setInputValues({ ...inputValues, assignees });
	};

	useEffect(() => {
		dispatch(getUserData({ populateTeamUsers: true }));
	}, []);

	useEffect(() => {
		if (teams.length) {
			const currentTeam = teams.find((team) => team._id === currentTeamId);
			if (currentTeam) {
				setUsers(currentTeam.users);
			}
		}
	}, [teams]);

	return (
		<Box style={{ paddingTop: 24 }}>
			<div className={dialogClasses.inputTitle}>{t("assignments")}</div>
			<Box className={classes.container}>
				<Box className={classes.labelContainer}>
					<AdminIconSvg size={20} />
					<Box className={classes.label}>{t("admins")}:</Box>
				</Box>

				<Box style={{ marginTop: 8 }}>
					{users
						.filter((user) => user.role !== "writer")
						.map((teamUser, index) => (
							<TaskAssignee
								inputValues={inputValues}
								onClick={handleUserClick}
								editMode={true}
								key={index}
								teamUser={teamUser}
							/>
						))}
				</Box>

				{Boolean(users.filter((user) => user.role === "writer").length) && (
					<>
						<Box className={classes.labelContainer}>
							<WriterIconSvg size={18} />
							<Box className={classes.label}>{t("writers")}:</Box>
						</Box>

						<Box style={{ marginTop: 8 }}>
							{users
								.filter((user) => user.role === "writer")
								.map((teamUser, index) => (
									<TaskAssignee
										inputValues={inputValues}
										onClick={handleUserClick}
										editMode={true}
										key={index}
										teamUser={teamUser}
									/>
								))}
						</Box>
					</>
				)}
			</Box>
		</Box>
	);
};

export default Assignments;

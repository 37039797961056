export default function WarningIconSvg({ size, color }: { size: number; color?: string }) {
	return (
		<svg
			width={size || "28"}
			height={size || "28"}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.0002 2.9165L25.6668 15.4806L24.4805 16.5822L14.0002 5.29563L3.5198 16.5822L2.3335 15.4806L14.0002 2.9165ZM14.0002 11.8204L25.6668 24.3845L24.4805 25.486L14.0002 14.1995L3.5198 25.486L2.3335 24.3845L14.0002 11.8204Z"
				fill={color || "black"}
			/>
		</svg>
	);
}

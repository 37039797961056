import { Box } from "@material-ui/core";
import { useState, useEffect, ChangeEvent } from "react";
import Task from "types/Task";
import EditModeHeader from "./Header";
import Inputs from "./Inputs";
import AddSocialProfiles from "./AddSocialProfiles";
import Assignments from "./Assignments";
import Attachments from "./Attachments";
import { FileUploadProgress, handleUploadProgressChange } from "redux/actions/media/upload";
import EditModeActions from "./Actions";
import { useDispatch, useSelector } from "react-redux";
import { submitTask } from "redux/actions/tasks/submit";
import { StoreState } from "../../../../setup/store";
import { SetStateAction, Dispatch } from "react";
import { setTaskDialog } from "redux/actions/modals";
import { EditModeInputValues } from "../TaskDialog";

interface Props {
	selectedTask?: Task;
	inputValues: EditModeInputValues;
	onFilesChange: (files: FileList | null) => void;
	setInputValues: Dispatch<SetStateAction<EditModeInputValues>>;
	onSubmit?: (updatedTask: Task, deleted?: boolean) => void;
	setEditMode: Dispatch<SetStateAction<boolean>>;
	onClose: () => void;
}

const TaskDialogEditMode = (props: Props) => {
	const [mediaUploadProgress, setMediaUploadProgress] = useState<FileUploadProgress[]>([]);
	const { taskDialog } = useSelector((state: StoreState) => state.modals);
	const { submitTaskError: errors, submitTaskLoading: loading } = useSelector(
		(state: StoreState) => state.tasks
	);

	const {
		selectedTask,
		inputValues,
		onFilesChange,
		setInputValues,
		setEditMode,
		onClose,
		onSubmit,
	} = props;
	const dispatch = useDispatch();

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleSubmit = () => {
		dispatch(
			submitTask(
				inputValues,
				selectedTask?._id,
				(newProgress) =>
					handleUploadProgressChange(mediaUploadProgress, setMediaUploadProgress, newProgress),
				(task) => {
					setMediaUploadProgress([]);
					if (task) {
						setEditMode(false);
						dispatch(setTaskDialog(true, task, taskDialog?.onSubmit));
					}

					if (task) {
						onSubmit?.(task);
					}
				}
			)
		);
	};

	useEffect(() => {
		if (selectedTask) {
			setInputValues({ ...selectedTask });
		} else {
			setInputValues({});
		}
	}, [selectedTask]);

	return (
		<Box>
			<EditModeHeader
				selectedTask={selectedTask}
				inputValues={inputValues}
				setInputValues={setInputValues}
			/>

			{/* Inputs */}
			<Box display="flex">
				<Box width="50%" style={{ marginRight: 16 }}>
					<Inputs
						inputValues={inputValues}
						errors={errors || {}}
						setInputValues={setInputValues}
						onSubmit={handleSubmit}
						handleInputChange={handleInputChange}
					/>
					<AddSocialProfiles inputValues={inputValues} setInputValues={setInputValues} />
				</Box>
				<Box width="49.5%" style={{ marginLeft: 16 }}>
					<Assignments inputValues={inputValues} setInputValues={setInputValues} />
					<Attachments
						inputValues={inputValues}
						mediaUploadProgress={mediaUploadProgress}
						setInputValues={setInputValues}
						onFilesChange={onFilesChange}
					/>
				</Box>
			</Box>

			{/* Actions */}
			<EditModeActions
				onCancelClick={() => (selectedTask ? setEditMode(false) : onClose())}
				onSubmitClick={handleSubmit}
				loading={loading}
			/>
		</Box>
	);
};

export default TaskDialogEditMode;

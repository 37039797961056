import { makeStyles } from "@material-ui/core";

const useCountryAnalyticsStyles = makeStyles((theme) => ({
	card: {
		height: 350,
		width: "49.5%",
		marginRight: 12,
		backgroundColor: "white",
		borderRadius: 10,
		padding: 28,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		paddingLeft: 100,
		paddingTop: 12,
	},

	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	rowItem: {
		textAlign: "right",
		width: 90,
		fontFamily: "Avenir",
		fontSize: 12,
		paddingTop: 6,
	},

	country: {
		fontWeight: 500,
		width: 120,
		fontSize: 14,
	},

	cardTitle: {
		fontSize: 16,
		fontFamily: "Avenir",
	},
}));

export default useCountryAnalyticsStyles;

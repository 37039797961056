import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { CSSProperties, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { createTaskComment } from "redux/actions/tasks/comments/create";
import { listTaskComments } from "redux/actions/tasks/comments/get";
import { StoreState } from "../../../../setup/store";
import Task from "types/Task";
import Message from "../../../Chat/components/Message/Message";
import MessageInput, { CommentPayload } from "../../../Chat/components/MessageInput";
import MessagesSkeleton from "../../LoadingSkeletons/CommentsSkeleton";

interface Props {
	task: Task;
	style?: CSSProperties;
	infiniteScrollHeight?: number;
}

const CommentsList = (props: Props) => {
	const { task, style, infiniteScrollHeight } = props;
	const {
		taskComments,
		commentsLoading,
		taskCommentsTotal: total,
	} = useSelector((state: StoreState) => state.tasks);
	const messagesEnd = useRef<HTMLDivElement | null>(null);
	const dispatch = useDispatch();
	const { t } = useTranslation("tasks");

	const handleCommentSubmit = (comment: CommentPayload) => {
		dispatch(createTaskComment(task._id, comment));
	};

	const scrollToBottom = () => {
		messagesEnd.current?.scrollIntoView({ behavior: "smooth" });
	};

	const fetchMore = () => {
		dispatch(listTaskComments(task._id, { from: taskComments.length, pageLimit: 10 }));
	};

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 100);
	}, [taskComments, commentsLoading]);

	useEffect(() => {
		dispatch(listTaskComments(task._id, { pageLimit: 10 }));
	}, []);

	return (
		<Box
			style={{
				position: "relative",
				overflow: "hidden",
				height: "85%",
				...style,
			}}
		>
			<InfiniteScroll
				dataLength={taskComments.length}
				next={fetchMore}
				inverse={true}
				height={infiniteScrollHeight || 420}
				style={{
					overflowX: "hidden",
					display: "flex",
					flexDirection: "column-reverse",
				}}
				loader={
					<Box paddingTop={6} display="flex" justifyContent="center">
						<CircularProgress size={20} color="primary" />
					</Box>
				}
				hasMore={total > taskComments.length}
			>
				<Box style={{ paddingLeft: 32 }}>{commentsLoading && <MessagesSkeleton />}</Box>

				{!commentsLoading &&
					taskComments.map((comment, index) => {
						let nextComment = taskComments[index + 1];
						let hideHeader = false;
						if (nextComment) {
							const { createdAt, user } = nextComment;
							if (
								moment(comment.createdAt).diff(moment(createdAt), "minutes") < 10 &&
								user === comment.user
							) {
								hideHeader = true;
							}
						}

						return (
							<Message key={comment._id} type="comment" message={comment} hideHeader={hideHeader} />
						);
					})}
			</InfiniteScroll>
			<div ref={messagesEnd}></div>
			<MessageInput placeholder={t("yourComment")} onSubmit={handleCommentSubmit} />
		</Box>
	);
};

export default CommentsList;

import { makeStyles } from "@material-ui/core";

const usePageStyles = makeStyles(() => ({
	pageContainer: {
		position: "relative",
		backgroundColor: "#F7F7F7",
		minHeight: "90vh",
		paddingTop: 24,
		width: "100%",
		paddingBottom: 82,
	},

	innerContainer: {
		width: "100%",
		maxWidth: 1100,
		marginLeft: "auto",
		marginRight: "auto",
	},

	cardTitle: {
		fontFamily: "Chau Philomene One",
		color: "#5B666E",
		fontSize: 22,
		marginLeft: 32,
	},

	card: {
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
		marginTop: 12,
		minHeight: 320,
		padding: 32,
		position: "relative",
	},

	linearContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	loadingContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
	},
}));

export default usePageStyles;

import { CognitoUserPool, UserData as CognitoUserData } from "amazon-cognito-identity-js";
import axios from "axios";
import { Dispatch } from "react";
import config from "config";
import { getCognitoClientId } from "helpers/utils";
import { StoreState } from "../../../setup/store";
import { CheckAuthFunc } from "types/ReduxActions";
import UserData from "types/User/UserData";
import { SET_AUTH_LOADING, CHECK_AUTH_RESOPNSE, SET_USER_DATA } from "../../reducers/user";

export const EMAIL_NOT_VERIFIED_ERROR = "EmailNotVerified";

export const checkAuth: CheckAuthFunc = (callback) => {
	return (dispatch: Dispatch<any>, getState: () => StoreState) => {
		let { userData } = getState().user;

		dispatch({
			type: SET_AUTH_LOADING,
			payload: true,
		});

		const poolData = {
			ClientId: getCognitoClientId(),
			UserPoolId: config.UserPoolId,
		};

		const userPool = new CognitoUserPool(poolData);

		const cognitoUser = userPool.getCurrentUser();

		if (!cognitoUser) {
			dispatch({
				type: SET_AUTH_LOADING,
				payload: false,
			});

			if (callback) callback(null);
		}

		cognitoUser?.getSession((err: any) => {
			cognitoUser.getUserData(async (err, cognitoUserData) => {
				if (err) {
					if (callback) callback(null);
					dispatch({
						type: CHECK_AUTH_RESOPNSE,
						payload: null,
					});
				} else {
					if (!userData) {
						let headers: { [index: string]: string } = {};
						let accessToken: string | null = "";
						let idToken: string | null = "";

						Object.keys(localStorage).forEach((key: string) => {
							if (key.endsWith("accessToken")) {
								accessToken = localStorage.getItem(key);
							} else if (key.endsWith("idToken")) {
								idToken = localStorage.getItem("key");
							}
						});

						headers["Authorization"] = `Bearer ${accessToken}, IDToken ${idToken}`;
						try {
							const result = await axios.get(`${config.apiUrl}/user/current`, {
								headers,
							});
							userData = result.data;
							dispatch({
								type: SET_USER_DATA,
								payload: { userData: result.data, authenticated: Boolean(cognitoUserData) },
							});

							if (callback) {
								callback(cognitoUserData as CognitoUserData, userData as UserData);
							}

							dispatch({ type: CHECK_AUTH_RESOPNSE, payload: cognitoUserData });
						} catch (e) {
							console.log(e);
							dispatch({
								type: SET_AUTH_LOADING,
								payload: false,
							});
							if (callback) {
								callback(null, null, e.response.data);
							}
						}
					} else {
						if (callback) {
							dispatch({ type: CHECK_AUTH_RESOPNSE, payload: cognitoUserData });
							callback(cognitoUserData as CognitoUserData, userData as UserData);
						}
					}
				}
			});
		});
	};
};

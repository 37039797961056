import Campaign from "types/Campaign";
import { ReduxAction } from "types/ReduxActions";

export interface CampaignsReducerState {
	campaigns: Campaign[];
	nextCampaign: Campaign | null;
	listCampaignsLoading: boolean;

	createCampaignSuccess: boolean;
	deleteCampaignSuccess: boolean;

	createCampaignLoading: boolean;
	deleteCampaignLoading: boolean;

	createCampaignErrors: { [index: string]: string } | null;
}

let initialState = {
	campaigns: [],
	nextCampaign: null,
	listCampaignsLoading: false,

	createCampaignSuccess: false,
	deleteCampaignSuccess: false,

	createCampaignLoading: false,
	deleteCampaignLoading: false,

	createCampaignErrors: null,
};

export const CREATE_CAMPAIGN_LOADING = "CREATE_CAMPAIGN_LOADING";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_ERROR = "CREATE_CAMPAIGN_ERROR";

export const DELETE_CAMPAIGN_LOADING = "DELETE_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";

export const LIST_CAMPAIGNS_LOADING = "LIST_CAMPAIGNS_LOADING";
export const LIST_CAMPAIGNS_RESULT = "LIST_CAMPAIGNS_RESULTS";

export const SET_NEXT_CAMPAIGN = "SET_NEXT_CAMPAIGN";

export default function campaignsReducer(
	state: CampaignsReducerState = initialState,
	action: ReduxAction
) {
	switch (action.type) {
		case SET_NEXT_CAMPAIGN: {
			return {
				...state,
				nextCampaign: action.payload,
			};
		}
		case CREATE_CAMPAIGN_LOADING: {
			return {
				...state,
				createCampaignLoading: true,
			};
		}
		case CREATE_CAMPAIGN_ERROR: {
			return {
				...state,
				createCampaignLoading: false,
				createCampaignErrors: action.payload,
			};
		}
		case CREATE_CAMPAIGN_SUCCESS: {
			return {
				...state,
				createCampaignLoading: false,
				createCampaignSuccess: action.payload,
				createCampaignErrors: null,
			};
		}

		case LIST_CAMPAIGNS_LOADING: {
			return {
				...state,
				listCampaignsLoading: action.payload,
			};
		}

		case LIST_CAMPAIGNS_RESULT: {
			return {
				...state,
				listCampaignsLoading: false,
				campaigns: action.payload,
			};
		}

		case DELETE_CAMPAIGN_LOADING: {
			return {
				...state,
				deleteCampaignLoading: action.payload,
			};
		}
		case DELETE_CAMPAIGN_SUCCESS: {
			return {
				...state,
				deleteCampaignLoading: false,
				deleteCampaignSuccess: action.payload,
			};
		}

		default:
			return state;
	}
}

import { Box, CircularProgress, Fade } from "@material-ui/core";
import { useRef, useState } from "react";
import { useEffect } from "react";
import ImageEditor from "tui-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import Button from "components/Basic/Button";
import { base64toBlob, toBase64 } from "helpers/utils";
import "./editor-styles.css";
import useEditorStyles from "./styles/styles";

interface Props {
	image: File | string;
	onCancelClick: () => void;
	onSubmit: (image: File) => void;
}

const PhotoEditor = (props: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [options, setOptions] = useState<any>({});
	const editorRef = useRef<ImageEditor>();
	const classes = useEditorStyles();
	const { image, onSubmit, onCancelClick } = props;

	const loadImage = async () => {
		if (!image) return;
		setLoading(true);
		const file = await toBase64(image);

		setOptions({
			includeUI: {
				initMenu: "filter",
				menuBarPosition: "bottom",
				loadImage: { path: file, name: "Blank" },
				theme: {
					"common.backgroundColor": "#F7F7F7",
					"submenu.backgroundColor": "white",
					"common.fontFamily": "Avenir",
					"submenu.normalLabel.color": "#3E4C55",
					"submenu.activeLabel.color": "#3E4C55",
					"checkbox.border": "1px solid #3E4C55",
					"checkbox.backgroundColor": "#F7F7F7",
					"range.pointer.color": "#F04755",
					"menu.iconSize.width": "23px",
					"menu.iconSize.height": "23px",
				},
			},
			cssMaxWidth: 700,
			selectionStyle: {
				cornerSize: 20,
				rotatingPointOffset: 70,
			},
			usageStatistics: false,
		});

		setLoading(false);
	};

	const handleSaveClick = () => {
		const base64String = editorRef.current?.toDataURL();
		if (base64String) {
			const imageData = base64String?.split(",")[1];
			const imageType = base64String?.split(":")[1].split(";")[0];
			const blob = base64toBlob(imageData, imageType);
			const file = new File([blob], "image");

			onSubmit && onSubmit(file);
		}
	};

	useEffect(() => {
		const imageEditor = new ImageEditor("#tui-image-editor-container", options);
		editorRef.current = imageEditor;
	}, [options]);

	useEffect(() => {
		loadImage();
	}, [image]);

	return (
		<div className={classes.container}>
			<div className={classes.innerContainer}>
				<Fade unmountOnExit timeout={{ enter: 500, exit: 0 }} in={loading}>
					<div className={classes.loadingContainer}>
						<CircularProgress size={25} />
					</div>
				</Fade>

				<Fade unmountOnExit timeout={{ enter: 500, exit: 0 }} in={!loading}>
					<Box>
						<div className={classes.buttonsContainer}>
							<Button onClick={onCancelClick} secondary style={{ marginRight: 16, width: 150 }}>
								Back to Posting
							</Button>
							<Button onClick={handleSaveClick} style={{ width: 150 }}>
								Save
							</Button>
						</div>
					</Box>
				</Fade>
				<div id="tui-image-editor-container"></div>
			</div>
		</div>
	);
};

export default PhotoEditor;

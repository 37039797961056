import { Box } from "@material-ui/core";
import HintText from "components/Basic/Text/HintText";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "setup/store";
import CancelledSubscriptionButton from "./CancelledSubscriptionButton";
import ScheduledChangesButton from "./ScheduledChangesButton";

const CurrentSubscriptionHintText = () => {
	const { t } = useTranslation("settings");
	const { currentTeamSubscription } = useSelector((state: StoreState) => state.subscription);

	if (!currentTeamSubscription) {
		return <></>;
	}

	const { currentTermEnd, status, userAddons, hasScheduledChanges } = currentTeamSubscription;
	const currentTermEndDate = moment.unix(currentTermEnd).format("DD MMM yyyy");

	return (
		<Box style={{ marginTop: 24 }}>
			{Boolean(userAddons) && (
				<HintText style={{ fontSize: 12 }}>
					{t("extraTeamMembers")} - {userAddons}
				</HintText>
			)}

			{status === "active" && (
				<HintText style={{ marginTop: 8, fontSize: 12 }}>
					{t("nextBillingDate")} - {currentTermEndDate}
				</HintText>
			)}

			{status === "non_renewing" && (
				<HintText isWarningIconVisible style={{ marginTop: 8, fontSize: 12 }}>
					{t("scheduledCancellation")} - {currentTermEndDate}
				</HintText>
			)}

			{status === "cancelled" && <CancelledSubscriptionButton style={{ marginTop: 8 }} />}

			{hasScheduledChanges && <ScheduledChangesButton style={{ marginTop: 8 }} />}
		</Box>
	);
};
export default CurrentSubscriptionHintText;

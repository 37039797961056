import { Box } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import MenuDotsSvg from "assets/SVG/MenuDotsSvg";
import { StoreState } from "../../../../setup/store";
import { TaskComment } from "types/Task";
import MessageOptionsMenu from "../MessageOptionsMenu";
import { Message as MessageType } from "types/Chat/Chat";

interface MenuDotsProps {
	message: Partial<TaskComment & MessageType>;
	type: "message" | "comment";
}

const MessageOptions = (props: MenuDotsProps) => {
	const { message, type } = props;
	const { userData } = useSelector((state: StoreState) => state.user);
	const [optionsMenu, setOptionsMenu] = useState<HTMLElement | null>(null);

	if (userData?._id !== message.sender && userData?._id !== message.user) {
		return <></>;
	}

	return (
		<>
			<MessageOptionsMenu
				anchorEl={optionsMenu}
				message={message}
				type={type}
				onClose={() => setOptionsMenu(null)}
			/>

			<Box
				onClick={(e) => setOptionsMenu(e.currentTarget)}
				style={{ cursor: "pointer", paddingLeft: 6, paddingRight: 6, marginTop: 6 }}
				width="fit-content"
				marginLeft="auto"
			>
				<MenuDotsSvg height={12} />
			</Box>
		</>
	);
};

export default MessageOptions;

import { Box, Dialog, IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
import { KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import CloseIconSvg from "assets/SVG/CloseIconSvg";
import DownloadIconSvg from "assets/SVG/DownloadIconSvg";
import ExpandIconSvg from "assets/SVG/ExpandIconSvg";
import { downloadFiles } from "redux/actions/media/download";
import { Media } from "types/Task";
import { TeamUser } from "types/User/Team";
import UserPicture from "../../Media/UserPicture";
import { getAttachmentPath } from "../AttachmentPreview/utils";
import useFullScreenPreviewStyles from "./styles";

interface Props {
	open: boolean;
	author?: TeamUser;
	createdAt?: string;
	media: Media[];
	initialSelectedIndex: number;
	onClose: () => void;
}

const FullScreenMediaPreview = (props: Props) => {
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [path, setPath] = useState<string>();
	const { t } = useTranslation();
	const { open, author, createdAt, media, initialSelectedIndex, onClose } = props;
	const classes = useFullScreenPreviewStyles();

	const getPath = async () => {
		const path = await getAttachmentPath(media[currentIndex]);
		setPath(path);
	};

	const handleDownloadClick = () => {
		downloadFiles(media.map((item) => item.value.toString()));
	};

	const handlePaperClick = () => {
		onClose();
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
		if (e.key === "ArrowRight") {
			if (media.length - 1 > currentIndex) {
				setCurrentIndex(currentIndex + 1);
			}
		} else if (e.key === "ArrowLeft") {
			if (currentIndex !== 0) {
				setCurrentIndex(currentIndex - 1);
			}
		} else if (e.key === "Escape") {
			onClose();
		}
	};

	useEffect(() => {
		if (open) {
			getPath();
		}
	}, [open, currentIndex]);

	useEffect(() => {
		if (initialSelectedIndex) {
			setCurrentIndex(initialSelectedIndex);
		}
	}, [open, initialSelectedIndex]);

	return (
		<Dialog
			onKeyDown={handleKeyDown}
			classes={{
				root: classes.root,
				paper: classes.paper,
			}}
			PaperProps={{
				onClick: handlePaperClick,
			}}
			onClose={onClose}
			open={open}
		>
			<Box className={classes.header}>
				{author && (
					<Box display="flex" alignItems="center">
						<UserPicture user={author.user} />
						<Box className={classes.authorName}>
							{author.user?.firstName} {author.user?.lastName}
						</Box>

						<Box className={classes.role}>{author.role}</Box>
					</Box>
				)}

				{createdAt && <Box className={classes.role}>{moment(createdAt).format("hh:mm A")}</Box>}

				<Box onClick={onClose} style={{ cursor: "pointer" }} marginLeft="auto" width="fit-content">
					<CloseIconSvg color="white" size={18} />
				</Box>
			</Box>

			<Box
				onClick={(e) => e.stopPropagation()}
				width="fit-content"
				margin="auto"
				display="flex"
				alignItems="center"
			>
				{path && currentIndex !== 0 && (
					<IconButton
						onClick={() => setCurrentIndex(currentIndex - 1)}
						style={{
							marginRight: 32,
							backgroundColor: "#999999",
							transform: "rotate(90deg) scaleX(-1)",
						}}
					>
						<ExpandIconSvg color="black" size={25} light />
					</IconButton>
				)}

				{media[currentIndex]?.type === "image" && (
					<img alt="preview" className={classes.image} src={path} />
				)}

				{media[currentIndex]?.type === "video" && (
					<ReactPlayer url={path} width={"100%"} height={"100%"} controls={true} />
				)}

				{path && media[currentIndex + 1] && (
					<IconButton
						onClick={() => setCurrentIndex(currentIndex + 1)}
						style={{
							marginLeft: 32,
							backgroundColor: "#999999",
							transform: "rotate(270deg) scaleX(-1)",
						}}
					>
						<ExpandIconSvg color="black" size={25} light />
					</IconButton>
				)}
			</Box>

			<Box className={classes.actionsContainer}>
				<Tooltip title={t("download") as string}>
					<IconButton onClick={handleDownloadClick}>
						<DownloadIconSvg strokeWidth={2} size={25} color="white" />
					</IconButton>
				</Tooltip>
			</Box>
		</Dialog>
	);
};

export default FullScreenMediaPreview;

import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { NestedItem } from "..";
import CalendarEvent from "types/CalendarEvent";
import CalendarEventDialog from "../../CalendarEventDialog/CalendarEventDialog";
import { smallNetworkIcons } from "types/Network";
import { cellHeight } from "../styles";
import NestedItemsTag from "./NestedItems";

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "#F7F7F7",
		width: "100%",
		height: cellHeight,
		display: "flex",
		flexDirection: "row",
		position: "relative",
		cursor: "pointer",
	},

	campaignColorStrip: {
		width: 4,
		height: "100%",
		borderTopLeftRadius: 6,
		borderBottomLeftRadius: 6,
	},

	campaignTitle: {
		fontSize: 11,
		marignLeft: 8,
		maxWidth: "85%",
		color: theme.palette.text.primary,
		padding: 8,
		lineHeight: 1,
	},
	socialMediaIcons: {
		display: "flex",
		flexDirection: "row",
		position: "absolute",
		bottom: 6,
		right: 6,
	},

	icon: {
		height: 13,
		marginLeft: 6,
	},
}));

interface Props {
	event: CalendarEvent;
	day?: number;
	hour?: string;
	nestedItems?: NestedItem[];
	hideNestedTag?: boolean;
}

const CalendarItem = (props: Props) => {
	const classes = useStyles();
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [eventDialog, setEventDialog] = useState<boolean>(false);
	const { event, nestedItems, day, hour, hideNestedTag } = props;

	const handleEventClick = () => {
		if (menuAnchor) return;
		setEventDialog(true);
		setMenuAnchor(null);
	};

	return (
		<>
			<CalendarEventDialog
				open={eventDialog}
				handleClose={() => setEventDialog(false)}
				selectedEvent={event}
			/>
			<div
				onClick={handleEventClick}
				style={{
					backgroundColor: hideNestedTag ? "white" : "",
					width: hideNestedTag ? "100%" : "",
				}}
				className={classes.container}
			>
				{!hideNestedTag && (
					<NestedItemsTag
						nestedItems={nestedItems as NestedItem[]}
						day={day as number}
						hour={hour as string}
						menuAnchor={menuAnchor}
						setMenuAnchor={setMenuAnchor}
						selectedItem={event}
					/>
				)}
				<div style={{ backgroundColor: event.color }} className={classes.campaignColorStrip}></div>
				<div className={classes.campaignTitle}>
					{event.title && event.title.length > 50
						? `${event.title.substring(0, 50)}...`
						: event.title}
				</div>
				<div className={classes.socialMediaIcons}>
					{event.networks?.map((network, index) => (
						<img key={index} className={classes.icon} src={smallNetworkIcons[network]} />
					))}
				</div>
			</div>
		</>
	);
};

export default CalendarItem;

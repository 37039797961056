import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TaskChip from "modules/Tasks/TaskChip";
import TaskChipsSkeleton from "modules/Tasks/LoadingSkeletons/TaskChipsSkeleton";
import { listActiveTasks } from "redux/actions/tasks/get";
import { StoreState } from "../../setup/store";
import Task from "types/Task";
import usePageStyles from "./styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { setTaskDialog } from "redux/actions/modals";

const MyTasks = () => {
	const { t } = useTranslation("tasks");
	const classes = usePageStyles();
	const { userData } = useSelector((state: StoreState) => state.user);
	const { currentTeamId } = useSelector((state: StoreState) => state.teams);
	const { currentUserTasks, totalCurrentUserTasks, listTasksLoading } = useSelector(
		(state: StoreState) => state.tasks
	);
	const dispatch = useDispatch();

	const getTasks = (next?: boolean) => {
		dispatch(
			listActiveTasks({
				currentUserTasks: true,
				unreadComments: true,
				from: next ? currentUserTasks.length : undefined,
				pageLimit: 10,
			})
		);
	};

	const handleTaskClick = (task: Task) => {
		dispatch(setTaskDialog(true, task));
	};

	useEffect(() => {
		if (currentTeamId && userData) {
			getTasks();
		}
	}, [currentTeamId, userData]);

	return (
		<Box style={{ paddingTop: 68, paddingLeft: 32, height: "35vh" }}>
			<Box className={classes.cardTitle}>{t("myTasks")}</Box>

			<InfiniteScroll
				dataLength={currentUserTasks.length}
				next={() => getTasks(true)}
				height="30vh"
				style={{ overflowX: "hidden", marginTop: 8 }}
				loader={<h4>Loading...</h4>}
				hasMore={totalCurrentUserTasks > currentUserTasks.length}
			>
				{!listTasksLoading &&
					currentUserTasks.map((task, index) => (
						<TaskChip
							showDeadline
							style={{ backgroundColor: "white" }}
							onClick={handleTaskClick}
							task={task}
							key={index}
						/>
					))}

				{listTasksLoading && <TaskChipsSkeleton />}

				{!listTasksLoading && currentUserTasks.length === 0 && (
					<Box
						style={{ textAlign: "center", marginTop: 102 }}
						className={classes.emptySectionMessage}
					>
						{t("noCurrentUserTasks1")}
						<br />
						{t("noCurrentUserTasks2")}
					</Box>
				)}
			</InfiniteScroll>
		</Box>
	);
};

export default MyTasks;

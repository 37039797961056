import { Conversation, Message } from "types/Chat/Chat";
import { ReduxAction } from "types/ReduxActions";

export interface ChatReducerState {
	selectedConvo: Conversation | null;
	isChatOpen: boolean;
	conversations: Array<
		{
			messages: Message[];
			total: number;
		} & Conversation
	>;
	messagesLoading: boolean;
	unreadMessages: { [sender: string]: number } | null;
	searchValue: string;
}

const initialState: ChatReducerState = {
	isChatOpen: false,
	selectedConvo: {
		type: "channel",
		receiver: "general",
	},
	conversations: [],
	messagesLoading: false,
	unreadMessages: null,
	searchValue: "",
};

export const SET_SELECTED_CONVO = "SET_SELECTED_CONVO";
export const SET_CONVERSATION_MESSAGES = "SET_CONVERSATION_MESSAGES";
export const SET_MESSAGES_LOADING = "SET_MESSAGES_LOADING";
export const SET_CHAT_OPEN = "SET_CHAT_OPEN";
export const SET_UNREAD_MESSAGES = "SET_UNREAD_MESSAGES";
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";

const chatReducer = (
	state: ChatReducerState = initialState,
	action: ReduxAction
): ChatReducerState => {
	switch (action.type) {
		case SET_SEARCH_VALUE:
			return {
				...state,
				searchValue: action.payload,
			};
		case SET_UNREAD_MESSAGES:
			return {
				...state,
				unreadMessages: action.payload,
			};
		case SET_CHAT_OPEN:
			return {
				...state,
				isChatOpen: action.payload,
			};
		case SET_MESSAGES_LOADING: {
			return {
				...state,
				messagesLoading: action.payload,
			};
		}

		case SET_SELECTED_CONVO: {
			return {
				...state,
				selectedConvo: action.payload,
			};
		}

		case SET_CONVERSATION_MESSAGES: {
			let convoFound = false;
			const { receiver } = action.payload;
			const conversations = [...state.conversations];
			conversations.forEach((convo, index) => {
				if (convo.receiver === receiver) {
					convoFound = true;
					conversations[index] = {
						...convo,
						...action.payload,
					};
				}
			});

			if (!convoFound) {
				conversations.push(action.payload);
			}

			return {
				...state,
				conversations,
				messagesLoading: false,
			};
		}

		case SET_CONVERSATIONS: {
			return {
				...state,
				conversations: action.payload,
			};
		}

		default:
			return state;
	}
};

export default chatReducer;

import { Box } from "@material-ui/core";
import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import UserPicture from "components/Media/UserPicture";
import { StoreState } from "../../../../setup/store";
import { TeamUser } from "types/User/Team";
import UserData from "types/User/UserData";
import useTaskAssignmentStyles from "../../styles/assignments";
import { EditModeInputValues } from "../TaskDialog";
import UserRoleIcon from "modules/Settings/Team/UserRoleIcon";

interface Props {
	teamUser: Partial<TeamUser>;
	inputValues?: EditModeInputValues;
	editMode?: boolean;
	style?: CSSProperties;
	hideCurrentUserHighlighter?: boolean;
	onClick?: (user: UserData) => void;
}

const TaskAssignee = (props: Props) => {
	const { teamUser, inputValues, editMode, style, onClick, hideCurrentUserHighlighter } = props;
	const { userData } = useSelector((state: StoreState) => state.user);
	const classes = useTaskAssignmentStyles();
	const selected = Boolean(
		inputValues?.assignees?.find((assignee) => assignee._id === teamUser.user?._id)
	);
	const isCurrentUser = userData?._id === teamUser.user?._id;

	return (
		<Box
			className={classes.teamUser}
			onClick={() => onClick && onClick(teamUser.user!)}
			style={{
				backgroundColor: selected && editMode ? "white" : "",
				cursor: !editMode ? "default" : "",
				fontWeight: isCurrentUser ? 500 : 400,
				...style,
			}}
		>
			<UserPicture user={teamUser.user} />
			<Box className={classes.username}>
				{teamUser.user?.firstName} {teamUser.user?.lastName}{" "}
				{isCurrentUser && !hideCurrentUserHighlighter ? "(You)" : ""}
			</Box>

			{!editMode && (
				<UserRoleIcon role={teamUser.role!} style={{ width: "fit-content", marginLeft: "auto" }} />
			)}
		</Box>
	);
};

export default TaskAssignee;

import { Box, CircularProgress } from "@material-ui/core";
import { CSSProperties } from "react";

interface Props {
	style?: CSSProperties;
	size?: number;
	isVisible?: boolean;
}

const LoadingSpinner: React.FC<Props> = (props) => {
	const { style, size, isVisible: visible } = props;

	const isHidden = typeof visible === "boolean" && !visible;

	return (
		<Box
			style={{
				width: "fit-content",
				marginLeft: "auto",
				marginRight: "auto",
				marginTop: isHidden ? 0 : 32,
				marginBottom: isHidden ? 0 : 32,
				transition: "0.3s",
				overflow: "hidden",
				minHeight: isHidden ? 0 : 35,
				maxHeight: isHidden ? 0 : 35,
				...style,
			}}
		>
			<CircularProgress
				style={{
					overflow: "hidden",
					transition: "0.3s",
					width: 35,
					minHeight: isHidden ? 0 : 35,
					maxHeight: isHidden ? 0 : 35,
				}}
				size={size || 25}
			/>
		</Box>
	);
};

export default LoadingSpinner;

import { Box, CircularProgress } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Basic/Button";
import TeamCard from "modules/Settings/Team/TeamCard";
import { StoreState } from "../../../../../../setup/store";
import Team from "types/User/Team";
import usePageStyles from "../../../../page-styles";
import useWorkspacesStyles from "../../styles";
import { setCurrentTeamId } from "redux/actions/team/post";

interface Props {
	setSelectedTeam: Dispatch<SetStateAction<Team | undefined>>;
	setNewTeam: Dispatch<SetStateAction<boolean>>;
	selectedTeam: Team | undefined;
}

const TeamsList = (props: Props) => {
	const classes = useWorkspacesStyles();
	const pageClasses = usePageStyles();
	const { t } = useTranslation("workspaces");
	const { selectedTeam, setNewTeam, setSelectedTeam } = props;
	const { teams, teamsLoading } = useSelector((state: StoreState) => state.teams);
	const dispatch = useDispatch();

	const handleCreateClick = () => {
		setNewTeam(true);
		setSelectedTeam(undefined);
	};

	const handleTeamClick = (team: Team) => {
		setNewTeam(false);
		console.log(team._id)
		dispatch(setCurrentTeamId(team._id));
	};

	return (
		<Box style={{ width: "40%", marginLeft: 32 }}>
			<Box display="flex" alignItems="center">
				<Box style={{ marginLeft: 0, marginBottom: 12 }} className={pageClasses.cardTitle}>
					{t("yourWorkspaces")}
				</Box>
			</Box>

			<Box style={{ marginTop: 12 }}>
				<Box style={{ gap: 32 }} display="flex" alignItems="center" flexWrap="wrap">
					{teams.map((team, index) => (
						<TeamCard
							key={index}
							team={team}
							onClick={handleTeamClick}
							selected={selectedTeam?._id === team._id}
						/>
					))}
				</Box>

				{teamsLoading && teams.length === 0 && (
					<Box className={classes.loadingContainer}>
						<CircularProgress size={20} />
					</Box>
				)}
			</Box>

			<Button secondary onClick={handleCreateClick} style={{ width: "100%", marginTop: 32 }}>
				{t("createWorkspace")}
			</Button>
		</Box>
	);
};

export default TeamsList;

import { makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import { FileUploadProgress } from "redux/actions/media/upload";
import { ContentType } from "types/Content";
import { PostPayload } from "types/Post";
import PostCreator from "./PostCreator";

export interface CreatorProps {
	selectedContentType: ContentType;
	postPayload: PostPayload;
	postContent: string;
	filesUploadProgress: FileUploadProgress[];
	isHashtagsDrawerOpen: boolean;
	setIsHashtagsDrawerOpen: Dispatch<SetStateAction<boolean>>;
	setPostContent: Dispatch<SetStateAction<string>>;
	setPostPayload: Dispatch<SetStateAction<PostPayload>>;
	handleSubmit: (publishNow?: boolean, draft?: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundColor: "white",
		borderRadius: 12,
		width: "50%",
		overflowY: "auto",
		marginTop: 16,
		marginRight: 8,
		padding: 26,
		position: "relative",
		maxHeight: "calc(90vh - 160px)",
		height: "fit-content",
	},

	postContentContainer: {
		border: `1px solid ${theme.palette.text.secondary}`,
		height: 350,
		width: "100%",
		borderRadius: 10,
		marginTop: 16,
	},
}));

const CreatorSection: React.FC<CreatorProps> = (props) => {
	const classes = useStyles();
	const { filesUploadProgress } = props;

	return (
		<div className={classes.container}>
			{props.selectedContentType === "post" && (
				<PostCreator {...props} filesUploadProgress={filesUploadProgress} />
			)}
		</div>
	);
};

export default CreatorSection;

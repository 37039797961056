import { makeStyles } from "@material-ui/core";

const useProfilesSelectorStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: "#D9E3EB",
		height: 36,
		width: 170,
		borderRadius: 8,
		fontFamily: "Avenir",
		fontSize: 12,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		border: "0px",
		paddingLeft: 8,
		color: "#3E4C55",
		"&:focus": {
			outline: "none",
		},
		cursor: "pointer",
	},

	popover: {
		backgroundColor: "white",
		width: 270,
		height: 390,
		borderRadius: 10,
		padding: 22,
	},

	menuTitle: {
		color: theme.palette.text.primary,
		fontSize: 12,
		fontFamily: "Avenir",
		letterSpacing: 0.5,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	profilesContainer: {
		marginTop: 8,
		maxHeight: 235,
		overflowY: "scroll",
		alignItems: "center",
	},

	profile: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 4,
		justifyContent: "center",
		cursor: 'pointer'
	},

	profileName: {
		color: theme.palette.text.primary,
		fontFamily: "Avenir",
		fontSize: 11,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		width: 100,
	},

	checkboxContainer: {
		marginLeft: "auto",
	},

	buttonContainer: {
		marginLeft: "auto",
		marginRight: "auto",
		width: "fit-content",
		marginTop: 24,
		display: "flex",
		flexDirection: "column",
	},

	addNewProfile: {
		cursor: "pointer",
		color: theme.palette.text.primary,
		fontSize: 12,
		marginTop: 12,
		textAlign: "center",
		fontFamily: "Avenir",
	},

	loadingContainer: {
		width: "fit-content",
		marginLeft: "auto",
		marginRight: "auto",
		padding: 24,
	},
}));

export default useProfilesSelectorStyles;

const SearchIconSvg = ({ size, color }: { size?: number; color?: string }) => {
	return (
		<svg
			width={size || 28}
			height={size || 28}
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.6"
				d="M1.5 26.5L8.64286 19.3571M15.7857 22.9286C21.7031 22.9286 26.5 18.1316 26.5 12.2143C26.5 6.29695 21.7031 1.5 15.7857 1.5C9.86838 1.5 5.07143 6.29695 5.07143 12.2143C5.07143 18.1316 9.86838 22.9286 15.7857 22.9286Z"
				stroke={color || "#868C91"}
				strokeWidth="2"
			/>
		</svg>
	);
};

export default SearchIconSvg;

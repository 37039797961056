import { Box, Divider, IconButton } from "@material-ui/core";
import useStyles from "./styles";
import moment from "moment";
import HeartIconSvg from "assets/SVG/HeartIconSvg";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ClampLines from "react-clamp-lines";
import Interaction from "types/Interaction";
import SocialProfile from "types/SocialProfile";
import Button from "components/Basic/Button";
import SocialProfileInfo from "./SocialProfileInfo";
import SocialProfilePicture from "components/Media/SocialProfilePicture";
import MediaAttachment from "components/Media/MediaAttachment";
import { useDispatch } from "react-redux";
import { setFullScreenMediaPreview } from "redux/actions/modals";

interface Props {
	interaction: Interaction;
	socialProfile: SocialProfile;
	handleExpand: () => void;
	handleReply: () => void;
}

const ExpandedItem = (props: Props) => {
	const classes = useStyles();
	const { interaction, socialProfile, handleExpand, handleReply } = props;
	const { type, replied, read } = interaction;
	const postInfoShown = type === "repost" || type === "comment";
	const dispatch = useDispatch();

	const handleAttachmentClick = (index: number) => {
		if (interaction.media) {
			dispatch(
				setFullScreenMediaPreview({
					open: true,
					media: interaction.media,
					initialSelectedIndex: index,
					createdAt: interaction.createdAt,
				})
			);
		}
	};

	return (
		<div
			className={classes.container}
			style={{ flexDirection: "column", alignItems: "flex-start", padding: 12 }}
		>
			<div className={classes.linearContainer}>
				<SocialProfilePicture
					style={{ width: 45, height: 45, marginRight: 12 }}
					url={interaction.from?.profilePictureUrl}
				/>
				<div className={classes.username}>
					{interaction.from?.displayName || interaction.from?.username}
				</div>
				<div className={classes.date}>
					{moment(interaction.createdAt).format("MMMM Do YYYY, h:mm a")}
				</div>

				{postInfoShown && (
					<div style={{ paddingLeft: 32 }} className={classes.linearContainer}>
						<HeartIconSvg selected={interaction.isLiked} />
						<span style={{ paddingLeft: 5 }} className={classes.content}>
							{interaction.likes}
						</span>
					</div>
				)}

				{(replied || read) && (
					<div
						style={{
							color: replied ? "#F04755" : "",
							border: replied ? "1px solid #F04755" : "",
							marginLeft: 42,
						}}
						className={classes.interactionStatus}
					>
						{replied ? "Replied" : "Read"}
					</div>
				)}
			</div>

			<div style={{ padding: 12, paddingLeft: 8 }} className={classes.postContent}>
				{interaction.message}
			</div>

			<Box display="flex" flexWrap="wrap" style={{ gap: 8 }}>
				{interaction.media?.map((attachment, index) => (
					<MediaAttachment
						attachment={attachment.value}
						type={attachment.type}
						thumbnailKey={attachment.thumbnail}
						key={index}
						onAttachmentClick={() => handleAttachmentClick(index)}
					/>
				))}
			</Box>

			<div className={classes.collapseIconContainer}>
				<div
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						width: "fit-content",
					}}
				>
					<IconButton onClick={handleExpand}>
						<ExpandLessIcon className={classes.expandIcon} />
					</IconButton>
				</div>

				<div style={{ width: 200, marginTop: -4, marginBottom: 8 }}>
					<Divider />
				</div>
			</div>

			<div className={classes.linearContainer} style={{ width: "100%" }}>
				{postInfoShown && interaction.post && (
					<div className={classes.linearContainer} style={{ width: "65%" }}>
						{interaction.post.media?.[0] && (
							<img src={String(interaction.post.media[0].value)} className={classes.postImage} />
						)}

						<div className={classes.postContent} style={{ width: "100%" }}>
							<span style={{ fontSize: 13 }}>
								Posted {moment(interaction.post.createdAt).format("MMMM Do YYYY, h:mm a")}
							</span>
							<br />
							<span style={{ fontSize: 12 }}>
								<ClampLines
									id="post-content-3"
									text={interaction.post.content as string}
									lines={1}
									ellipsis="..."
									buttons={false}
								></ClampLines>
							</span>
						</div>
					</div>
				)}

				<div
					className={classes.linearContainer}
					style={{ width: "fit-content", marginLeft: "auto" }}
				>
					<SocialProfileInfo socialProfile={socialProfile} />
					<Button onClick={handleReply} style={{ height: 25, width: 100, marginLeft: 32 }}>
						Reply
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ExpandedItem;

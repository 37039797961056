import { makeStyles } from "@material-ui/core";

const useTimeAnalyticsStyles = makeStyles((theme) => ({
	chartContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "50%",
		justifyContent: "center",
		paddingLeft: 28,
	},

	clockContainer: {
		width: "50%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},

	clockLabel: {
		fontSize: 14,
		color: theme.palette.text.primary,
		paddingTop: 18,
	},
}));

export default useTimeAnalyticsStyles;

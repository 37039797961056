import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	container: {
		background: "#FFF8E0",
		width: 220,
		padding: 16,
		boxShadow: "2px 2px 0px #FFE39D",
		position: "relative",
		marginTop: 8,
		cursor: "pointer",
		borderRadius: 8,
	},

	textArea: {
		minHeight: 80,
		color: "#3E4C55",
		fontFamily: "Avenir",
		maxHeight: 300,
		overflowY: "auto",
	},

	dateContainer: {
		position: "absolute",
		bottom: 16,
		right: 16,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	dateText: {
		color: "#3E4C55",
		marginLeft: 8,
		fontSize: 12,
		opacity: 0.8,
		fontFamily: "Avenir",
	},
}));

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useInteractionsSectionStyles = makeStyles(() => ({
	container: {
		padding: 32,
		width: "100%",
		backgroundColor: "white",
		borderRadius: 12,
		marginTop: 16,
		marginBottom: 16,
	},

	header: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},

	filterContainer: {
		width: "70%",
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		gap: 8,
	},

	itemsContainer: {
		marginTop: 20,
	},

	viewAllButtonContainer: {
		marginLeft: "auto",
		width: "fit-content",
		marginTop: 32,
	},

	viewAllButton: {
		width: 170,
		border: "1px solid #D1DDE6 !important",
		color: "#4B7FA3 !important",
		backgroundColor: "white !important",
	},

	loadingContainer: {
		display: "flex",
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
	},
}));

export default useInteractionsSectionStyles;

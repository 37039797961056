import usePageStyles from "../../styles";
import Input from "components/Basic/Inputs/Input";
import Button from "components/Basic/Button";
import { ChangeEvent, useState } from "react";
import { useEffect } from "react";
import { validatePassword } from "helpers/utils";
import { changePassword } from "redux/actions/auth/password";
import { useDispatch } from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import usePersonalInfoStyles from "../styles/personal-info-styles";
import { getSettingPageRoute, selectedSettingTabQuery } from "pages/Settings";
import clsx from "clsx";
import PasswordValidator from "pages/Auth/SignUp/PasswordValidator";

type Payload = {
	currentPassword?: string;
	newPassword?: string;
	confirmPassword?: string;
};

const ChangePassword = () => {
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const [inputValues, setInputValues] = useState<Payload>({});
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { t } = useTranslation("settings");
	const [errors, setErrors] = useState<{ [index: string]: string }>({});
	const pageClasses = usePageStyles();
	const history = useHistory();
	const classes = usePersonalInfoStyles();

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		setInputValues({
			...inputValues,
			[name]: value,
		});
	};

	const handleSubmitClick = () => {
		const { currentPassword, newPassword } = inputValues;
		if (!validatePassword(newPassword!) || !currentPassword || !newPassword)
			return setErrors({
				newPassword: t("invalidPassword"),
			});

		setLoading(true);

		dispatch(
			changePassword(currentPassword, newPassword, (success, error) => {
				if (error) {
					if (error === "NotAuthorizedException")
						setErrors({
							...errors,
							currentPassword: t("invalidPassword"),
						});
				} else {
					setErrors({});
					history.push(getSettingPageRoute('myAccount'));
				}

				setLoading(false);
			})
		);
	};

	useEffect(() => {
		const { currentPassword, newPassword, confirmPassword } = inputValues;

		if (!currentPassword || !newPassword || newPassword !== confirmPassword)
			setSubmitDisabled(true);
		else setSubmitDisabled(false);
	}, [inputValues]);

	return (
		<div style={{ width: "40%", marginLeft: 16 }}>
			<div className={clsx(pageClasses.card, classes.card)}>
				<Box className={pageClasses.innerCardTitle}>{t("passwordUpdate")}</Box>
				<div className={classes.inputContainer}>
					<div className={pageClasses.inputTitle} style={{ marginBottom: 4 }}>
						{t("yourCurrentPassword")}
					</div>
					<Input
						style={{ width: 320, fontSize: 12 }}
						inputStyle={{ width: "100%" }}
						name="currentPassword"
						error={errors["currentPassword"]}
						type="password"
						onChange={handleInputChange}
						value={inputValues["currentPassword"]}
					/>
				</div>

				<div className={classes.inputContainer} style={{ width: 320 }}>
					<div className={pageClasses.inputTitle} style={{ marginBottom: 4 }}>
						{t("createNewPassword")}
					</div>
					<Input
						style={{ width: 320, fontSize: 12 }}
						inputStyle={{ width: "100%" }}
						name="newPassword"
						type="password"
						onChange={handleInputChange}
						value={inputValues["newPassword"]}
					/>
					<PasswordValidator password={inputValues["newPassword"]} />
				</div>

				<div className={classes.inputContainer}>
					<div className={pageClasses.inputTitle} style={{ marginBottom: 4 }}>
						{t("confirmNewPassword")}
					</div>
					<Input
						style={{ width: 320, fontSize: 12 }}
						name="confirmPassword"
						type="password"
						onChange={handleInputChange}
						error={errors["confirmPassword"]}
						value={inputValues["confirmPassword"]}
					/>
				</div>

				<div className={classes.buttonsContainer}>
					<Button
						onClick={handleSubmitClick}
						disabled={submitDisabled || loading}
						style={{ width: 170 }}
					>
						{loading ? <CircularProgress size={20} /> : t("saveChanges")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;

import { ChangeEvent, CSSProperties, MouseEvent, useState } from "react";
import { Box, Popover } from "@material-ui/core";
import ExpandIconSvg from "assets/SVG/ExpandIconSvg";
import useDropdownInputStyles from "./styles";
import SearchInput from "../SearchInput";
import { useCallback } from "react";
import { debounce } from "lodash";
import { useEffect } from "react";

type DropdownInputOption = {
	value: string;
	label: string;
};

interface Props {
	label: string;
	name: string;
	value?: string;
	style?: CSSProperties;
	rootStyle?: CSSProperties;
	options: DropdownInputOption[];
	disableSearch?: boolean;
	selectedOption?: string;
	onChange: (name: string, value: string) => void;
}

const DropdownInput = (props: Props) => {
	const classes = useDropdownInputStyles();
	const [searchValue, setSearchValue] = useState<string>("");
	const [popover, setPopover] = useState<HTMLElement | null>(null);
	const [filteredOptions, setFilteredOptions] = useState<DropdownInputOption[]>([]);
	const { label, value, options, disableSearch, style, name, rootStyle, onChange } = props;

	const handleExpandClick = (e: MouseEvent<HTMLElement>) => {
		setPopover(e.currentTarget);
	};

	const debouncedSearch = useCallback(
		debounce((searchValue) => {
			let filteredOptions: DropdownInputOption[] = [];
			let lowerCaseValue = searchValue.toLowerCase();
			options.forEach((option) => {
				if (
					option.label.toLowerCase().includes(lowerCaseValue) ||
					option.value.toLowerCase().includes(lowerCaseValue)
				) {
					filteredOptions.push(option);
				}
			});

			setFilteredOptions([...filteredOptions]);
		}, 300),
		[options]
	);

	const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchValue(value);
		if (value) {
			debouncedSearch(value);
		} else {
			setFilteredOptions([...options]);
		}
	};

	useEffect(() => {
		if (popover) {
			setSearchValue("");
			setFilteredOptions([...options]);
		}
	}, [options, popover]);

	return (
		<Box style={rootStyle}>
			<Box className={classes.label} style={{ marginBottom: 8 }}>
				{label}
			</Box>
			<Box style={{ ...style }} onClick={handleExpandClick} className={classes.container}>
				<Box className={classes.label}>
					{options.filter((option) => option.value === value)[0].label}
				</Box>

				<Box className={classes.rightContainer}>
					<ExpandIconSvg />
				</Box>
			</Box>

			<Popover
				open={Boolean(popover)}
				onClose={() => setPopover(null)}
				anchorEl={popover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Box className={classes.popoverContainer}>
					{!disableSearch && (
						<Box style={{ padding: 12 }} width='fit-content' margin='auto'>
							<SearchInput
								autoFocus
								inputStyle={{ width: 160, fontSize: 12 }}
								iconStyle={{ marginTop: 2, marginLeft: 2 }}
								iconSize={14}
								style={{ width: 180, height: 30 }}
								value={searchValue}
								onChange={handleSearchInputChange}
							/>
						</Box>
					)}

					<Box style={{ maxHeight: 300, overflowY: "auto" }}>
						{filteredOptions.map((option, index) => (
							<div
								onClick={() => {
									onChange(name, option.value);
									setPopover(null);
								}}
								key={index}
								style={{ fontWeight: value === option.value ? 700 : 400 }}
								className={classes.option}
							>
								{option.label}
							</div>
						))}
					</Box>
				</Box>
			</Popover>
		</Box>
	);
};

export default DropdownInput;

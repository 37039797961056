import { useEffect, useState } from "react";
import { validateEmail } from "helpers/utils";
import FormHeader from "../FormHeader";
import AuthIllustration from "../Illustration";
import useAuthStyles from "../styles";
import EmailEntry from "./EmailEntry";
import NewPasswordEntry from "./NewPasswordEntry";
import { useDispatch } from "react-redux";
import { sendPassRecoveryEmail } from "redux/actions/auth/password";
import { useTranslation } from "react-i18next";

const PasswordRecovery = () => {
	const classes = useAuthStyles();
	const [sentEmails, setSentEmails] = useState<number>(0);
	const [email, setEmail] = useState<string>("");
	const [emailError, setEmailError] = useState<string>("");
	const [resendError, setResendError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation("auth");
	const dispatch = useDispatch();

	const handleSubmit = () => {
		let error = "";
		if (!email) {
			error = t("enterEmail");
		} else if (!validateEmail(email)) {
			error = t("invalidEmail");
		}

		setEmailError(error);
		if (!error) {
			setLoading(true);

			dispatch(
				sendPassRecoveryEmail(email, (success, error) => {
					setLoading(false);
					if (success) {
						setSentEmails(sentEmails + 1);
					} else {
						const { code } = error;
						if (code === "LimitExceededException") {
							if (sentEmails === 0) {
								setEmailError(t(error));
							} else {
								setResendError(t(error));
							}
						}
					}
				})
			);
		}
	};

	useEffect(() => {
		return function cleanup() {
			setSentEmails(0);
			setEmailError("");
			setResendError("");
		};
	}, []);

	return (
		<div className={classes.pageContainer}>
			<AuthIllustration />
			<div className={classes.rightSection}>
				<FormHeader />

				<div className={classes.formContainer}>
					<div className={classes.authTabsContainer}>
						<div className={classes.authTab}>{t("passwordRecovery")}</div>
					</div>

					{sentEmails === 0 && (
						<EmailEntry
							email={email}
							setEmail={setEmail}
							onSubmit={handleSubmit}
							emailError={emailError}
							loading={loading}
							sentEmails={sentEmails}
						/>
					)}

					{sentEmails > 0 && <NewPasswordEntry email={email} />}

					{sentEmails === 1 && (
						<>
							<div onClick={handleSubmit} className={classes.resendText}>
								{t("resendEmailMessage")}
							</div>

							{resendError && <div className={classes.message}>{resendError}</div>}
						</>
					)}

					{sentEmails === 2 && (
						<div className={classes.message}>{t("confirmationEmailResent")}</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PasswordRecovery;
